import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

import TipsWidget from 'components/TipsWidget/index';
import Tabs from 'components/Tabs';
import { Card, CardTitle } from 'components/Card';
import NewModalPauseCampaign from 'components/ModalLearningPhase';
import ModalPauseCampaign from 'components/ModalPauseCampaign';

import {
    RETARGETING,
    CUSTOM,
    SPECIAL_OFFER,
    PROSPECTING,
    DYNAMIC_RETARGETING,
    VISITOR_RETARGETING, SOCIAL_RETARGETING, AI_BOOSTED_SALES
} from 'constants/campaign';
import { metricType } from 'constants/metric';

import CampaignDetailsPromoContainer from './CampaignDetailsPromo';
import { CAMPAIGN_CLIENT_STATUS, switchClassNameFromCampaignStatus, getDailyBudget } from './helpers';
import DailyBudgetRecommendation from './DailyBudgetRecommendation';
import CampaignDetailsAdMessage from './CampaignDetailsAdMessage';
import ResumeCampaignButton from './ResumeCampaignButton';
import PauseCampaignButton from './PauseCampaignButton';
import EditCampaignButton from './EditCampaignButton';

import './CampaignDetails.css';

class CampaignDetails extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            collapseAdMessageInput: false,
            isPauseCampaignModalVisible: false,
            isNewPauseCampaignModalVisible: false,
        };

        this.toggleCollapseAdMessage = this.toggleCollapseAdMessage.bind(this);
    }

    toggleCollapseAdMessage() {
        this.setState({
            collapseAdMessageInput: !this.state.collapseAdMessageInput,
        });
    }

    toggleNewPauseCampaignModal = (id, campaignType) => {
        const { trackEventInitiateCampaignPause, requestSendEventMetrics } = this.props;

        if (!this.state.isNewPauseCampaignModalVisible) {
            if (this.props?.campaignDetailsData?.type) {
                trackEventInitiateCampaignPause(this.props?.campaignDetailsData?.type);
            } else {
                trackEventInitiateCampaignPause(campaignType);
            }
        }

        const metricProps = {
            shop_id: id,
            type: metricType.btnClicked,
            props: {
                btn_name: 'pausing_fb_campaign_in_learning_phase',
            },
        };
        id && requestSendEventMetrics(metricProps);

        this.setState((state) => ({
            ...state,
            isNewPauseCampaignModalVisible: !state.isNewPauseCampaignModalVisible,
        }));
    };

    togglePauseCampaignModal = (id, campaignType) => {
        const { trackEventInitiateCampaignPause } = this.props;

        if (!this.state.isPauseCampaignModalVisible) {
            if (this.props?.campaignDetailsData?.type) {
                trackEventInitiateCampaignPause(this.props?.campaignDetailsData?.type);
            } else {
                trackEventInitiateCampaignPause(campaignType);
            }
        }

        this.setState((state) => ({
            ...state,
            isPauseCampaignModalVisible: !state.isPauseCampaignModalVisible,
        }));
    };

    getCampaignTitle() {
        const { title, campaignType, campaignDetailsData } = this.props;

        const customCampaignTitle = `Custom Campaign: ${campaignDetailsData?.name}`;

        return campaignType === CUSTOM ? customCampaignTitle : title;
    }

    render() {
        const {
            shopError: { errorToken },
            shopValidateRequesting,
            campaignDetailsData,
            resumeCampaign,
            campaignPausePending,
            campaignResumePending,
            titleTooltipContent,
            titleTooltipLink,
            type,
        } = this.props;

        const campaignStatusBadgeClassName = switchClassNameFromCampaignStatus(
            campaignDetailsData.client_status,
            campaignDetailsData.in_learning_phase,
            'dashboard-campaign__title-status',
            'dashboard-campaign__title-status_pending',
            'dashboard-campaign__title-status_active',
            'dashboard-campaign__title-status_paused',
            'dashboard-campaign__title-status_declined',
            'dashboard-campaign__title-status_creating',
            'dashboard-campaign__title-status_rejected',
            'dashboard-campaign__title-status_removed',
            'dashboard-campaign__title-status_in_learning_phase',
        );

        const isDisabledEditAdMessageButton =
            campaignPausePending ||
            shopValidateRequesting ||
            campaignResumePending ||
            campaignDetailsData.client_status === CAMPAIGN_CLIENT_STATUS.DECLINED ||
            campaignDetailsData.client_status === CAMPAIGN_CLIENT_STATUS.REJECTED ||
            campaignDetailsData.client_status === CAMPAIGN_CLIENT_STATUS.CREATING ||
            [
                'payment-method-not-found',
                'ad-account-has-wrong-status-disabled',
                'ad-account-has-wrong-status-closed',
                'ad-account-has-wrong-status-pending_risk_review',
                'shop-is-frozen-due-to-ad-account-is-unsettled',
                'ad-account-has-wrong-status-unsettled',
                'public-page-not-found',
                'account-doesnt-have-access-to-page',
                'has-no-page-access-token',
                'shop-not-found',
                'account-doesnt-have-access-to-business-manager',
                'business-manager-was-deleted',
                'platform-doesnt-have-access-to-shop',
                'ad-account-is-disabled-due-to-risk-payment',
                'shop-is-frozen-due-to-force-paused',
                'shop-is-frozen-due-to-ra-plugin-for-wc-deactivated',
                'shop-is-frozen-due-to-subscription-canceled',
                'shop-is-frozen-due-to-subscription-spend-limit-reached'
            ].includes(errorToken);

        const getProductsInAdsFieldView = (campaign) => {
            if (campaign.products_in_ads) {
                return (
                    <li className="dashboard-campaign-info__item">
                        <CardTitle subTitle>Products used in ads</CardTitle>
                        <span className="dashboard-campaign-info__item-value">{campaign.products_in_ads}</span>
                    </li>
                );
            }
            return '';
        };
        // eslint-disable-next-line
        const TooltipForStandardTitle = () => {
            return (
                <TipsWidget isDarkIcon placement="top">
                    <p className="tips-description__content">
                        Re-engages your website visitors who viewed a product but didn’t purchase it.
                    </p>
                    <p className="tips-description__content">
                        <i>Needs at least 100 visitors per day in store.</i>
                    </p>

                    <p className="tips-description__content">
                        <a href={titleTooltipLink} className="link" rel="noopener noreferrer" target="_blank">
                            Learn more →
                        </a>
                    </p>
                </TipsWidget>
            );
        };
        // eslint-disable-next-line
        const TooltipForEnhancedTitle = () => {
            return (
                <TipsWidget isDarkIcon placement="top">
                    <p className="tips-description__content">
                        Re-engages your website visitors who added a product to the cart but did not purchase it.
                    </p>

                    <p className="tips-description__content">
                        <a
                            href={
                                'https://help.adwisely.com/improve-your-campaign-results/' +
                                'learn-about-advertising-tactics/enhanced-cart-recovery'
                            }
                            className="link"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Learn more →
                        </a>
                    </p>
                </TipsWidget>
            );
        };

        let tabsTitles = [];
        let tabsContent = [];

        const isRetargeting =
            campaignDetailsData.type === DYNAMIC_RETARGETING ||
            campaignDetailsData.type === VISITOR_RETARGETING ||
            campaignDetailsData.type === SOCIAL_RETARGETING ||
            campaignDetailsData.type === AI_BOOSTED_SALES;

        if (isRetargeting) {
            const retargetingAdSetNames = {
                retargeting_standard: 'Standard',
                retargeting_ecr: 'Enhanced Cart Recovery',
                dynamic_retargeting: 'Dynamic Retargeting',
                ai_boosted_sales: 'AI-Powered',
                visitor_retargeting: 'Visitor Retargeting',
                social_media_retargeting: 'Social Media Retargeting'
            };

            campaignDetailsData.ad_sets.forEach((ad_set, i) => {
                tabsTitles.push(
                    <div className="tabs-navigation__link-content-static retargeting">
                        <span>{retargetingAdSetNames[ad_set?.type]}</span>
                        {ad_set?.type === 'retargeting_ecr' ? <TooltipForEnhancedTitle /> : <TooltipForStandardTitle />}
                    </div>,
                );
                tabsContent.push(
                    <CampaignDetailsAdMessage
                        toggleCollapseAdMessage={this.toggleCollapseAdMessage}
                        adMessage={ad_set?.message}
                        adTargetingSettings={campaignDetailsData.targeting_settings}
                        shop={campaignDetailsData.shop}
                        campaignEditPending={campaignDetailsData.campaignEditPending}
                        campaignId={campaignDetailsData.id}
                        dailyBudget={campaignDetailsData.settings && campaignDetailsData.settings.daily_budget}
                        type={campaignDetailsData.type}
                        isDisabledEditAdMessageButton={isDisabledEditAdMessageButton}
                        adSetType={ad_set?.type}
                    />,
                );
            });
        }

        const isCustomCampaign = campaignDetailsData.type === CUSTOM;

        if (isCustomCampaign) {
            const { campaignDetailsData } = this.props;

            tabsTitles = [];
            tabsContent = [];
            this.props.campaignDetailsData?.ad_sets.forEach((ad_set) => {
                ad_set?.ad_set_ads.forEach((ad_set_ad) => {
                    tabsTitles.push(
                        <div className="tabs-navigation__link-content-static">
                            <span>{ad_set_ad?.name}</span>
                        </div>,
                    );
                    tabsContent.push(
                        <CampaignDetailsAdMessage
                            toggleCollapseAdMessage={this.toggleCollapseAdMessage}
                            adMessage={ad_set_ad?.message}
                            shop={campaignDetailsData.shop}
                            campaignEditPending={false}
                            campaignId={campaignDetailsData.id}
                            dailyBudget={campaignDetailsData.settings && campaignDetailsData.settings.daily_budget}
                            type={campaignDetailsData.type}
                            isDisabledEditAdMessageButton={isDisabledEditAdMessageButton}
                            adSetType="custom"
                        />,
                    );
                });
            });
        }

        const isSpecialOfferV2 = campaignDetailsData.type === SPECIAL_OFFER && campaignDetailsData.version === 2;

        if (isSpecialOfferV2) {
            const { campaignDetailsData } = this.props;

            tabsTitles = [];
            tabsContent = [];
            campaignDetailsData?.ad_sets.forEach((ad_set, i) => {
                tabsTitles.push(
                    <div className="tabs-navigation__link-content-static">
                        <span>{ad_set?.name}</span>
                    </div>,
                );
                tabsContent.push(
                    <CampaignDetailsAdMessage
                        toggleCollapseAdMessage={this.toggleCollapseAdMessage}
                        adTargetingSettings={campaignDetailsData.targeting_settings}
                        adMessage={ad_set?.message}
                        shop={campaignDetailsData.shop}
                        campaignEditPending={false}
                        campaignId={campaignDetailsData.id}
                        dailyBudget={campaignDetailsData.settings && campaignDetailsData.settings.daily_budget}
                        type={campaignDetailsData.type}
                        isDisabledEditAdMessageButton={isDisabledEditAdMessageButton}
                        adSetType={ad_set?.type}
                    />,
                );
            });
        }

        const newPauseCampaignModal = (
            <NewModalPauseCampaign
                togglePauseCampaignModal={this.toggleNewPauseCampaignModal}
                campaignId={campaignDetailsData.id}
                isOpenModal={this.state.isNewPauseCampaignModalVisible}
                type="facebook"
            />
        );

        const pauseCampaignModal = (
            <ModalPauseCampaign
                togglePauseCampaignModal={this.togglePauseCampaignModal}
                campaignType={campaignDetailsData.type}
                campaignId={campaignDetailsData.id}
                isOpenModal={this.state.isPauseCampaignModalVisible}
            />
        );

        const pauseAndEditButtons = (
            <>
                <PauseCampaignButton
                    clientStatus={campaignDetailsData.client_status}
                    collapseAdMessageInput={this.state.collapseAdMessageInput}
                    errorToken={errorToken}
                    shopValidateRequesting={shopValidateRequesting}
                    pending={campaignPausePending}
                    togglePauseCampaignModal={this.togglePauseCampaignModal}
                    toggleNewPauseCampaignModal={this.toggleNewPauseCampaignModal}
                    campaignFailureError={campaignDetailsData.campaignFailureError}
                    campaignType={campaignDetailsData.type}
                    isInLearningPhase={campaignDetailsData?.in_learning_phase}
                    type={type}
                    campaignId={campaignDetailsData.id}
                />

                {campaignDetailsData.type !== CUSTOM && (
                    <EditCampaignButton
                        campaignPausePending={campaignPausePending}
                        campaignResumePending={campaignResumePending}
                        clientStatus={campaignDetailsData.client_status}
                        collapseAdMessageInput={this.state.collapseAdMessageInput}
                        errorToken={errorToken}
                        shopValidateRequesting={shopValidateRequesting}
                        campaignId={campaignDetailsData.id}
                        campaignType={campaignDetailsData.type}
                        shopId={campaignDetailsData.shop}
                        campaignFailureError={campaignDetailsData.campaignFailureError}
                    />
                )}
            </>
        );

        const adMessage = () => {
            let message;
            if (
                campaignDetailsData.type === PROSPECTING &&
                campaignDetailsData.settings.audience_type === 'lookalike'
            ) {
                message = campaignDetailsData?.ad_sets.find(
                    (adSet) => adSet.type === 'lookalike_standard' || adSet.type === 'lookalike_standard_old',
                )?.message;
            } else if (
                campaignDetailsData.type === PROSPECTING &&
                campaignDetailsData.settings.audience_type === 'broad'
            ) {
                message = campaignDetailsData?.ad_sets.find((adSet) => adSet.type === 'broad_standard')?.message;
            } else if (campaignDetailsData.type === SPECIAL_OFFER && campaignDetailsData.version === 1) {
                message = campaignDetailsData?.ad_sets.find(
                    (adSet) => adSet.type === 'special_offer_standard',
                )?.message;
            }
            return message;
        };

        return (
            <Card disablePadding className="dashboard-campaign">
                <CampaignDetailsPromoContainer campaign={campaignDetailsData} />

                <div className="dashboard-campaign__header">
                    <CardTitle>
                        <span>{this.getCampaignTitle()}</span>

                        <TipsWidget isDarkIcon isDarkView>
                            <p className="tips-description__content">{titleTooltipContent}</p>

                            <p className="tips-description__content">
                                <a
                                    href={titleTooltipLink}
                                    className="link link-white"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Learn more →
                                </a>
                            </p>
                        </TipsWidget>
                    </CardTitle>
                    <p className={campaignStatusBadgeClassName} />
                </div>
                <section className="dashboard-campaign__wrapper">
                    <section className="dashboard-campaign__aside">
                        <ul className="dashboard-campaign-info">
                            <li className="dashboard-campaign-info__item">
                                <CardTitle subTitle>Daily Budget</CardTitle>

                                <p className="dashboard-campaign-info__item-value">
                                    {getDailyBudget(campaignDetailsData)}
                                </p>
                            </li>
                            <DailyBudgetRecommendation campaign={campaignDetailsData} />
                            {getProductsInAdsFieldView(campaignDetailsData)}
                        </ul>
                        <div className="dashboard-campaign__aside-nav">
                            <ResumeCampaignButton
                                clientStatus={campaignDetailsData.client_status}
                                collapseAdMessageInput={this.state.collapseAdMessageInput}
                                errorToken={errorToken}
                                shopValidateRequesting={shopValidateRequesting}
                                pending={campaignResumePending}
                                campaignId={campaignDetailsData.id}
                                resumeCampaign={resumeCampaign}
                                campaignFailureError={campaignDetailsData.campaignFailureError}
                            />
                            {pauseAndEditButtons}
                        </div>
                    </section>

                    <section className="dashboard-campaign__main">
                        {isRetargeting || isSpecialOfferV2 || isCustomCampaign ? (
                            <Tabs tabsTitles={tabsTitles} tabsContent={tabsContent} />
                        ) : (
                            <CampaignDetailsAdMessage
                                toggleCollapseAdMessage={this.toggleCollapseAdMessage}
                                adMessage={adMessage()}
                                shop={campaignDetailsData.shop}
                                campaignEditPending={campaignDetailsData.campaignEditPending}
                                campaignId={campaignDetailsData.id}
                                dailyBudget={campaignDetailsData.settings && campaignDetailsData.settings.daily_budget}
                                type={campaignDetailsData.type}
                                isDisabledEditAdMessageButton={isDisabledEditAdMessageButton}
                                adSetType={campaignDetailsData.ad_sets[0].type}
                                adTargetingSettings={campaignDetailsData.targeting_settings}
                            />
                        )}
                    </section>
                </section>
                {this.state.isNewPauseCampaignModalVisible && newPauseCampaignModal}
                {this.state.isPauseCampaignModalVisible && pauseCampaignModal}
            </Card>
        );
    }
}

CampaignDetails.defaultProps = {
    titleTooltipLink: null,
    shopError: {
        errorToken: null,
    },
    campaignPausePending: false,
    campaignResumePending: false,
};

CampaignDetails.propTypes = {
    title: PropTypes.string.isRequired,
    titleTooltipContent: PropTypes.string.isRequired,
    titleTooltipLink: PropTypes.string,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
    }),
    campaignDetailsData: PropTypes.shape({
        client_status: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        products_in_ads: PropTypes.number,
        settings: PropTypes.shape({
            daily_budget: PropTypes.number,
        }),
        shop: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    resumeCampaign: PropTypes.func.isRequired,
    campaignPausePending: PropTypes.bool,
    campaignResumePending: PropTypes.bool,
};

export default CampaignDetails;
