import {callApiShopNotifications, callApiShopNotificationsPut, callApiShopNotificationsDelete} from './api';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const showNotification = notification => ({
    type: SHOW_NOTIFICATION,
    payload: notification,
});

export const hideNotification = () => ({
    type: HIDE_NOTIFICATION,
});

export const SHOW_BULK_NOTIFICATION = 'SHOW_BULK_NOTIFICATION';
export const HIDE_BULK_NOTIFICATION = 'HIDE_BULK_NOTIFICATION';

export const showBulkNotification = (notificationContent, notificationType) => ({
    type: SHOW_BULK_NOTIFICATION,
    payload: {notificationContent, notificationType},
});

export const hideBulkNotification = () => ({
    type: HIDE_BULK_NOTIFICATION,
});

export const displayBulkNotification = (() => {
    let timerBulkNotification = null;
    return (notificationText, notificationType, hideTime) => (dispatch) => {
        if (timerBulkNotification) {
            clearTimeout(timerBulkNotification);
            dispatch(hideBulkNotification());
        }
        timerBulkNotification = setTimeout(() => {
            dispatch(hideBulkNotification());
        }, hideTime || 3000);
        dispatch(showBulkNotification(notificationText, notificationType));
    };
})();

export const SHOP_NOTIFICATIONS_REQUEST = 'SHOP_NOTIFICATIONS_REQUEST';
export const SHOP_NOTIFICATIONS_SUCCESS = 'SHOP_NOTIFICATIONS_SUCCESS';
export const SHOP_NOTIFICATIONS_FAILURE = 'SHOP_NOTIFICATIONS_FAILURE';

export const requestShopNotifications = params => (callApiShopNotifications({
    params,
    types: [
        SHOP_NOTIFICATIONS_REQUEST,
        SHOP_NOTIFICATIONS_SUCCESS,
        SHOP_NOTIFICATIONS_FAILURE,
    ],
}));

export const NOTIFICATION_UPDATE_REQUEST = 'NOTIFICATION_UPDATE_REQUEST';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';
export const NOTIFICATION_UPDATE_FAILURE = 'NOTIFICATION_UPDATE_FAILURE';


export const requestUpdateShopNotification = params => (callApiShopNotificationsPut({
    params,
    types: [
        NOTIFICATION_UPDATE_REQUEST,
        NOTIFICATION_UPDATE_SUCCESS,
        NOTIFICATION_UPDATE_FAILURE,
    ],
}));

export const NOTIFICATION_DELETE_REQUEST = 'NOTIFICATION_DELETE_REQUEST';
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_FAILURE = 'NOTIFICATION_DELETE_FAILURE';


export const requestDeleteShopNotification = params => (callApiShopNotificationsDelete({
    params,
    types: [
        NOTIFICATION_DELETE_REQUEST,
        NOTIFICATION_DELETE_SUCCESS,
        NOTIFICATION_DELETE_FAILURE,
    ],
}));

export const NOTIFICATIONS_REVIEW_BANNER_DELETE_REQUEST = 'NOTIFICATIONS_REVIEW_BANNER_DELETE_REQUEST';
export const NOTIFICATIONS_REVIEW_BANNER_DELETE_SUCCESS = 'NOTIFICATIONS_REVIEW_BANNER_DELETE_SUCCESS';
export const NOTIFICATIONS_REVIEW_BANNER_DELETE_FAILURE = 'NOTIFICATIONS_REVIEW_BANNER_DELETE_FAILURE';


export const requestShopNotificationsDeleteReviewBanner = params => (callApiShopNotificationsDelete({
    params,
    types: [
        NOTIFICATIONS_REVIEW_BANNER_DELETE_REQUEST,
        NOTIFICATIONS_REVIEW_BANNER_DELETE_SUCCESS,
        NOTIFICATIONS_REVIEW_BANNER_DELETE_FAILURE,
    ],
}));

export const NOTIFICATIONS_READ_ALL_ORDERS_DELETE_REQUEST = 'NOTIFICATIONS_READ_ALL_ORDERS_DELETE_REQUEST';
export const NOTIFICATIONS_READ_ALL_ORDERS_DELETE_SUCCESS = 'NOTIFICATIONS_READ_ALL_ORDERS_DELETE_SUCCESS';
export const NOTIFICATIONS_READ_ALL_ORDERS_DELETE_FAILURE = 'NOTIFICATIONS_READ_ALL_ORDERS_DELETE_FAILURE';


export const requestShopNotificationsDeleteReadAllOrders = params => (callApiShopNotificationsDelete({
    params,
    types: [
        NOTIFICATIONS_READ_ALL_ORDERS_DELETE_REQUEST,
        NOTIFICATIONS_READ_ALL_ORDERS_DELETE_SUCCESS,
        NOTIFICATIONS_READ_ALL_ORDERS_DELETE_FAILURE,
    ],
}));

export const NOTIFICATIONS_PROSPECTING_INVITE_DELETE_REQUEST = 'NOTIFICATIONS_PROSPECTING_INVITE_DELETE_REQUEST';
export const NOTIFICATIONS_PROSPECTING_INVITE_DELETE_SUCCESS = 'NOTIFICATIONS_PROSPECTING_INVITE_DELETE_SUCCESS';
export const NOTIFICATIONS_PROSPECTING_INVITE_DELETE_FAILURE = 'NOTIFICATIONS_PROSPECTING_INVITE_DELETE_FAILURE';


export const requestShopNotificationsDeleteProspectingInvite = params => (callApiShopNotificationsDelete({
    params,
    types: [
        NOTIFICATIONS_PROSPECTING_INVITE_DELETE_REQUEST,
        NOTIFICATIONS_PROSPECTING_INVITE_DELETE_SUCCESS,
        NOTIFICATIONS_PROSPECTING_INVITE_DELETE_FAILURE,
    ],
}));

export const NOTIFICATIONS_SPECIAL_OFFER_INVITE_DELETE_REQUEST = 'NOTIFICATIONS_PROSPECTING_INVITE_DELETE_REQUEST';
export const NOTIFICATIONS_SPECIAL_OFFER_INVITE_DELETE_SUCCESS = 'NOTIFICATIONS_PROSPECTING_INVITE_DELETE_SUCCESS';
export const NOTIFICATIONS_SPECIAL_OFFER_INVITE_DELETE_FAILURE = 'NOTIFICATIONS_PROSPECTING_INVITE_DELETE_FAILURE';


export const requestShopNotificationsDeleteSpecialOfferInvite = params => (callApiShopNotificationsDelete({
    params,
    types: [
        NOTIFICATIONS_SPECIAL_OFFER_INVITE_DELETE_REQUEST,
        NOTIFICATIONS_SPECIAL_OFFER_INVITE_DELETE_SUCCESS,
        NOTIFICATIONS_SPECIAL_OFFER_INVITE_DELETE_FAILURE,
    ],
}));
