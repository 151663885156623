import React from 'react';
import classNames from 'classnames';

export const ToDoListFlagToggler = ({title, progress}) => (
    <div className="todo-list__toggler">
        <div className={
            classNames(
                "todo-list_progress",
                {
                    'todo-list_progress--red': progress === 0,
                    'todo-list_progress--neon': (progress > 0 && progress <= 33),
                    'todo-list_progress--orange': (progress > 33 && progress < 100),
                    'todo-list_progress--green': progress === 100
                }
            )
        }>
            {progress}%
        </div>
        <div className="todo-list__toggler-main">
            <div className="todo-list__toggler-title">
                {title}
            </div>
            <div className="todo-list__progress-bar-wrapper">
                <div
                    className={classNames(
                        "todo-list__progress-bar",
                        {
                            'todo-list__progress-bar--red': progress === 0,
                            'todo-list__progress-bar--neon': (progress > 0 && progress <= 33),
                            'todo-list__progress-bar--orange': (progress > 33 && progress < 100),
                            'todo-list__progress-bar--green': progress === 100
                        }
                    )}
                    style={{width: `${progress}%`}}
                />
            </div>
        </div>
        <div className="todo-list__caret">
            <i className="caret-icon" />
        </div>
    </div>
)