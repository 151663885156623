import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { setLocalStorageObjectItem } from 'helpers';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import {
    TIK_TOK_ONBOARDING_STATES,
    TIK_TOK_ONBOARDING_STEPS
} from "constants/tikTokOnboarging";
import { ROUTES } from 'constants/routes';

import { statePollingInterval } from 'helpers/statePollingInterval';
import { fetchOnboardingState } from 'helpers/fetchOnboardingState';

import Wizard from "../../components/Wizard";


const getTikTokRedirectPage = (res, currentShopId, replace) => {
    const { state } = res;
    if (state !== 'not_started') {
        if (Object.keys(TIK_TOK_ONBOARDING_STATES).includes(state) && state !== "done") {
            replace(`/${currentShopId}/tiktok/${TIK_TOK_ONBOARDING_STEPS[state]}`);
        } else {
            replace(`/${currentShopId}/home`);
        }
    } else {
        replace(`/${currentShopId}/tiktok/before-you-begin`);
    }
}

export const tikTokStatePolling = (shopId, redirect, additionalAction = () => {}, signal) => {
    const onCompleteCb = (res) => {
        additionalAction();
        getTikTokRedirectPage(res, shopId, redirect);
    };

    const onNotAuthCb = () => {
        redirect(ROUTES.facebookLogin)
    }

    statePollingInterval(
        fetchOnboardingState,
        ONBOARDING_TYPES.tik_tok,
        shopId,
        onCompleteCb,
        () => {},
        onNotAuthCb,
        signal
    );
};

const TikTokOnboardingMiddleware = ({ replace, children, currentShopId }) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        setLocalStorageObjectItem('currentOnboarding', ONBOARDING_TYPES.tik_tok);
        tikTokStatePolling(
            currentShopId,
            replace,
            () => {
                setIsLoading(false)
            },
            signal);
        return () => {
            controller.abort();
        }
    }, [])

    return <Wizard>{!isLoading && children}</Wizard>;
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(TikTokOnboardingMiddleware);