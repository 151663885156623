import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { CAMPAIGN_CLIENT_STATUS } from './helpers';

const EditCampaignButton = (props) => {
    const errorsList = [
        'payment-method-not-found',
        'ad-account-has-wrong-status-disabled',
        'ad-account-has-wrong-status-closed',
        'ad-account-has-wrong-status-pending_risk_review',
        'ad-account-has-wrong-status-unsettled',
        'shop-is-frozen-due-to-ad-account-is-unsettled',
        'public-page-not-found',
        'account-doesnt-have-access-to-page',
        'has-no-page-access-token',
        'shop-not-found',
        'platform-doesnt-have-access-to-shop',
        'failed-to-validate-message',
        'account-doesnt-have-access-to-business-manager',
        'business-manager-was-deleted',
        'ad-account-is-disabled-due-to-risk-payment',
        'shop-is-frozen-due-to-spend-cap-reached',
        'shop-is-frozen-due-to-pending-recurring-charge',
        'shop-is-frozen-due-to-expired-recurring-charge',
        'shop-is-frozen-due-to-cancelled-recurring-charge',
        'shop-is-frozen-due-to-declined-recurring-charge',
        'shop-is-frozen-due-to-force-paused',
        'shop-is-frozen-due-to-ra-plugin-for-wc-deactivated',
        'shop-is-frozen-due-to-subscription-canceled',
        'shop-is-frozen-due-to-subscription-spend-limit-reached'
    ];

    const isDisabled =
        props.campaignPausePending ||
        props.shopValidateRequesting ||
        props.campaignFailureError ||
        props.campaignResumePending ||
        props.collapseAdMessageInput ||
        props.clientStatus === CAMPAIGN_CLIENT_STATUS.DECLINED ||
        props.clientStatus === CAMPAIGN_CLIENT_STATUS.CREATING ||
        props.clientStatus === CAMPAIGN_CLIENT_STATUS.REJECTED ||
        errorsList.includes(props.errorToken) ||
        (props.type === 'google' && props.isDefaultGlAdExist === false);

    return (
        <Button
            id="editButton"
            innerUrl={isDisabled ? null : `/${props.shopId}/${props.campaignId}/edit`}
            disabled={isDisabled}
        >
            Edit
        </Button>
    );
};

EditCampaignButton.propTypes = {
    campaignFailureError: PropTypes.string,
    campaignId: PropTypes.number.isRequired,
    campaignPausePending: PropTypes.bool,
    campaignResumePending: PropTypes.bool,
    clientStatus: PropTypes.string.isRequired,
    collapseAdMessageInput: PropTypes.bool.isRequired,
    errorToken: PropTypes.string,
    isDefaultGlAdExist: PropTypes.bool,
    shopId: PropTypes.number.isRequired,
    shopValidateRequesting: PropTypes.bool,
    type: PropTypes.string,
};

EditCampaignButton.defaultProps = {
    errorToken: null,
    type: 'facebook',
    campaignPausePending: false,
    campaignResumePending: false,
    shopValidateRequesting: false,
    campaignFailureError: null,
    isDefaultGlAdExist: false,
};

export default EditCampaignButton;
