export const campaignCopyright = {
    visitor_retargeting: {
        title: 'Facebook Visitor Retargeting',
        description: 'Got store visitors, but no sales yet? Re-engage the window shoppers with ads that showcase your store best-sellers',
        adMessageTipText: 'This message will be shown to people who visited your store within the past 60 days'
    },
    dynamic_retargeting: {
        title: 'Facebook Dynamic Retargeting',
        description: 'Get even more sales with Dynamic Product ads (DPA). Show each of your store visitors and cart abandoners an ad with exact product(s) they viewed on your store',
        adMessageTipText: 'This message will be shown to people who viewed your products and / or added them to cart within the past 60 days'
    },
    social_retargeting: {
        title: 'Facebook Social Retargeting',
        description: 'Harness the power of your social profiles by showing ads to people who interacted with your Facebook page and/or Instagram profile',
        adMessageTipText: 'This message will be shown to people who followed, liked, or commented your store’s social profiles within the past 60 days'
    },
    ai_boosted_sales: {
        title: 'Meta AI-powered Campaign',
        description: 'This smart ad strategy maximizes effectiveness and boosts profits by combining machine learning to allocate your budget wisely, reaching both new and existing customers. This results in personalized product showcases and optimal outcomes.',
        adMessageTipText: 'This message will be displayed to both new and existing customers. We recommend including a promotional code offering a discount.'
    }
}