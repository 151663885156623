/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import api from '../middleware/api';
import onboarding from '../middleware/onboarding';
import account from '../middleware/account';
import pages from '../middleware/pages';
import campaign from '../middleware/campaign';
import intercom from '../middleware/intercom';
import hydrateLocalStorage from '../middleware/hydrateLocalStorage';
import sentryUserSetting from '../middleware/sentryUserSetting';

const configureStore = ({ preloadedState, middlewares = [] }) => {
    middlewares.push(api, intercom, onboarding, campaign, account, pages, hydrateLocalStorage, sentryUserSetting);

    return createStore(
        rootReducer,
        preloadedState,
        process.env.NODE_ENV === 'production'
            ? applyMiddleware(thunk, ...middlewares)
            : composeWithDevTools(
                  applyMiddleware(
                      thunk,
                      ...middlewares,
                      createLogger({
                          collapsed: true,
                      }),
                  ),
              ),
    );
};

export default configureStore;
