export const environment_name = (() => {
    if (window.ENVIRONMENT_NAME && window.ENVIRONMENT_NAME !== '__ENVIRONMENT_NAME__') {
        return window.ENVIRONMENT_NAME;
    }
    return 'dev';
})();

export const release_version = (() => {
    if (window.RELEASE_VERSION && window.RELEASE_VERSION !== '__RELEASE_VERSION__') {
        return window.RELEASE_VERSION;
    }
    return 'dev';
})();
