import React from 'react';
import facebookIcon from './img/facebook.svg';
import googleIcon from './img/google.svg';
import instagramIcon from './img/instagram.svg';
import './SocialIcon.css';

export const SocialIcons = ({divider}) => (
        <ul className="choose-plan__icons">
            <li className="choose-plan__icons-item">
                <img src={facebookIcon} alt="facebook icon" />
            </li>
            <li className="choose-plan__icons-item">
                <img src={instagramIcon} alt="google icon" />
            </li>
            {divider &&
                <li className="choose-plan__icons-item">
                    {divider}
                </li>
            }

            <li className="choose-plan__icons-item">
                <img src={googleIcon} alt="tiktok icon" />
            </li>
        </ul>
    );