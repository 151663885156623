import React from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

import { useGetLastActiveSubcription } from 'hooks/useGetLastActiveSubcription';
import { useResumeSubscription } from 'hooks/useResumeSubscription';

import './ResumePlanBanner.css';

export const ResumePlanBanner = ({isVisible, shopId, onResumeBtnClick, setIsLoadingTrue}) => {
    const {name, fee} = isVisible && useGetLastActiveSubcription(shopId);
    const { resumeSubscriptionFetch } = useResumeSubscription(shopId);
    return isVisible && (
        <div className='choose-plan-banner'>
            <NotificationWidget type='warning'>
                <div className="notification__title">Resume your Adwisely plan to continue advertising</div>
                <div className="notification__description">
                    All your Adwisely-powered ads have been stopped as you cancelled your Adwisely plan.
                </div>
                <div className="notification__description">The latest plan you selected was {name} with monthly fee
                    starting at ${fee}.
                </div>
                <div className="notification__description"> To resume the ads, please, resume your plan or contact
                    support to change the plan.
                </div>
                <div className="choose-plan-banner__buttons">
                    <Button
                        onClick={() => {
                            setIsLoadingTrue();
                            resumeSubscriptionFetch().then(() => {
                                onResumeBtnClick();
                            });
                        }}
                        color="primary">
                            Resume your plan
                    </Button>
                    <Button
                        color='primary'
                        url=
                            'mailto:help@adwisely.com?subject=I%20would%20like%20to%20choose%20Adwisely%20plan&body=
                    Hello%2C%20%0A%0ARecently%20I%20cancelled%20my%20Adwisely%20plan.%20Now%20I%20would%20like%20to%20resume%20my%20
                    Adwisely-powered%20ads%20and%20choose%20a%20new%20plan.%20'>
                        Contact Support
                    </Button>
                </div>

            </NotificationWidget>
        </div>
    )
}