import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { replace, goBack } from 'react-router-redux';

import { ArrowLeft } from 'components/Icons';
import BulkNotification from 'components/BulkNotification';
import Button from 'components/Button';
import ConfirmUpgradeModal from './components/ConfirmUpgradeModal';

import './UpgradeSubscriptionPlan.css';
import { SocialIcons } from '../../onboarding/components/SubscriptionPlan/components/SocialIcons/SocialIcons';
import TipsWidget from '../../../components/TipsWidget';
import {SubscriptionPlanType} from '../../../constants/subscriptionPlanType';
import {BTN_NAME} from '../../onboarding/components/SubscriptionPlan';


const version1 = 'Version 1';
const version2 = 'Version 2';
const version3 = 'BFCM 2022';
const version4 = 'Easter 2023';
const version5 = 'May 2023';
const version6 = 'Nov 2023';


const STARTER = 'Starter';
const BASIC = 'Basic';
const STANDARD = 'Standard';
const ADVANCED = 'Advanced';
const REGULAR = 'Regular';

const UpgradeSubscriptionPlan = ({
                                     currentShopId,
                                     subscriptionName,
                                     bulkNotificationContent,
                                     bulkNotificationType,
                                     goBack,
                                     shopSubscriptionVersion
                                 }) => {
    const [openModal, setOpenModal] = useState(false);
    const [upgradeToPlan, setUpgradeToPlan] = useState('');
    const [chargeFee, setChargeFee] = useState();

    useEffect(() => {
        upgradeToPlan && fetch(`/api/shop/${currentShopId}/subscription/cost_of_upgrade?new_plan=${upgradeToPlan}`)
            .then((res) => res.json())
            .then((res) => setChargeFee(res.data?.cost_of_update));
    }, [upgradeToPlan]);

    const showAvailablePlans = () => {
        const basicPlan = (
            <div className="upgrade-plan__item">
                <div className="upgrade-plan__info-wrapper">
                    <h6>Basic</h6>
                    <p className="upgrade-plan__price">$99 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $1,000*
                    </p>
                    <ul className="upgrade-plan__list">
                        <li><span>One</span> automated campaign</li>
                        <li>
                            <span>One</span> ad platform: <br />
                            <SocialIcons divider='or' />
                        </li>
                        <li>Email & chat support</li>
                        <li>Automated ad optimization</li>
                    </ul>
                </div>
                <Button className="upgrade-plan__cta-button" onClick={() => {
                    setUpgradeToPlan(BASIC);
                    setOpenModal(true);
                }}>
                    Choose Basic Plan
                </Button>
            </div>
        )

        const standardPlan = (
            <div className="choose-plan__item choose-plan__item--accent">
                <div className="choose-plan__info-wrapper">
                    <h6>Standard</h6>
                    <p className="upgrade-plan__price upgrade-plan__price--accent">$249 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $3,000*
                    </p>
                    <ul className="upgrade-plan__list">
                        <li><span>Unlimited</span> automated campaign</li>
                        <li><span>Two or more</span> ad platform: <br />
                            <SocialIcons />
                        </li>
                        <li>Email & chat support</li>
                        <li>Automated ad optimization</li>
                    </ul>
                </div>
                <Button color="green" className="upgrade-plan__cta-button" onClick={() => {
                    setUpgradeToPlan(STANDARD);
                    setOpenModal(true);
                }}>
                    Choose Standard Plan
                </Button>
            </div>
        )

        const standard99Plan = (
            <div className="upgrade-plan__item">
                <div className="upgrade-plan__info-wrapper">
                    <h6>Standard</h6>
                    <p className="upgrade-plan__price upgrade-plan__price--accent">$99 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $1,000*
                    </p>
                    <ul className="upgrade-plan__list">
                        <li><span>Unlimited</span> automated campaign</li>
                        <li><span>Two or more</span> ad platform: <br />
                            <SocialIcons />
                        </li>
                        <li>Email & chat support</li>
                        <li>Automated ad optimization</li>
                    </ul>
                </div>
                <Button color="green" className="upgrade-plan__cta-button" onClick={() => {
                    setUpgradeToPlan(STANDARD);
                    setOpenModal(true);
                }}>
                    Choose Standard Plan
                </Button>
            </div>
        )

        const advancedPlan = (
            <div className="choose-plan__item choose-plan__item--accent">
                <div className="choose-plan__info-wrapper">
                    <h6>Advanced</h6>
                    <p className="upgrade-plan__price">$499 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $6,000*
                    </p>
                    <ul className="upgrade-plan__list">
                        <li><span>Unlimited</span> automated campaigns</li>
                        <li><span>Two or more</span> ad platform: <br />
                            <SocialIcons />
                        </li>
                        <li>Email, chat & voice support</li>
                        <li>Automated ad optimization</li>
                        <li>Сustom campaigns</li>
                    </ul>
                </div>
                <Button className="upgrade-plan__cta-button" onClick={() => {
                    setUpgradeToPlan(ADVANCED);
                    setOpenModal(true);
                }}>
                    Choose Advanced Plan
                </Button>
            </div>
        )

        const advanced249Plan = (
            <div className="choose-plan__item choose-plan__item--accent">
                <div className="choose-plan__info-wrapper">
                    <h6>Advanced</h6>
                    <p className="upgrade-plan__price">$249 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $3,000*
                    </p>
                    <ul className="upgrade-plan__list">
                        <li><span>Unlimited</span> automated campaigns</li>
                        <li><span>Two or more</span> ad platform: <br />
                            <SocialIcons />
                        </li>
                        <li>Email, chat & voice support</li>
                        <li>Automated ad optimization</li>
                        <li>Сustom campaigns</li>
                    </ul>
                </div>
                <Button className="upgrade-plan__cta-button" onClick={() => {
                    setUpgradeToPlan(ADVANCED);
                    setOpenModal(true);
                }}>
                    Choose Advanced Plan
                </Button>
            </div>
        )

        const regularPlan = (
            <div className="choose-plan__item choose-plan__item--accent">
                <div className="choose-plan__info-wrapper">
                    <h6>Regular</h6>
                    <p className="choose-plan__price choose-plan__price--accent">$249 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $3,000*
                    </p>
                    <ul className="choose-plan__list">
                        <li><span>Unlimited</span>  automated campaigns <br /> across
                            <SocialIcons divider='and' />
                        </li>
                        <li>Email & chat support</li>
                        <li>Automated ad optimization</li>
                    </ul>
                </div>
                <Button color="green"
                        className="choose-plan__cta-button"
                        onClick={() => {
                            setUpgradeToPlan(REGULAR);
                            setOpenModal(true);
                        }}>
                    Choose Regular Plan
                </Button>
            </div>
        )

        const starterPlan = (
            <div className="choose-plan__item choose-plan__item--accent">
                <div className="choose-plan__info-wrapper">
                    <h6>Starter</h6>
                    <p className="choose-plan__price choose-plan__price--accent">$99 <span>/month</span></p>
                    <p className="choose-plan__description choose-plan__description--limit">
                        Ad spend limit $1,000*
                    </p>
                    <ul className="choose-plan__list">
                        <li>
                            <span>Unlimited</span> automated campaigns <br /> across
                            <SocialIcons divider='and' />
                        </li>
                        <li>Email & chat support</li>
                        <li>Automated ad optimization</li>
                    </ul>
                </div>
                <Button color="green"
                        className="choose-plan__cta-button"
                        onClick={() => {
                            setUpgradeToPlan(STARTER);
                            setOpenModal(true);
                        }}>
                    Choose Starter Plan
                </Button>
            </div>
        )

        let availablePlans = [];
        switch(shopSubscriptionVersion) {
            case version1:
                if (subscriptionName === BASIC) {
                    availablePlans = [standard99Plan, advanced249Plan];
                } else if (subscriptionName === STANDARD) {
                    availablePlans = [advanced249Plan];
                }
                break;
            case version2:
                if (subscriptionName === STARTER) {
                    availablePlans = [basicPlan, standardPlan];
                } else if (subscriptionName === BASIC) {
                    availablePlans = [standardPlan];
                }
                break;
            case version3:
                if (subscriptionName === BASIC) {
                    availablePlans = [standardPlan];
                }
                break;
            case version4:
                if (subscriptionName === BASIC) {
                    availablePlans = [standardPlan];
                }
                break;
            case version5:
                if (subscriptionName === STARTER) {
                    availablePlans = [regularPlan];
                }
                break;
            case version6:
                if (subscriptionName === STARTER) {
                    availablePlans = [regularPlan];
                }
                break;
            default:
                availablePlans = [];
        }

        return availablePlans;
    }

    let bulkNotification = null;
    if (bulkNotificationContent) {
        bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
    }

    return (
        <>
            <div className="upgrade-plan__container">
                <Button className="upgrade-plan__back-button" onClick={() => {goBack();}}>
                    <ArrowLeft /> Back
                </Button>
                <div className="upgrade-plan__wrapper">
                    <h1 className="upgrade-plan__title">Upgrade your Plan</h1>
                    <p className="upgrade-plan__subtitle">Select Adwisely plan based on your ad budget & features. You can change it later.</p>
                    <div className="upgrade-plan__main-section">
                        {showAvailablePlans().map(plan => plan)}
                    </div>
                </div>
                <ConfirmUpgradeModal
                    setOpenConfirmUpgradeModal={setOpenModal}
                    isOpen={openModal}
                    upgradeToPlan={upgradeToPlan}
                    chargeAmount={chargeFee}
                    currentShopId={currentShopId}
                    goBack={goBack}
                />
                {bulkNotification}
            </div>
        </>
    );
};

const mapStateToProps = ({ shops, shopSubscription, bulkNotification }) => ({
    currentShopId: shops?.currentShopId,
    shopSubscriptionVersion: shopSubscription.version,
    subscriptionName: shopSubscription.name,
    bulkNotificationContent: bulkNotification.notificationContent,
    bulkNotificationType: bulkNotification.notificationType,
});

const mapPropsToDispatch = {
    replace,
    goBack
};

export default connect(mapStateToProps, mapPropsToDispatch)(UpgradeSubscriptionPlan);