import React from 'react';
import PropTypes from 'prop-types';

import './ManualRedirect.css';

const ManualRedirectFromIframe = ({ url }) => (
    <div className="manual-redirect-wrapper">
        <section className="manual-redirect-section">
            <div className="manual-redirect-section__container">
                <h1 className="manual-redirect-section__title">Oops!</h1>
                <p className="manual-redirect-section__description">
                    For some reason the page wasn’t loaded. No worries, click the button below and everything should
                    work fine
                    <span role="img" aria-label="ok">
                        👌
                    </span>
                </p>
                <a className="manual-redirect-section__buttton" href={url} rel="noopener noreferrer" target="_parent">
                    Click to Continue
                </a>
            </div>
        </section>
    </div>
);

ManualRedirectFromIframe.propTypes = {
    url: PropTypes.string.isRequired,
};

export default ManualRedirectFromIframe;
