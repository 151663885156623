import React from 'react';
import PropTypes from 'prop-types';

import AlertWidget from 'components/AlertWidget';

const AcceptInvitationNotifications = ({ isVisible }) => {
    let pageAlert = null;

    if (isVisible) {
        pageAlert = (
            <AlertWidget color="info">
                <p className="alert-description__item">
                    To run and manage a campaign, first, you need to click "Accept invitation" and accept Adwisely
                    invitation to manage campaign assets.
                    <br />
                    <a
                        href={
                            'https://help.adwisely.com/install-and-configure-retargetapp/' +
                            'business-manager-and-ad-account/' +
                            'how-do-i-accept-invitation-to-manage-my-retargetapp-powered-campaign'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="primary-link alert-description__link_with-arrow"
                    >
                        I want to learn more
                    </a>
                </p>
            </AlertWidget>
        );
    }

    return pageAlert;
};

AcceptInvitationNotifications.defaultProps = {
    isVisible: false,
};

AcceptInvitationNotifications.propTypes = {
    isVisible: PropTypes.bool,
};

export default AcceptInvitationNotifications;
