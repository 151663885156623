import {
    SHOP_CAMPAIGN_SETTINGS_REQUEST,
    SHOP_CAMPAIGN_SETTINGS_SUCCESS,
    SHOP_CAMPAIGN_SETTINGS_FAILURE,
    SHOP_CAMPAIGN_SETTINGS_UPDATE_REQUEST,
    SHOP_CAMPAIGN_SETTINGS_UPDATE_SUCCESS,
    SHOP_CAMPAIGN_SETTINGS_UPDATE_FAILURE,
    CREATE_CHILD_BM_REQUEST,
    CREATE_AD_ACCOUNT_IN_CHILD_BM_REQUEST,
    CREATE_CHILD_BM_SUCCESS,
    CREATE_AD_ACCOUNT_IN_CHILD_BM_SUCCESS,
    CREATE_CHILD_BM_FAILURE,
    CREATE_AD_ACCOUNT_IN_CHILD_BM_FAILURE,
} from 'actions/shopCampaignsSettings';

const initialState = {
    entity: null,
    error: null,
    pending: false,
    success: false,
    failure: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SHOP_CAMPAIGN_SETTINGS_REQUEST:
            state = {
                ...initialState,
                pending: true,
            };
            break;
        case SHOP_CAMPAIGN_SETTINGS_SUCCESS:
            state = {
                ...initialState,
                entity: payload,
                success: true,
            };
            break;
        case SHOP_CAMPAIGN_SETTINGS_FAILURE:
            state = {
                ...initialState,
                error: payload,
                failure: true,
            };
            break;

        case SHOP_CAMPAIGN_SETTINGS_UPDATE_REQUEST:
        case CREATE_CHILD_BM_REQUEST:
        case CREATE_AD_ACCOUNT_IN_CHILD_BM_REQUEST:
            state = {
                ...state,
                pending: true,
            };
            break;

        case SHOP_CAMPAIGN_SETTINGS_UPDATE_SUCCESS:
        case CREATE_CHILD_BM_SUCCESS:
        case CREATE_AD_ACCOUNT_IN_CHILD_BM_SUCCESS:
            state = {
                ...state,
                pending: false,
                entity: {
                    ...state.entity,
                    ...payload,
                },
            };
            break;

        case SHOP_CAMPAIGN_SETTINGS_UPDATE_FAILURE:
        case CREATE_CHILD_BM_FAILURE:
        case CREATE_AD_ACCOUNT_IN_CHILD_BM_FAILURE: {
            let entity = state.entity;
            if (payload.errorData) {
                entity = {
                    ...state.entity,
                    ...payload.errorData,
                };
            }
            state = {
                ...state,
                entity,
                pending: false,
                error: payload,
            };
            break;
        }
        default:
            break;
    }
    return state;
};
