export const formatMonth = (month) => (month > 9 ? month : `0${month}`);

export const getCardLogo = (brand) => {
    switch (brand) {
        case 'Visa':
            return 'card-info__visa';
        case 'MasterCard':
            return 'card-info__mastercard';
        case 'American Express':
            return 'card-info__american-express';
        case 'Discover':
            return 'card-info__discover';
        case 'Diners Club':
            return 'card-info__diners-club';
        case 'JCB':
            return 'card-info__jcb';
        case 'UnionPay':
            return 'card-info__union-pay';
        default:
            return null;
    }
};
