/**
 * RECOMMENDED_DB_APPROVED Constant that uses as a key to localStorage
 * for storing campaign ids that approved recommended DB with a date.
 */
export const RECOMMENDED_DB_APPROVED = 'recommendedDBApproved';

export const WILL_BACK_SOON_PAUSE_REASON = 'WILL_BACK_SOON_PAUSE_REASON';
export const HELP_WITH_SETTINGS_PAUSE_REASON = 'HELP_WITH_SETTINGS_PAUSE_REASON';
export const CAMPAIGN_CAN_DO_BETTER_PAUSE_REASON = 'CAMPAIGN_CAN_DO_BETTER_PAUSE_REASON';
export const OTHER_PAUSE_REASON = 'OTHER_PAUSE_REASON';

export const DOES_NOT_SHOW_ANY_RESULT_PAUSE_REASON = 'DOES_NOT_SHOW_ANY_RESULT_PAUSE_REASON';
export const DO_NOT_UNDERSTAND_APP_WORK_PAUSE_REASON = 'DO_NOT_UNDERSTAND_APP_WORK_PAUSE_REASON';
export const HELP_WITH_APP_SETTINGS_PAUSE_REASON = 'HELP_WITH_APP_SETTINGS_PAUSE_REASON';
export const DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON = 'DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON';

export const BEFORE_LEARNING_PHASE_ENDED_REASON = 'BEFORE_LEARNING_PHASE_ENDED_REASON';
