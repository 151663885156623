export const PREVIEW_TYPE_DESKTOP_FEED_STANDARD = 'DESKTOP_FEED_STANDARD';
export const PREVIEW_TYPE_RIGHT_COLUMN_STANDARD = 'RIGHT_COLUMN_STANDARD';
export const PREVIEW_TYPE_MOBILE_FEED_STANDARD = 'MOBILE_FEED_STANDARD';
export const PREVIEW_TYPE_INSTANT_ARTICLES_STANDARD = 'INSTANT_ARTICLE_STANDARD';
export const PREVIEW_TYPE_MARKETPLACE_MOBILE = 'MARKETPLACE_MOBILE';
export const PREVIEW_TYPE_FACEBOOK_STORY_MOBILE = 'FACEBOOK_STORY_MOBILE';
export const PREVIEW_TYPE_INSTAGRAM_STORY = 'INSTAGRAM_STORY';
export const PREVIEW_TYPE_INSTAGRAM_STANDARD = 'INSTAGRAM_STANDARD';
export const PREVIEW_TYPE_MESSENGER_MOBILE_INBOX_MEDIA = 'MESSENGER_MOBILE_INBOX_MEDIA';

export const pageTypes = {
    retargetingCreate: 'Create retargeting campaign: buttons',
    retargetingEdit: 'Edit retargeting campaign: buttons',
    specialOfferCreate: 'Create special offer campaign: buttons',
    specialOfferEdit: 'Edit special offer campaign: buttons',
};

export const sourcePage = {
    createCampaign: 'create_campaign',
    editCampaign: 'edit_campaign',
};
