import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { logException } from 'libs/sentryConfig';

import { ONBOARDING_TYPES } from 'constants/onboarding';
import { changeOnboardingState } from 'modules/home/utils';
import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';
import Button from 'components/Button';
import { AdAccountDropdown } from 'modules/onboarding/components/AdAccountDropdown';
import { replace } from "react-router-redux";
import { setGoogleOnboardingState } from "actions/googleOnboardingState";

import './ConnectGoogleAdsAccount.css';
import { googleStatePolling } from "../../helpers/googleStatePolling";


const ConnectGoogleAdsAccount = ({ currentShopId, googleOnboardingState, setGoogleOnboardingState, redirect }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdAccountsPending, setIsAdAccountsPending] = useState(false);
    const [selectedAdAccountId, setSelectedAdAccountId] = useState(null);
    const [adAccounts, setAdAccounts] = useState([]);

    useEffect(() => {
        setIsAdAccountsPending(true);
        fetch(`/api/onboarding/${currentShopId}/google/ad_accounts`)
            .then((res) => {
                if (!res.ok) {
                    const err = new Error(`Status code: ${res.status}`);
                    err.status = res.status;
                    err.statusText = res.statusText;
                    throw err;
                }
                return res.json();
            })
            .then((res) => {
                if (!(res instanceof Error)) {
                    (res?.length === 1) ? setSelectedAdAccountId(res[0]?.gl_id) : null;
                    setAdAccounts(res);
                    setIsAdAccountsPending(false);
                }
            })
            .catch((error) => {
                logException(
                    `Couldn't fetch Google Ad Accounts. Reason: ${error.statusText}`,
                    error,
                    { currentShopId },
                    'javascript',
                    'error',
                );
            });
    }, []);

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES.google,
            status: googleOnboardingState,
            currentShopId,
            data: { ad_account_gl_id: selectedAdAccountId },
        }).then((res) => {
            if (res.status === 409) {
                googleStatePolling(currentShopId, redirect, setGoogleOnboardingState)
            }
            return res.json()
        })
            .then((res) => {
                if (res?.processing) {
                    googleStatePolling(currentShopId, redirect, setGoogleOnboardingState);
                }
            });
    }

    const onChange = (adAccountId) => setSelectedAdAccountId(adAccountId);

    const chosenAdAccount = adAccounts?.find((adAccount) => +adAccount?.gl_id === +selectedAdAccountId);

    return (
        <div className="connect-gl-ad-account__wrapper">
            <h1 className="connect-gl-ad-account__title">Please choose an account</h1>

            <div className="connect-gl-ad-account__body-wrapper">
                <AdAccountDropdown
                    openDropdown
                    title="Choose Ad Account"
                    type="default"
                    renderContent={() => (<>
                        <p>Select Ad Account from the following list to continue with onboarding.</p>
                        {!isAdAccountsPending ? (
                            <DropdownProvider>
                                {({ toggle, isOpen }) => {
                                    const dropdownItems = adAccounts?.map((adAccount, key) => (
                                        <DropdownItemMain
                                            key={key}
                                            value={adAccount.gl_id}
                                            onClick={() => {
                                                onChange(adAccount.gl_id);
                                                toggle();
                                            }}
                                            active={+adAccount.gl_id === +selectedAdAccountId}
                                            title={`${adAccount.name}` || "Google Ads Account"}
                                            subtitle={`ID: ${adAccount.gl_id}`}
                                        />
                                    ));

                                    const flagToggler = (
                                        <FlagToggler
                                            title={chosenAdAccount ? chosenAdAccount.name || "Google Ads Account" : null}
                                            subtitle={chosenAdAccount ? `ID: ${chosenAdAccount.gl_id}` : null}
                                            caseIcon
                                        >
                                            Select Ad Account
                                        </FlagToggler>
                                    )

                                    return (
                                        <Dropdown toggle={toggle} isOpen={isOpen} flagToggler={flagToggler} items={dropdownItems} />
                                    )
                                }}
                            </DropdownProvider>
                        ) : 'Loading...'}
                    </>)}
                />
            </div>

            <Button onClick={handleContinueBtn} color="primary" pending={isLoading} disabled={isAdAccountsPending}>
                Continue
            </Button>

        </div>
    );
};

const mapStateToProps = ({ google, shops }) => ({ 
    googleOnboardingState: google.googleOnboardingState,
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    redirect: replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export default connect(mapStateToProps, mapPropsToDispatch)(ConnectGoogleAdsAccount);


