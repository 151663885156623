import { replace } from 'react-router-redux';

import { ROUTES } from 'constants/routes';
import withData from 'decorator/withStateData';

import NoShops from './NoShops';

export default withData(
    NoShops,
    ['account'],
    (data) => data.account.entity,
    () => (dispatch) => dispatch(replace(ROUTES.facebookLogin)),
);
