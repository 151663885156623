import * as pages from '../../actions/facebook/pages';

const initialState = {
    entities: [],
    fbPagesRequesting: false,
    fbPagesRequestSuccess: false,
    fbPagesRequestError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case pages.FB_PAGES_REQUEST:
            state = {
                ...state,
                fbPagesRequesting: true,
                fbPagesRequestSuccess: false,
                fbPagesRequestError: false,
            };
            break;

        case pages.FB_PAGES_REQUEST_SUCCESS:
            state = {
                ...state,
                entities: action.payload,
                fbPagesRequesting: false,
                fbPagesRequestSuccess: true,
                fbPagesRequestError: false,
            };
            break;

        case pages.FB_PAGES_REQUEST_FAILURE:
            state = {
                ...state,
                fbPagesRequesting: false,
                fbPagesRequestSuccess: false,
                fbPagesRequestError: true,
            };
            break;

        case pages.FB_PAGES_PENDING:
            state = {
                ...state,
                fbPagesRequesting: true,
                fbPagesRequestSuccess: false,
                fbPagesRequestError: false,
            };
            break;

        case pages.FB_PAGES_SUCCESS:
            state = {
                ...state,
                entities: action.payload,
                fbPagesRequesting: false,
                fbPagesRequestSuccess: true,
                fbPagesRequestError: false,
            };
            break;

        case pages.FB_PAGES_FAILURE:
            state = {
                ...state,
                fbPagesRequesting: false,
                fbPagesRequestSuccess: false,
                fbPagesRequestError: true,
            };
            break;

        default:
            break;
    }
    return state;
};
