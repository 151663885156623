import PropTypes from "prop-types"
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { changeAccountEmail } from 'actions/account';

import Button from 'components/Button';
import { Card, CardContent, CardTitle } from 'components/Card';

import './ChangeEmail.css';
import submitIcon from './check.svg';
import cancelIcon from './close-icon.svg';

const getInitialState = (initialValue) => ({
    emailInput: {
        value: initialValue,
        valid: false,
        touched: false,
    },
    editEmailStatus: false,
    verifyEmail: null,
});

class ChangeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = getInitialState(props.userEmail);
        this.emailInput = React.createRef();
        this.onEditEmail = this.onEditEmail.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidUpdate() {
        if (this.emailInput && this.emailInput.current) this.emailInput.current.focus();
    }

    onEditEmail() {
        this.setState({ editEmailStatus: true });
    }

    validateInput(event) {
        const emailValue = event.target.value;
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const isValid = pattern.test(emailValue);
        this.setState(({ emailInput }) => ({
            emailInput: {
                ...emailInput,
                value: emailValue,
                valid: isValid,
                touched: true,
            },
        }));
    }

    resetForm() {
        this.setState(getInitialState(this.props.userEmail));
    }

    submitForm() {
        const { value, valid } = this.state.emailInput;
        const { changeAccountEmail, currentShopId } = this.props;

        if (valid) {
            changeAccountEmail({
                'change-email-action': true,
                shop_id: currentShopId,
                email: value,
            }).then((data) => {
                if (!(data instanceof Error)) {
                    this.resetForm();
                    this.setState({ verifyEmail: value });
                } else {
                    this.setState((state) => ({
                        emailInput: {
                            ...state.emailInput,
                            valid: false,
                            touched: true,
                        },
                    }));
                }
            });
        }
    }

    render() {
        const { emailInput, editEmailStatus, verifyEmail } = this.state;
        const {
            userEmail,
            accountInfo: { id: currentAccountId },
            shopNotifications,
        } = this.props;
        let emailField = (
            <span role="button" className="change-email-show-email" onClick={this.onEditEmail}>
                {userEmail}
            </span>
        );
        const invalidInputValue = !emailInput.valid && emailInput.touched;
        const formError = invalidInputValue ? 'Enter a valid email address' : '';
        const currentNotification = shopNotifications.find((notification) => notification.type === 'change_user_email');
        const verifyEmailBlock =
            verifyEmail ||
            (currentNotification !== undefined &&
                JSON.parse(currentNotification.params).account_id === currentAccountId) ? (
                    <div className="settings-page__block">
                        <div className=" verify-email__wrapper">
                            <div className="verify-email__aside" />
                            <div className="verify-email__main">
                                <p>
                                We sent an email to {''}
                                    <strong>
                                        {verifyEmail === null
                                            ? JSON.parse(currentNotification.params).new_email
                                            : verifyEmail}
                                    </strong>
                                . Please, open it and click “Verify email”.
                                </p>
                            </div>
                        </div>
                    </div>
                ) : null;

        if (editEmailStatus) {
            emailField = (
                <form
                    className="change-email__form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.submitForm();
                    }}
                >
                    <div>
                        <label
                            htmlFor="change-email__form-input"
                            className={classNames('daily-budget-input', {
                                'daily-budget-input_error': invalidInputValue,
                            })}
                        >
                            <input
                                id="change-email__form-input"
                                ref={this.emailInput}
                                value={emailInput.value}
                                onChange={this.validateInput}
                                className="change-email__form-input daily-budget-input__input"
                                type="email"
                                placeholder="Enter your email"
                            />
                        </label>
                        <p className="change-email__form-error">{formError}</p>
                    </div>
                    <Button
                        onClick={this.submitForm}
                        className="change-email__form-submit"
                        disabled={invalidInputValue || emailInput.value === userEmail}
                    >
                        <img src={submitIcon} alt="Submit" />
                    </Button>
                    <Button onClick={this.resetForm} className="change-email__form-cancel">
                        <img src={cancelIcon} alt="Cancel" />
                    </Button>
                </form>
            );
        }
        return (
            <Card disablePadding className="settings-page__container">
                {verifyEmailBlock}
                <div className="settings-page__block">
                    <CardTitle>Contact Info</CardTitle>
                    <CardContent>
                        Below is your contact information used for sending the relevant notifications and updates.
                    </CardContent>
                </div>
                <div className="settings-page__block">
                    <div className={!invalidInputValue ? 'change-email__wrapper' : 'change-email__wrapper-error'}>
                        <span>Email</span>
                        {emailField}
                    </div>
                </div>
            </Card>
        );
    }
}

ChangeEmail.defaultProps = {
    accountInfo: {
        email: '',
    },
    shopNotifications: [],
};

ChangeEmail.propTypes = {
    accountInfo: PropTypes.shape({
        email: PropTypes.string
    }),
    changeAccountEmail: PropTypes.func.isRequired,
    currentShopId: PropTypes.string.isRequired,
    shopNotifications: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
        }),
    ),
};

const mapStateToProps = ({ account, shops, shopNotifications }) => ({
    accountInfo: account.entity,
    currentShopId: shops.currentShopId,
    shopNotifications: shopNotifications.entity,
});

const mapDispatchToProps = {
    changeAccountEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
