import React from 'react';
import { ToDoListStatus } from '../ToDoListConstants';
import { ReactComponent as CompleteIcon } from '../icons/complete-icon.svg';
import { ReactComponent as VerificationIcon } from '../icons/verification-icon.svg';

export const ToDoListItemIcon = ({ status }) => {
    switch (status) {
        case ToDoListStatus.complete:
            return <CompleteIcon />
        case ToDoListStatus.verification:
            return <VerificationIcon />
        default:
            return <div className="todo-list__dot" />
    }
}