import React, { useRef } from 'react';

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { ONBOARDING_TYPES } from "constants/onboarding";

// icons
import googleIcon from 'modules/home/images/google-icon-card.svg';
import anIcon from 'modules/home/images/an-icon-card.svg';
import facebookIcon from 'modules/home/images/facebook-icon-card.svg';
import instagramIcon from 'modules/home/images/instagram-icon-card.svg';
import tikTokIcon from 'modules/home/images/tiktok-icon-card.svg';
import youtubeIcon from 'modules/home/images/youtube-icon-card.svg';
import messengerIcon from 'modules/home/images/messenger-icon-card.svg';
import gmailIcon from 'modules/home/images/google-mail-icon-card.png';

import './PreviewSlider.css';

// meta desktop
import { FacebookDesktop } from "./components/FacebookDesktop/FacebookDesktop";
import { InstagramDesktop } from "./components/InstagramDesktop/InstagramDesktop";

// meta mobile
import { MessengerMobile } from "./components/MessengerMobile/MessengerMobile";
import { AudienceNetworkMobile} from "./components/AudienceNetworkMobile/AudienceNetworkMobile";
import { InstagramStoriesMobile } from "./components/InstagramStoriesMobile/InstagramStoriesMobile";
import { InstagramMobile } from "./components/InstagramMobile/InstagramMobile";

// google desktop
import { GmailDesktop } from "./components/GmailDesktop/GmailDesktop";
import { GoogleDisplayDesktop } from "./components/GoogleDisplayDesktop/GoogleDisplayDesktop";
import { YoutubeDesktop } from "./components/YoutubeDesktop/YoutubeDesktop";
import { GoogleSearchDesktop } from "./components/GoogleSearchDesktop/GoogleSearchDesktop";
import { GoogleMapsDesktop } from "./components/GoogleMapsDesktop/GoogleMapsDesktop";

// google mobile
import { GmailMobile } from "./components/GmailMobile/GmailMobile";
import { GoogleDisplayMobile } from "./components/GoogleDisplayMobile/GoogleDisplayMobile";
import { GoogleSearchMobile } from "./components/GoogleSearchMobile/GoogleSearchMobile";
import { GoogleMapsMobile } from "./components/GoogleMapsMobile/GoogleMapsMobile";


// tiktok mobile
import { TikTokMobile } from "./components/TikTokMobile/TikTokMobile";

const slides = (product) => ([
        {
            name: 'facebook',
            mobileSlides: [
                {
                    component: <FacebookDesktop product={product} />,
                    icon: facebookIcon,
                    descr: 'Facebook Desktop News Feed'
                },
                {
                    component: <InstagramMobile product={product} />,
                    icon: instagramIcon,
                    descr: 'Instagram Mobile Feed'
                },
                {
                    component: <InstagramStoriesMobile product={product} />,
                    icon: instagramIcon,
                    descr: 'Instagram Stories'
                },
                {
                    component: <MessengerMobile  product={product} />,
                    icon: messengerIcon,
                    descr: 'Messenger Inbox'
                },
                {
                    component: <AudienceNetworkMobile product={product} />,
                    icon: anIcon,
                    descr: 'Audience Network Interstinal'
                },
            ],
            desktopSlides: [
                {
                    component: <FacebookDesktop product={product} />,
                    icon: facebookIcon,
                    descr: 'Facebook Desktop News Feed'
                },
                {
                    component: <InstagramDesktop product={product} />,
                    icon: instagramIcon,
                    descr: 'Instagram Desktop Feed'
                },
            ]
        },
        {
            name: 'tiktok',
            mobileSlides: [
                {
                    component: <TikTokMobile product={product} />,
                    icon: tikTokIcon,
                    descr: 'TikTok'
                },
            ],
            desktopSlides: []
        },
        {
            name: 'google',
            mobileSlides: [
                {
                    component: <GmailMobile product={product} />,
                    icon: gmailIcon,
                    descr: 'Gmail'
                },
                {
                    component: <GoogleDisplayMobile product={product} />,
                    icon: googleIcon,
                    descr: 'Google Display Network'
                },
                {
                    component: <GoogleSearchMobile product={product} />,
                    icon: googleIcon,
                    descr: 'Google Search'
                },
                {
                    component: <GoogleMapsMobile />,
                    icon: googleIcon,
                    descr: 'Google Maps'
                },
            ],
            desktopSlides: [
                {
                    component: <GmailDesktop product={product} />,
                    icon: gmailIcon,
                    descr: 'Gmail'
                },
                {
                    component: <GoogleDisplayDesktop product={product} />,
                    icon: googleIcon,
                    descr: 'Google Display Network'
                },
                {
                    component: <YoutubeDesktop product={product} />,
                    icon: youtubeIcon,
                    descr: 'Youtube'
                },
                {
                    component: <GoogleSearchDesktop product={product} />,
                    icon: googleIcon,
                    descr: 'Google Search'
                },
                {
                    component: <GoogleMapsDesktop product={product} />,
                    icon: googleIcon,
                    descr: 'Google Maps'
                }
            ]
        }
    ])

const PreviewSlider = ({currentType, isMobile, product}) => {
    const swiperRef = useRef();
    const type =
        (currentType === ONBOARDING_TYPES.facebook_prospecting ||
        currentType === ONBOARDING_TYPES.facebook_childbm ||
        currentType === ONBOARDING_TYPES.facebook_visitor_retargeting ||
        currentType === ONBOARDING_TYPES.facebook_social_media_retargeting ||
        currentType === ONBOARDING_TYPES.facebook_special_offer ||
        currentType === ONBOARDING_TYPES.facebook_dynamic_retargeting) ||
        currentType === ONBOARDING_TYPES.facebook_ai_boosted_sales ? 'facebook' : currentType

    const currentSlides = slides(product)
        .filter(item => item.name === type)
        .map(item => isMobile ? item.mobileSlides : item.desktopSlides)

    return (
        <div className='preview-slider-container'>
            <div className='preview-slider-back-btn' onClick={() => swiperRef.current.slidePrev()}>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.0275 23.4725L16.0658 18.5L21.0275 13.5275L19.5 12L13 18.5L19.5 25L21.0275 23.4725Z" fill="white"/>
                    <mask id="mask0_11932_229659" maskUnits="userSpaceOnUse" x="13" y="12" width="9" height="13">
                        <path d="M21.0275 23.4725L16.0658 18.5L21.0275 13.5275L19.5 12L13 18.5L19.5 25L21.0275 23.4725Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_11932_229659)">
                        <g opacity="0.37">
                            <rect x="-44" y="-44" width="120" height="120" fill="#00223D"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="preview-slider-next-btn" onClick={() => swiperRef.current.slideNext()}>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9998 23.4725L19.9615 18.5L14.9998 13.5275L16.5273 12L23.0273 18.5L16.5273 25L14.9998 23.4725Z" fill="white"/>
                    <mask id="mask0_11932_229660" maskUnits="userSpaceOnUse" x="15" y="12" width="9" height="13">
                        <path d="M14.9998 23.4725L19.9615 18.5L14.9998 13.5275L16.5273 12L23.0273 18.5L16.5273 25L14.9998 23.4725Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_11932_229660)">
                        <g opacity="0.37">
                            <rect x="-44" y="-44" width="120" height="120" fill="#00223D"/>
                        </g>
                    </g>
                </svg>
            </div>
            <Swiper
                spaceBetween={16}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                className="swiper-preview"
                modules={[Autoplay]}
                autoplay={
                    {
                        delay: 4000,
                        disableOnInteraction: false
                    }
                }
            >
                {currentSlides[0] && currentSlides[0].map(slide => (
                    <SwiperSlide key={slide.descr}>
                        <div className='preview-slide-wrapper'>
                            {slide.component}
                        </div>
                        <div className='preview-slide-description'>
                            <div className='preview-slide-icon'>
                                <img src={slide.icon} alt="icon"/>
                            </div>
                            <div className='preview-slide-text'>{slide.descr}</div>
                        </div>
                    </SwiperSlide>
                ) )}
            </Swiper>
        </div>
    )
}

export default PreviewSlider;
