import { FEE_RATES, YEAR_OF_FIRST_RA_BILLING } from './constants';

export const convertDatePeriodToString = (startDate, endDate) => {
    const convertToMonthAndDayFormat = (date) =>
        `${new Date(date).toLocaleString('en-us', { month: 'short' })} ${new Date(date).getDate()}`;

    return `${convertToMonthAndDayFormat(startDate)} — ${convertToMonthAndDayFormat(endDate)}`;
};

export const getListOfYears = () => {
    const list = [];
    for (let year = +YEAR_OF_FIRST_RA_BILLING; year <= new Date().getFullYear(); year += 1) list.push(year);
    return list;
};

export const sortDataByDate = (dataToFormat) => {
    const getTimeStamp = (date) => {
        const newDate = date.split('-');
        return new Date(newDate[0], newDate[1] - 1, newDate[2]).getTime();
    };
    return dataToFormat.sort((a, b) => getTimeStamp(b.started_at) - getTimeStamp(a.started_at));
};

export const formatDataForTableRender = (dataArray) => {
    if (!dataArray) return [];
    return dataArray
        .map((dataItem, index) => {
            const currentDate = convertDatePeriodToString(dataItem.started_at, dataItem.ended_at);
            const currentDateCampaignType = dataItem.campaign_type;
            const currentDateFeeRate = FEE_RATES[dataItem.fee_rate];
            const currentDateFeeAmount = dataItem.fee_amount;

            let previousDate = null;
            let previousDateCampaignType = null;
            let previousDateFeeRate = null;
            let previousDateFeeAmount = null;
            if (index > 0) {
                const previousItem = dataArray[index - 1];
                previousDate = convertDatePeriodToString(previousItem.started_at, previousItem.ended_at);
                previousDateCampaignType = previousItem.campaign_type;
                previousDateFeeRate = FEE_RATES[previousItem.fee_rate];
                previousDateFeeAmount = previousItem.fee_amount;
            }
            const ifDatesEqual = currentDate === previousDate;
            const getArrayOfInfo = (prevInfo, currInfo) => (ifDatesEqual ? [prevInfo, currInfo] : [currInfo]);
            return {
                endedAtYear: new Date(dataItem.ended_at).getFullYear(),
                datePeriod: currentDate,
                campaignTypes: getArrayOfInfo(previousDateCampaignType, currentDateCampaignType),
                feeRate: getArrayOfInfo(previousDateFeeRate, currentDateFeeRate),
                feeAmount: getArrayOfInfo(previousDateFeeAmount, currentDateFeeAmount),
                currency: dataItem.currency,
            };
        })
        .filter((el, index, array) => {
            const nextElement = array[index + 1];
            return !(nextElement && nextElement.datePeriod === el.datePeriod);
        });
};
