import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { metricType } from 'constants/metric';
import { requestSendEventMetrics } from 'actions/shop';

import { useFacebookBillingSession } from '../hooks/useFacebookBillingSession';
import './PaymentReview.css';

const PaymentReview = ({ shopError, shopId, requestSendEventMetrics }) => {
    const { savePreviewPaymentStatus } = useFacebookBillingSession();

    const handleAddPaymentMethod = () => {
        window.FB.ui({
            account_id: shopError?.errorData?.ad_account_id,
            display: 'popup',
            method: 'ads_payment',
        }, () => {});

        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_addcard_add_paymethod',
            },
        };
        
        requestSendEventMetrics(metricProps);
        savePreviewPaymentStatus();
    };

    return (
        <div className="payment-review__wrapper">
            <h2 className="payment-review__caption">Review payment info: Facebook Ad Spend</h2>
            <p className="payment-review__subcaption">
                You will pay for Ad Spend directly to <span>Facebook</span>
            </p>
            <p className="payment-review__description">
                To run ads for you, Adwisely has created a brand new ad account. Please, connect a payment method to
                it
            </p>
            <a
                className="payment-review__link"
                href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-retargetapp"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn more
            </a>
            <Button onClick={handleAddPaymentMethod} color="primary">
                Add Payment Method
            </Button>
        </div>
    );
};

PaymentReview.propTypes = {
    shopId: PropTypes.string.isRequired,
}

const mapDispatchToProps = {
    requestSendEventMetrics,
};

export default connect(null, mapDispatchToProps)(PaymentReview);
