import * as inframe from 'actions/inframe';

const initialState = {
    inframe: false,
    platformType: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case inframe.ON_INFRAME_TRUE:
            state = {
                ...state,
                inframe: true,
            };
            break;
        case inframe.ON_INFRAME_FALSE:
            state = {
                ...state,
                inframe: false,
            };
            break;
        case inframe.ON_INFRAME_SET_PLATFORM_TYPE:
            state = {
                ...state,
                platformType: action.platformType,
            };
            break;
        default:
            break;
    }
    return state;
};
