import PropTypes from "prop-types"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestEditGoogleCampaign, requestGoogleCampaigns, goToDashboard } from 'actions/campaign';
import { showBulkNotification, displayBulkNotification } from 'actions/notification';

import pageNameSetter from 'decorator/pageNameSetter';

import { getBulkNotificationContentSelector, getBulkNotificationTypeSelector } from 'helpers/selectors';

import BulkNotification from 'components/BulkNotification';
import TipsWidget from 'components/TipsWidget';
import { CardTitle } from 'components/Card';

import { changeShopPage } from 'constants/metric';

import { CampaignPageLayout, CampaignPageCol } from '../components/CampaignPageLayout';
import CampaignInfo from '../components/CampaignInfo/index';
import GoogleRemarketingAdPreviewCard from '../components/GoogleRemarketingAdPreview/GoogleRemarketingAdPreviewCard';
import EditCampaignDetails from '../components/EditCampaignDetails/index';
import EditRemarketingAdsForm from '../components/EditRemarketingAdsForm/';

import './RemarketingAdsEdit.css';

export class RemarketingAdsEdit extends Component {
    state = {
        // save campaign details
        hasCampaignFormError: false,
        tipsErrorText: '',

        // default values
        campaignMinBudget: 25,
        campaignCurrency: 'USD',
        campaignDailyBudget: this.props.campaign ? this.props.campaign.daily_budget / 100 : 0,
        campaignLongHeadline: this.props.campaignAd ? this.props.campaignAd.long_headline : '',
        campaignShortHeadline: this.props.campaignAd ? this.props.campaignAd.short_headline : '',
        campaignDescription: this.props.campaignAd ? this.props.campaignAd.description : '',
        campaignFinalUrl: this.props.campaignAd ? this.props.campaignAd.final_url : '',
    };

    saveCampaign = (dailyBudget, longHeadline, shortHeadline, finalUrl, description) => {
        const {
            requestEditGoogleCampaign,
            requestGoogleCampaigns,
            goToNextStep,
            params: { shopId, id },
        } = this.props;

        const params = {
            campaignId: +id,
            shopId,
            daily_budget: dailyBudget,
            short_headline: shortHeadline,
            long_headline: longHeadline,
            final_url: finalUrl,
            description,
        };

        requestEditGoogleCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                this.props.showBulkNotification('Campaign settings updated.');
                requestGoogleCampaigns(shopId);
                goToNextStep(shopId);
            } else {
                this.props.displayBulkNotification('Campaign edit failure!', 'error');
            }
        });
    };

    handleAdPreviewChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleFormError = (hasCampaignFormError) => {
        this.setState({
            hasCampaignFormError,
        });
    };

    render() {
        const {
            campaignEditPending,
            params: { shopId, id },
            bulkNotificationContent,
            bulkNotificationType,
            googleRemarketingAdPreviewData,
        } = this.props;
        const {
            campaignDescription,
            campaignFinalUrl,
            campaignCurrency,
            campaignMinBudget,
            campaignDailyBudget,
            campaignLongHeadline,
            campaignShortHeadline,
        } = this.state;

        let bulkNotification = null;

        if (bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        const currentCampaignAdPreview =
            googleRemarketingAdPreviewData &&
            googleRemarketingAdPreviewData.find((adPreview) => adPreview.campaign_id === +id);

        return (
            <div className="remarketing-ads-edit__wrapper">
                <CampaignPageLayout>
                    <CampaignPageCol>
                        <EditRemarketingAdsForm
                            formName="remarketingAdsEdit"
                            campaignLongHeadline={campaignLongHeadline}
                            campaignShortHeadline={campaignShortHeadline}
                            campaignDescription={campaignDescription}
                            campaignFinalUrl={campaignFinalUrl}
                            campaignCurrency={campaignCurrency}
                            campaignMinBudget={campaignMinBudget}
                            campaignDailyBudget={campaignDailyBudget}
                            disabled={campaignEditPending}
                            handleAdPreviewChange={this.handleAdPreviewChange}
                            saveCampaign={this.saveCampaign}
                            handleFormError={this.handleFormError}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <div className="remarketing-ads-edit__ad-preview">
                            <CardTitle className="campaign-details__title" labelFor="campaignAdPreview" subTitle>
                                <span>Ad Preview</span>
                                <TipsWidget placement="right" isDarkView>
                                    <p className="tips-description__content">
                                        This is how your 480x120 Remarketing Shopping ad looks to your potential
                                        customers. If you would like to see the previews of this ad in other sizes or
                                        want to change the image featured in the ad, please, message us.
                                    </p>
                                    <p className="tips-description__content">
                                        <a
                                            id="open_chat"
                                            href="mailto:help@adwisely.com"
                                            className="link link-white"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Send us a message →
                                        </a>
                                    </p>
                                </TipsWidget>
                            </CardTitle>
                            <p>Please, contact our support team if you want to change the image of the ad</p>
                            {currentCampaignAdPreview && (
                                <GoogleRemarketingAdPreviewCard
                                    imageLink={currentCampaignAdPreview.image_link}
                                    headline={campaignShortHeadline}
                                    shopUrl={campaignFinalUrl}
                                />
                            )}
                        </div>

                        <CampaignInfo
                            showPricing={false}
                            showIcon={false}
                            campaignTitle="Smart Shopping Ads"
                            learnMoreLink="https://help.adwisely.com/en/articles/3343845-smart-shopping"
                            campaignDescription="Re-engage your site visitors who didn’t make a purchase with personalized ads of the previously viewed products."
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <EditCampaignDetails
                    formName="remarketingAdsEdit"
                    hasCampaignFormError={this.state.hasCampaignFormError}
                    shopId={shopId}
                    campaignEditPending={campaignEditPending}
                    tipsErrorText={this.state.tipsErrorText}
                />
                {bulkNotification}
            </div>
        );
    }
}

RemarketingAdsEdit.defaultProps = {
    bulkNotificationContent: null,
    bulkNotificationType: null,
    campaign: null,
    campaignAd: null,
};

RemarketingAdsEdit.propTypes = {
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    campaign: PropTypes.shape({
        daily_budget: PropTypes.number
    }),
    campaignAd: PropTypes.shape({
        description: PropTypes.string,
        final_url: PropTypes.string,
        long_headline: PropTypes.string,
        short_headline: PropTypes.string,
    }),
    campaignEditPending: PropTypes.bool.isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    googleRemarketingAdPreviewData:  PropTypes.arrayOf(
        PropTypes.shape({
            campaign_id: PropTypes.number,
        }),
    ).isRequired,
    requestEditGoogleCampaign: PropTypes.func.isRequired,
    requestGoogleCampaigns: PropTypes.func.isRequired,
    showBulkNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    campaign: state.googleCampaign.googleCampaignData,
    campaignAd:
        state.googleCampaign.googleCampaignData &&
        state.googleCampaign.googleCampaignData.ads.find(
            (ad) => ad.type === state.googleCampaign.googleCampaignData.default_ad_type,
        ),
    campaignEditPending: state.googleCampaigns.googleCampaignsData.find((el) => el.id === +ownProps.params.id)
        .campaignEditPending,
    bulkNotificationContent: getBulkNotificationContentSelector(state),
    bulkNotificationType: getBulkNotificationTypeSelector(state),
    googleRemarketingAdPreviewData: state.googleRemarketingAdPreview.googleRemarketingAdPreviewData,
});

const mapDispatchToProps = {
    requestEditGoogleCampaign,
    requestGoogleCampaigns,
    showBulkNotification,
    displayBulkNotification,
    goToNextStep: goToDashboard,
};

const RemarketingAdsEditContainer = connect(mapStateToProps, mapDispatchToProps)(RemarketingAdsEdit);

export default pageNameSetter(changeShopPage.edit_google_ads)(RemarketingAdsEditContainer);
