import React from 'react';

import './FacebookPageLayout.css';

const FacebookPageLayout = ({ children }) => (
    <div className="facebook-page-layout__wrapper action-success__wrapper">
        <div className="facebook-page-layout fb-login-page">
            <div className="facebook-page-layout__container">
                <div className="facebook-page-layout__content-wrapper">{children}</div>
            </div>
            <div className="facebook-page-layout__logo-container">
                <p className="facebook-page-layout__logo action-success__logo" />
            </div>
        </div>
    </div>
);

export default FacebookPageLayout;
