import { Component } from 'react';
import { connect } from 'react-redux';

import { redirect } from 'actions/redirect';

class ShopMiddleware extends Component {
    state = { done: false };

    componentDidMount() {
        const {
            account,
            replace,
            location: { pathname, search },
            shops: { entities, currentShopId },
        } = this.props;

        try {
            const [currentShop] = entities.filter((shop) => +shop.id === +currentShopId);
            const currentShopParams = JSON.parse(currentShop.params);
            if (currentShopParams.stencil_enabled === true) {
                replace(`/shop/${currentShopId}`);
            }
        } catch (e) {}

        this.setState({ done: true });
    }

    render() {
        if (this.state.done) {
            return this.props.children;
        }

        return null;
    }
}

const mapStateToProps = ({ account, shops }) => ({
    shops,
    account,
});

const mapPropsToDispatch = {
    replace: redirect,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ShopMiddleware);
