import React from 'react';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { campaignCopyright } from 'constants/campaignCopyright';
import { DYNAMIC_RETARGETING } from 'constants/campaign';

import CreateRetargetingCampaign from '../CreateRetargetingCampaign';

export const CreateDynamicRetargetingCampaign = () => (
    <CreateRetargetingCampaign
        campaignDescription={campaignCopyright.dynamic_retargeting.description}
        campaignTitle={campaignCopyright.dynamic_retargeting.title}
        adMessageTipText={campaignCopyright.dynamic_retargeting.adMessageTipText}
        onboardingType={ONBOARDING_TYPES.facebook_dynamic_retargeting}
        campaignType={DYNAMIC_RETARGETING}
        onboardingStatus={UBM_ONBOARDING_STATES.create_dynamic_retargeting_campaign}
    />
);
