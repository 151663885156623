export const metricType = {
    dashboardDateRangeChanged: 'dashboard_date_range_changed',
    adPreviewPlacementChanged: 'ad_preview_placement_changed',
    switchedToAnotherStore: 'switched_to_another_store',
    pageViewed: 'page_viewed',
    downloadedInvoices: 'downloaded_invoices',
    facebookPageChanged: 'facebook_page_changed',
    loggedOut: 'logged_out',
    btnClicked: 'button_clicked',
    elementViewed: 'element_viewed',
    fbCampaignInvitationAccepted: 'fb_campaign_invitation_accepted',
    bookCall: 'book_call',
};

export const fbCampaignTactics = {
    prospecting: 'prospecting',
    retargeting: 'retargeting',
    special_offer: 'special_offer',
};

export const changeShopPage = {
    homepage: 'Homepage',
    create_campaign: 'Create Campaign',
    dashboard: 'Dashboard',
    edit_campaign: 'Edit Campaign',
    choose_shop: 'Choose Shop',
    create_prospecting: 'Create Prospecting',
    edit_prospecting_campaign: 'Edit Prospecting Campaign',
    account_settings: 'Settings',
    choose_install_shop: 'Choose Install Shop',
    edit_google_ads: 'Edit Google Ads',
    onboarding_get_started: '1/2: Get started',
    onboarding_create_campaign: '2/2: Сreate campaign',
    before_you_begin: 'Before you begin',
    book_a_call: 'Book a call',

    // old onboarding pages
    login: 'Login',
    not_qualified: 'Not Qualified',
    invitation: 'Invite Child BM',
    page: 'Connect page',
};
