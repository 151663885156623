import {
    SHOP_RECURRING_CHARGE_REQUEST,
    SHOP_RECURRING_CHARGE_SUCCESS,
    SHOP_RECURRING_CHARGE_FAILURE,
    SHOP_CREATE_RECURRING_CHARGE_SUCCESS,
    SHOP_UPDATE_RECURRING_CHARGE_SUCCESS,
    SHOP_UPDATE_RECURRING_CHARGE_REQUEST,
    SHOP_UPDATE_RECURRING_CHARGE_FAILURE,
} from 'actions/shop';

const initialState = {
    entity: null,
    error: null,
    pending: false,
    success: false,
    failure: false,
    updateUrlPending: false,
    timestamp: 0,
};

export default (state = initialState, action) => {
    const { payload, type, meta } = action;
    let timestamp;
    if (meta && meta.timestamp) {
        timestamp = action.meta.timestamp;
    }
    switch (type) {
        case SHOP_RECURRING_CHARGE_REQUEST:
            state = {
                ...initialState,
                pending: true,
                timestamp: timestamp > state.timestamp ? timestamp : state.timestamp,
            };
            break;
        case SHOP_RECURRING_CHARGE_SUCCESS:
            if (timestamp === state.timestamp) {
                state = {
                    ...initialState,
                    entity: payload,
                    success: true,
                };
            }
            break;
        case SHOP_RECURRING_CHARGE_FAILURE:
            if (timestamp === state.timestamp) {
                state = {
                    ...initialState,
                    error: payload,
                    failure: true,
                };
            }
            break;
        case SHOP_UPDATE_RECURRING_CHARGE_REQUEST:
            state = {
                ...state,
                updateUrlPending: true,
            };
            break;
        case SHOP_UPDATE_RECURRING_CHARGE_SUCCESS:
            state = {
                ...state,
                entity: {
                    ...state.entity,
                    update_url: payload.url,
                },
                updateUrlPending: false,
                success: true,
            };
            break;
        case SHOP_UPDATE_RECURRING_CHARGE_FAILURE:
            state = {
                ...state,
                updateUrlPending: false,
            };
            break;
        case SHOP_CREATE_RECURRING_CHARGE_SUCCESS:
            state = {
                ...initialState,
                success: true,
                entity: payload,
            };
            break;
        default:
            break;
    }
    return state;
};
