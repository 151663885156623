import React from 'react';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { campaignCopyright } from 'constants/campaignCopyright';
import { SOCIAL_RETARGETING } from 'constants/campaign';

import CreateRetargetingCampaign from '../CreateRetargetingCampaign';

export const CreateSocialRetargetingCampaign = () => (
    <CreateRetargetingCampaign
        campaignDescription={campaignCopyright.social_retargeting.description}
        campaignTitle={campaignCopyright.social_retargeting.title}
        adMessageTipText={campaignCopyright.social_retargeting.adMessageTipText}
        onboardingType={ONBOARDING_TYPES.facebook_social_media_retargeting}
        campaignType={SOCIAL_RETARGETING}
        onboardingStatus={UBM_ONBOARDING_STATES.create_social_media_retargeting_campaign}
    />
);
