import React from 'react';

const SetupInProgress = () => (
    <div className="home__board__block__connect__button home__board__block__connect__button_progress">
        Setup in progress
    </div>
);

const SetupNotStarted = () => (
    <div className="home__board__block__connect__button home__board__block__connect__button_start">
        Learn more
    </div>
);

const Done = () => (
    <div className="home__board__block__connect__button home__board__block__connect__button_finish">
        Setup complete
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.66667 8.33317L0 4.99984L1 3.99984L3.66667 6.33317L9 0.666504L10 1.6665L3.66667 8.33317Z" fill="#07AE3A"/>
        </svg>
    </div>
);

const types = {
    not_started: <SetupNotStarted />,
    started: <SetupInProgress />,
    done: <Done />,
};

// we cannot control all states on backend side but we know for sure
// what should we do in states 'done' and 'not_started' for all other cases we can use type started
export const Status = ({ type }) => types[type] || types.started;
