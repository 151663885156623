import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as shopActions from 'actions/onboarding';
import { hideLoader, showLoader } from 'actions/loader';

import { changeShopPage } from 'constants/metric';
import { WOO_COMMERCE } from 'constants/platform';
import pageNameSetter from 'decorator/pageNameSetter';
import { getCurrentShopSelector } from 'helpers/selectors';
import { FacebookButton } from 'components/FacebookButton';
import { FACEBOOK_LOGIN_CALLBACK_URL } from 'modules/facebook/login/const';
import './FacebookLogin.css';

class FacebookLogin extends Component {
    state = {
        isApproved: false,
        disabledButton: true,
        isLoginButtonClicked: false,
    };

    componentDidMount() {
        const { requestOnBoardingLink, inframe } = this.props;

        requestOnBoardingLink(inframe);
    }

    togglePrivacyPolicy = () => {
        this.setState(({ isApproved }) => ({ isApproved: !isApproved }));
        this.setState(({ disabledButton }) => ({ disabledButton: !disabledButton }));
    };

    facebookLoginHandler = (loginLink) => {
        const { inframe } = this.props;

        this.setState({ isLoginButtonClicked: true });

        if (inframe) {
            window.open(loginLink, '_blank');
        } else {
            window.location.assign(loginLink);
        }
    };

    render() {
        const {
            inframe,
            loginLink,
            currentShop,
            params: { shopId },
        } = this.props;

        const { isApproved, disabledButton, isLoginButtonClicked } = this.state;

        const showPrivacyPolicy = currentShop?.type !== WOO_COMMERCE;
        // TODO: check if we can move it to componentDidMount
        window.localStorage.setItem(FACEBOOK_LOGIN_CALLBACK_URL, `/${shopId}/`);

        return (
            <div className="facebook-login__wrapper">
                <h1 className="facebook-login__title">Log In</h1>
                <p className="facebook-login__description">
                    Please, log in with Facebook. This will help us identify you in our system, get the list of Pages
                    you manage and get a permission to manage ads on your behalf.
                </p>

                <p className="facebook-login__save-info">Any information you share is safe with us.</p>

                <div className="facebook-login__description">
                    {showPrivacyPolicy && (
                        <div className="facebook-login__privacy">
                            <label
                                htmlFor="privacyPolicy"
                                className={classNames('facebook-login__checkbox-label', {
                                    'facebook-login__checkbox-label_accent': !isApproved && isLoginButtonClicked,
                                })}
                            >
                                <div className="custom-checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="privacyPolicy"
                                        id="privacyPolicy"
                                        checked={isApproved}
                                        onChange={this.togglePrivacyPolicy}
                                        className="facebook-login__checkbox"
                                    />
                                    <span className="checkmark" />
                                </div>
                                <div className="facebook-login__link-text">
                                    I’ve read Adwisely{' '}
                                    <a
                                        className="in-text"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                            'https://help.adwisely.com/' +
                                            'learn-about-pricing-privacy-policy-and-terms/privacy-policy'
                                        }
                                    >
                                        Privacy Policy
                                    </a>{' '}
                                    and allow Adwisely to process my personal and business data.{' '}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="facebook-login">
                        <FacebookButton
                            disabled={disabledButton}
                            onClick={() => this.facebookLoginHandler(loginLink)}
                        />
                        <div className="login-view__button__fb" />
                    </div>
                    {inframe && (
                        <a href="/" className="facebook-login__reload">
                            Reload page
                        </a>
                    )}
                </div>
            </div>
        );
    }
}

FacebookLogin.defaultProps = {
    loginLink: '',
};

FacebookLogin.propTypes = {
    currentShop: PropTypes.shape({
        type: PropTypes.string.isRequired,
    }).isRequired,
    inframe: PropTypes.bool.isRequired,
    loginLink: PropTypes.string,
};

const mapStateToProps = ({ shops, account, onboarding }) => ({
    shops: shops.entities,
    inframe: onboarding.inframe.inframe,
    accountRequesting: account.requesting,
    platformType: onboarding.inframe.platformType,
    loginLink: onboarding.facebookLogin.fbLoginLink,
    isAccountRequestSuccess: account.requestingSuccess,
    currentShop: getCurrentShopSelector(shops, shops.currentShopId),
});

const mapDispatchToProps = {
    hideLoader,
    showLoader,
    requestOnBoardingLink: shopActions.requestOnBoardingLink,
};

const FacebookLoginContainer = connect(mapStateToProps, mapDispatchToProps)(FacebookLogin);
export default pageNameSetter(changeShopPage.onboarding_get_started, changeShopPage.login)(FacebookLoginContainer);
