import React, { Component } from 'react';
import { connect } from 'react-redux';

import { requestShops } from 'actions/shop';
import { redirect } from 'actions/redirect';

import Header from 'components/Header';
import Footer from 'components/Footer';

import 'containers/App.css';

import getInstallLink from '../utils';
import './Uninstalled.css';

class Uninstalled extends Component {
    componentDidMount() {
        const {
            replace,
            shops,
            params: { shopId },
            requestShops,
        } = this.props;

        if (!shops.length) {
            requestShops();
        }

        if (shops.length) {
            const [currentShop] = shops.filter((shop) => +shop.id === +shopId);
            if (currentShop) {
                if (!currentShop.app_uninstalled_at) {
                    replace(`/${currentShop.id}`);
                }
            } else {
                replace('/');
            }
        }
    }

    render() {
        const {
            shops,
            params: { shopId },
        } = this.props;
        let shopName;
        let shopDomain;
        let shopType;

        if (shops.length) {
            const [currentShop] = shops.filter((shop) => +shop.id === +shopId);
            if (currentShop) {
                if (currentShop.app_uninstalled_at) {
                    shopName = currentShop.name;
                    shopDomain = currentShop.platform_id;
                    shopType = currentShop?.type;
                }
            }
        }

        return (
            <div className="uninstalled-wrapper">
                <Header shopId={shopId} />

                <section className="uninstalled-section">
                    <div className="uninstalled-section__container">
                        <h1 className="uninstalled-section__title">Log in</h1>

                        <p className="uninstalled-section__description">
                            It appears that you removed Adwisely application from {shopName} store.
                        </p>

                        <p className="uninstalled-section__description">
                            To run and manage your advertising campaigns, you need to install Adwisely.
                        </p>

                        <a
                            className="uninstalled-section__buttton"
                            href={getInstallLink(shopDomain, shopType)}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Install Adwisely
                        </a>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ shops }) => ({
    shops: shops.entities,
});

const mapDispatchToProps = {
    requestShops,
    replace: redirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Uninstalled);
