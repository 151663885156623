import React from 'react';
import intercom from 'libs/intercom';
import Button from 'components/Button';

import './DefaultError.css';

const DefaultErrorPage = () => (
    <div className="onboarding-error__wrapper">
        <h1 className="onboarding-error__title">Ooops!</h1>
        <div className="onboarding-error__body-wrapper">
            <p className="onboarding-error__description">
                <strong>Contact Support to complete ad setup</strong>
            </p>
            <p className="onboarding-error__description">
                It looks like something went wrong on our end. No worries, though - we can fix it together.
            </p>
            <p className="onboarding-error__description">
                Contact our Support via chat or book a quick call - we’ll be happy to help you complete the ad setup.
            </p>
        </div>
        <div className="onboarding-error__buttons">
            <Button url="https://calendly.com/adwisely-support-team/support-team" color="primary">
                Book a call
            </Button>
            <Button onClick={intercom.show}>
                Contact Support
            </Button>
        </div>
    </div>
);

export default DefaultErrorPage;
