import React from 'react';
import { LOGIN_ERROR_MESSAGES } from '../../const';
import {
    LoginErrorMessageDecline,
    LoginErrorMessageFailed,
    LoginErrorMessageLinkError,
} from './components';
import LoginErrorMessageMissingEmail from './components/LoginErrorMessageMissingEmail';

export const getLoginErrorMessage = ({ error, loginLink, loginLinkError, requestLoginLink }) => {
    let errorMsg = '';

    if (error) {
        switch (error) {
            case LOGIN_ERROR_MESSAGES.BAD_PARAMS:
                errorMsg = 'Seems to be request to login was corrupted.';
                break;
            case LOGIN_ERROR_MESSAGES.DECLINE:
                errorMsg = <LoginErrorMessageDecline loginLink={loginLink} loginLinkError={loginLinkError} />;
                break;
            case LOGIN_ERROR_MESSAGES.FAILED:
                errorMsg = <LoginErrorMessageFailed loginLink={loginLink} loginLinkError={loginLinkError} />;
                break;
            case LOGIN_ERROR_MESSAGES.NO_USER_EMAIL:
                errorMsg = <LoginErrorMessageMissingEmail />;
                break;
            default:
                break;
        }
    } else if (loginLinkError) {
        errorMsg = <LoginErrorMessageLinkError requestLoginLink={requestLoginLink} />;
    }

    return errorMsg;
};
