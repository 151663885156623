import React from 'react';
import { Modal } from 'components/Modal/Modal';

import './VideoPlayerModal.css';

const VideoPlayerModal = ({ isOpen, hideModal, url }) => (
    <Modal className="video-player__modal" isOpen={isOpen} centered toggle={hideModal}>
        <div className="video-player__close-btn" onClick={() => hideModal(false)} />
        <div className="video-player__modal__body">
            <iframe
                width="568"
                height="321"
                src={url}
                title="YouTube video player"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    </Modal>
);

export default VideoPlayerModal;
