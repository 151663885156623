import React, { PureComponent } from 'react';
import NotificationWidget from 'components/NotificationWidget';

class CampaignError extends PureComponent {
    constructor(props) {
        super(props);
        this.getCampaignErrorBanner = this.getCampaignErrorBanner.bind(this);
    }

    getCampaignErrorBanner() {
        const { campaignError } = this.props;
        let notificationData = null;
        let bannerTitle = null;
        let bannerContent = null;
        switch (campaignError) {
            case 'deleted-campaign-can-not-be-paused':
            case 'deleted-campaign-can-not-be-changed':
            case 'deleted-ad-can-not-be-changed':
            case 'deleted-adset-can-not-be-changed':
            case 'deleted-campaign-can-not-be-resumed': {
                bannerTitle = <p className="notification__title">Your campaign will be re-created soon</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            Looks like someone deleted a campaign, ad set or ad from your Adwisely-powered ad
                            account. No worries{' '}
                            <span className="banner__emoji" aria-labelledby="slightly_smiling_face" role="img">
                                🙂
                            </span>
                            The campaign will be recreated soon. Once it is reviewed, we will set it to the status your
                            ad was previously set to.
                        </p>

                        <p className="notification__description">
                            Please, do not edit or delete anything in the ad account Adwisely created for you.
                        </p>
                    </div>
                );

                notificationData = [bannerTitle, bannerContent];
                break;
            }
            default:
                break;
        }
        return notificationData;
    }

    render() {
        const shopCommonErrorBannerArray = this.getCampaignErrorBanner();
        if (shopCommonErrorBannerArray) {
            return (
                <div>
                    <NotificationWidget type="danger">
                        {shopCommonErrorBannerArray.map((notificationItem, key) => (
                            <span key={key}>{notificationItem}</span>
                        ))}
                    </NotificationWidget>
                </div>
            );
        }
        return null;
    }
}

export default CampaignError;
