import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { numberDigitSeparateWithSpace } from 'helpers';

export const StatMainItemWithTips = ({ prefix, postfix, valueCurrent }) => {
    const customIcon = (prefix || '') + (numberDigitSeparateWithSpace(valueCurrent, true) || '0') + (postfix || '');

    return (
        <p className="statistics-section-item__value statistics-section-primary-item__value statistics-section-primary-item__value_with-tips">
            {customIcon}
        </p>
    );
};

StatMainItemWithTips.defaultProps = {
    prefix: '',
    postfix: '',
};

StatMainItemWithTips.propTypes = {
    valueCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    prefix: PropTypes.string,
    postfix: PropTypes.string,
};

export default connect(null)(StatMainItemWithTips);
