const getAppId = () => window.intercomSettings.app_id;

const getDefaultSettings = () => ({
    app_id: getAppId(),
});

const intercom = (...args) => window.Intercom(...args);

const API = {};

/**
 * Initialize Intercom
 */
API.boot = (custom = {}) => {
    intercom('boot', {
        ...getDefaultSettings(),
        ...custom,
    });
};

/**
 * Shutdown Intercom
 */

API.shutdown = () => {
    intercom('shutdown');
};

/**
 * Update Intercom session with user params
 * @param user {object}
 * @param user.email {string}
 * @param user.user_id {string}
 * @param user.name {string}
 * @param user.created_at {number} - unix timestamp, user sign up date
 */
API.update = (user) => {
    intercom('update', {
        ...getDefaultSettings(),
        ...user,
    });
};

/**
 * Show Intercom Messenger
 */
API.show = () => intercom('show');

/**
 * Track event through Intercom
 * @param event {object} - event object
 * @param event.type {string} - specific event type string, required
 * @param event.meta {object} - object with event specify params
 */
API.trackEvent = (event) => {
    intercom('trackEvent', event.type, event.meta);
};

/**
 * Get visitor id
 */
API.getVisitorId = () => intercom('getVisitorId');

/**
 * Check intercom inited
 */
API.checkIntercomInited = (ms, times) => new Promise((resolve) => {
    let i = 0;
    const interval = setInterval(() => {
        i += 1;
        const intercomVisitorId = API.getVisitorId();
        if (i >= times || intercomVisitorId) {
            clearInterval(interval);
            const id = intercomVisitorId || '';
            resolve(id);
        }
    }, ms);
});

/** @exports intercom */
export default API;
