import React, {useEffect, useState} from 'react';
import { fetchUserAccount } from 'helpers/fetchUserAccount';

export const useGetUserEmail = (setIsLoading) => {
    const [email, setEmail] = useState('');
    useEffect(() => {
        setIsLoading(true);
        fetchUserAccount(setIsLoading, setEmail);
    }, [])

    return { email };
}