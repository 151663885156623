import React from 'react';

import IntercomChatCaller from 'components/IntercomChatCaller';

import './BigCommerceContactUs.css';

const BigCommerceContactUs = () => (
    <div className="wizard-container bigcommerce-contact-us__wizard-container">
        <p className="bigcommerce-contact-us__text">
            We cannot automatically install Adwisely on your store yet <br />
            because your website is built on Blueprint store theme engine.
        </p>
        <p>
            No worries, we are working on it{' '}
            <span role="img" aria-label="smile">
                😉
            </span>
        </p>
        <p className="bigcommerce-contact-us__text">Please, message us using the chat window for more information.</p>
        <IntercomChatCaller
            content={<button className="shopify-reinstall__button bigcommerce-contact-us__button">Contact us</button>}
        />
    </div>
);

export default BigCommerceContactUs;
