import PropTypes from "prop-types"
import React from 'react';

const GoogleRemarketingAdPreviewCard = ({ imageLink, headline, shopUrl }) => (
    <div>
        <div className="ad-preview-remarketing__wrap">
            <div className="ad-preview-remarketing__card">
                <div className="ad-preview-remarketing__image-wrapper">
                    <img className="ad-preview-remarketing__image" src={imageLink} alt="product" />
                </div>
                <div className="ad-preview-remarketing__desc">
                    <div className="ad-preview-remarketing__headline">{headline}</div>
                    <div className="ad-preview-remarketing__ad-badge">Ad</div>
                    <div className="ad-preview-remarketing__button">
                        <a
                            className="ad-preview-remarketing__button-link"
                            href={/^https?:\/\//.test(shopUrl) ? shopUrl : `//${shopUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Visit Site
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

GoogleRemarketingAdPreviewCard.propTypes = {
    headline: PropTypes.string.isRequired,
    imageLink: PropTypes.string.isRequired,
    shopUrl: PropTypes.string.isRequired,
};

export default GoogleRemarketingAdPreviewCard;
