import { useState } from 'react';

export const useSetPhoneData = () => {
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('US');
    const [countryCallingCode, setCountryCallingCode] = useState('1');

    return {
        phone,
        setPhone,
        countryCode,
        setCountryCode,
        countryCallingCode,
        setCountryCallingCode,
    };
};
