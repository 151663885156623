import React from "react";

import {trackBookCall} from "helpers/metrics";

export const BookACallSection = ({ shopId, requestSendEventMetrics }) => (
    <div className="share-pixel-help">
        <h3 className="share-pixel-help__title">Need help?</h3>
        <p>
            <a
                href="https://help.adwisely.com/en/articles/4957812-how-do-i-prepare-for-ios-14-update#h_d519968f04"
                target="_blank"
                rel="noopener noreferrer"
                className="share-pixel-help__link"
            >
                Read the instruction
            </a>{' '}
            or{' '}
            <a
                href="https://calendly.com/adwisely-support-team/support-team"
                target="_blank"
                rel="noopener noreferrer"
                className="share-pixel-help__link"
                onClick={() => trackBookCall(shopId, requestSendEventMetrics, 'share_pixel')}
            >
                book a call
            </a>
        </p>
    </div>
);