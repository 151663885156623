import React, {useState} from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';
import Button from 'components/Button';

import { changeOnboardingState } from 'modules/home/utils';
import {fetchPost, getLocalStorageObjectItem} from 'helpers';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import {InstagramAccount} from '../../InstagramAccount';
import {useFetchInstagramAccounts} from '../../hooks/useFetchInstagramAccounts';

const DropdownItems = ({dropdownItems, toggle, onChange, selectedItem}) => (
    <>
        {dropdownItems.map((item, key) =>(
            <DropdownItemMain
                key={key}
                value={item.id}
                onClick={() => {
                    onChange(item.id);
                    toggle();
                }}
                active={item.id === selectedItem}
                title={`${item.name} [ID: ${item.id}]`}
                subtitle={item.status}
                disabled={false}
            />
        ))}
    </>

);
const FlagTogglerWrapper = ({selectedItem}) => (
    <FlagToggler
        title={selectedItem ? `${selectedItem.name} [ID: ${selectedItem.id}]` : null}
        subtitle={selectedItem ? selectedItem.status : null}
    >
        Select Instagram account
    </FlagToggler>
)

export const ChooseInstagramAccountView = ({shopId, replace}) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const instagramAccounts = useFetchInstagramAccounts(shopId);
    const selectedItem = (id) => instagramAccounts.find(item => item.id === id)

    const handleContinueClick = () => {
        setIsLoading(true);

        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.choose_instagram_account,
            currentShopId: shopId,
            data: {instagram_account_ig_id: selectedAccountId}
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(shopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(shopId, replace);
                }
            });
    }

    const toggle = (isOpen) => {
        isOpen !== undefined ? setIsDropdownOpen(isOpen) : setIsDropdownOpen(!isDropdownOpen)
    }

    return (
        <InstagramAccount isLoading={isLoading}>
            <div className="connect-instagram__description">Adwisely will run ads on behalf of your Instagram Business profile.To proceed, please, select the Instagram account you’d like us to use.</div>
            <div className="connect-instagram__dropdown-wrapper">
                <h3 className="connect-instagram__dropdown-title">Instagram account</h3>
                <Dropdown
                    toggle={toggle}
                    isOpen={isDropdownOpen}
                    flagToggler={<FlagTogglerWrapper selectedItem={selectedItem(selectedAccountId)} />}
                    items={<DropdownItems
                        dropdownItems={instagramAccounts}
                        toggle={toggle}
                        onChange={(data) => {
                            setSelectedAccountId(data)
                        }}
                        selectedItem={selectedItem(selectedAccountId)}
                    />}
                />
            </div>
            <div className="connect-instagram__btn-wrapper">
                <Button color='primary' disabled={!selectedAccountId} onClick={handleContinueClick}>
                    Continue
                </Button>
            </div>
        </InstagramAccount>
    )
}

const mapStateToProps = ({ shops }) => (
    {
        shopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace
};

export const ChooseInstagramAccount = connect(mapStateToProps, mapPropsToDispatch)(ChooseInstagramAccountView)