import { prepareURI } from './api-utils';

export const CALL_API = 'CALL_API';

const SHOPS = '/shops';
const SHOPIFY_LOGIN = '/shopify/login';
const FACEBOOK_LOGIN = '/facebook/login';
const FACEBOOK_LOGIN_LINK = '/facebook/login';
const ACCOUNT = '/account';
const FB_AD_ACCOUNT_CONNECT = '/facebook/ad_accounts_requests/$id';
const FB_AD_ACCOUNT_CONNECT_STATUS = '/facebook/ad_accounts_requests/$id';
const FB_AD_ACCOUNT_REMOVE = '/facebook/ad_accounts_requests/$id';
const FB_PAGES = '/facebook/pages';
const FB_PAGE_CONNECT = '/shop/$shopId/pages_requests/$id';
const SHOP_STATISTIC = '/shops/$shopId/statistics';
const SHOP_CAMPAIGNS = '/shops/$shopId/facebook_campaigns';
const GOOGLE_CAMPAIGNS = '/shops/$shopId/google_campaigns';
const GOOGLE_PAYMENT_METHOD = '/google/$shopId/gl-check-payment-method';
const SHOP_STATUS = '/shops/$shopId/status';
const CAMPAIGN_SUGGESTIONS = '/shops/$id/campaigns_settings/suggestions';
const CAMPAIGN_SETTINGS = '/shops/$shopId/campaigns_settings';
const CAMPAIGN_SETTINGS_CREATE_CHILD_BM = '/shops/$shopId/campaigns_settings/child_business_manager';
const CAMPAIGN_SETTINGS_CREATE_AD_ACCOUNT = '/shops/$shopId/campaigns_settings/ad_account';
const BM_USERS = '/facebook/business_manager/$bmId/business_users';
const CAMPAIGN_VALIDATE = '/shops/$shopId/campaigns/validate';
const CAMPAIGN_VALIDATE_FACEBOOK = '/shops/$shopId/campaigns/validate';
const CAMPAIGN = '/campaigns/$id';
const CAMPAIGNS_PROMO = '/shop/$shopId/campaigns_promo';
const CAMPAIGNS_PROMO_ITEM = '/shop/$shopId/campaigns_promo/$campaignPromoId';
const CAMPAIGN_PAUSE = '/campaign/$id/pause';
const PAUSE_CAMPAIGN_REASON = '/campaign/$id/pause_reason';
const PAUSE_GOOGLE_CAMPAIGN_REASON = '/shops/$shopId/google_campaigns/$campaignId/pause_reason';
const CAMPAIGN_RESUME = '/campaign/$id/resume';
const GOOGLE_CAMPAIGN_RESUME = '/shops/$shopId/google_campaigns/$campaignId/resume';
const GOOGLE_CAMPAIGN_PAUSE = '/shops/$shopId/google_campaigns/$campaignId/pause';
const SHOP_CAMPAIGNS_SETTINGS_PREPARE = '/shops/$shopId/campaigns_settings/prepare';
const SHOP_NOTIFICATIONS = '/shop/$id/notifications';
const SHOP_QUALIFICATION = '/shop/$id/qualification';
const EDIT_PAGE_AD_PREVIEW = '/campaign/$campaignId/ad_preview';
const SHOP_BILLING_INFO = '/shop/$shopId/billing_info';
const SHOP_BILLING_PLAN_STATUS = '/shop/$shopId/billing_plan_status';
const GET_GOOGLE_LOGIN_LINK = '/google/gl_account_info';
const ACTION_LINK = '/action';
const STRIPE_CREATE_CUSTOMER = '/shop/$shopId/stripe/customer';
const STRIPE_SOURCE = '/shop/$shopId/stripe/source';
const STRIPE_USER_CHARGE = '/shops/$shopId/charge';
const INSTALL_SHOPIFY_URL_WITH_DOMAIN = '/install_url/shopify';
const INSTALL_WOOCOMMERCE_URL_WITH_DOMAIN = '/install_url/woocommerce';
const SHOP_RECOMMENDATION_DASHBOARD = '/shop/$shopId/recommendations';
const SHOP_MIGRATION_ONBOARDING = '/shop/$shopId/migration_onboarding';
const SHOP_BRAINTREE_CHECKOUT = '/shop/$shopId/braintree_checkout';
const SHOP_EVENT_METRICS = '/ev-tr';
const GOOGLE_STANDARD_AD_PREVIEW = '/shops/$shopId/google_campaigns/$campaignId/ads/$adId/preview';
const GOOGLE_REMARKETING_AD_PREVIEW = '/shops/$shopId/google_campaigns/$campaignId/ads/$adId/preview';
const GOOGLE_CAMPAIGN = '/shops/$shopId/google_campaigns/$campaignId';
const USER_BUSINESS_MANAGER = '/onboarding/$shopId/$onboardingType/businesses';
const FEATURE_FLAGS = '/shops/$shopId/feature_toggles';
const ONBOARDINGS = '/onboarding/$shopId/';
const SUBSCRIPTION = '/shop/$shopId/subscription';
const INTERCOM_USER = '/intercom-user';

/**
 * @param endpoint {string}
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 * @param method {string} - GET, POST, PUT or DELETE
 * @param expectedClientErrors
 */
export const callAPI = (endpoint, params = {}, method = 'GET', expectedClientErrors = []) => ({
    [CALL_API]: {
        endpoint,
        method,
        expectedClientErrors,
        ...params,
    },
});

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIShopInstall = (params) => {
    const requestParams = {
        types: params.types,
        params: {
            type: 'shopify',
            ...params.params,
        },
    };
    if (params.params.signed_payload) return callAPI(SHOPS, requestParams, 'PUT');
    return callAPI(SHOPS, requestParams, 'POST');
};

/**
 * API action creator
 * Request shops
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIGetShops = (params) => callAPI(SHOPS, params, 'GET');

export const callAPIUpdateShop = (params) => callAPI(SHOPS, params, 'PATCH');

/**
 * API action creator
 * Verify shopify login
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIShopLogin = (params) => callAPI(SHOPIFY_LOGIN, params, 'POST');

/**
 * Confirm Facebook OAuth with `code`
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIFacebookLogin = (params) => callAPI(FACEBOOK_LOGIN, params, 'POST');

/**
 * Perform request for Facebook OAuth login link
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIGetFBLoginLink = (params) => callAPI(FACEBOOK_LOGIN_LINK, params, 'GET');

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIAccountInfo = (params) => callAPI(ACCOUNT, params, 'GET');

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIAccountRemove = (params) => callAPI(ACCOUNT, params, 'DELETE');

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIConnectFBAdAccount = (params) => {
    const endpoint = prepareURI(FB_AD_ACCOUNT_CONNECT, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'POST');
};

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIFBAdAccountConnectStatus = (params) => {
    const endpoint = prepareURI(FB_AD_ACCOUNT_CONNECT_STATUS, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'GET');
};

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIRemoveFBAdAccount = (params) => {
    const endpoint = prepareURI(FB_AD_ACCOUNT_REMOVE, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'DELETE');
};

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIFBPages = (params) => callAPI(FB_PAGES, params, 'GET');

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIConnectFBPage = (params) => {
    const endpoint = prepareURI(FB_PAGE_CONNECT, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'POST');
};

export const callAPIConnectFBPageOnboarding = (id, shopId, params) => {
    const endpoint = prepareURI(FB_PAGE_CONNECT, { id, shopId });
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'POST');
};

const CREATE_CAMPAIGN = (id) => `/shops/${id}/facebook_campaigns`;

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPICreateCampaign = (params) => callAPI(CREATE_CAMPAIGN(params.params.shopId), params, 'POST');

const FACEBOOK_AD_PREVIEW = (id) => `/shops/${id}/ad_preview`;

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIGetAdPreview = (params) => callAPI(FACEBOOK_AD_PREVIEW(params.params.id), params, 'GET');

/**
 *
 * @param shopId {''}
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIGetShopStatisticsData = (shopId, params) =>
    callAPI(prepareURI(SHOP_STATISTIC, { shopId }), params, 'GET');

/**
 *
 * @param campaignId {''}
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIGetEditPageAdPreview = (campaignId, params) => {
    const endpoint = prepareURI(EDIT_PAGE_AD_PREVIEW, { campaignId });
    return callAPI(endpoint, params, 'GET');
};

/**
 * @param shopId {string}
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */

export const callAPIGetCampaignsPromo = (shopId, params) =>
    callAPI(prepareURI(CAMPAIGNS_PROMO, { shopId }), params, 'GET');

export const callAPIDeleteCampaignsPromo = (shopId, campaignPromoId, params) =>
    callAPI(prepareURI(CAMPAIGNS_PROMO_ITEM, { shopId, campaignPromoId }), params, 'DELETE');

export const callAPIGetShopCampaigns = (shopId, params) =>
    callAPI(prepareURI(SHOP_CAMPAIGNS, { shopId }), params, 'GET');

export const callAPIGetGoogleCampaigns = (shopId, params) =>
    callAPI(prepareURI(GOOGLE_CAMPAIGNS, { shopId }), params, 'GET');

export const callAPIGetGoogleCampaign = (shopId, campaignId, params) =>
    callAPI(prepareURI(GOOGLE_CAMPAIGN, { shopId, campaignId }), params, 'GET');

export const callAPIGetGooglePaymentMethod = (shopId, params) =>
    callAPI(prepareURI(GOOGLE_PAYMENT_METHOD, { shopId }), params, 'GET');

export const callAPIGetGoogleStandardAdPreview = (params) => {
    const endpoint = prepareURI(GOOGLE_STANDARD_AD_PREVIEW, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'GET');
};

export const callAPIGetGoogleRemarketingAdPreview = (params) => {
    const endpoint = prepareURI(GOOGLE_REMARKETING_AD_PREVIEW, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'GET');
};

export const callAPIValidateShop = (params) => {
    const endpoint = prepareURI(SHOP_STATUS, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'GET');
};

/**
 * @param types {[]} - array of events to be fired while API call
 * @param params {{}}
 */
export const callAPIShopsCampaignsSettings = ({ types, params }) => {
    const { shopId } = params;
    return callAPI(prepareURI(CAMPAIGN_SETTINGS, { shopId }), { types }, 'GET');
};

/**
 *
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPISetCampSettings = (shopId, params) =>
    callAPI(prepareURI(CAMPAIGN_SETTINGS, { shopId }), params, 'PUT');

/**
 * @param params {{}}
 * @param params.types {[]} - array of events to be fired while API call
 * @param params.params {{}}
 */
export const callAPIGetCampaignSuggestions = (params) => {
    const endpoint = prepareURI(CAMPAIGN_SUGGESTIONS, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'GET');
};

export const callAPICreateChildBM = ({ types, params }) => {
    const { shopId } = params;
    return callAPI(prepareURI(CAMPAIGN_SETTINGS_CREATE_CHILD_BM, { shopId }), { types }, 'POST');
};

export const callAPICreateAdAccountInChildBM = ({ types, params }) => {
    const { shopId } = params;
    return callAPI(prepareURI(CAMPAIGN_SETTINGS_CREATE_AD_ACCOUNT, { shopId }), { types }, 'POST');
};

export const callApiGetUserStatusInChildBM = ({ types, params }) => {
    const { bmId } = params;
    return callAPI(prepareURI(BM_USERS, { bmId }), { types }, 'GET');
};

export const callApiAddUserToChildBM = ({ types, params }) => {
    const { bmId } = params;
    return callAPI(prepareURI(BM_USERS, { bmId }), { types }, 'POST');
};

export const callAPIGetCampaignValidate = (params) => {
    const endpoint = prepareURI(CAMPAIGN_VALIDATE, params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(endpoint, params, 'GET');
};

export const callAPIRequestCampaignValidateFacebook = ({ shopId, types, params }) => {
    const endpoint = prepareURI(CAMPAIGN_VALIDATE_FACEBOOK, { shopId });
    return callAPI(endpoint, { types, params }, 'POST');
};

export const callAPIEditCampaign = (params) => {
    const endpoint = prepareURI(CAMPAIGN, params.params);
    return callAPI(endpoint, params, 'PUT');
};

export const callAPIEditGoogleCampaign = (params) => {
    const endpoint = prepareURI(GOOGLE_CAMPAIGN, params.params);
    return callAPI(endpoint, params, 'PUT');
};

export const callAPIPauseGoogleCampaign = (params) => {
    const endpoint = prepareURI(GOOGLE_CAMPAIGN_PAUSE, params.params);
    return callAPI(endpoint, params, 'POST');
};

export const callAPIPauseGoogleCampaignReason = (params) => {
    const endpoint = prepareURI(PAUSE_GOOGLE_CAMPAIGN_REASON, params.params);
    return callAPI(endpoint, params, 'POST');
};

export const callAPIPauseCampaign = (params) => {
    const endpoint = prepareURI(CAMPAIGN_PAUSE, params.params);
    return callAPI(endpoint, params, 'POST');
};

export const callAPIPauseCampaignReason = (params) => {
    const endpoint = prepareURI(PAUSE_CAMPAIGN_REASON, params.params);
    return callAPI(endpoint, params, 'POST');
};

export const callAPIResumeCampaign = (params) => {
    const endpoint = prepareURI(CAMPAIGN_RESUME, params.params);
    return callAPI(endpoint, params, 'POST');
};

export const callAPIResumeGoogleCampaign = (params) => {
    const endpoint = prepareURI(GOOGLE_CAMPAIGN_RESUME, params.params);
    return callAPI(endpoint, params, 'POST');
};

export const callAPIGetRecurringCharge = (params) => {
    const uri = prepareURI('/shops/$shopId/shopify_recurring_charge', params.params);
    delete params.params; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'GET');
};

export const callAPIUpdateRecurringCharge = (params) => {
    const uri = prepareURI('/shops/$shopId/shopify_recurring_charge', params.params);
    delete params.params.shopId; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'PUT');
};

export const callAPICreateRecurringCharge = (params) => {
    const uri = prepareURI('/shops/$shopId/shopify_recurring_charge', params.params);
    delete params.params.shopId; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'POST');
};

export const callAPIgetShopBillingPeriod = (shopId, params) => {
    const uri = `/shop/${shopId}/billing_period`;
    return callAPI(uri, params, 'GET');
};

export const callAPIShopCampaignsSettingsPrepare = (params) => {
    const uri = prepareURI(SHOP_CAMPAIGNS_SETTINGS_PREPARE, params.params);
    delete params.params.id; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'POST');
};

export const callApiShopNotifications = (params) => {
    const uri = prepareURI(SHOP_NOTIFICATIONS, params.params);
    delete params.params.id; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'GET');
};

export const callAPIShopQualification = (params) => {
    const uri = prepareURI(SHOP_QUALIFICATION, params.params);
    delete params.params.id; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'GET');
};

export const callApiShopNotificationsPut = (params) => {
    const uri = prepareURI(SHOP_NOTIFICATIONS, params.params);
    delete params.params.id; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'PUT');
};

export const callApiShopNotificationsDelete = (params) => {
    const uri = prepareURI(SHOP_NOTIFICATIONS, params.params);
    delete params.params.id; // eslint-disable-line no-param-reassign
    return callAPI(uri, params, 'DELETE');
};

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIGetShopBillingInfo = (shopId, params) =>
    callAPI(prepareURI(SHOP_BILLING_INFO, { shopId }), params, 'GET');

/**
 *
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIgetShopInfo = (shopId, params) => callAPI(`/shop/${shopId}/info`, params, 'GET');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIGetShopBillingPlanStatus = (shopId, params) =>
    callAPI(prepareURI(SHOP_BILLING_PLAN_STATUS, { shopId }), params, 'GET');

export const callAPIGetGoogleLoginUrl = (params) => callAPI(GET_GOOGLE_LOGIN_LINK, params, 'GET');

/**
 * @param params {{}}
 * @param token {string}
 */
export const callAPIGETTokenHandler = (params) => callAPI(ACTION_LINK, params, 'POST');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPICreateStripeCustomer = (shopId, params) =>
    callAPI(prepareURI(STRIPE_CREATE_CUSTOMER, { shopId }), params, 'POST');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIStripeSource = (shopId, params) => callAPI(prepareURI(STRIPE_SOURCE, { shopId }), params, 'POST');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIDeleteStripeSource = (shopId, params) =>
    callAPI(prepareURI(STRIPE_SOURCE, { shopId }), params, 'DELETE');
/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIStripeRetryCharge = (shopId, params) =>
    callAPI(prepareURI(STRIPE_USER_CHARGE, { shopId }), params, 'POST');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIGetStripeChargeAmount = (shopId, params) =>
    callAPI(prepareURI(STRIPE_USER_CHARGE, { shopId }), params, 'GET');

export const callAPIGetStoreRecommendation = (shopId, params) =>
    callAPI(prepareURI(SHOP_RECOMMENDATION_DASHBOARD, { shopId }), params, 'GET');

/**
 * @param params {{}}
 * @param params.shop_id {string}
 * @param params.change-email-action {boolean}
 * @param params.email {string}
 */
export const callAPIChangeUserEmail = (params) => callAPI(ACCOUNT, params, 'PUT');

/**
 * @param params {{domain}}
 */
export const callAPIInstallShopifyWithDomain = (params) => callAPI(INSTALL_SHOPIFY_URL_WITH_DOMAIN, params, 'GET');

/**
 * @param params {{domain}}
 */
export const callAPIInstallWoocommerceWithDomain = (params) =>
    callAPI(INSTALL_WOOCOMMERCE_URL_WITH_DOMAIN, params, 'GET');

/**
 * @param params {{}}
 */
export const callAPISendEventMetrics = (params) => callAPI(SHOP_EVENT_METRICS, params, 'POST');

/**
 * @param shopId {string}
 * @param params {{}}
 */

export const callAPIGetMigrationOnboarding = (shopId, params) =>
    callAPI(prepareURI(SHOP_MIGRATION_ONBOARDING, { shopId }), params, 'GET');

/**
 * @param shopId {string}
 * @param params {{}}
 */

export const callAPIGetBraintreeCheckout = (shopId, params) =>
    callAPI(prepareURI(SHOP_BRAINTREE_CHECKOUT, { shopId }), params, 'GET');

/**
 * @param shopId {string}
 * @param params {{}}
 */

export const callAPIPostBraintreeCheckout = (shopId, params) =>
    callAPI(prepareURI(SHOP_BRAINTREE_CHECKOUT, { shopId }), params, 'POST');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIUserBMList = (shopId, onboardingType, params) =>
    callAPI(prepareURI(USER_BUSINESS_MANAGER, { shopId, onboardingType }), params, 'GET');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIOnboardings = (shopId, params) => callAPI(prepareURI(ONBOARDINGS, { shopId }), params, 'GET');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIGetShopSubscription = (shopId, params) =>
    callAPI(prepareURI(SUBSCRIPTION, { shopId }), params, 'GET');

/**
 * @param shopId {string}
 * @param params {{}}
 */
export const callAPIFeatureFlags = (shopId, params) => callAPI(prepareURI(FEATURE_FLAGS, { shopId }), params, 'GET');

/**
 * @param params {{}}
 */
export const callAPIGetIntercomUser = (params) => callAPI(INTERCOM_USER, params, 'GET', [401, 404]);
