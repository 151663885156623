import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SPECIAL_OFFER_INVITE } from 'constants/notificationType';
import { shopHasNotification } from 'helpers';
import { CAMPAIGNS_TYPES } from 'constants/campaign';
import { fetchSpecialOfferOnboardingState } from 'modules/onboarding/userBMFlow/helpers/getOnboardingState';

import SpecialOfferInvite from './SpecialOfferInvite';
import SpecialOfferInviteExistingUsers from './SpecialOfferInviteExistingUsers';

const SpecialOfferInviteWrapper = ({
    shopId,
    isSpecialOfferInviteExist,
    specialOfferCampaign,
    campaignType,
    isActive,
    ownerType,
    shopValidateSuccess
}) => {
    const [isSpecialOfferBannerHidden, setIsSpecialOfferBannerHidden] = useState(false);
    const [specialOfferState, setSpecialOfferState] = useState(null);

    useEffect(() => {
        fetchSpecialOfferOnboardingState(shopId).then(res => setSpecialOfferState(res?.state));
    }, []);

    const hideSpecialOfferBanner = () => setIsSpecialOfferBannerHidden(true);

    const isVisible = !isSpecialOfferBannerHidden && campaignType === CAMPAIGNS_TYPES.facebook && shopValidateSuccess;

    return (
        <>
            {isVisible && isActive && (
                <>
                    {specialOfferCampaign?.client_status === 'paused' && (
                        <SpecialOfferInviteExistingUsers
                            shopId={shopId}
                            campaignId={specialOfferCampaign?.id}
                            onClickHandler={hideSpecialOfferBanner}
                        />
                    )}

                    {!specialOfferCampaign && isSpecialOfferInviteExist &&
                        (ownerType === 'user' ? specialOfferState === 'not_started' : true) && (
                            <SpecialOfferInvite shopId={shopId} onClickHandler={hideSpecialOfferBanner} ownerType={ownerType} />
                        )
                    }
                </>
            )}
        </>
    );
}

SpecialOfferInviteWrapper.defaultProps = {
    specialOfferCampaign: null,
};

SpecialOfferInviteWrapper.propTypes = {
    campaignType: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isSpecialOfferInviteExist: PropTypes.bool.isRequired,
    shopId: PropTypes.string.isRequired,
    specialOfferCampaign: PropTypes.shape({
        client_status: PropTypes.string,
        id: PropTypes.number,
    }),
    ownerType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    shopValidateSuccess: state.shopError.shopValidateSuccess,
    isSpecialOfferInviteExist: !!shopHasNotification(state.shopNotifications.entity, SPECIAL_OFFER_INVITE),
    specialOfferCampaign: state.campaignDetails.campaignDetailsData.find((campaign) => campaign.type === 'special_offer'),
    ownerType: state.shopsCampaignsSettings.entity.fb_business_manager_owner_type,
});

export default connect(mapStateToProps)(SpecialOfferInviteWrapper);
