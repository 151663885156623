import {
    SHOP_CAMPAIGN_SETTINGS_PREPARE_REQUEST,
    SHOP_CAMPAIGN_SETTINGS_PREPARE_SUCCESS,
    SHOP_CAMPAIGN_SETTINGS_PREPARE_FAILURE,
} from 'actions/shopCampaignsSettings';

import { CLEAR_REQUEST_BM_TO_SETUP_ERROR } from 'actions/onboarding';

const initialState = {
    entity: null,
    error: null,
    pending: false,
    success: false,
    failure: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOP_CAMPAIGN_SETTINGS_PREPARE_REQUEST:
            return {
                ...initialState,
                pending: true,
            };
        case SHOP_CAMPAIGN_SETTINGS_PREPARE_SUCCESS:
            return {
                ...initialState,
                success: true,
                entity: payload,
            };
        case SHOP_CAMPAIGN_SETTINGS_PREPARE_FAILURE:
            return {
                ...initialState,
                failure: true,
                error: payload,
            };
        case CLEAR_REQUEST_BM_TO_SETUP_ERROR:
            return {
                ...initialState,
                failure: false,
                error: null,
            };
        default:
            return state;
    }
};
