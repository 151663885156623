import React from 'react';
import { PropTypes } from 'prop-types';

import classNames from 'classnames';
import AlertWidget from 'components/AlertWidget';

export const Alert = ({ errorMsg, errorWide }) => (
    <div className={classNames('login-view-error__wrapper', { 'login-view-error__wrapper_wide': errorWide })}>
        <AlertWidget color="warning">{errorMsg}</AlertWidget>
    </div>
);

Alert.propTypes = {
    errorMsg: PropTypes.string.isRequired,
    errorWide: PropTypes.bool.isRequired,
};
