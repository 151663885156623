import { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { ROUTES } from 'constants/routes';

import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import { getCurrentShopSelector, getCurrentBMUserStatus } from 'helpers/selectors';
import {
    isRecurringChargeApproved,
    isStripeAdded,
    isRetargetAppFeeApproved,
    isFacebookPaymentMethodExist,
    isCampaignInfoSaved,
    isFBPageInfoSaved,
    checkFBPage,
} from 'helpers/onboardingHelper';

import { GRANTED } from 'constants/fbInvitaionStatuses';

export class ShopIndex extends Component {
    componentDidMount() {
        const {
            page,
            businessManager,
            adAccount,
            fbInviteStatus,
            params: { shopId },
            location: { pathname },
            location,
            replace,
            hasOldBM,
            account,
            shopFirstInstall,
            shopMigration,
            domainIsVerified,
            pixelIsShared,
            campaignInfoIsSaved,
            retargetAppFeeIsApproved,
            facebookBillingIsApproved,
            campaignIsCreated,
            fbPageInfoIsSaved,
        } = this.props;

        if (
            !campaignIsCreated &&
            !hasOldBM &&
            !(
                page &&
                businessManager &&
                adAccount &&
                fbInviteStatus === GRANTED &&
                domainIsVerified &&
                pixelIsShared &&
                campaignInfoIsSaved &&
                retargetAppFeeIsApproved &&
                facebookBillingIsApproved &&
                fbPageInfoIsSaved
            )
        ) {
            if (!account.entity && !shopFirstInstall) {
                replace(ROUTES.facebookLogin);
                return;
            }
            if (shopMigration) {
                replace(`/ra3-migration/${shopId}`);
                return;
            }
            replace(`/${shopId}/home`);
            return;
        }
        if (!account.entity) {
            replace(`/facebook/login?redirect_to=${pathname}`);
            return;
        }
        if (location.search && location.search.indexOf('charge_id') !== -1) {
            const campaignType = campaignCreateStorageHelper.getCampaignType();
            if (campaignType) {
                replace(`/${shopId}/onboarding/facebook-billing`);
                return;
            }
        }
        replace(`/${shopId}/home`);
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({
    shops,
    account,
    shopsCampaignsSettings,
    businessManager,
    shopRecurringCharge,
    onboarding,
    shopError,
    campaignDetails,
}) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    checkFBPage(currentShop.id, shopsCampaignsSettings);
    const recurringChargeIsApproved = isRecurringChargeApproved(shopRecurringCharge);
    const stripeIsAdded = isStripeAdded(onboarding.stripeAdd, currentShop);
    const retargetAppFeeIsApproved = isRetargetAppFeeApproved(currentShop, recurringChargeIsApproved, stripeIsAdded);
    const facebookBillingIsApproved = isFacebookPaymentMethodExist(shopError.shopError);
    return {
        shopFirstInstall: shops.installation_type === 'first_install',
        shopMigration: shops.rav2_migration_onboarding,
        account,
        page: shopsCampaignsSettings.entity && shopsCampaignsSettings.entity.page,
        hasOldBM: !!shopsCampaignsSettings?.entity?.fb_business_id,
        businessManager: shopsCampaignsSettings?.entity && shopsCampaignsSettings?.entity?.business_manager,
        adAccount: shopsCampaignsSettings?.entity && shopsCampaignsSettings?.entity?.ad_account,
        fbInviteStatus: businessManager?.data && getCurrentBMUserStatus(businessManager.data, account),
        domainIsVerified: currentShop?.params?.domain_verified_checkbox_checked,
        pixelIsShared: currentShop?.params?.pixel_shared_checkbox_checked,
        retargetAppFeeIsApproved,
        facebookBillingIsApproved,
        campaignIsCreated: campaignDetails?.campaignDetailsData.length,
        campaignInfoIsSaved: isCampaignInfoSaved(currentShop.id),
        fbPageInfoIsSaved: isFBPageInfoSaved(currentShop.id),
    };
};

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ShopIndex);
