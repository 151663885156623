import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import intercom from 'libs/intercom';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import './RestrictedError.css';

const RestrictedError = ({ currentShopId, replace }) => {
    const [isLoading, setIsLoading] = useState(false);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.bm_restricted_exception,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    const resetPage = () => {
                        if (res?.state === UBM_ONBOARDING_STATES.bm_restricted_exception) {
                            setIsLoading(false);
                        }
                    };
                    facebookStatePolling(currentShopId, replace, resetPage);
                }
            });
        }

    return (
        <div className="restricted-bm__wrapper">
            <h1 className="restricted-bm__title">You are restricted by Facebook</h1>
                <div>
                    <p className="restricted-bm__description">
                        We have detected that creation of new Ad Account is restricted for you by Facebook.
                        To resolve this Issue please contact Facebook by the {''}
                        <a
                            href="https://www.facebook.com/help/contact/273898596750902"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            following link
                        </a>
                    </p>
                    <p className="restricted-bm__description">
                        If you have resolved the issue with the help of Facebook, please click <strong>Already done</strong> button (or refresh page in browser).
                        Or {''}
                        <span
                            onClick={intercom.show}
                        >
                            contact our support
                        </span> {''}
                        for more information.
                    </p>
                </div>
            <Button onClick={handleContinueBtn} color="primary">
                Already Done
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(RestrictedError);
