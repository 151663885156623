import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { replace, push } from 'react-router-redux';

import { isGooglePlatformSupported, setLocalStorageObjectItem } from 'helpers';
import {
    isQualified,
    isQualifiedByCriterias,
    SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN,
    SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT,
    SHOP_QUALIFICATION_CRITERIA_ORDERS,
} from 'helpers/shopQualifacation';
import { getCurrentShopSelector } from 'helpers/selectors';

import { Loader, LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import { ToDoList } from 'components/ToDoList/ToDoList';
import AdsPreviewSection from 'components/AdsPreviewSection/AdsPreviewSection';
import { ResumePlanBanner } from 'components/Banners/ResumeBannerBanner/ResumePlanBanner';
import AiBoostedSalesInvite from 'components/Banners/AiBoostedSalesInvite/AiBoostedSalesInvite';

import { ONBOARDING_TYPES } from 'constants/onboarding';
import { SHOPIFY, STANDALONE } from 'constants/platform';
import { changeShopPage } from 'constants/metric';

import {requestSendEventMetrics, validateShop} from 'actions/shop';
import { setGoogleOnboardingState } from 'actions/googleOnboardingState';
import { requestLoginLink } from 'actions/login';
import { requestOnboardings } from 'actions/onboarding';
import { getIntercomUser } from 'actions/intercom';

import { getVerificationNotificationType } from 'helpers/getVerificationNotificationType';

import { CustomerInterviewBannerWrapper } from 'modules/dashboard/Banners';
import pageNameSetter from 'decorator/pageNameSetter';

import { useTabs, useOnboardingState } from './hooks';
import { changeOnboardingState } from './utils';

import './home.styles.css';

import { Recommendations } from './components/Recommendations/Recommendations';
import { Cards } from './components/Cards/Cards';
import { NotQualifiedBanner } from './components';
import GeneralRestrictionBanner from './components/banners/GeneralRestrictionBanner';
import { VerificationNotifications } from './components/VerificationNotifications/VerificationNotifications';

import { useHandleNotAuthOnboading } from './hooks/useHandleNotAuthOnboading';

const checkButtons = ({ shopQualificationCriteria }) =>
    !isQualified(shopQualificationCriteria) ||
    !isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN) ||
    !isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT) ||
    !isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_ORDERS);

const freezeReasons = [
    'shop-is-frozen-due-to-subscription-canceled',
    'shop-is-frozen-due-to-subscription-spend-limit-reached'
]

const isShopFreezedOrOnboardingDone = (freezeReason, onboardingState) =>
    freezeReasons.includes(freezeReason) && onboardingState !== 'done';

export const Home = ({
    redirect,
    push,
    currentShop,
    currentShopId,
    googleLoginUrl,
    shopQualificationCriteria,
    isGoogleAuthorized,
    isFacebookAuthorized,
    requestLoginLink,
    loginLink,
    requestSendEventMetrics,
    getIntercomUser,
    shopError,
    checkShopForErrors,
    requestOnboardings,
    onboardings,
    notifications,
}) => {
    const { tabs, changeActiveTabs } = useTabs(isGooglePlatformSupported(currentShop.type), onboardings);
    const [isLoading, setIsLoading] = useState(false);

    useOnboardingState({ redirect, onboardingState: onboardings, currentShopId });
    useHandleNotAuthOnboading(
        {
            onboardings,
            currentShopId,
            changeOnboardingState,
            isFacebookAuthorized,
            setIsLoading,
            redirect
        }
    );

    const disableButtons = checkButtons({ shopQualificationCriteria });

    const handleTabsChanges = ({ target }) => changeActiveTabs(Number(target?.id));

    useEffect(() => {
        requestOnboardings(currentShopId);
        checkShopForErrors({ shopId: currentShopId });
        getIntercomUser();
    }, []);

    // TODO: remove this hook and request login link only on facebook button click (so we will prevent un requests every time)
    useEffect(() => {
        !isFacebookAuthorized && requestLoginLink();
    }, []);

    const [currentActiveCard, setCurrentActiveCard] = useState(ONBOARDING_TYPES.facebook_visitor_retargeting);
    const [isMobilePreview, setIsMobilePreview] = useState(false);

    return (
        <>
            <div className="home__board__wrapper">
                <ToDoList
                    shopId={currentShopId}
                    push={push}
                    onboardings={onboardings}
                    isGoogleAuthorized={isGoogleAuthorized}
                    googleLoginUrl={googleLoginUrl} />
                <div className="home__board">
                    <AiBoostedSalesInvite setIsLoading={setIsLoading}/>
                    <CustomerInterviewBannerWrapper shopId={currentShopId} isActive={false} />
                    <ResumePlanBanner
                        isVisible={shopError === 'shop-is-frozen-due-to-subscription-canceled'}
                        shopId={currentShopId}
                        setIsLoadingTrue={() => {
                            setIsLoading(true);
                        }}
                        onResumeBtnClick={() => {
                            checkShopForErrors({ shopId: currentShopId }).then(() => {
                                setIsLoading(false);
                            });
                        }}
                    />
                    <VerificationNotifications
                        type={getVerificationNotificationType(notifications)}
                        setIsLoading={setIsLoading}
                    />
                    {currentShop.type === STANDALONE ? <GeneralRestrictionBanner /> : <NotQualifiedBanner /> }

                    <div className="home__board__tabs" onClick={handleTabsChanges}>
                        {tabs.map(({ id, tab, type, content }) => (
                            <Link
                                onClick={() => {
                                    if (tab) {
                                        setLocalStorageObjectItem('activeTab', tab);
                                    }
                                }}
                                className="home__board__tab__link"
                                id={id}
                                key={id}
                                to={`/${currentShopId}/dashboard`}
                            >
                                <div
                                    className={`home__board__tab ${type} ${
                                        type === 'campaign' && 'home__board__tab_active'
                                    }`}
                                >
                                    {content}
                                </div>
                            </Link>
                        ))}
                    </div>

                    <div className="home__board__container">
                        {(!onboardings?.length) ? (
                            <div className="home__board__loader">
                                <Loader />
                            </div>
                        ) : (
                            <div className="home-board-inner">
                                <Cards
                                    currentShopId={currentShopId}
                                    onboardings={onboardings}
                                    isGoogleAuthorized={isGoogleAuthorized}
                                    googleLoginUrl={googleLoginUrl}
                                    isShopFreezedOrOnboardingDone={isShopFreezedOrOnboardingDone}
                                    currentShop={currentShop}
                                    currentActiveCard={currentActiveCard}
                                    setIsMobilePreview={setIsMobilePreview}
                                    loginLink={loginLink}
                                    isFacebookAuthorized={isFacebookAuthorized}
                                    setCurrentActiveCard={setCurrentActiveCard}
                                    disableButtons={disableButtons}
                                    redirect={push}
                                    requestSendEventMetrics={requestSendEventMetrics}
                                    shopError={shopError}
                                    setIsLoading={setIsLoading}
                                />
                                <div className={'home-board-inner__media'}>
                                    <Recommendations currentActiveCard={currentActiveCard} />
                                    <AdsPreviewSection
                                        setIsMobilePreview={setIsMobilePreview}
                                        isMobilePreview={isMobilePreview}
                                        currentActiveCard={currentActiveCard}
                                        additionalClass="home-board-inner__preview"
                                        isTogglerVisible={currentActiveCard !== 'tiktok'}
                                        isShopify={currentShop?.type === SHOPIFY}
                                        isHomePage
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </>
    );
};

const mapStateToProps = ({ shops, google, shopQualification, account, facebook, shopError, onboarding, shopNotifications }) => ({
    currentShop: getCurrentShopSelector(shops, shops?.currentShopId),
    currentShopId: shops?.currentShopId,
    googleLoginUrl: google?.login?.authUrl,
    isGoogleAuthorized: google?.login?.authorized,
    isFacebookAuthorized: !!account.entity?.fb_user_token,
    loginLink: facebook.login.loginLink,
    shopQualificationCriteria: shopQualification?.criterias,
    shopError: shopError.shopError.errorToken,
    onboardings: onboarding.onboardingsState.onboardings,
    notifications: shopNotifications.entity
});

const mapPropsToDispatch = {
    redirect: replace,
    push,
    requestLoginLink,
    setGoogleOnboardingState,
    requestSendEventMetrics,
    getIntercomUser,
    checkShopForErrors: validateShop,
    requestOnboardings,
};

export default pageNameSetter(changeShopPage.homepage)(connect(mapStateToProps, mapPropsToDispatch)(Home));
