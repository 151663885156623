import React from 'react';
import Button from '../components/Button';

import { BIG_COMMERCE, WOO_COMMERCE } from '../constants/platform';

export const fixedItButton = (clickHandler, params) => (
    <Button
        onClick={() => clickHandler(params)}
        className="button_link button_transparent notification__link_with-left-margin-24"
        color="transparent"
    >
        I Fixed It
    </Button>
);

export const getNoShopBannerLink = (shopType, shopDomain) => {
    if (shopType === BIG_COMMERCE) {
        return `http://${shopDomain}/manage/products`;
    } else if (shopType === WOO_COMMERCE) {
        return `http://${shopDomain}/wp-admin/edit.php?post_type=product`;
    }
    return `http://${shopDomain}/admin/products`;
};
