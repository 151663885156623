import React from 'react'
import './AudienceNetworkMobile.css'

export const AudienceNetworkMobile = ({product}) => (
        <div className='an-mobile'>
            <div className='an-mobile__adv'>
                <div className="an-mobile__adv-img-wrapper">
                    <img src={product.img} alt={product.title} />
                </div>
                <div className="an-mobile__adv-main">
                    <div className='an-mobile__adv-top'>
                        <div className="an-mobile__avatar" />
                        <div className="an-mobile__title-wrapper">
                            <div className="an-mobile__title">{product.title}</div>
                            <div className="an-mobile__subtitle">{product.subtitle}</div>
                        </div>
                    </div>
                    <div className="an-mobile__description">
                        {product.description}
                    </div>
                    <div className="an-mobile__btn">Shop now</div>
                </div>
            </div>
        </div>
    )