import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';

import DropdownWidget from 'components/DropdownWidget';

import { BIG_COMMERCE, WOO_COMMERCE, SHOPIFY, STANDALONE } from 'constants/platform';
import { metricType } from 'constants/metric';
import { setLocalStorageObjectItem } from 'helpers';

import shopifyIcon from './shopify-logo-icon.svg';
import bigcommerceIcon from './bigcommerce-logo-icon.svg';
import woocommerceIcon from './woocommerce-logo-icon.svg';
import standaloneIcon from './standalone-logo-icon.svg';
import './ShopifyStoreListWidget.css';

class ShopifyStoreListWidget extends PureComponent {
    componentDidUpdate(prevProps) {
        const { chosenShopifyStoreId, shopsList } = this.props;
        if (prevProps.chosenShopifyStoreId !== chosenShopifyStoreId) {
            let fromStore;
            let toStore;
            shopsList.forEach((shop) => {
                if (shop.id === prevProps.chosenShopifyStoreId) fromStore = shop.domain;
                if (shop.id === chosenShopifyStoreId) toStore = shop.domain;
            });
            setLocalStorageObjectItem('activeTab', '0');
        }
    }

    changeShopMetrics(shopId) {
        const { requestSendEventMetrics, changeShopMetricSource, chosenShopifyStoreId } = this.props;
        if (chosenShopifyStoreId !== shopId) {
            const metricProps = {
                shop_id: +chosenShopifyStoreId,
                type: metricType.switchedToAnotherStore,
                props: {
                    source: changeShopMetricSource,
                },
            };
            requestSendEventMetrics(metricProps);
        }
    }

    render() {
        const { shopsList, chosenShopifyStoreId } = this.props;

        if (!shopsList.length) {
            return null;
        }

        let chosenShopifyStoreName = 'Connected Shops';
        let chosenShopifyStoreDomain = 'listed below';
        let chosenStoreIcon = '';

        shopsList.some((shop) => {
            if (shop.id === chosenShopifyStoreId) {
                chosenShopifyStoreName = shop.name;
                chosenShopifyStoreDomain = shop.domain;
                if (shop.type === BIG_COMMERCE) {
                    chosenStoreIcon = bigcommerceIcon;
                } else if (shop.type === WOO_COMMERCE) {
                    chosenStoreIcon = woocommerceIcon;
                } else if (shop.type === STANDALONE) {
                    chosenStoreIcon = standaloneIcon;
                } else {
                    chosenStoreIcon = shopifyIcon;
                }
                return true;
            }
            return false;
        });

        let dropdownWidgetMenuList;

        if (!this.props.getActiveShopLink) {
            dropdownWidgetMenuList = shopsList.map((shop) => (
                <Link
                    to={`/${shop.id}/home`}
                    onClick={() => this.changeShopMetrics(shop.id)}
                    className={classNames(
                        'dropdown-widget__menu-item choose-shop-type dropdown-widget__menu-link',
                        { active: shop.id === chosenShopifyStoreId },
                        { 'type-bigcommerce': shop.type === BIG_COMMERCE },
                        { 'type-shopify': shop.type === SHOPIFY },
                        { 'type-woocommerce': shop.type === WOO_COMMERCE },
                        { 'type-standalone': shop.type === STANDALONE },
                    )}
                    key={shop.id}
                    id={shop.id}
                >
                    <p className="dropdown-widget__menu-item__title">{shop.name}</p>
                    <p className="dropdown-widget__menu-item__subtitle">{shop.domain}</p>
                </Link>
            ));
        } else {
            dropdownWidgetMenuList = shopsList.map((shop) => (
                <button
                    onClick={() => this.props.getActiveShopLink(shop.id)}
                    activelink={`/${shop.id}`}
                    className={classNames(
                        'dropdown-widget__menu-item choose-shop-type',
                        { active: shop.id === chosenShopifyStoreId },
                        { 'type-bigcommerce': shop.type === BIG_COMMERCE },
                        { 'type-shopify': shop.type === SHOPIFY },
                        { 'type-woocommerce': shop.type === WOO_COMMERCE },
                        { 'type-standalone': shop.type === STANDALONE },
                    )}
                    key={shop.id}
                    id={shop.id}
                >
                    <p className="dropdown-widget__menu-item__title">{shop.name}</p>
                    <p className="dropdown-widget__menu-item__subtitle">{shop.domain}</p>
                </button>
            ));
        }

        return (
            <DropdownWidget
                toggleIcon={chosenStoreIcon}
                toggleTitle={chosenShopifyStoreName}
                toggleSubTitle={chosenShopifyStoreDomain}
                menuList={dropdownWidgetMenuList}
                customWrapperClassName="shopify-store-list-wrapper"
                alignMenuToTogglesRightSide
                inNavbar
            />
        );
    }
}

ShopifyStoreListWidget.propTypes = {
    shopsList: PropTypes.arrayOf(PropTypes.object),
    chosenShopifyStoreId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShopifyStoreListWidget.defaultProps = {
    shopsList: null,
    chosenShopifyStoreId: null,
};

export default ShopifyStoreListWidget;
