import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

import { metricType } from 'constants/metric';
import Tabs from 'components/Tabs';
import TipsWidget from 'components/TipsWidget';
import { Card, CardTitle } from 'components/Card';
import NewModalPauseCampaign from 'components/ModalLearningPhase';
import ModalPauseCampaign from 'components/ModalPauseCampaign';

import ResumeCampaignButton from './ResumeCampaignButton';
import PauseCampaignButton from './PauseCampaignButton';
import EditCampaignButton from './EditCampaignButton';
import GoogleStandardAdPreview from '../../campaign/components/GoogleStandardAdPreview/GoogleStandardAdPreview';
import GoogleRemarketingAdPreview from '../../campaign/components/GoogleRemarketingAdPreview';
import { switchClassNameFromCampaignStatus, getGlDailyBudget } from './helpers';

import './CampaignDetails.css';

class GoogleCampaignDetails extends PureComponent {
    state = {
        isPauseCampaignModalVisible: false,
        isPauseAdsModalOpen: false,
    };

    togglePauseCampaignModal = () => {
        const { googleCampaignData, shopId, requestSendEventMetrics } = this.props;
        const { isPauseAdsModalOpen } = this.state;

        this.setState((state) => ({
                ...state,
                isPauseAdsModalOpen: false,
                isPauseCampaignModalVisible: !state.isPauseCampaignModalVisible,
            }));
    };

    componentDidMount() {
        const {
            requestGoogleStandardAdPreview,
            requestGoogleRemarketingAdPreview,
            campaignId,
            shopId,
            standardAd,
            remarketingAd,
        } = this.props;

        if (standardAd !== undefined) {
            standardAd.id && requestGoogleStandardAdPreview(shopId, campaignId, standardAd.id);
        }

        if (remarketingAd !== undefined) {
            remarketingAd.id && requestGoogleRemarketingAdPreview(shopId, campaignId, remarketingAd.id);
        }
    }

    handleDefaultModal = (isOpen) => this.setState({ isPauseCampaignModalVisible: isOpen });


    render() {
        const {
            googleCampaignData,
            titleTooltipContent,
            resumeCampaign,
            shopValidateRequesting,
            campaignPausePending,
            campaignResumePending,
            campaignId,
            shopId,
            campaignType,
            shop,
        } = this.props;

        const campaignStatusBadgeClassName = switchClassNameFromCampaignStatus(
            googleCampaignData.status,
            false,
            'dashboard-campaign__title-status',
            'dashboard-campaign__title-status_pending',
            'dashboard-campaign__title-status_active',
            'dashboard-campaign__title-status_paused',
            'dashboard-campaign__title-status_declined',
            'dashboard-campaign__title-status_creating',
            'dashboard-campaign__title-status_rejected',
            'dashboard-campaign__title-status_removed',
        );

        const TooltipAdTitle = () => (
            <TipsWidget isDarkIcon placement="top">
                <p className="tips-description__content">
                    Re-engage your site visitors who didn’t make a purchase with personalized ads of the previously
                    viewed products.
                </p>

                <p className="tips-description__content">
                    <a
                        href="https://help.adwisely.com/en/articles/3343845-smart-shopping"
                        className="link"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        I want to learn more →
                    </a>
                </p>
            </TipsWidget>
        );

        const tabsTitles = [];
        const tabsContent = [];

        googleCampaignData.ads &&
            googleCampaignData.ads.map((ad) => {
                if (ad.type.includes('Remarketing')) {
                    tabsTitles.push(
                        <div className="tabs-navigation__link-content-static">
                            <span>{ad.type}</span>
                            <TooltipAdTitle />
                        </div>,
                    );
                    tabsContent.push(<GoogleRemarketingAdPreview campaignId={campaignId} />);
                }
                if (ad.type.includes('Standard')) {
                    tabsTitles.push(
                        <div className="tabs-navigation__link-content-static">
                            <span>{ad.type}</span>
                            <TooltipAdTitle />
                        </div>,
                    );
                    tabsContent.push(<GoogleStandardAdPreview campaignId={campaignId} />);
                }
                return tabsTitles;
            });

        const getProductsInAdsFieldView = (campaign) => {
            if (campaign.products_in_ads) {
                return (
                    <li className="dashboard-campaign-info__item">
                        <CardTitle subTitle>Products in Ads</CardTitle>
                        <span className="dashboard-campaign-info__item-value">{campaign.products_in_ads}</span>
                    </li>
                );
            }
            return '';
        };

        const pauseCampaignModal = (
            <ModalPauseCampaign
                handleDefaultModal={this.handleDefaultModal}
                campaignId={googleCampaignData.id}
                isOpenModal={this.state.isPauseCampaignModalVisible}
                type={campaignType}
            />
        );

        return (
            <>
                <NewModalPauseCampaign
                    togglePauseCampaignModal={this.togglePauseCampaignModal}
                    campaignId={campaignId}
                    isOpenModal={this.state.isPauseAdsModalOpen}
                    type="google"
                />
                <Card disablePadding className="dashboard-campaign">
                    <div className="dashboard-campaign__header">
                        <div className="dashboard-campaign__header-wrapper">
                            <CardTitle>
                                <span>{googleCampaignData.name}</span>
                                <TipsWidget isDarkIcon isDarkView>
                                    <p className="tips-description__content">{titleTooltipContent}</p>
                                </TipsWidget>
                            </CardTitle>
                            <span className="dashboard-campaign__type">{googleCampaignData.type}</span>
                        </div>
                        <p className={campaignStatusBadgeClassName} />
                    </div>
                    <section className="dashboard-campaign__wrapper">
                        <section className="dashboard-campaign__aside">
                            <ul className="dashboard-campaign-info">
                                <li className="dashboard-campaign-info__item">
                                    <CardTitle subTitle>Daily Budget</CardTitle>
                                    <p className="dashboard-campaign-info__item-value">
                                        {getGlDailyBudget(googleCampaignData)}
                                    </p>
                                </li>
                                {getProductsInAdsFieldView(googleCampaignData)}
                            </ul>
                            <div className="dashboard-campaign__aside-nav">
                                <PauseCampaignButton
                                    clientStatus={googleCampaignData.status}
                                    shopValidateRequesting={shopValidateRequesting}
                                    pending={campaignPausePending}
                                    togglePauseCampaignModal={this.togglePauseCampaignModal}
                                    campaignId={googleCampaignData.id}
                                    campaignFailureError={googleCampaignData.campaignFailureError}
                                    campaignType={googleCampaignData.type}
                                    collapseAdMessageInput={false}
                                    type={campaignType}
                                    shopId={shopId}
                                />

                                <ResumeCampaignButton
                                    clientStatus={googleCampaignData.status}
                                    shopValidateRequesting={shopValidateRequesting}
                                    pending={campaignResumePending}
                                    campaignId={googleCampaignData.id}
                                    resumeCampaign={resumeCampaign}
                                    campaignFailureError={googleCampaignData.campaignFailureError}
                                    collapseAdMessageInput={false}
                                    campaignType={campaignType}
                                    shopId={shopId}
                                />

                                <EditCampaignButton
                                    campaignPausePending={campaignPausePending}
                                    campaignResumePending={campaignResumePending}
                                    clientStatus={googleCampaignData.status}
                                    shopValidateRequesting={shopValidateRequesting}
                                    campaignId={googleCampaignData.id}
                                    campaignType={googleCampaignData.type}
                                    shopId={shop.id}
                                    campaignFailureError={googleCampaignData.campaignFailureError}
                                    collapseAdMessageInput={false}
                                    isDefaultGlAdExist={
                                        googleCampaignData.ads &&
                                        googleCampaignData.ads.some(
                                            (ad) => ad.type === googleCampaignData.default_ad_type,
                                        )
                                    }
                                    type="google"
                                />
                            </div>
                        </section>

                        <section className="dashboard-campaign__main">
                            <Tabs tabsTitles={tabsTitles} tabsContent={tabsContent} />
                        </section>
                    </section>
                    {this.state.isPauseCampaignModalVisible && pauseCampaignModal}
                </Card>
            </>
        );
    }
}

GoogleCampaignDetails.defaultProps = {
    campaignPausePending: false,
    campaignResumePending: false,
    shopValidateRequesting: false,
    campaignType: 'google',
    remarketingAd: {},
    standardAd: {},
};

GoogleCampaignDetails.propTypes = {
    campaignId: PropTypes.number.isRequired,
    campaignPausePending: PropTypes.bool,
    campaignResumePending: PropTypes.bool,
    campaignType: PropTypes.string,
    googleCampaignData: PropTypes.shape({
        ads: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            }),
        ),
        default_ad_type: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    remarketingAd: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    requestGoogleRemarketingAdPreview: PropTypes.func.isRequired,
    requestGoogleStandardAdPreview: PropTypes.func.isRequired,
    resumeCampaign: PropTypes.func.isRequired,
    shop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    shopId: PropTypes.string.isRequired,
    shopValidateRequesting: PropTypes.bool,
    standardAd: PropTypes.shape({
        id: PropTypes.string,
    }),
    titleTooltipContent: PropTypes.string.isRequired,
};

export default GoogleCampaignDetails;
