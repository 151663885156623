import * as intercomEvent from '../libs/intercomEventConst';
import {
    SPECIAL_OFFER,
    CUSTOM,
    VISITOR_RETARGETING, AI_BOOSTED_SALES
} from '../constants/campaign';

import {callAPIGetIntercomUser} from "./api";

export const INTERCOM_TRACK_EVENT = 'INTERCOM_TRACK_EVENT';

const getCurrentShopDomain = (state) => {
    const {shops} = state;
    const currentShopId = parseInt(shops.currentShopId, 10);
    let shopDomain = '';
    shops.entities.some((shop) => {
        if (shop.id === currentShopId) {
            shopDomain = shop.domain;
            return true;
        }
        return false;
    });
    return shopDomain;
};

/**
 *
 * @param {string} type - Title of intercom event
 * @param {object} meta - Metadata of intercom event
 * @param {bool} withoutShopDomain - The marker that indicates if there is a need to add a shop domain
 * to event metadata in intercom middleware
 */
export const trackEvent = (type, meta, withoutShopDomain) => (dispatch, getState) => {
    if (withoutShopDomain) {
        return dispatch({
            type: INTERCOM_TRACK_EVENT,
            payload: {
                type,
                meta,
            },
        });
    }
    return dispatch({
        type: INTERCOM_TRACK_EVENT,
        payload: {
            type,
            meta: {
                ...meta,
                shop_domain: getCurrentShopDomain(getState()),
            },
        },
    });
};

export const trackEventFacebookLoginDeclined = () => trackEvent(intercomEvent.FacebookLoginDeclined);

export const trackEventAdMessageUpdateOnEditCampaign = (type, meta) => {
    if (type === VISITOR_RETARGETING) return trackEvent(intercomEvent.StandardCampaignAdMessageUpdatedViaEditCampaign, meta);
    if (type === SPECIAL_OFFER) return trackEvent(intercomEvent.SpecialOfferCampaignAdMessageUpdatedViaEditCampaign, meta);
    if (type === AI_BOOSTED_SALES) return trackEvent(intercomEvent.AiBoostedCampaignAdMessageUpdatedViaEditCampaign, meta);

};

export const trackEventCampaignResumed = (type) => {
    if (type === CUSTOM) return trackEvent(intercomEvent.CustomCampaignResumed);
};

export const trackEventInitiateCampaignPause = (type) => {
    if (type === CUSTOM) return trackEvent(intercomEvent.CustomCampaignPause);
};

export const trackEventStandardCampaignDateRangeChanged = meta =>
    (trackEvent(intercomEvent.StandardCampaignDateRangeChanged, meta));

export const trackEventStandardCampaignViewDetailedFunnel = () =>
    (trackEvent(intercomEvent.StandardCampaignViewDetailedFunnel));

/**
 * STRIPE ADD CARD
 */

export const trackEventInformBillingUpdate = () => trackEvent(intercomEvent.InformBillingUpdate);
export const trackEventGoogleBannerClicked = () => trackEvent(intercomEvent.GooogleBannerClicked);
export const trackEventGoogleLoggedIn = () => trackEvent(intercomEvent.GoogleLoggedIn);

/**
 * EXTEND TRIAL PERIOD
 */

export const trackEventExtendTrialBadge = () => trackEvent(intercomEvent.ExtendTrialBadgeClicked);
export const trackEventExtendTrialBadgeBC = () => trackEvent(intercomEvent.ExtendTrialBadgeClickedBC);
export const trackEventExtendTrialBadgeWC = () => trackEvent(intercomEvent.ExtendTrialBadgeClickedWC);

/**
 * BLACK FRIDAY
 */

export const trackEventXmasclickedCTA = type => trackEvent(`Clicked on Christmas CTA button ${type} campaign`);
export const trackEventXmasDBincreased = type => trackEvent(`Christmas ${type} DB increased`);


/**
 * PROMO BANNER
 */

export const trackEventPromoBannerCTAClicked = (promoType) => {
    let type = '';
    if (promoType === 'black_friday') type = 'black friday';
    if (promoType === 'cyber_monday') type = 'cyber monday';
    if (promoType === 'christmas') type = promoType;
    return trackEvent(`${type} banner cta clicked`);
};

export const GET_INTERCOM_USER_REQUEST = 'GET_INTERCOM_USER_REQUEST';
export const GET_INTERCOM_USER_SUCCESS = 'GET_INTERCOM_USER_SUCCESS';
export const GET_INTERCOM_USER_FAILURE = 'GET_INTERCOM_USER_FAILURE';

export const getIntercomUser = (params) => (dispatch) => {
    dispatch(
        callAPIGetIntercomUser({
            params,
            types: [GET_INTERCOM_USER_REQUEST, GET_INTERCOM_USER_SUCCESS, GET_INTERCOM_USER_FAILURE]
        })
    )
}

