import React, { useState } from "react";
import classNames from 'classnames';
import "./Popover.css";

const Popover = (props) => {
    const [active, setActive] = useState(false);

    const togglePopover = () => {
        setActive(!active);
    };

    return (
        <div
            className="popover__wrapper"
            onClick={togglePopover}
        >
            {props.children}
            {active && (
                <div
                    className={classNames(
                        `popover-inner`,
                        props.placement,
                        props.additionalClassName,
                    )}
                >
                    {props.content}
                </div>
            )}
        </div>
    );
}

export default Popover;