import React from 'react';
import intercom from 'libs/intercom';

export const DATE_RANGES = {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST7DAYS: 'last_7d',
    LAST14DAYS: 'last_14d',
    LAST30DAYS: 'last_30d',
    LIFETIME: 'lifetime',
    CUSTOM: 'custom',
};

export const dateRangeList = [
    { id: DATE_RANGES.TODAY, name: 'Today' },
    { id: DATE_RANGES.YESTERDAY, name: 'Yesterday' },
    { id: DATE_RANGES.LAST7DAYS, name: 'Last 7 Days' },
    { id: DATE_RANGES.LAST14DAYS, name: 'Last 14 Days' },
    { id: DATE_RANGES.LAST30DAYS, name: 'Last 30 Days' },
    { id: DATE_RANGES.LIFETIME, name: 'Lifetime' },
    { id: DATE_RANGES.CUSTOM, name: 'Custom' },
];

export const STATISTICS_DATA = {
    CPP: 'cpp',
    CPC: 'cpc',
    CTR: 'ctr',
    ROAS: 'roas',
    SPEND: 'spend',
    REACH: 'reach',
    PURCHASES: 'purchases',
    LINK_CLICKS: 'link_clicks',
    ADD_TO_CARD: 'add_to_card',
    PRODUCT_VIEWS: 'products_views',
    PURCHASES_VALUE: 'purchases_value',
};

export const getStatisticsDataToRender = (currency) => ({
    purchases: {
        name: STATISTICS_DATA.PURCHASES,
        title: 'Purchases',
        tips: (
            <div>
                <div className="tips-description__inner-title">Purchases</div>
                <p className="tips-description__content">
                    Number of purchases your customers made after interacting with Adwisely-powered ads
                </p>
            </div>
        ),
        prefix: null,
        currency: null,
        postfix: null,
        isFloat: false,
    },
    spend: {
        name: STATISTICS_DATA.SPEND,
        title: 'Ad Spend',
        tips: (
            <div>
                <div className="tips-description__inner-title">Ad Spend</div>
                <p className="tips-description__content">
                    Total amount you spent on Adwisely-powered ads within the selected period
                </p>
            </div>
        ),
        warning: (
            <div>
                <p className="tips-description__content">
                    Your total ad spend has exceeded the plan limit. In beginning of the next
                    billing period, you will pay 10% fee on all the spend above the limit.
                </p>
                <a
                    href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-adwisely"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tips-description__link"
                >
                    Learn how to upgrade your plan →
                </a>
            </div>
        ),
        prefix: null,
        currency,
        postfix: null,
        isFloat: true,
    },
    roas: {
        name: STATISTICS_DATA.ROAS,
        title: 'Return On Ad Spend',
        tips: (
            <div>
                <div className="tips-description__inner-title">Return On Ad Spend</div>
                <p className="tips-description__content">Percentage of Revenue compared to Ad Spend</p>
            </div>
        ),
        prefix: null,
        currency: null,
        postfix: '%',
        isFloat: true,
    },
    purchases_value: {
        name: STATISTICS_DATA.PURCHASES_VALUE,
        title: 'Revenue',
        tips: (
            <div>
                <div className="tips-description__inner-title">Revenue</div>
                <p className="tips-description__content">
                    Total value of all purchases made after interacting with Adwisely-powered ads
                </p>
            </div>
        ),
        prefix: null,
        currency,
        postfix: null,
        isFloat: true,
    },

    link_clicks: {
        name: STATISTICS_DATA.LINK_CLICKS,
        title: 'Link Clicks',
        tips: (
            <div>
                <div className="tips-description__inner-title">Link Clicks</div>
                <p className="tips-description__content">
                    Total number of times people came to your store after clicking or tapping on Adwisely-powered ads
                </p>
            </div>
        ),
        prefix: null,
        currency: null,
        postfix: null,
        isFloat: false,
    },
    cpc: {
        name: STATISTICS_DATA.CPC,
        title: 'Cost per Click',
        tips: (
            <div>
                <div className="tips-description__inner-title">Cost per Click</div>
                <p className="tips-description__content">
                    Total Ad Spend divided by the number of Link Clicks on Adwisely-powered ads
                </p>
            </div>
        ),
        prefix: null,
        currency,
        postfix: null,
        isFloat: true,
    },
    ctr: {
        name: STATISTICS_DATA.CTR,
        title: 'Link Click-Through Rate',
        tips: (
            <div>
                <div className="tips-description__inner-title">Link Click-Through Rate</div>
                <p className="tips-description__content">
                    Percentage of Link Clicks compared to the total number of the times Adwisely-powered ads were
                    shown
                </p>
            </div>
        ),
        prefix: null,
        currency: null,
        postfix: '%',
        isFloat: true,
    },
    cpp: {
        name: STATISTICS_DATA.CPP,
        title: 'Cost per Purchase',
        tips: (
            <div>
                <div className="tips-description__inner-title">Cost per Purchase</div>
                <p className="tips-description__content">
                    Total Ad Spend divided by the number of Purchases from Adwisely-powered ads
                </p>
            </div>
        ),
        prefix: null,
        currency,
        postfix: null,
        isFloat: true,
    },

    reach: {
        name: STATISTICS_DATA.REACH,
        title: 'Reach',
        tips: null,
        prefix: null,
        currency: null,
        postfix: null,
    },
    products_views: {
        name: STATISTICS_DATA.PRODUCT_VIEWS,
        title: 'Product Views',
        tips: null,
        prefix: null,
        currency: null,
        postfix: null,
    },
    add_to_card: {
        name: STATISTICS_DATA.ADD_TO_CARD,
        title: 'Add To Cart',
        tips: null,
        prefix: null,
        currency: null,
        postfix: null,
    },
});

export const getStatisticsDataToRenderMainList = (statisticsDataToRender) =>
    [STATISTICS_DATA.PURCHASES, STATISTICS_DATA.SPEND, STATISTICS_DATA.ROAS, STATISTICS_DATA.PURCHASES_VALUE].map(
        (item) => statisticsDataToRender[item],
    );

export const getStatisticsDataToRenderSubsectionList = (statisticsDataToRender) =>
    [STATISTICS_DATA.LINK_CLICKS, STATISTICS_DATA.CPC, STATISTICS_DATA.CTR, STATISTICS_DATA.CPP].map(
        (item) => statisticsDataToRender[item],
    );

export const getStatisticsDataToRenderMainPurchaseTipsList = (statisticsDataToRender) =>
    [
        STATISTICS_DATA.LINK_CLICKS,
        STATISTICS_DATA.PRODUCT_VIEWS,
        STATISTICS_DATA.ADD_TO_CARD,
        STATISTICS_DATA.PURCHASES,
        STATISTICS_DATA.PURCHASES_VALUE,
    ].map((item) => statisticsDataToRender[item]);

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const formatDropdownDate = (date) => {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substr(-2);

    return `${day} ${monthNames[monthIndex].toUpperCase()} ${year}`;
};
