export const CUSTOM = 'custom';
export const RETARGETING = 'retargeting';
export const PROSPECTING = 'prospecting';
export const SPECIAL_OFFER = 'special_offer';
export const DEFAULT_FILTER_CAMPAIGN = 'all';
export const DYNAMIC_RETARGETING = 'dynamic_retargeting';
export const VISITOR_RETARGETING = 'visitor_retargeting';
export const SOCIAL_RETARGETING = 'social_media_retargeting';
export const AI_BOOSTED_SALES = 'ai_boosted_sales';

export const CAMPAIGNS_TYPES = {
    google: 'google',
    facebook: 'facebook',
};

export const changeDailyBudgetPage = {
    dashboard: 'dashboard',
    editCampaignPage: 'edit_campaign',
};
