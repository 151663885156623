import React from 'react';
import { Link } from 'react-router';

import './NotFound.css';
import logo from '../logo.svg';

const NotFound = () => (
    <div className="not-found__container">
        <div className="not-found__logo-container">
            <img src={logo} alt="Adwisely" className="not-found__logo" />
        </div>
        <h1 className="not-found__title text-center">Store not found</h1>
        <p className="text-center">
            You may be logged out or use a wrong link. Please, click {''}
            <Link to="/">here</Link> to continue.
        </p>
        <p className="text-center">
            Got an urgent request? Open {''}
            <a id="open_chat" href="mailto:help@adwisely.com">
                chat
            </a>{' '}
            {''}
            or send email to {''}
            <a href="mailto:help@adwisely.com">help@adwisely.com</a>
        </p>
    </div>
);

export default NotFound;
