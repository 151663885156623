import React from 'react';
import { ToDoListModalView } from './ToDoListModalView';

export const PrioritizePixelEventsModal = ({isOpen, closeModal, onSupportBtnClick, onCompleteBtnClick}) => (
    <ToDoListModalView
        isOpen={isOpen}
        onCloseBtnClick={closeModal}
        checkboxLabel="I have prioritized Pixel Events"
        onSupportBtnClick={onSupportBtnClick}
        onCompleteBtnClick={onCompleteBtnClick}
    >
        <h3 className="todo-list-modal__title">Prioritize Pixel events</h3>
        <p>To ensure maximum efficiency of the ads powered by Adwisely, prioritize the events on the Meta Pixel you shared with Adwisely.</p>

        <ol>
            <li>In your <a href="https://www.facebook.com/events_manager" target="_blank" rel="noopener noreferrer">Events Manager</a>, pick the Pixel you want to set up</li>
            <li>Under <b>Aggregated Event Management</b>, select <br /> <b>Manage Events</b></li>
            <li>Select your store domain and click <b>Manage Events</b></li>
            <li>Drag <b>Purchase</b> event to the Highest priority.
                Place <b>Add to cart</b>, <b>Initiate checkout</b>, and <b>View content</b> right under it.
                <span>Note: If you cannot see one or more of the events, click Add event</span>
            </li>
            <li>Click <b>Apply</b>, tick the checkbox once you review the changes and click <b>Apply</b> again</li>
        </ol>
    </ToDoListModalView>
)