import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { requestCampaignSuggestionsForCampaignCreate } from 'actions/campaign';
import { goToShopifyBilling, goToStripeBilling, goToChoosePlan } from 'actions/onboarding';
import { validateShop } from 'actions/shop';

import PricingWidget from 'components/PricingWidget/PricingWidget';

import { getCurrentShopSelector } from 'helpers/selectors';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import { saveCurrentShopInfo } from 'helpers/onboardingHelper';

import { SPENDS_PERCENTS, SPENDS_PRICE } from 'constants/userBillingType';
import { PROSPECTING } from 'constants/campaign';
import { SHOPIFY } from 'constants/platform';

import CampaignInfo from 'modules/campaign/components/CampaignInfo';
import CampaignDetails from 'modules/campaign/components/CampaignDetails';
import SaveCampaignDetails from 'modules/campaign/components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from 'modules/campaign/components/CampaignPageLayout';
import ProspectingPreview from './ProspectingPreview';

const CAMPAIGN_AD_MESSAGE_DEFAULT = 'You gonna love 😍 this. Treat yourself, you deserved it.';

export class SetUpProspecting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignMinBudget: 0,

            // save values before saving to localStorage:
            dailyBudget: 0,
            adMessage: '',

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            saveCampaignError: null,
        };
        this.handleFormError = this.handleFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);
        this.saveCampaignHandler = this.saveCampaignHandler.bind(this);
        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
            checkShopForErrors,
        } = this.props;
        requestSuggestions({ id: shopId });
        checkShopForErrors({ shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let newRecurringChargeStatus = null;
        let cappedAmount = null;

        if (nextProps.shopRecurringCharge.entity) {
            newRecurringChargeStatus = nextProps.shopRecurringCharge.entity.status;
            cappedAmount = parseInt(nextProps.shopRecurringCharge.entity.capped_amount, 10);
        }

        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = CAMPAIGN_AD_MESSAGE_DEFAULT;

                this.setState({
                    campaignBudget: suggestionsData.daily_budget.prospecting_suggested_value / 100,
                    campaignMinBudget: suggestionsData.daily_budget.min_value / 100,
                    campaignCurrency: suggestionsData.daily_budget.currency,
                    campaignAdMessage: suggestedAdMessage,
                });
            }
        }
    }

    handleFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        setTimeout(() => {
            this.setState({
                adMessageTagsHasError: !!tipsErrorText,
                adMessageTagsHasErrorTipsErrorText: tipsErrorText,
            });
        }, 0);
    }

    saveCampaignHandler(dailyBudget, adMessage) {
        const {
            redirectToChoosePlan,
            redirectToShopifyBilling,
            redirectToStripeBilling,
            params: { shopId },
            currentShop,
            businessModel,
            subscriptionPlan,
        } = this.props;

        campaignCreateStorageHelper.setCampaign(PROSPECTING, dailyBudget, adMessage, shopId);

        saveCurrentShopInfo(currentShop.id);

        if (![SPENDS_PERCENTS, SPENDS_PRICE].includes(businessModel) && !subscriptionPlan) {
            redirectToChoosePlan(shopId);
        } else {
            (currentShop.type === SHOPIFY) ? redirectToShopifyBilling(shopId) : redirectToStripeBilling(shopId);
        }
    }

    campaignValidateFacebookHandler(params) {
        const {
            shopValidateRequesting,
            checkShopForErrors,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId, params).then((res) => {
                if (!(res instanceof Error)) {
                    checkShopForErrors({ shopId });
                }
            });
        }
    }

    render() {
        const campaignFormName = 'prospectingCampaignCreate';
        const {
            shopError,
            params: { shopId },
            shopRecurringCharge,
            suggestions: { campaignSuggestionsData },
        } = this.props;

        const allowedErrors = [
            'payment-method-not-found',
            'shop-is-frozen-due-to-pending-recurring-charge',
            'shop-is-frozen-due-to-expired-recurring-charge',
            'shop-is-frozen-due-to-cancelled-recurring-charge',
        ];

        return (
            <div className="create-prospecting-campaign__wrapper">
                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            showIcon
                            shopId={shopId}
                            campaignTitle="Prospecting"
                            learnMoreLink={
                                'https://help.adwisely.com/improve-your-campaign-results/' +
                                'learn-about-advertising-tactics/prospecting'
                            }
                            campaignDescription={
                                'Boost sales and acquire more customers by targeting new ' +
                                'users who are similar to your current buyers with the ads of ' +
                                'the products they are most likely to purchase.'
                            }
                            customPricingWidget={
                                <PricingWidget
                                    pricingItemTitle="Prospecting Ad Spend"
                                    description={
                                        'Adwisely fee is based on your total Monthly Ad Spends ' +
                                        'and can not be less than $15.00 per month'
                                    }
                                />
                            }
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={() => {}}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={this.state.campaignAdMessage}
                            campaignCurrency={this.state.campaignCurrency}
                            campaignMinBudget={this.state.campaignMinBudget}
                            campaignBudget={this.state.campaignBudget}
                            pricingAdviceItems={
                                campaignSuggestionsData && campaignSuggestionsData.daily_budget
                                    ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                                    : []
                            }
                            saveCampaign={this.saveCampaignHandler}
                            useDynamicTagsDropdown={false}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            dailyBudgetDescription={
                                'Daily budget is the maximum amount of money you are ready ' +
                                'to spend on your ads within a 24-hour period.'
                            }
                            saveCampaignError={this.state.saveCampaignError}
                            campaignType={PROSPECTING}
                        />

                        <SaveCampaignDetails
                            formName={campaignFormName}
                            pending={shopRecurringCharge.updateUrlPending}
                            buttonTitle="Continue"
                            buttonColor="primary"
                            hasCampaignFormError={
                                shopError.errorToken && !allowedErrors.includes(shopError.errorToken) ||
                                this.state.adMessageTagsHasError ||
                                !!this.state.hasCampaignFormError
                            }
                            tipsErrorText={this.state.adMessageTagsHasErrorTipsErrorText || this.state.tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <ProspectingPreview isAdExample />
                    </CampaignPageCol>
                </CampaignPageLayout>
            </div>
        );
    }
}

SetUpProspecting.defaultProps = {
    shopError: null,
};

SetUpProspecting.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    campaignAutoCreateType: PropTypes.shape({
        type: PropTypes.string
    }).isRequired,
    campaignValidateFacebook: PropTypes.func.isRequired,
    checkShopForErrors: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        app_uninstalled_at: PropTypes.any,
        business_model: PropTypes.string,
        created_at: PropTypes.string,
        credentials: PropTypes.any,
        domain: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        owner_email: PropTypes.string,
        owner_name: PropTypes.string,
        platform_id: PropTypes.string,
        review_note: PropTypes.string,
        review_rate: PropTypes.any,
        review_status: PropTypes.string,
        stripe_customer_id: PropTypes.string,
        type: PropTypes.string,
        updated_at: PropTypes.string
    }).isRequired,
    params: PropTypes.shape({
        shopId: PropTypes.string.isRequired
    }).isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    shopRecurringCharge: PropTypes.shape({
        entity: PropTypes.shape({
            confirmation_url: PropTypes.string,
            capped_amount: PropTypes.string,
            status: PropTypes.string,
        }),
        pending: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        updateUrlPending: PropTypes.bool.isRequired,
    }).isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsData: PropTypes.shape({
            daily_budget: PropTypes.shape({
                suggested_value: PropTypes.number,
                prospecting_suggested_value: PropTypes.number,
                min_value: PropTypes.number,
                currency: PropTypes.string,
            }),
            ad_message: PropTypes.string
        }),
        campaignSuggestionsSuccess: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = ({
    campaignSuggestions,
    shops,
    shopError: { shopError, shopValidateRequesting },
    shopRecurringCharge,
    campaignAutoCreateType,
    shopBillingInfo,
    shopSubscription,
}) => ({
    currentShop: getCurrentShopSelector(shops, shops.currentShopId),
    suggestions: campaignSuggestions,
    shops,
    shopError,
    shopRecurringCharge,
    campaignAutoCreateType,
    shopValidateRequesting,
    businessModel: shopBillingInfo.businessModel,
    subscriptionPlan: shopSubscription.name,
});

const mapDispatchToProps = {
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    checkShopForErrors: validateShop,
    redirectToChoosePlan: goToChoosePlan,
    redirectToShopifyBilling: goToShopifyBilling,
    redirectToStripeBilling: goToStripeBilling,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetUpProspecting);
