import PropTypes from "prop-types"
import React from 'react';

import TipsWidget from 'components/TipsWidget';
import Button from 'components/Button';
import { ROUTES } from 'constants/routes';
import './EditCampaignDetails.css';

export const EditCampaignDetails = ({ formName, hasCampaignFormError, shopId, tipsErrorText, campaignEditPending }) => {
    const goToDashboard = `/${shopId}${ROUTES.dashboard}`;

    const disabledButtonTips = (
        <TipsWidget
            isDarkView
            placement="top"
            additionalClassName="edit-campaign-details-cta-button__tips-wrapper"
            additionalIconClassName="edit-campaign-details-cta-button__tips-icon"
        >
            <div>
                <p className="tips-description__content">{tipsErrorText}</p>
            </div>
        </TipsWidget>
    );

    return (
        <section className="edit-campaign-details__wrapper">
            <Button
                innerUrl={goToDashboard}
                className="edit-campaign-details__cta-button-secondary"
                disabled={campaignEditPending}
            >
                Cancel Changes
            </Button>

            <Button
                pending={campaignEditPending}
                color="primary"
                submit
                form={formName}
                disabled={!!hasCampaignFormError || !!campaignEditPending}
            >
                <span>Save Changes</span>

                {hasCampaignFormError && (tipsErrorText.length || tipsErrorText.type) && !campaignEditPending
                    ? disabledButtonTips
                    : ''}
            </Button>
        </section>
    );
};

EditCampaignDetails.defaultProps = {
    hasCampaignFormError: null,
    campaignEditPending: false,
};

EditCampaignDetails.propTypes = {
    campaignEditPending: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    hasCampaignFormError: PropTypes.bool,
    shopId: PropTypes.string.isRequired,
};

export default EditCampaignDetails;
