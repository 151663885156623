import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { logException } from 'libs/sentryConfig';

import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import ConnectedAssets from 'modules/onboarding/components/ConnectedAssets';
import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import './ConnectPage.css';

const ConnectPage = ({ currentShopId, replace }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPagesPending, setIsPagesPending] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(null);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        setIsPagesPending(!isPagesPending);
        const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
        currentOnboarding && fetch(`/api/onboarding/${currentShopId}/${currentOnboarding}/pages`)
            .then((res) => {
                if (!res.ok) {
                    const err = new Error(`Status code: ${res.status}`);
                    err.status = res.status;
                    err.statusText = res.statusText;
                    throw err;
                }
                return res.json();
            })
            .then((res) => {
                if (!(res instanceof Error)) {
                    (res.data?.length === 1) ? setSelectedPageId(res.data[0]?.id) : null;
                    setPages(res.data);
                    setIsPagesPending(!isPagesPending);
                }
            })
            .catch((error) => {
                logException(
                    `Couldn't fetch UBM Pages. Reason: ${error.statusText}`,
                    error,
                    { currentShopId },
                    'javascript',
                    'error',
                );
            });
    }, []);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.assign_system_user_to_page,
            currentShopId,
            data: { page_fb_id: selectedPageId },
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
    }

    const onChange = (pageId) => setSelectedPageId(pageId);
    
    const chosenPage = pages?.find((page) => +page.id === +selectedPageId);

    let fbPageSelector = null;

    if (isPagesPending) {
        fbPageSelector = <p className="connect-page__description">Loading...</p>
    }

    if (pages?.length > 1) {
        fbPageSelector = <DropdownProvider>
            {({ toggle, isOpen }) => {
                const dropdownItems = pages?.map((page, key) => (
                    <DropdownItemMain
                        key={key}
                        value={page.id}
                        onClick={() => {
                            onChange(page.id);
                            toggle();
                        }}
                        active={+page.id === +selectedPageId}
                        title={`${page.name}`}
                        subtitle={getFbPageIdLink(page.link, page.id)}
                    />
                ));

                const flagToggler = (
                    <FlagToggler
                        title={chosenPage ? chosenPage.name : null}
                        subtitle={chosenPage ? getFbPageIdLink(chosenPage.link, chosenPage.id) : null}
                    >
                        Select Facebook Page
                    </FlagToggler>
                );

                return (
                    <Dropdown toggle={toggle} isOpen={isOpen} flagToggler={flagToggler} items={dropdownItems} />
                )
            }}
        </DropdownProvider>
    }

    const getFbPageIdLink = (link, id) => <a href={link} target="_blank" rel="noopener noreferrer">{id}</a>;

    if (pages?.length === 1) {
        fbPageSelector = <p className="connect-page__description">
            <strong>[{pages[0]?.name}] (ID: {getFbPageIdLink(pages[0]?.link, pages[0]?.id)})</strong>
        </p> 
    }

    return (
        <div className="connect-page__wrapper">
            <div className="connect-page__body-wrapper">
                <div>
                    <h1 className="connect-page__title">Connect your Facebook page to Adwisely</h1>
                    <p className="connect-page__description">
                        Adwisely runs ads on behalf of your Facebook Page. <br />
                        Choose the Page you'd like us to feature in ads.
                    </p>
                    <h6>Facebook Page</h6>
                    {fbPageSelector}
                </div>
                <div>
                    <ConnectedAssets />
                    <BookACallSection />
                </div>
            </div>
            <Button onClick={handleContinueBtn} color="primary" disabled={!selectedPageId}>
                Continue
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ConnectPage);
