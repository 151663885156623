import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RETARGETING } from 'constants/campaign';
import { RETARGETING_INVITE } from 'constants/notificationType';
import { shopHasNotification } from 'helpers';
import { fetchRetargetingOnboardingState } from 'modules/onboarding/userBMFlow/helpers/getOnboardingState';

import RetargetingInvite from './RetargetingInvite';

const RetargetingInviteWrapper = ({
    shopId,
    showRetargetingBanner,
    shopValidateSuccess,
    ownerType,
}) => {
    const [toRemindLater, setToRemindLater] = useState(false);
    const [retargetingState, setRetargetingState] = useState(null);

    useEffect(() => {
        fetchRetargetingOnboardingState(shopId).then(res => setRetargetingState(res?.state));
    }, []);

    const isVisible = ownerType === 'user'
        ? showRetargetingBanner && shopValidateSuccess && retargetingState === 'not_started'
        : showRetargetingBanner && shopValidateSuccess;

    return <>{isVisible && !toRemindLater && <RetargetingInvite shopId={shopId} setToRemindLater={setToRemindLater} ownerType={ownerType} />}</>;
};

RetargetingInviteWrapper.propTypes = {
    shopId: PropTypes.string.isRequired,
    showRetargetingBanner: PropTypes.bool.isRequired,
};

const showRetargetingBannerSelector = (campaignDetailsData, shopNotifications) => {
    if (!campaignDetailsData.some((campaign) => campaign.type === RETARGETING)) {
        return !!shopHasNotification(shopNotifications, RETARGETING_INVITE);
    }
    return false;
};

const mapStateToProps = (state) => ({
    shopValidateSuccess: state.shopError.shopValidateSuccess,
    showRetargetingBanner: showRetargetingBannerSelector(
        state.campaignDetails.campaignDetailsData,
        state.shopNotifications.entity,
    ),
    ownerType: state.shopsCampaignsSettings.entity.fb_business_manager_owner_type,
});

export default connect(mapStateToProps)(RetargetingInviteWrapper);
