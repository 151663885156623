import React from 'react';

import {LoaderFullScreen, LoaderLongWait} from 'components/Loader';
import ConnectedAssets from 'modules/onboarding/components/ConnectedAssets';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';

import './InstagramAccount.css';

export const InstagramAccount = ({children, isLoading}) => (
    <>
        <div className='connect-instagram'>
            <div className="connect-instagram__main">
                <h1 className="connect-instagram__title">Connect your Instagram profile</h1>
                {children}
            </div>
            <div className="connect-instagram__side">
                <ConnectedAssets />
                <BookACallSection />
            </div>

        </div>
        <LoaderFullScreen
            isLoaderShowed={isLoading}
        >
            <LoaderLongWait title="Loading..." />
        </LoaderFullScreen>
    </>

)