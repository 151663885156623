import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { redirect } from 'actions/redirect';
import * as accountActions from 'actions/account';
import * as onboardingActions from 'actions/onboarding';
import { requestShops, goToNextStep, requestSendEventMetrics } from 'actions/shop';

import TipsWidget from 'components/TipsWidget';
import ModalTryAgain from 'components/ModalError';
import ModalConnectPageError from 'components/ModalConnectPageError';
import ModalWindowWidget from 'components/ModalWindowWidget';
import IntercomChatCaller from 'components/IntercomChatCaller';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeShopPage } from 'constants/metric';
import { GRANTED } from 'constants/fbInvitaionStatuses';
import { REQUEST_STATUS_PENDING } from 'constants/requestStatuses';
import { BIG_COMMERCE, SHOPIFY, WOO_COMMERCE } from 'constants/platform';

import pageNameSetter from 'decorator/pageNameSetter';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import { getCurrentBMUserSelector } from 'helpers/selectors';
import { trackBookCall } from 'helpers/metrics';
import { once } from 'helpers';

import PagesInfo from './components/PagesInfo';
import FbPageItemsList from './components/PageItemsList';
import PageNotifications from './components/PageNotifications';

import * as pageAsyncHelper from './pageAsyncHelper';

import './Page.css';

const BookACallSection = ({ shopId, requestSendEventMetrics }) => (
    <div className="fb-page-help">
        <h3 className="fb-page-help__title">Need help?</h3>
        <p className="fb-page-help__caption">Our managers will be happy to assist!</p>
        <a
            href="https://calendly.com/adwisely-support-team/support-team"
            target="_blank"
            rel="noopener noreferrer"
            className="fb-page-help__link"
            onClick={() => trackBookCall(shopId, requestSendEventMetrics, 'connect_page')}
        >
            Book a call
        </a>
    </div>
);

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalFbPagesList: false,
            isOpenTryAgainModal: false,
            isOpenConnectPageErrorModal: false,
            isConnectPageErrorModalShown: false,
            isPageSaved: props.fbPageInfoIsSaved,
        };

        this.getPublishedFbPages = this.getPublishedFbPages.bind(this);
        this.getFbPagesOnTabFocus = this.getFbPagesOnTabFocus.bind(this);
        this.handleGoToNextStep = this.handleGoToNextStep.bind(this);
        this.handleCreateNewFbPage = this.handleCreateNewFbPage.bind(this);
        this.handleChosenFbPage = this.handleChosenFbPage.bind(this);
        this.toggleModalFbPagesList = this.toggleModalFbPagesList.bind(this);
        this.tryAgainHandler = this.tryAgainHandler.bind(this);
    }

    componentDidMount() {
        const { isPageSaved } = this.state;
        const {
            account,
            facebook,
            accountInfo,
            getFbPages,
            params: { shopId },
            shopsCampaignsSettings,
            businessManager,
            setFBPageAndCreateChildBM,
            currentBMuser,
        } = this.props;
        if (!account.entity) accountInfo();
        if (!facebook.pages.entities.length) getFbPages();
        if (shopsCampaignsSettings.entity && shopsCampaignsSettings.entity.page) {
            setFBPageAndCreateChildBM(shopId, null, shopsCampaignsSettings, currentBMuser, account, isPageSaved);
        }
        if (
            (shopsCampaignsSettings.error || businessManager.error) &&
            !shopsCampaignsSettings.pending &&
            businessManager.requestStatus !== REQUEST_STATUS_PENDING
        ) {
            this.openTryAgainModal();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            params: { shopId },
            goToNextStep,
            currentBMuser,
        } = this.props;

        if (this.props.businessManager.data !== prevProps.businessManager.data) {
            if (currentBMuser?.status === GRANTED) {
                goToNextStep(shopId);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            onboarding: { shopError, pages },
            params: { shopId },
            redirectTo,
        } = this.props;
        const initialFbPages = this.getPublishedFbPages(this.props.facebook.pages.entities);
        const updatedFbPages = this.getPublishedFbPages(nextProps.facebook.pages.entities);
        const { shopsCampaignsSettings, businessManager } = nextProps;

        // Check when User had no pages and added one
        if (updatedFbPages.length !== initialFbPages.length) {
            document.removeEventListener('visibilitychange', this.getFbPagesOnTabFocus);
        }

        // Check when user had at least one page, deleted/unpublished it and then added/published another page
        for (const i of updatedFbPages) {
            if (updatedFbPages[i] && initialFbPages[i] && updatedFbPages[i].id !== initialFbPages[i].id) {
                document.removeEventListener('visibilitychange', this.getFbPagesOnTabFocus);
            }
        }

        // Check if Shopify app was uninstalled
        if (shopError.shopValidateError && shopError.shopError.errorToken === 'platform-doesnt-have-access-to-shop') {
            if (this.props.shopType === SHOPIFY) {
                once(redirectTo(`/shopify/uninstalled/${shopId}`), () =>
                    setTimeout(() => this.props.requestShops(), 0),
                );
            } else if (this.props.shopType === BIG_COMMERCE) {
                once(redirectTo(`/bigcommerce/uninstalled/${shopId}`), () =>
                    setTimeout(() => this.props.requestShops(), 0),
                );
            } else if (this.props.shopType === WOO_COMMERCE) {
                once(redirectTo(`/woocommerce/uninstalled/${shopId}`), () =>
                    setTimeout(() => this.props.requestShops(), 0),
                );
            }
        }

        if (pages.fbPageConnectRequestError) {
            this.openConnectPageErrorModal();
        }
        
        if (
            (shopsCampaignsSettings.error || businessManager.error) &&
            !shopsCampaignsSettings.pending &&
            businessManager.requestStatus !== REQUEST_STATUS_PENDING
        ) {
            this.openTryAgainModal();
        }
    }

    componentWillUnmount() {
        const { requestShops, clearShopError } = this.props;

        clearShopError();
        once('redirectTo', () => clearTimeout(requestShops));
    }

    getPublishedFbPages(pages) {
        return pages.filter((page) => page.is_published);
    }

    getFbPagesOnTabFocus() {
        const { getFbPages } = this.props;
        return !document.hidden ? getFbPages() : '';
    }

    getFbPageTips = (iconCustomClassName) => (
        <TipsWidget
            isDarkIcon
            isDarkView
            additionalClassName="fb-page__tips-wrapper"
            additionalIconClassName={iconCustomClassName ? ` ${iconCustomClassName}` : false}
        >
            Below is the list of all published Facebook pages you are the admin of.
        </TipsWidget>
    );

    openTryAgainModal = () => {
        this.setState({
            isOpenTryAgainModal: true,
        });
    };

    hideTryAgainModal = () => {
        this.setState({
            isOpenTryAgainModal: false,
        });
    };

    openConnectPageErrorModal = () => {
        this.setState({
            isOpenConnectPageErrorModal: true,
        });
    };

    hideConnectPageErrorModal = () => {
        this.setState({
            isOpenConnectPageErrorModal: false,
            isConnectPageErrorModalShown: true,
        });
    };

    handleGoToNextStep() {
        const { isPageSaved } = this.state;
        const {
            account,
            currentBMuser,
            params: { shopId },
            onboarding: { pages },
            shopsCampaignsSettings,
            setFBPageAndCreateChildBM,
        } = this.props;

        const { chosenFBPageId } = pages;
        const connectedFbPage = pages.fbPageConnectStatus;

        if (connectedFbPage && connectedFbPage.fb_id === chosenFBPageId) {
            setFBPageAndCreateChildBM(
                shopId,
                chosenFBPageId,
                shopsCampaignsSettings,
                currentBMuser,
                account,
                isPageSaved,
            );
            this.setState({ modalFbPagesList: !this.state.modalFbPagesList });
        }
    }

    handleCreateNewFbPage() {
        window.open('https://www.facebook.com/pages/create?ref_type=adwisely', '_blank');
        document.addEventListener('visibilitychange', this.getFbPagesOnTabFocus);
    }

    handleChosenFbPage(fbPageId, business) {
        const {
            connectFbPage,
            params: { shopId },
        } = this.props;

        connectFbPage(fbPageId, shopId)
            .then(() => {
                const fbPageInfo = JSON.parse(campaignCreateStorageHelper.getFBPageIsSaved());
                let fbPageIndex = fbPageInfo.findIndex((page) => page.shopId === +shopId);

                if (fbPageIndex !== -1) {
                    fbPageInfo[fbPageIndex].isSaved = true;
                } else {
                    fbPageInfo.push({
                        shopId: shopId,
                        isSaved: true,
                    });
                    fbPageIndex = fbPageInfo.length - 1;
                }
                campaignCreateStorageHelper.setFBPageIsSaved(fbPageInfo);
                this.setState({ isPageSaved: fbPageInfo[fbPageIndex].isSaved });
            })
            .then((res) => {
                if (!(res instanceof Error)) {
                    this.handleGoToNextStep();
                }
            });

        this.setState({
            chosenFBPageId: fbPageId,
            isBusinessFbPage: !!business,
        });
    }

    toggleModalFbPagesList() {
        this.setState({
            modalFbPagesList: !this.state.modalFbPagesList,
        });
    }

    tryAgainHandler() {
        const { isPageSaved } = this.state;
        const {
            account,
            currentBMuser,
            params: { shopId },
            shopsCampaignsSettings,
            setFBPageAndCreateChildBM,
            onboarding: { pages }
        } = this.props;

        pages.fbPageConnectRequestError && this.hideConnectPageErrorModal();

        setFBPageAndCreateChildBM(shopId, null, shopsCampaignsSettings, currentBMuser, account, isPageSaved);
    }

    getPageDescription = (fbPages) => {
        const { facebook } = this.props;

        const facebookPagesExist = fbPages.length >= 1;
        if (facebookPagesExist) {
            return (
                <p className="fb-page__description">
                    Choose a Facebook page you’ve created for your business. It will be used in Adwisely-powered ads.
                </p>
            );
        }

        const facebookDontPagesExist = facebook.pages.fbPagesRequestSuccess && fbPages.length === 0;
        if (facebookDontPagesExist) {
            return (
                <p className="fb-page__description">
                    We can’t find any pages connected to your Facebook account. To launch a retargeting campaign, you
                    need to create a Facebook page. The page picture and name will be displayed on top of your ads.
                </p>
            );
        }
    };

    getModalFbPagesListContent = (fbPages, chosenFBPageId) => ({
        header: (
            <div>
                <span>Available Facebook pages</span>
                {this.getFbPageTips('fb-page-modal__tips-icon')}
            </div>
        ),
        body: <FbPageItemsList fbPages={fbPages} activeId={chosenFBPageId} clickHandler={this.handleChosenFbPage} />,
    });

    render() {
        const {
            facebook,
            onboarding,
            businessManager,
            shopsCampaignsSettings,
            params: { shopId },
            requestSendEventMetrics,
            onboarding: { pages },
        } = this.props;

        const { isOpenTryAgainModal, isOpenConnectPageErrorModal, modalFbPagesList } = this.state;

        const { chosenFBPageId } = onboarding.pages;
        const fbPages = this.getPublishedFbPages(facebook.pages.entities);

        const modalFbPagesListContent = this.getModalFbPagesListContent(fbPages, chosenFBPageId);

        const showGeneralError = (((!pages.fbPageConnectRequestError || !pages.fbPageConnectRequesting) && pages.chosenFBPageId === '') || pages.fbPageConnectRequestSuccess);

        return (
            <div className="fb-page__wrapper">
                <h1 className="fb-page__title">Connect Facebook Page</h1>
                <div className="fb-page__notifications-wrapper">
                    <PageNotifications isVisible={onboarding.pages.fbPageConnectRequestError} />
                </div>
                <div className="fb-page__connect-wrapper">
                    <div>
                        {this.getPageDescription(fbPages)}
                        <div>
                            <PagesInfo
                                pages={fbPages}
                                facebook={facebook}
                                onboarding={onboarding}
                                chosenFBPageId={chosenFBPageId}
                                fbPagesTips={this.getFbPageTips}
                                handleChosenFbPage={this.handleChosenFbPage}
                                togglePagesList={this.toggleModalFbPagesList}
                                handleCreateNewFbPage={this.handleCreateNewFbPage}
                            />

                            <ModalWindowWidget
                                type="info"
                                headerHasCloseBtn
                                isOpen={modalFbPagesList}
                                toggle={this.toggleModalFbPagesList}
                                bodyContent={modalFbPagesListContent.body}
                                headerContent={modalFbPagesListContent.header}
                                additionalModalClassName="fb-page__modal_fb-pages-list"
                            />
                        </div>
                    </div>
                    <BookACallSection shopId={shopId} requestSendEventMetrics={requestSendEventMetrics} />
                </div>

                <LoaderFullScreen
                    isLoaderShowed={
                        shopsCampaignsSettings.pending ||
                        onboarding.pages.fbPageConnectRequesting ||
                        businessManager.requestStatus === REQUEST_STATUS_PENDING
                    }
                >
                    <LoaderLongWait title="Connect Facebook page" />
                </LoaderFullScreen>
                {this.state.isConnectPageErrorModalShown ? (
                    <ModalConnectPageError
                        isOpen={isOpenConnectPageErrorModal}
                        hideModal={this.hideConnectPageErrorModal}
                        buttonClickHandler={null}
                        modalLogo="error"
                    >
                        <div>
                            <p><b>We still couldn&#39;t connect your Facebook page</b></p>
                            <p>No worries - just {''}
                                <a href="https://calendly.com/adwisely-support-team/support-team" target="_blank" rel="noopener noreferrer" className="modal-page-error-body__underline">
                                    book a quick free call
                                </a> with our Support specialist - together we will make sure that your campaign is fully set up.
                            </p>
                        </div>
                    </ModalConnectPageError>
                ) : (
                    <ModalConnectPageError
                        isOpen={isOpenConnectPageErrorModal}
                        hideModal={this.hideConnectPageErrorModal}
                        buttonClickHandler={this.tryAgainHandler}
                        modalLogo="try-again"
                    >
                        <div>
                            <p><b>We couldn&#39;t connect your Facebook page</b></p>
                            Please, make sure that:
                            <ul className="modal-page-error__list">
                                <li className="modal-page-error__list-item">The Facebook page you are connecting is added to a Facebook Business Manager</li>
                                <li className="modal-page-error__list-item">You are the Admin of this Business Manager </li>
                            </ul>
                            <p>Not sure how to check all this? Not a problem - {''}
                                <a href="https://calendly.com/adwisely-support-team/support-team" target="_blank" rel="noopener noreferrer" className="modal-page-error-body__underline">
                                    Book a call
                                </a> - we&#39;ll deal with it together.</p>
                            <p>Once you&#39;ve checked all the settings, you can try to connect the Facebook page again.</p>
                        </div>
                    </ModalConnectPageError>
                )}
                {showGeneralError && <ModalTryAgain
                    isOpen={isOpenTryAgainModal}
                    hideModal={this.hideTryAgainModal}
                    buttonClickHandler={this.tryAgainHandler}
                    buttonName="Try again"
                    modalLogo="try-again"
                >
                    <div>
                        Oops, we couldn&#39;t create some assets for your campaign. No worries, just{' '}
                        <b>click Try Again</b> to continue. If it doesn&#39;t work or you need help,{' '}
                        <IntercomChatCaller content="contact us" className="modal-error-body__underline" />.
                    </div>
                </ModalTryAgain>}
            </div>
        );
    }
}

Page.defaultProps = {
    currentBMuser: {},
}

Page.propTypes = {
    account: PropTypes.shape({
        entity: PropTypes.object.isRequired
    }).isRequired,
    businessManager: PropTypes.shape({
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    }).isRequired,
    currentBMuser: PropTypes.shape({
        status: PropTypes.string.isRequired
    }),
    facebook: PropTypes.shape({
        pages: PropTypes.shape({
            entities: PropTypes.array.isRequired,
            fbPagesRequestSuccess: PropTypes.bool.isRequired
        })
    }).isRequired,
    fbPageInfoIsSaved: PropTypes.bool.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    onboarding: PropTypes.shape({
        pages: PropTypes.shape({
            chosenFBPageId: PropTypes.string.isRequired,
            fbPageConnectRequestError: PropTypes.bool.isRequired,
            fbPageConnectRequesting: PropTypes.bool.isRequired,
        })
    }).isRequired,
    setFBPageAndCreateChildBM: PropTypes.func.isRequired,
    shopType: PropTypes.string.isRequired,
    shopsCampaignsSettings: PropTypes.shape({
        entity: PropTypes.shape({
            page: PropTypes.number,
        }),
        pending: PropTypes.bool,
    }).isRequired,
}

const mapStateToProps = (state) => {
    const arrayOfFBPagesInfo = JSON.parse(campaignCreateStorageHelper.getFBPageIsSaved());
    const fbPageInfoIsSaved =
        arrayOfFBPagesInfo.find((pageInfo) => +pageInfo.shopId === +state?.shops?.currentShopId)?.isSaved || false;
    return {
        onboarding: state.onboarding,
        facebook: state.facebook,
        account: state.account,
        shopsCampaignsSettings: state.shopsCampaignsSettings,
        businessManager: state.businessManager,
        shopType: state?.shops?.entities.find((shop) => shop?.id === +state?.shops?.currentShopId)?.type,
        currentBMuser:
            state.businessManager.data && getCurrentBMUserSelector(state.businessManager.data, state.account),
        campaignIsCreated: state.campaignDetails?.campaignDetailsData.length,
        fbPageInfoIsSaved,
    };
};

const mapDispatchToProps = {
    accountInfo: accountActions.requestAccountInfo,
    getFbPages: pageAsyncHelper.getFbPages,
    connectFbPage: pageAsyncHelper.connectFbPage,
    setFBPageAndCreateChildBM: pageAsyncHelper.setFBPageAndCreateChildBM,
    clearShopError: onboardingActions.clearShopError,
    redirectTo: redirect,
    requestShops,
    goToNextStep,
    requestSendEventMetrics,
};

const PageContainer = connect(mapStateToProps, mapDispatchToProps)(Page);
export default pageNameSetter(changeShopPage.onboarding_get_started, changeShopPage.page)(PageContainer);
