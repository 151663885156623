import * as actions from '../../actions/stripe';

const initialState = {
    pending: false,
    success: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.STRIPE_ID_REQUEST:
            state = {
                ...state,
                pending: true,
                success: false,
                error: null,
            };
            break;
        case actions.STRIPE_ID_SUCCESS:
            state = {
                ...state,
                pending: false,
                success: true,
                error: null,
            };
            break;
        case actions.STRIPE_ID_FAILURE:
            state = {
                ...state,
                pending: false,
                success: false,
                error: action.payload,
            };
            break;
        case actions.STRIPE_ID_RESET:
            state = {
                ...initialState,
            };
            break;
        default:
            break;
    }
    return state;
};
