import React, { Component } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';
import { shopHasNotification } from 'helpers';
import { NON_DISCRIMINATION_POLICY } from 'constants/notificationType';

const NOTIFICATION_NOT_REVIEWED_SHOW_KEY = 'NOTIFICATION_NOT_REVIEWED_SHOW_KEY';
const NOTIFICATION_REVIEWED_SHOW_KEY = 'NOTIFICATION_REVIEWED_SHOW_KEY';
const NOTIFICATION_SHOW_DELAY = 1000 * 60 * 60 * 24;

const SHOP_STATUS_NOT_REVIEWED = 'not_reviewed';
const SHOP_STATUS_REVIEWED = 'reviewed';

class DashboardInfoNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosed: this.getInitialState(),
            isPolicyNotificationHidden: true,
        };
        this.getNotification = this.getNotification.bind(this);
        this.getPolicyInfoNotification = this.getPolicyInfoNotification.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.closeNotificationShopNotReviewed = this.closeNotificationShopNotReviewed.bind(this);
        this.closeNotificationShopReviewed = this.closeNotificationShopReviewed.bind(this);
    }

    onUpdate() {
        const isCustomCampaignExist = shopHasNotification(this.props?.shopNotificationEntities, NON_DISCRIMINATION_POLICY);
        if (isCustomCampaignExist) {
            this.setState({
                isPolicyNotificationHidden: false,
            });
        };
    }
    componentDidMount() {
        this.onUpdate();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps?.shopNotificationEntities?.length !== this.props?.shopNotificationEntities?.length) {
            this.onUpdate();
        }
    }

    getInitialState() {
        const { shop, campaignEdit } = this.props;
        let isClosed = false;

        switch (shop.review_status) {
            case SHOP_STATUS_NOT_REVIEWED: {
                const lastClosedAt = this.getStorageKey(NOTIFICATION_NOT_REVIEWED_SHOW_KEY);
                if (lastClosedAt) {
                    isClosed = Date.now() - +lastClosedAt < NOTIFICATION_SHOW_DELAY;
                    if (!isClosed) this.removeStorageKey(NOTIFICATION_NOT_REVIEWED_SHOW_KEY);
                }
                break;
            }

            case SHOP_STATUS_REVIEWED: {
                if (!campaignEdit) {
                    const wasShowed = this.getStorageKey(NOTIFICATION_REVIEWED_SHOW_KEY);
                    if (+wasShowed === 1) {
                        isClosed = true;
                    }
                }
                break;
            }

            default:
                break;
        }

        return isClosed;
    }

    getStorageKey(key) {
        return localStorage.getItem(`${key}_${this.props.shop.id}`);
    }

    setStorageKey(key, value) {
        localStorage.setItem(`${key}_${this.props.shop.id}`, value);
    }

    getNotification() {
        const { shop, campaignEdit } = this.props;
        let notificationType;
        let notificationDescription;

        switch (shop.review_status) {
            case SHOP_STATUS_NOT_REVIEWED:
                notificationType = 'info';

                notificationDescription = (
                    <div>
                        <p className="notification__title">Store is in review</p>

                        <p className="notification__description">
                            That’s it &mdash; we pick it up from here{' '}
                            <span className="banner__emoji" aria-labelledby="wink-emoji" role="img">
                                😉
                            </span>
                        </p>
                        <br />
                        <p className="notification__description">
                            Within the next <strong>24 hours</strong>, we will check if your store complies with Advertising Policies of the platform you chose and create your campaign for you. Once it’s active, you will get an e-mail.
                        </p>
                        <br />
                        <p className="notification__description">
                            Please, <strong>do not create, edit or delete anything</strong> in the ad account
                            Adwisely has created for you &mdash; we will handle everything{' '}
                            <span className="banner__emoji" aria-labelledby="wink-emoji" role="img">
                                😉
                            </span>
                        </p>

                        <Button color="transparent-bordered" onClick={this.closeNotificationShopNotReviewed}>
                            Got it!{' '}
                            <span className="banner__emoji" aria-labelledby="wink-emoji" role="img">
                                😊
                            </span>
                        </Button>
                    </div>
                );

                break;

            case SHOP_STATUS_REVIEWED:
                if (!campaignEdit) {
                    notificationType = 'success';

                    notificationDescription = (
                        <div>
                            <p className="notification__title">
                                Woohoo!{' '}
                                <span role="img" aria-label="Ta-da">
                                    🎉
                                </span>{' '}
                                Your store was reviewed
                            </p>

                            <p className="notification__description">
                                Adwisely is now studying your website visitors’ behaviour to come up with the most efficient ways to advertise to them and bring you the highest ROAS possible.
                            </p>
                            <p className="notification__description">
                                <strong>Please, do not pause, edit, or delete any Adwisely-powered campaigns.</strong>
                            </p>

                            <Button onClick={this.closeNotificationShopReviewed} color="transparent-bordered">
                                Got it!{' '}
                                <span role="img" aria-label="Happy Face">
                                    😊
                                </span>
                            </Button>
                        </div>
                    );
                }

                break;

            default:
                break;
        }

        if (notificationDescription) {
            return (
                <section className="dashboard-page__section">
                    <NotificationWidget type={notificationType}>{notificationDescription}</NotificationWidget>
                </section>
            );
        }
        return null;
    }

    closeNotificationShopNotReviewed() {
        this.setState({
            isClosed: true,
        });
        this.setStorageKey(NOTIFICATION_NOT_REVIEWED_SHOW_KEY, Date.now());
        this.removeStorageKey(NOTIFICATION_REVIEWED_SHOW_KEY, 1);
    }

    closeNotificationShopReviewed() {
        this.setState({
            isClosed: true,
        });
        this.setStorageKey(NOTIFICATION_REVIEWED_SHOW_KEY, 1);
        this.removeStorageKey(NOTIFICATION_NOT_REVIEWED_SHOW_KEY);
    }

    closePolicyNotification = () => {
        const { removeNotification, shop } = this.props;

        removeNotification({
            id: shop.id,
            type: NON_DISCRIMINATION_POLICY,
        });

        this.setState({
            isPolicyNotificationHidden: true,
        });
    }

    removeStorageKey(key) {
        localStorage.removeItem(`${key}_${this.props.shop.id}`);
    }

    getPolicyInfoNotification = () => {
        const isCustomCampaignExist = shopHasNotification(this.props?.shopNotificationEntities, NON_DISCRIMINATION_POLICY);

        const policyNotificationDescription = (
            <div>
                <p className="notification__title">
                    Accept the non-discrimination policy
                </p>

                <p className="notification__description">
                    As Meta Partners, we share and support their values. We can only run ads for you if you agree with Meta’s
                    non-discrimination policy. If you haven’t yet, please, take the time to review the policy and accept it.
                </p>

                <Button onClick={this.closePolicyNotification} color="transparent-bordered" url="https://www.facebook.com/certification/nondiscrimination/">
                    Review the policy
                </Button>
            </div>
        );

        return <>{isCustomCampaignExist && <NotificationWidget type="info">{policyNotificationDescription}</NotificationWidget>}</>;
    }

    render() {
        return <>
            <div>{!this.state.isClosed && this.getNotification()}</div>
            <div>{!this.state.isPolicyNotificationHidden && this.getPolicyInfoNotification()}</div>
        </>
    }
}

export default DashboardInfoNotifications;
