import * as campaignActions from 'actions/campaign';

const initialStateAdPreview = {
    adPreviewLink: null,
    adPreviewError: null,
    adPreviewTimestamp: null,
    adPreviewRequesting: false,
    adPreviewSuccess: false,
    adPreviewFailure: false,
};

export default (state = { ...initialStateAdPreview }, action) => {
    let timestamp;
    if (action.meta && action.meta.timestamp) {
        timestamp = action.meta.timestamp;
    }
    switch (action.type) {
        case campaignActions.AD_PREVIEW_REQUEST:
            state = {
                ...state,
                ...initialStateAdPreview,
                adPreviewRequesting: true,
                adPreviewTimestamp:
                    !state.adPreviewTimestamp || state.adPreviewTimestamp < timestamp
                        ? timestamp
                        : state.adPreviewTimestamp,
            };
            break;
        case campaignActions.AD_PREVIEW_SUCCESS:
            if (timestamp === state.adPreviewTimestamp) {
                state = {
                    ...state,
                    ...initialStateAdPreview,
                    adPreviewLink: action.payload,
                    adPreviewSuccess: true,
                };
            }
            break;
        case campaignActions.AD_PREVIEW_FAILURE:
            if (timestamp === state.adPreviewTimestamp) {
                state = {
                    ...state,
                    ...initialStateAdPreview,
                    adPreviewError: action.payload,
                    adPreviewFailure: true,
                };
            }
            break;
        default:
            break;
    }
    return state;
};
