import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { Caret } from 'components/Icons';
import './index.css';

const propTypes = {
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export const StandardToggler = ({ disabled, children }) => (
    <Button disabled={disabled} className="standard-toggler">
        {children}
        <Caret />
    </Button>
);

StandardToggler.propTypes = propTypes;

export default StandardToggler;
