import React, {useState} from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';


import {setLocalStorageObjectItem, shopHasNotification} from 'helpers';
import { AI_BOOSTED_SALES_INVITE } from 'constants/notificationType';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import { requestDeleteShopNotification, requestUpdateShopNotification } from 'actions/notification';

import { changeOnboardingState } from 'modules/home/utils';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';

import './AiBoostedSalesInvite.css';

import { AiBoostedSalesInviteView } from './components/AiBoostedSalesInviteView';


const AiBoostedSalesInvite = ({aiBoostedSalesInvite, currentShopId, onboardings, hideNotification, removeNotification, setIsLoading, ownerType, push, replace}) => {
  const [isBannerHidden, setBannerHidden] = useState(false);
  const isVisible =
      !isBannerHidden &&
      !!aiBoostedSalesInvite

  const onRemindBtnClick = () => {
    hideNotification({
      id: currentShopId,
      type: AI_BOOSTED_SALES_INVITE,
    });
    setBannerHidden(true);
  };

  const onCloseBtnClick = () => {
    removeNotification({
      id: currentShopId,
      type: AI_BOOSTED_SALES_INVITE,
    });
    setBannerHidden(true);
  };

  const onLaunchBtnClick = () => {
    if (ownerType !== 'child') {
      setLocalStorageObjectItem('currentOnboarding', ONBOARDING_TYPES.facebook_ai_boosted_sales);
      setIsLoading(true);
      changeOnboardingState({
        type: ONBOARDING_TYPES.facebook_ai_boosted_sales,
        status: UBM_ONBOARDING_STATES.not_started,
        currentShopId: currentShopId,
      })
          .then((res) => res.json())
          .then(res => {
            if (res?.processing) {
              facebookStatePolling(currentShopId, replace);
            }
          });
    } else {
      push(`/${currentShopId}/create/ai-boosted-sales`)
    }
  }

  return isVisible && <AiBoostedSalesInviteView onLaunchBtnClick={onLaunchBtnClick} onRemindBtnClick={onRemindBtnClick} onCloseBtnClick={onCloseBtnClick} />
}

const mapStateToProps = ({ shopNotifications, shops, onboarding, shopsCampaignsSettings }) => ({
  aiBoostedSalesInvite: shopHasNotification(shopNotifications.entity, AI_BOOSTED_SALES_INVITE),
  currentShopId: shops?.currentShopId,
  onboardings: onboarding.onboardingsState.onboardings,
  ownerType: shopsCampaignsSettings.entity.fb_business_manager_owner_type,

});

const mapDispatchToProps = {
  hideNotification: requestUpdateShopNotification,
  removeNotification: requestDeleteShopNotification,
  push,
  replace
};

export default connect(mapStateToProps, mapDispatchToProps)(AiBoostedSalesInvite);