import { FEATURE_FLAGS_SUCCESS } from 'actions/featureFlags';

const initialState = {
    isHomePageEnabled: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case FEATURE_FLAGS_SUCCESS:
            state = {
                ...state,
                isHomePageEnabled: payload?.feature_toggles?.homepage,
                adsPreviewOnHomePage: payload?.feature_toggles?.ads_preview_on_homepage,
                isEaster2023: payload?.feature_toggles?.subscription_plan_discounts_easter_2023,
                starterSubscriptionPlan: payload?.feature_toggles?.starter_subscription_plan
            };
            break;

        default:
            break;
    }
    return state;
};
