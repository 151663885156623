import { ACCOUNT_INFO_REQUEST_SUCCESS, ACCOUNT_REMOVE_SUCCESS } from 'actions/account';
import { setUserContext, removeUserContext } from 'libs/sentryConfig';

export default () => (next) => (action) => {
    switch (action.type) {
        case ACCOUNT_INFO_REQUEST_SUCCESS:
            setUserContext(action.payload.id);
            break;
        case ACCOUNT_REMOVE_SUCCESS:
            removeUserContext();
            break;
        default:
            break;
    }
    next(action);
};
