import React, { PureComponent } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';
import intercom from 'libs/intercom';

class AdAccountErrorBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.getAdAccountErrorReasonBanner = this.getAdAccountErrorReasonBanner.bind(this);
    }

    getAdAccountErrorReasonBanner() {
        const { shopError } = this.props;
        let notificationData = null;
        let bannerTitle = null;
        let bannerContent = null;
        let bannerButtons = null;
        if (!shopError.errorData) shopError.errorData = {};
        switch (shopError.errorToken) {
            case 'ad-account-has-wrong-status-disabled':
            case 'ad-account-has-wrong-status-closed':
            case 'ad-account-has-wrong-status-pending_risk_review': {
                bannerTitle = <p className="notification__title">Campaign is paused</p>;
                bannerContent = (
                    <p className="notification__description">
                        The ad account used for Adwisely-powered campaign is no longer available for running ads.{' '}
                        <span className="font-weight-bold">Contact us</span>, for more information.
                    </p>
                );
                bannerButtons = (
                    <div>
                        <Button color="transparent-bordered" onClick={intercom.show}>
                            Contact us
                        </Button>
                        {'  '}
                        <a
                            href={
                                'https://help.adwisely.com/en/articles/' +
                                '900338-what-do-i-do-if-my-ad-account-was-disabled'
                            }
                            className="notification__link
                                        notification__link_with-arrow
                                        notification__link_with-left-margin"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more
                        </a>
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;
            }
            case 'ad-account-is-disabled-due-to-risk-payment': {
                bannerTitle = <p className="notification__title">Verify your Ad Account</p>;
                bannerContent = (
                    <p className="notification__description">
                        The ad account used for running Adwisely-powered ads is being verified by Facebook. There is
                        nothing to worry about, it is a regular procedure. To activate your ad account, go to{' '}
                        <span className="font-weight-bold">Ads Manager</span>
                        <span>&nbsp;</span> and on the banner, you see click&#34;verify your account&#34; and enter the
                        required info. Once your account is activated, your campaign can start delivering. If you have
                        any questions, message us and we will do our best to assist.
                    </p>
                );
                bannerButtons = (
                    <Button
                        url={`https://business.facebook.com/adsmanager/manage/campaigns?
                            act=${shopError.errorData.ad_account_id || ''}`}
                        color="transparent-bordered"
                    >
                        Go to Ads Manager
                    </Button>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;
            }
            case 'account-doesnt-have-access-to-ad-account': {
                bannerTitle = <p className="notification__title">Your action is required</p>;
                bannerContent = (
                    <p className="notification__description">
                        You don’t have an admin role to the ad account Adwisely created. To launch a campaign,
                        contact the ad account admin to give you an admin role or{' '}
                        <span className="font-weight-bold">contact us</span> to send you an invitation to the ad
                        account.
                    </p>
                );
                bannerButtons = (
                    <Button color="transparent-bordered" onClick={intercom.show}>
                        Contact us
                    </Button>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;
            }
            default:
                break;
        }
        return notificationData;
    }

    render() {
        const shopAdAccountErrorBannerArray = this.getAdAccountErrorReasonBanner();
        if (shopAdAccountErrorBannerArray) {
            return (
                <div>
                    <NotificationWidget type="danger">
                        {shopAdAccountErrorBannerArray.map((notificationItem, key) => (
                            <span key={key}>{notificationItem}</span>
                        ))}
                    </NotificationWidget>
                </div>
            );
        }
        return null;
    }
}

export default AdAccountErrorBanner;
