import { ROUTES } from 'constants/routes';
import { isGooglePlatformSupported } from './isGooglePlatformSupported';
import {FACEBOOK_ONBOARDING_TYPES} from '../constants/onboarding';

/**
 * Return boolean value if shop has notification with type
 * @param {array} shopNotifications - is array of notifications by shop
 * @param {string} notificationType - type of notification
 * @return {obj} notification
 */

export const shopHasNotification = (shopNotifications, notificationType) =>
    shopNotifications?.find((notification) => notification.type === notificationType);

/**
 * Returns string with value and passed currency in format "1,700.00 USD"
 * @param value {string, number}
 * @param currency {string}
 * @returns {string}
 */
export const toFloatNumberWithCurrency = (value, currency = '$') => {
    const numValue = +value;
    return `${numValue
        .toFixed(2)
        .replace(/./g, (currentNumberFromValue, index, valueFull) =>
            index > 0 && currentNumberFromValue !== '.' && (valueFull.length - index) % 3 === 0
                ? `,${currentNumberFromValue}`
                : currentNumberFromValue,
        )} ${currency}`;
};

/**
 * Returns string with number separated with dot and two numbers after dot (floating number with two decimal)
 * @param value {string, number}
 * @param integer {boolean}
 * @returns {string}
 */
export const numberDigitSeparateWithSpace = (value, integer) => {
    const numValue = integer ? Math.trunc(+value) : (+value).toFixed(2) || 0;
    return numValue
        .toString()
        .replace(/./g, (currentNumberFromValue, index, valueFull) =>
            index > 0 && currentNumberFromValue !== '.' && (valueFull.length - index) % 3 === 0
                ? ` ${currentNumberFromValue}`
                : currentNumberFromValue,
        );
};

/**
 * Generates string with random letters and numbers, without leading number in format like "pizm2bg2pm"
 * @returns {string}
 */
export const getUniqueRandomString = () => {
    let res = null;
    const generateUniqueString = () => Math.random(1000000000000).toString(36).substr(2, 11).toString();
    res = generateUniqueString();
    while (!isNaN(+res.charAt(0))) res = generateUniqueString();
    return res;
};

/**
 * Check if image source is available.
 * If the source is available it will call callback function with true argument,
 * or call with false argument if not.
 * @param imgSrc {string}
 * @param callback {function}
 */
export const checkImage = (imgSrc, callback) => {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
    img.src = imgSrc;
};

/**
 * Create cancelable promise.
 * Allows to resolve and reject promise from different places in component,
 * Using for solve problem with set state to unmounted component.
 * @param promise {Promise}
 */
export const cancelablePromise = (promise) => {
    let hasCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (value) => (hasCanceled ? reject({ isCanceled: true, value }) : resolve(value)),
            (error) => reject({ isCanceled: hasCanceled, error }),
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            hasCanceled = true;
            return hasCanceled;
        },
    };
};

/**
 *
 * Calculate difference beetween to dates in months
 * @param {Date obj} d1
 * @param {Date obj} d2
 */
export const monthDiff = (d1, d2) => {
    let diff = Math.abs(d2.getTime() - d1.getTime());
    diff /= 1000 * 3600 * 24 * 30.42;
    return Math.floor(Math.abs(diff));
};

/**
 *
 * Calculate difference beetween to dates in days
 * @param {Date obj} d1
 * @param {Date obj} d2
 */
export const dayDiff = (d1, d2) => {
    let diff = Math.abs(d2.getTime() - d1.getTime());
    diff /= 1000 * 3600 * 24;
    return Math.floor(Math.abs(diff));
};

export const setLocalStorageObjectItem = (key, value) => {
    if (value === undefined) {
        window.localStorage.removeItem(key);
    } else {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getLocalStorageObjectItem = (key) => {
    const json = window.localStorage.getItem(key);
    if (json === undefined) {
        return undefined;
    }
    return JSON.parse(json);
};

export const removeLocalStorageObjectItem = (key) => window.localStorage.removeItem(key);

/**
 *
 * Separate number with commas if number is bigger than 1000
 * @param {number} number
 */
export const numberWithCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * createLinkToBMPayments
 * @param {number} adAccountId
 */

export const createLinkToBMPayments = (adAccountId) => {
    if (!adAccountId) {
        return '#';
    }
    return `https://www.facebook.com/ads/manager/account_settings/account_billing/?act=${adAccountId}&pid=p2`;
};

/**
 * logoutFunc
 * @param logout {function}
 * @param inframe {boolean}
 * @param replace {function}
 * @param intercom {Object}
 */

export const logoutFunc = (logout, inframe, replace, intercom) => {
    logout().then((data) => {
        if (!(data instanceof Error)) {
            intercom.shutdown();
            setLocalStorageObjectItem('RA_LOGOUT', '');
            setLocalStorageObjectItem('RA_LOGOUT');
            if (inframe) {
                replace(ROUTES.facebookLogin);
            }  else {
                window.open('https://adwisely.com/', '_self');
            }
        }
    });
};

const campaignsPriority = {
    facebook_ai_boosted_sales: 2,
    google: 1,
};

export const sortCampaignsByPriority = (campaigns) =>
    campaigns
        .map((campaign) => {
            for (const campaignType in campaignsPriority) {
                if (campaign.type === campaignType || campaign.name === campaignType) {
                    campaign.priority = campaignsPriority[campaignType];
                }
            }
            return campaign;
        })
        .sort((a, b) => a.priority - b.priority);

export const processCampaignArray = (onboardings) => {
    const sortedCampaigns = sortCampaignsByPriority(onboardings);
    return [sortedCampaigns];
}
/*
 Allow to call function "fn" once depend of key passed
 */
export const once = () => {
    const calledFunction = {};
    return (functionKey, fn) => {
        if (functionKey in calledFunction) return;
        calledFunction[functionKey] = true;
        fn();
    };
};

export const fetchPost = (url, body) =>
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body,
    });

export { isGooglePlatformSupported }
