import * as actions from 'actions/campaign';

const initialState = {
    type: null,
};

const campaignAutoCreateType = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actions.SET_AUTO_CREATE_CAMPAIGN_TYPE:
            return {
                ...state,
                type: payload,
            };
        case actions.CLEAR_AUTO_CREATE_CAMPAIGN_TYPE:
            return {
                ...state,
                type: null,
            };
        default:
            break;
    }

    return state;
};
export default campaignAutoCreateType;
