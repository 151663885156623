import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { requestSendEventMetrics } from 'actions/shop';

import ModalAddStripe from 'components/ModalAddStripe';
import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { getRedirectPage } from 'modules/onboarding/userBMFlow/helpers/getOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';

import { getLocalStorageObjectItem } from 'helpers';
import { getCurrentShopSelector } from 'helpers/selectors';
import { isStripeAdded } from 'helpers/onboardingHelper';
import { metricType } from 'constants/metric';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import './StripeBilling.css';

export const StripeBilling = ({
    params: { shopId },
    replace,
    stripeIsAdded,
    requestSendEventMetrics,
    currentShop,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showAddStripeModal, setShowAddStripeModal] = useState(false);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    useEffect(() => {
        if (stripeIsAdded) {
            setIsLoading(true);
            changeOnboardingState({
                type: ONBOARDING_TYPES[currentOnboarding],
                status: UBM_ONBOARDING_STATES.confirm_stripe_payment,
                currentShopId: shopId,
            })
                .then((res) => {
                    if (res.status === 409) {
                        facebookStatePolling(shopId, replace)
                    }
                    return res.json()
                })
                .then(res => {
                    if (res?.processing) {
                        facebookStatePolling(shopId, replace);
                    } else {
                        getRedirectPage(res, shopId, replace);
                    }
                });
        }
    }, [stripeIsAdded]);

    const showModalHandler = () => {
        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_fee_add_card',
            },
        };
        requestSendEventMetrics(metricProps);

        setShowAddStripeModal(true);
    };

    const hideModalHandler = () => {
        setShowAddStripeModal(false);
    };

    const getStripeModal = (showAddStripeModal) => {
        if (!showAddStripeModal) {
            return null;
        }

        const stripeModalHeader = 'Pay Adwisely fee directly to Adwisely';
        const stripeModalBody =
            'To run ads for you, Adwisely has created a brand new ad account. Please, connect a payment method to it';
        const stripeModalList = [
            'You will not be charged now',
            <>
                You will have a <span className="modal-add-stripe__body-detail-options-bold">7-Day Free Trial</span>
            </>,
        ];

        return (
            <ModalAddStripe
                shopId={currentShop.id}
                stripeModalBody={stripeModalBody}
                stripeModalList={stripeModalList}
                isOpenModal={showAddStripeModal}
                stripeModalHeader={stripeModalHeader}
                hideModalHandler={hideModalHandler}
            />
        );
    };

    const addStripeModal = getStripeModal(showAddStripeModal);

    return (
        <div className="stripe-billing__wrapper">
            <h1 className="stripe-billing__title">Review payment info: Adwisely fee</h1>
            <section className="stripe-billing__section">
                <p className="stripe-billing__subtitle">Pay Adwisely fee directly to Adwisely</p>
                <p className="stripe-billing__text">
                    Please, approve future charges from Adwisely. You will not pay Adwisely fee during the 7-Day Free Trial
                </p>
                <p className="stripe-billing__text--info">
                    <a
                        className="stripe-billing__link"
                        href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-retargetapp"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn more
                    </a>
                </p>
                <Button color="primary" className="stripe-billing__button" onClick={showModalHandler}>
                    Add Card
                </Button>
            </section>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
            {addStripeModal}
        </div>
    )
}

StripeBilling.propTypes = {
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    stripeIsAdded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

const mapStateToProps = ({ shops, onboarding }) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    const stripeIsAdded = isStripeAdded(onboarding.stripeAdd, currentShop);
    return {
        currentShop,
        stripeIsAdded,
    };
};

const mapDispatchToProps = {
    requestSendEventMetrics,
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeBilling);
