export const ToDoListStatus = {
    not_started: 'not_started',
    verification: 'on_verification',
    complete: 'complete'
}

export const ToDoListTitle = {
    share_access_to_ad_account: 'Share access to Meta Ad Account',
    share_access_to_meta_pixel: 'Share access to Meta Pixel',
    share_access_to_store_domain: 'Share access to store domain',
    prioritize_pixel_events: 'Prioritize Pixel events',
    launch_google_campaign: 'Launch Google AI-powered campaign'
}

export const ToDoListType = {
    ad_account: 'share_access_to_ad_account',
    meta_pixel: 'share_access_to_meta_pixel',
    store_domain: 'share_access_to_store_domain',
    prioritize_pixel_events: 'prioritize_pixel_events'
}

export const ToDoCategoryTitle = {
    facebook: 'Additional steps required'
}

export const ToDoItemTooltip = {
    share_access_to_ad_account: 'It is necessary for Adwisely\'s Meta Ad Expert to review account performance and apply improvements to grow ROAS when needed.',
    launch_google_campaign: 'This campaign will complement your Meta Ads and can potentially increase total sales by 25% while maintaining a healthy ROAS'
}