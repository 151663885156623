import React from 'react';
import { ONBOARDING_TYPES } from 'constants/onboarding';

import './Recommendations.css';

const recommendationsData = [
    {
        title: 'Meta AI-powered Campaign',
        slug: ONBOARDING_TYPES.facebook_ai_boosted_sales,
        minimumBudget: '$30 / day',
        averageRoas: '200%-600%',
        firstResults: '1-2 weeks',
        forBestResults: 'at least 100 purchases in the last 30 days'
    },
    {
        title: 'Facebook Prospecting',
        slug: ONBOARDING_TYPES.facebook_prospecting,
        minimumBudget: '$30 / day',
        averageRoas: '630%',
        firstResults: '3-4 weeks',
        forBestResults: 'at least 100 adds to cart in the past 180 days'
    },
    {
        title: 'Facebook Visitor Retargeting',
        slug: ONBOARDING_TYPES.facebook_visitor_retargeting,
        minimumBudget: '$15 / day',
        averageRoas: '760%',
        firstResults: '3-4 weeks',
        forBestResults: 'at least 100 daily visitors'
    },
    {
        title: 'Facebook Dynamic Retargeting',
        slug: ONBOARDING_TYPES.facebook_dynamic_retargeting,
        minimumBudget: '$15 / day',
        averageRoas: '200% - 600%',
        firstResults: '1-3 weeks',
        forBestResults: 'at least 50 orders recorded'
    },
    {
        title: 'Facebook Social Retargeting',
        slug: ONBOARDING_TYPES.facebook_social_media_retargeting,
        minimumBudget: '$15 / day',
        averageRoas: '200% - 600%',
        firstResults: '1-3 weeks',
        forBestResults: 'at least 50 orders recorded'
    },
    {
        title: 'Facebook Special Offer',
        slug: ONBOARDING_TYPES.facebook_special_offer,
        minimumBudget: '$50 / day',
        averageRoas: '810%',
        firstResults: '3-4 weeks',
        forBestResults: 'at least 100 daily visitors'
    },
    {
        title: 'Google AI-powered Campaign',
        slug: ONBOARDING_TYPES.google,
        minimumBudget: '$15 / day',
        averageRoas: '570%',
        firstResults: '5-10 days',
        forBestResults: '15 sales during the last 30 days'
    },
    {
        title: 'Meta AI-powered Campaign',
        slug: ONBOARDING_TYPES.facebook_childbm,
        minimumBudget: '$30 / day',
        averageRoas: '200%-600%',
        firstResults: '1-2 weeks',
        forBestResults: 'at least 100 purchases in the last 30 days'
    },
]

export const Recommendations = ({currentActiveCard}) => {
    const currentData = recommendationsData.find(item => item.slug === currentActiveCard);

    if (!currentData) {
        return null;
    }

    return (
        <div className='recommendations'>
            <div className="recommendations__title">{currentData.title}</div>
            <div className="recommendations__card">
                <div className="recommendations__badge">Recommendations</div>
                <div className="recommendations__card-item">
                    <div className="recommendations__card-item-name">Minimum budget:
                    </div>
                    <div className="recommendations__card-item-value">{currentData.minimumBudget}</div>
                </div>
                <div className="recommendations__card-item">
                    <div className="recommendations__card-item-name">First results will come in at least:
                    </div>
                    <div className="recommendations__card-item-value">{currentData.firstResults}</div>
                </div>
                <div className="recommendations__card-item">
                    <div className="recommendations__card-item-name">Expected ROAS:
                    </div>
                    <div className="recommendations__card-item-value">{currentData.averageRoas}</div>
                </div>
                <div className="recommendations__card-item">
                    <div className="recommendations__card-item-name">For best results you need to have:
                    </div>
                    <div className="recommendations__card-item-value">{currentData.forBestResults}</div>
                </div>
            </div>
        </div>
    )
}