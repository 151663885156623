import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router';
import { replace } from 'react-router-redux';

import { removeAccount } from 'actions/account';
import { getShopBillingPeriod, requestSendEventMetrics } from 'actions/shop';
import { googleFlow } from 'modules/home/utils';
import { getCurrentShopSelector, isStaffPlatformPlanSelector } from 'helpers/selectors';
import { logoutFunc } from 'helpers';
import { SubscriptionPlanType } from 'constants/subscriptionPlanType';
import intercom from 'libs/intercom';
import ShopifyStoreListWidget from 'components/ShopifyStoreListWidget';
import UserProfileWidget from 'components/UserProfileWidget';
import { Collapse } from 'components/Collapse/Collapse';

import Button from 'components/Button';
import { AdSpendProgressBar } from 'components/AdSpendProgressBar/AdSpendProgressBar';

import TrialBadge from './TrialBadge/TrialBadge';
import './Header.css';
import './Base.css';
import logo from '../logo.svg';

export const PageTitle = ({ pageTitle, trialDaysLeft }) => (
    <div className="header__page-title">
        <h2
            className={classNames('header__page-title__content', {
                'header__page-title__content_small': trialDaysLeft > 0,
            })}
            title={pageTitle}
        >
            {pageTitle}
        </h2>
    </div>
);

PageTitle.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    trialDaysLeft: PropTypes.number.isRequired,
};

export const UserProfileWrapper = ({
    account,
    shopId,
    pageTitle,
    logout,
    inframe,
    shopType,
    featureFlags,
    isStaffPlatformPlan,
    replace,
    requestSendEventMetrics,
    isFbCampaignsExist,
    onboardingsState,
}) => (
    <UserProfileWidget
        shopType={shopType}
        featureFlags={featureFlags}
        firstName={account.first_name}
        lastName={account.last_name}
        accountImage={account.image_url}
        shopId={+shopId}
        isStaffPlatformPlan={isStaffPlatformPlan}
        logout={() => logoutFunc(logout, inframe, replace, intercom)}
        pageTitle={pageTitle}
        requestSendEventMetrics={requestSendEventMetrics}
        isFbCampaignsExist={isFbCampaignsExist}
        onboardingsState={onboardingsState}
    />
);

export class Header extends Component {
    state = {
        navbarIsOpen: false,
        isPageTitleVisible: true
    };

    toggleNavbar = () => {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen,
        });
    };

    togglePageTitleVisible = () => {
        this.setState({
            isPageTitleVisible:
                !window.location.pathname.includes('tiktok')
        });
    };


    componentDidMount() {
        const { shopBillingPeriodShopId, shopId, getBillingPeriod } = this.props;
        this.togglePageTitleVisible();
        if (shopBillingPeriodShopId !== shopId && +shopId) getBillingPeriod(shopId);
    };

    componentDidUpdate(prevProps) {
        const { shopBillingPeriodShopId, getBillingPeriod, shopId } = prevProps;
        if (this.props.pageTitle !== prevProps.pageTitle) {
            this.togglePageTitleVisible();
        }

        if (shopBillingPeriodShopId !== this.props.shopId && shopId !== this.props.shopId && +shopId) {
            getBillingPeriod(this.props.shopId);
        }
    };

    render() {
        const {
            shops,
            account,
            shopId,
            pathname,
            pageTitle,
            logout,
            inframe,
            replace,
            pageLoaded,
            featureFlags,
            trialDaysLeft,
            isStaffPlatformPlan,
            campaignDetailsData,
            requestSendEventMetrics,
            onboardingsState,
            shopSubscriptionPlan,
            shopSubscriptionPlanFee
        } = this.props;

        const showTrialBadge = !pathname.match(/\/onboarding/gi);
        const headerLogoLink = `/${shopId}/home`;
        const showShowTrialBadge =
            !isStaffPlatformPlan &&
            showTrialBadge &&
            pageLoaded &&
            shopSubscriptionPlan &&
            !(shopSubscriptionPlan === SubscriptionPlanType.STARTER && shopSubscriptionPlanFee === 0) &&
            trialDaysLeft > 0;

        return (
            <header className="header-wrapper">
                <div
                    className={classNames('main-container header-navbar', {
                        'header-navbar_small': pathname.match(/\/onboarding/gi) || pathname.match(/\/home/gi),
                        'header-navbar--with-progress-bar': shopSubscriptionPlan === SubscriptionPlanType.STARTER
                    })}
                >
                    <div className="header-navbar-brand">
                        <Link onClick={() => googleFlow.end()} className="header__app-logo" to={headerLogoLink}>
                            <img src={logo} className="header__app-logo__icon" alt="Adwisely" />
                        </Link>

                        {
                            this.state.isPageTitleVisible &&
                            shopSubscriptionPlan !== SubscriptionPlanType.STARTER &&
                            pageTitle &&
                            <PageTitle pageTitle={pageTitle} trialDaysLeft={trialDaysLeft} />
                        }

                        {shopSubscriptionPlan === SubscriptionPlanType.STARTER && shopSubscriptionPlan === 0 &&
                            <div className='header-progress-bar'>
                                <AdSpendProgressBar shopId={shopId} />
                                <Button
                                    color='primary'
                                    onClick={() => replace(`/${shopId}/upgrade-plan`)}
                                    className='header-progress-bar__btn'
                                    >
                                    Upgrade Plan
                                </Button>
                            </div>
                        }
                    </div>

                    {showShowTrialBadge && <TrialBadge shopId={shopId} />}

                    <button className="header-navbar-toggler" onClick={this.toggleNavbar}>
                        Menu
                    </button>

                    <Collapse className="header-navbar-collapse" isOpen={this.state.navbarIsOpen}>
                        <ul className="header-navbar-nav">
                            {shops?.length && (
                                <li>
                                    <ShopifyStoreListWidget
                                        shopsList={shops}
                                        chosenShopifyStoreId={+shopId}
                                        changeShopMetricSource={pageTitle.toString().toLowerCase().split(' ').join('_')}
                                        requestSendEventMetrics={requestSendEventMetrics}
                                    />
                                </li>
                            )}
                            {account && (
                                <li>
                                    <UserProfileWrapper
                                        account={account}
                                        shopId={shopId}
                                        pageTitle={pageTitle}
                                        logout={logout}
                                        inframe={inframe}
                                        isStaffPlatformPlan={isStaffPlatformPlan}
                                        replace={replace}
                                        featureFlags={featureFlags}
                                        requestSendEventMetrics={requestSendEventMetrics}
                                        isFbCampaignsExist={campaignDetailsData.length > 0}
                                        onboardingsState={onboardingsState}
                                    />
                                </li>
                            )}
                        </ul>
                    </Collapse>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    account: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        image_url: PropTypes.string,
    }).isRequired,
    pageTitle: PropTypes.string,
    shopId: PropTypes.string,
    pageLoaded: PropTypes.bool,
    trialDaysLeft: PropTypes.number,
    logout: PropTypes.func.isRequired,
    shops: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        }),
    ).isRequired,
    pathname: PropTypes.string.isRequired,
    campaignDetailsData: PropTypes.arrayOf(PropTypes.any).isRequired,
    shopSubscriptionPlan: PropTypes.string,
};

Header.defaultProps = {
    pageTitle: '',
    shopId: '',
    trialDaysLeft: 0,
    pageLoaded: false,
    shopSubscriptionPlan: null
};

const mapStateToProps = (state) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);
    return {
        shopSubscriptionPlan: state.shopSubscription.name,
        shopSubscriptionPlanFee: state.shopSubscription.fee,
        shops: state.shops.entities,
        account: state.account.entity,
        pageTitle: state.pageTitle,
        pathname: state.routing.locationBeforeTransitions.pathname,
        campaignDetailsData: state.campaignDetails.campaignDetailsData,
        trialDaysLeft: state.shopBillingPeriod.trialDaysLeft,
        shopBillingPeriodShopId: state.shopBillingPeriod.shopId,
        isStaffPlatformPlan: isStaffPlatformPlanSelector(currentShop),
        pageLoaded: state.loadingStatus.loaded,
        inframe: state.onboarding.inframe.inframe,
        featureFlags: state.featureFlags,
        onboardingsState: state.onboarding.onboardingsState,
    };
};

const mapDispatchToProps = {
    logout: removeAccount,
    replace,
    requestSendEventMetrics,
    getBillingPeriod: getShopBillingPeriod,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
