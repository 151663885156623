import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Banners from 'components/Banners';
import TipsWidget from 'components/TipsWidget';

import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import {
    getShopErrorSelector,
    getCurrentShopSelector,
    isShopValidateRequesting,
    getShopRecurringChargeSelector,
} from 'helpers/selectors';
import { SubscriptionPlanType } from 'constants/subscriptionPlanType';

import { validateShop } from 'actions/shop';
import { requestCampaignValidate, requestCampaignValidateFacebook } from 'actions/campaign';

import WizardStatusBar from './WizardStatusBar';

import './WizardBody.css';

const CAMPAIGN_TYPE_RETARGETING = 'retargeting';
const SCREEN_SIZE_SM = 576;

let isCreateCampaignStep = (
    window.location.pathname.endsWith('/onboarding/set-up-retargeting') ||
    window.location.pathname.endsWith('/onboarding/set-up-prospecting') ||
    window.location.pathname.endsWith('/user-bm/create-retargeting-campaign') ||
    window.location.pathname.endsWith('/user-bm/create-special-offer-campaign') ||
    window.location.pathname.endsWith('/user-bm/create-prospecting-campaign')
);
let isStepperHidden = (
    window.location.pathname.endsWith('/error') ||
    window.location.pathname.endsWith('/user-bm/ad-account-error')
);
let isChoosePlanStep = window.location.pathname.endsWith('/choose-plan');

function getPopoverPlacement() {
    let placement = 'bottom';
    const screenWidth = window.innerWidth;

    if (screenWidth >= SCREEN_SIZE_SM) {
        placement = 'bottom center';
    }
    return placement;
}

class WizardBody extends PureComponent {
    constructor(props) {
        super(props);
        const placement = getPopoverPlacement();
        this.state = {
            popoverPlacement: placement,
            tipsErrorText: '',
            hasCampaignFormError: false,
            isPublishPage: true,
        };
        this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 100);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.children !== this.props.children) {
            isCreateCampaignStep = (
                window.location.pathname.endsWith('/onboarding/set-up-retargeting') ||
                window.location.pathname.endsWith('/onboarding/set-up-prospecting') ||
                window.location.pathname.endsWith('/user-bm/create-retargeting-campaign') ||
                window.location.pathname.endsWith('/user-bm/create-special-offer-campaign') ||
                window.location.pathname.endsWith('/user-bm/create-prospecting-campaign')
            );
            isStepperHidden = (
                window.location.pathname.endsWith('/error') ||
                window.location.pathname.endsWith('/user-bm/ad-account-error')
            );
            isChoosePlanStep = window.location.pathname.endsWith('/choose-plan');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        const placement = getPopoverPlacement();

        if (placement !== this.state.popoverPlacement) {
            this.setState({
                popoverPlacement: placement,
            });
        }
    }

    changeTipsErrorText = (tipsText) => {
        this.setState({
            tipsErrorText: tipsText,
        });
    };

    handleFormError = (hasCampaignFormError) => {
        this.setState({
            hasCampaignFormError,
        });
    };

    changePublishPage = (isPublish) => {
        this.setState({
            isPublishPage: isPublish,
        });
    };

    campaignValidateFacebookHandler = () => {
        const {
            shopValidateRequesting,
            campaignValidateFacebook,
            currentShop: { id },
        } = this.props;

        if (!shopValidateRequesting) {
            campaignValidateFacebook(id).then((res) => {
                if (!(res instanceof Error)) {
                    this.props.requestCampaignValidate({
                        shopId: id,
                        campaign_type: CAMPAIGN_TYPE_RETARGETING,
                        daily_budget: campaignCreateStorageHelper.getCampaignDailyBudget(),
                        message: campaignCreateStorageHelper.getCampaignAdMessage(),
                    });
                }
            });
        }
    }

    render() {
        const {
            children,
            shopError,
            currentShop,
            shopRecurringCharge,
            shopValidateRequesting,
            checkShopForErrors,
            shopSubscriptionPlan,
            trialDaysLeft,
            onboardingVersion
        } = this.props;

        const isTrialFooterVisible =
            !isCreateCampaignStep &&
            shopSubscriptionPlan &&
            shopSubscriptionPlan !== SubscriptionPlanType.STARTER &&
            trialDaysLeft > 0;

        let shopErrorBanner = null;
        if (shopError && Object.keys(shopError).length) {
            shopErrorBanner = (
                <Banners
                    shopError={shopError}
                    changeTipsErrorText={this.changeTipsErrorText}
                    handleFormError={this.handleFormError}
                    validationPending={shopValidateRequesting}
                    validateShopSettings={() => checkShopForErrors(currentShop.id)}
                    campaignValidateFacebook={this.campaignValidateFacebookHandler}
                    shopRecurringCharge={shopRecurringCharge}
                    checkShopForErrors={() => checkShopForErrors(currentShop.id)}
                    shopId={currentShop.id}
                    currentShop={currentShop}
                    changePublishPage={this.changePublishPage}
                />
            );
        }

        return (
            <div
                className={classNames('wizard-container', {
                    'wizard-container--retargeting': isCreateCampaignStep,
                    'wizard-container--lg': isChoosePlanStep,
                })}
            >
                {isCreateCampaignStep && shopErrorBanner}
                {!isStepperHidden && (
                    <div className="wizard-header">
                        <WizardStatusBar
                            isCreateCampaignStep={isCreateCampaignStep}
                            isV2Onboarding={onboardingVersion === 2}
                            shopSubscriptionPlan={shopSubscriptionPlan}
                        />
                    </div>
                )}

                <div className={classNames('wizard-body', { 'wizard-body--retargeting': isCreateCampaignStep })}>
                    {children}
                </div>

                {isTrialFooterVisible && (
                    <div className="wizard-footer">
                        <p className="text-warning text-center wizard-footer__p-text wizard-footer__p-text-first">
                            7-Day Free Trial
                        </p>
                        <div className="text-center wizard-footer__p-text">
                            During the trial you will pay for ads directly to Facebook, TikTok or Google, however, Adwisely service
                            will be free.{' '}
                            <TipsWidget
                                placement="top"
                                isDarkView
                                noIcon
                                customText="See pricing"
                                additionalIconClassName="primary-link"
                            >
                                <>
                                    <p className="tips-description__title light">Pricing</p>
                                    <p className="tips-description__content">
                                        Adwisely fee is based on your ad spend and starts at $99/30 days.
                                    </p>
                                    <a
                                        href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-retargetapp"
                                        className="link link-white"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        How billing works
                                    </a>{' '}
                                    <span>→</span>
                                </>
                            </TipsWidget>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

WizardBody.defaultProps = {
    currentShopId: 0,
};

WizardBody.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
};

const mapStateToProps = (state) => ({
    shopError: getShopErrorSelector(state),
    shopValidateRequesting: isShopValidateRequesting(state),
    shopRecurringCharge: getShopRecurringChargeSelector(state),
    currentShop: getCurrentShopSelector(state.shops, state.shops.currentShopId),
    shopSubscriptionPlan: state.shopSubscription.name,
    trialDaysLeft: state.shopBillingPeriod.trialDaysLeft,
    onboardingVersion: state.onboarding.onboardingsState.version
});

const mapDispatchToProps = {
    requestCampaignValidate,
    checkShopForErrors: validateShop,
    campaignValidateFacebook: requestCampaignValidateFacebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardBody);
