import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TipsWidget from 'components/TipsWidget';
import AlertWidget from 'components/AlertWidget';
import AdMessageInput from 'components/AdMessageInput';
import DailyBudgetInput from 'components/DailyBudgetInput';
import { Card, CardTitle, CardContent } from 'components/Card';

import {
    AI_BOOSTED_SALES,
    DYNAMIC_RETARGETING,
    PROSPECTING,
    SOCIAL_RETARGETING,
    SPECIAL_OFFER,
    VISITOR_RETARGETING
} from 'constants/campaign';
import { AdMessageValidator } from 'libs/AdMessageValidator';
import { InvalidTagError, ProhibitedWordError, AD_MESSAGE_ERROR_CODE } from 'libs/AdMessageValidatorErrors';

import AdMessageProhibitedWordsError from '../AdMessageProhibitedWordsError';
import AdMessageTagsError from '../AdMessageTagsError';
import './CampaignDetails.css';

export function dollarToCent(value) {
    const newValue = parseInt(value * 100, 10);
    if (isNaN(newValue)) {
        return new Error('Can`t parse daily budget');
    }
    return newValue;
}

export class CampaignDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            campaignAdMessage: props.campaignAdMessage,
            campaignAdMessage2: props.campaignAdMessage2,
            campaignDailyBudget: props.campaignBudget || 0,
            currency: 'USD',

            campaignDailyBudgetErrors: [],
            campaignAdMessageErrors: [],
            campaignAdMessage2Errors: [],
        };

        this.validateCampaignFieldAsync = (() => {
            let timer = null;
            /** @var {null|number} -  time start */
            let ts = null;
            /** @var {number} -  time delta */
            const td = 600;
            return (...args) =>
                new Promise((resolve) => {
                    /** @var {number} - time delta */
                    let d;
                    clearTimeout(timer);
                    if (ts === null) {
                        ts = Date.now();
                        d = td;
                    } else {
                        d = Date.now() - (ts + td);
                    }
                    timer = setTimeout(
                        () => {
                            resolve(this.validateCampaignField(...args));
                            ts = null;
                            timer = null;
                        },
                        d <= 0 ? 0 : d,
                    );
                });
        })();

        this.changeCampaignField = this.changeCampaignField.bind(this);
        this.setMinimumDailyBudget = this.setMinimumDailyBudget.bind(this);
        this.onCampaignCreateFormSubmit = this.onCampaignCreateFormSubmit.bind(this);
        this.setAdviceDailyBudget = this.setAdviceDailyBudget.bind(this);
        this.handleCampaignFieldAsyncValidation = this.handleCampaignFieldAsyncValidation.bind(this);
    }

    componentDidMount() {
        const { handleAdPreviewMsg } = this.props;
        handleAdPreviewMsg(this.state.campaignAdMessage, true);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.shopError !== nextProps.shopError) {
            if (nextProps.shopError) {
                if (nextProps.shopError.errorToken === 'failed-to-validate-message') {
                    this.props.handleFormError(true);
                    this.setState({
                        campaignAdMessageErrors: [nextProps.shopError.errorToken],
                        campaignAdMessage2Errors: [nextProps.shopError.errorToken],
                    });
                }
            }
        }

        if (this.props.campaignBudget !== nextProps.campaignBudget) {
            this.setState({
                campaignDailyBudget: nextProps.campaignBudget,
            });
        }

        if (this.props.campaignCurrency !== nextProps.campaignCurrency) {
            this.setState({
                currency: nextProps.campaignCurrency,
            });
        }

        if (this.props.campaignAdMessage !== nextProps.campaignAdMessage) {
            this.setState({
                campaignAdMessage: nextProps.campaignAdMessage,
            });
        }
        if (nextProps.saveCampaignError) {
            this.setState({
                campaignDailyBudgetErrors: nextProps.saveCampaignError,
            });
        }
    }

    isSpecialOfferV2 = () => {
        const { campaign, campaignType } = this.props;

        let specialOfferV2;
        if (campaignType === SPECIAL_OFFER) {
            if (campaign) {
                specialOfferV2 = campaign?.version === 2;
            } else {
                specialOfferV2 = true;
            }
        };
        return specialOfferV2;
    }

    onCampaignCreateFormSubmit(e) {
        e.preventDefault();
        const { saveCampaign, campaignType } = this.props;
        const dailyBudget = dollarToCent(this.state.campaignDailyBudget);
        if (dailyBudget instanceof Error) {
            const err = dailyBudget;
            this.setState({
                campaignDailyBudgetErrors: [err.message],
            });
            return;
        }

        const getAdSetMessages = () => {
            let adSetMessages = {};


            if (campaignType ===  SPECIAL_OFFER) {
                this.isSpecialOfferV2()
                    ? adSetMessages = { "special_offer_prospecting": this.state.campaignAdMessage, "special_offer_retargeting": this.state.campaignAdMessage2 }
                    : adSetMessages = { "special_offer_standard": this.state.campaignAdMessage };
            };

            if (
                campaignType === VISITOR_RETARGETING ||
                campaignType === DYNAMIC_RETARGETING
            ) {
                    adSetMessages = { "retargeting_standard": this.state.campaignAdMessage };
            };

            if (campaignType === SOCIAL_RETARGETING) {
                adSetMessages = { "social_media_retargeting": this.state.campaignAdMessage };
            }

            if (campaignType === PROSPECTING) {
                adSetMessages = { "lookalike_standard": this.state.campaignAdMessage };
            };

            if (campaignType === AI_BOOSTED_SALES) {
                adSetMessages = { "ai_boosted_sales": this.state.campaignAdMessage };
            }

            return adSetMessages;
        }

        const messages = getAdSetMessages();

        saveCampaign(dailyBudget, messages);
    }

    onCampaignFieldChange(fieldName, value) {
        if (fieldName === 'campaignAdMessage') {
            this.props.handleAdPreviewMsg(value);
        }
    }

    setMinimumDailyBudget() {
        const { campaignMinBudget, handleFormError } = this.props;
        this.setState({
            campaignDailyBudget: campaignMinBudget,
            campaignDailyBudgetErrors: [],
        });
        handleFormError(false);
    }

    setAdviceDailyBudget(value) {
        const { handleFormError } = this.props;
        this.setState({
            campaignDailyBudget: value,
            campaignDailyBudgetErrors: [],
        });
        handleFormError(false);
    }

    validateCampaignField(name, value) {
        const errors = [];
        switch (name) {
            case 'campaignDailyBudget': {
                if (value.length === 0) {
                    errors.push('blank');
                }
                const num = parseFloat(value);
                if (!/^[-+]{0,1}[0-9]+\.{0,1}[0-9]{0,}$/.test(value) || isNaN(num)) {
                    errors.push('number');
                }

                const minimalDailyBudget = this.props.campaignMinBudget;
                if (num < minimalDailyBudget) {
                    errors.push('lower');
                }
                break;
            }
            case 'campaignAdMessage':
            case 'campaignAdMessage2': {
                const validator = new AdMessageValidator(value);
                const invalidTag = validator.getInvalidTag();
                if (invalidTag !== null) {
                    errors.push(new InvalidTagError(invalidTag));
                }
                const prohibitedWord = validator.getProhibitedWord();
                if (prohibitedWord !== null) {
                    errors.push(new ProhibitedWordError(prohibitedWord));
                }
                if (!value || !value.length) {
                    errors.push('empty');
                }
                if (value.length > 512) {
                    errors.push('ad message is too long');
                }
                break;
            }
            default:
                break;
        }
        return {
            [`${name}Errors`]: errors,
        };
    }

    hasCampaignFormErrors() {
        return (
            this.state.campaignAdMessage2Errors.length !== 0 ||
            this.state.campaignAdMessageErrors.length !== 0 ||
            this.state.campaignDailyBudgetErrors.length !== 0
        );
    }

    handleCampaignFieldAsyncValidation(name, value) {
        this.validateCampaignFieldAsync(name, value).then((fieldErrorState) => {
            const { handleAdMessageTagsHasError, handleFormError, changeTipsErrorText, campaignMinBudget } = this.props;

            // call method like with state object
            // because of issue of many state changes in same time
            const hasErrors = this.hasCampaignFormErrors.apply({
                state: {
                    ...this.state,
                    ...fieldErrorState,
                },
            });

            this.setState({
                ...fieldErrorState,
            });

            const messageAdMessageError = fieldErrorState.campaignAdMessageErrors;
            const messageAdMessage2Error = fieldErrorState.campaignAdMessage2Errors;
            const dailyBudgetError = fieldErrorState.campaignDailyBudgetErrors;

            if (messageAdMessageError && messageAdMessageError.length) {
                const adMessageError = messageAdMessageError[0];
                if (adMessageError instanceof Error && 'code' in adMessageError) {
                    switch (adMessageError.code) {
                        case AD_MESSAGE_ERROR_CODE.INVALID_DYNAMIC_TAG:
                            handleAdMessageTagsHasError("You can't save an ad message with a wrong dynamic tag.");
                            break;
                        case AD_MESSAGE_ERROR_CODE.PROHIBITED_WORD:
                            handleAdMessageTagsHasError("You can't save an ad message with a prohibited words.");
                            break;
                        default:
                            break;
                    }
                }
            } else {
                handleAdMessageTagsHasError(false);
            }

            if (messageAdMessage2Error && messageAdMessage2Error.length) {
                const adMessage2Error = messageAdMessage2Error[0];
                if (adMessage2Error instanceof Error && 'code' in adMessage2Error) {
                    switch (adMessage2Error.code) {
                        case AD_MESSAGE_ERROR_CODE.INVALID_DYNAMIC_TAG:
                            handleAdMessageTagsHasError("You can't save an ad message with a wrong dynamic tag.");
                            break;
                        case AD_MESSAGE_ERROR_CODE.PROHIBITED_WORD:
                            handleAdMessageTagsHasError("You can't save an ad message with a prohibited words.");
                            break;
                        default:
                            break;
                    }
                }
            } else {
                handleAdMessageTagsHasError(false);
            }

            if (dailyBudgetError && dailyBudgetError.length) {
                changeTipsErrorText(
                    'You can’t launch a campaign with a daily budget lower than ' +
                        `${campaignMinBudget} ${this.state.currency}.`,
                );
            }

            handleFormError(hasErrors);
        });
    }

    changeCampaignField(e) {
        const { name, value } = e.target;
        this.onCampaignFieldChange(name, value);
        this.setState({
            [name]: value,
        });
        this.handleCampaignFieldAsyncValidation(name, value);
    }

    render() {
        const {
            formName,
            useDynamicTagsDropdown,
            pricingAdviceItems,
            dailyBudgetDescription,
            disabled,
        } = this.props;
        let dailyBudgetAlert = '';
        let adMessageAlert = '';

        const dailyBudgetErrors = this.state.campaignDailyBudgetErrors;
        if (dailyBudgetErrors.length) {
            if (
                dailyBudgetErrors.includes('lower') ||
                dailyBudgetErrors.includes('blank') ||
                dailyBudgetErrors.includes('less')
            ) {
                dailyBudgetAlert = (
                    <AlertWidget color="danger">
                        <p className="alert-description__item">
                            Minimum Daily Budget is {this.props.campaignMinBudget} {this.state.currency}.
                        </p>
                        <button
                            type="button"
                            className="alert-description__link_highlighted"
                            onClick={this.setMinimumDailyBudget}
                        >
                            Set {this.props.campaignMinBudget} {this.state.currency}
                        </button>
                    </AlertWidget>
                );
            }
        }

        const messageAdMessageError = this.state.campaignAdMessageErrors;
        const adMessageError = messageAdMessageError[0];
        if (adMessageError instanceof Error && 'code' in adMessageError) {
            switch (adMessageError.code) {
                case AD_MESSAGE_ERROR_CODE.INVALID_DYNAMIC_TAG:
                    adMessageAlert = <AdMessageTagsError />;
                    break;
                case AD_MESSAGE_ERROR_CODE.PROHIBITED_WORD:
                    adMessageAlert = <AdMessageProhibitedWordsError word={adMessageError.value} />;
                    break;
                default:
                    break;
            }
        }

        const messageAdMessage2Error = this.state.campaignAdMessage2Errors;
        const adMessage2Error = messageAdMessage2Error[0];
        if (adMessage2Error instanceof Error && 'code' in adMessage2Error) {
            switch (adMessage2Error.code) {
                case AD_MESSAGE_ERROR_CODE.INVALID_DYNAMIC_TAG:
                    adMessageAlert = <AdMessageTagsError />;
                    break;
                case AD_MESSAGE_ERROR_CODE.PROHIBITED_WORD:
                    adMessageAlert = <AdMessageProhibitedWordsError word={adMessageError.value} />;
                    break;
                default:
                    break;
            }
        }

        const recommendedDailyTips =
            pricingAdviceItems.length &&
            pricingAdviceItems.map((dailyTip, index) => {
                const dailyAmount = +dailyTip.amount / 100;
                return (
                    <section
                        className="campaign-details__advice-tip-section"
                        key={index}
                        onClick={() => this.setAdviceDailyBudget(dailyAmount)}
                    >
                        <p className="campaign-details__advice-tip-title">
                            {dailyAmount} {this.state.currency}
                        </p>
                        <p className="campaign-details__advice-tip-description">{dailyTip.title}</p>
                    </section>
                );
            });

        const recommendedPromoTip = (
            <>
                {this.isSpecialOfferV2() ? (
                    <p className="special_offer-campaign campaign-details__advice-tip-description">
                        <span className="campaign-details__advice-min-db">45$</span>
                        Minimum daily budget for Special Offer
                    </p>
                ) : (
                    <p className="special_offer-campaign campaign-details__advice-tip-description">
                        To cover your whole potential audience, set a budget that is 2x-3x higher than the one you normally set.
                    </p>
                )}
            </>
        );

        const specialOfferMessageInputs = (
            <>
                {adMessageAlert}
                <CardTitle className="campaign-details__title" labelFor="adMessageInput" subTitle>
                    <span>Ad message for new customers</span>
                    <TipsWidget placement="top" isDarkView>
                        <p className="tips-description__content">
                            This text will be shown to people who have never been to your store,
                            but are interested in the products you offer
                        </p>
                        <p className="tips-description__content">
                            <a
                                href='https://help.adwisely.com/en/articles/2434322-set-a-special-ad-message-for-a-special-occasion'
                                className="link link-white"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                I want to learn more
                            </a>
                        </p>
                    </TipsWidget>
                </CardTitle>
                <CardContent>
                    <AdMessageInput
                        inputClassName="campaign-details__input"
                        wrapperClassName="campaign-details__admessage-wrapper"
                        name="campaignAdMessage"
                        adMessage={this.state.campaignAdMessage}
                        changeAdMessage={this.changeCampaignField}
                        useDynamicTagsDropdown={useDynamicTagsDropdown}
                        disabled={disabled}
                        error={messageAdMessageError.length !== 0}
                    />
                </CardContent>
                <CardTitle className="campaign-details__title" labelFor="adMessageInput" subTitle>
                    <span>Ad message for store visitors & cart abandoners</span>
                    <TipsWidget placement="top" isDarkView>
                        <p className="tips-description__content">
                            This text will be shown to people who have been to your store, but haven't made a purchase yet
                        </p>
                        <p className="tips-description__content">
                            <a
                                href='https://help.adwisely.com/en/articles/2434322-set-a-special-ad-message-for-a-special-occasion'
                                className="link link-white"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                I want to learn more
                            </a>
                        </p>
                    </TipsWidget>
                </CardTitle>
                <CardContent>
                    <AdMessageInput
                        inputClassName="campaign-details__input"
                        wrapperClassName="campaign-details__admessage-wrapper"
                        name="campaignAdMessage2"
                        adMessage={this.state.campaignAdMessage2}
                        changeAdMessage={this.changeCampaignField}
                        useDynamicTagsDropdown={useDynamicTagsDropdown}
                        disabled={disabled}
                        error={messageAdMessage2Error.length !== 0}
                    />
                </CardContent>
            </>
        )

        const adMessageTipText =
            this.props.adMessageTipText ?
                this.props.adMessageTipText :
                'Ad message is a short text displayed in your ad which should convince customers to buy from your store.'
        return (
            <Card className="campaign-details">
                <form id={formName} onSubmit={this.onCampaignCreateFormSubmit}>
                    {this.isSpecialOfferV2() ? specialOfferMessageInputs : (
                        <>
                            <CardTitle className="campaign-details__title" labelFor="adMessageInput" subTitle>
                                <span>Ad Message</span>
                                <TipsWidget placement="top" isDarkView>
                                    <p className="tips-description__content">
                                        {adMessageTipText}
                                    </p>
                                    <p className="tips-description__content">
                                        <a
                                            href={
                                                'https://help.adwisely.com/en/articles/900367-how-do-i-write-the-best-ad-text'
                                            }
                                            className="link link-white"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            How do I write the best ad text? &#x2192;
                                        </a>
                                    </p>
                                </TipsWidget>
                            </CardTitle>
                            <CardContent>
                                {adMessageAlert}
                                <AdMessageInput
                                    inputClassName="campaign-details__input"
                                    wrapperClassName="campaign-details__admessage-wrapper"
                                    name="campaignAdMessage"
                                    adMessage={this.state.campaignAdMessage}
                                    changeAdMessage={this.changeCampaignField}
                                    useDynamicTagsDropdown={useDynamicTagsDropdown}
                                    disabled={disabled}
                                    error={messageAdMessageError.length !== 0}
                                />
                            </CardContent>
                        </>
                    )}
                    {dailyBudgetAlert}
                    <CardTitle subTitle labelFor="dbInput">
                        <span>Daily Budget</span>
                        <TipsWidget placement="top" isDarkView>
                            <p className="tips-description__content">{dailyBudgetDescription}</p>
                            <p className="tips-description__content">
                                <a
                                    href={
                                        'https://help.adwisely.com' +
                                        '/learn-about-pricing-privacy-policy-and-terms' +
                                        '/whats-the-cost-of-retargetapp'
                                    }
                                    className="link link-white"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    I want to learn more &#x2192;
                                </a>
                            </p>
                        </TipsWidget>
                    </CardTitle>
                    <CardContent>
                        <DailyBudgetInput
                            dailyBudget={this.state.campaignDailyBudget.toString()}
                            dailyBudgetCurrency={this.state.currency}
                            onChange={this.changeCampaignField}
                            onFocus={this.changeCampaignField}
                            disabled={disabled}
                            dailyBudgetHasErrors={!!dailyBudgetErrors.length}
                        />
                        <TipsWidget
                            placement="top"
                            isDarkView
                            additionalClassName="campaign-details__link-tips"
                            customIcon={<span className="campaign-details__link">Show Recommendations</span>}
                        >
                            <div className="campaign-details__advice-tip">
                                {formName === 'specialOfferCampaignCreate' || formName === 'specialOfferCampaignEdit'
                                    ? recommendedPromoTip
                                    : recommendedDailyTips}
                            </div>
                        </TipsWidget>
                    </CardContent>
                </form>
            </Card>
        );
    }
}

const mapStateToProps = ({ shopError }) => ({
    shopError: shopError.shopError,
});

const mapDispatchToProps = {};

CampaignDetails.defaultProps = {
    dailyBudgetDescription:
        'Daily budget is the maximum amount of money you are ready ' +
        'to spend on your retargeting ads within a 24-hour period.',
    pricingAdviceItems: [
        {
            amount: 3000,
            title: 'New stores',
        },
        {
            amount: 7000,
            title: '100+ orders/month',
        },
        {
            amount: 15000,
            title: '300+ orders/month',
        },
    ],
    disabled: false,
    shopError: null,
    useDynamicTagsDropdown: true,
};

CampaignDetails.propTypes = {
    campaignAdMessage: PropTypes.string.isRequired,
    campaignBudget: PropTypes.number.isRequired,
    campaignCurrency: PropTypes.string.isRequired,
    campaignMinBudget: PropTypes.number.isRequired,
    campaignType: PropTypes.string.isRequired,
    changeTipsErrorText: PropTypes.func.isRequired,
    dailyBudgetDescription: PropTypes.string,
    disabled: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    handleAdMessageTagsHasError: PropTypes.func.isRequired,
    handleAdPreviewMsg: PropTypes.func.isRequired,
    handleFormError: PropTypes.func.isRequired,
    pricingAdviceItems: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.number,
        title: PropTypes.string
    })),
    saveCampaign: PropTypes.func.isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    useDynamicTagsDropdown: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
