import React from 'react';
import cx from 'classnames';

import DropdownProvider from 'components/DropdownProvider';
import Button from 'components/Button';
import { Caret } from 'components/Icons';
import Dropdown from 'components/Dropdown';

const generateAgeArray = (minAge, maxAge) => {
    const ageArray = [];
    for (let i = minAge; i <= maxAge; i+=1) {
        ageArray.push(i)
    }
    return ageArray;
}

export const AgeDropdown = ({startAge, endAge, setStartAge, setEndAge, availableStartAge, availableEndAge}) => (
    <div className='age-wrapper'>
        <DropdownProvider>
            {({isOpen, toggle}) => {
                const flagToggler = (
                    <Button square className={cx('countries-dropdown-flag')}>
                        <span>{startAge}</span>
                        <Caret/>
                    </Button>
                )

                const items = generateAgeArray(availableStartAge, availableEndAge)?.map((item) => (
                    <div
                        key={item}
                        className={
                            cx('countries-dropdown-item', {
                                "countries-dropdown-item--selected": item === startAge
                            } )
                        }
                        onClick={() => {
                            if (item < endAge) {
                                setStartAge(item)
                                toggle()
                            }
                        }}
                    >
                        <span>{item}</span>
                    </div>
                ))
                return (
                    <div className='ad-audience-form-group'>
                        <div className='ad-audience-form-group__label'>aged</div>
                        <Dropdown
                            toggle={(e) => {
                                toggle(e);
                            }}
                            isOpen={isOpen}
                            items={items}
                            flagToggler={flagToggler}
                            additionalMenuClassName={'age-dropdown-menu'}
                        />
                    </div>
                )
            }}
        </DropdownProvider>
        <DropdownProvider>
            {({isOpen, toggle}) => {
                const flagToggler = (
                    <Button square className={cx('countries-dropdown-flag')}>
                        <span>{endAge}</span>
                        <Caret/>
                    </Button>
                )

                const items = generateAgeArray(availableStartAge, availableEndAge)?.map((item) => (
                    <div
                        key={item}
                        className={
                            cx('countries-dropdown-item', {
                                "countries-dropdown-item--selected": item === endAge
                            } )
                        }
                        onClick={() => {
                            if (item > startAge) {
                                setEndAge(item)
                                toggle()
                            }
                        }}
                    >
                        <span>{item}</span>
                    </div>
                ))
                return (
                    <div className='ad-audience-form-group'>
                        <div className='ad-audience-form-group__label ad-audience-form-group__label--margin'>to</div>
                        <Dropdown
                            toggle={(e) => {
                                toggle(e);
                            }}
                            isOpen={isOpen}
                            items={items}
                            flagToggler={flagToggler}
                            additionalMenuClassName={'age-dropdown-menu'}
                        />
                    </div>
                )
            }}
        </DropdownProvider>
    </div>

)