import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { requestSendEventMetrics } from 'actions/shop';
import { metricType } from 'constants/metric';

import WarningIcon from './WarningIcon';
import './PaymentError.css';

const PaymentError = ({
    shopError,
    shopId,
    requestSendEventMetrics,
    adAccountFacebookId,
    facebookBMId,
}) => {
    const handleAddPaymentMethod = () => {
        window.FB.ui(
            {
                account_id: shopError?.errorData?.ad_account_id,
                display: 'popup',
                method: 'ads_payment',
            },
            () => {},
        );

        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_addcard_add_paymethod',
            },
        };
        requestSendEventMetrics(metricProps);
    };

    return (
        <div className="payment-error__wrapper">
            <h2 className="payment-error__caption">Payment not added. Try again</h2>
            <div className="payment-error__icon-wrapper">
                <WarningIcon />
            </div>
            <p className="payment-error__description">
                A valid payment method was not added. Please, add a valid payment method to start your campaign
            </p>
            <a
                className="payment-error__link"
                href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-retargetapp"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn more
            </a>
            <Button onClick={handleAddPaymentMethod} color="primary">
                Add Payment Method
            </Button>
            <p className="payment-error__text">
                Problem adding a payment method? {''}
                <a
                    className="payment-error__text-link"
                    href={`https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${adAccountFacebookId}&business_id=${facebookBMId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Try it here
                </a>
            </p>
            
        </div>
    );
};

PaymentError.propTypes = {
    shopId: PropTypes.string.isRequired,
    adAccountFacebookId: PropTypes.string.isRequired,
    facebookBMId: PropTypes.string.isRequired,
}

const mapStateToProps = ({ shopsCampaignsSettings }) => ({
    adAccountFacebookId: shopsCampaignsSettings.entity.fb_ad_account_id,
    facebookBMId: shopsCampaignsSettings.entity.fb_business_manager_fb_id,
});


const mapDispatchToProps = {
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentError);
