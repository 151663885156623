import React from 'react';
import classNames from 'classnames';

import './BulkNotification.css';

const BULK_NOTIFICATION_TYPES = {
    success: 'bulk-notification_success',
    error: 'bulk-notification_error',
};

const BulkNotification = ({ text, type }) => (
    <div className="bulk-notification__wrapper">
        <div
            className={classNames(
                'bulk-notification',
                BULK_NOTIFICATION_TYPES[type] || BULK_NOTIFICATION_TYPES.success,
            )}
        >
            <p className="bulk-notification__text">{text}</p>
        </div>
    </div>
);

export default BulkNotification;
