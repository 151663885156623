import React from 'react';
import './GoogleSearchDesktop.css';

export const GoogleSearchDesktop = ({product}) => (
    <div className='gl-search'>
        <div className="gl-search__search">{product.title}</div>
        <div className="gl-search__content">
            <div className="gl-search__content-url">
                <div className="gl-search__ad-icon">Ad</div>
                <div className="gl-search__url">{product.url}</div>
            </div>
            <div className="gl-search__content-title">
                {product.subtitle} | {product.title}
            </div>
            <div className="gl-search__content-descr">
                {product.description}
            </div>
        </div>
    </div>
)