import { hideLoader } from 'actions/loader';
import { requestFBPages } from 'actions/facebook/pages';
import { requestAddUserToChildBM, requestGetUserStatusInChildBM } from 'actions/businessManager';
import { goToInvitation, goToDomainVerification, requestOnboardingFBPageConnect } from 'actions/onboarding';
import { createChildBM, setShopCampaignSettings, createAdAccountInChildBM } from 'actions/shopCampaignsSettings';

import { GRANTED } from 'constants/fbInvitaionStatuses';
import { getCurrentBMUserSelector } from 'helpers/selectors';

export const setFBPageAndCreateChildBM = (shopId, chosenFBPageId, shopCampaignSettings, userStatus, account, fbPageIsSaved) => (
    dispatch,
) => {
    let bmId = '';
    let entryPointFn = () => dispatch(setShopCampaignSettings(shopId, { pageId: chosenFBPageId }));
    if (shopCampaignSettings.entity && shopCampaignSettings.entity.page) {
        entryPointFn = () => Promise.resolve(shopCampaignSettings.entity);
    }
    dispatch(hideLoader());
    return entryPointFn()
        .then((res) => {
            if (!(res instanceof Error)) {
                if (res.business_manager) return Promise.resolve(res);
                return dispatch(createChildBM(shopId));
            }
            return res;
        })
        .then((res) => {
            if (!(res instanceof Error)) {
                if (res.ad_account) return Promise.resolve(res);
                return dispatch(createAdAccountInChildBM(shopId));
            }
            return res;
        })
        .then((res) => {
            if (!(res instanceof Error)) {
                bmId = res.business_manager;
                if (userStatus && userStatus.fb_invite_link) return Promise.resolve(userStatus);
                return dispatch(requestGetUserStatusInChildBM(bmId));
            }
            return res;
        })
        .then((res) => {
            if (Array.isArray(res)) {
                handleBusinessUsersArray(res, account, dispatch, shopId, bmId, fbPageIsSaved);
            } else {
                handleBusinessUserObject(res, dispatch, shopId, bmId, fbPageIsSaved);
            }
            return res;
        });
};

const handleBusinessUsersArray = (res, account, dispatch, shopId, bmId, fbPageIsSaved) => {
    const currentBMUser = getCurrentBMUserSelector(res, account);
    if (!(res instanceof Error) && (currentBMUser?.status !== GRANTED || !res.length) && fbPageIsSaved) {
        return dispatch(requestAddUserToChildBM(bmId)).then((result) => {
            if (!(result instanceof Error)) {
                dispatch(requestGetUserStatusInChildBM(bmId));
                return dispatch(goToInvitation(shopId));
            }
            return res;
        });
    } else if (!(res instanceof Error) && currentBMUser?.status === GRANTED) {
        return dispatch(goToDomainVerification(shopId));
    }
};

const handleBusinessUserObject = (res, dispatch, shopId, bmId, fbPageIsSaved) => {
    if (!(res instanceof Error) && (res.status !== GRANTED || Object.keys(res).length === 0) && fbPageIsSaved) {
        return dispatch(requestAddUserToChildBM(bmId)).then((result) => {
            if (!(result instanceof Error)) {
                dispatch(requestGetUserStatusInChildBM(bmId));
                return dispatch(goToInvitation(shopId));
            }
            return res;
        });
    } else if (!(res instanceof Error) && res.status === GRANTED) {
        return dispatch(goToDomainVerification(shopId));
    }
};

export const getFbPages = (params) => (dispatch) =>
    dispatch(requestFBPages(params))

export const connectFbPage = (id, shopId) => (dispatch) =>
    dispatch(requestOnboardingFBPageConnect(id, shopId))
        .then((res) => {
            dispatch(setShopCampaignSettings(shopId, { pageId: res.fb_id }))
        })
