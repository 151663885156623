import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import DailyBudgetInput from 'components/DailyBudgetInput';
import { dollarToCent } from 'modules/campaign/components/CampaignDetails/CampaignDetails';

import './GoogleEditStandardAdsForm.css';

export class GoogleEditStandardAdsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            campaignCurrency: props.campaignCurrency,
            campaignDailyBudget: props.campaignDailyBudget || 0,
            campaignDailyBudgetErrors: [],
        };

        this.validateCampaignFieldAsync = (() => {
            let timer = null;
            /** @var {null|number} -  time start */
            let ts = null;
            /** @var {number} -  time delta */
            const td = 600;
            return (...args) =>
                new Promise((resolve) => {
                    /** @var {number} - time delta */
                    let d;
                    clearTimeout(timer);
                    if (ts === null) {
                        ts = Date.now();
                        d = td;
                    } else {
                        d = Date.now() - (ts + td);
                    }
                    timer = setTimeout(
                        () => {
                            resolve(this.validateCampaignField(...args));
                            ts = null;
                            timer = null;
                        },
                        d <= 0 ? 0 : d,
                    );
                });
        })();

        this.changeCampaignField = this.changeCampaignField.bind(this);
        this.validateCampaignField = this.validateCampaignField.bind(this);
        this.setMinimumDailyBudget = this.setMinimumDailyBudget.bind(this);
        this.onCampaignCreateFormSubmit = this.onCampaignCreateFormSubmit.bind(this);
        this.handleCampaignFieldAsyncValidation = this.handleCampaignFieldAsyncValidation.bind(this);
    }

    changeCampaignField(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.handleCampaignFieldAsyncValidation(name, value);
    }

    setMinimumDailyBudget() {
        const { campaignMinBudget, handleFormError } = this.props;
        this.setState({
            campaignDailyBudget: campaignMinBudget,
            campaignDailyBudgetErrors: [],
        });
        handleFormError(false);
    }

    validateCampaignField(name, value) {
        const errors = [];
        switch (name) {
            case 'campaignDailyBudget': {
                if (value.length === 0) {
                    errors.push('blank');
                }
                const num = parseFloat(value);
                if (!/^[-+]{0,1}[0-9]+\.{0,1}[0-9]{0,}$/.test(value) || isNaN(num)) {
                    errors.push('number');
                }
                const minimalDailyBudget = this.props.campaignMinBudget;
                if (num < minimalDailyBudget) {
                    errors.push('lower');
                }
                break;
            }
            default:
                break;
        }
        return {
            [`${name}Errors`]: errors,
        };
    }

    hasCampaignFormErrors() {
        return this.state.campaignDailyBudgetErrors.length !== 0;
    }

    handleCampaignFieldAsyncValidation(name, value) {
        this.validateCampaignFieldAsync(name, value).then((fieldErrorState) => {
            const { handleFormError, setHasCampaignFormError } = this.props;

            const hasErrors = this.hasCampaignFormErrors.apply({
                state: {
                    ...this.state,
                    ...fieldErrorState,
                },
            });

            this.setState({
                ...fieldErrorState,
            });

            handleFormError(hasErrors);
            setHasCampaignFormError(hasErrors);
        });
    }

    onCampaignCreateFormSubmit(e) {
        e.preventDefault();
        const { saveCampaign } = this.props;
        const { campaignDailyBudget } = this.state;
        const dailyBudget = dollarToCent(campaignDailyBudget);
        if (dailyBudget instanceof Error) {
            const err = dailyBudget;
            this.setState({
                campaignDailyBudgetErrors: [err.message],
            });
            return;
        }

        saveCampaign(dailyBudget);
    }

    render() {
        const { formName, disabled, disabledSubmitButton, campaignMinBudget } = this.props;
        const { campaignCurrency, campaignDailyBudget, campaignDailyBudgetErrors } = this.state;

        const hasDailyBudgetError =
            (campaignDailyBudgetErrors?.length && campaignDailyBudgetErrors.includes('lower')) ||
            campaignDailyBudgetErrors.includes('blank');

        return (
            <>
                <div className="gl-onboarding-settings__daily-budget__wrapper">
                    <span className="gl-onboarding-settings__daily-budget__wrapper__title">Daily budget</span>
                    <form id={formName} className="gl-edit-standard-ads-form">
                        <DailyBudgetInput
                            disabled={disabled}
                            onFocus={this.changeCampaignField}
                            onChange={this.changeCampaignField}
                            dailyBudgetCurrency={campaignCurrency}
                            dailyBudget={campaignDailyBudget.toString()}
                            dailyBudgetHasErrors={!!campaignDailyBudgetErrors.length}
                        />
                        {hasDailyBudgetError && (
                            <div className="gl-edit-standard-ads-form__error">{`$${campaignMinBudget} USD Minimum`}</div>
                        )}
                    </form>
                </div>
                <div className="gl-onboarding-settings__button-wrapper">
                    <Button
                        submit
                        color="primary"
                        disabled={disabledSubmitButton}
                        form="GoogleOnboardingSettings"
                        className="gl-onboarding-settings-cta-button"
                        onClick={this.onCampaignCreateFormSubmit}
                    >
                        Continue
                    </Button>
                </div>
            </>
        );
    }
}

GoogleEditStandardAdsForm.defaultProps = {
    disabled: false,
};

GoogleEditStandardAdsForm.propTypes = {
    disabledSubmitButton: PropTypes.bool.isRequired,
    campaignCurrency: PropTypes.string.isRequired,
    campaignDailyBudget: PropTypes.number.isRequired,
    campaignMinBudget: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    handleFormError: PropTypes.func.isRequired,
    saveCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = ({ shopError }) => ({
    shopError: shopError.shopError,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleEditStandardAdsForm);
