import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import DashboardGoogleInfoNotifications from 'modules/dashboard/DashboardGoogleInfoNotifications';
import { setLocalStorageObjectItem, getLocalStorageObjectItem } from 'helpers';
import { CAMPAIGNS_TYPES } from 'constants/campaign';

import './StatisticTabs.css';

class Tabs extends Component {
    state = {
        activeTab: getLocalStorageObjectItem('activeTab') || '0',
        campaignType: getLocalStorageObjectItem('activeTab') === '1' ? CAMPAIGNS_TYPES.google : CAMPAIGNS_TYPES.facebook,
    };

    getCampaignType = (tab) => {
        const type = tab > 0 ? CAMPAIGNS_TYPES.google : CAMPAIGNS_TYPES.facebook;
        this.props.getCampaignType(type);
        this.setState({
            campaignType: type,
        });
    };

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
            setLocalStorageObjectItem('activeTab', tab);
        }
    };

    render() {
        const { tabsTitles, tabsContent, currentShopId, disableTab } = this.props;
        const { activeTab, campaignType } = this.state;
        return (
            <div className="tabs--insights">
                <div tabs className="tabs-navigation--insights">
                    {tabsTitles.map((title, id) => (
                        <div key={id} className='nav-item'>
                            <a
                                className={classNames(
                                    'tabs-navigation__link--insights',
                                    { 'tabs-navigation__link_active--insights': activeTab === `${id}` },
                                    { 'disable-tab--insights': disableTab },
                                )}
                                onClick={() => {
                                    this.toggle(`${id}`);
                                    this.getCampaignType(`${id}`);
                                }}
                            >
                                {title}
                            </a>
                        </div>
                    ))}
                </div>

                {campaignType === CAMPAIGNS_TYPES.google && <DashboardGoogleInfoNotifications shopId={currentShopId} />}

                <div className="tabs-content">
                    {tabsContent.map((tabContent, id) => (
                        <div className={classNames('tabs-pane', {'active':  activeTab === `${id}`})} key={id}>
                            {tabContent}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    tabsTitles: PropTypes.arrayOf(PropTypes.element).isRequired,
    tabsContent: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Tabs;
