import React, {useState} from 'react';
import {connect} from 'react-redux';

import {ONBOARDING_TYPES} from 'constants/onboarding';
import {changeOnboardingState} from 'modules/home/utils';
import Button from 'components/Button';
import {replace} from "react-router-redux";
import {setGoogleOnboardingState} from "actions/googleOnboardingState";

import './CreateMerchantCenter.css';
import {googleStatePolling} from "../../helpers/googleStatePolling";


const CreateMerchantCenter = ({ currentShopId, googleOnboardingState, setGoogleOnboardingState, redirect }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES.google,
            status: googleOnboardingState,
            currentShopId,
        }).then((res) => {
            if (res.status === 409) {
                googleStatePolling(currentShopId, redirect, setGoogleOnboardingState)
            }
            return res.json()
        })
            .then((res) => {
                if (res?.processing) {
                    googleStatePolling(currentShopId, redirect, setGoogleOnboardingState);
                }
            });
    }

    return (
        <div className="create-merchant-center__wrapper">

            <div className="create-merchant-center__body-wrapper">
                <div>
                    <p className="create-merchant-center__description">
                        We noticed you may not yet have a Google Merchant Center account. To proceed with Google onboarding, you'll need to create one. For your Google account <span className="notification__bold_span">used for store access</span>, please visit
                        <a
                            href={
                                'https://www.google.com/retail/solutions/merchant-center/'
                            }
                            className="notification__link_with-left-margin-5 notification__link_with-right-margin-5"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            this link
                        </a>
                        to create a Google Merchant Center.
                    </p>
                    <p className="create-merchant-center__description">
                        Once you've created a Merchant Center account, return to this page and click the button below.
                    </p>
                </div>
            </div>

            <Button onClick={handleContinueBtn} color="primary" pending={isLoading}>
                I have a Merchant Center
            </Button>

            <p className="create-merchant-center__bottom_text_field">
                If you already have a Merchant Center account but you are still seeing this error window, please contact us at
                <a href="mailto:help@adwisely.com" className="primary-link">
                    help@adwisely.com
                </a>
            </p>

        </div>
    );
};

const mapStateToProps = ({ google, shops }) => ({ 
    googleOnboardingState: "create_merchant_center",
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    redirect: replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export default connect(mapStateToProps, mapPropsToDispatch)(CreateMerchantCenter);
