import PropTypes from "prop-types"
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    requestCampaignSuggestionsForCampaignCreate,
    goToDashboard,
    clearCampaignError,
    requestCampaignValidateFacebook,
} from 'actions/campaign';
import { showBulkNotification, displayBulkNotification } from 'actions/notification';
import { validateShop, clearShopError } from 'actions/shop';

import pageNameSetter from 'decorator/pageNameSetter';

import campaignEditHelper from 'helpers/campaignEditHelper';
import {
    campaignErrorSelector,
    getCurrentShopSelector,
    isShopValidateRequesting,
    getShopErrorSelector,
    getBulkNotificationContentSelector,
    getBulkNotificationTypeSelector,
    getAdAccountsSelector,
    getCampaignSuggestionsSelector,
    getShopRecurringChargeSelector,
} from 'helpers/selectors';

import Banners from 'components/Banners';
import IntercomChatCaller from 'components/IntercomChatCaller';
import BulkNotification from 'components/BulkNotification';
import GeneralBannerNotificationList from 'components/GeneralBannerNotificationList';

import { SPECIAL_OFFER, changeDailyBudgetPage } from 'constants/campaign';
import { changeShopPage } from 'constants/metric';
import AdsPreviewSection from "components/AdsPreviewSection/AdsPreviewSection";
import { ONBOARDING_TYPES } from "constants/onboarding";
import { SHOPIFY } from "constants/platform";
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from '../constants';

import CampaignInfo from '../components/CampaignInfo/index';
import CampaignDetails from '../components/CampaignDetails';
import EditCampaignDetails from '../components/EditCampaignDetails/index';
import { CampaignPageLayout, CampaignPageCol } from '../components/CampaignPageLayout';

import DashboardInfoNotifications from '../../dashboard/DashboardInfoNotifications';
import specialOfferIcon from './star.svg';

import './SpecialOfferCampaignEdit.css';
import {EditCampaignAdAudience} from '../components/EditCampaignAdAudience/EditCampaignAdAudience';
import {normalizeCountriesArray} from '../components/AdAudience/helpers/handleAdAudienceState';

class SpecialOfferCampaignEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewAdMessage: null,
            currentPreviewType: null,
            isPublishPage: true,

            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: props.campaign ? props.campaign.settings.daily_budget / 100 : 0,
            campaignAdMessage: props.campaign ? props.campaign.ad_sets[0]?.message : '',
            campaignAdMessage2: props.campaign ? props.campaign.ad_sets[1]?.message : '',
            campaignMinBudget: 0,

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            saveCampaignError: null,
            isMobilePreview: false,
            countriesList: [],
            isWorldwide: false,
            ageMin: 0,
            ageMax: 0,
            genders: 0
        };

        this.handleAdPreviewMsg = this.handleAdPreviewMsg.bind(this);
        this.handlePreviewType = this.handlePreviewType.bind(this);

        // save campaign details
        this.handleValidateFormError = this.handleValidateFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);

        this.refreshMinBudgetAndAdPreview = this.refreshMinBudgetAndAdPreview.bind(this);
        this.changePublishPage = this.changePublishPage.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
            checkShopForErrors,
        } = this.props;
        requestSuggestions({ id: shopId });
        checkShopForErrors({ shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.suggestions.campaignSuggestionsSuccess && nextProps.suggestions.campaignSuggestionsSuccess) {
            this.setState({
                campaignMinBudget:
                    nextProps.suggestions.campaignSuggestionsData.daily_budget.special_offer_min_value / 100,
                campaignCurrency: nextProps.suggestions.campaignSuggestionsData.daily_budget.currency,
            });
        }

        if (!this.props.campaign.campaignEditSuccess && nextProps.campaign.campaignEditSuccess) {
            const { campaign } = nextProps;
            this.setState({
                campaignBudget: campaign.settings.daily_budget / 100,
                campaignAdMessage: campaign.ad_sets[0]?.message,
                campaignAdMessage2: campaign.ad_sets[1]?.message,
            });
        }
        if (nextProps.campaign.campaignEditFailure) {
            const text = (
                <span>
                    Something went wrong. Change daily budget/ad message or{' '}
                    <IntercomChatCaller className="edit-campaign-details-contact-us_link" content="contact us" /> to
                    give you help.
                </span>
            );
            this.handleAdMessageTagsHasError(text);
        }
        if (this.props.adAccounts.connectStatus.access_status !== nextProps.adAccounts.connectStatus.access_status) {
            const { adAccounts } = nextProps;
            const { chosenAdAccountId, connectStatus } = adAccounts;

            if (
                chosenAdAccountId &&
                connectStatus.fb_id === this.state.errorData.ad_account_id &&
                connectStatus.access_status === 'granted'
            ) {
                this.refreshMinBudgetAndAdPreview();
            }
        }
    }

    componentDidUpdate() {
        if (this.props.campaignError) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    componentWillUnmount() {
        const { clearCampaignError, clearShopError } = this.props;
        clearCampaignError();
        clearShopError();
    }

    handleAdPreviewMsg(msg, isForceRequest = false) {
        this.setState({
            previewAdMessage: msg,
        });
    }

    handlePreviewType(curPreviewType, isForceRequest = true) {
        this.setState({
            currentPreviewType: curPreviewType,
        });
    }

    campaignValidateFacebookHandler(params) {
        const {
            shopValidateRequesting,
            checkShopForErrors,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId, params).then((res) => {
                if (!(res instanceof Error)) {
                    checkShopForErrors({ shopId });
                }
            });
        }
    }

    handleValidateFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    saveCampaign(dailyBudget, adMessage) {
        const {
            editSpecialOfferCampaign,
            goToNextStep,
            params: { shopId, id },
        } = this.props;

        const {
            countriesList,
            isWorldwide,
            ageMin,
            ageMax,
            genders
        } = this.state;

        const gendersArray = Object.keys(genders).map(gender => +gender)

        const params = {
            id: +id,
            shopId,
            campaign_type: SPECIAL_OFFER,
            daily_budget: dailyBudget,
            messages: adMessage,
            set_from_page: changeDailyBudgetPage.editCampaignPage,
            targeting_settings: {
                countries: countriesList,
                worldwide: isWorldwide,
                age_min: ageMin,
                age_max: ageMax,
                genders: gendersArray
            }
        };

        editSpecialOfferCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                this.props.showBulkNotification('Campaign settings updated.');
                goToNextStep(shopId);
                this.setState({
                    saveCampaignError: null,
                });
            } else {
                this.props.displayBulkNotification('Campaign edit failure!', 'error');
                if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                    this.setState({
                        saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                    });
                }
            }
        });
    }

    refreshMinBudgetAndAdPreview() {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    changePublishPage(isPublish) {
        this.setState({
            isPublishPage: isPublish,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        this.setState({
            adMessageTagsHasError: !!tipsErrorText,
            adMessageTagsHasErrorTipsErrorText: tipsErrorText,
        });
    }

    setIsMobilePreview = (newState) => {
        this.setState({isMobilePreview: newState})
    }

    getAdAudience = (countries, isWorldwide, ageMin, ageMax, genders) => {
        this.setState({
            countriesList: countries.length > 0 ? countries.map(item => item.slug) : [],
            isWorldwide,
            ageMin,
            ageMax,
            genders
        })
    }

    render() {
        const campaignFormName = 'specialOfferCampaignEdit';

        const {isWorldwide, countriesList} = this.state;

        const {
            shopError,
            params: { shopId },
            shopRecurringCharge,
            campaign,
            validationPending,
            suggestions: { campaignSuggestionsData },
            bulkNotificationContent,
            bulkNotificationType,
            currentShop,
            checkShopForErrors,
            campaignError,
        } = this.props;
        const { campaignEditPending } = campaign;
        let bulkNotification = null;

        if (bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        let shopErrorBanner = null;
        if ((shopError && Object.keys(shopError).length) || campaignError) {
            shopErrorBanner = (
                <Banners
                    shopError={shopError}
                    changeTipsErrorText={this.changeTipsErrorText}
                    handleFormError={this.handleValidateFormError}
                    validationPending={validationPending}
                    validateShopSettings={() => checkShopForErrors({ shopId })}
                    campaignValidateFacebook={this.campaignValidateFacebookHandler}
                    shopRecurringCharge={shopRecurringCharge}
                    checkShopForErrors={() => checkShopForErrors({ shopId })}
                    shopId={shopId}
                    currentShop={currentShop}
                    changePublishPage={this.changePublishPage}
                    campaignError={campaignError}
                />
            );
        }

        return (
            <div className="special_offer-campaign-edit__wrapper">
                <DashboardInfoNotifications shop={currentShop} campaignEdit />

                <GeneralBannerNotificationList shopId={shopId} />

                {shopErrorBanner}

                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleValidateFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={this.state.campaignAdMessage}
                            campaignAdMessage2={this.state.campaignAdMessage2}
                            campaignCurrency={this.state.campaignCurrency}
                            campaignMinBudget={this.state.campaignMinBudget}
                            campaignBudget={this.state.campaignBudget}
                            saveCampaign={this.saveCampaign}
                            pricingAdviceItems={
                                campaignSuggestionsData && campaignSuggestionsData.daily_budget
                                    ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                                    : []
                            }
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            disabled={campaignEditPending}
                            saveCampaignError={this.state.saveCampaignError}
                            campaignType={SPECIAL_OFFER}
                            campaign={campaign}
                        />

                        <EditCampaignAdAudience
                            onAdAudienceSettingsChange={this.getAdAudience}
                            shopId={shopId}
                            isDropdownError={!isWorldwide && Object.keys(countriesList).length === 0}
                            countriesListData={
                                normalizeCountriesArray(
                                    Object.keys(campaign?.targeting_settings?.countries), campaign?.targeting_settings?.available_countries
                                )}
                            availableCountriesData={
                                normalizeCountriesArray(
                                    Object.keys(campaign?.targeting_settings?.available_countries), campaign?.targeting_settings?.available_countries
                                )}
                            isWorldwideAvailable={campaign?.targeting_settings?.available_worldwide}
                            isWorldWide={campaign?.targeting_settings?.worldwide}
                            maxNumOfCountries={campaign?.targeting_settings?.max_num_of_countries}
                            gendersData={campaign?.targeting_settings?.genders}
                            availableGendersData={campaign?.targeting_settings?.available_genders}
                            ageMaxData={campaign?.targeting_settings?.age_max}
                            ageMinData={campaign?.targeting_settings?.age_min}
                            availableAgeMaxData={campaign?.targeting_settings?.available_age_max}
                            availableAgeMinData={campaign?.targeting_settings?.available_age_min}
                            isGenderVisible
                            isAgeVisible
                            isCountriesVisible
                        />

                        <CampaignInfo
                            showPricing={false}
                            showIcon
                            titleIconUrl={specialOfferIcon}
                            campaignTitle="Special Offer"
                            learnMoreLink="https://help.adwisely.com/en/articles/4585544-special-offer"
                            campaignDescription="Re-engage people who were interested in your products in the past 180 days with a limited-time offer like a sitewide discount."
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdsPreviewSection
                            currentActiveCard={ONBOARDING_TYPES.facebook_visitor_retargeting}
                            isMobilePreview={this.state.isMobilePreview}
                            setIsMobilePreview={this.setIsMobilePreview}
                            previewAdMessage={this.state.previewAdMessage}
                            isShopify={this.props.currentShop?.type === SHOPIFY}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <EditCampaignDetails
                    formName={campaignFormName}
                    hasCampaignFormError={this.state.hasCampaignFormError}
                    shopId={shopId}
                    campaignEditPending={campaignEditPending}
                    tipsErrorText={this.state.adMessageTagsHasErrorTipsErrorText || this.state.tipsErrorText}
                />
                {bulkNotification}
            </div>
        );
    }
}

SpecialOfferCampaignEdit.defaultProps = {
    bulkNotificationContent: null,
    bulkNotificationType: null,
    shopError: null,
    validationPending: false,
};

SpecialOfferCampaignEdit.propTypes = {
    adAccounts: PropTypes.shape({
        connectStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                access_status: PropTypes.string,
            })
        ]),
    }).isRequired,
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    campaign: PropTypes.shape({
        campaignEditPending: PropTypes.bool,
        campaignEditSuccess: PropTypes.bool,
        settings: PropTypes.shape({
            daily_budget: PropTypes.number,
        }),
    }).isRequired,
    campaignValidateFacebook: PropTypes.func.isRequired,
    checkShopForErrors: PropTypes.func.isRequired,
    clearCampaignError: PropTypes.func.isRequired,
    clearShopError: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    editSpecialOfferCampaign: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    shopRecurringCharge: PropTypes.shape({
        entity: PropTypes.shape({
            confirmation_url: PropTypes.string,
        }),
    }).isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    showBulkNotification: PropTypes.func.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsSuccess: PropTypes.bool.isRequired
    }).isRequired,
    validationPending: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
    currentShop: getCurrentShopSelector(state.shops, state.shops.currentShopId),
    suggestions: getCampaignSuggestionsSelector(state),
    campaignDetails: state.campaignDetails,
    campaign: state.campaignDetails.campaignDetailsData.find((el) => el.id === +ownProps.params.id),
    shopError: getShopErrorSelector(state),
    shopValidateRequesting: isShopValidateRequesting(state),
    shopRecurringCharge: getShopRecurringChargeSelector(state),
    bulkNotificationContent: getBulkNotificationContentSelector(state),
    bulkNotificationType: getBulkNotificationTypeSelector(state),
    adAccounts: getAdAccountsSelector(state),
    campaignError: campaignErrorSelector(state.campaignDetails.campaignDetailsData),
});

const mapDispatchToProps = {
    campaignValidateFacebook: requestCampaignValidateFacebook,
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    editSpecialOfferCampaign: (params) => (dispatch, getState) => campaignEditHelper(params, dispatch, getState),
    goToNextStep: goToDashboard,
    checkShopForErrors: validateShop,
    clearCampaignError,
    showBulkNotification,
    displayBulkNotification,
    clearShopError,
};

const SpecialOfferCampaignEditContainer = connect(mapStateToProps, mapDispatchToProps)(SpecialOfferCampaignEdit);

export default pageNameSetter(changeShopPage.edit_campaign)(SpecialOfferCampaignEditContainer);
