import {environment_name} from 'constants/environment';

export default (on_load) => {
    /*
     * GDPR Consent Popup is implemented via cookie-script.com
     * See the following links on its SDK:
     * - https://support.cookie-script.com/article/20-custom-events
     * - https://support.cookie-script.com/article/21-custom-functions
     *
     * Cookie Script in the PROD environment is geo restricted to EU and UK only.
     * In other environments it is turned on for all clients.
     *
     */
    const cookiescript_src = environment_name === 'prod'
        ? '//geo.cookie-script.com/s/a0cdecbf5a327f2e5a719ee3b315ad9f.js?region=eu&country=uk'
        : '//cdn.cookie-script.com/s/8aac8d3278fd6b4fe745befd133dc5f3.js';

    const new_script_id = 'cookiescript';
    window.addEventListener('CookieScriptLoaded', () => {
        const state = CookieScript.instance.currentState().categories;
        // WARNING: The following code doesn't work for non-EU countries because
        // the geo restricted version of Cookie Script doesn't emit the `CookieScriptLoaded` event.
        // TODO: Do not rely on the `CookieScriptLoaded` DOM event.
        on_load(
            state.includes('functionality'),
            state.includes('performance'),
            state.includes('targeting'));
    });

    if (document.getElementById(new_script_id)) {
        return;
    }

    const first_script = document.getElementsByTagName('script')[0];
    const new_script = document.createElement('script');
    new_script.id = new_script_id;
    new_script.src = cookiescript_src;
    first_script.parentNode.insertBefore(new_script, first_script);
};
