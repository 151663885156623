import { getRedirectPage } from 'modules/onboarding/userBMFlow/helpers/getOnboardingState';
import { fetchOnboardingState } from 'helpers/fetchOnboardingState';
import { statePollingInterval } from 'helpers/statePollingInterval';
import { getLocalStorageObjectItem } from 'helpers';
import { ROUTES } from 'constants/routes';

export const facebookStatePolling = (shopId, redirect, additionalAction = () => {}, signal) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const onCompleteCb = (res) => {
        additionalAction(res);
        getRedirectPage(res, shopId, redirect)
    }

    const onNotAuthCb = () => {
        redirect(ROUTES.facebookLogin)
    }

    statePollingInterval(
        fetchOnboardingState,
        currentOnboarding,
        shopId,
        onCompleteCb,
        () => {},
        onNotAuthCb,
        signal
    );
};
