import { combineReducers } from 'redux';

import pages from './pages';
import login from './login';
import pageConnect from './pageConnect';

const facebook = combineReducers({
    pages,
    login,
    pageConnect,
});

export default facebook;
