import appHostList from 'constants/appHostList';
import { BIG_COMMERCE, SHOPIFY, WOO_COMMERCE } from 'constants/platform';
import { environment_name } from 'constants/environment';

const appIdsList = {
    dev: 3050930177,
    test: 3056992257,
    test_ah: 2804285441,
};

const createLink = (shopHost, shopType, env) => {
    let link = '';
    if (shopType === SHOPIFY) {
        link = `https://partners.shopify.com/310961/apps/${appIdsList[env]}/overview`;
    } else if (shopType === BIG_COMMERCE) {
        link = `https://${shopHost}/manage/marketplace/apps/15924`;
    } else {
        link =
            `http://${shopHost}` +
            '/wp-admin/plugin-install.php?tab=plugin-information&plugin=retargetapp-integration-for-woocommerce';
    }
    return link;
};

const getInstallLink = (shopHost, shopType) => {
    const env = environment_name;
    let link;

    if (env === 'prod' || !shopHost || !(env in appHostList)) {
        if (shopType === SHOPIFY) {
            link = 'https://apps.shopify.com/adwisely#login';
        } else if (shopType === BIG_COMMERCE) {
            link = 'https://www.bigcommerce.com/apps/adwisely/';
        } else if (shopType === WOO_COMMERCE) {
            link = 'https://wordpress.org/plugins/retargetapp-integration-for-woocommerce/#installation';
        }
    } else {
        link = createLink(shopHost, shopType, env);
    }

    return link;
};

export default getInstallLink;
