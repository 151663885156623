import React from 'react';
import { ToDoListStatus } from '../ToDoListConstants';

export const ToDoListItemStatus = ({ status }) => {
    switch (status) {
        case ToDoListStatus.complete:
            return <div className='todo-list__item-status todo-list__item-status--complete'>Complete</div>
        case ToDoListStatus.verification:
            return <div className='todo-list__item-status todo-list__item-status--verification'>On verification*</div>
        default:
            return <div className="todo-list__item-status todo-list__item-status--start-btn">Start</div>
    }
}