import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AlertWidget from 'components/AlertWidget';
import { EditRemarketingAdsFormView } from 'modules/campaign/components/EditRemarketingAdsForm/EditRemarketingAdsFormView';

import { dollarToCent } from '../../../campaign/components/CampaignDetails/CampaignDetails';

export class EditRemarketingAdsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            campaignShortHeadline: props.campaignShortHeadline,
            campaignLongHeadline: props.campaignLongHeadline,
            campaignDescription: props.campaignDescription,
            campaignFinalUrl: props.campaignFinalUrl,
            campaignCurrency: props.campaignCurrency,
            campaignDailyBudget: props.campaignDailyBudget || 0,

            campaignShortHeadlineErrors: [],
            campaignLongHeadlineErrors: [],
            campaignDescriptionErrors: [],
            campaignFinalUrlErrors: [],
            campaignDailyBudgetErrors: [],
        };

        this.validateCampaignFieldAsync = (() => {
            let timer = null;
            /** @var {null|number} -  time start */
            let ts = null;
            /** @var {number} -  time delta */
            const td = 600;
            return (...args) =>
                new Promise((resolve) => {
                    /** @var {number} - time delta */
                    let d;
                    clearTimeout(timer);
                    if (ts === null) {
                        ts = Date.now();
                        d = td;
                    } else {
                        d = Date.now() - (ts + td);
                    }
                    timer = setTimeout(
                        () => {
                            resolve(this.validateCampaignField(...args));
                            ts = null;
                            timer = null;
                        },
                        d <= 0 ? 0 : d,
                    );
                });
        })();

        this.changeCampaignField = this.changeCampaignField.bind(this);
        this.validateCampaignField = this.validateCampaignField.bind(this);
        this.setMinimumDailyBudget = this.setMinimumDailyBudget.bind(this);
        this.onCampaignCreateFormSubmit = this.onCampaignCreateFormSubmit.bind(this);
        this.handleCampaignFieldAsyncValidation = this.handleCampaignFieldAsyncValidation.bind(this);
    }

    onCampaignFieldChange = (fieldName, value) => {
        if (fieldName === 'campaignShortHeadline') {
            this.props.handleAdPreviewChange(fieldName, value);
        }

        if (fieldName === 'campaignFinalUrl') {
            this.props.handleAdPreviewChange(fieldName, value);
        }
    };

    changeCampaignField = (e) => {
        const { name, value } = e.target;

        this.onCampaignFieldChange(name, value);

        this.setState({
            [name]: value,
        });

        this.handleCampaignFieldAsyncValidation(name, value);
    };

    setMinimumDailyBudget = () => {
        const { campaignMinBudget, handleFormError } = this.props;

        this.setState({
            campaignDailyBudget: campaignMinBudget,
            campaignDailyBudgetErrors: [],
        });

        handleFormError(false);
    };

    validateCampaignField = (name, value) => {
        const errors = [];

        switch (name) {
            case 'campaignShortHeadline': {
                if (value.length === 0) {
                    errors.push('empty-short_headline');
                }
                if (value.length > 25) {
                    errors.push('maxlen-short_headline');
                }
                break;
            }
            case 'campaignLongHeadline':
            case 'campaignDescription': {
                if (value.length === 0) {
                    errors.push('empty-long_headline');
                }
                if (value.length > 90) {
                    errors.push('maxlen-long_headline');
                }
                break;
            }
            case 'campaignFinalUrl': {
                if (value.length === 0) {
                    errors.push('empty_description');
                }
                break;
            }
            case 'campaignDailyBudget': {
                if (value.length === 0) {
                    errors.push('blank');
                }
                const num = parseFloat(value);
                if (!/^[-+]{0,1}[0-9]+\.{0,1}[0-9]{0,}$/.test(value) || isNaN(num)) {
                    errors.push('number');
                }
                const minimalDailyBudget = this.props.campaignMinBudget;
                if (num < minimalDailyBudget) {
                    errors.push('lower');
                }
                break;
            }
            default:
                break;
        }

        return {
            [`${name}Errors`]: errors,
        };
    };

    hasCampaignFormErrors = () => {
        const {
            campaignShortHeadlineErrors,
            campaignLongHeadlineErrors,
            campaignDescriptionErrors,
            campaignFinalUrlErrors,
            campaignDailyBudgetErrors,
        } = this.state;

        return (
            campaignDailyBudgetErrors.length !== 0 ||
            campaignShortHeadlineErrors.length !== 0 ||
            campaignLongHeadlineErrors.length !== 0 ||
            campaignDescriptionErrors.length !== 0 ||
            campaignFinalUrlErrors.length !== 0
        );
    };

    handleCampaignFieldAsyncValidation = (name, value) => {
        this.validateCampaignFieldAsync(name, value).then((fieldErrorState) => {
            const { handleFormError } = this.props;

            const hasErrors = this.hasCampaignFormErrors.apply({
                state: {
                    ...this.state,
                    ...fieldErrorState,
                },
            });

            this.setState({
                ...fieldErrorState,
            });

            handleFormError(hasErrors);
        });
    };

    onCampaignCreateFormSubmit = (e) => {
        e.preventDefault();

        const { saveCampaign, handleFormError } = this.props;

        const {
            campaignShortHeadline,
            campaignLongHeadline,
            campaignDescription,
            campaignFinalUrl,
            campaignDailyBudget,
        } = this.state;

        const dailyBudget = dollarToCent(campaignDailyBudget);
        if (dailyBudget instanceof Error) {
            const err = dailyBudget;
            this.setState({
                campaignDailyBudgetErrors: [err.message],
            });
            return;
        }

        const fieldNames = ['campaignShortHeadline', 'campaignLongHeadline', 'campaignDescription', 'campaignFinalUrl'];

        if (
            campaignShortHeadline === '' ||
            campaignLongHeadline === '' ||
            campaignDescription === '' ||
            campaignFinalUrl === ''
        ) {
            handleFormError(true);
            fieldNames.forEach((name) => {
                if (this.state[name] === '') {
                    this.setState({
                        [`${name}Errors`]: ['empty-field'],
                    });
                }
            });
        } else {
            saveCampaign(
                dailyBudget,
                campaignLongHeadline,
                campaignShortHeadline,
                campaignFinalUrl,
                campaignDescription,
            );
        }
    };

    render() {
        const {
            campaignCurrency,
            campaignFinalUrl,
            campaignDailyBudget,
            campaignDescription,
            campaignLongHeadline,
            campaignShortHeadline,
            campaignShortHeadlineErrors,
        } = this.state;

        const { formName, disabled, campaignMinBudget } = this.props;

        let dailyBudgetAlert = '';

        const dailyBudgetErrors = this.state.campaignDailyBudgetErrors;
        if (dailyBudgetErrors.length) {
            if (dailyBudgetErrors.includes('lower') || dailyBudgetErrors.includes('blank')) {
                dailyBudgetAlert = (
                    <AlertWidget color="danger">
                        <p className="alert-description__item">
                            Minimum Daily Budget is {campaignMinBudget.toFixed(2)} {this.state.campaignCurrency}.
                        </p>
                        <button
                            type="button"
                            className="alert-description__link_highlighted"
                            onClick={this.setMinimumDailyBudget}
                        >
                            Set {campaignMinBudget.toFixed(2)} {this.state.campaignCurrency}
                        </button>
                    </AlertWidget>
                );
            }
        }

        return (
            <EditRemarketingAdsFormView
                disabled={disabled}
                formName={formName}
                campaignFinalUrl={campaignFinalUrl}
                dailyBudgetAlert={dailyBudgetAlert}
                dailyBudgetErrors={dailyBudgetErrors}
                dailyBudgetCurrency={campaignCurrency}
                campaignDescription={campaignDescription}
                campaignLongHeadline={campaignLongHeadline}
                dailyBudget={campaignDailyBudget.toString()}
                campaignShortHeadline={campaignShortHeadline}
                changeCampaignField={this.changeCampaignField}
                onCampaignCreateFormSubmit={this.onCampaignCreateFormSubmit}
                campaignShortHeadlineError={campaignShortHeadlineErrors.length !== 0}
                campaignFinalUrlError={this.state.campaignFinalUrlErrors.length !== 0}
                campaignDescriptionError={this.state.campaignDescriptionErrors.length !== 0}
            />
        );
    }
}

EditRemarketingAdsForm.defaultProps = {
    disabled: false,
};

EditRemarketingAdsForm.propTypes = {
    campaignCurrency: PropTypes.string.isRequired,
    campaignDailyBudget: PropTypes.number.isRequired,
    campaignDescription: PropTypes.string.isRequired,
    campaignFinalUrl: PropTypes.string.isRequired,
    campaignLongHeadline: PropTypes.string.isRequired,
    campaignMinBudget: PropTypes.number.isRequired,
    campaignShortHeadline: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    handleAdPreviewChange: PropTypes.func.isRequired,
    handleFormError: PropTypes.func.isRequired,
    saveCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = ({ shopError }) => ({
    shopError: shopError.shopError,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditRemarketingAdsForm);
