import React from 'react';
import { ToDoListModalView } from './ToDoListModalView';

export const ShareAccessToStoreDomain = ({isOpen, closeModal, onSupportBtnClick, onCompleteBtnClick}) => (
    <ToDoListModalView
        isOpen={isOpen}
        onCloseBtnClick={closeModal}
        checkboxLabel="I have shared access to my store domain"
        onSupportBtnClick={onSupportBtnClick}
        onCompleteBtnClick={onCompleteBtnClick}
    >
        <h3 className="todo-list-modal__title">Share access to store domain</h3>
        <p>To let Adwisely track and improve the results of the ads we run for you, share access to your store domain with Adwisely.</p>

        <ol>
            <li>
                In your Business Manager Settings, go to {' '}
                <a href=" https://business.facebook.com/settings/partners"
                   target="_blank"
                   rel="noopener noreferrer"
                >Partners</a>
            </li>
            <li>Click on <b>Adwisely</b></li>
            <li>Under Assets you shared, click <b>Share Assets</b></li>
            <li>In the list on the right, pick <b>Domains</b> and select the domain you want to share</li>
            <li>Under <b>Domain Partial Access</b>, click on the toggles next to <b>Link to domain</b> and <b>View events information</b></li>
            <li>Click <b>Save Changes</b></li>
        </ol>
    </ToDoListModalView>
)