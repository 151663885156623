import {
    trackEventAdMessageUpdateOnEditCampaign,
} from 'actions/intercom';
import { hideCampaignPromo } from 'actions/campaignsPromo';
import { requestEditCampaign } from 'actions/campaign';

const campaignEditHelper = (params, dispatch, getState) => {
    const state = getState();
    let isMessageChanged = false;
    const [campaign] = state.campaignDetails.campaignDetailsData.filter((camp) => +camp.id === +params.id);
    if (campaign && campaign.ad_sets[0].message !== params.message) {
        isMessageChanged = true;
    }
    const { campaignDetailsData } = state.campaignDetails;
    const currentCampaignId = +params.id;
    const newDailyBudget = params.daily_budget;
    const currentCampaign = campaignDetailsData.find((el) => el.id === currentCampaignId);
    const oldDailyBudget = currentCampaign && currentCampaign.settings.daily_budget;

    return dispatch(requestEditCampaign(params)).then((res) => {
        if (!(res instanceof Error)) {
            if (isMessageChanged) {
                dispatch(
                    trackEventAdMessageUpdateOnEditCampaign(params.campaign_type, { newAdMessage: params.message }),
                );
            }
            if (newDailyBudget > oldDailyBudget) {
                dispatch(hideCampaignPromo(params.id));
            }
        }
        return res;
    });
};

export default campaignEditHelper;
