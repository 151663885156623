import React from "react";
import './Toggler.css';

export const Toggler = ({children, isChecked, id, onChange}) => {
    const [option1, option2] = children;
    return (
        <div className='custom-toggle'>
            <input type="checkbox" className='custom-toggle__input' id={id} checked={isChecked} onChange={onChange}/>
            <label htmlFor={id} className='custom-toggle__label'>
                <span className='custom-toggle__option custom-toggle__option--1'>{option1}</span>
                <span className='custom-toggle__box' />
                <span className='custom-toggle__option custom-toggle__option--2'>{option2}</span>
            </label>
        </div>
    )
}