import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { CAMPAIGN_CLIENT_STATUS, GOOGLE_CAMPAIGN_STATUS } from './helpers';

export const showPauseCampaignButton = (clientStatus) =>
    clientStatus !== CAMPAIGN_CLIENT_STATUS.PAUSED &&
    clientStatus !== CAMPAIGN_CLIENT_STATUS.DECLINED &&
    clientStatus !== GOOGLE_CAMPAIGN_STATUS.PAUSED;

export const checkIsPauseCampaignButtonDisabled = ({
    collapseAdMessageInput,
    shopValidateRequesting,
    campaignFailureError,
    clientStatus,
    errorToken,
}) => {
    const disabledButtonTokens = [
        'failed-to-validate-message',
        'account-doesnt-have-access-to-business-manager',
        'business-manager-was-deleted',
        'ad-account-is-disabled-due-to-risk-payment',
        'shop-is-frozen-due-to-pending-recurring-charge',
        'shop-is-frozen-due-to-expired-recurring-charge',
        'shop-is-frozen-due-to-cancelled-recurring-charge',
        'shop-is-frozen-due-to-declined-recurring-charge',
        'shop-is-frozen-due-to-subscription-canceled',
        'shop-is-frozen-due-to-subscription-spend-limit-reached'
    ];

    return (
        collapseAdMessageInput ||
        shopValidateRequesting ||
        campaignFailureError ||
        clientStatus === CAMPAIGN_CLIENT_STATUS.PENDING ||
        clientStatus === CAMPAIGN_CLIENT_STATUS.DECLINED ||
        clientStatus === CAMPAIGN_CLIENT_STATUS.CREATING ||
        clientStatus === CAMPAIGN_CLIENT_STATUS.REJECTED ||
        clientStatus === GOOGLE_CAMPAIGN_STATUS.UNKNOWN ||
        clientStatus === GOOGLE_CAMPAIGN_STATUS.UNSPECIFIED ||
        clientStatus === GOOGLE_CAMPAIGN_STATUS.REMOVED ||
        disabledButtonTokens.includes(errorToken)
    );
};

const PauseCampaignButton = ({
    pending,
    campaignId,
    errorToken,
    clientStatus,
    campaignType,
    collapseAdMessageInput,
    shopValidateRequesting,
    campaignFailureError,
    togglePauseCampaignModal,
}) => {
    const isPauseCampaignButtonDisabled = checkIsPauseCampaignButtonDisabled({
        errorToken,
        clientStatus,
        collapseAdMessageInput,
        shopValidateRequesting,
        campaignFailureError,
    });

    const shouldShowPauseCampaignButton = showPauseCampaignButton(clientStatus);

    const pauseCampaignAction = () =>
        togglePauseCampaignModal(campaignId, campaignType)

    const handlePauseButton = () => !isPauseCampaignButtonDisabled && !pending && pauseCampaignAction();

    if (shouldShowPauseCampaignButton) {
        return (
            <Button
                id="pauseButton"
                pending={pending}
                disabled={isPauseCampaignButtonDisabled}
                onClick={handlePauseButton}
            >
                Pause
            </Button>
        );
    }

    return null;
};

PauseCampaignButton.propTypes = {
    campaignFailureError: PropTypes.string,
    campaignId: PropTypes.number,
    campaignType: PropTypes.string,
    clientStatus: PropTypes.string.isRequired,
    collapseAdMessageInput: PropTypes.bool.isRequired,
    errorToken: PropTypes.string,
    pending: PropTypes.bool,
    shopValidateRequesting: PropTypes.bool.isRequired,
    togglePauseCampaignModal: PropTypes.func.isRequired,
};

PauseCampaignButton.defaultProps = {
    pending: false,
    errorToken: null,
    campaignFailureError: null,
    campaignType: '',
    campaignId: null,
};

export default PauseCampaignButton;
