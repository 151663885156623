import React from 'react';
import Tooltip from 'components/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getUniqueRandomString } from 'helpers';
import { BlueInfo, Info, Warning } from 'components/Icons';

import './TipsWidget.css';

const Tips = ({
    placement,
    tipsForceOpen,
    isDarkView,
    isDarkIcon,
    additionalClassName,
    additionalIconClassName,
    customIcon,
    warningIcon,
    blueWarningIcon,
    children,
    noIcon,
    customText,
    onMouseLeave,
}) => {
    const target = getUniqueRandomString();

    let standardIcon = (
        <Info
            id={target}
            className={classNames(
                'tips__icon',
                { tips__icon_dark: isDarkView || isDarkIcon },
                additionalIconClassName,
            )}
        />
    );

    if (noIcon) {
        standardIcon = (
            <span
                onMouseLeave={onMouseLeave}
                id={target}
                className={classNames(
                    'tips__icon',
                    { tips__icon_dark: isDarkView || isDarkIcon },
                    additionalIconClassName,
                )}
            >
                {customText}
            </span>
        );
    } else if (customIcon) {
        standardIcon = <div id={target}>{customIcon}</div>;
    } else if (warningIcon) {
        standardIcon = (
            <Warning
                id={target}
                className={classNames(
                    'tips__icon',
                    additionalIconClassName,
                )}
            />
        )
    } else if (blueWarningIcon) {
        standardIcon = (
            <BlueInfo
                id={target}
                className={classNames(
                    'tips__icon',
                    additionalIconClassName,
                )}
            />
        )
    }

    return (
        <div className={classNames('tips__wrapper', { tips__wrapper_aligned: !customIcon })}>
            <Tooltip
                className={classNames(
                    'tips__description',
                    { tips__description_dark: isDarkView },
                    additionalClassName,
                )}
                placement={placement}
                target={target}
                content={children}
                isDarkView={isDarkView}
                additionalClassName={additionalClassName}
                tipsForceOpen={tipsForceOpen}
            >
                {standardIcon}
            </Tooltip>
        </div>
    );
}

Tips.defaultProps = {
    placement: 'top',
    tipsForceOpen: false,
    noIcon: false,
    isDarkView: false,
    isDarkIcon: false,
    additionalClassName: '',
    additionalIconClassName: '',
    customIcon: null,
    children: <p>This is Tooltip. Place here your own tooltip info.</p>,
};

Tips.propTypes = {
    placement: PropTypes.string,
    noIcon: PropTypes.bool,
    tipsForceOpen: PropTypes.bool,
    isDarkView: PropTypes.bool,
    isDarkIcon: PropTypes.bool,
    additionalClassName: PropTypes.string,
    additionalIconClassName: PropTypes.string,
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default Tips;
