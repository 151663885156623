import React from 'react';
import './GoogleMapsDesktop.css';

export const GoogleMapsDesktop = ({product}) => (
    <div className="gl-mp">
        <div className="gl-mp__content">
            <div className="gl-mp__url">
                <span>Ad</span>
                {product.url}
            </div>
            <div className="gl-mp__title">
                Shop the Best Selling {product.title}
            </div>
        </div>
    </div>
)