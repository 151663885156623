import React from 'react';
import cx from 'classnames';

import Button from 'components/Button';
import { Caret } from 'components/Icons';
import Dropdown from 'components/Dropdown';
import DropdownProvider from 'components/DropdownProvider';

const normalizeGendersObject = (gendersSlugArray, availableGenders) => (
    gendersSlugArray.map(item => ({
        slug: item,
        name: availableGenders[item]
    }))
)

export const GenderDropdown = ({genders, setGenders, availableGenders}) => {
    const availableGendersSlugArray = Object.keys(availableGenders);
    const availableGendersArray = normalizeGendersObject(availableGendersSlugArray, availableGenders);
    const currentGendersSlugArray = Object.keys(genders)[0];
    const currentGender = {
        slug: currentGendersSlugArray,
        name: genders[currentGendersSlugArray]
    }
    return (
        <DropdownProvider>
            {({isOpen, toggle}) => {
                const flagToggler = (
                    <Button square className={cx('countries-dropdown-flag')}>
                        <span>{currentGender.name}</span>
                        <Caret/>
                    </Button>
                )

                const items = availableGendersArray?.map(({name, slug}) => (
                    <div
                        key={slug}
                        className={
                            cx('countries-dropdown-item', {
                                "countries-dropdown-item--selected": name === currentGender.name
                            })
                        }
                        onClick={() => {
                            const gender = {};
                            gender[slug] = name
                            setGenders(gender)
                            toggle()
                        }}
                    >
                        <span>{name}</span>
                    </div>
                ))
                return (
                    <Dropdown
                        toggle={(e) => {
                            toggle(e);
                        }}
                        isOpen={isOpen}
                        items={items}
                        flagToggler={flagToggler}
                        additionalMenuClassName={'countries-dropdown-menu'}
                    />
                )
            }}
        </DropdownProvider>
    )
}