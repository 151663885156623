import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal/Modal';

import classNames from 'classnames';
import Button from 'components/Button';
import {
    requestCampaignPauseReason,
    requestGoogleCampaignPauseReason,
    requestPauseCampaign,
    requestPauseGoogleCampaign,
} from 'actions/campaign';
import { isTrialPeriodSelector } from 'helpers/selectors';

import { displayBulkNotification } from 'actions/notification';
import {
    WILL_BACK_SOON_PAUSE_REASON,
    HELP_WITH_SETTINGS_PAUSE_REASON,
    CAMPAIGN_CAN_DO_BETTER_PAUSE_REASON,
    DOES_NOT_SHOW_ANY_RESULT_PAUSE_REASON,
    DO_NOT_UNDERSTAND_APP_WORK_PAUSE_REASON,
    HELP_WITH_APP_SETTINGS_PAUSE_REASON,
    OTHER_PAUSE_REASON,
    DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON,
} from '../../constants';

import './ModalPauseCampaign.css';

class ModalPauseCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pauseOption: '',
            otherReasonTextarea: '',
            otherReasonTextareaDisabled: true,
            formValid: false,
            pending: false,
            isUninstalling: false,
        };
        this.optionsArray = new Map([
            [WILL_BACK_SOON_PAUSE_REASON, "No worries, I'll be back soon 😉"],
            [HELP_WITH_SETTINGS_PAUSE_REASON, 'I need help with campaign settings'],
            [CAMPAIGN_CAN_DO_BETTER_PAUSE_REASON, 'I think my campaign can do better'],
            [DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON, "I don't want to use Adwisely anymore"],
            [OTHER_PAUSE_REASON, 'Other reason'],
        ]);

        this.textareaRef = React.createRef();
        this.trialOtherReasonTextareaPlaceholder = 'Please, tell us more...';
        this.baseState = this.state;
        this.hideModal = this.hideModal.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.getWarningBlock = this.getWarningBlock.bind(this);
    }

    getWarningBlock() {
        const { isTrialPeriod, type } = this.props;
        if (isTrialPeriod && type === 'facebook') {
            const warningMessage = (
                <div className="pause-campaign-modal-trial-warning-message__wrapper">
                    <div className="pause-campaign-modal-trial-warning-message__aside" />
                    <div className="pause-campaign-modal-trial-warning-message__main">
                        <p className="pause-campaign-modal-trial-warning-message">
                            Facebook is now studying your website audience. This normally takes 7–10 days. If you pause
                            the ad now, the ad optimization will take longer.{' '}
                            <a
                                href={
                                    'https://help.adwisely.com/install-and-configure-retargetapp/faq/' +
                                    'when-should-i-expect-the-first-results'
                                }
                                target="_blank"
                                className="notification__link-black alert-description__link"
                                rel="noopener noreferrer"
                            >
                                Learn more
                            </a>
                        </p>
                    </div>
                </div>
            );
            const optionsHeadline = (
                <p className="pause-campaign-modal-trial-options__headline">
                    If you want to pause your ad anyway, please, pick one of the reasons below:
                </p>
            );
            this.optionsArray = new Map([
                [DOES_NOT_SHOW_ANY_RESULT_PAUSE_REASON, "The dashboard doesn't show any results"],
                [DO_NOT_UNDERSTAND_APP_WORK_PAUSE_REASON, 'I do not understand how the app works'],
                [HELP_WITH_APP_SETTINGS_PAUSE_REASON, 'I need help with the app settings'],
                [DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON, "I don't want to use Adwisely anymore"],
                [OTHER_PAUSE_REASON, 'Other reason'],
            ]);
            return [warningMessage, optionsHeadline];
        }

        if (type === 'google') {
            const warningMessage = (
                <div className="pause-campaign-modal-trial-warning-message__wrapper">
                    <div className="pause-campaign-modal-trial-warning-message__aside" />
                    <div className="pause-campaign-modal-trial-warning-message__main">
                        <p className="pause-campaign-modal-trial-warning-message">
                            Google shopping campaign is now studying. This normally takes 14-17 days. If you pause the
                            ad now, the ad optimisation will take longer.{' '}
                            <a
                                href="https://support.google.com/google-ads/answer/7674739?hl=en"
                                target="_blank"
                                className="notification__link-black alert-description__link"
                                rel="noopener noreferrer"
                            >
                                Learn more
                            </a>
                        </p>
                    </div>
                </div>
            );
            const optionsHeadline = (
                <p className="pause-campaign-modal-trial-options__headline">
                    If you want to pause your ad anyway, please, pick one of the reasons below:
                </p>
            );
            this.optionsArray = new Map([
                [DOES_NOT_SHOW_ANY_RESULT_PAUSE_REASON, "The dashboard doesn't show any results"],
                [DO_NOT_UNDERSTAND_APP_WORK_PAUSE_REASON, ' I do not understand how the shopping campaign works'],
                [HELP_WITH_APP_SETTINGS_PAUSE_REASON, 'I need help with the app settings'],
                [DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON, "I don't want to use Adwisely anymore"],
                [OTHER_PAUSE_REASON, 'Other reason'],
            ]);
            return [warningMessage, optionsHeadline];
        }

        return [null, null];
    }

    submitForm(e) {
        const { pauseOption, otherReasonTextarea } = this.state;
        const {
            campaignId,
            pauseCampaignReason,
            pauseGoogleCampaignReason,
            isTrialPeriod,
            type,
            currentShopId,
        } = this.props;
        e.preventDefault();
        let pauseReason = this.optionsArray.get(pauseOption);
        if (pauseOption === OTHER_PAUSE_REASON) {
            pauseReason = otherReasonTextarea;
        }
        const pauseRequestGlParams = {
            shopId: currentShopId,
            campaignId,
            message: pauseReason && pauseReason.trim(),
        };
        const pauseRequestParams = {
            id: campaignId,
            message: pauseReason && pauseReason.trim(),
            trial: isTrialPeriod,
        };
        this.setState({ pending: true });

        if (type === 'google') {
            pauseGoogleCampaignReason(pauseRequestGlParams, pauseOption, {
                reason: pauseReason.trim(),
                ad_platform: type,
            }).then(
                (result) => {
                    if (!(result instanceof Error)) {
                        this.props.showBulkNotification('Campaign paused.');
                    }
                    this.hideModal();
                },
                () => {
                    this.hideModal();
                },
            );
        } else {
            pauseCampaignReason(pauseRequestParams, pauseOption, {
                reason: pauseReason.trim(),
                ad_platform: type,
            }).then(
                (result) => {
                    if (!(result instanceof Error)) {
                        this.props.showBulkNotification('Campaign paused.');
                    }
                    this.hideModal();
                },
                () => {
                    this.hideModal();
                },
            );
        }
    }

    inputChangeHandler(e) {
        const { shops, type } = this.props;
        let pauseOption;
        let otherReasonTextarea = '';
        let otherReasonTextareaDisabled = false;
        let formValid = true;
        let isUninstalling = false;

        if (e.target.name === 'pauseOption' && e.target.value !== OTHER_PAUSE_REASON) {
            pauseOption = e.target.value;
            otherReasonTextareaDisabled = true;
        } else if (e.target.value === OTHER_PAUSE_REASON) {
            pauseOption = e.target.value;
            formValid = false;
        } else {
            pauseOption = OTHER_PAUSE_REASON;
            otherReasonTextarea = e.target.value;
            formValid = !!(e.target.value.trim().length > 2 && e.target.value.length <= 512);
        }

        if (e.target.value === DO_NOT_WANT_USE_RETARGETAPP_PAUSE_REASON && shops.length === 1 && type === 'facebook') {
            isUninstalling = true;
        }

        this.setState({
            pauseOption,
            otherReasonTextarea,
            otherReasonTextareaDisabled,
            formValid,
            isUninstalling,
        });
        new Promise((resolve) => {
            if (this.state.otherReasonTextareaDisabled) {
                resolve();
            }
        }).then(() => {
            this.textareaRef.current.focus();
        });
    }

    hideModal() {
        const { type, handleDefaultModal, togglePauseCampaignModal } = this.props;
        if (this.state) this.setState(this.baseState);
        type === 'google' ? handleDefaultModal(false) : togglePauseCampaignModal();
    }

    render() {
        const { isUninstalling, otherReasonTextarea, otherReasonTextareaDisabled } = this.state;
        const { isOpenModal, isTrialPeriod, adAccountFacebookId, type } = this.props;
        const optionsFormArray = [];
        const [warningMessage, optionsHeadline] = this.getWarningBlock();
        const uninstallAlert = (
            <div className="pause-campaign-modal-info-message__wrapper">
                <div className="pause-campaign-modal-info-message__aside" />
                <div className="pause-campaign-modal-info-message__main">
                    <p className="pause-campaign-modal-info-message">
                        Before you uninstall Adwisely, please, go to {''}
                        <a
                            href={
                                'https://business.facebook.com/ads/manager/account_settings/account_billing/' +
                                `?act=${adAccountFacebookId}&pid=p2&page=account_settings&` +
                                'tab=account_billing_settings'
                            }
                            target="_blank"
                            className="pause-campaign-modal-info-message__link"
                            rel="noopener noreferrer"
                        >
                            Payment Settings
                        </a>{' '}
                        {''}
                        of Adwisely-powered ad account, pay the Amount Due and remove the payment method.
                    </p>
                    <p>You will lose access to this ad account once you uninstall Adwisely.</p>
                </div>
            </div>
        );

        let modalTitle = (
            <>
                Oh, my! Looks like you want to pause a campaign{' '}
                <span aria-labelledby="screaming-emoji" role="img">
                    😱
                </span>
            </>
        );
        this.optionsArray.forEach((value, key) => {
            optionsFormArray.push(
                <label key={key} className="option-form__radio-item">
                    <input type="radio" name="pauseOption" value={key} onChange={this.inputChangeHandler} />
                    {value}
                </label>,
            );
        });
        if (isTrialPeriod || type === 'google') modalTitle = 'Are you sure you want to pause your campaign?';

        if (isUninstalling) {
            optionsFormArray.splice(optionsFormArray.length - 1, 0, uninstallAlert);
        }

        return (
            <Modal className="modal-form__dialog" isOpen={isOpenModal}>
                <button className="modal-special__close-button" onClick={this.hideModal} />
                <ModalHeader className="modal-form__header">
                    <p className="modal-special__title">{modalTitle}</p>
                </ModalHeader>

                <ModalBody className="modal-form__body">
                    {warningMessage}
                    {optionsHeadline}
                    <form className="modal-form__body-form">
                        <div className="form-group__options">{optionsFormArray}</div>
                        <div className="pause-message-input-wrapper">
                            <textarea
                                className={classNames('pause-message-input', {
                                    'pause-message-input_error': otherReasonTextarea.length > 512,
                                })}
                                ref={this.textareaRef}
                                disabled={otherReasonTextareaDisabled}
                                placeholder={this.trialOtherReasonTextareaPlaceholder}
                                onChange={this.inputChangeHandler}
                                name="otherReasonTextarea"
                                value={otherReasonTextarea}
                                type="textarea"
                            />
                            <span
                                className={classNames('pause-message-counter', {
                                    'pause-message-counter_error': otherReasonTextarea.length > 512,
                                })}
                            >
                                {otherReasonTextarea.length}/512
                            </span>
                        </div>
                    </form>
                </ModalBody>

                <ModalFooter className="modal-form__footer">
                    <Button className="modal-form-footer-btn modal-form-footer-cancel-btn" onClick={this.hideModal}>
                        Cancel
                    </Button>
                    <Button
                        className="modal-form-footer-btn modal-form-footer-btn__pause"
                        pending={this.state.pending}
                        onClick={this.submitForm}
                        disabled={!this.state.formValid}
                        color="danger"
                    >
                        <span>{isTrialPeriod || type === 'google' ? 'Pause anyway' : 'Pause'}</span>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

ModalPauseCampaign.defaultProps = {
    showBulkNotification: () => {},
    type: 'facebook',
};

ModalPauseCampaign.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    handleDefaultModal: PropTypes.func.isRequired,
    campaignId: PropTypes.number.isRequired,
    showBulkNotification: PropTypes.func,
    isTrialPeriod: PropTypes.bool.isRequired,
    type: PropTypes.string,
};

const mapStateToProps = (state) => {
    const isTrialPeriod = isTrialPeriodSelector(
        state.shopBillingPeriod.freeType,
        state.shopBillingPeriod.trialDaysLeft,
    );
    return {
        isTrialPeriod,
        shops: state.shops.entities,
        adAccountFacebookId: state.shopsCampaignsSettings.entity.fb_ad_account_id,
        currentShopId: state.shops.currentShopId,
    };
};

const mapDispatchToProps = {
    pauseCampaignReason: (params, campaignReason, meta) => (dispatch) =>
        dispatch(requestPauseCampaign(params)).then(
            (data) => {
                dispatch(requestCampaignPauseReason(params));
                return data;
            },
            (error) => error,
        ),
    pauseGoogleCampaignReason: (params, campaignReason, meta) => (dispatch) =>
        dispatch(requestPauseGoogleCampaign(params)).then(
            (data) => {
                dispatch(requestGoogleCampaignPauseReason(params));
                return data;
            },
            (error) => error,
        ),
    showBulkNotification: displayBulkNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalPauseCampaign);
