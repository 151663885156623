import React, {useState} from 'react';

import intercom from 'libs/intercom';

import { ToDoListView } from './ToDoListView';
import {ToDoCategoryTitle, ToDoListStatus, ToDoListType} from './ToDoListConstants';
import { ShareAccessToAdAccountModal } from './components/ToDoListModal/ShareAccessToAdAccountModal';
import { ShareAccessToPixelModal } from './components/ToDoListModal/ShareAccessToPixelModal';
import { ShareAccessToStoreDomain } from './components/ToDoListModal/ShareAccessToStoreDomain';
import { PrioritizePixelEventsModal } from './components/ToDoListModal/PrioritizePixelEventsModal';
import { useGetToDoList } from './hooks/useGetToDoList';
import {ONBOARDING_STATES, ONBOARDING_STEPS} from '../../constants/googleOnboarding';
import {changeOnboardingState} from '../../modules/home/utils';
import {ONBOARDING_TYPES} from '../../constants/onboarding';

export const ToDoList = ({shopId, push, onboardings, googleLoginUrl, isGoogleAuthorized}) => {
    const [currentToDoModal, setCurrentToDoModal] = useState({});
    const [todoListData, updateTodoList] = useGetToDoList(shopId);
    const googleOnboarding = onboardings.find(item => item.name === 'google')

    const onSupportBtnClick = () => {
        intercom.show();
    }

    const onStartBtnClick = (todoListItemData) => {
        if (todoListItemData.status === ToDoListStatus.not_started) {
            if (todoListItemData.slug === "launch_google_campaign") {
                if (isGoogleAuthorized) {
                    fetch(`/api/shops/${shopId}/todo-lists/${todoListItemData.id}/start`,
                        {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                        .then(res => res.json())
                        .then((data) => {
                            updateTodoList('facebook', data.data);
                            if (googleOnboarding.state === ONBOARDING_STATES.not_started) {
                                changeOnboardingState({
                                    currentShopId: shopId,
                                    type: ONBOARDING_TYPES.google,
                                    status: ONBOARDING_STATES.not_started,
                                });
                                push(`/${shopId}/google`);
                            } else {
                                push(`/${shopId}/google`);
                            }
                        })

                } else {
                    window.location.assign(googleLoginUrl);
                }
            } else {
                setCurrentToDoModal(todoListItemData);
            }
        }

    }

    const onCloseModalBtnClick = () => {
        setCurrentToDoModal({})
    }

    const onCompleteBtnClick = () => {
        fetch(`/api/shops/${shopId}/todo-lists/${currentToDoModal.id}/start`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then((data) => {
                setCurrentToDoModal({});
                updateTodoList(currentToDoModal.listName, data.data);
            })
    }

    return (
        <>
            {todoListData.length > 0 &&
                <div className="todolist-wrapper">
                    {
                        todoListData.map(item => {
                            const {id, name, progress_pct, items} = item;
                            return (
                                <ToDoListView
                                    listName={name}
                                    listData={items}
                                    progress={progress_pct}
                                    title={ToDoCategoryTitle[name]}
                                    onStartBtnClick={onStartBtnClick}
                                    key={id}
                                />
                            )
                        })}

                    <ShareAccessToAdAccountModal
                        isOpen={currentToDoModal.slug === ToDoListType.ad_account}
                        onSupportBtnClick={onSupportBtnClick}
                        closeModal={onCloseModalBtnClick}
                        onCompleteBtnClick={onCompleteBtnClick}
                    />
                    <ShareAccessToPixelModal
                        isOpen={currentToDoModal.slug === ToDoListType.meta_pixel}
                        onSupportBtnClick={onSupportBtnClick}
                        closeModal={onCloseModalBtnClick}
                        onCompleteBtnClick={onCompleteBtnClick}
                    />
                    <ShareAccessToStoreDomain
                        isOpen={currentToDoModal.slug === ToDoListType.store_domain}
                        onSupportBtnClick={onSupportBtnClick}
                        closeModal={onCloseModalBtnClick}
                        onCompleteBtnClick={onCompleteBtnClick}
                    />
                    <PrioritizePixelEventsModal
                        isOpen={currentToDoModal.slug === ToDoListType.prioritize_pixel_events}
                        onSupportBtnClick={onSupportBtnClick}
                        closeModal={onCloseModalBtnClick}
                        onCompleteBtnClick={onCompleteBtnClick}
                    />
                </div>
            }
        </>
    )
}