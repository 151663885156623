export const AD_MESSAGE_VALID_TAGS = [
    '{{product.name}}',
    '{{product.price}}',
];

export const AD_MESSAGE_PROHIBITED_WORDS = [
    'fb',
    'facebook',
    'fbook',
];

export class AdMessageValidator {
    constructor(msg) {
        this.msg = msg;
    }

    isValid() {
        return this.getInvalidTag() === null &&
               this.getProhibitedWord() === null &&
               this.msg.length > 0 &&
               this.msg.length <= 512;
    }

    getInvalidTag() {
        const tagRegExp = /{{2}(.*?)}{2}/g;

        let invalidTag = null;
        const tags = this.msg.match(tagRegExp);
        if (tags) {
            tags.every((tag) => {
                if (AD_MESSAGE_VALID_TAGS.includes(tag)) {
                    return true;
                }
                invalidTag = tag;
                return false;
            });
        }
        return invalidTag;
    }

    getProhibitedWord() {
        let invalidWord = null;
        this.msg.split(/[\s]+/g).some((word) => {
            if (AD_MESSAGE_PROHIBITED_WORDS.includes(word.toLowerCase())) {
                invalidWord = word;
                return true;
            }
            return false;
        });
        return invalidWord;
    }
}

export default AdMessageValidator;
