import { push, replace } from 'react-router-redux';

import { ROUTES } from 'constants/routes';

import {
    callAPIShopLogin,
    callAPIGetShops,
    callAPIShopInstall,
    callAPIValidateShop,
    callAPIGetRecurringCharge,
    callAPIUpdateRecurringCharge,
    callAPICreateRecurringCharge,
    callAPIgetShopBillingPeriod,
    callAPIGetShopBillingInfo,
    callAPIGetShopBillingPlanStatus,
    callAPIgetShopInfo,
    callAPIGetStoreRecommendation,
    callAPIInstallShopifyWithDomain,
    callAPIInstallWoocommerceWithDomain,
    callAPISendEventMetrics,
    callAPIUpdateShop,
    callAPIGetShopSubscription,
} from './api';

export const goToDashboard = (id) => push(`/${id}${ROUTES.dashboard}`);

export const goToNextStep = (id) => push(`/${id}`);

export const redirectToDashboard = (id) => replace(`/${id}${ROUTES.dashboard}`);

export const SHOPS_REQUEST = 'SHOPS_REQUEST';
export const SHOPS_SUCCESS = 'SHOPS_SUCCESS';
export const SHOPS_FAILURE = 'SHOPS_FAILURE';
export const ON_INFRAME_FILTER_SHOPS = 'ON_INFRAME_FILTER_SHOPS';

export const requestShops = () => (dispatch, getState) =>
    dispatch(
        callAPIGetShops({
            types: [SHOPS_REQUEST, SHOPS_SUCCESS, SHOPS_FAILURE],
        }),
    ).then(() => {
        const state = getState();
        if (state.onboarding && state.onboarding.inframe && state.onboarding.inframe.inframe) {
            dispatch({ type: ON_INFRAME_FILTER_SHOPS });
        }
    });

export const SHOP_UPDATE_REQUEST = 'SHOP_UPDATE_REQUEST';
export const SHOP_UPDATE_SUCCESS = 'SHOP_UPDATE_SUCCESS';
export const SHOP_UPDATE_FAILURE = 'SHOP_UPDATE_FAILURE';

export const requestUpdateShop = (params) => (dispatch) =>
    dispatch(
        callAPIUpdateShop({
            params,
            types: [SHOP_UPDATE_REQUEST, SHOP_UPDATE_SUCCESS, SHOP_UPDATE_FAILURE],
        }),
    );

export const SHOP_LOGIN_REQUEST = 'SHOP_LOGIN_REQUEST';
export const SHOP_LOGIN_SUCCESS = 'SHOP_LOGIN_SUCCESS';
export const SHOP_LOGIN_FAILURE = 'SHOP_LOGIN_FAILURE';

export const login = (params) => (dispatch) =>
    dispatch(
        callAPIShopLogin({
            types: [SHOP_LOGIN_REQUEST, SHOP_LOGIN_SUCCESS, SHOP_LOGIN_FAILURE],
            params,
        }),
    ).then((data) => {
        if (!(data instanceof Error)) {
            dispatch({ type: SHOPS_SUCCESS, payload: [data] });
        }
        return data;
    });

export const SHOP_INSTALL_REQUEST = 'SHOP_INSTALL_REQUEST';
export const SHOP_INSTALL_SUCCESS = 'SHOP_INSTALL_SUCCESS';
export const SHOP_INSTALL_FAILURE = 'SHOP_INSTALL_FAILURE';

export const install = (params) => (dispatch) =>
    dispatch(
        callAPIShopInstall({
            params,
            types: [SHOP_INSTALL_REQUEST, SHOP_INSTALL_SUCCESS, SHOP_INSTALL_FAILURE],
        }),
    );

export const SHOPIFY_INSTALL_REQUEST = 'SHOPIFY_INSTALL_REQUEST';
export const SHOPIFY_INSTALL_SUCCESS = 'SHOPIFY_INSTALL_SUCCESS';
export const SHOPIFY_INSTALL_FAILURE = 'SHOPIFY_INSTALL_FAILURE';

export const installShopfyShopWithDomain = (params) => (dispatch) =>
    dispatch(
        callAPIInstallShopifyWithDomain({
            params,
            types: [SHOPIFY_INSTALL_REQUEST, SHOPIFY_INSTALL_SUCCESS, SHOPIFY_INSTALL_FAILURE],
        }),
    );

export const WOOCOMMERCE_INSTALL_REQUEST = 'WOOCOMMERCE_INSTALL_REQUEST';
export const WOOCOMMERCE_INSTALL_SUCCESS = 'WOOCOMMERCE_INSTALL_SUCCESS';
export const WOOCOMMERCE_INSTALL_FAILURE = 'WOOCOMMERCE_INSTALL_FAILURE';

export const installWoocommerceShopWithDomain = (params) => (dispatch) =>
    dispatch(
        callAPIInstallWoocommerceWithDomain({
            params,
            types: [WOOCOMMERCE_INSTALL_REQUEST, WOOCOMMERCE_INSTALL_SUCCESS, WOOCOMMERCE_INSTALL_FAILURE],
        }),
    );

export const SHOPS_ENTITIES_UPDATE = 'SHOPS_ENTITIES_UPDATE';

export const updateShopsEntities = (entities) => ({
    type: SHOPS_ENTITIES_UPDATE,
    payload: entities,
});

export const SHOPS_CURRENT_SHOP_ID_UPDATE = 'SHOPS_CURRENT_SHOP_ID_UPDATE';

export const updateCurrentShopId = (id) => ({
    type: SHOPS_CURRENT_SHOP_ID_UPDATE,
    payload: id,
});

export const SHOP_VALIDATE_REQUEST = 'SHOP_VALIDATE_REQUEST';
export const SHOP_VALIDATE_SUCCESS = 'SHOP_VALIDATE_SUCCESS';
export const SHOP_VALIDATE_FAILURE = 'SHOP_VALIDATE_FAILURE';

export const validateShop = (params) =>
    callAPIValidateShop({
        params,
        types: [SHOP_VALIDATE_REQUEST, SHOP_VALIDATE_SUCCESS, SHOP_VALIDATE_FAILURE],
    });

export const CLEAR_SHOP_ERROR = 'CLEAR_SHOP_ERROR';

export const clearShopError = () => ({
    type: CLEAR_SHOP_ERROR,
});

export const SHOP_RECURRING_CHARGE_REQUEST = 'SHOP_RECURRING_CHARGE_REQUEST';
export const SHOP_RECURRING_CHARGE_SUCCESS = 'SHOP_RECURRING_CHARGE_SUCCESS';
export const SHOP_RECURRING_CHARGE_FAILURE = 'SHOP_RECURRING_CHARGE_FAILURE';

export const getRecurringChange = (shopId) =>
    callAPIGetRecurringCharge({
        params: {
            shopId,
        },
        types: [SHOP_RECURRING_CHARGE_REQUEST, SHOP_RECURRING_CHARGE_SUCCESS, SHOP_RECURRING_CHARGE_FAILURE],
    });

export const SHOP_UPDATE_RECURRING_CHARGE_REQUEST = 'SHOP_UPDATE_RECURRING_CHARGE_REQUEST';
export const SHOP_UPDATE_RECURRING_CHARGE_SUCCESS = 'SHOP_UPDATE_RECURRING_CHARGE_SUCCESS';
export const SHOP_UPDATE_RECURRING_CHARGE_FAILURE = 'SHOP_UPDATE_RECURRING_CHARGE_FAILURE';

export const updateRecurringChange = (shopId, cappedAmount) =>
    callAPIUpdateRecurringCharge({
        params: {
            shopId,
            capped_amount: cappedAmount,
        },
        types: [
            SHOP_UPDATE_RECURRING_CHARGE_REQUEST,
            SHOP_UPDATE_RECURRING_CHARGE_SUCCESS,
            SHOP_UPDATE_RECURRING_CHARGE_FAILURE,
        ],
    });

export const SHOP_CREATE_RECURRING_CHARGE_REQUEST = 'SHOP_CREATE_RECURRING_CHARGE_REQUEST';
export const SHOP_CREATE_RECURRING_CHARGE_SUCCESS = 'SHOP_CREATE_RECURRING_CHARGE_SUCCESS';
export const SHOP_CREATE_RECURRING_CHARGE_FAILURE = 'SHOP_CREATE_RECURRING_CHARGE_FAILURE';

export const createRecurringChange = (shopId, redirectUrl) =>
    callAPICreateRecurringCharge({
        params: {
            shopId,
            redirect_url: redirectUrl,
        },
        types: [
            SHOP_CREATE_RECURRING_CHARGE_REQUEST,
            SHOP_CREATE_RECURRING_CHARGE_SUCCESS,
            SHOP_CREATE_RECURRING_CHARGE_FAILURE,
        ],
    });

export const getOrCreateRecurringCharge = (shopId) => (dispatch) => {
    const redirectUrl = `${window.location.origin}/${shopId}`;
    return dispatch(getRecurringChange(shopId)).then((res) => {
        if (res instanceof Error && res.errorToken === 'shop-doesnt-have-recurring-charge') {
            return dispatch(createRecurringChange(shopId, redirectUrl));
        }
        const invalidStatuses = ['cancelled', 'expired', 'declined'];
        if (invalidStatuses.includes(res.status)) {
            return dispatch(createRecurringChange(shopId, redirectUrl));
        }
        return null;
    });
};

export const SHOP_BILLING_PERIOD_REQUEST = 'SHOP_BILLING_PERIOD_REQUEST';
export const SHOP_BILLING_PERIOD_SUCCESS = 'SHOP_BILLING_PERIOD_SUCCESS';
export const SHOP_BILLING_PERIOD_FAILURE = 'SHOP_BILLING_PERIOD_FAILURE';
export const SHOP_BILLING_SET_SHOP_ID = 'SHOP_BILLING_SET_SHOP_ID';

export const getShopBillingPeriod = (shopId) => (dispatch) => {
    dispatch({
        type: SHOP_BILLING_SET_SHOP_ID,
        payload: { shopId },
    });
    return dispatch(
        callAPIgetShopBillingPeriod(shopId, {
            types: [SHOP_BILLING_PERIOD_REQUEST, SHOP_BILLING_PERIOD_SUCCESS, SHOP_BILLING_PERIOD_FAILURE],
        }),
    );
};

export const SHOP_BILLING_INFO_REQUEST = 'SHOP_BILLING_INFO_REQUEST';
export const SHOP_BILLING_INFO_SUCCESS = 'SHOP_BILLING_INFO_SUCCESS';
export const SHOP_BILLING_INFO_FAILURE = 'SHOP_BILLING_INFO_FAILURE';

export const requestShopBillingInfo = (shopId, params) =>
    callAPIGetShopBillingInfo(shopId, {
        params: {
            year: params.billingYear,
            ...params.params,
        },
        types: [SHOP_BILLING_INFO_REQUEST, SHOP_BILLING_INFO_SUCCESS, SHOP_BILLING_INFO_FAILURE],
    });

export const SHOP_INFO_REQUEST = 'SHOP_INFO_REQUEST';
export const SHOP_INFO_SUCCESS = 'SHOP_INFO_SUCCESS';
export const SHOP_INFO_FAILURE = 'SHOP_INFO_FAILURE';

export const getShopInfo = (shopId) => (dispatch) =>
    dispatch(
        callAPIgetShopInfo(shopId, {
            types: [SHOP_INFO_REQUEST, SHOP_INFO_SUCCESS, SHOP_INFO_FAILURE],
        }),
    );

export const SHOP_BILLING_PLAN_STATUS_REQUEST = 'SHOP_BILLING_PLAN_STATUS_REQUEST';
export const SHOP_BILLING_PLAN_STATUS_SUCCESS = 'SHOP_BILLING_PLAN_STATUS_SUCCESS';
export const SHOP_BILLING_PLAN_STATUS_FAILURE = 'SHOP_BILLING_PLAN_STATUS_FAILURE';

export const requestShopBillingPlanStatus = (shopId) =>
    callAPIGetShopBillingPlanStatus(shopId, {
        types: [SHOP_BILLING_PLAN_STATUS_REQUEST, SHOP_BILLING_PLAN_STATUS_SUCCESS, SHOP_BILLING_PLAN_STATUS_FAILURE],
    });

export const SHOP_SUBSCRIPTION_REQUEST = 'SHOP_SUBSCRIPTION_REQUEST';
export const SHOP_SUBSCRIPTION_SUCCESS = 'SHOP_SUBSCRIPTION_SUCCESS';
export const SHOP_SUBSCRIPTION_FAILURE = 'SHOP_SUBSCRIPTION_FAILURE';

export const requestShopSubscription = (shopId) =>
    callAPIGetShopSubscription(shopId, {
        types: [SHOP_SUBSCRIPTION_REQUEST, SHOP_SUBSCRIPTION_SUCCESS, SHOP_SUBSCRIPTION_FAILURE],
    });

export const GET_SHOP_RECOMMENDATION_REQUEST = 'GET_SHOP_RECOMMENDATION_REQUEST';
export const GET_SHOP_RECOMMENDATION_SUCCESS = 'GET_SHOP_RECOMMENDATION_SUCCESS';
export const GET_SHOP_RECOMMENDATION_FAILURE = 'GET_SHOP_RECOMMENDATION_FAILURE';
export const requestShopRecommendation = (shopId) =>
    callAPIGetStoreRecommendation(shopId, {
        types: [GET_SHOP_RECOMMENDATION_REQUEST, GET_SHOP_RECOMMENDATION_SUCCESS, GET_SHOP_RECOMMENDATION_FAILURE],
    });

export const SEND_EVENT_METRICS_REQUEST = 'SEND_EVENT_METRICS_REQUEST';
export const SEND_EVENT_METRICS_SUCCESS = 'SEND_EVENT_METRICS_SUCCESS';
export const SEND_EVENT_METRICS_FAILURE = 'SEND_EVENT_METRICS_FAILURE';
export const requestSendEventMetrics = (params) =>
    callAPISendEventMetrics({
        params,
        types: [SEND_EVENT_METRICS_REQUEST, SEND_EVENT_METRICS_SUCCESS, SEND_EVENT_METRICS_FAILURE],
    });
