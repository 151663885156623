import { replace } from 'react-router-redux';
import { ROUTES } from 'constants/routes';
import withData from 'decorator/withStateData';
import SpecialOfferCampaignCreate from './SpecialOfferCampaignCreate';

export default withData(
    SpecialOfferCampaignCreate,
    ['campaignDetails', 'shopNotifications'],
    (data) => {
        const { campaignDetailsData } = data.campaignDetails;
        const isSpecialOfferCreated = campaignDetailsData.find((el) => el.type === 'special_offer');
        const isInvited = !!data.shopNotifications.entity.find((el) => el.type === 'special_offer_invite');
        return isInvited && !isSpecialOfferCreated;
    },
    (nextState) => (dispatch) => {
        const {
            params: { shopId },
        } = nextState;
        dispatch(replace(`/${shopId}${ROUTES.dashboard}`));
    },
);
