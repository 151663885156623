import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { install } from 'actions/shop';

import { BIG_COMMERCE } from 'constants/platform';

import Button from 'components/Button';
import { LoaderLongWait as Loader } from 'components/Loader';

import trackAppInstalled from 'libs/trackAppInstalled';

export class BigCommerceInstallCallback extends PureComponent {
    reInstallBigCommerce = () => {
        const {
            location: { query },
            installBigcommerce,
            replace,
        } = this.props;

        installBigcommerce({
            params: query,
            type: BIG_COMMERCE,
            intercom_visitor_id: '',
        }).then((shop) => {
            if (shop instanceof Error) {
                return;
            }
            if (shop?.installation_type === 'first_install' || shop?.installation_type === 'reinstall') {
                trackAppInstalled(shop.shop.id)
            }
            if (shop.shop) replace(`/shop/${shop.shop.id}/`);
        });
    };

    render() {
        const { shopRequestFailure } = this.props;
        let callBackContent = <Loader />;

        if (shopRequestFailure) {
            callBackContent = (
                <>
                    <h4 className="shopify-reinstall__title">App Installation</h4>
                    <p className="shopify-reinstall__text">Something went wrong. Try installing Adwisely again.</p>
                    <Button onClick={this.reInstallBigCommerce} className="shopify-reinstall__button">
                        Install Adwisely
                    </Button>
                </>
            );
        }
        return callBackContent;
    }
}

const mapStateToProps = ({ shops }) => ({
    shopRequestFailure: shops.shopRequestFailure,
});

const mapPropsToDispatch = {
    replace,
    installBigcommerce: install,
};

export default connect(mapStateToProps, mapPropsToDispatch)(BigCommerceInstallCallback);
