import React, { useState } from "react";
import classNames from 'classnames';
import "./Tooltip.css";

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    clearInterval(timeout);
    setActive(true);
  };

  const hideTip = () => {
    timeout = setTimeout(() => {
        setActive(false);
    }, props.delay || 400);
  };

  return (
    <div
      className="tooltip__wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {(active || props.tipsForceOpen) && (
        <div
            className={classNames(
                `tooltip-inner`,
                { 'tooltip-inner_dark': props.isDarkView },
                props.placement,
                props.additionalClassName,
            )}
        >
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
