import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { metricType } from 'constants/metric';
import { requestSendEventMetrics, requestShopSubscription } from 'actions/shop';

import './SubscriptionPlan.css';
import { SubscriptionDurationButtons } from './components/SubscriptionDurationButtons/SubscriptionDurationButtons';
import { SubscriptionMonthlyView } from './components/SubscriptionMainSection/SubscriptionMonthlyView';
import { SubscriptionQuarterlyView } from './components/SubscriptionMainSection/SubscriptionQuarterlyView';

export const BTN_NAME = {
    STARTER: 'starter',
    REGULAR: 'regular',
    BASIC: 'basic',
    STANDARD: 'standard',
    ADVANCED: 'advanced',
    YEAR_BASIC: 'annually_basic',
    YEAR_STANDARD: 'annually_standard',
    YEAR_ADVANCED: 'annually_advanced',
    YEAR_BASIC_BFCM_2022: 'annually_basic_bfcm_2022',
    YEAR_STANDARD_BFCM_2022: 'annually_standard_bfcm_2022',
    YEAR_ADVANCED_BFCM_2022: 'annually_advanced_bfcm_2022',
    YEAR_BASIC_EASTER_2023: 'annually_basic_easter_2023',
    YEAR_STANDARD_EASTER_2023: 'annually_standard_easter_2023',
    YEAR_ADVANCED_EASTER_2023: 'annually_advanced_easter_2023',
    QUARTERLY_STARTER: 'quarterly_starter',
    QUARTERLY_REGULAR: 'quarterly_regular',
}

export const SubDuration = {
    yearly: 'yearly',
    monthly: 'monthly'
}

const SubscriptionPlan = ({
                              currentShopId,
                              subscriptionPlan,
                              requestSendEventMetrics,
                              requestShopSubscription,
                              onSubscriptionPlanSelectSuccess
                          }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeDuration, setActiveDuration] = useState(SubDuration.monthly);

    useEffect(() => {
        const metricData = {
            type: metricType.pageViewed,
            shop_id: currentShopId,
            props: {
                page: "choose_subscription_plan",
                duration: activeDuration === SubDuration.monthly
                    ? "monthly"
                    : "annually",
                url_path: window.location.pathname
            }
        }
        requestSendEventMetrics(metricData);
    }, [activeDuration])

    useEffect(() => {
        if (subscriptionPlan) {
            onSubscriptionPlanSelectSuccess();
        }
    }, [subscriptionPlan])

    const saveSubscriptionPlan = (plan) => fetch(`/api/shop/${currentShopId}/subscription`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({"subscription_plan": plan}),
    }).then((res) => res.json());

    const choosePlanHandler = (plan, btnPlan) => {
        setIsLoading(true);
        const metricData = {
            type: metricType.btnClicked,
            shop_id: currentShopId,
            props: {
                btn_name: "choose_subscription_plan",
                plan: btnPlan
            }
        }

        requestSendEventMetrics(metricData);
        if (!subscriptionPlan) {
            localStorage.setItem('auto_redirect_to_approve', 'true');
            saveSubscriptionPlan(plan)
                .then((res) => {
                    if (!(res instanceof Error)) {
                        requestShopSubscription(currentShopId);
                        onSubscriptionPlanSelectSuccess();
                    } else {
                        setIsLoading(false);
                    }
                })
        } else {
            onSubscriptionPlanSelectSuccess();
        }
    };

    return (
        <>
            <div className="choose-plan__wrapper">
                <div className="choose-plan__title-wrapper">
                    <h1 className="choose-plan__title">Choose your Plan</h1>
                </div>
                {activeDuration === SubDuration.monthly
                    ? <SubscriptionMonthlyView
                        choosePlanHandler={choosePlanHandler}
                    />
                    : <SubscriptionQuarterlyView
                        choosePlanHandler={choosePlanHandler}
                    />}
            </div>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </>
    );
};

const mapStateToProps = ({ shops, shopSubscription, featureFlags }) => (
    {
        currentShopId: shops?.currentShopId,
        subscriptionPlan: shopSubscription.name,
    }
);

const mapPropsToDispatch = {
    requestSendEventMetrics,
    requestShopSubscription,
};

export default connect(mapStateToProps, mapPropsToDispatch)(SubscriptionPlan);
