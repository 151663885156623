import { changeOnboardingState } from 'modules/home/utils';
import { ONBOARDING_TYPES, СBM_ONBOARDING_STATES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from "constants/ubmOnboarding";
import { getLocalStorageObjectItem, setLocalStorageObjectItem } from 'helpers';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';

export const onboardingSteps = {
    select_bm: 'connect-bm',
    upgrade_role_in_bm: 'upgrade-role',
    create_fb_page: 'create-page',
    assign_system_user_to_page: 'connect-page',
    bm_restricted_exception: 'restricted-bm',
    choose_or_create_ad_account: 'connect-or-create-ad-account',
    choose_ad_account: 'connect-ad-account',
    ad_account_policy_violation_exception: 'ad-account-error',
    assign_pixel_to_ad_account: 'confirm-pixel',
    check_pixel_settings: 'pixel-not-found',
    choose_subscription_plan: 'choose-plan',
    confirm_shopify_payment: 'shopify-billing',
    confirm_stripe_payment: 'stripe-billing',
    confirm_facebook_payment: 'facebook-billing',
    create_visitor_retargeting_campaign_request: 'create-visitor-retargeting-campaign',
    create_special_offer_campaign: 'create-special-offer-campaign',
    create_prospecting_campaign: 'create-prospecting-campaign',
    create_visitor_retargeting_campaign: 'create-visitor-retargeting-campaign',
    create_dynamic_retargeting_campaign: 'create-dynamic-retargeting-campaign',
    create_social_media_retargeting_campaign: 'create-social-media-retargeting-campaign',
    create_ai_boosted_sales_campaign: 'create-ai-boosted-sales-campaign',
    start_free_trial: 'start-free-trial',
    create_bm: 'create-bm',
    still_have_no_bm: 'create-bm',
    choose_instagram_account: 'choose-instagram-account',
    create_instagram_account: 'create-instagram-account',
    still_have_no_instagram_account: 'still-have-no-instagram-account'
};

export const getRedirectPage = (res, currentShopId, replace) => {
    if (res?.state === 'closed') {
        changeOnboardingState({
            currentShopId,
            type: ONBOARDING_TYPES.facebook_childbm,
            status: СBM_ONBOARDING_STATES.not_started,
        }).then(() => replace(`/${currentShopId}/onboarding`));
        return;
    }

    if (res?.state === 'failed') {
        replace(`/${currentShopId}/user-bm/error`);
        return;
    }

    if (res?.state === 'done') {
        setLocalStorageObjectItem('activeTab', '0');
        replace(`/${currentShopId}/dashboard`);
        return;
    }

    if (!res.wait_for_input && res.state !== UBM_ONBOARDING_STATES.confirm_shopify_payment) {
        const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: res.state,
            currentShopId,
        })
            .then((res) => res.json())
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
        return;
    }

    if (Object.keys(onboardingSteps).includes(res?.state)) {
        replace(`/${currentShopId}/user-bm/${onboardingSteps[res.state]}`);
    } else {
        replace(`/${currentShopId}/home`);
    }
};

export const fetchProspectingOnboardingState = (currentShopId) =>
    fetch(`/api/onboarding/${currentShopId}/facebook_prospecting`)
        .then((res) => res.json());

export const fetchRetargetingOnboardingState = (currentShopId) =>
    fetch(`/api/onboarding/${currentShopId}/facebook_visitor_retargeting`)
        .then((res) => res.json());

export const fetchSpecialOfferOnboardingState = (currentShopId) =>
    fetch(`/api/onboarding/${currentShopId}/facebook_special_offer`)
        .then((res) => res.json());

export const fetchOnboardings = (currentShopId) =>
    fetch(`/api/onboarding/${currentShopId}/`)
        .then((res) => res.json());
