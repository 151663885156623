import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import { goToSharePixel } from 'actions/onboarding';
import { requestSendEventMetrics, requestUpdateShop } from 'actions/shop';
import { getCurrentShopSelector } from 'helpers/selectors';
import { trackBookCall } from 'helpers/metrics';

import { metricType } from 'constants/metric';

import './VerifyDomain.css';

const BookACallSection = ({ shopId, requestSendEventMetrics }) => (
    <div className="verify-domain-help">
        <h3 className="verify-domain-help__title">Need help?</h3>
        <p>
            <a
                href="https://help.adwisely.com/en/articles/4957812-how-do-i-prepare-for-ios-14-update#h_61d59f0b78"
                target="_blank"
                rel="noopener noreferrer"
                className="verify-domain-help__link"
            >
                Read the instruction
            </a> 
            {' '} or {' '}
            <a
                href="https://calendly.com/adwisely-support-team/support-team"
                target="_blank"
                rel="noopener noreferrer"
                className="verify-domain-help__link"
                onClick={() => trackBookCall(shopId, requestSendEventMetrics, 'domain_verification')}
            >
                book a call
            </a>
        </p>
    </div>
);

class VerifyDomain extends Component {
    state = {
        isChecked: false,
    };

    componentDidMount() {
        const {
            params: { shopId },
            redirectToSharePixelStep,
            domainIsVerified,
        } = this.props;

        domainIsVerified && redirectToSharePixelStep(shopId);
    }

    toggleCheckbox = () => {
        const {
            params: { shopId },
            requestSendEventMetrics,
        } = this.props;

        this.setState({ isChecked: !this.state.isChecked });

        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_domain_confirm',
            },
        };
        requestSendEventMetrics(metricProps);
    };

    continueButtonHandler = () => {
        const {
            params: { shopId },
            requestUpdateShop,
            requestSendEventMetrics,
        } = this.props;

        const params = {
            shop_id: shopId,
            type: 'shopify',
            params_to_update: {
                domain_verified_checkbox_checked: true,
            },
        };

        requestUpdateShop(params);
        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_domain_continue',
            },
        };
        requestSendEventMetrics(metricProps);
    };

    render() {
        const { isChecked } = this.state;
        const { continueButtonLoading, params: { shopId }, requestSendEventMetrics } = this.props;

        return (
            <div className="verify-domain__wrapper">
                <h1 className="verify-domain__title">Make sure your domain is verified on Facebook</h1>
                <section className="verify-domain__section">
                    <p className="verify-domain__text">
                        Adwisely can only run Facebook ads for verified online store domains. Please, complete the
                        following steps in order to continue:
                    </p>
                    <div className="verify-domain__instruction-wrapper">
                        <div>
                            <ol className="verify-domain__list">
                                <li className="verify-domain__list-item">
                                    Go to{' '}
                                    <a
                                        className="verify-domain__text-link"
                                        href="https://business.facebook.com/settings/owned-domains/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Domains
                                    </a>{' '}
                                    in Facebook Business Manager and see if there’s a green <strong>“Verified”</strong> status
                                    next to the URL of the store you are getting Adwisely for.
                                </li>
                                <li className="verify-domain__list-item">
                                    If the domain is not verified, click <strong>“Add”</strong> and follow the on-screen
                                    instructions.{' '}
                                    <a
                                        className="verify-domain__text-link--red"
                                        href="https://www.youtube.com/watch?v=zlrAhS_90I8"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        See instruction <span className="verify-domain__yt-icon" />
                                    </a>
                                </li>
                            </ol>

                            <div className="verify-domain__validation">
                                <label className="verify-domain__checkbox-label" htmlFor="domainVerification">
                                    <Checkbox onChange={this.toggleCheckbox} checkboxId="domainVerification" />I made sure my
                                    store domain is verified
                                </label>
                            </div>

                            <Button
                                color="primary"
                                onClick={this.continueButtonHandler}
                                className="verify-domain__button"
                                disabled={!isChecked || continueButtonLoading}
                                pending={continueButtonLoading}
                            >
                                Continue
                            </Button>
                        </div>
                        <BookACallSection shopId={shopId} requestSendEventMetrics={requestSendEventMetrics} />
                    </div>
                </section>
            </div>
        );
    }
}

VerifyDomain.propTypes = {
    continueButtonLoading: PropTypes.bool.isRequired,
    domainIsVerified: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ shops }) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    return {
        domainIsVerified: currentShop.params.domain_verified_checkbox_checked,
        continueButtonLoading: shops.shopRequesting,
    };
};

const mapPropsToDispatch = {
    requestUpdateShop,
    requestSendEventMetrics,
    redirectToSharePixelStep: goToSharePixel,
};

export default connect(mapStateToProps, mapPropsToDispatch)(VerifyDomain);
