import React from 'react'

import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import AlertWidget from 'components/AlertWidget';

import ChooseOrCreateAdAccount from './ChooseOrCreateAdAccount';

const LimitNotification = () => (
    <div className="gl-payment-method__notification">
        <AlertWidget color="danger">
            <p className="alert-description__item">
                You cannot create a new Ad Account as you have reached the account creation limit.
                To continue, please, choose an existing Ad Account from a drop-down menu.
            </p>
        </AlertWidget>
    </div>
)

export const ConnectAdAccount = () => (
    <ChooseOrCreateAdAccount status={UBM_ONBOARDING_STATES.choose_ad_account} notification={<LimitNotification />} />
)