import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TipsWidget from 'components/TipsWidget/TipsWidget';
import { Card, CardContent, CardTitle } from 'components/Card';
import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';
import Button from 'components/Button';

class FacebookPageSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasChanges: false,
            selectedPageId: props.selectedPageId,
        };

        this.onCancel = this.onCancel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedPageId !== this.props.selectedPageId) {
            this.setState({
                hasChanges: false,
                selectedPageId: nextProps.selectedPageId,
            });
        }

        if (this.props.isSubmitPending && !nextProps.isSubmitPending) {
            this.setState({
                hasChanges: false,
                selectedPageId: nextProps.selectedPageId,
            });
        }
    }

    onChange(pageId) {
        this.setState({
            hasChanges: pageId !== this.props.selectedPageId,
            selectedPageId: pageId,
        });
    }

    onCancel() {
        const { selectedPageId } = this.props;
        this.setState({
            selectedPageId,
            hasChanges: false,
        });
    }

    render() {
        const { pages, isPagePending, isSubmitPending, isCancelPending, onCreateNewPage, onSubmit } = this.props;

        const { selectedPageId, hasChanges } = this.state;

        let ctaBlock = null;
        if (hasChanges) {
            ctaBlock = (
                <CardContent className="settings-page__block settings-page__block-footer">
                    <Button type="button" disabled={isCancelPending} onClick={this.onCancel}>
                        Cancel
                    </Button>
                    <Button
                        pending={isSubmitPending}
                        color="primary"
                        disabled={isSubmitPending}
                        onClick={() => {
                            if (isSubmitPending) return;
                            onSubmit(selectedPageId);
                        }}
                    >
                        Save
                    </Button>
                </CardContent>
            );
        }

        let body = null;

        const chosenPage = pages.find((page) => +page.id === +selectedPageId);

        if (pages.length) {
            body = (
                <section className="settings-page-form">
                    <a
                        href="https://www.facebook.com/pages/create?ref_type=adwisely"
                        className="link float-right pdg-v-5px"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={onCreateNewPage}
                    >
                        Create New Page
                    </a>
                    <CardTitle subTitle>
                        <span>Available Facebook pages</span>
                        <TipsWidget placement="top" isDarkView>
                            Below is the list of all published Facebook pages you are the admin of.
                        </TipsWidget>
                    </CardTitle>
                    <DropdownProvider>
                        {({ toggle, isOpen }) => {
                            const dropdownItems = pages.map((page, key) => (
                                <DropdownItemMain
                                    key={key}
                                    value={page.id}
                                    onClick={() => {
                                        this.onChange(page.id);
                                        toggle();
                                    }}
                                    active={+page.id === +selectedPageId}
                                    title={`${page.name}`}
                                    subtitle={`Likes: ${page.fan_count}`}
                                />
                            ));

                            const flagToggler = (
                                <FlagToggler
                                    disabled={isSubmitPending}
                                    title={chosenPage ? chosenPage.name : null}
                                    subtitle={chosenPage ? `Likes: ${chosenPage.fan_count}` : null}
                                >
                                    Select Facebook Page
                                </FlagToggler>
                            );

                            return (
                                <Dropdown toggle={toggle} isOpen={isOpen} items={dropdownItems} flagToggler={flagToggler} />
                            )
                        }}
                    </DropdownProvider>
                </section>
            );
        } else if (isPagePending) {
            body = 'Loading...';
        } else {
            body = (
                <Button
                    color="primary"
                    onClick={() => {
                        window.open('https://www.facebook.com/pages/create?ref_type=adwisely', '_blank');
                        onCreateNewPage();
                    }}
                >
                    Create New Page
                </Button>
            );
        }
        return (
            <Card disablePadding className="settings-page__container">
                <div className="settings-page__block">
                    <CardTitle>Facebook Page</CardTitle>
                    <CardContent>
                        The name and profile image of this Facebook page are used in Adwisely-powered ads. Please
                        note that only published page can be used for running ads.
                    </CardContent>
                </div>
                <div className="settings-page__block">{body}</div>
                {ctaBlock}
            </Card>
        );
    }
}

FacebookPageSettings.propTypes = {
    isCancelPending: PropTypes.bool.isRequired,
    isPagePending: PropTypes.bool.isRequired,
    isSubmitPending: PropTypes.bool.isRequired,
    onCreateNewPage: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            fan_count: PropTypes.number.isRequired,
        }),
    ).isRequired,
    selectedPageId: PropTypes.string.isRequired,
};

export default FacebookPageSettings;