import React from 'react';
import { PropTypes } from 'prop-types';

import { GoogleButton } from 'components/GoogleButton';
import { FacebookButton } from 'components/FacebookButton';
import ModalAddEmailToFB from 'components/ModalAddEmailToFB';

import { Alert } from '../Alert';
import './LoginView.css';

const LoginView = ({
                       facebookLoginLink,
                       googleLoginLink,
                       title,
                       inframe,
                       errorMsg,
                       description,
                       targetBlank,
                       errorWide = false }) => (
    <div className="login-view">
        <h1 className="login-view__title">{title}</h1>

        <div className="login-view__white-substrate">
            {errorMsg && <Alert errorWide={errorWide} errorMsg={errorMsg} />}

            <div className="login-view__description">{description}</div>

            <div className="login-view__button">
                <FacebookButton link={facebookLoginLink} target={targetBlank ? '_blank' : '_self'} />
                <div className="login-view__button__fb" />
                <GoogleButton link={googleLoginLink}  />
            </div>

            {inframe && (
                <a href="/" className="facebook-login__reload">
                    Reload page
                </a>
            )}
        </div>
    </div>
);

LoginView.propTypes = {
    facebookLoginLink: PropTypes.string.isRequired,
    googleLoginLink: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inframe: PropTypes.bool.isRequired,
    errorWide: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string.isRequired,
    targetBlank: PropTypes.bool.isRequired,
    description: PropTypes.element.isRequired,
};

export default LoginView;
