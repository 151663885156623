import React from 'react';
import { connect } from 'react-redux';

import './Loader.css';
import logo from './loader.svg';

export const Loader = () => <img className="loader-image loader-image--spinner" src={logo} alt="Adwisely" />;

export const LoaderLongWait = ({ title }) => (
    <div className="loader-long-wait">
        <div className="loader-long-wait__loader-wrapper">
            <Loader />
        </div>
        <p className="loader-long-wait__loader-title">{title || 'Loading'}</p>
        <p className="loader-long-wait__loader-text">This may take a moment...</p>
    </div>
);

export const LoaderFullScreen = (props) => {
    const { isLoaderShowed, children } = props;

    if (!isLoaderShowed) {
        return null;
    }

    return <div className="loader-wrapper">{children || <Loader />}</div>;
};

export default connect(({ loader }) => ({
    isLoaderShowed: loader,
}))(LoaderFullScreen);
