import * as actionHandler from '../actions/actionHandler';

const initialState = {
    entity: {},
    actionHandlerPending: false,
    actionHandlerSuccess: false,
    actionHandlerFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionHandler.TOKEN_HANDLER_REQUEST:
            state = {
                ...initialState,
                actionHandlerPending: true,
            };
            break;
        case actionHandler.TOKEN_HANDLER_SUCCESS:
            state = {
                ...initialState,
                entity: action.payload,
                actionHandlerSuccess: true,
            };
            break;
        case actionHandler.TOKEN_HANDLER_FAILURE:
            state = {
                ...initialState,
                entity: action.payload && action.payload.errorData,
                actionHandlerFailure: true,
                actionHandlerSuccess: false,
            };
            break;
        default:
            break;
    }
    return state;
};
