import React, { useState } from 'react';
import { connect } from 'react-redux';
import {replace} from "react-router-redux";

import { PopupModal } from 'react-calendly';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { setGoogleOnboardingState } from "actions/googleOnboardingState";

import { useFetchUserEmail } from './hooks';

import './WebsiteClaimed.css';
import {googleStatePolling} from "../../onboarding/google/helpers/googleStatePolling";

const WebsiteClaimedView = ({ currentShopId, redirect, setGoogleOnboardingState }) => {
    const email = useFetchUserEmail(currentShopId);
    const [isChecked, setIsChecked] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    const toggleCheckbox = () => setIsChecked(!isChecked);

    const buttonClickHandler = () => {
        setIsPending(!isPending);

        changeOnboardingState({
            currentShopId,
            type: ONBOARDING_TYPES.google,
            status: ONBOARDING_STATES.site_claim_error,
        }).then((res) => {
            if (res.status === 409) {
                googleStatePolling(currentShopId, redirect, setGoogleOnboardingState)
            }
            return res.json()
        })
            .then((res) => {
                if (res?.processing) {
                    googleStatePolling(currentShopId, redirect, setGoogleOnboardingState);
                }
                setIsPending(false);
            });
    };

    return (
        <>
            <div className="website-claimed__wrapper">
                <h1 className="website-claimed__title">Your store is claimed by another Merchant Center account</h1>

                <div className="website-claimed__main-section">
                    <p className="website-claimed__description">
                        To continue setting up Google Ads, you need to remove your store URL from <span className="website-claimed__email">{email}</span> in Merchant
                        Center.
                    </p>
                    <a
                        href="https://support.google.com/merchants/answer/7527436?hl=en#:~:text=Reasons%20you%20may%20be%20unable%20to%20claim%20your%20website%20URL&text=You%20can%20only%20claim%20a,Center%20account%20can%20claim%20it."
                        target="_blank"
                        rel="noopener noreferrer"
                        className="website-claimed__text-link"
                    >
                        Learn more
                    </a>

                    <p className="website-claimed__description">
                        <label className="website-claimed__checkbox" htmlFor="mcVerification">
                            <Checkbox onChange={toggleCheckbox} checkboxId="isShopProtected" />I have unclaimed my
                            domain
                        </label>
                    </p>

                    <div className="website-claimed__buttons">
                        <Button
                            color="primary"
                            pending={isPending}
                            disabled={!isChecked}
                            onClick={buttonClickHandler}
                            className="website-claimed__button"
                        >
                            Continue
                        </Button>
                        <div>
                            <Button
                                className="website-claimed__button"
                                onClick={() => setIsCalendlyOpen(true)}
                            >
                                Contact support
                            </Button>
                            <PopupModal
                                url="https://calendly.com/adwisely-support-team/support-team"
                                onModalClose={() => setIsCalendlyOpen(false)}
                                open={isCalendlyOpen}
                                rootElement={document.getElementById("root")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    redirect: replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export const WebsiteClaimed = connect(mapStateToProps, mapPropsToDispatch)(WebsiteClaimedView);
