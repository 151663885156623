import React, {useEffect} from 'react';
import classNames from 'classnames';

import { SubscriptionPlanType } from 'constants/subscriptionPlanType';
import { getLocalStorageObjectItem } from 'helpers';
import { ONBOARDING_TYPES } from 'constants/onboarding';

import './WIzardStatusBar.css';

const stepLoginURL = '/onboarding/login';
const stepPageURL = '/onboarding/page';
const stepQualifyURL = '/onboarding/unqualified';
const stepAcceptInvitationURL = '/onboarding/invitation';

const stepVerifyDomainURL = '/onboarding/verify-domain';
const stepSharePixelURL = '/onboarding/share-pixel';
const stepChooseCampaignURL = '/onboarding/choose-campaign';
const stepSetUpRetargetingURL = '/onboarding/set-up-retargeting';
const stepSetUpProspectingURL = '/onboarding/set-up-prospecting';
const stepChoosePlanURL = '/onboarding/choose-plan';
const stepShopifyBillingURL = '/onboarding/shopify-billing';
const stepStripeBillingURL = '/onboarding/stripe-billing';
const stepFacebookBillingURL = '/onboarding/facebook-billing';

const stepGooglePermissionsURL = '/google/permissions';
const stepGoogleCampaignSettingsURL = '/google/settings';
const stepGoogleChoosePlanURL = '/google/choose-plan';
const stepGoogleShopifyBillingURL = '/google/shopify-billing';
const stepGoogleStripeBillingURL = '/google/stripe-billing';
const stepGoogleWebsiteClaimed = '/google/website-claimed';
const stepGoogleShopProtected = '/google/shop-protected';
const stepGoogleBillingURL = '/google/billing';
const stepGoogleChooseAdAccountURL = '/google/choose-ad-account';
const stepGoogleCreateMerchantCenterURL = '/google/create-merchant-center';
const stepGoogleChooseOrCreateProductFeed = '/google/choose-product-feed';

const stepUserBMConnectURL = '/user-bm/connect-bm';
const stepUserBMUpgradeRoleURL = '/user-bm/upgrade-role';
const stepUserBMCreatePageURL = '/user-bm/create-page';
const stepUserBMConnectPageURL = '/user-bm/connect-page';
const stepUserBMConnectAdAccountURL = '/user-bm/connect-ad-account';
const stepUserBMConnectOrCreateAdAccountURL = '/user-bm/connect-or-create-ad-account';
const stepUserBMRestrictedBMURL = '/user-bm/restricted-bm';
const stepUserBMPixelSettingsURL = '/user-bm/confirm-pixel';
const stepUserBMPixelNotFoundURL = '/user-bm/pixel-not-found';
const stepUserBMChoosePlanURL = '/user-bm/choose-plan';
const stepUserBMShopifyBillingURL = '/user-bm/shopify-billing';
const stepUserBMStripeBillingURL = '/user-bm/stripe-billing';
const stepUserBMFacebookBillingURL = '/user-bm/facebook-billing';
const stepUserBMCreateVisitorRetargetingCampaignURL = '/user-bm/create-visitor-retargeting-campaign';
const stepUserBMCreateDynamicRetargetingCampaignURL = '/user-bm/create-dynamic-retargeting-campaign';
const stepUserBMCreateAiBoostedSalesCampaignURL = '/user-bm/create-ai-boosted-sales-campaign';
const stepUserBMCreatSocialMediaRetargetingCampaignURL = '/user-bm/create-social-media-retargeting-campaign';
const stepUserBMChooseInstagramAccountURL = '/user-bm/choose-instagram-account';
const stepUserBMCreateInstagramAccountURL = '/user-bm/create-instagram-account';
const stepUserBMStillHaveNoInstagramAccountURL = '/user-bm/still-have-no-instagram-account';

const stepUserBMCreateProspectingCampaignURL = '/user-bm/create-prospecting-campaign';
const stepUserBMCreateSOCampaignURL = '/user-bm/create-special-offer-campaign';
const stepUserBMStartFreeTrial = '/user-bm/start-free-trial';
const stepUserBMCreateBM = '/user-bm/create-bm';

const stepTikTokBeforeYouBeginURL = '/tiktok/before-you-begin';
const stepTikTokChoosePlanURL = '/tiktok/choose-plan';
const stepTikTokShopifyBillingURL = '/tiktok/shopify-billing';
const stepTikTokStripeBillingURL = '/tiktok/stripe-billing';
const stepTikTokBookCallURL = '/tiktok/book-call';

const isCurrentStep = (links) => links.some((link) => window.location.pathname.endsWith(link));
const isCurrentState = (links, state) => links.some((i) => i.includes(state));

const googleStepperList = [
    {
        links: [
            'oauth',
            'shop_protected',
            'find_user_mc',
            'user_mc_required',
            'umc_create_ad_account',
            'find_product_feed',
            'product_feed_required',
            'umc_invite_to_google_ads',
            'link_ad_account_to_users_mc',
            'create_child_mc',
            'child_mc_created',
            'site_claimed',
            'ad_account_created',
            'user_invited_to_ad_account',
            'ad_account_linked_to_child_mc',
            'ad_account_link_to_child_mc_confirmed',
            'smart_shopping_campaign_enabled',
            stepGooglePermissionsURL,
            stepGoogleShopProtected,
            stepGoogleCreateMerchantCenterURL,
            stepGoogleChooseAdAccountURL,
            stepGoogleChooseOrCreateProductFeed
        ],
        title: 'Step 1. Accept request',
    },
    {
        links: [stepGoogleCampaignSettingsURL, stepGoogleWebsiteClaimed],
        title: 'Step 2. Review settings',
    },
    {
        links: [
            'choose_subscription_plan',
            'find_shop_payment',
            'approve_app_fee',
            'approve_stripe_app_fee',
            'create_campaign',
            stepGoogleShopifyBillingURL,
            stepGoogleStripeBillingURL,
            stepGoogleChoosePlanURL,
        ],
        title: 'Step 3. Approve app fee',
    },
    {
        links: ['add_payment_method', stepGoogleBillingURL],
        title: 'Step 4. Set up payment on Google',
    },
];

const getStartedStepperList = [
    {
        links: [stepLoginURL],
        title: 'Step 1. Log In',
    },
    {
        links: [stepQualifyURL],
        title: 'Step 2. Pre-Launch Check-Up',
    },
    {
        links: [stepPageURL],
        title: 'Step 3. Connect Facebook Page',
    },
    {
        links: [stepAcceptInvitationURL],
        title: 'Step 4. Accept Invitation',
    },
];

const createCampaignStepperList = [
    {
        links: [stepVerifyDomainURL],
        title: '1. Verify Domain',
    },
    {
        links: [stepSharePixelURL],
        title: '2. Share your Pixel',
    },
    {
        links: [stepSetUpRetargetingURL, stepSetUpProspectingURL, stepChooseCampaignURL],
        title: '3. Set up Campaign',
    },
    {
        links: [stepShopifyBillingURL, stepStripeBillingURL, stepChoosePlanURL],
        title: '4. Approve app fee',
    },
    {
        links: [stepFacebookBillingURL],
        title: '5. Add a card on Facebook',
    },
];

const userBMGetStartedStepperListOLD = [
    {
        links: [stepUserBMConnectURL, stepUserBMUpgradeRoleURL],
        title: '1. Business Manager',
    },
    {
        links: [stepUserBMConnectPageURL, stepUserBMRestrictedBMURL, stepUserBMCreatePageURL, stepUserBMConnectAdAccountURL, stepUserBMConnectOrCreateAdAccountURL],
        title: '2. Facebook page',
    },
    {
        links: [stepUserBMPixelSettingsURL, stepUserBMPixelNotFoundURL],
        title: '3. Pixel',
    },
    {
        links: [
            stepUserBMShopifyBillingURL,
            stepUserBMStripeBillingURL,
            stepUserBMFacebookBillingURL,
            stepUserBMChoosePlanURL,
        ],
        title: '4. Payments',
    },
    {
        links: [stepUserBMCreateVisitorRetargetingCampaignURL,
            stepUserBMCreateDynamicRetargetingCampaignURL,
            stepUserBMCreatSocialMediaRetargetingCampaignURL,
            stepUserBMCreateAiBoostedSalesCampaignURL,
            stepUserBMCreateProspectingCampaignURL,
            stepUserBMCreateSOCampaignURL],
        title: '5. Create campaign',
    },
];

const userBMGetStartedStepperList = [
    {
        links: [
            stepUserBMCreateVisitorRetargetingCampaignURL,
            stepUserBMCreateDynamicRetargetingCampaignURL,
            stepUserBMCreatSocialMediaRetargetingCampaignURL,
            stepUserBMCreateAiBoostedSalesCampaignURL,
            stepUserBMCreateProspectingCampaignURL,
            stepUserBMCreateSOCampaignURL],
        title: '1. Create campaign',
    },
    {
        links: [
            stepUserBMShopifyBillingURL,
            stepUserBMStripeBillingURL,
            stepUserBMChoosePlanURL,
        ],
        title: '2. Payments',
    },
    {
        links: [stepUserBMConnectURL, stepUserBMUpgradeRoleURL, stepUserBMCreateBM],
        title: '3. Business Manager',
    },
    {
        links: [stepUserBMConnectPageURL, stepUserBMRestrictedBMURL, stepUserBMCreatePageURL, stepUserBMConnectAdAccountURL, stepUserBMConnectOrCreateAdAccountURL],
        title: '4. Facebook page',
    },
    {
        links: [stepUserBMPixelSettingsURL, stepUserBMPixelNotFoundURL],
        title: '5. Pixel',
    }
];

const userBMWithInstagramGetStartedStepperList = [
    {
        links: [
            stepUserBMCreateVisitorRetargetingCampaignURL,
            stepUserBMCreateDynamicRetargetingCampaignURL,
            stepUserBMCreatSocialMediaRetargetingCampaignURL,
            stepUserBMCreateAiBoostedSalesCampaignURL,
            stepUserBMCreateProspectingCampaignURL,
            stepUserBMCreateSOCampaignURL],
        title: '1. Create campaign',
    },
    {
        links: [
            stepUserBMShopifyBillingURL,
            stepUserBMStripeBillingURL,
            stepUserBMChoosePlanURL,
        ],
        title: '2. Payments',
    },
    {
        links: [stepUserBMConnectURL, stepUserBMUpgradeRoleURL, stepUserBMCreateBM],
        title: '3. Business Manager',
    },
    {
        links: [stepUserBMChooseInstagramAccountURL, stepUserBMCreateInstagramAccountURL, stepUserBMStillHaveNoInstagramAccountURL],
        title: '4. Instagram account',
    },
    {
        links: [stepUserBMConnectPageURL, stepUserBMRestrictedBMURL, stepUserBMCreatePageURL, stepUserBMConnectAdAccountURL, stepUserBMConnectOrCreateAdAccountURL],
        title: '5. Facebook page',
    },
    {
        links: [stepUserBMPixelSettingsURL, stepUserBMPixelNotFoundURL],
        title: '6. Pixel',
    }
];

const tikTopStepperList = [
    {
        links: [stepTikTokBeforeYouBeginURL],
        title: 'Before you begin'
    },
    {
        links: [stepTikTokChoosePlanURL],
        title: 'Choose a plan'
    },
    {
        links: [stepTikTokStripeBillingURL, stepTikTokShopifyBillingURL],
        title: 'Approve fee'
    },
    {
        links: [stepTikTokBookCallURL],
        title: 'Book a call'
    },
];

const createCampaignStepperLinks = [
    stepVerifyDomainURL,
    stepSharePixelURL,
    stepSetUpRetargetingURL,
    stepSetUpProspectingURL,
    stepChoosePlanURL,
    stepShopifyBillingURL,
    stepStripeBillingURL,
    stepFacebookBillingURL,
    stepChooseCampaignURL,
];

const googleStepperLinks = [
    stepGooglePermissionsURL,
    stepGoogleWebsiteClaimed,
    stepGoogleCampaignSettingsURL,
    stepGoogleShopifyBillingURL,
    stepGoogleStripeBillingURL,
    stepGoogleBillingURL,
    stepGoogleChoosePlanURL,
    stepGoogleCreateMerchantCenterURL,
    stepGoogleChooseAdAccountURL,
    stepGoogleChooseOrCreateProductFeed
];

const userBMStepperLinks = [
    stepUserBMConnectURL,
    stepUserBMUpgradeRoleURL,
    stepUserBMConnectPageURL,
    stepUserBMConnectAdAccountURL,
    stepUserBMConnectOrCreateAdAccountURL,
    stepUserBMRestrictedBMURL,
    stepUserBMPixelSettingsURL,
    stepUserBMPixelNotFoundURL,
    stepUserBMChoosePlanURL,
    stepUserBMShopifyBillingURL,
    stepUserBMStripeBillingURL,
    stepUserBMFacebookBillingURL,
    stepUserBMCreateVisitorRetargetingCampaignURL,
    stepUserBMCreateDynamicRetargetingCampaignURL,
    stepUserBMCreatSocialMediaRetargetingCampaignURL,
    stepUserBMCreateAiBoostedSalesCampaignURL,
    stepUserBMCreateProspectingCampaignURL,
    stepUserBMCreateSOCampaignURL,
    stepUserBMCreatePageURL,
    stepUserBMStartFreeTrial,
    stepUserBMCreateBM,
    stepUserBMChooseInstagramAccountURL,
    stepUserBMCreateInstagramAccountURL,
    stepUserBMStillHaveNoInstagramAccountURL
];

const tikTokStepperLinks = [
    stepTikTokBeforeYouBeginURL,
    stepTikTokChoosePlanURL,
    stepTikTokShopifyBillingURL,
    stepTikTokStripeBillingURL,
    stepTikTokBookCallURL
]

const WizardStatusBar = ({ state, isCreateCampaignStep, isV2Onboarding, shopSubscriptionPlan }) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const isGoogleOnBoarding =
        googleStepperLinks.some((stepURL) => window.location.pathname.endsWith(stepURL)) || state
    const isBMOnBoarding =
        userBMStepperLinks.some((stepURL) => window.location.pathname.endsWith(stepURL));
    const isBMOnBoardingWithInstagram = isBMOnBoarding && currentOnboarding === ONBOARDING_TYPES.facebook_social_media_retargeting;
    const isFbOnBoarding =
        createCampaignStepperLinks.some((stepURL) => window.location.pathname.endsWith(stepURL));
    const isTikTokOnboarding =
        tikTokStepperLinks.some((stepURL) => window.location.pathname.endsWith(stepURL));
    let statusBarList;

    useEffect(() => {
        if (isBMOnBoarding && shopSubscriptionPlan && shopSubscriptionPlan !== SubscriptionPlanType.STARTER) {
            userBMGetStartedStepperList.push(
                {
                    links: [stepUserBMStartFreeTrial, stepUserBMFacebookBillingURL],
                    title: '6. Start Free Trial',
                }
            )
        }
    }, [shopSubscriptionPlan])

    if (isGoogleOnBoarding) {
        statusBarList = googleStepperList;
    } else if(isBMOnBoardingWithInstagram) {
        statusBarList = userBMWithInstagramGetStartedStepperList;
    } else if (isBMOnBoarding) {
        statusBarList = isV2Onboarding ? userBMGetStartedStepperList : userBMGetStartedStepperListOLD
    } else if (isFbOnBoarding) {
        statusBarList = createCampaignStepperList;
    }  else if (isTikTokOnboarding) {
        statusBarList = tikTopStepperList
    } else {
        statusBarList = getStartedStepperList;
    }

    const navList = statusBarList.map(({ links, title }, index) =>(
            <li
                key={index}
                className={classNames('nav-item', {
                    'd-none d-sm-block': !isCurrentStep(links),
                    'current-step': isCurrentStep(links) || isCurrentState(links, state),
                })}
            >
                <a
                    disabled={state ? !isCurrentState(links, state) : !isCurrentStep(links)}
                    className={
                    classNames("wizard-status-bar__link no-wrap",
                        {
                            "disabled": state ? !isCurrentState(links, state) : !isCurrentStep(links)
                        })
                    }
                    href={window.location.pathname}
                >
                    {title}
                </a>
            </li>
        ));

    return (
        <ul className={
            classNames(
                'd-flex wizard-status-bar',
                {
                    'create-campaign': isCreateCampaignStep,
                    'full-width-flex': isTikTokOnboarding
                }
            )}
        >
            {navList}
        </ul>
    );
};

export default WizardStatusBar;
