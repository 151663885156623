import React, { Component } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

class NotificationMigratedUserBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationMigratedUserIsClosed: false,
        };
        this.handleGotItButtonNotificationMigratedUser = this.handleGotItButtonNotificationMigratedUser.bind(this);
    }

    handleGotItButtonNotificationMigratedUser() {
        window.localStorage.setItem('NOTIFICATION_MIGRATED_USER_HIDDEN', 'true');
        this.setState({
            notificationMigratedUserIsClosed: true,
        });
    }

    render() {
        const notificationMigratedUserIsVisible =
            this.props.isMigratedUser &&
            !this.state.notificationMigratedUserIsClosed &&
            !window.localStorage.getItem('NOTIFICATION_MIGRATED_USER_HIDDEN');
        let notificationMigratedUser = null;

        if (notificationMigratedUserIsVisible) {
            notificationMigratedUser = (
                <NotificationWidget type="success">
                    <p className="notification__title">Previous campaign settings were saved</p>
                    <div>
                        <p className="notification__description">
                            For your convenience, we applied your previous retargeting campaign settings (such as ad
                            message, geo-targeting etc.) to the new one.
                            <br />
                            Moreover, for better ad delivery we changed the daily budget minimum to $20, so now
                            you&#39;ll have even more sales with Adwisely. <br />
                            If you&#39;d like to change something in your ad, please, let us know.
                        </p>
                    </div>
                    <Button color="transparent-bordered" onClick={this.handleGotItButtonNotificationMigratedUser}>
                        Got it{' '}
                        <span role="img" aria-label="Sunglasses">
                            {' '}
                            😎
                        </span>
                    </Button>
                </NotificationWidget>
            );
        }
        return <div>{notificationMigratedUser}</div>;
    }
}

export default NotificationMigratedUserBanner;
