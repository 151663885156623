import React from 'react';
import { ToDoListModalView } from './ToDoListModalView';

export const ShareAccessToAdAccountModal = ({isOpen, closeModal, onSupportBtnClick, onCompleteBtnClick}) => (
    <ToDoListModalView
        isOpen={isOpen}
        onCloseBtnClick={closeModal}
        checkboxLabel="I have shared access to Ad Account"
        onSupportBtnClick={onSupportBtnClick}
        onCompleteBtnClick={onCompleteBtnClick}
    >
        <h3 className="todo-list-modal__title">Share access to Ad Account</h3>
        <p>To let Adwisely create and manage efficient ads inside your Facebook Ad Account, share the account with
            Adwisely.</p>
        <ol>
            <li>
                In your Business Manager Settings, go to {' '}
                <a href=" https://business.facebook.com/settings/partners"
                   target="_blank"
                   rel="noopener noreferrer">Partners</a>
            </li>
            <li>Click on <b>Adwisely</b></li>
            <li>Under Assets you shared, click <b>Share Assets</b></li>
            <li>In the list on the right, pick <b>Ad accounts</b> and select the Ad Account you want to share</li>
            <li>Under <b>Ad Account Partial Access</b>, click on the toggle next to <b>Manage campaigns</b></li>
            <li>Click <b>Save Changes</b></li>
        </ol>
    </ToDoListModalView>
)