import React from 'react';

import './PageItemsList.css';

const FbPageItem = ({ fbPage, active, clickHandler }) => (
    <li
        className={active ? 'fb-pages-list__item active' : 'fb-pages-list__item'}
        id={fbPage.id}
        onClick={() => clickHandler(fbPage.id, fbPage.business)}
    >
        <p className="fb-pages-list__item__title">{fbPage.name}</p>
        <p className="fb-pages-list__item__notice">Likes {fbPage.fan_count}</p>
    </li>
);

const FbPageItemsList = ({ fbPages, activeId, clickHandler }) => (
    <ul className="fb-pages-list">
        {fbPages.map((page) => (
            <FbPageItem fbPage={page} active={page.id === activeId} clickHandler={clickHandler} key={page.id} />
        ))}
    </ul>
);

export default FbPageItemsList;
