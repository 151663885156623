import * as loaderActions from '../actions/loader';

export const initialState = false;

const loader = (state = initialState, action) => {
    switch (action.type) {
        case loaderActions.SHOW_LOADER:
            state = true;
            break;
        case loaderActions.HIDE_LOADER:
            state = false;
            break;
        default:
    }
    return state;
};

export default loader;
