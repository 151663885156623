import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from 'modules/index';

const Root = ({ store, history }) => (
    <Provider store={store}>
        <AppRouter history={history} />
    </Provider>
);

export default Root;
