import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import PropTypes from 'prop-types';

import { requestCampaignSuggestionsForCampaignCreate } from 'actions/campaign';
import { updateRecurringChange } from 'actions/shop';

import PricingWidget from 'components/PricingWidget/PricingWidget';
import AdsPreviewSection from 'components/AdsPreviewSection/AdsPreviewSection';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { getCurrentShopSelector } from 'helpers/selectors';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';

import { PROSPECTING } from 'constants/campaign';
import { BIG_COMMERCE, SHOPIFY, WOO_COMMERCE } from 'constants/platform';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import CampaignInfo from 'modules/campaign/components/CampaignInfo';
import CampaignDetails from 'modules/campaign/components/CampaignDetails';
import SaveCampaignDetails from 'modules/campaign/components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from 'modules/campaign/components/CampaignPageLayout';
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from 'modules/campaign/constants';

import './CreateProspectingCampaign.css';
import {CreateCampaignAdAudience} from '../CreateCampaignAdAudience/CreateCampaignAdAudience';

const CAMPAIGN_AD_MESSAGE_DEFAULT = 'You gonna love 😍 this. Treat yourself, you deserved it.';
const CAMPAIGN_TYPE_PROSPECTING = 'prospecting';

export class CreateProspectingCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',
            isLoading: false,

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignMinBudget: 0,

            // save values before saving to localStorage:
            dailyBudget: 0,
            adMessage: '',

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            saveCampaignError: null,
            isMobilePreview: false,
            countriesList : [],
            isWorldwide: false,
            ageMin: 0,
            ageMax: 0,
            genders: 0
        };
        this.handleFormError = this.handleFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);
        this.createCampaign = this.createCampaign.bind(this);
        this.saveCampaignHandler = this.saveCampaignHandler.bind(this);
        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = CAMPAIGN_AD_MESSAGE_DEFAULT;

                this.setState({
                    campaignBudget: suggestionsData.daily_budget.prospecting_suggested_value / 100,
                    campaignMinBudget: suggestionsData.daily_budget.min_value / 100,
                    campaignCurrency: suggestionsData.daily_budget.currency,
                    campaignAdMessage: suggestedAdMessage,
                });
            }
        }
    }

    handleFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        setTimeout(() => {
            this.setState({
                adMessageTagsHasError: !!tipsErrorText,
                adMessageTagsHasErrorTipsErrorText: tipsErrorText,
            });
        }, 0);
    }

    createCampaign(dailyBudget, adMessage) {
        if (!dailyBudget) return;
        const {
            params: { shopId },
            replace,
        } = this.props;

        const { countriesList, isWorldwide, ageMin, ageMax, genders } = this.state;

        const gendersArray = Object.keys(genders).map(gender => +gender)

        const params = {
            shopId,
            campaign_type: CAMPAIGN_TYPE_PROSPECTING,
            daily_budget: dailyBudget,
            messages: adMessage,
            currency: this.state.campaignCurrency,
            targeting_settings: {
                countries: countriesList,
                worldwide: isWorldwide,
                age_min: ageMin,
                age_max: ageMax,
                genders: gendersArray
            }
        };
        campaignCreateStorageHelper.clearStorage();

        changeOnboardingState({
            type: ONBOARDING_TYPES.facebook_prospecting,
            status: UBM_ONBOARDING_STATES.create_prospecting_campaign,
            currentShopId: shopId,
            data: params,
        })
            .then((res) => {
                if (!(res instanceof Error)) {
                    this.setState({
                        saveCampaignError: null,
                        isLoading: true,
                    });
                } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                    this.setState({
                        saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                        isLoading: false,
                    });
                }
                if (res.status === 409) {
                    facebookStatePolling(shopId, replace);
                }
                return res.json();
            })
            .then((res) => {
                if (res?.processing) {
                    facebookStatePolling(shopId, replace);
                }
            });
    }

    getAdAudience = (countries, isWorldwide, ageMin, ageMax, genders) => {
        this.setState({
            countriesList: countries.length > 0 ? countries.map(item => item.slug) : [],
            isWorldwide,
            ageMin,
            ageMax,
            genders
        })
    }

    saveCampaignHandler(dailyBudget, adMessage) {
        const {
            suggestions,
            currentShop,
        } = this.props;

        if (currentShop?.type === BIG_COMMERCE || currentShop?.type === WOO_COMMERCE) {
            if (!adMessage || !dailyBudget) return;
            this.createCampaign(dailyBudget, adMessage);
        } else {
            if (!suggestions.campaignSuggestionsSuccess) return;

            this.setState({
                dailyBudget,
                adMessage,
            });

            this.createCampaign(dailyBudget, adMessage);
        }
    }

    setIsMobilePreview = (newState) => {
        this.setState({ isMobilePreview: newState });
    };

    handleAdPreviewMsg = (msg) => {
        this.setState({
            previewAdMessage: msg,
        });
    };

    render() {
        const campaignFormName = 'prospectingCampaignCreate';
        const {
            params: { shopId },
            suggestions: { campaignSuggestionsData },
        } = this.props;

        const { isWorldwide, countriesList } = this.state;

        const { isLoading } = this.state;
        return (
            <div className="create-prospecting-campaign__wrapper">
                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            showIcon
                            shopId={shopId}
                            campaignTitle="Prospecting"
                            learnMoreLink={
                                'https://help.adwisely.com/improve-your-campaign-results/' +
                                'learn-about-advertising-tactics/prospecting'
                            }
                            campaignDescription={
                                'Boost sales and acquire more customers by targeting new ' +
                                'users who are similar to your current buyers with the ads of ' +
                                'the products they are most likely to purchase.'
                            }
                            customPricingWidget={
                                <PricingWidget
                                    pricingItemTitle="Prospecting Ad Spend"
                                    description={
                                        'Adwisely fee is based on your total Monthly Ad Spends ' +
                                        'and can not be less than $15.00 per month'
                                    }
                                />
                            }
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={this.state.campaignAdMessage}
                            campaignCurrency={this.state.campaignCurrency}
                            campaignMinBudget={this.state.campaignMinBudget}
                            campaignBudget={this.state.campaignBudget}
                            pricingAdviceItems={
                                campaignSuggestionsData && campaignSuggestionsData.daily_budget
                                    ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                                    : []
                            }
                            saveCampaign={this.saveCampaignHandler}
                            useDynamicTagsDropdown={false}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            dailyBudgetDescription={
                                'Daily budget is the maximum amount of money you are ready ' +
                                'to spend on your ads within a 24-hour period.'
                            }
                            saveCampaignError={this.state.saveCampaignError}
                            campaignType={PROSPECTING}
                        />

                        <CreateCampaignAdAudience
                            onAdAudienceSettingsChange={this.getAdAudience}
                            shopId={shopId}
                            isDropdownError={!isWorldwide && Object.keys(countriesList).length === 0}
                            isGenderVisible
                            isAgeVisible
                            isCountriesVisible
                        />

                        <SaveCampaignDetails
                            isButtonDisabled={!isWorldwide && Object.keys(countriesList).length === 0}
                            formName={campaignFormName}
                            buttonTitle="Continue"
                            buttonColor="primary"
                            hasCampaignFormError={this.state.adMessageTagsHasError || !!this.state.hasCampaignFormError}
                            tipsErrorText={this.state.adMessageTagsHasErrorTipsErrorText || this.state.tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdsPreviewSection
                            isMobilePreview={this.state.isMobilePreview}
                            setIsMobilePreview={this.setIsMobilePreview}
                            currentActiveCard={ONBOARDING_TYPES.facebook_prospecting}
                            isShopify={this.props.currentShop?.type === SHOPIFY}
                            previewAdMessage={this.state.previewAdMessage}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <LoaderFullScreen isLoaderShowed={isLoading}>
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
            </div>
        );
    }
}

CreateProspectingCampaign.defaultProps = {};

CreateProspectingCampaign.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    campaignAutoCreateType: PropTypes.shape({
        type: PropTypes.string,
    }).isRequired,
    currentShop: PropTypes.shape({
        app_uninstalled_at: PropTypes.any,
        business_model: PropTypes.string,
        created_at: PropTypes.string,
        credentials: PropTypes.any,
        domain: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        owner_email: PropTypes.string,
        owner_name: PropTypes.string,
        platform_id: PropTypes.string,
        review_note: PropTypes.string,
        review_rate: PropTypes.any,
        review_status: PropTypes.string,
        stripe_customer_id: PropTypes.string,
        type: PropTypes.string,
        updated_at: PropTypes.string,
    }).isRequired,
    params: PropTypes.shape({
        shopId: PropTypes.string.isRequired,
    }).isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsData: PropTypes.shape({
            daily_budget: PropTypes.shape({
                suggested_value: PropTypes.number,
                prospecting_suggested_value: PropTypes.number,
                min_value: PropTypes.number,
                currency: PropTypes.string,
            }),
            ad_message: PropTypes.string,
        }),
        campaignSuggestionsSuccess: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = ({
    campaignSuggestions,
    shops,
    campaignAutoCreateType,
}) => ({
    currentShop: getCurrentShopSelector(shops, shops.currentShopId),
    suggestions: campaignSuggestions,
    shops,
    campaignAutoCreateType,
});

const mapDispatchToProps = {
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    updateCharge: updateRecurringChange,
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProspectingCampaign);
