import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import TipsWidget from 'components/TipsWidget';

import { CAMPAIGN_CLIENT_STATUS, GOOGLE_CAMPAIGN_STATUS } from './helpers';

const ResumeCampaignButton = (props) => {
    const errorsList = [
        'payment-method-not-found',
        'ad-account-has-wrong-status-disabled',
        'ad-account-has-wrong-status-closed',
        'ad-account-has-wrong-status-pending_risk_review',
        'ad-account-has-wrong-status-unsettled',
        'shop-is-frozen-due-to-ad-account-is-unsettled',
        'public-page-not-found',
        'account-doesnt-have-access-to-page',
        'has-no-page-access-token',
        'shop-not-found',
        'platform-doesnt-have-access-to-shop',
        'shop-is-frozen-due-to-shop-password-enabled',
        'shop-is-frozen-due-to-shop-paused',
        'shop-payment-frozen',
        'shop-is-frozen-due-to-shop-payment-required',
        'shop-is-frozen-due-to-frozen-recurring-charge',
        'shop-is-frozen-due-to-pending-recurring-charge',
        'shop-is-frozen-due-to-expired-recurring-charge',
        'shop-is-frozen-due-to-cancelled-recurring-charge',
        'shop-is-frozen-due-to-declined-recurring-charge',
        'business-manager-doesnt-have-access-to-page',
        'shop-has-wrong-status-action-required',
        'products-not-found',
        'account-doesnt-have-access-to-business-manager',
        'business-manager-was-deleted',
        'ad-account-is-disabled-due-to-risk-payment',
        'shop-is-frozen-due-to-spend-cap-reached',
        'shop-is-frozen-due-to-force-paused',
        'shop-is-frozen-due-to-ra-plugin-for-wc-deactivated',
        'shop-is-frozen-due-to-subscription-canceled',
        'shop-is-frozen-due-to-subscription-spend-limit-reached'
    ];

    const isDisabled =
        props.collapseAdMessageInput ||
        props.campaignFailureError ||
        props.shopValidateRequesting ||
        props.clientStatus === CAMPAIGN_CLIENT_STATUS.PENDING ||
        props.clientStatus === CAMPAIGN_CLIENT_STATUS.DECLINED ||
        errorsList.includes(props.errorToken);
    const haveTips = props.errorToken !== 'ad-account-is-disabled-due-to-risk-payment';

    const getDisabledResumeButtonTips = () => {
        if (isDisabled && haveTips) {
            const getTipsContent = () => {
                switch (props.errorToken) {
                    case 'payment-method-not-found':
                        return (
                            'To resume or edit a campaign, you need to add a payment method ' +
                            'to the ad account used for your retargeting ads.'
                        );

                    case 'ad-account-has-wrong-status-disabled':
                    case 'ad-account-has-wrong-status-closed':
                    case 'ad-account-has-wrong-status-pending_risk_review':
                        return 'Once your ad account is activated, your campaign will be resumed automatically.';
                    case 'shop-is-frozen-due-to-ad-account-is-unsettled':
                    case 'ad-account-has-wrong-status-unsettled':
                        return (
                            'To resume or edit a campaign, you need to add a new payment method ' +
                            'to the ad account used for your retargeting ads.'
                        );

                    case 'shop-is-frozen-due-to-spend-cap-reached':
                        return 'Remove the spending limit to resume your campaign.';

                    case 'public-page-not-found':
                        return (
                            'To resume or edit a campaign, make the connected Facebook page ' +
                            'published or select another one.'
                        );
                    case 'has-no-page-access-token':
                    case 'account-doesnt-have-access-to-page':
                        return 'You need to connect a Facebook page to resume or edit a campaign.';

                    case 'shop-not-found':
                        return 'You can’t resume a campaign for a closed store.';

                    case 'platform-doesnt-have-access-to-shop':
                        return 'To run and manage your advertising campaigns, you need to install Adwisely.';

                    case 'shop-is-frozen-due-to-shop-password-enabled':
                        return 'You can’t run a campaign for a store with a password.';

                    case 'shop-is-frozen-due-to-shop-paused':
                        return 'You can’t run a campaign for a paused store.';

                    case 'shop-payment-frozen':
                    case 'shop-is-frozen-due-to-shop-payment-required':
                    case 'shop-is-frozen-due-to-frozen-recurring-charge':
                    case 'shop-is-frozen-due-to-pending-recurring-charge':
                    case 'shop-is-frozen-due-to-expired-recurring-charge':
                    case 'shop-is-frozen-due-to-cancelled-recurring-charge':
                    case 'shop-is-frozen-due-to-declined-recurring-charge':
                        return "You can't run a campaign for not active Shopify store.";

                    case 'business-manager-doesnt-have-access-to-page':
                        return (
                            'To launch a campaign, you need to make the connected Facebook page ' +
                            'published or choose another page.'
                        );

                    case 'shop-has-wrong-status-action-required':
                        return 'Your action is required to start a campaign.';

                    case 'products-not-found':
                        return 'You can’t resume a campaign for a store without products.';
                    case 'account-doesnt-have-access-to-business-manager':
                    case 'business-manager-was-deleted':
                        return 'To launch a campaign, please, contact us.';

                    default:
                        break;
                }
                return null;
            };

            if (getTipsContent()) {
                return (
                    <TipsWidget
                        isDarkIcon
                        isDarkView
                        noIcon
                        placement="top"
                        additionalClassName="dashboard-campaign-section-nav-button__tips-wrapper"
                        additionalIconClassName="dashboard-campaign-section-nav-button__tips-icon
                                             dashboard-campaign-section-nav-button__tips-icon_disabled"
                    >
                        <p className="tips-description__content">{getTipsContent()}</p>
                    </TipsWidget>
                );
            }
        }
        return null;
    };

    const resumeCampaign = () => {
        if (props.campaignType === 'google') {
            return props.resumeCampaign(props.shopId, props.campaignId);
        }
        return props.resumeCampaign(props.campaignId);
    };

    if (
        props.clientStatus !== CAMPAIGN_CLIENT_STATUS.ACTIVE &&
        props.clientStatus !== CAMPAIGN_CLIENT_STATUS.PENDING &&
        props.clientStatus !== CAMPAIGN_CLIENT_STATUS.CREATING &&
        props.clientStatus !== CAMPAIGN_CLIENT_STATUS.REJECTED &&
        props.clientStatus !== GOOGLE_CAMPAIGN_STATUS.ACTIVE &&
        props.clientStatus !== GOOGLE_CAMPAIGN_STATUS.UNKNOWN &&
        props.clientStatus !== GOOGLE_CAMPAIGN_STATUS.UNSPECIFIED &&
        props.clientStatus !== GOOGLE_CAMPAIGN_STATUS.REMOVED
    ) {
        return (
            <Button
                id="resumeButton"
                color="primary"
                pending={props.pending}
                disabled={isDisabled}
                onClick={() => !isDisabled && !props.pending && resumeCampaign()}
            >
                <span>Resume</span>
                {getDisabledResumeButtonTips()}
            </Button>
        );
    }
    return null;
};

ResumeCampaignButton.propTypes = {
    campaignFailureError: PropTypes.string,
    campaignId: PropTypes.number,
    campaignType: PropTypes.string,
    clientStatus: PropTypes.string.isRequired,
    collapseAdMessageInput: PropTypes.bool.isRequired,
    errorToken: PropTypes.string,
    pending: PropTypes.bool,
    resumeCampaign: PropTypes.func.isRequired,
    shopId: PropTypes.string,
    shopValidateRequesting: PropTypes.bool,
};

ResumeCampaignButton.defaultProps = {
    errorToken: null,
    pending: false,
    campaignType: 'facebook',
    shopValidateRequesting: false,
    campaignFailureError: null,
    campaignId: null,
    shopId: null,
};

export default ResumeCampaignButton;
