import { SHOULD_RENDER, DISABLE_RENDER } from 'actions/pageLoading';

const initialState = {
    loaded: false,
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case SHOULD_RENDER:
            return {
                ...state,
                loaded: true,
            };
        case DISABLE_RENDER:
            return {
                ...state,
                loaded: false,
            };
        default:
            return state;
    }
};
