import { callAPIFeatureFlags } from 'actions/api';

const FEATURE_FLAGS_REQUEST = 'FEATURE_FLAGS_REQUEST';
export const FEATURE_FLAGS_SUCCESS = 'FEATURE_FLAGS_SUCCESS';
const FEATURE_FLAGS_FAILURE = 'FEATURE_FLAGS_FAILURE';

export const requestFeatureFlags = (shopId) => (dispatch) =>
    dispatch(
        callAPIFeatureFlags(shopId, {
            types: [FEATURE_FLAGS_REQUEST, FEATURE_FLAGS_SUCCESS, FEATURE_FLAGS_FAILURE],
        }),
    );
