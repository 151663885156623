/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { requestShops } from 'actions/shop';

import { Loader } from 'components/Loader/Loader';
import intercom from 'libs/intercom';

import ErrorPageMonkeyImg from './errorpage-monkey-img.png';
import RALogo from './RA-color-logo.svg';
import './ErrorPage.css';

class ErrorPage extends PureComponent {
    componentDidMount() {
        const { requestShops } = this.props;

        const intervalRequestShops = setInterval(requestShops, 20000);
        setTimeout(() => {
            clearInterval(intervalRequestShops);
        }, 65000);
    }

    chatButtonHandler = () => {
        intercom.show();
    };

    render() {
        return (
            <div className="errorpage__wrapper">
                <div className="errorpage">
                    <div className="errorpage__img">
                        <img src={ErrorPageMonkeyImg} alt="Error page monkey img" />
                    </div>
                    <h2 className="errorpage__title">Houston, we have a problem</h2>
                    <p className="errorpage__description">
                        Don’t worry, it’s not your fault. We are already looking into it. Please, come back in a few
                        hours.
                    </p>
                    <div className="errorpage__loader">
                        <Loader />
                    </div>
                    <hr className="errorpage__bottom-line" />
                    <h4 className="errorpage__subtitle">
                        Got an urgent request?{' '}
                        <span
                            onClick={this.chatButtonHandler}
                            className="primary-link in-text"
                            onKeyDown={this.chatButtonHandler}
                            role="button"
                        >
                            Open chat
                        </span>
                    </h4>
                    <p className="errorpage__message-us">
                        or send email to{' '}
                        <a href="mailto:help@adwisely.com" className="primary-link">
                            help@adwisely.com
                        </a>
                    </p>
                </div>

                <div className="errorpage__ra-logo">
                    <img src={RALogo} alt="RA Logo" />
                </div>
            </div>
        );
    }
}

const mapPropsToDispatch = {
    requestShops,
};

export default connect(null, mapPropsToDispatch)(ErrorPage);
