import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'components/Modal/Modal';
import Button from 'components/Button';

import './ModalConnectPageError.css';

const ModalConnectPageError = (props) => {
    const { isOpen, buttonClickHandler, children, hideModal, modalLogo } = props;

    return (
        <Modal className="modal-page-error__dialog" isOpen={isOpen}>
            <button className="modal-page-error__close-button" onClick={hideModal} />
            <ModalBody className={`modal-page-error__body ${modalLogo}`}>
                <div className={`modal-page-error-body__logo ${modalLogo}`} />
                <div className="modal-page-error-body__content">{children}</div>
            </ModalBody>

            <ModalFooter className={`modal-page-error__footer ${modalLogo}`}>
                {modalLogo === "try-again" && <Button onClick={buttonClickHandler}>
                    Try Again
                </Button>}
                <Button url="https://calendly.com/adwisely-support-team/support-team" color="primary">
                    Book a call
                </Button>
            </ModalFooter>
        </Modal>
    );
};

ModalConnectPageError.defaultProps = {
    isOpen: false,
    modalLogo: 'error',
};

ModalConnectPageError.propTypes = {
    isOpen: PropTypes.bool,
    buttonClickHandler: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    modalLogo: PropTypes.string,
};

export default ModalConnectPageError;
