export const useResumeSubscription = (shopId) => {
    const resumeSubscriptionFetch = () => fetch(`/api/shop/${shopId}/subscription/resume`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })

    return {resumeSubscriptionFetch}
}