import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'components/Modal/Modal';

import './ModalWindowCharge.css';

const ModalWindowUpdateChargeProspecting = (props) => {
    const { isOpenModal, cancelHandler, approveHandler, approveChargeLink } = props;

    return (
        <Modal className="modal-charge__dialog" isOpen={isOpenModal}>
            <ModalHeader className="modal-charge__header">
                <p className="modal-charge__title">Upgrade Adwisely charge in Shopify</p>
            </ModalHeader>

            <ModalBody className="modal-charge__body">
                <p className="modal-charge-body__content">
                    To launch your Prospecting campaign, you need to upgrade Adwisely charge in Shopify. Once you
                    upgrade, your Shopify bill will include the charge for both your Retargeting and Prospecting
                    campaigns
                </p>

                <p className="modal-charge-body__content">
                    Adwisely fee for your Prospecting campaign will be counted the same way as for the Retargeting
                    one. It will be based on your monthly Prospecting ad spend and can range from{' '}
                    <span className="modal-charge-body__caption">5%</span> to{' '}
                    <span className="modal-charge-body__caption">10%.</span>
                </p>

                <p className="modal-charge-body__content modal-charge-body__footnote">
                    <a
                        href={
                            'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/' +
                            'whats-the-cost-of-retargetapp'
                        }
                        className="modal-charge__link modal-charge__link_with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn more
                    </a>
                </p>
            </ModalBody>

            <ModalFooter className="modal-charge__footer">
                <button className="modal-charge__button modal-charge__button_secondary" onClick={cancelHandler}>
                    Cancel
                </button>

                <a
                    href={approveChargeLink}
                    className="modal-charge__button modal-charge__button_primary"
                    onClick={approveHandler}
                    rel="noopener noreferrer"
                >
                    Go to Upgrade Charge
                </a>
            </ModalFooter>
        </Modal>
    );
};

ModalWindowUpdateChargeProspecting.defaultProps = {
    approveHandler: () => {},
    approveChargeLink: '',
};

ModalWindowUpdateChargeProspecting.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    approveHandler: PropTypes.func,
    approveChargeLink: PropTypes.string,
};

export default ModalWindowUpdateChargeProspecting;
