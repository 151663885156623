import React from 'react';

import Button from 'components/Button';
import { Card } from 'modules/home/components/Card';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { googleFlow } from 'modules/home/utils';
import { setLocalStorageObjectItem } from 'helpers';
import facebookIcon from "../../images/facebook-btn-icon.svg";
import specialOfferIcon from './stars.svg';
import {FACEBOOK_LOGIN_CALLBACK_URL} from '../../../facebook/login/const';

const FacebookCardContent = {
    facebook_special_offer: {
        title: <p className="campaign-info__title">Facebook <span className="campaign-info__title--green">Special Offer</span><img src={specialOfferIcon} alt="" className="campaign-info__title-img--special-offer" /></p>,
        body: 'Run a short-term promo for a new collection, sale, sitewide discount, or a special holiday offer to brand new customers and store visitors',
    },
    facebook_prospecting: {
        title: 'Facebook Prospecting',
        body: 'Boost sales and acquire more customers by targeting new users who are similar to your current buyers with the ads.',
    },
    facebook_visitor_retargeting: {
        title: 'Facebook Visitor Retargeting',
        body: 'Got store visitors, but no sales yet? Re-engage the window shoppers with ads that showcase your store best-sellers',
    },
    facebook_social_media_retargeting: {
        title: 'Facebook Social Retargeting',
        body: 'Harness the power of your social profiles by showing ads to people who interacted with your Facebook page and/or Instagram profile',
    },
    facebook_dynamic_retargeting: {
        title: 'Facebook Dynamic Retargeting',
        body: 'Get even more sales with Dynamic Product ads (DPA). Show each of your store visitors and cart abandoners an ad with exact product(s) they viewed on your store',
    },
    facebook_childbm: {
        title: 'Meta AI-powered Campaign',
        body: 'This smart ad strategy maximizes effectiveness and boosts profits by combining machine learning to allocate your budget wisely, reaching both new and existing customers. This results in personalized product showcases and optimal outcomes.',
    },
    facebook_ai_boosted_sales: {
        title: 'Meta AI-powered Campaign',
        body: 'This smart ad strategy maximizes effectiveness and boosts profits by combining machine learning to allocate your budget wisely, reaching both new and existing customers. This results in personalized product showcases and optimal outcomes.'
    }
}

export const FacebookCard = ({
    additionalClassName,
    state,
    redirect,
    currentShopId,
    disableButtons,
    handleStartOnboarding,
    onboardingType,
    isFacebookAuthorized,
    loginLink,
    sendMetrics,
    isActive,
    onClick,
    icons,
    setIsLoading
}) => {
    const redirectToFBLogin = () => {
        if (!isFacebookAuthorized) {
            localStorage.setItem(FACEBOOK_LOGIN_CALLBACK_URL, `/${currentShopId}/`);
            window.location.assign(loginLink);
        }
    };

    const handleFacebookButton = () => {
        sendMetrics();
        setIsLoading(true);
        googleFlow.reset();
        setLocalStorageObjectItem('currentOnboarding', onboardingType);

        if (state === UBM_ONBOARDING_STATES.done) {
            setLocalStorageObjectItem('activeTab', '0');
            redirect(`/${currentShopId}/dashboard`);
            return;
        }

        if (onboardingType === ONBOARDING_TYPES.facebook_childbm) {
            redirect(`/${currentShopId}/onboarding`); // start facebook cbm onboarding flow
            handleStartOnboarding();
            return;
        }

        if (state === UBM_ONBOARDING_STATES.not_started) {
            handleStartOnboarding() // start facebook ubm onboarding flow
                .then(res => res.json())
                .then(res => {
                    if (res?.processing) {
                        facebookStatePolling(currentShopId, redirect, redirectToFBLogin);
                    }
                });
            } else if (state === UBM_ONBOARDING_STATES.login && !isFacebookAuthorized) {
                redirectToFBLogin();
            } else {
                redirect(`/${currentShopId}/user-bm`);
            }
    };

    return (
        <Card
            additionalClassName={additionalClassName}
            icons={icons}
            onClick={onClick}
            type={state}
            isActive={isActive}
            title={FacebookCardContent[onboardingType].title}
            text={FacebookCardContent[onboardingType].body}
            renderButton={() => (
                <Button
                    color="primary"
                    onClick={handleFacebookButton}
                    disabled={disableButtons}
                    className="home__board__block__content__btn"
                >
                    <span className="home__board__block__content__btn-icon">
                        <img src={facebookIcon} alt="facebook icon"/>
                    </span>
                    {isFacebookAuthorized ? 'Continue with Facebook' : 'Sign in with Facebook'}
                </Button>
            )}
        />
    );
};
