import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './BoostSalesBanner.css';

import Button from '../../Button';
import {setLocalStorageObjectItem} from '../../../helpers';

const BoostBannerType = ['gl_boost_sales_boots', 'gl_boost_sales_fishing', 'gl_boost_sales_automoto', 'gl_boost_sales_massager', 'gl_boost_sales_pets'];

const BoostSalesBannerView = ({
  notifications,
  currentShopId,
  push
}) => {
  const notification = notifications.find((notification) => BoostBannerType.includes(notification.type));
  if (!notification) return null;
  const { type } = notification;
  const cx = classNames(
      'boost-banner',
      {
        'boost-banner--1': type === BoostBannerType[0],
        'boost-banner--2': type === BoostBannerType[1],
        'boost-banner--3': type === BoostBannerType[2],
        'boost-banner--4': type === BoostBannerType[3],
        'boost-banner--5': type === BoostBannerType[4],
      }
  );
  return (
      <section className={cx}>
          <h2 className="boost-banner__title">
            Boost Your Sales and ROAS!
          </h2>
        <span className="boost-banner__text">
            Our clients who launched a ‘Google AI-powered campaign’ saw:
        </span>
        <div className="boost-banner__features">
          <div className="boost-banner__features-item">
            40%
            <span>increase <br /> in sales</span>
          </div>
          <div className="boost-banner__features-item">5x ROAS</div>
        </div>
        <div className="custom_campaign_banner-cta">
          <Button className="custom_campaign_banner-cta_book_call" color="yellow" onClick={() => {
            setLocalStorageObjectItem('boost-banner', 'true');
            push(`/${currentShopId}/`);

          }}>
            Launch Ads
          </Button>
          <Button url="https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose#h_c05aa4f07d" className="custom_campaign_banner_learn_more" color="link">
            Learn More
          </Button>
        </div>
      </section>
  )
}

const mapStateToProps = ({ shops }) => ({
  currentShopId: shops?.currentShopId,
});

const dispatchToProps = {
  push: push,
}

export const BoostSalesBanner =  connect(mapStateToProps, dispatchToProps)(BoostSalesBannerView);