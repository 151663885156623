import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import './ConnectBM.css';

const ConnectBM = ({ currentShopId, userBusinessManager, replace, userBMListRequesting }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBMId, setSelectedBMId] = useState(null);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            !selectedBMId && setSelectedBMId(userBusinessManager[0]?.id);
        }
    });

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.select_bm,
            currentShopId,
            data: { business_fb_id: selectedBMId },
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
    }

    const onChange = (bmId) => setSelectedBMId(bmId);
    
    const chosenBM = userBusinessManager?.find((bm) => +bm.id === +selectedBMId);

    let ubmSelector = null;

    if (userBMListRequesting) {
        ubmSelector = <p className="connect-bm__description">Loading...</p>
    }

    if (userBusinessManager?.length > 1) {
        ubmSelector = <DropdownProvider>
            {({ toggle, isOpen }) => {
                const dropdownItems = userBusinessManager?.map((bm, key) => (
                    <DropdownItemMain
                        key={key}
                        value={bm.id}
                        onClick={() => {
                            onChange(bm.id);
                            toggle();
                        }}
                        active={+bm.id === +selectedBMId}
                        title={`${bm.name} [ID: ${bm.id}]`}
                        subtitle={!bm.is_user_admin_of_business ? 'No admin rights' : ''}
                        disabled={!bm.is_user_admin_of_business}
                    />
                ));

                const flagToggler = (
                    <FlagToggler
                        title={chosenBM ? `${chosenBM.name} [ID: ${chosenBM.id}]` : null}
                        subtitle={chosenBM && !chosenBM.is_user_admin_of_business ? 'No admin rights' : ''}
                        caseIcon
                    >
                        Select Business Manager
                    </FlagToggler>
                );

                return (
                    <Dropdown toggle={toggle} isOpen={isOpen} flagToggler={flagToggler} items={dropdownItems} />

                )
            }}
        </DropdownProvider>
    }

    const getUserBMIdLink = (id) => <a href={`https://business.facebook.com/adsmanager/manage/campaigns?business_id=${id}`} target="_blank" rel="noopener noreferrer">{id}</a>;

    if (userBusinessManager?.length === 1) {
        ubmSelector = <p className="connect-bm__description">
            <strong>[{userBusinessManager[0]?.name}] (ID: {getUserBMIdLink(userBusinessManager[0]?.id)})</strong>
        </p>
    }

    return (
        <div className="connect-bm__wrapper">
            <h1 className="connect-bm__title">Connect your Business Manager to Adwisely</h1>
            <div className="connect-bm__body-wrapper">
                <div>
                    <p className="connect-bm__description">
                        Adwisely will create a new Ad Account inside your Business Manager.
                        To proceed, confirm the Business Manager you'd like us to use.
                    </p>
                    {userBusinessManager?.length > 1 && <p className="connect-bm__description connect-bm__description--sm">
                        All Business managers that you have no admin rights for, are pointed
                        in grey, if you want to continue with them please receive admin rights
                        for them. If you have additional questions please contact our Support
                    </p>}
                    <h6>Business Mananger</h6>
                    {ubmSelector}
                </div>
                <BookACallSection />
            </div>
            <Button onClick={handleContinueBtn} color="primary">
                Continue
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops, userBusinessManager }) => ({
    currentShopId: shops?.currentShopId,
    userBusinessManager: userBusinessManager?.userBMList,
    userBMListRequesting: userBusinessManager?.userBMListRequesting,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ConnectBM);
