import React, { PureComponent } from 'react';

import intercom from 'libs/intercom';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

class ShopWrongStatusBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.hasCampaignFormError = false;
        this.TipsErrorText = '';
        this.getShopWrongStatusBanner = this.getShopWrongStatusBanner.bind(this);
        this.openIntercomChatButton = this.openIntercomChatButton.bind(this);
    }

    componentDidMount() {
        const { changeTipsErrorText, handleFormError } = this.props;
        if (changeTipsErrorText && this.TipsErrorText) changeTipsErrorText(this.TipsErrorText);
        if (handleFormError && this.hasCampaignFormError) handleFormError(this.hasCampaignFormError);
    }

    getShopWrongStatusBanner() {
        const { shopError } = this.props;
        let notificationData = null;
        let bannerTitle = null;
        let bannerContent = null;
        let bannerButtons = null;
        if (!shopError.errorData) shopError.errorData = {};

        switch (shopError.errorToken) {
            case 'shop-has-wrong-status-rejected':
                bannerTitle = <p className="notification__title">We couldn’t create ads for you</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description">
                            We couldn’t create a campaign for your store because the store main
                            page and/or the store products don’t seem to comply with the Advertising
                            Policies of the platform you selected. If you’d like to learn more or believe
                            that your store complies with the Ads Policies, please contact Support.
                        </p>
                    </div>
                );

                bannerButtons = this.openIntercomChatButton();

                notificationData = [bannerTitle, bannerContent, bannerButtons];
                this.TipsErrorText = 'We can’t launch your campaign';
                this.hasCampaignFormError = true;
                break;
            default:
                break;
        }
        return notificationData;
    }

    openIntercomChatButton = (color = 'transparent-bordered', title = 'Contact Support') => (
        <Button color={color} onClick={intercom.show}>
            {title}
        </Button>
    );

    render() {
        const shopStatusBannerArray = this.getShopWrongStatusBanner();
        if (shopStatusBannerArray) {
            return (
                <div>
                    <NotificationWidget type="danger">
                        {shopStatusBannerArray.map((notificationItem, key) => (
                            <span key={key}>{notificationItem}</span>
                        ))}
                    </NotificationWidget>
                </div>
            );
        }
        return null;
    }
}

export default ShopWrongStatusBanner;
