import React from 'react';
import { PropTypes } from 'prop-types';

import StatSubItem from './components/StatSubItem';
import StatMainItem from './components/StatMainItem';

import './Statistics.css';

const Statistics = ({ dataMainList, dataSubsectionList, isAdSpendsOverTheLimit }) => (
    <section className="statistics__wrapper">
        <ul className="statistics__section statistics__section_primary">
            {dataMainList.map(({ name, title, tips, warning, prefix, postfix, currency, isFloat, valueCurrent }) => (
                <li key={name} className={`statistics-section__item statistics-section__item_${name}`}>
                    <StatMainItem
                        tips={tips}
                        warning={warning}
                        title={title}
                        prefix={prefix}
                        postfix={postfix}
                        isFloat={isFloat}
                        currency={currency}
                        valueCurrent={valueCurrent}
                        isAdSpendsOverTheLimit={isAdSpendsOverTheLimit}
                    />
                </li>
            ))}
        </ul>

        <ul className="statistics__section statistics__section_secondary">
            {dataSubsectionList.map(({ name, title, tips, prefix, postfix, currency, isFloat, valueCurrent }) => (
                <li key={name} className="statistics-section__item statistics-section-secondary__item">
                    <StatSubItem
                        tips={tips}
                        title={title}
                        prefix={prefix}
                        postfix={postfix}
                        isFloat={isFloat}
                        currency={currency}
                        valueCurrent={valueCurrent}
                    />
                </li>
            ))}
        </ul>
    </section>
);

Statistics.propTypes = {
    dataMainList: PropTypes.arrayOf(
        PropTypes.shape({
            delta: PropTypes.string,
            valueCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            isFloat: PropTypes.bool,
            prefix: PropTypes.string,
            postfix: PropTypes.string,
            title: PropTypes.string.isRequired,
            tips: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
            name: PropTypes.string,
        }),
    ).isRequired,
    dataSubsectionList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            tips: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
            valueCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            prefix: PropTypes.string,
            postfix: PropTypes.string,
        }),
    ).isRequired,
};

export default Statistics;
