import React from 'react';
import './Table.css';

export const StyledTable = (props) => (
    <table className="styled-table" {...props}>
        {props.children}
    </table>
);

export default StyledTable;
