import gdprConsent from "../libs/gdprConsent";
import eSputnik from "../libs/eSputnik";

export const addGDPRBanner = () => {
    // Warning: Do not add the banner to pages that participate in OAuth flows.
    // The OAuth callback pages should be fast because of security and the first impression.
    // Also, they can't be reloaded/refreshed/opened twice because of protection against replay attacks.
    gdprConsent((functionality, performance, targeting) => {
        if (performance && targeting) {
            eSputnik();
        }
    });
}

