export const FEE_RATES = {
    trial: 'trial',
    'regular-10': '10%',
    'regular-8': '8%',
    'regular-5': '5%',
    'prospecting-trial': 'trial',
    'prospecting-10': '10%',
    'prospecting-8': '8%',
    'prospecting-5': '5%',
    'partner-free': 'Free',
};

// ‘2018’ because the first client paid his first fee for using Adwisely V.3 in 2018
export const YEAR_OF_FIRST_RA_BILLING = '2018';
