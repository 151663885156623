import {callAPIGETTokenHandler} from './api';

export const TOKEN_HANDLER_REQUEST = 'TOKEN_HANDLER_REQUEST';
export const TOKEN_HANDLER_SUCCESS = 'TOKEN_HANDLER_SUCCESS';
export const TOKEN_HANDLER_FAILURE = 'TOKEN_HANDLER_FAILURE';

export const requestActionTokenHandler = token => (dispatch) => {
    dispatch(callAPIGETTokenHandler({
        types: [
            TOKEN_HANDLER_REQUEST,
            TOKEN_HANDLER_SUCCESS,
            TOKEN_HANDLER_FAILURE,
        ],
        params: {
            token,
        },
    }));
};

