import React, { Component } from 'react';

import { CUSTOM_CAMPAIGN } from 'constants/notificationType';

import CustomCampaignBanner from './CustomCampaignBanner';

class CustomCampaignWrapper extends Component {
    state = {
        isCustomCampaignBannerHidden: false,
    };

    hideCustomCampaignBanner = () => {
        this.setState({ isCustomCampaignBannerHidden: true });
    };

    render() {
        const isCustomCampaignExist = this.props.shopNotificationEntities?.find(
            (notification) => notification.type === CUSTOM_CAMPAIGN,
        );

        const isVisible = !this.state.isCustomCampaignBannerHidden && isCustomCampaignExist;

        return <>{isVisible && <CustomCampaignBanner onClickHandler={this.hideCustomCampaignBanner} />}</>;
    }
}

export default CustomCampaignWrapper;
