import React from 'react';

import Button from 'components/Button';
import SuccessIcon from './components/SuccessIcon';

import './StartFreeTrialView.css';

export const StartFreeTrialView = ({onStartTrialBtnClick}) => (
        <div className="start-trial__wrapper">
            <h2 className="start-trial__caption">
                You are almost done{' '}
                <span role="img" aria-label="Party popper">
                    🎉
                </span>
            </h2>
            <div className="start-trial__icon-wrapper">
                <SuccessIcon />
            </div>
            <p className="start-trial__description">
                Looks like you are all set.
                <span role="img" aria-label="Flexed Biceps">💪🏿</span>
                You can now start your 7-Day Free Trial
            </p>
            <Button className="start-trial__button" onClick={onStartTrialBtnClick} large color="primary" selfTarget>
                START FREE TRIAL
            </Button>
            <div className="start-trial__link">
                By clicking “Start free trial”, you agree to our&nbsp;
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://help.adwisely.com/en/articles/900431-terms-of-service"
                >
                    Terms of Service
                </a>{' '}
                and <br /> that you have read our{' '}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://help.adwisely.com/en/articles/900424-privacy-policy"
                >
                    Privacy Policy
                </a>
                .
            </div>
        </div>
    );
