/**
 *
 * @param uri {String}
 * @param params {{}}
 * @throws {Error}
 * @returns {String}
 */
export const prepareURI = (uri, params) => {
    const paramsPattern = /\$([a-zA-Z]*)/ig;
    let finalUri = uri;

    const matches = uri.match(paramsPattern);

    if (matches && matches.length) {
        matches.forEach((paramKey) => {
            const key = paramKey.slice(1);
            if (params[key]) {
                finalUri = finalUri.replace(paramKey, params[key]);
            } else {
                throw new Error(`No param ${key}`);
            }
        });
    }

    return finalUri;
};
