export const TIK_TOK_ONBOARDING_STEPS = {
    before_you_begin: 'before-you-begin',
    approve_app_fee: 'shopify-billing',
    approve_stripe_app_fee: 'stripe-billing',
    book_a_call: 'book-call',
    call_booked: 'call-booked',
    done: 'done',
    failed: 'failed',
    choose_subscription_plan: 'choose-plan',
}

export const TIK_TOK_ONBOARDING_STATES = {
    not_started: 'not_started',
    before_you_begin: 'before_you_begin',
    choose_subscription_plan: 'choose_subscription_plan',
    approve_app_fee: 'approve_app_fee',
    approve_stripe_app_fee: 'approve_stripe_app_fee',
    book_a_call: 'book_a_call',
    call_booked: 'call_booked',
    done: 'done',
    failed: 'failed',
}