import React from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { verificationNotificationType } from 'constants/verificationNotificationType';
import { requestSendEventMetrics } from 'actions/shop';
import { requestDeleteShopNotification, requestShopNotifications } from 'actions/notification';
import { changeAccountEmail } from 'actions/account';


import NotificationWidget from 'components/NotificationWidget';

import Button from 'components/Button';

import './VerificationNotifications.css';
import {useGetUserEmail} from '../../../../hooks/useGetUserEmail';

export const VerificationNotificationsView =
    ({
         type,
         removeNotification,
         requestShopNotifications,
         currentShopId,
         push,
         changeAccountEmail,
         setIsLoading
    }) => {
    const { email } = useGetUserEmail(setIsLoading);

    const deleteSuccessfullyVerifiedNotification = () => {
        removeNotification({
            id: currentShopId,
            type: verificationNotificationType.emailSuccessfullyVerified,
        }).then(() => {
            requestShopNotifications({
                id: currentShopId,
            })
        });
    }

    const onVerifyEmailBtnClick = () => {
        setIsLoading(true);
        changeAccountEmail({
            "verify-email-action": true,
            "shop_id": currentShopId
        }).then(res => {
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
            requestShopNotifications({
                id: currentShopId,
            })
        })
    }

    switch (type) {
        case verificationNotificationType.pleaseVerifyYourEmail:
            return (
                <div className='verification-notification'>
                    <NotificationWidget type='warning'>
                        <div className="notification__title">Verify your email address</div>
                        <div className="notification__description verification-notification-description">
                            We will use <span className='notification__description--underline-text'>{email}</span> to send you important messages and updates on your campaign performance. <br />
                            To confirm this address, click <b>Verify email</b>  and follow the instructions. <br />
                            If you would like to use a different email address, click <b>Change email</b> </div>
                        <div className="verification-notification-btns">
                            <Button
                                color='transparent-bordered'
                                className='verification-notification__btn'
                                onClick={onVerifyEmailBtnClick}
                            >Verify email</Button>
                            <Button
                                color='transparent-no-border'
                                onClick={() => {
                                    push(`/${currentShopId}/settings`)
                                }}
                            >Change email</Button>
                        </div>
                    </NotificationWidget>
                </div>
            )
        case verificationNotificationType.emailVerificationLinkSent:
            return (
                <div className='verification-notification'>
                    <NotificationWidget type='warning'>
                        <div className="notification__title">Check your inbox</div>
                        <div className="notification__description verification-notification-description">
                            We have sent a confirmation email to <span className='notification__description--underline-text'>{email}</span>. <br />
                            Please, go to your inbox and click the button inside the email. If you haven’t received the email within 15 minutes, <br />
                            click <b>Re-send email</b>
                        </div>
                        <div className="verification-notification-btns">
                            <Button
                                color='transparent-bordered'
                                onClick={onVerifyEmailBtnClick}
                            >Re-send email</Button>
                        </div>
                    </NotificationWidget>
                </div>
            )
        case verificationNotificationType.emailSuccessfullyVerified:
            return (
                <div className='verification-notification'>
                    <NotificationWidget type='success'>
                        <div className="notification__title">Email successfully verified <span role='img' aria-label='party-popper' >🎉</span></div>
                        <div className="verification-notification-btns">
                            <Button color='transparent-bordered' onClick={deleteSuccessfullyVerifiedNotification}>Okay</Button>
                        </div>
                    </NotificationWidget>
                </div>
            )
        default:
            return <></>
    }

}

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const dispatchToProps = {
    requestSendEventMetrics,
    removeNotification: requestDeleteShopNotification,
    requestShopNotifications,
    push: push,
    changeAccountEmail
}

export const VerificationNotifications =  connect(mapStateToProps, dispatchToProps)(VerificationNotificationsView);