import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'components/Modal/Modal';
import Button from 'components/Button';

import './ModalWindowCharge.css';

class ModalWindowUpdateShopifyPermissions extends PureComponent {
    render() {
        const { isOpenModal, cancelHandler, updatePermissionsLink, updatePermissionsHandler } = this.props;
        return (
            <Modal className="modal-charge__dialog modal-permissions__dialog" isOpen={isOpenModal}>
                <ModalHeader className="modal-charge__header modal-permissions__header">
                    <p className="modal-charge__title">Update Adwisely</p>
                </ModalHeader>

                <ModalBody className="modal-charge__body modal-permissions__body">
                    <p className="modal-charge-body__content">
                        To make sure that you can reach out to brand new customers and provide more relevant offers,
                        please, update your version of Adwisely — this won’t take more than a minute.
                    </p>
                </ModalBody>

                <ModalFooter className="modal-charge__footer">
                    <Button onClick={cancelHandler}>Remind me later</Button>
                    <Button url={updatePermissionsLink} onClick={updatePermissionsHandler} selfTarget color="primary">
                        Update Adwisely
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

ModalWindowUpdateShopifyPermissions.defaultProps = {
    updatePermissionsHandler: () => {},
    updatePermissionsLink: '',
};

ModalWindowUpdateShopifyPermissions.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    updatePermissionsLink: PropTypes.string,
    updatePermissionsHandler: PropTypes.func,
};
export default ModalWindowUpdateShopifyPermissions;
