import { useState } from 'react';

import { ONBOARDING_TYPES } from 'constants/onboarding';

export const useTabs = (isGoogleSupported, onboardingState) => {
    const [, changeActiveTabs] = useState(1);

    const states = {
        isGoogleDone: onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.google)[0]?.state === 'done',
        isFacebookDone:
            onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.facebook_special_offer)[0]?.state === 'done' ||
            onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.facebook_ai_boosted_sales)[0]?.state === 'done' ||
            onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.facebook_prospecting)[0]?.state === 'done' ||
            onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.facebook_visitor_retargeting)[0]?.state === 'done' ||
            onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.facebook_dynamic_retargeting)[0]?.state === 'done' ||
            onboardingState?.filter(({ name }) => name === ONBOARDING_TYPES.facebook_childbm)[0]?.state === 'done',
    };

    const platformTabs = [
        { id: 1, content: 'Available ad types', type: 'campaign' },
        states.isFacebookDone && { id: 2, content: 'Facebook Dashboard', type: 'facebook', tab: '0' },
    ];
    const platformTabsWithGoogleSupport = [...platformTabs, states.isGoogleDone && { id: 3, content: 'Google Dashboard', type: 'google', tab: '1' }];

    const tabs = isGoogleSupported
        ? platformTabsWithGoogleSupport
        : platformTabs;

    return { tabs: tabs.filter(i => i), changeActiveTabs };
};
