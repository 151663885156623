import React from 'react';

import { useCounter } from './hooks';

import './SMSCounter.css';

export const SMSCounter = ({ handleResendSmsCode }) => {
    const [counter, isTimeOut, resetCounter] = useCounter();

    return isTimeOut ? (
        <div
            className="gl-onboarding-settings__sms-counter__resend-link"
            onClick={() => {
                resetCounter();
                handleResendSmsCode();
            }}
        >
            Resend the text message
        </div>
    ) : (
        <div className="gl-onboarding-settings__modal__content gl-onboarding-settings__sms-counter__countdown__counter__wrapper">
            Didn't receive the text message? Please wait{' '}
            <span className="gl-onboarding-settings__sms-counter__countdown__counter">00:{counter}</span>
        </div>
    );
};
