import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Dropdown';
import DropdownProvider from 'components/DropdownProvider';
import { DropdownItemMain } from 'components/DropdownItem';
import { StandardToggler } from 'components/DropdownToggler';
import './SelectDropdown.css';


export default class SelectDropdown extends Component {
    constructor(props) {
        super(props);

        this.dropdownItemHandler = this.dropdownItemHandler.bind(this);
    }

    dropdownItemHandler(choosenValue) {
        const { clickItemHandler } = this.props;
        if (clickItemHandler) clickItemHandler(choosenValue);
    }

    render() {
        const { disabled, list, placeholder } = this.props;
        return (
            <DropdownProvider>
                {({ isOpen, toggle }) => (
                    <Dropdown
                        placement={'top-end'}
                        isOpen={isOpen}
                        toggle={toggle}
                        className="select-dropdown"
                        additionalMenuClassName={'dropdown-menu_list--billing-history'}
                        flagToggler={
                            <StandardToggler disabled={disabled}>
                                {placeholder}
                            </StandardToggler>
                        }
                        items={list.map((item) => (
                            <DropdownItemMain
                                onClick={() => {
                                    this.dropdownItemHandler(item);
                                    toggle();
                                }}
                                active={+placeholder === +item}
                                key={item.toString()}
                                title={item.toString()}
                            />
                        ))}
                    />
                )}
            </DropdownProvider>
        );
    }
}

SelectDropdown.defaultProps = {
    clickItemHandler: null,
};

SelectDropdown.propTypes = {
    // eslint-disable-next-line
    list: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    clickItemHandler: PropTypes.func,
};
