import React from 'react';

import './googleButton.styles.css';

export const GoogleButton = ({
    disabled,
    link,
}) => (
    <a
        href={link}
        target="_self"
        className={disabled ? 'google_button_wrapper disabled' : 'google_button_wrapper'}
    >
        Continue with Google
    </a>
);

