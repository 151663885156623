import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { requestActionTokenHandler } from 'actions/actionHandler';

import { LoaderLongWait as Loader } from 'components/Loader';

import ActionTokenSuccess from './ActionTokenSuccess';
import ActionTokenFailure from './ActionTokenFailure';

import './ActionHandler.css';

class ActionHandler extends PureComponent {
    componentDidMount() {
        const { location, requestActionTokenHandler } = this.props;
        if (location.query && location.query.token) {
            requestActionTokenHandler(location.query.token);
        }
    }

    render() {
        const { replace } = this.props;

        if (this.props.actionSuccess) {
            return <ActionTokenSuccess replace={replace} />;
        }
        if (this.props.actionFailure) {
            return <ActionTokenFailure />;
        }

        return (
            <div className="action-handler-loader">
                <Loader />
            </div>
        );
    }
}

const mapStateToProps = ({ actionHandler }) => ({
    actionSuccess: actionHandler.actionHandlerSuccess,
    actionFailure: actionHandler.actionHandlerFailure,
});

const mapDispatchToProps = {
    replace,
    requestActionTokenHandler,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionHandler);
