import { combineReducers } from 'redux';

import adAccounts from './adAccounts';
import facebookLogin from './facebookLogin';
import pages from './pages';
import inframe from './inframe';
import shop from './shop';
import shopError from './shop-error';
import stripeAdd from './stripe-add';
import stripeDeleteSource from './stripe-delete';
import onboardingsState from './onboardingsState'

const onboarding = combineReducers({
    adAccounts,
    facebookLogin,
    inframe,
    pages,
    shop,
    shopError,
    stripeAdd,
    stripeDeleteSource,
    onboardingsState,
});

export default onboarding;
