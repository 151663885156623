import * as campaignActions from '../actions/campaign';

const initialState = {
    googleCampaignsData: [],
    googleCampaignsPending: false,
    googleCampaignsSuccess: false,
    googleCampaignsFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.GOOGLE_CAMPAIGNS_REQUEST:
            return {
                ...state,
                googleCampaignsSuccess: false,
                googleCampaignsPending: true,
                googleCampaignsFailure: false,
            };

        case campaignActions.GOOGLE_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                googleCampaignsSuccess: true,
                googleCampaignsPending: false,
                googleCampaignsFailure: false,
                googleCampaignsData: action.payload,
            };

        case campaignActions.GOOGLE_CAMPAIGNS_FAILURE:
            return {
                ...state,
                googleCampaignsSuccess: false,
                googleCampaignsFailure: true,
                googleCampaignsPending: false,
            };

        case campaignActions.GOOGLE_CAMPAIGN_PAUSE_REQUEST:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.payload.campaignId) {
                        return {
                            ...campaign,
                            campaignPausePending: true,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_PAUSE_SUCCESS:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...action.payload,
                            campaignPausePending: false,
                            campaignFailureError: null,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_PAUSE_FAILURE:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.meta.params.id) {
                        return {
                            ...campaign,
                            campaignPausePending: false,
                            campaignFailureError: action.payload.errorToken,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_RESUME_REQUEST:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.payload.campaignId) {
                        return {
                            ...campaign,
                            campaignResumePending: true,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_RESUME_SUCCESS:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...action.payload,
                            campaignResumePending: false,
                            campaignFailureError: null,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_RESUME_FAILURE:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.meta.params.id) {
                        return {
                            ...campaign,
                            campaignResumePending: false,
                            campaignFailureError: action.payload.errorToken,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_EDIT_REQUEST:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.payload.campaignId) {
                        return {
                            ...campaign,
                            campaignEditPending: true,
                            campaignEditSuccess: false,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_EDIT_SUCCESS:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...campaign,
                            ...action.payload,
                            campaignEditPending: false,
                            campaignEditSuccess: true,
                            campaignEditFailure: false,
                            campaignFailureError: null,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.GOOGLE_CAMPAIGN_EDIT_FAILURE:
            return {
                ...state,
                googleCampaignsData: state.googleCampaignsData.map((campaign) => {
                    if (campaign.id === action.meta.params.id) {
                        return {
                            ...campaign,
                            campaignEditPending: false,
                            campaignEditSuccess: false,
                            campaignEditFailure: true,
                            campaignFailureError: action.payload.errorToken,
                        };
                    }
                    return campaign;
                }),
            };

        default:
            return state;
    }
};
