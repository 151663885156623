import * as campaignActions from '../actions/campaign';

const initialState = {
    campaignStatisticsData: {},
    lifetimeROAS: null,
    campaignStatisticsDataPending: false,
    campaignStatisticsDataSuccess: false,
    campaignStatisticsDataFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.SHOP_STATISTIC_REQUEST:
            state = {
                ...initialState,
                lifetimeROAS: state.lifetimeROAS,
                campaignStatisticsDataPending: true,
            };
            break;

        case campaignActions.SHOP_STATISTIC_SUCCESS:
            state = {
                ...initialState,
                lifetimeROAS: state.lifetimeROAS,
                campaignStatisticsDataSuccess: true,
                campaignStatisticsData: action.payload,
            };
            break;

        case campaignActions.SHOP_STATISTIC_LIFETIME_SUCCESS:
            state = {
                ...initialState,
                campaignStatisticsData: state.campaignStatisticsData,
                lifetimeROAS: action.payload[0].roas,
            };
            break;

        case campaignActions.SHOP_STATISTIC_FAILURE:
            state = {
                ...initialState,
                lifetimeROAS: state.lifetimeROAS,
                campaignStatisticsDataFailure: true,
            };
            break;

        default:
            break;
    }
    return state;
};
