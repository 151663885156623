import React from 'react';
import { Modal, ModalBody, ModalHeader } from "components/Modal/Modal";
import Button from "components/Button";

import './CancelPlanModal.css';

export const CancelPlanModal = ({isModalVisible, onBackBtnClick, onCancelPlanBtnClick, onReasonInputChange, isCancelPlanBtnDisabled}) => (
    <Modal isOpen={isModalVisible} className='cancel-plan-modal'>
        <ModalHeader className='cancel-plan-modal__header'>Cancel Adwisely plan</ModalHeader>
        <ModalBody className='cancel-plan-modal__body'>
            <p>You are about to cancel your Adwisely plan. After you do so, all Adwisely-powered ads will stop in the end of your current billing period, and, consequently, any ongoing learning phases will be reset.</p>
            <p>If this isn’t the solution you were looking for, you can pause your ads, change your plan or contact support.</p>
            <p>If you would like to cancel your Adwisely plan, please, specify the reason for cancellation in the box below and click “Cancel Adwisely plan”</p>
            <textarea onChange={onReasonInputChange} className='cancel-plan-modal__textarea' />
            <span className='cancel-plan-modal__input-tip'>*Required <br /> 10 characters min </span>

            <div className="cancel-plan-modal__buttons">
                <Button onClick={onBackBtnClick}>Back to Settings</Button>
                <Button color="primary" onClick={onCancelPlanBtnClick} disabled={isCancelPlanBtnDisabled}>Cancel Adwisely plan</Button>
            </div>
        </ModalBody>
    </Modal>
)