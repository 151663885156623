import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    requestCampaignSuggestionsForCampaignCreate,
    goToDashboard,
    requestCampaignValidateFacebook,
} from 'actions/campaign';
import { validateShop } from 'actions/shop';
import { displayBulkNotification, showBulkNotification } from 'actions/notification';

import pageNameSetter from 'decorator/pageNameSetter';

import campaignEditHelper from 'helpers/campaignEditHelper';
import {
    campaignErrorSelector,
    getCurrentShopSelector,
    isShopValidateRequesting,
    getShopErrorSelector,
    getBulkNotificationContentSelector,
    getBulkNotificationTypeSelector,
    getAdAccountsSelector,
    getCampaignSuggestionsSelector,
    getShopRecurringChargeSelector,
} from 'helpers/selectors';

import IntercomChatCaller from 'components/IntercomChatCaller';
import BulkNotification from 'components/BulkNotification';
import GeneralBannerNotificationList from 'components/GeneralBannerNotificationList';
import Banners from 'components/Banners';

import { changeShopPage } from 'constants/metric';
import { PROSPECTING, changeDailyBudgetPage } from 'constants/campaign';
import AdsPreviewSection from "components/AdsPreviewSection/AdsPreviewSection";
import { ONBOARDING_TYPES } from "constants/onboarding";
import { SHOPIFY } from "constants/platform";
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from '../constants';

import CampaignInfo from '../components/CampaignInfo/index';
import CampaignDetails from '../components/CampaignDetails/index';
import EditCampaignDetails from '../components/EditCampaignDetails/index';
import { CampaignPageLayout, CampaignPageCol } from '../components/CampaignPageLayout';
import DashboardInfoNotifications from '../../dashboard/DashboardInfoNotifications';

import './ProspectingCampaignEdit.css';
import prospectingIcon from '../../../img/prospecting-logo-icon.svg';
import {EditCampaignAdAudience} from '../components/EditCampaignAdAudience/EditCampaignAdAudience';
import {normalizeCountriesArray} from '../components/AdAudience/helpers/handleAdAudienceState';

class ProspectingCampaignEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPublishPage: true,
            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',
            // default values
            campaignCurrency: 'USD',
            campaignBudget: props.campaign.settings.daily_budget / 100,
            campaignAdMessage: props.campaign.ad_sets[0].message,
            campaignMinBudget: 0,
            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            saveCampaignError: null,
            isMobilePreview: false,
            previewAdMessage: null,
            countriesList: [],
            isWorldwide: false,
            ageMin: 0,
            ageMax: 0,
            genders: 0
        };

        // save campaign details
        this.handleValidateFormError = this.handleValidateFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);
        this.refreshMinBudgetAndAdPreview = this.refreshMinBudgetAndAdPreview.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.changePublishPage = this.changePublishPage.bind(this);
        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId, id },
            checkShopForErrors,
        } = this.props;
        requestSuggestions({ id: shopId });
        checkShopForErrors({ shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.campaignSuggestionsSuccess && nextProps.campaignSuggestionsSuccess) {
            this.setState({
                campaignMinBudget: nextProps.campaignSuggestionsData.daily_budget.min_value / 100,
                campaignCurrency: nextProps.campaignSuggestionsData.daily_budget.currency,
            });
        }

        if (!this.props.campaign.campaignEditSuccess && nextProps.campaign.campaignEditSuccess) {
            const { campaign } = nextProps;

            this.setState({
                campaignBudget: campaign.settings.daily_budget / 100,
                campaignAdMessage: campaign.ad_sets[0].message,
            });
        }
        if (nextProps.campaign.campaignEditFailure) {
            const text = (
                <span>
                    Something went wrong. Change daily budget/ad message or{' '}
                    <IntercomChatCaller className="edit-campaign-details-contact-us_link" content="contact us" /> to
                    give you help.
                </span>
            );
            this.handleAdMessageTagsHasError(text);
        }

        if (this.props.adAccounts.connectStatus.access_status !== nextProps.adAccounts.connectStatus.access_status) {
            const { adAccounts } = nextProps;
            const { chosenAdAccountId, connectStatus } = adAccounts;

            if (
                chosenAdAccountId &&
                connectStatus.fb_id === this.state.errorData.ad_account_id &&
                connectStatus.access_status === 'granted'
            ) {
                this.refreshMinBudgetAndAdPreview();
            }
        }
    }

    componentDidUpdate() {
        if (this.props.campaignError) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    handleValidateFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    saveCampaign(dailyBudget, adMessage) {
        const {
            params: { shopId, id },
            editProspectingCampaign,
            goToNextStep,
        } = this.props;

        const {
            countriesList,
            isWorldwide,
            ageMin,
            ageMax,
            genders
        } = this.state;

        const gendersArray = Object.keys(genders).map(gender => +gender)

        const params = {
            id: +id,
            shopId,
            campaign_type: PROSPECTING,
            daily_budget: dailyBudget,
            messages: adMessage,
            set_from_page: changeDailyBudgetPage.editCampaignPage,
            targeting_settings: {
                countries: countriesList,
                worldwide: isWorldwide,
                age_min: ageMin,
                age_max: ageMax,
                genders: gendersArray
            }
        };

        editProspectingCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                this.props.showBulkNotification('Campaign settings updated.');
                goToNextStep(shopId);
                this.setState({
                    saveCampaignError: null,
                });
            } else {
                this.props.displayBulkNotification('Campaign edit failure!', 'error');
                if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                    this.setState({
                        saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                    });
                }
            }
        });
    }

    refreshMinBudgetAndAdPreview() {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });
    }

    campaignValidateFacebookHandler(params) {
        const {
            shopValidateRequesting,
            checkShopForErrors,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId, params).then((res) => {
                if (!(res instanceof Error)) {
                    checkShopForErrors({ shopId });
                }
            });
        }
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    changePublishPage(isPublish) {
        this.setState({
            isPublishPage: isPublish,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        this.setState({
            adMessageTagsHasError: !!tipsErrorText,
            adMessageTagsHasErrorTipsErrorText: tipsErrorText,
        });
    }

    setIsMobilePreview = (newState) => {
        this.setState({isMobilePreview: newState})
    }

    handleAdPreviewMsg = (msg) => {
        this.setState({
            previewAdMessage: msg,
        });
    };

    getAdAudience = (countries, isWorldwide, ageMin, ageMax, genders) => {
        this.setState({
            countriesList: countries.length > 0 ? countries.map(item => item.slug) : [],
            isWorldwide,
            ageMin,
            ageMax,
            genders
        })
    }

    render() {
        const campaignFormName = 'prospectingCampaignEdit';
        const {
            adPreviewError,
            params: { shopId },
            shopRecurringCharge,
            shopError,
            campaign,
            shopValidateRequesting,
            suggestions: { campaignSuggestionsData },
            bulkNotificationContent,
            bulkNotificationType,
            currentShop,
            checkShopForErrors,
            campaignError,
        } = this.props;

        const {isWorldwide, countriesList} = this.state;

        const { campaignEditPending } = campaign;
        let bulkNotification = null;

        if (bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        let shopErrorBanner = null;
        if ((shopError && Object.keys(shopError).length) || campaignError) {
            shopErrorBanner = (
                <Banners
                    shopError={shopError || adPreviewError}
                    changeTipsErrorText={this.changeTipsErrorText}
                    handleFormError={this.handleValidateFormError}
                    validationPending={shopValidateRequesting}
                    validateShopSettings={() => checkShopForErrors({ shopId })}
                    campaignValidateFacebook={this.campaignValidateFacebookHandler}
                    shopRecurringCharge={shopRecurringCharge}
                    checkShopForErrors={() => checkShopForErrors({ shopId })}
                    shopId={shopId}
                    changePublishPage={this.changePublishPage}
                    currentShop={currentShop}
                    campaignError={campaignError}
                />
            );
        }

        return (
            <div className="prospecting-campaign-edit__wrapper">
                {shopErrorBanner}

                <DashboardInfoNotifications shop={currentShop} campaignEdit />

                <GeneralBannerNotificationList shopId={shopId} />

                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleValidateFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={this.state.campaignAdMessage}
                            campaignCurrency={this.state.campaignCurrency}
                            campaignMinBudget={this.state.campaignMinBudget}
                            campaignBudget={this.state.campaignBudget}
                            saveCampaign={this.saveCampaign}
                            disabled={campaignEditPending}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            saveCampaignError={this.state.saveCampaignError}
                            campaignType={PROSPECTING}
                        />

                        <EditCampaignAdAudience
                            onAdAudienceSettingsChange={this.getAdAudience}
                            shopId={shopId}
                            isDropdownError={!isWorldwide && Object.keys(countriesList).length === 0}
                            countriesListData={
                                normalizeCountriesArray(
                                    Object.keys(campaign?.targeting_settings?.countries), campaign?.targeting_settings?.available_countries
                                )}
                            availableCountriesData={
                                normalizeCountriesArray(
                                    Object.keys(campaign?.targeting_settings?.available_countries), campaign?.targeting_settings?.available_countries
                                )}
                            isWorldwideAvailable={campaign?.targeting_settings?.available_worldwide}
                            isWorldWide={campaign?.targeting_settings?.worldwide}
                            maxNumOfCountries={campaign?.targeting_settings?.max_num_of_countries}
                            gendersData={campaign?.targeting_settings?.genders}
                            availableGendersData={campaign?.targeting_settings?.available_genders}
                            ageMaxData={campaign?.targeting_settings?.age_max}
                            ageMinData={campaign?.targeting_settings?.age_min}
                            availableAgeMaxData={campaign?.targeting_settings?.available_age_max}
                            availableAgeMinData={campaign?.targeting_settings?.available_age_min}
                            isGenderVisible
                            isAgeVisible
                            isCountriesVisible
                        />

                        <CampaignInfo
                            showIcon={false}
                            campaignTitle="Prospecting"
                            titleIconUrl={prospectingIcon}
                            learnMoreLink={
                                'https://help.adwisely.com/improve-your-campaign-results/' +
                                'learn-about-advertising-tactics/prospecting'
                            }
                            campaignDescription={
                                'Boost sales and acquire more customers by targeting new ' +
                                'users who are similar to your current buyers with the ads of ' +
                                'the products they are most likely to purchase.'
                            }
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdsPreviewSection
                            isMobilePreview={this.state.isMobilePreview}
                            setIsMobilePreview={this.setIsMobilePreview}
                            currentActiveCard={ONBOARDING_TYPES.facebook_prospecting}
                            isShopify={this.props.currentShop?.type === SHOPIFY}
                            previewAdMessage={this.state.previewAdMessage}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <EditCampaignDetails
                    formName={campaignFormName}
                    hasCampaignFormError={this.state.hasCampaignFormError}
                    shopId={shopId}
                    campaignEditPending={campaignEditPending}
                    tipsErrorText={this.state.adMessageTagsHasErrorTipsErrorText || this.state.tipsErrorText}
                    pricingAdviceItems={
                        campaignSuggestionsData && campaignSuggestionsData.daily_budget
                            ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                            : []
                    }
                />
                {bulkNotification}
            </div>
        );
    }
}

ProspectingCampaignEdit.defaultProps = {
    campaignSuggestionsData: null,
    campaign: {
        campaignEditPending: false,
        campaignEditSuccess: false,
        settings: {},
    },
    adPreviewError: null,
    shopError: null,
    shopRecurringCharge: null,
    bulkNotificationContent: null,
    bulkNotificationType: null,
    campaignError: null,
};

ProspectingCampaignEdit.propTypes = {
    adAccounts: PropTypes.shape({
        connectStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                access_status: PropTypes.string,
            })
        ]),
    }).isRequired,
    adPreviewError: PropTypes.shape({
        errorToken: PropTypes.string,
    }),
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    campaign: PropTypes.shape({
        campaignEditPending: PropTypes.bool,
        campaignEditSuccess: PropTypes.bool,
        settings: PropTypes.shape({
            daily_budget: PropTypes.number,
        }),
    }).isRequired,
    campaignError: PropTypes.string,
    campaignSuggestionsSuccess: PropTypes.bool.isRequired,
    campaignValidateFacebook: PropTypes.func.isRequired,
    checkShopForErrors: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    editProspectingCampaign: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    shopRecurringCharge: PropTypes.shape({
        entity: PropTypes.shape({
            confirmation_url: PropTypes.string,
        }),
    }).isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    showBulkNotification: PropTypes.func.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsData: PropTypes.shape({
            ad_message: PropTypes.string,
            daily_budget: PropTypes.shape({
                suggested_value: PropTypes.number,
                prospecting_suggested_value: PropTypes.number,
                min_value: PropTypes.number,
                currency: PropTypes.string,
            }),
            migrated_user: PropTypes.bool
        }),
    }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);
    return {
        currentShop,
        campaignSuggestionsSuccess: getCampaignSuggestionsSelector(state).campaignSuggestionsSuccess,
        campaignSuggestionsData: getCampaignSuggestionsSelector(state).campaignSuggestionsData,
        campaign: state.campaignDetails.campaignDetailsData.find((el) => el.id === +ownProps.params.id),
        adPreviewError: state.campaign.adPreviewError,
        shopError: getShopErrorSelector(state),
        shopValidateRequesting: isShopValidateRequesting(state),
        shopRecurringCharge: getShopRecurringChargeSelector(state),
        suggestions: getCampaignSuggestionsSelector(state),
        bulkNotificationContent: getBulkNotificationContentSelector(state),
        bulkNotificationType: getBulkNotificationTypeSelector(state),
        adAccounts: getAdAccountsSelector(state),
        campaignError: campaignErrorSelector(state.campaignDetails.campaignDetailsData),
    };
};

const mapDispatchToProps = {
    checkShopForErrors: validateShop,
    campaignValidateFacebook: requestCampaignValidateFacebook,
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    editProspectingCampaign: (params) => (dispatch, getState) => campaignEditHelper(params, dispatch, getState),
    goToNextStep: goToDashboard,
    showBulkNotification,
    displayBulkNotification,
};

const ProspectingCampaignEditContainer = connect(mapStateToProps, mapDispatchToProps)(ProspectingCampaignEdit);

export default pageNameSetter(changeShopPage.edit_prospecting_campaign)(ProspectingCampaignEditContainer);
