export const ONBOARDING_STEPS = {
    done: 'done',
    oauth: 'processing',
    failed: 'failed',
    umc_connect_ad_account: 'choose-ad-account',
    cmc_connect_ad_account: 'choose-ad-account',
    create_merchant_center: 'create-merchant-center',
    verify_phone: 'settings',
    configure_campaign: 'settings',
    choose_subscription_plan: 'choose-plan',
    add_payment_method: 'billing',
    approve_app_fee: 'shopify-billing',
    approve_stripe_app_fee: 'stripe-billing',
    site_claim_error: 'website-claimed',
    shop_protected: 'shop-protected',
    choose_product_feed: 'choose-product-feed'
};

export const ONBOARDING_PAGES = {
    home: 'home',
    processing: 'processing',
};

export const ONBOARDING_STATES = {
    failed: 'failed',
    not_started: 'not_started',
    oauth: 'oauth',
    site_claim_error: 'site_claim_error',
    started: 'started',
    shop_protected: 'shop_protected',
    umc_connect_ad_account: 'umc_connect_ad_account',
    cmc_connect_ad_account: 'cmc_connect_ad_account',
    create_merchant_center: 'create-merchant-center',
    verify_phone: 'verify_phone',
    configure_campaign: 'configure_campaign',
    choose_subscription_plan: 'choose_subscription_plan',
    add_payment_method: 'add_payment_method',
    approve_app_fee: 'approve_app_fee',
    approve_stripe_app_fee: 'approve_stripe_app_fee',
    choose_product_feed: 'choose_product_feed',
    done: 'done',
};
