import * as BMActions from 'actions/businessManager';
import { REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS, REQUEST_STATUS_FAILURE } from 'constants/requestStatuses';

const initialState = {
    data: null,
    error: null,
    requestStatus: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case BMActions.GET_USER_STATUS_IN_CHILD_BM_REQUEST:
        case BMActions.ADD_USER_TO_CHILD_BM_REQUEST:
            return {
                ...state,
                requestStatus: REQUEST_STATUS_PENDING,
            };
        case BMActions.GET_USER_STATUS_IN_CHILD_BM_SUCCESS:
        case BMActions.ADD_USER_TO_CHILD_BM_SUCCESS:
            return {
                ...state,
                data: payload,
                requestStatus: REQUEST_STATUS_SUCCESS,
            };
        case BMActions.GET_USER_STATUS_IN_CHILD_BM_FAILURE:
        case BMActions.ADD_USER_TO_CHILD_BM_FAILURE:
            return {
                ...state,
                error: payload,
                requestStatus: REQUEST_STATUS_FAILURE,
            };
        default:
            return state;
    }
};
