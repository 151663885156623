import React from 'react';
import { PropTypes } from 'prop-types';

import { numberDigitSeparateWithSpace } from 'helpers';
import TipsWidget from 'components/TipsWidget';
import { StatMainItemWithTips } from './StatMainItemWithTips';
import { STATISTICS_DATA } from '../../constants';

const getMainItemToRender = ({ prefix, postfix, currency, valueCurrent, isFloat }) => {
    if (valueCurrent) {
        return name === STATISTICS_DATA.PURCHASES ? (
            <StatMainItemWithTips prefix={prefix} postfix={postfix} valueCurrent={valueCurrent} />
        ) : (
            <p className="statistics-section-item__value statistics-section-primary-item__value">
                {(prefix || '') +
                    (postfix
                        ? Math.trunc(+valueCurrent)
                        : numberDigitSeparateWithSpace(valueCurrent, !isFloat) || valueCurrent)}
                <sup>{postfix || currency}</sup>
            </p>
        );
    }

    return <p className="statistics-section-item__value statistics-section-primary-item__value">-</p>;
};

const StatMainItem = ({ title, tips, warning, prefix, postfix, currency, valueCurrent, isFloat, isAdSpendsOverTheLimit }) => (
    <div className="statistics-section__item statistics-section-primary__item">
        <div className="statistics-section-item__title statistics-section-primary-item__title">
            <span>{title}</span>
            <TipsWidget>{tips}</TipsWidget>
            {warning && isAdSpendsOverTheLimit && <TipsWidget warningIcon>{warning}</TipsWidget>}
        </div>
        {getMainItemToRender({
            prefix,
            postfix,
            currency,
            valueCurrent,
            isFloat,
        })}
    </div>
);

StatMainItem.defaultProps = {
    isFloat: false,
    prefix: null,
    postfix: null,
    valueCurrent: null,
    currency: null,
};

StatMainItem.propTypes = {
    valueCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFloat: PropTypes.bool,
    prefix: PropTypes.string,
    postfix: PropTypes.string,
    currency: PropTypes.string,
    title: PropTypes.string.isRequired,
    tips: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default StatMainItem;
