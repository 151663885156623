import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import {
    goToDashboard,
    clearCampaignError,
    requestAdPreviewForCampCreate,
    requestCampaignSuggestionsForCampaignCreate,
    requestCreateCampaign,
} from 'actions/campaign';
import { displayBulkNotification } from 'actions/notification';
import { requestSendEventMetrics, validateShop } from 'actions/shop';

import Button from 'components/Button';
import { ArrowLeft } from 'components/Icons';
import AdPreview from 'modules/campaign/components/AdPreview';
import CampaignInfo from 'modules/campaign/components/CampaignInfo';
import CampaignDetails from 'modules/campaign/components/CampaignDetails';
import SaveCampaignDetails from 'modules/campaign/components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from 'modules/campaign/components/CampaignPageLayout';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import BulkNotification from 'components/BulkNotification';
import NotificationMigratedUserBanner from 'components/Banners/NotificationMigratedUserBanner';

import {
    getCurrentShopSelector,
    isStaffPlatformPlanSelector,
    isShopValidateRequesting,
    getShopErrorTokenSelector,
    getBulkNotificationContentSelector,
    getBulkNotificationTypeSelector,
    getAdAccountsSelector,
    getCampaignSuggestionsSelector,
} from 'helpers/selectors';

import { RETARGETING } from 'constants/campaign';
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from 'modules/campaign/constants';
import { pageTypes, sourcePage } from 'constants/adPreview';

import './RetargetingCampaignCreate.css';

const CAMPAIGN_AD_MESSAGE_DEFAULT = "Check what we've got 😉 I'm sure you'll love it! Get your {{product.name}} now!";

export class RetargetingCampaignCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewAdMessage: null,
            currentPreviewType: null,
            isPublishPage: true,
            isLoading: false,

            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignMinBudget: 0,

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
        };

        this.requestAdPreview = this.requestAdPreview();
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
            checkShopForErrors,
        } = this.props;
        this.requestAdPreview();
        requestSuggestions({ id: shopId });
        checkShopForErrors({ shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = suggestionsData.migrated_user
                    ? suggestionsData.ad_message
                    : CAMPAIGN_AD_MESSAGE_DEFAULT;

                this.setState({
                    campaignBudget: suggestionsData.daily_budget.prospecting_suggested_value / 100,
                    campaignMinBudget: suggestionsData.daily_budget.min_value / 100,
                    campaignCurrency: suggestionsData.daily_budget.currency,
                    campaignAdMessage: suggestedAdMessage,
                });
            }
        }

        if (this.props.adAccounts.connectStatus.access_status !== nextProps.adAccounts.connectStatus.access_status) {
            const { adAccounts } = nextProps;
            const { chosenAdAccountId, connectStatus } = adAccounts;

            if (
                chosenAdAccountId &&
                connectStatus.fb_id === this.state.errorData.ad_account_id &&
                connectStatus.access_status === 'granted'
            ) {
                this.refreshMinBudgetAndAdPreview();
            }
        }
    }

    componentWillUnmount() {
        this.props.clearCampaignError();
    }

    handleAdPreviewMsg = (msg, isForceRequest = false) => {
        this.setState({
            previewAdMessage: msg,
        });
        this.requestAdPreview(msg, isForceRequest);
    };

    handlePreviewType = (curPreviewType, isForceRequest = true) => {
        this.setState({
            currentPreviewType: curPreviewType,
        });
        this.requestAdPreview(null, isForceRequest);
    };

    requestAdPreview = () => {
        const {
            requestAdPreview,
            params: { shopId },
        } = this.props;
        const self = this;
        let t = null;
        return (msg, isForceRequest = false) => {
            if (t) clearTimeout(t);
            t = setTimeout(
                () => {
                    requestAdPreview({
                        id: shopId,
                        format: self.state.currentPreviewType,
                        message: msg || self.state.previewAdMessage,
                    });
                    t = null;
                },
                isForceRequest ? 0 : 1000,
            );
        };
    };

    handleFormError = (hasCampaignFormError) => {
        this.setState({
            hasCampaignFormError,
        });
    };

    saveCampaign = (dailyBudget, adMessage) => {
        const {
            createCampaign,
            params: { shopId },
            suggestions,
            goToNextStep,
        } = this.props;

        const params = {
            shopId,
            campaign_type: RETARGETING,
            daily_budget: +dailyBudget,
            messages: adMessage,
        };

        if (!suggestions.campaignSuggestionsSuccess || !dailyBudget) return;

        createCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                this.setState({ isLoading: true });
                goToNextStep(shopId);
            } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                this.setState({ isLoading: false });
                this.setState({
                    saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                });
            }
        });
    };

    refreshMinBudgetAndAdPreview = () => {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });
        this.requestAdPreview();
    };

    changeTipsErrorText = (tipsText) => {
        this.setState({
            tipsErrorText: tipsText,
        });
    };

    handleAdMessageTagsHasError = (tipsErrorText) => {
        this.setState({
            adMessageTagsHasError: !!tipsErrorText,
            adMessageTagsHasErrorTipsErrorText: tipsErrorText,
        });
    };

    render() {
        const campaignFormName = 'retargetingCampaignCreate';
        const {
            shopValidateRequesting,
            isShopErrorExist,
            params: { shopId },
            suggestions: { campaignSuggestionsData },
            currentShop,
            bulkNotificationContent,
            bulkNotificationType,
        } = this.props;
        const {
            campaignBudget,
            campaignAdMessage,
            campaignCurrency,
            campaignMinBudget,
            adMessageTagsHasError,
            hasCampaignFormError,
            adMessageTagsHasErrorTipsErrorText,
            isPublishPage,
            tipsErrorText,
            saveCampaignError,
            isLoading,
        } = this.state;

        let bulkNotification = null;

        if (bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        const isMigratedUser = campaignSuggestionsData ? campaignSuggestionsData.migrated_user : false;

        return (
            <div className="retargeting-campaign-create__wrapper">
                <NotificationMigratedUserBanner isMigratedUser={isMigratedUser} />

                <Button className="retargeting-campaign-create__back-button" innerUrl={`/${shopId}/dashboard`}>
                    <ArrowLeft /> Back
                </Button>

                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            campaignTitle="Facebook Retargeting"
                            linkCaption="Learn more"
                            learnMoreLink="https://help.adwisely.com/en/articles/1879301-standard-retargeting"
                            showPricing
                            showIcon
                            shopId={shopId}
                            campaignDescription="Re-engage your store visitors & cart abandoners with personalized ads on Facebook and Instagram"
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={campaignAdMessage}
                            campaignCurrency={campaignCurrency}
                            campaignMinBudget={campaignMinBudget}
                            campaignBudget={campaignBudget}
                            pricingAdviceItems={
                                campaignSuggestionsData && campaignSuggestionsData.daily_budget
                                    ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                                    : []
                            }
                            saveCampaign={this.saveCampaign}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            saveCampaignError={saveCampaignError}
                            campaignType={RETARGETING}
                        />

                        <SaveCampaignDetails
                            formName={campaignFormName}
                            buttonTitle="Run Retargeting Campaign"
                            buttonColor="primary"
                            hasCampaignFormError={
                                adMessageTagsHasError ||
                                hasCampaignFormError ||
                                !!shopValidateRequesting ||
                                isShopErrorExist
                            }
                            tipsErrorText={adMessageTagsHasErrorTipsErrorText || tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdPreview
                            pageType={pageTypes.retargetingCreate}
                            sourcePage={sourcePage.createCampaign}
                            campaignType={RETARGETING}
                            handlePreviewType={this.handlePreviewType}
                            isPublishPage={isPublishPage}
                            shopId={currentShop.id}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <LoaderFullScreen
                    isLoaderShowed={isLoading}
                >
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
                {bulkNotification}
            </div>
        );
    }
}

RetargetingCampaignCreate.defaultProps = {
    bulkNotificationContent: null,
    bulkNotificationType: null,
    isShopErrorExist: false,
}

RetargetingCampaignCreate.propTypes = {
    adAccounts: PropTypes.shape({
        connectStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                access_status: PropTypes.string,
            })
        ]),
    }).isRequired,
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    isShopErrorExist: PropTypes.bool,
    shopValidateRequesting: PropTypes.bool.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsSuccess: PropTypes.bool.isRequired
    }).isRequired,
}

const mapStateToProps = (state) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);

    return {
        suggestions: getCampaignSuggestionsSelector(state),
        shopValidateRequesting: isShopValidateRequesting(state),
        isShopErrorExist: getShopErrorTokenSelector(state),
        currentShop,
        isStaffPlatformPlan: isStaffPlatformPlanSelector(currentShop),
        shops: state.shops,
        bulkNotificationContent: getBulkNotificationContentSelector(state),
        bulkNotificationType: getBulkNotificationTypeSelector(state),
        adAccounts: getAdAccountsSelector(state),
    };
};

const mapDispatchToProps = {
    requestAdPreview: requestAdPreviewForCampCreate,
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    goToNextStep: goToDashboard,
    clearCampaignError,
    showBulkNotification: displayBulkNotification,
    checkShopForErrors: validateShop,
    requestSendEventMetrics,
    createCampaign: requestCreateCampaign,
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(RetargetingCampaignCreate);
