import {
    LOGIN_LINK_REQUEST,
    LOGIN_LINK_SUCCESS,
    LOGIN_LINK_FAILURE,
    LOGIN_CONFIRM_REQUEST,
    LOGIN_CONFIRM_SUCCESS,
    LOGIN_CONFIRM_FAILURE,
} from '../../actions/login';

const confirmRequestState = {
    confirmRequest: false,
    confirmSuccess: false,
    confirmFailure: false,
    confirmError: null,
};

const loginRequestState = {
    loginLink: '',
    loginLinkError: null,
    loginLinkRequest: false,
    loginLinkSuccess: false,
    loginLinkFailure: false,
};

const initialState = {
    ...confirmRequestState,
    ...loginRequestState,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_CONFIRM_REQUEST:
            state = {
                ...state,
                ...confirmRequestState,
                confirmRequest: true,
            };
            break;
        case LOGIN_CONFIRM_SUCCESS:
            state = {
                ...state,
                ...confirmRequestState,
                confirmSuccess: true,
            };
            break;
        case LOGIN_CONFIRM_FAILURE:
            state = {
                ...state,
                ...confirmRequestState,
                confirmFailure: true,
                confirmError: action.payload,
            };
            break;

        case LOGIN_LINK_REQUEST:
            state = {
                ...state,
                ...loginRequestState,
                loginLinkRequest: true,
            };
            break;
        case LOGIN_LINK_SUCCESS:
            state = {
                ...state,
                ...loginRequestState,
                loginLinkSuccess: true,
                loginLink: action.payload,
            };
            break;
        case LOGIN_LINK_FAILURE:
            state = {
                ...state,
                ...loginRequestState,
                loginLinkFailure: true,
                loginLinkError: action.payload,
            };
            break;

        default:
    }
    return state;
};
