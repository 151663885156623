const initialPollingInterval = 3000;
const maxPollingInterval = 30000;
const pollingIntervalMultiplier = 2;

export const statePollingInterval = (
    fetchOnboarding,
    currentOnboarding,
    shopId,
    onCompleteCb = () => {},
    processingCb = () => {},
    onNotAuthCb = () => {},
    signal
) => {
    let pollingInterval = initialPollingInterval;
    let dontStartNextPolling = false;
    const request = () => {
        fetchOnboarding(shopId, currentOnboarding, signal).then(res => {
            const [status, response] = res;

            if (status === 200) {
                response.then(data => {
                    if (data?.processing) {
                        processingCb(data);
                        if (!dontStartNextPolling) {
                            setTimeout(request, pollingInterval);
                        }
                    } else {
                        onCompleteCb(data)
                    }
                })
            } else if (status === 401) {
                onNotAuthCb()
            } else if (status >= 500) {
                const nextPollingInterval = pollingInterval * pollingIntervalMultiplier;
                pollingInterval = nextPollingInterval < maxPollingInterval ? nextPollingInterval : maxPollingInterval;
                if (!dontStartNextPolling) {
                    setTimeout(request, pollingInterval);
                }
            }
        }).catch(error => {
            if (error.name === "AbortError") {
                return "Request Aborted ";
            }
            return error;
        })
    }

    setTimeout(request, pollingInterval);

    return {
        stopPolling: () => {
            dontStartNextPolling = true;
        }
    }
}