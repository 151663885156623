import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { Link } from 'react-router';

import { ROUTES } from 'constants/routes';

import intercom from 'libs/intercom';
import { removeAccount } from 'actions/account';
import { logoutFunc, setLocalStorageObjectItem } from 'helpers';
import { getCurrentShopSelector } from 'helpers/selectors';
import { addGDPRBanner } from "helpers/gdpr";
import { requestSendEventMetrics } from 'actions/shop';
import { changeShopPage, metricType } from 'constants/metric';
import pageNameSetter from 'decorator/pageNameSetter';

import FacebookPageLayout from 'modules/facebook/components/FacebookPageLayout';
import ShopifyStoreListWidget from 'components/ShopifyStoreListWidget';
import ConnectEmailFB from 'components/ConnectEmailFB/ConnectEmailFB';

import './ChooseShop.css';
import defaultStoreIcon from './default-store-icon.png';

class ChooseShop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopActiveLink: null,
            chosenShopId: null,
        };

        this.chooseShopSendEventMetrics = this.chooseShopSendEventMetrics.bind(this);
        this.getActiveShopLink = this.getActiveShopLink.bind(this);
        this.getNewestShop = this.getNewestShop.bind(this);
        this.logOutHandler = this.logOutHandler.bind(this);
    }

    componentDidMount() {
        this.getNewestShop();
        addGDPRBanner();
    }

    getNewestShop() {
        const { replace, firstInstall, shops } = this.props;
        const shopList = shops.entities;

        if (shopList.length === 1) {
            if (shopList[0] && shopList[0].rav2_migration_onboarding) {
                replace(`/ra3-migration/${shopList[0].id}`);
            }
            if (firstInstall) {
                replace(`/${shopList[0].id}/onboarding`);
                return;
            }
            replace(`/${shopList[0].id}/`);
        } else if (shopList.length === 0) {
            replace(ROUTES.facebookLogin);
        }

        const newestShop = shopList.reduce((prevShop, shop) => {
            if (!prevShop) return shop;
            return shop.id > prevShop.id ? shop : prevShop;
        }, 0);
        let newestShopActiveLink = newestShop.id;

        if (newestShop.rav2_migration_onboarding) {
            newestShopActiveLink = `ra3-migration/${newestShop.id}`;
        }

        this.setState({
            shopActiveLink: newestShopActiveLink,
            chosenShopId: newestShop.id,
        });
    }

    getActiveShopLink(id) {
        if (this.props.firstInstall) {
            this.setState({
                shopActiveLink: `${id}/onboarding`,
                chosenShopId: id,
            });
        } else {
            this.setState({
                shopActiveLink: id,
                chosenShopId: id,
            });
        }
        this.migrateRa3Middleware(id);
    }

    migrateRa3Middleware(id) {
        const { shops } = this.props;
        const currentShop = getCurrentShopSelector(shops, id);
        if (currentShop && currentShop.rav2_migration_onboarding) {
            this.setState({
                shopActiveLink: `ra3-migration/${id}`,
                chosenShopId: id,
            });
        }
    }

    chooseShopSendEventMetrics() {
        const { requestSendEventMetrics, pageTitle } = this.props;
        const { chosenShopId } = this.state;
        const metricProps = {
            shop_id: chosenShopId,
            type: metricType.switchedToAnotherStore,
            props: {
                source: pageTitle.toString().toLowerCase().split(' ').join('_'),
            },
        };
        requestSendEventMetrics(metricProps);
        setLocalStorageObjectItem('activeTab', '0');
    }

    logOutHandler() {
        const { pageTitle, requestSendEventMetrics, logout, inframe, replace } = this.props;
        const { chosenShopId } = this.state;
        const metricProps = {
            shop_id: chosenShopId,
            type: metricType.loggedOut,
            props: {
                source: pageTitle.toString().toLowerCase().split(' ').join('_'),
            },
        };
        requestSendEventMetrics(metricProps);
        logoutFunc(logout, inframe, replace, intercom);
    }

    render() {
        const { shops, accountInfo } = this.props;
        const { shopActiveLink, chosenShopId } = this.state;
        const shopList = shops.entities;
        let FBLayoutInner;
        let LoggedInAs = null;
        let loginViewTitle = <h1 className="login-view__title">Choose a store</h1>;

        const logoutButton = (
            <button className="fb-login-logout-button" onClick={this.logOutHandler}>
                Log out
            </button>
        );

        if (accountInfo && Object.keys(accountInfo).length) {
            LoggedInAs = (
                <p className="login-view__description logged-as-text">
                    You are logged in as{' '}
                    <span>
                        {accountInfo.first_name} {accountInfo.last_name}
                    </span>
                    . {logoutButton}
                </p>
            );
        }

        if (accountInfo?.email && Object.keys(accountInfo?.email)?.length === 0) {
            FBLayoutInner = (
                <div>
                    <ConnectEmailFB />
                </div>
            );
            loginViewTitle = <h1 className="login-view__title">Log In</h1>;
        } else if (shopList.length > 0) {
            FBLayoutInner = (
                <div>
                    <div className="login-view__description">Choose a store:</div>
                    <div className="login-view__dropdown">
                        <div className="login-view__dropdown-store-icon">
                            <img src={defaultStoreIcon} alt="default store icon" />
                        </div>
                        <ShopifyStoreListWidget
                            shopsList={shopList}
                            chosenShopifyStoreId={chosenShopId}
                            getActiveShopLink={this.getActiveShopLink}
                        />
                    </div>
                    <Link
                        to={`/${shopActiveLink}`}
                        onClick={this.chooseShopSendEventMetrics}
                        className="choose-shop-login__button button_primary"
                    >
                        Continue
                    </Link>
                </div>
            );
        }
        return (
            <FacebookPageLayout>
                {loginViewTitle}
                <div className="login-view__white-substrate choose-shop">{FBLayoutInner}</div>
                {LoggedInAs}
            </FacebookPageLayout>
        );
    }
}

const mapStateToProps = ({ shops, account, onboarding, pageTitle }) => ({
    shops,
    firstInstall: shops.installation_type === 'first_install',
    accountInfo: account.entity,
    inframe: onboarding.inframe.inframe,
    pageTitle,
});

const mapPropsToDispatch = {
    logout: removeAccount,
    replace,
    requestSendEventMetrics,
};

export default pageNameSetter(changeShopPage.choose_shop)(connect(mapStateToProps, mapPropsToDispatch)(ChooseShop));
