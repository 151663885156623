import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { logException } from 'libs/sentryConfig';

import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import Button from 'components/Button';
import ConnectedAssets from 'modules/onboarding/components/ConnectedAssets';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';
import { AdAccountDropdown } from 'modules/onboarding/components/AdAccountDropdown';

import './ChooseOrCreateAdAccount.css';

const ChooseOrCreateAdAccount = ({ currentShopId, replace, status, notification }) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const [isLoading, setIsLoading] = useState(false);
    const [isAdAccountsPending, setIsAdAccountsPending] = useState(false);
    const [selectedAdAccountId, setSelectedAdAccountId] = useState(null);
    const [adAccounts, setAdAccounts] = useState([]);

    const [isChooseOpen, setIsChooseOpen] = useState(true);
    const [isCreateOpen, setIsCreateOpen] = useState(false);

    const handleDropdowns = () => {
        setIsChooseOpen(!isChooseOpen);
        setIsCreateOpen(!isCreateOpen);
    };

    useEffect(() => {
        setIsAdAccountsPending(!isAdAccountsPending);
        currentOnboarding && fetch(`/api/onboarding/${currentShopId}/${currentOnboarding}/ad_accounts`)
            .then((res) => {
                if (!res.ok) {
                    const err = new Error(`Status code: ${res.status}`);
                    err.status = res.status;
                    err.statusText = res.statusText;
                    throw err;
                }
                return res.json();
            })
            .then((res) => {
                if (!(res instanceof Error)) {
                    (res.data?.length === 1) ? setSelectedAdAccountId(res.data[0]?.id) : null;
                    setAdAccounts(res.data);
                    setIsAdAccountsPending(!isAdAccountsPending);
                }
            })
            .catch((error) => {
                logException(
                    `Couldn't fetch Ad Accounts. Reason: ${error.statusText}`,
                    error,
                    { currentShopId },
                    'javascript',
                    'error',
                );
            });
    }, []);

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: status,
            currentShopId,
            data: isChooseOpen ? { ad_account_fb_id: selectedAdAccountId } : {},
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
    }

    const onChange = (adAccountId) => setSelectedAdAccountId(adAccountId);

    const chosenAdAccount = adAccounts?.find((adAccount) => +adAccount?.id === +selectedAdAccountId);

    return (
        <div className="connect-ad-account__wrapper">
            <h1 className="connect-ad-account__title">Please choose or create an account</h1>

            <div className="connect-ad-account__body-wrapper">
                <div className="connect-ad-account__dropdown-wrapper">
                    {notification && notification}
                    <AdAccountDropdown
                        onClick={handleDropdowns}
                        openDropdown={isChooseOpen}
                        title="Choose Ad Account"
                        type={!isChooseOpen ? 'default' : 'selected'}
                        renderContent={() => (<>
                            <p>Select Ad account from the following list to continue with onboarding.</p>
                            <DropdownProvider>
                                {({ toggle, isOpen }) => {
                                    const dropdownItems = adAccounts?.map((adAccount, key) => (
                                        <DropdownItemMain
                                            key={key}
                                            value={adAccount.id}
                                            onClick={() => {
                                                onChange(adAccount.id);
                                                toggle();
                                            }}
                                            active={+adAccount.id === +selectedAdAccountId}
                                            title={`${adAccount.name} [ID: ${adAccount.id}]`}
                                            subtitle={adAccount.status}
                                            disabled={!adAccount.can_connect}
                                        />
                                    ));
                                    const flagToggler = (
                                        <FlagToggler
                                            title={chosenAdAccount ? `${chosenAdAccount.name} [ID: ${chosenAdAccount.id}]` : null}
                                            subtitle={chosenAdAccount ? chosenAdAccount.status : null}
                                            caseIcon
                                        >
                                            Select Ad Account
                                        </FlagToggler>
                                    )
                                    return (
                                        <Dropdown toggle={toggle} isOpen={isOpen} flagToggler={flagToggler} items={dropdownItems} />
                                    )
                                }
                                }
                            </DropdownProvider>
                        </>)}
                    />

                    <br />

                    <AdAccountDropdown
                        onClick={handleDropdowns}
                        title="Create Ad Account"
                        openDropdown={isCreateOpen}
                        type={!isCreateOpen ? 'default' : 'selected'}
                        renderContent={() => (
                            <div>Press button down below to create a new Ad Account</div>
                        )}
                    />

                    <br />

                    <Button
                        onClick={handleContinueBtn}
                        color="primary"
                        disabled={
                            (!isCreateOpen && (!selectedAdAccountId || !chosenAdAccount?.can_connect)) ||
                            (isCreateOpen && status === UBM_ONBOARDING_STATES.choose_ad_account)
                        }
                    >
                        {isCreateOpen ? 'Create' : 'Continue'}
                    </Button>
                </div>
                <div>
                    <ConnectedAssets />
                    <BookACallSection />
                </div>
            </div>

            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace
};

export default connect(mapStateToProps, mapPropsToDispatch)(ChooseOrCreateAdAccount);

