import * as campaignActions from '../actions/campaign';
import { DEFAULT_FILTER_CAMPAIGN } from '../constants/campaign';

const initialState = {
    campaignDetailsData: [],
    googleFilterCampaignId: DEFAULT_FILTER_CAMPAIGN,
    facebookFilterCampaignId: DEFAULT_FILTER_CAMPAIGN,
    campaignDetailsPending: false,
    campaignDetailsSuccess: false,
    campaignDetailsFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.SHOP_CAMPAIGNS_REQUEST:
            return {
                ...state,
                campaignDetailsSuccess: false,
                campaignDetailsPending: true,
                campaignDetailsFailure: false,
            };

        case campaignActions.FILTER_FACEBOOK_CAMPAIGN_ID:
            return {
                ...state,
                facebookFilterCampaignId: action.payload,
            };

        case campaignActions.FILTER_GOOGLE_CAMPAIGN_ID:
            return {
                ...state,
                googleFilterCampaignId: action.payload,
            };

        case campaignActions.SHOP_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaignDetailsSuccess: true,
                campaignDetailsPending: false,
                campaignDetailsFailure: false,
                campaignDetailsData: action.payload,
            };

        case campaignActions.SHOP_CAMPAIGNS_FAILURE:
            return {
                ...state,
                campaignDetailsSuccess: false,
                campaignDetailsFailure: true,
                campaignDetailsPending: false,
            };

        case campaignActions.CAMPAIGN_CREATE_REQUEST:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData,
                campaignDetailsPending: true,
            };

        case campaignActions.CAMPAIGN_CREATE_SUCCESS:
            return {
                ...state,
                campaignDetailsData: [...state.campaignDetailsData, action.payload],
                campaignDetailsSuccess: true,
            };

        case campaignActions.CAMPAIGN_CREATE_FAILURE:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData,
                campaignDetailsFailure: true,
            };

        case campaignActions.CAMPAIGN_EDIT_REQUEST:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...campaign,
                            campaignEditPending: true,
                            campaignEditSuccess: false,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_EDIT_SUCCESS:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...campaign,
                            ...action.payload,
                            campaignEditPending: false,
                            campaignEditSuccess: true,
                            campaignEditFailure: false,
                            campaignFailureError: null,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_EDIT_FAILURE:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.meta.params.id) {
                        return {
                            ...campaign,
                            campaignEditPending: false,
                            campaignEditSuccess: false,
                            campaignEditFailure: true,
                            campaignFailureError: action.payload.errorToken,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_PAUSE_REQUEST:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...campaign,
                            campaignPausePending: true,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_PAUSE_SUCCESS:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...action.payload,
                            campaignPausePending: false,
                            campaignFailureError: null,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_PAUSE_FAILURE:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.meta.params.id) {
                        return {
                            ...campaign,
                            campaignPausePending: false,
                            campaignFailureError: action.payload.errorToken,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_RESUME_REQUEST:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...campaign,
                            campaignResumePending: true,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_RESUME_SUCCESS:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.payload.id) {
                        return {
                            ...action.payload,
                            campaignResumePending: false,
                            campaignFailureError: null,
                        };
                    }
                    return campaign;
                }),
            };

        case campaignActions.CAMPAIGN_RESUME_FAILURE:
            return {
                ...state,
                campaignDetailsData: state.campaignDetailsData.map((campaign) => {
                    if (campaign.id === action.meta.params.id) {
                        return {
                            ...campaign,
                            campaignResumePending: false,
                            campaignFailureError: action.payload.errorToken,
                        };
                    }
                    return campaign;
                }),
            };

        default:
            return state;
    }
};
