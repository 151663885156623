import { useState } from 'react';

export const useCountriesFilter = () => {
    const [countryFilter, setCountryFilter] = useState('');

    const handleCountriesSearch = ({ target }) => {
        setCountryFilter(target.value?.toLowerCase());
    };

    const getSearchedCountries = (countries) =>
        countries?.filter((country) => {
            if (countryFilter.length > 1) {
                return country.countryNameEn.toLowerCase().includes(countryFilter) && country;
            }

            return country;
        });

    const resetCountriesFilter = () => {
        setCountryFilter('');
    };

    return { resetCountriesFilter, handleCountriesSearch, getSearchedCountries };
};
