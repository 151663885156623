import {useEffect, useState} from 'react';

export const useGetLastActiveSubcription = (shopId) => {
    const [lastActiveSubscription, setLastActiveSubscription] = useState({
        name: '',
        spends_limit: '',
        fee: '',
        version: '',
        duration: ''

    });

    useEffect(() => {
        fetch(`/api/shop/${shopId}/subscription?last-active=true`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((res) =>  res.json())
          .then((data) => {
            if (data.data.subscription_plan) {
                const {name, spends_limit, fee, version, duration} = data.data.subscription_plan
                setLastActiveSubscription({name, spends_limit, fee, version, duration})
            }
        })
    }, [])

    return lastActiveSubscription;
}