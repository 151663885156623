import * as campaignActions from '../actions/campaign';

const initialState = {
    googleRemarketingAdPreviewData: [],
    googleRemarketingAdPreviewPending: false,
    googleRemarketingAdPreviewSuccess: false,
    googleRemarketingAdPreviewFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.GOOGLE_REMARKETING_AD_PREVIEW_REQUEST:
            return {
                ...state,
                googleRemarketingAdPreviewSuccess: false,
                googleRemarketingAdPreviewPending: true,
                googleRemarketingAdPreviewFailure: false,
                googleRemarketingAdPreviewData: [],
            };

        case campaignActions.GOOGLE_REMARKETING_AD_PREVIEW_SUCCESS:
            return {
                ...state,
                googleRemarketingAdPreviewSuccess: true,
                googleRemarketingAdPreviewPending: false,
                googleRemarketingAdPreviewFailure: false,
                googleRemarketingAdPreviewData: [...state.googleRemarketingAdPreviewData, ...action.payload],
            };

        case campaignActions.GOOGLE_REMARKETING_AD_PREVIEW_FAILURE:
            return {
                ...state,
                googleRemarketingAdPreviewSuccess: false,
                googleRemarketingAdPreviewFailure: true,
                googleRemarketingAdPreviewPending: false,
            };

        default:
            return state;
    }
};
