import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';


import { BookACallView } from "modules/onboarding/components/BookACall/BookACallView";
import pageNameSetter from "decorator/pageNameSetter";
import { changeShopPage, metricType } from "constants/metric";
import { requestSendEventMetrics } from "actions/shop";

import { changeOnboardingState } from "modules/home/utils";
import { ONBOARDING_TYPES } from "constants/onboarding";
import { TIK_TOK_ONBOARDING_STATES } from "constants/tikTokOnboarging";

const BookACallTikTok = ({ shopId, requestSendEventMetrics }) => {
    const [isCalendlyPopupOpened, setCalendlyPopupOpened] = useState(false);

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            if (e.data.event === "calendly.event_scheduled") {
                changeOnboardingState({
                    currentShopId: shopId,
                    type: ONBOARDING_TYPES.tik_tok,
                    status: TIK_TOK_ONBOARDING_STATES.book_a_call
                }).then(res => {
                    if (res.status === 200) {
                        setCalendlyPopupOpened(false);
                    }
                })
            }
        },
    });
    return (
        <>
            <BookACallView
                onBookACallClick={ () => {
                    setCalendlyPopupOpened(true)
                    const metricProps = {
                        shop_id: shopId,
                        type: metricType.btnClicked,
                        props: {
                            btn_name: 'book_a_call',
                        },
                    };
                    requestSendEventMetrics(metricProps);
                }}
            />
            <PopupModal
                onModalClose={() => {
                    setCalendlyPopupOpened(false)
                }}
                open={isCalendlyPopupOpened}
                rootElement={document.getElementById("root")}
                url={`https://calendly.com/adwisely-support-team/15min?month=${year}-${month}`} />
        </>

    )
}

const mapStateToProps = ({ shops } ) => (
    {
        shopId: shops.currentShopId,
    }
)

const mapDispatchToProps = {
    requestSendEventMetrics,
}

export default pageNameSetter(changeShopPage.book_a_call)(
    connect(mapStateToProps, mapDispatchToProps)(BookACallTikTok)
);