import React from 'react';
import classNames from 'classnames';

import './AlertWidget.css';

const ALERT_COLORS = {
    light: 'alert__wrapper_light',
    info: 'alert__wrapper_info',
    warning: 'alert__wrapper_warning',
    danger: 'alert__wrapper_danger',
};

/**
 *
 * @param color {array}
 * @param onClose {function}
 * @param children {object}
 * @param withTopMargin {boolean}
 * @constructor
 */
const AlertWidget = ({ color, withTopMargin, onClose, children }) => (
    <div
        className={classNames('alert__wrapper', ALERT_COLORS[color], {
            'alert__wrapper_with-top-margin': withTopMargin,
        })}
    >
        <div className="alert__description">{children}</div>

        {onClose ? (
            <button className="alert-description__button-close" onClick={onClose}>
                Close
            </button>
        ) : (
            ''
        )}
    </div>
);

export default AlertWidget;
