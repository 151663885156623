import React, { PureComponent } from 'react';
import { Link } from 'react-router';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

import { fixedItButton } from 'helpers/bannerHelper';

/*
 Allow to call function "fn" once depend of key passed
 */
const once = (function () {
    let calledFunction = {};
    return {
        run: (functionKey, fn) => {
            if (functionKey in calledFunction) return;
            calledFunction[functionKey] = true;
            fn();
        },
        clear: () => {
            calledFunction = {};
        },
    };
}());

class CampaignSettingsErrorBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.TipsErrorText = '';
        this.hasCampaignFormError = false;
        this.isPublishPage = true;
        this.getCampaignSettingsErrorReasonBanner = this.getCampaignSettingsErrorReasonBanner.bind(this);
    }

    componentDidMount() {
        const { changeTipsErrorText, handleFormError, changePublishPage } = this.props;
        if (changeTipsErrorText && this.TipsErrorText) changeTipsErrorText(this.TipsErrorText);
        if (handleFormError && this.hasCampaignFormError) handleFormError(this.hasCampaignFormError);
        if (changePublishPage && this.hasCampaignFormError) changePublishPage(this.hasCampaignFormError);
    }

    componentWillUnmount() {
        once.clear();
    }

    getCampaignSettingsErrorReasonBanner() {
        const { shopError, shopId, checkShopForErrors, requestShops } = this.props;
        let notificationData = null;
        let bannerTitle = null;
        let bannerContent = null;
        let bannerButtons = null;
        if (!shopError.errorData) shopError.errorData = {};
        switch (shopError.errorToken) {
            case 'public-page-not-found':
                bannerTitle = <p className="notification__title">Publish Facebook page</p>;
                bannerContent = (
                    <p className="notification__description">
                        The Facebook page connected to your campaign is not published.
                        Only published Facebook pages can be used for running ads. Click{' '}
                        <span className="notification__caption">Publish page</span> or choose a different page to run a
                        campaign.
                    </p>
                );
                bannerButtons = (
                    <div>
                        <Button
                            url={`https://www.facebook.com/${shopError.errorData.page_id || ''}/settings/?tab=settings`}
                            color="transparent-bordered"
                        >
                            Go to Page Settings
                        </Button>

                        <Link
                            to={`/${shopId}/settings`}
                            className="notification__link notification__link_with-left-margin"
                        >
                            Choose another page
                        </Link>
                        {!!checkShopForErrors && fixedItButton(checkShopForErrors)}
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.TipsErrorText =
                    'To launch a campaign, you need to make the connected Facebook page ' +
                    'published or choose another page.';
                this.hasCampaignFormError = true;
                this.isPublishPage = false;
                break;

            case 'platform-doesnt-have-access-to-shop': {
                bannerTitle = <p className="notification__title">Campaign can’t be launched</p>;

                bannerContent = (
                    <p className="notification__description">
                        It appears that you removed Adwisely application from{' '}
                        {(shopError.errorData && shopError.errorData.shop_name) || ''} store. To run and manage your
                        campaign, you need to <span className="font-weight-bold">install Adwisely</span>.
                    </p>
                );

                bannerButtons = (
                    <Button
                        url="https://apps.shopify.com/adwisely#login"
                        color="transparent-bordered"
                    >
                        Install Adwisely
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                if (requestShops) {
                    once.run('redirect', () =>
                        setTimeout(() => {
                            requestShops();
                        }, 15000),
                    );
                }

                this.hasCampaignFormError = true;
                this.TipsErrorText = 'To run and manage your campaign, you need to install Adwisely.';
                break;
            }
            default:
                break;
        }
        return notificationData;
    }

    render() {
        const campaignSettingsErrorArray = this.getCampaignSettingsErrorReasonBanner();
        if (campaignSettingsErrorArray) {
            return (
                <div>
                    <NotificationWidget type="danger">
                        {campaignSettingsErrorArray.map((notificationItem, key) => (
                            <span key={key}>{notificationItem}</span>
                        ))}
                    </NotificationWidget>
                </div>
            );
        }
        return null;
    }
}

export default CampaignSettingsErrorBanner;
