import { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { ROUTES } from 'constants/routes';

export class OnboardingIndex extends Component {
    componentDidMount() {
        const {
            replace,
            account,
            params: { shopId },
        } = this.props;

        if (!account.entity) {
            replace(`/${shopId}/${ROUTES.facebookLogin}`);
        }
    }

    render() {
        return null;
    }
}

const props = ({ account, shopsCampaignsSettings }) => ({
    account,
    shopsCampaignsSettings,
});

export default connect(props, { replace })(OnboardingIndex);
