import React, { useState } from 'react';

import { Card, CardContent, CardTitle } from 'components/Card';
import { useResumeSubscription } from 'hooks/useResumeSubscription';

import { CancelPlanModal } from "./components/CancelPlanModal/CancelPlanModal";
import { ResumePlanModal } from "./components/ResumePlanModal/ResumePlanModal";

const BillingStatus = ({ shopId, replace, subscriptionPlan, requestShopSubscription, push }) => {
    const [isCancelPlanModalVisible, setIsCancelPlanModalVisible] = useState(false);
    const [isResumePlanModalVisible, setIsResumePlanModalVisible] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const isMonthly = subscriptionPlan?.duration === 30;
    const { resumeSubscriptionFetch } = useResumeSubscription(shopId);

    const onCancelPlanBtnClick = () => {
        const body = JSON.stringify({reason: cancelReason});
        fetch(`/api/shop/${shopId}/subscription/cancel`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body
        }).then(() => {
            setIsCancelPlanModalVisible(false);
            requestShopSubscription(shopId);
            setCancelReason('');
        })
    }
    return (
        <>
            <Card className="settings-page__container">
                <div className="settings-page__block-content_flex">
                    <div className="settings-page__block-content-wrapper">
                        <CardTitle>Billing</CardTitle>
                        <CardContent>
                            <p>
                                The total cost of Adwisely services is your plan fee + 10% of all the ad spend above the
                                plan limit. <br/>
                                Learn {''}
                                <a
                                    href={
                                        'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/' +
                                        'whats-the-cost-of-retargetapp'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    how billing works
                                </a>
                                .
                            </p>
                        </CardContent>
                        <CardContent className="billing-plan-statistics_block">
                            <div className="billing-plan__wrap">
                                {subscriptionPlan.name !== 'Canceled'
                                    ? (
                                        <>
                                            <p><strong>Current plan:</strong>{isMonthly && ' Monthly'} {subscriptionPlan?.name}</p>
                                            <p>{isMonthly ? <strong>Monthly fee:</strong> : <strong>Quarterly fee:</strong>} {subscriptionPlan?.fee} USD</p>
                                        </>
                                    )
                                    : (<p><strong>Current plan:</strong> Not selected</p>)
                                }

                            </div>
                            <div className="billing-plan__wrap">
                                {
                                    isMonthly &&
                                    subscriptionPlan.name !== 'Advanced' &&
                                    subscriptionPlan.name !== 'Regular' &&
                                    subscriptionPlan.name !== 'Standard' &&
                                    <span
                                        className="billing-plan__link"
                                        onClick={() => push(`/${shopId}/upgrade-plan`)}
                                    >
                                    Change plan
                                </span>
                                }
                                {subscriptionPlan.name !== 'Canceled' ? (
                                    <button
                                        className="billing-plan__link"
                                        onClick={() => {
                                            setIsCancelPlanModalVisible(true)
                                        }}
                                    >Cancel plan</button>
                                ) : (
                                    <button
                                        className="billing-plan__link"
                                        onClick={() => {
                                            setIsResumePlanModalVisible(true)
                                            resumeSubscriptionFetch().then(() => {
                                                requestShopSubscription(shopId);
                                            })
                                        }}
                                    >Resume plan</button>)
                                }
                            </div>
                        </CardContent>
                    </div>
                </div>
            </Card>
            <CancelPlanModal
                isModalVisible={isCancelPlanModalVisible}
                onReasonInputChange={(e) => {
                    setCancelReason(e.target.value)
                }}
                onBackBtnClick={() => {
                    setIsCancelPlanModalVisible(false)
                    setCancelReason('')
                }}
                onCancelPlanBtnClick={onCancelPlanBtnClick}
                isCancelPlanBtnDisabled={cancelReason.length < 10}
            />
            <ResumePlanModal
                shopId={shopId}
                onBackBtnClick={() => {
                    setIsResumePlanModalVisible(false);
                }}
                isOpen={isResumePlanModalVisible}
                onOkBtnClick={() => {
                    replace(`/${shopId}/dashboard`)
                    setIsResumePlanModalVisible(false);
                }}
            />
        </>

    )
};

export default BillingStatus;
