import React, { Component } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

class ProspectingCampaignRejected extends Component {
    state = {
        isClosed: false,
    };

    handleGotItButton = () => {
        window.localStorage.setItem('NOTIFICATION_REJECTED_CAMPAIGN_HIDDEN', 'true');
        this.setState({
            isClosed: true,
        });
    };

    render() {
        const { campaignDetails } = this.props;
        const isVisible =
            campaignDetails.some(
                (campaign) => campaign.type === 'prospecting' && campaign.client_status === 'rejected',
            ) &&
            !this.state.isClosed &&
            !window.localStorage.getItem('NOTIFICATION_REJECTED_CAMPAIGN_HIDDEN');

        return (
            <>
                {isVisible && (
                    <NotificationWidget type="warning">
                        <p className="notification__title">Your Prospecting campaign was rejected</p>
                        {/* eslint-disable-next-line */}
                        <p className="notification__description">
                            We are already taking care of it, no worries! We will get back to you with the updates as
                            soon as possible. Meanwhile, you can contact our support team for further assistance{' '}
                            <span role="img" aria-label="raised hands">
                                🙌
                            </span>
                        </p>
                        <Button
                            className="notification__button_bigger"
                            color="transparent-bordered"
                            onClick={this.handleGotItButton}
                        >
                            Got it
                        </Button>
                    </NotificationWidget>
                )}
            </>
        );
    }
}

export default ProspectingCampaignRejected;
