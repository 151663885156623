import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.css';

const propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.element]).isRequired,
    url: PropTypes.string,
    innerUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    pending: PropTypes.bool,
    disabled: PropTypes.bool,
    large: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func,
    submit: PropTypes.bool,
    className: PropTypes.string,
    form: PropTypes.string,
    square: PropTypes.bool,
};

const defaultProps = {
    id: null,
    url: null,
    innerUrl: null,
    pending: false,
    disabled: false,
    large: false,
    square: false,
    color: null,
    onClick: null,
    submit: false,
    className: null,
    form: null,
    selfTarget: false,
};

const Button = React.forwardRef(
    (
        {
            id,
            children,
            url,
            innerUrl,
            pending,
            disabled,
            large,
            medium,
            color,
            onClick,
            submit,
            className,
            form,
            square,
            selfTarget,
        },
        ref,
    ) => {
        const classN = classNames(
            className,
            'button',
            pending && 'button_pending',
            disabled && 'button_disabled',
            color === 'primary' && 'button_primary',
            color === 'danger' && 'button_danger',
            color === 'transparent' && 'button_transparent',
            color === 'light' && 'button_light',
            large && 'button_large',
            medium && 'button_medium',
            square && 'button_square',
            color === 'link' && 'button_link button_transparent',
            color === 'transparent-bordered' && 'button_transparent-bordered',
            color === 'transparent-no-border' && 'button_transparent-no-border',
            color === 'transparent-bordered-white' && 'button_transparent-bordered-white',
            color === 'green' && 'button_green',
            color === 'yellow' && 'button_yellow',
        );
        if (url) {
            return (
                <a
                    href={url}
                    target={selfTarget ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                    id={id}
                    className={classN}
                    onClick={onClick}
                    ref={ref}
                    disabled={disabled}
                >
                    {children}
                </a>
            );
        }
        if (innerUrl) {
            return (
                <Link
                    to={!disabled ? innerUrl : null}
                    id={id}
                    className={classN}
                    onClick={onClick}
                    disabled={disabled}
                    ref={ref}
                >
                    {children}
                </Link>
            );
        }
        return (
            <button
                id={id}
                className={classN}
                onClick={onClick}
                disabled={disabled}
                type={submit ? 'submit' : 'button'}
                form={form}
                ref={ref}
            >
                {children}
            </button>
        );
    },
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
