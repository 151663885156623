import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { Toggler } from "components/Toggler/Toggler";

import TipsWidget from "components/TipsWidget";
import sneekerImg from "modules/home/images/sneeker.jpg";
import placeholderImage from "./img/placeholder-image.jpg";

import './AdsPreviewSection.css';

import PreviewSlider from "./components/PreviewSlider/PreviewSlider";

const defaultProduct = {
    title: 'Sneakersclub',
    description: 'Either or not a sneaker die-hard, our varied selections of sneakers offer versatile options to complement any ensemble and cater to endless wearing contexts.',
    subtitle: 'Nothing great is made alone',
    img: sneekerImg,
    url: 'SNEAKERSCLUB.COM',
    price: 0
}

const transformPreviewAdMessage = (msg, product, currency) => {
    const titleReg = new RegExp(/{{product.name}}/, 'g');
    const priceReg = new RegExp(/{{product.price}}/, 'g');
    let newMessage = '';
    if (product && msg) {
        const { price, title } = product;
        newMessage = msg.replace(titleReg, title);
        if (price) {
            newMessage = newMessage.replace(priceReg, `${price} ${currency}`)
        }
    }
    return newMessage;
}

const AdsPreviewSection = ({
        additionalClass = '',
        currentActiveCard,
        isMobilePreview,
        setIsMobilePreview,
        previewAdMessage = '',
        isTogglerVisible = true,
        isShopify,
        shopId,
        currency,
        isHomePage = false,
        featureFlags
    }) => {
    const [product, setProduct] = useState(
        {
            title: '',
            img: '',
            description: '',
            url: '',
            price: '',
            subtitle: ''
        }
    );
    const { adsPreviewOnHomePage } = featureFlags;

    useEffect(() => {
        if ((!isHomePage || adsPreviewOnHomePage) && isShopify) {
            fetch(`/api/shops/${shopId}/best-selling-product`, {method: 'GET'})
                .then(res => res.json())
                .then(data => {
                    if (!data.data.error) {
                        const { image, description, title, link, price } = data.data
                        const url = link.split('/')[2];
                        setProduct({
                            title,
                            img: image || placeholderImage,
                            description: description || 'Your text here...',
                            url,
                            price: price || 'Product price here',
                            subtitle: defaultProduct.subtitle
                        })
                    } else {
                        setProduct(defaultProduct)
                    }
                })
                .catch(() => {
                    setProduct(defaultProduct);
                })
        } else {
            setProduct(defaultProduct);
        }
    }, [])

    useEffect(() => {
        if (previewAdMessage) {
            setProduct({
                ...product,
                description: transformPreviewAdMessage(previewAdMessage, product, currency)
            });
        }

    }, [previewAdMessage, product.description])


    return (
        <div className={`home-preview ${additionalClass}`}>
            {isHomePage && (
                <div className="home-preview__badge">Ad previews</div>
            )}
            <div className={classNames('home-preview__top', {'home-preview__top--home': isHomePage})}>
                <div className='home-preview__title-wrapper'>
                    {!isHomePage && (
                        <div className='home-preview__title'>Ad examples</div>
                    )}
                    <div className='home-preview__info'>
                        <TipsWidget placement="bottom" isDarkView>
                            {isShopify ?
                                <p>Preview features your best-selling products.
                                    The actual ad will contain the products that your
                                    potential customers were interested in</p>
                                :
                                <p>You will see the preview of the ads with your
                                    products during the campaign setup</p>
                            }
                        </TipsWidget>
                    </div>
                </div>
                {isTogglerVisible &&
                    <Toggler
                        isChecked={isMobilePreview}
                        id='preview-checkbox'
                        onChange={() => {
                            setIsMobilePreview(!isMobilePreview)
                        }}>
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 0H1.2C0.54 0 0 0.54 0 1.2V8.4C0 9.06 0.54 9.6 1.2
                                                9.6H5.4L4.2 11.4V12H9V11.4L7.8 9.6H12C12.66 9.6 13.2 9.06 13.2
                                                8.4V1.2C13.2 0.54 12.66 0 12 0ZM12 7.2H1.2V1.2H12V7.2Z"
                                fill="currentColor"
                            />
                        </svg>
                        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.09006 0.00590908L1.17675 0C0.526285 0 0 0.531818 0 1.18182V11.8182C0 12.4682 0.526285 13
                                                1.17675 13H7.09006C7.74053 13 8.27273 12.4682 8.27273 11.8182V1.18182C8.27273 0.531818 7.74053
                                                0.00590908 7.09006 0.00590908ZM7.09006 10.6364H1.17675V2.36364H7.09006V10.6364Z"
                                fill="currentColor"
                            />
                        </svg>
                    </Toggler>
                }

            </div>
            <div className='home-preview__slider-wrapper'>
                {product &&
                    <PreviewSlider
                        currentType={currentActiveCard}
                        isMobile={isMobilePreview}
                        product={product}
                    />
                }

            </div>
        </div>
    )
}

const mapStateToProps = ({shops, shopBillingInfo, featureFlags}) => ({
    shopId: shops?.currentShopId,
    currency: shopBillingInfo?.currency,
    featureFlags
});

const mapPropsToDispatch = {};

export default connect(mapStateToProps, mapPropsToDispatch)(AdsPreviewSection)
