import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { requestAccountInfo } from 'actions/account';
import { tokenActionTypes } from 'constants/actionTypes';

import Button from 'components/Button';

import iconSuccess from './icon-success.svg';

import './ActionTokenSuccess.css';

class ActionTokenSuccess extends PureComponent {
    componentDidMount() {
        /* necessary code, forced decision */
        const goToAccountSettingsBtn = document.getElementById('goToAccountSettings');
        if (goToAccountSettingsBtn) goToAccountSettingsBtn.focus();
    }

    render() {
        const { entity, replace, requestAccountInfo } = this.props;
        let actionTokenContent = null;
        switch (entity.action_type) {
            case tokenActionTypes.CHANGE_USER_EMAIL: {
                const { email, shop_id } = entity || {};
                actionTokenContent = (
                    <div>
                        <p className="action-success__main-message">
                            Your email has been successfully changed to {email}
                        </p>
                        <Button
                            id="goToAccountSettings"
                            innerUrl={`/${shop_id}/settings`}
                            onClick={requestAccountInfo}
                            color="transparent"
                            className="action-success__button"
                        >
                            Go to Account Settings
                        </Button>
                    </div>
                );
                break;
            }
            default:
                break;
        }

        if (!actionTokenContent) replace('/');

        return (
            <div className="action-success__wrapper">
                <div className="shopify-page-layout">
                    <div className="shopify-page-layout__container">
                        <div className="shopify-page-layout__content-wrapper action-success__block">
                            <img src={iconSuccess} alt="success" />
                            {actionTokenContent}
                        </div>
                    </div>
                    <div className="shopify-page-layout__logo-container">
                        <p className="action-success__logo" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ actionHandler }) => ({
    entity: actionHandler.entity,
});

const mapDispatchToProps = {
    requestAccountInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionTokenSuccess);
