import React from 'react';

import './ShopifyPageLayout.css';

const ShopifyPageLayout = ({ children }) => (
    <div className="shopify-page-layout">
        <div className="shopify-page-layout__container">
            <div className="shopify-page-layout__content-wrapper">{children}</div>
        </div>
        {!window.location.pathname.includes('uninstalled') && (
            <div className="shopify-page-layout__logo-container">
                <p className="shopify-page-layout__logo" />
            </div>
        )}
    </div>
);

export default ShopifyPageLayout;
