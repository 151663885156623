import ReactDOM from 'react-dom';
import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import classNames from "classnames";
import './Modal.css';

const modalRoot = document.getElementById('modal-root');

export const ModalHeader = ({children, className}) => {
    const classN = classNames(
        'c-modal-header',
        className
    )
    return (
        <div className={classN}>
            {children}
        </div>
    )
}

export const ModalBody = ({children, className}) => {
    const classN = classNames(
        'c-modal-body',
        className
    )
    return (
        <div className={classN}>
            {children}
        </div>
    )
}

export const ModalFooter = ({children, className})=> {
    const classN = classNames(
        'c-modal-footer',
        className
    )
    return (
        <div className={classN}>
            {children}
        </div>
    )
}

export const Modal = ({children, isOpen, className, centered = true}) => {
    const [runTransition, setRunTransition] = useState(false);
    const classModalInner = classNames(
        className,
        'c-modal__inner',
        centered && 'centered'
    )

    const classModalBackDrop = classNames(
        'c-modal-backdrop',
        isOpen && 'show'
    )

    useEffect(() => {
        setRunTransition(isOpen)
        if (isOpen) {
            document.body.classList.add('c-modal-open');
        } else {
            document.body.classList.remove('c-modal-open');
        }
        return () => {
            document.body.classList.remove('c-modal-open');
            setRunTransition(false);
        }
    }, [isOpen])

    return ReactDOM.createPortal(
        <>
            <CSSTransition
                in={runTransition}
                timeout={600}
                classNames="c-modal"
                unmountOnExit
            >
                <div className='c-modal'>
                    <div className={classModalInner}>
                        <div className='c-modal__content'>
                            {children}
                        </div>
                    </div>
                    <div className={classModalBackDrop} />
                </div>
            </CSSTransition>
        </>
,
        modalRoot
    )
}