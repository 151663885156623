import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { install } from 'actions/shop';
import { LoaderLongWait as Loader } from 'components/Loader';

import intercom from 'libs/intercom';
import trackAppInstalled from 'libs/trackAppInstalled';
import { ROUTES } from 'constants/routes';

import ShopifyReInstallPage from 'modules/shopify/components/ShopifyReInstallPage';
import {Icon} from 'modules/google-onboarding/ProcessingScreen/components/Icon';

import './InstallCallback.css';

const validateShopifyParams = ({ code, hmac, shop }) => code && hmac && shop;

const LINK_SHOPIFY_APP = 'https://apps.shopify.com/adwisely';

const createRequestError = () => {
    const [title, linkText] = ['App Installation', 'Install Adwisely'];
    const body = 'Something went wrong. Try installing Adwisely again.';

    return <ShopifyReInstallPage title={title} body={body} link={LINK_SHOPIFY_APP} linkText={linkText} />;
};

export class Install extends Component {
    state = {
        installed: false,
        paramsError: false,
        shopRequesting: false,
        shopRequestError: false,
    };

    componentDidMount() {
        const { location, installShop, redirect, account } = this.props;
        if (validateShopifyParams(location.query)) {
            this.setState({
                shopRequesting: true,
            });
            installShop({
                params: location.query,
            }).then((response) => {
                if (response instanceof Error) {
                    this.setState({
                        shopRequesting: false,
                        shopRequestError: true,
                        error: response,
                    });
                    return;
                }
                if (response.installation_type === 'first_install' || response.installation_type === 'reinstall') {
                    trackAppInstalled(response.shop.id)
                }
                this.setState({
                    installed: true,
                });

                if (!account.entity) {
                    redirect(`/facebook/login`);
                } else {
                    redirect(`/shop/${response?.shop?.id}/`);
                }

            });
        } else {
            this.setState({
                paramsError: true,
            });
        }
    }

    render() {
        const { location } = this.props;
        const { paramsError, shopRequestError, error } = this.state;

        if (shopRequestError) {
            return createRequestError(location.query.shop, error, this.props.redirect);
        }

        if (paramsError) {
            return createRequestError(location.query.shop, { errorToken: 'bad-params' });
        }

        if (this.state.shopRequesting) {
            return <>
                <Icon />
                <div className='install-callback__title'>Give us a minute :)</div>
                <div className='install-callback__text'>Please, don’t refresh this page.</div>
                <div className='install-callback__text'>We’re сreating your Adwisely account. It will take less than 30 seconds.</div>
            </>;
        }

        return <div />;
    }
}

const mapDispatchToProps = {
    redirect: replace,
    installShop: (params = {}) => (dispatch) =>
        new Promise((resolve) => {
            intercom.checkIntercomInited(100, 10).then((intercomVisitorId) => {
                resolve(
                    dispatch(
                        install({
                            ...params,
                            intercom_visitor_id: intercomVisitorId,
                        }),
                    ),
                );
            });
        }),
};

const props = ({ account }) => ({
    account,
});

export default connect(props, mapDispatchToProps)(Install);
