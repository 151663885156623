import { replace } from 'react-router-redux';
import { ROUTES } from 'constants/routes';
import { RETARGETING } from 'constants/campaign';
import { RETARGETING_INVITE } from 'constants/notificationType';
import withData from 'decorator/withStateData';
import RetargetingCampaignCreate from './RetargetingCampaignCreate';

export default withData(
    RetargetingCampaignCreate,
    ['shopNotifications', 'campaignDetails'],
    (data) => {
        const { campaignDetailsData } = data.campaignDetails;
        const isRetargetingCreated = campaignDetailsData.find((el) => el.type === RETARGETING);
        const isInvited = !!data.shopNotifications.entity.find((el) => el.type === RETARGETING_INVITE);
        return isInvited && !isRetargetingCreated;
    },
    (nextState) => (dispatch) => {
        const {
            params: { shopId },
        } = nextState;
        dispatch(replace(`/${shopId}${ROUTES.dashboard}`));
    },
);
