import * as pages from 'actions/facebook/pages';

import { hideLoader, showLoader } from 'actions/loader';

export default (store) => (next) => (action) => {
    const { dispatch } = store;

    switch (action.type) {
        case pages.FB_PAGES_REQUEST:
            next(action);
            dispatch(showLoader());
            break;

        case pages.FB_PAGES_REQUEST_SUCCESS:
            next(action);
            dispatch(hideLoader());
            break;

        case pages.FB_PAGES_REQUEST_FAILURE:
            dispatch(hideLoader());
            next(action);
            break;

        default:
            next(action);
            break;
    }
};
