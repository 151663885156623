import orderBy from 'lodash/orderBy';

import { DEFAULT_FILTER_CAMPAIGN, CAMPAIGNS_TYPES } from 'constants/campaign';

const defaultCampaign = { id: DEFAULT_FILTER_CAMPAIGN, name: 'All' };

const removeUnderscoreFromString = (string) => string.split('_').join(' ');

const _toDropdownGoogleCampaigns = (campaigns) => {
    const GOOGLE_CAMPAIGN = {
        'Smart Shopping Campaign': 'Smart',
        'Standard Shopping Campaign': 'Standard',
        'PERFORMANCE_MAX_CAMPAIGN': 'Performance Max',
    };

    const normalizedCampaigns = campaigns.map(({ id, type, country }) => ({
        id: id.toString(),
        name: `${GOOGLE_CAMPAIGN[type]} (${country})`,
    }));

    return [defaultCampaign, ...orderBy(normalizedCampaigns, ['name'], ['asc'])];
};

const _toDropdownFacebookCampaigns = (campaigns) => {
    const normalizedCampaigns = campaigns.map(({ id, readable_type }) => ({
        id: id.toString(),
        name: readable_type,
    }));

    return [defaultCampaign, ...orderBy(normalizedCampaigns, ['name'], ['asc'])];
};

export const getDropdownCampaigns = ({ campaignType, googleCampaigns, campaignDetailsData }) =>
    campaignType === CAMPAIGNS_TYPES.google
        ? _toDropdownGoogleCampaigns(googleCampaigns)
        : _toDropdownFacebookCampaigns(campaignDetailsData);
