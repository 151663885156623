import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'components/Button';
import intercom from 'libs/intercom';
import NotificationWidget from 'components/NotificationWidget';

const LearningPhaseBanner = ({ isCampaignInLearningPhase }) => {
    if (!isCampaignInLearningPhase) {
        return null;
    }

    return (
        <NotificationWidget type="info">
            <p className="notification__title">Your campaign is now in Learning phase</p>
            <p className="notification__description">
                Within the next 7-10 days our system will explore the best ways to deliver your ads. During the learning
                phase, you may see little to no results on the dashboard - and it’s perfectly normal.
            </p>
            <br />
            <p>
                <strong>Do not, edit or pause Adwisely-powered ads</strong> during the{' '}
                <a
                    href="https://www.facebook.com/business/help/112167992830700"
                    className="alert-description__link"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Learning phase
                </a>{' '}
                - otherwise, it will take longer.
            </p>
            <Button className="notification__button_bigger" color="green" onClick={intercom.show}>
                Contact Support
            </Button>
        </NotificationWidget>
    );
};

LearningPhaseBanner.propTypes = {
    isCampaignInLearningPhase: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ shopNotifications }) => ({
    isCampaignInLearningPhase: Boolean(
        shopNotifications?.entity?.filter(({ type }) => type === 'campaign_in_learning_phase')?.length,
    ),
});

export default connect(mapStateToProps, null)(LearningPhaseBanner);
