import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import classNames from 'classnames';

import { logoutFunc } from 'helpers';
import intercom from 'libs/intercom';
import {addGDPRBanner} from "helpers/gdpr";
import { removeAccount } from 'actions/account';
import { INSTALL_LINK_BIGCOMMERCE } from 'constants/staticLinks';
import { BIG_COMMERCE, SHOPIFY, WOO_COMMERCE } from 'constants/platform';
import {
    installShopfyShopWithDomain,
    requestSendEventMetrics,
    installWoocommerceShopWithDomain,
    requestShops,
} from 'actions/shop';
import pageNameSetter from 'decorator/pageNameSetter';
import { changeShopPage, metricType } from 'constants/metric';

import Button from 'components/Button';
import FacebookPageLayout from 'modules/facebook/components/FacebookPageLayout';

import shopifyIcon from './assets/shopify_logo.svg';
import bigcommerceIcon from './assets/bc-logo.svg';
import woocommerceIcon from './assets/wc-logo.svg';

import facebookPartnersLogo from './assets/meta-partners-logo.svg';
import googlePartnersLogo from './assets/google-partners-logo.svg';
import shopifyPartnersLogo from './assets/shopify-partners-logo.svg';

import './ChooseInstallShop.css';

const SHOP_INSTALL_ERROR = {
    invalidUrl: 'invalidUrl',
    serverError: 'serverError',
};

// eslint-disable-next-line react/prefer-stateless-function
class ChooseInstallShop extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showUrlForm: false,
            shopType: null,
            shopUrl: '',
            shopUrlError: null,
            shopUrlTouched: false,
        };
        this.urlDomainRef = React.createRef();
    }

    getWidthOfInputText = (input, domainWidth) => {
        const tmp = document.createElement("span");
        tmp.className = "input-element tmp-element";
        document.body.appendChild(tmp);
        tmp.innerText = input.value;
        const inputTextWidth = tmp.offsetWidth;
        document.body.removeChild(tmp);

        const maxInputTextWidth = 400 - domainWidth;
        
        return inputTextWidth === 0 ? 134
            : inputTextWidth > maxInputTextWidth ? maxInputTextWidth + 35
            : inputTextWidth + 35;
    }

    updateInputElementsPosition = (type = null) => {
        const button = document.querySelector(".shops-install-url-form__btn");
        const domainPostfix = document.querySelector(".shops-install-url__domain");
        const inputEl = document.querySelector(".shops-install-url__input");

        const domainWidth = type === SHOPIFY ? 125
            : type === BIG_COMMERCE ? 174
            : 8;
            
        domainPostfix.style.marginLeft = `${this.getWidthOfInputText(inputEl, domainWidth)}px`;
        inputEl.style.paddingRight = `${domainWidth+button.offsetWidth+10}px`;
    }

    getShopUrlError = (shopType, shopUrl = this.state.shopUrl) => {
        const shopUrlPattern = /^[a-z0-9]+[a-z0-9_.-]*[a-z0-9]+$/;
        const shopUrlError = shopUrlPattern.test(shopUrl) ? null : SHOP_INSTALL_ERROR.invalidUrl;

        return shopUrlError;
    }

    onShopDomainChange = (event) => {
        const {shopType} = this.state;
        const shopUrl = event.target.value.toLowerCase();

        this.setState({
            shopUrl,
            shopUrlError: this.getShopUrlError(shopType, shopUrl),
            shopUrlTouched: true,
        });

        this.updateInputElementsPosition(shopType);
    }

    chooseShopType = (type) => {
        this.updateInputElementsPosition(type);
        if (this.state.shopType === type) {
            this.setState({
                shopType: null,
                showUrlForm: false,
                shopUrl: '',
                shopUrlError: null,
                shopUrlTouched: false,
            });
        } else {
            this.setState({
                shopType: type,
                showUrlForm: true,
                shopUrlError: this.getShopUrlError(type),
            });
        }
        this.urlDomainRef.current.focus();
    }

    submitUrlForm = (event) => {
        const { shopType, shopUrl, shopUrlError } = this.state;
        const { installShopfyShopWithDomain, installWoocommerceShopWithDomain, replace, requestShops } = this.props;

        event.preventDefault();

        if (!shopUrlError) {
            if (shopType === SHOPIFY) {
                installShopfyShopWithDomain({
                    domain: shopUrl,
                })
                    .then(({ url }) => {
                        document.location.assign(url);
                    })
                    .catch(() => {
                        this.setState({
                            shopUrlError: SHOP_INSTALL_ERROR.serverError,
                            shopUrlTouched: true,
                        });
                    });
            } else if (shopType === BIG_COMMERCE) {
                document.location.assign(INSTALL_LINK_BIGCOMMERCE);
            } else if (shopType === WOO_COMMERCE) {
                installWoocommerceShopWithDomain({
                    domain: shopUrl,
                }).then((res) => {
                    if (res.status === 'error') {
                        if (res.message === 'domain-validation-failed') {
                            this.setState({
                                shopUrlError: SHOP_INSTALL_ERROR.invalidUrl,
                                shopUrlTouched: true,
                            });
                        }

                        if (res.message === 'resource-unavailable') {
                            this.setState({
                                shopUrlError: SHOP_INSTALL_ERROR.serverError,
                                shopUrlTouched: true,
                            });
                        }
                    }

                    if (res.status === 'success') {
                        document.location.assign(
                            `http://${shopUrl}/wp-admin/plugin-install.php?tab=plugin-information&plugin=retargetapp-integration-for-woocommerce`,
                        );
                    }
                });
            } else {
                const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ domain_url: shopUrl, iana_timezone: tzid })
                };
                fetch('/api/install_url/standalone', requestOptions)
                    .then(res => res.json())
                    .then(res => {
                        if (res.meta.error) {
                            this.setState({
                                shopUrlError: SHOP_INSTALL_ERROR.invalidUrl,
                                shopUrlTouched: true,
                            });
                        } else {
                            requestShops().then(() => replace(`/${res.data.shop.id}/home`));
                        }
                    });
            }
        }
    }

    logOutHandler = () => {
        const { pageTitle, requestSendEventMetrics, logout, inframe } = this.props;
        const metricProps = {
            type: metricType.loggedOut,
            props: {
                source: pageTitle.toString().toLowerCase().split(' ').join('_'),
            },
        };
        requestSendEventMetrics(metricProps);
        logoutFunc(logout, inframe, replace, intercom);
    }

    componentDidMount() {
        addGDPRBanner();
    }

    render() {
        const { accountInfo } = this.props;
        const { showUrlForm, shopType, shopUrl, shopUrlError, shopUrlTouched } = this.state;

        let LoggedInAs = null;
        let shopDomainUrl = '';
        let shopInstallError = null;
        let shopHeaderText = null;

        const logoutButton = (
            <button className="fb-login-logout-button" onClick={this.logOutHandler}>
                Log out
            </button>
        );

        if (accountInfo) {
            LoggedInAs = (
                <p className="login-view__description logged-as-text">
                    You are logged in as{' '}
                    <span>
                        {accountInfo.first_name} {accountInfo.last_name}
                    </span>
                    . {logoutButton}
                </p>
            );
        }

        if (shopType === SHOPIFY) {
            shopDomainUrl = '.myshopify.com';
            shopHeaderText = 'What Shopify store would you like to get Adwisely for?';
        } else if (shopType === BIG_COMMERCE) {
            shopDomainUrl = '.mybigcommerce.com';
            shopHeaderText = 'What Bigcommerce store would you like to get Adwisely for?';
        } else if (shopType === WOO_COMMERCE) {
            shopHeaderText = 'What WooCommerce store would you like to get Adwisely for?';
        } else {
            shopHeaderText = 'Enter a valid store domain';
        }

        if (shopUrlTouched && shopUrlError) {
            if (shopUrlError === SHOP_INSTALL_ERROR.invalidUrl) {
                shopInstallError = (
                    <span>
                        This doesn&#39;t look right{' '}
                        <span aria-label="thinking-face" role="img">
                            🤔{' '}
                        </span>
                        Enter a valid store domain
                    </span>
                );
            } else if (shopUrlError === SHOP_INSTALL_ERROR.serverError) {
                shopInstallError = (
                    <span>
                        Oh my!{' '}
                        <span aria-label="scared-face" role="img">
                            😨{' '}
                        </span>
                        Can&#39;t find your store, please contact us!
                    </span>
                );
            }
        }

        const urlFormContent = (
            <>
                <div className="shops-install-url__header">
                    <span>{shopHeaderText}</span>
                </div>
                <form className="shops-install-url__form" onSubmit={this.submitUrlForm}>
                    <div>
                        <input
                            className="shops-install-url__input"
                            type="text"
                            name="shops-install-url__input"
                            placeholder="your-store-url"
                            value={shopUrl}
                            ref={this.urlDomainRef}
                            onChange={this.onShopDomainChange}
                            data-hj-allow="true"
                        />
                        <span className="shops-install-url__domain">{shopDomainUrl}</span>
                        <button
                            type="submit"
                            disabled={shopUrlError || !shopUrlTouched}
                            className="shops-install-url-form__btn"
                        >
                            Get 7-day Free Trial
                        </button>
                    </div>
                    <p className="shops-install-url__error">{shopInstallError}</p>
                </form>
            </>
        );

        return (
            <FacebookPageLayout>
                <h1 className="login-view__title">Log in</h1>
                <div className="login-view__white-substrate choose-shop">
                    <p className="login-view__description shops-install__on">Get Started</p>
                    <div className="shops-install-url__header">
                        <span>Choose your Ecommerce Platform</span>
                    </div>
                    <div className="shops-install__list">
                        <Button
                            className={classNames('shops-install__item', {
                                'shops-install__item-active': shopType === SHOPIFY,
                            })}
                            onClick={() => this.chooseShopType(SHOPIFY)}
                        >
                            <img src={shopifyIcon} alt="shopify-icon" />
                        </Button>
                        <Button
                            className={classNames('shops-install__item shops-install__item--bc', {
                                'shops-install__item-active': shopType === BIG_COMMERCE,
                            })}
                            onClick={() => this.chooseShopType(BIG_COMMERCE)}
                        >
                            <img src={bigcommerceIcon} alt="bigcommerce-icon" />
                        </Button>
                        <Button
                            className={classNames('shops-install__item', {
                                'shops-install__item-active': shopType === WOO_COMMERCE,
                            })}
                            onClick={() => this.chooseShopType(WOO_COMMERCE)}
                        >
                            <img src={woocommerceIcon} alt="woocommerce-icon" />
                        </Button>
                    </div>
                    <div
                        className={classNames('shops-install-url__wrapper', {
                            'shops-install-url__wrapper-visible': showUrlForm,
                        })}
                    >
                        {urlFormContent}
                    </div>
                    <div className="shops-install__partners-list">
                        <div
                            className="shops-install__partners-item shops-install__partners-item--fb"
                        >
                            <img alt="facebook" src={facebookPartnersLogo} />
                        </div>
                        <div
                            className="shops-install__partners-item shops-install__partners-item--gl"
                        >
                            <img alt="google" src={googlePartnersLogo} />
                        </div>
                        <div
                            className="shops-install__partners-item shops-install__partners-item--shopify"
                        >
                            <img alt="shopify" src={shopifyPartnersLogo} />
                        </div>
                    </div>
                </div>
                {LoggedInAs}
            </FacebookPageLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    inframe: state.onboarding.inframe.inframe,
    accountInfo: state.account.entity,
    pageTitle: state.pageTitle,
});

const mapPropsToDispatch = {
    requestShops,
    logout: removeAccount,
    installShopfyShopWithDomain,
    installWoocommerceShopWithDomain,
    requestSendEventMetrics,
    replace,
};

export default pageNameSetter(changeShopPage.choose_install_shop)(
    connect(mapStateToProps, mapPropsToDispatch)(ChooseInstallShop),
);
