import {push} from 'react-router-redux';
import getInstallIframeLink from '../modules/bigcommerce/utils';

import {
    callAPIConnectFBAdAccount,
    callAPIConnectFBPageOnboarding,
    callAPIFacebookLogin,
    callAPIFBAdAccountConnectStatus,
    callAPIGetFBLoginLink,
    callAPIShopInstall,
    callAPIRemoveFBAdAccount,
    callAPIValidateShop,
    callAPIOnboardings,
} from './api';

export const ONBOARDING_INSTALL_SHOP_REQUEST = 'ONBOARDING_INSTALL_SHOP_REQUEST';
export const ONBOARDING_INSTALL_SHOP_SUCCESS = 'ONBOARDING_INSTALL_SHOP_SUCCESS';
export const ONBOARDING_INSTALL_SHOP_FAILURE = 'ONBOARDING_INSTALL_SHOP_FAILURE';

export const ONBOARDING_FACEBOOK_LOGIN_REQUEST = 'ONBOARDING_FACEBOOK_LOGIN_REQUEST';
export const ONBOARDING_FACEBOOK_LOGIN_SUCCESS = 'ONBOARDING_FACEBOOK_LOGIN_SUCCESS';
export const ONBOARDING_FACEBOOK_LOGIN_FAILURE = 'ONBOARDING_FACEBOOK_LOGIN_FAILURE';

export const ONBOARDING_FB_LOGIN_LINK_REQUEST = 'ONBOARDING_FB_LOGIN_LINK_REQUEST';
export const ONBOARDING_FB_LOGIN_LINK_REQUEST_SUCCESS = 'ONBOARDING_FB_LOGIN_LINK_REQUEST_SUCCESS';
export const ONBOARDING_FB_LOGIN_LINK_REQUEST_FAILURE = 'ONBOARDING_FB_LOGIN_LINK_REQUEST_FAILURE';

export const ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST = 'ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST';
export const ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_SUCCESS = 'ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_SUCCESS';
export const ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_FAILURE = 'ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_FAILURE';

export const ONBOARDING_FB_AD_ACCOUNT_CHOSEN = 'ONBOARDING_FB_AD_ACCOUNT_CHOSEN';
export const ONBOARDING_FB_AD_ACCOUNT_REMOVE_CHOSEN = 'ONBOARDING_FB_AD_ACCOUNT_REMOVE_CHOSEN';

export const ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST = 'ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST';
export const ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_SUCCESS = 'ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_SUCCESS';
export const ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_FAILURE = 'ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_FAILURE';

export const ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST = 'ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST';
export const ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_SUCCESS = 'ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_SUCCESS';
export const ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_FAILURE = 'ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_FAILURE';

export const ONBOARDING_FB_AD_ACCOUNT_CONNECTED = 'ONBOARDING_FB_AD_ACCOUNT_CONNECTED';

export const ONBOARDING_FB_PAGE_CONNECT_REQUEST = 'ONBOARDING_FB_PAGE_CONNECT_REQUEST';
export const ONBOARDING_FB_PAGE_CONNECT_REQUEST_SUCCESS = 'ONBOARDING_FB_PAGE_CONNECT_REQUEST_SUCCESS';
export const ONBOARDING_FB_PAGE_CONNECT_REQUEST_FAILURE = 'ONBOARDING_FB_PAGE_CONNECT_REQUEST_FAILURE';

export const ONBOARDING_FB_PAGE_CHOSEN = 'ONBOARDING_FB_PAGE_CHOSEN';

export const FB_LOGIN_URI = `${window.location.protocol}//${window.location.host}/facebook/login/callback`;
export const LOGIN_CALLBACK_PAGE_URI = '/facebook/login/callback';

export const requestShopInstall = params => (dispatch) => {
    dispatch(callAPIShopInstall({
        params,
        types: [
            ONBOARDING_INSTALL_SHOP_REQUEST,
            ONBOARDING_INSTALL_SHOP_SUCCESS,
            ONBOARDING_INSTALL_SHOP_FAILURE,
        ],
    }));
};

export const requestFacebookLogin = params => (dispatch) => {
    params = params || {};
    params.redirect_url = FB_LOGIN_URI;
    dispatch(callAPIFacebookLogin({
        params,
        types: [
            ONBOARDING_FACEBOOK_LOGIN_REQUEST,
            ONBOARDING_FACEBOOK_LOGIN_SUCCESS,
            ONBOARDING_FACEBOOK_LOGIN_FAILURE,
        ],
    }));
};

export const requestOnBoardingLink = (inframe = false) => (dispatch) => {
    let onBoardingURL = FB_LOGIN_URI;
    if (inframe) {
        onBoardingURL = getInstallIframeLink() + LOGIN_CALLBACK_PAGE_URI;
    }
    return dispatch(callAPIGetFBLoginLink({
        params: {
            redirect_url: onBoardingURL,
        },
        types: [
            ONBOARDING_FB_LOGIN_LINK_REQUEST,
            ONBOARDING_FB_LOGIN_LINK_REQUEST_SUCCESS,
            ONBOARDING_FB_LOGIN_LINK_REQUEST_FAILURE,
        ],
    }));
};


export const goToLogin = id => push(`/${id}/onboarding/login`);

export const goToPage = id => push(`/${id}/onboarding/page`);

export const goToInvitation = id => push(`/${id}/onboarding/invitation`);

export const goToDomainVerification = id => push(`/${id}/onboarding/verify-domain`);

export const goToSharePixel = id => push(`/${id}/onboarding/share-pixel`);

export const goToChooseCampaign = id => push(`/${id}/onboarding/choose-campaign`);

export const goToSetUpRetargeting = id => push(`/${id}/onboarding/set-up-retargeting`);

export const goToChoosePlan = id => push(`/${id}/onboarding/choose-plan`);

export const goToShopifyBilling = id => push(`/${id}/onboarding/shopify-billing`);

export const goToStripeBilling = id => push(`/${id}/onboarding/stripe-billing`);

export const goToFacebookBilling = id => push(`/${id}/onboarding/facebook-billing`);

export const goToCreateCampaign = id => push(`/${id}/`);

export const chooseAdAccount = id => ({
    type: ONBOARDING_FB_AD_ACCOUNT_CHOSEN,
    payload: id,
});

export const removeChosenAdAccount = () => ({
    type: ONBOARDING_FB_AD_ACCOUNT_REMOVE_CHOSEN,
});

export const requestFBAdAccountConnect = id => (dispatch) => {
    dispatch(chooseAdAccount(id));
    dispatch(callAPIConnectFBAdAccount({
        params: {
            id,
        },
        types: [
            ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST,
            ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_SUCCESS,
            ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_FAILURE,
        ],
    }));
};

export const removeFBAdAccount = (params = {}) => (dispatch) => {
    dispatch(callAPIRemoveFBAdAccount({
        params,
        types: [
            ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST,
            ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_SUCCESS,
            ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_FAILURE,
        ],
    }));
};

export const requestFBAdAccountConnectStatus = (params = {}) => (dispatch) => {
    dispatch(callAPIFBAdAccountConnectStatus({
        params,
        types: [
            ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST,
            ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_SUCCESS,
            ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_FAILURE,
        ],
    }));
};

export const chooseFBPage = id => ({
    type: ONBOARDING_FB_PAGE_CHOSEN,
    payload: id,
});

export const requestOnboardingFBPageConnect = (id, shopId, params = {}) => (dispatch) => {
    dispatch(chooseFBPage(id));
    return dispatch(callAPIConnectFBPageOnboarding(id, shopId, {
        params,
        types: [
            ONBOARDING_FB_PAGE_CONNECT_REQUEST,
            ONBOARDING_FB_PAGE_CONNECT_REQUEST_SUCCESS,
            ONBOARDING_FB_PAGE_CONNECT_REQUEST_FAILURE,
        ],
    }));
};


export const CLEAR_REQUEST_BM_TO_SETUP_ERROR = 'CLEAR_REQUEST_BM_TO_SETUP_ERROR';

export const clearRequestBMToSetupError = () => ({type: CLEAR_REQUEST_BM_TO_SETUP_ERROR});

export const ONBOARDING_VALIDATE_SHOP_REQUEST = 'ONBOARDING_VALIDATE_SHOP_REQUEST';
export const ONBOARDING_VALIDATE_SHOP_SUCCESS = 'ONBOARDING_VALIDATE_SHOP_SUCCESS';
export const ONBOARDING_VALIDATE_SHOP_FAILURE = 'ONBOARDING_VALIDATE_SHOP_FAILURE';

export const validateShop = params => (callAPIValidateShop({
    params,
    types: [
        ONBOARDING_VALIDATE_SHOP_REQUEST,
        ONBOARDING_VALIDATE_SHOP_SUCCESS,
        ONBOARDING_VALIDATE_SHOP_FAILURE,
    ],
}));

export const CLEAR_ONBOARDING_SHOP_ERROR = 'CLEAR_ONBOARDING_SHOP_ERROR';

export const clearShopError = () => ({
    type: CLEAR_ONBOARDING_SHOP_ERROR,
});

export const GET_ONBOARDINGS_REQUEST = 'GET_ONBOARDINGS_REQUEST';
export const GET_ONBOARDINGS_SUCCESS = 'GET_ONBOARDINGS_SUCCESS';
export const GET_ONBOARDINGS_FAILURE = 'GET_ONBOARDINGS_FAILURE';

export const requestOnboardings = shopId => callAPIOnboardings(shopId, {
    types: [
        GET_ONBOARDINGS_REQUEST,
        GET_ONBOARDINGS_SUCCESS,
        GET_ONBOARDINGS_FAILURE,
    ],
});

export const SET_ONBOARDING_VERSION = 'SET_ONBOARDING_VERSION';

export const setOnboardingVersion = (version) => (dispatch) => {dispatch({type: 'SET_ONBOARDING_VERSION', version})}

