import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import intercom from 'libs/intercom';

import { metricType } from 'constants/metric';
import { requestSendEventMetrics } from 'actions/shop';

import Button from 'components/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal/Modal';

import './ModalLearningPhase.css';

const ModalLearningPhase = ({
    isOpenModal,
    togglePauseCampaignModal,
    requestSendEventMetrics,
    shopId,
    type,
}) => {
    const sendEventMetrics = (type, btnText) => {
        const metricProps = {
            shop_id: +shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: type === 'facebook' ? 'pausing_fb_campaign_in_learning_phase' : 'pausing_gl_campaign_in_learning_phase',
                parameters: btnText,
            },
        };
        requestSendEventMetrics(metricProps);
    };

    return (
        <Modal className="pause-modal__dialog" isOpen={isOpenModal}>
            <button
                className="pause-modal__close-button"
                onClick={() => {
                    togglePauseCampaignModal();
                    sendEventMetrics(type, 'got it');
                }}
            />
            <ModalHeader className="pause-modal__header">
                <p className="pause-modal__title">Your campaign is still in Learning phase</p>
            </ModalHeader>

            <ModalBody className="pause-modal__body">
                <p>To determine the best ways to bring you more sales from ads, we need to study your customers' behavior during the Learning phase.</p>
                <p>Until the Learning phase is over, please, <strong>do not edit or pause</strong> any of the ads Adwisely has created for you.</p>
                <p>If you feel that there is a problem with the ads, please, <strong>contact Support</strong></p>
            </ModalBody>

            <ModalFooter className="pause-modal__footer">
                <Button
                    onClick={() => {
                        togglePauseCampaignModal();
                        sendEventMetrics(type, 'got it');
                    }}
                    color="primary"
                >
                    Got it
                </Button>
                <Button
                    onClick={() => {
                        intercom.show();
                        sendEventMetrics(type, 'contact support');
                    }}
                >
                    Contact Support
                </Button>
            </ModalFooter>
        </Modal>
    )
};

ModalLearningPhase.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    togglePauseCampaignModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ shops }) => ({
    shopId: shops.currentShopId,
});

const mapPropsToDispatch = {
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ModalLearningPhase);
