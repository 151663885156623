import { SHOPS_SUCCESS } from 'actions/shop';

export default (store) => (next) => (action) => {
    let state = store.getState();
    switch (action.type) {
        case SHOPS_SUCCESS:
            next(action);
            state = store.getState();
            if (window.localStorage) {
                window.localStorage.setItem('shopEntities', JSON.stringify(state.shops.entities));
            }
            break;
        default:
            next(action);
            break;
    }
};
