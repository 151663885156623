import * as notify from 'actions/notification';
import * as campaignActions from 'actions/campaign';
import { hideLoader, showLoader } from 'actions/loader';

const PAYMENT_METHOD_STATUS = 'PAYMENT_METHOD_STATUS';
const NOT_CONNECTED = 'NOT_CONNECTED';
const getlocaLStorageItem = (item) => window.localStorage.getItem(item);
const setlocaLStorageItem = (item, value) => window.localStorage.setItem(item, value);
const removeLocalStorageItem = (item) => window.localStorage.removeItem(item);

export default ({ dispatch }) => (next) => (action) => {
    // bind to state;
    switch (action.type) {
        case campaignActions.CAMPAIGN_CREATE_REQUEST:
            dispatch(showLoader());
            next(action);
            break;

        case campaignActions.CAMPAIGN_CREATE_FAILURE:
            dispatch(hideLoader());
            next(action);
            break;

        case campaignActions.CAMPAIGN_CREATE_SUCCESS:
            dispatch(hideLoader());
            next(action);
            break;

        case campaignActions.SHOP_STATISTIC_FAILURE:
            next(action);
            break;

        case campaignActions.SHOP_STATISTIC_SUCCESS:
            next(action);
            break;

        case campaignActions.CAMPAIGN_SUGGESTIONS_FAILURE:
            dispatch(notify.showNotification(action.payload));
            next(action);
            break;

        case campaignActions.CAMPAIGN_SUGGESTIONS_SUCCESS:
            next(action);
            break;

        case campaignActions.SHOP_CAMPAIGNS_FAILURE:
            dispatch(notify.showNotification(action.payload));
            next(action);
            break;

        case campaignActions.SHOP_CAMPAIGNS_SUCCESS:
            next(action);
            break;

        case campaignActions.CAMPAIGN_VALIDATE_SUCCESS: {
            if (
                getlocaLStorageItem(PAYMENT_METHOD_STATUS) === NOT_CONNECTED &&
                window.location.pathname.endsWith('create/retargeting')
            ) {
                removeLocalStorageItem(PAYMENT_METHOD_STATUS);
            }
            next(action);
            break;
        }

        case campaignActions.CAMPAIGN_VALIDATE_FAILURE: {
            if (
                action.payload.errorToken === 'payment-method-not-found' &&
                !getlocaLStorageItem(PAYMENT_METHOD_STATUS) &&
                window.location.pathname.endsWith('create/retargeting')
            ) {
                setlocaLStorageItem(PAYMENT_METHOD_STATUS, NOT_CONNECTED);
            }
            next(action);
            break;
        }

        default:
            next(action);
            break;
    }
};
