import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestFeatureFlags } from 'actions/featureFlags';
import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import CustomToggler from 'components/DropdownToggler/CustomToggler';

import './DropdownWidget.css';

const DropdownWidget = ({
    inNavbar,
    menuList,
    toggleIcon,
    toggleTitle,
    toggleCaret,
    smallDropdown,
    toggleSubTitle,
    withoutItemBorders,
    customToggleImageClass,
    requestFeatureFlags,
    customWrapperClassName,
    alignMenuToTogglesRightSide,
}) => (
    <DropdownProvider>
        {({ toggle, isOpen }) => {
            const dropdownItems = menuList
                .filter((isMenuItemExists) => isMenuItemExists)
                .map((menuListItem, key) => (
                    <div
                        key={key}
                        className="dropdown-widget__menu-item-wrapper"
                        onClick={() => {
                            toggle();

                            if (menuListItem.key) {
                                requestFeatureFlags?.(menuListItem.key);
                            }
                        }}
                    >
                        {menuListItem}
                    </div>
                ));

            const flagToggler = (
                <CustomToggler
                    caret={menuList.length > 1 && toggleCaret}
                    disabled={menuList.length <= 1}
                    title={toggleTitle}
                    subtitle={toggleSubTitle}
                    customIcon={toggleIcon}
                    toggleTitle={toggleTitle}
                    customToggleImageClass={customToggleImageClass}
                    smallDropdown={smallDropdown}
                    inNavbar={inNavbar}
                />
            );
            return (
                <Dropdown toggle={toggle} isOpen={isOpen} items={dropdownItems} flagToggler={flagToggler}
                    additionalMenuClassName={classNames('dropdown-widget__menu', {
                        'dropdown-widget__menu_small': smallDropdown,
                        'dropdown-widget__menu_borderfree': withoutItemBorders,
                    })}
                    additionalDropdownClassName={classNames('dropdown-widget', customWrapperClassName)}
                    disabled={menuList.length <= 1}
                />
        )}}
    </DropdownProvider>
);

DropdownWidget.defaultProps = {
    inNavbar: false,
    toggleCaret: true,
    toggleIcon: null,
    toggleSubTitle: null,
    smallDropdown: false,
    withoutItemBorders: false,
    customToggleImageClass: null,
    customWrapperClassName: null,
    alignMenuToTogglesRightSide: false,
};

DropdownWidget.propTypes = {
    inNavbar: PropTypes.bool,
    toggleCaret: PropTypes.bool,
    smallDropdown: PropTypes.bool,
    withoutItemBorders: PropTypes.bool,
    customToggleImageClass: PropTypes.string,
    customWrapperClassName: PropTypes.string,
    alignMenuToTogglesRightSide: PropTypes.bool,
    requestFeatureFlags: PropTypes.func.isRequired,
    toggleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    toggleTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    toggleSubTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    menuList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])).isRequired,
};

const mapDispatchToProps = {
    requestFeatureFlags,
};

export default connect(null, mapDispatchToProps)(DropdownWidget);
