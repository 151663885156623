export const SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN = 'platform_plan';
export const SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT = 'products_count';
export const SHOP_QUALIFICATION_CRITERIA_ORDERS = 'orders';

export const isQualified = (shopQualificationCriterias) => shopQualificationCriterias?.is_qualified;

export const isQualifiedByCriterias = (shopQualificationCriterias, criteriaType) => {
    const notSatisfiedCriterias = shopQualificationCriterias?.reasons?.filter(
        (criteria) => criteriaType.includes(criteria.type) && !criteria.satisfied,
    );
    return !notSatisfiedCriterias?.length;
};
