import React, {useEffect, useState} from 'react';
import { PopupModal } from 'react-calendly';

import {
  getLocalStorageObjectItem,
  processCampaignArray,
  removeLocalStorageObjectItem,
  setLocalStorageObjectItem
} from 'helpers';
import { FACEBOOK_ONBOARDING_NAMES, ONBOARDING_TYPES } from 'constants/onboarding';
import { STANDALONE } from 'constants/platform';
import { metricType } from 'constants/metric';
import { CAMPAIGNS_TYPES } from 'constants/campaign';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';

import anIcon from 'modules/home/images/an-icon-card.svg';
import facebookIcon from 'modules/home/images/facebook-icon-card.svg';
import instagramIcon from 'modules/home/images/instagram-icon-card.svg';
import messengerIcon from 'modules/home/images/messenger-icon-card.svg';
import googleIcon from 'modules/home/images/google-icon-card.svg';
import googleAdsIcon from 'modules/home/images/google-ads-icon-card.svg';
import gmailIcon from 'modules/home/images/google-mail-icon-card.png';
import youtubeIcon from 'modules/home/images/youtube-icon-card.svg';
import tikTokIcon from 'modules/home/images/tiktok-icon-card.svg';

import { changeOnboardingState } from 'modules/home/utils';

import { FacebookCard } from '../FacebookCard';
import { GoogleCard } from '../GoogleCard';
import { TikTokCard } from '../TikTokCard';

const facebookIcons = [anIcon, facebookIcon, instagramIcon, messengerIcon];
const googleIcons = [googleIcon, googleAdsIcon, gmailIcon, youtubeIcon];
const tikTokIcons = [tikTokIcon];

export const Cards = ({
                          onboardings,
                          redirect,
                          currentShopId,
                          disableButtons,
                          currentShop,
                          isShopFreezedOrOnboardingDone,
                          loginLink,
                          isFacebookAuthorized,
                          currentActiveCard,
                          setCurrentActiveCard,
                          requestSendEventMetrics,
                          googleLoginUrl,
                          isGoogleAuthorized,
                          shopError,
                          setIsMobilePreview,
                          setIsLoading,
                      }) => {

    const [sortedOtherCampaigns] = processCampaignArray(onboardings);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    useEffect(() => {
        const boostBannerData = getLocalStorageObjectItem('boost-banner')
        let activeCard = sortedOtherCampaigns[0].name
        if (sortedOtherCampaigns.length > 1) {
            if (sortedOtherCampaigns[0].state !== 'not_started' && sortedOtherCampaigns[1].state === 'not_started') {
                activeCard = sortedOtherCampaigns[1].name
            }
            if (boostBannerData === 'true') {
                setCurrentActiveCard('google')
                removeLocalStorageObjectItem('boost-banner');
                return
            }
        }
        setCurrentActiveCard(activeCard)
    }, [sortedOtherCampaigns[0].name])

    return (
        <div className="home-board-inner__cards">
            <div className="home__block home__block--recommended">
                <div className="home__block-label home__block-label--recommended">AI-driven Campaigns - Recommended for your store
                </div>
                {sortedOtherCampaigns.map(({name, state, priority}) => {
                    if (name === ONBOARDING_TYPES.facebook_ai_boosted_sales) {
                        return (
                            <FacebookCard
                                additionalClassName={`home__board__block--${priority}`}
                                icons={facebookIcons}
                                key={name}
                                state={state}
                                redirect={redirect}
                                currentShopId={currentShopId}
                                disableButtons={
                                    disableButtons ||
                                    currentShop.type === STANDALONE ||
                                    isShopFreezedOrOnboardingDone(shopError, state)
                                }
                                onboardingType={name}
                                isFacebookAuthorized={isFacebookAuthorized}
                                loginLink={loginLink}
                                isActive={currentActiveCard === name}
                                onClick={() => {
                                    setCurrentActiveCard(name);
                                }}
                                sendMetrics={() => {
                                    const metricProps = {
                                        shop_id: currentShopId,
                                        type: metricType.btnClicked,
                                        props: {
                                            btn_name: name,
                                        },
                                    };
                                    requestSendEventMetrics(metricProps);
                                }}
                                handleStartOnboarding={() =>
                                    changeOnboardingState({
                                        currentShopId,
                                        type: name,
                                        status: UBM_ONBOARDING_STATES.not_started,
                                    })
                                }
                                setIsLoading={setIsLoading}
                            />
                        );
                    }
                    else if (name === ONBOARDING_TYPES.google) {
                        return (
                            <GoogleCard
                                additionalClassName={`home__board__block--${priority}`}
                                icons={googleIcons}
                                key={name}
                                state={state}
                                isActive={currentActiveCard === name}
                                onClick={() => {
                                    setCurrentActiveCard(CAMPAIGNS_TYPES.google);
                                }}
                                disableButtons={
                                    disableButtons || isShopFreezedOrOnboardingDone(shopError, state)
                                }
                                googleLoginUrl={googleLoginUrl}
                                isGoogleAuthorized={isGoogleAuthorized}
                                handleStartOnboarding={() => {
                                    const metricProps = {
                                        shop_id: currentShopId,
                                        type: metricType.btnClicked,
                                        props: {
                                            btn_name: 'google',
                                        },
                                    };
                                    setLocalStorageObjectItem('currentOnboarding', 'google');
                                    requestSendEventMetrics(metricProps);
                                    if (state === ONBOARDING_STATES.not_started) {
                                        changeOnboardingState({
                                            currentShopId,
                                            type: ONBOARDING_TYPES.google,
                                            status: ONBOARDING_STATES.not_started,
                                        });
                                    }
                                }}
                            />
                        )
                    }
                    return null;
                })}
            </div>
            <PopupModal
                url="https://calendly.com/adwisely/30min"
                onModalClose={() => setIsCalendlyOpen(false)}
                open={isCalendlyOpen}
                rootElement={document.getElementById("root")}
            />
        </div>
    )
}