import * as shop from '../actions/shop';

const initialState = {
    name: null,
    spendsLimit: null,
    fee: null,
    version: null,
    duration: null,
    subscriptionRequesting: false,
    subscriptionError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case shop.SHOP_SUBSCRIPTION_REQUEST:
            state = {
                ...state,
                subscriptionRequesting: true,
            };
            break;

        case shop.SHOP_SUBSCRIPTION_SUCCESS:
            state = {
                ...state,
                name: payload?.subscription_plan?.name,
                spendsLimit: payload?.subscription_plan?.spends_limit,
                duration: payload?.subscription_plan?.duration,
                fee: payload?.subscription_plan?.fee,
                version: payload?.subscription_plan?.version,
                subscriptionRequesting: false,
                subscriptionError: false,
            };
            break;

        case shop.SHOP_SUBSCRIPTION_FAILURE:
            state = {
                ...state,
                subscriptionRequesting: false,
                subscriptionError: true,
            };
            break;

        default:
            break;
    }
    return state;
};
