import Raven from 'raven-js';

export const SENTRY_URL = 'https://9bdd711d48964cc7913ca0d1a153ef7e@sentry.io/1210375';

export const logException = (ex, extra, tags, logger = 'javascript', level = 'error') => {
    Raven.captureMessage(ex, {
        level,
        extra,
        logger,
        tags,
    });
};

export const setUserContext = (id = '') => {
    Raven.setUserContext({
        id,
    });
};

export const removeUserContext = () => {
    Raven.setUserContext();
};
