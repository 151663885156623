import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.css';

const propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export const DropdownItemIcon = ({ icon, title, active, onClick }) => (
    <button className={classNames('dropdown-item-icon', { active })} onClick={onClick}>
        <img className="dropdown-item-icon__icon" src={icon} alt={title} />
        <span>{title}</span>
    </button>
);

DropdownItemIcon.propTypes = propTypes;

export default DropdownItemIcon;
