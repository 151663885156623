import { fetchOnboardingState } from "helpers/fetchOnboardingState";
import { statePollingInterval } from 'helpers/statePollingInterval';
import { changeOnboardingState, googleFlow } from "modules/home/utils";
import { ONBOARDING_PAGES, ONBOARDING_STATES } from "constants/googleOnboarding";
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ROUTES } from 'constants/routes';
import { getGoogleRedirectPage } from "./getGoogleRedirectPage";

export const googleStatePolling = (shopId, redirect, setGoogleOnboardingState = () => {}, setLoader = () => {}, signal ) => {
    const onCompleteCb = (res) => {
        if (res.state === ONBOARDING_STATES.oauth) {
            changeOnboardingState({
                currentShopId: shopId,
                status: ONBOARDING_STATES.oauth,
                type: ONBOARDING_TYPES.google,
            });
        }

        setGoogleOnboardingState(res.state);
        getGoogleRedirectPage(res, shopId, redirect);
    }

    const processingCb = (res) => {
        setLoader(res);
        if (googleFlow.get() !== 'none') {
            redirect(`/${shopId}/${ONBOARDING_PAGES.processing}`);
        }
    }

    const onNotAuthCb = () => {
        redirect(ROUTES.facebookLogin)
    }

    return statePollingInterval(
        fetchOnboardingState,
        ONBOARDING_TYPES.google,
        shopId,
        onCompleteCb,
        processingCb,
        onNotAuthCb,
        signal
    )
};
