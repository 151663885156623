import React from 'react';
import './InstagramMobile.css';

import dots from '../../img/dots.svg';

import middleMenu from './img/middle-menu.png';
import bottomMenu from './img/bottom-menu.png';

export const InstagramMobile = ({product}) => (
    <div className="insta-mob">
        <div className="insta-mob__top">
            <div className="insta-mob__avatar-wrapper">
                <div className="insta-mob__avatar" />
                <div className="insta-mob__title-wrapper">
                    <div className="inta-mob__title">{product.url}</div>
                    <div className="insta-mob__sponsored">Sponsored</div>
                </div>
            </div>
            <img src={dots} alt="dots"/>
        </div>
        <div className="insta-mob__img-wrapper">
            <img src={product.img} alt={product.title} />
        </div>
        <img className="insta-mob__menu-img" src={middleMenu} alt="menu" />
        <div className='insta-mob__post-info'>
                <span className='insta-mob__text'>
                    <span className='insta-mob__post-text'>
                        <span className='insta-mob__author'>{product.title}</span>
                        {product.description}
                    </span>
                    <span className='insta-mob__more'>... more</span>
                </span>
        </div>
        <img className="insta-mob__menu-img" src={bottomMenu} alt="menu" />

    </div>
)