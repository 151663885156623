import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './TextInput.css';

export class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caretPosition: props.inputValue.length,
            inputValue: props.inputValue,
        };

        this.handleChangeInputValue = this.handleChangeInputValue.bind(this);
        this.checkCaretPosition = this.checkCaretPosition.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this.props.onMountFocus) this.messageInput.focus();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.inputValue !== nextProps.inputValue) {
            this.setState({ inputValue: nextProps.inputValue });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.checkCaretPosition();
        }
    }

    handleChangeInputValue(e, cb) {
        if (e.persist !== undefined) e.persist();
        this.props.onChange(e);
        this.setState({ inputValue: e.target.value }, () => {
            this.checkCaretPosition(e);
            if (cb) cb();
        });
    }

    checkCaretPosition(e) {
        const position =
            e && e.target && e.target.selectionStart !== undefined
                ? e.target.selectionStart
                : this.state.inputValue.length;
        this.setState({ caretPosition: position });
    }

    render() {
        const { inputClassName, wrapperClassName, disabled, error, maxLength } = this.props;
        const { inputValue } = this.state;
        return (
            <div
                className={`text-input-wrapper ${wrapperClassName}`}
                ref={(wrapper) => {
                    this.wrapperRef = wrapper;
                }}
            >
                <input
                    className={classNames('text-input', inputClassName, 'form-control', {
                        'text-input_error': error,
                    })}
                    type="text"
                    name={this.props.name}
                    id="textInput"
                    value={inputValue}
                    disabled={disabled}
                    onChange={this.handleChangeInputValue}
                    onKeyDown={this.checkCaretPosition}
                    onClick={this.checkCaretPosition}
                    onFocus={this.checkCaretPosition}
                />
                {maxLength && (
                    <span
                        className={classNames('text-input-counter', {
                            'text-input-counter_error': error,
                        })}
                    >
                        {inputValue.length}/{maxLength}
                    </span>
                )}
            </div>
        );
    }
}

TextInput.defaultProps = {
    inputClassName: '',
    wrapperClassName: '',
    disabled: false,
    error: false,
    onMountFocus: false,
};

TextInput.propTypes = {
    inputClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    inputValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onMountFocus: PropTypes.bool,
};

export default TextInput;
