import axios from 'axios';

import errorsMapByToken from './errors-map-by-token';
import errorByStatus from './error-map-by-http-status';

const APIPrefix = '/api';

/**
 *
 * @param res {{}}
 * @param res.meta {{}}
 * @param res.meta.request_id {string}
 * @param res.meta.error {Error[]}
 * @param res.data {{}}
 */
const checkResponseMeta = res => res;

/**
 *
 * @param res {{}}
 * @param res.meta {{}}
 * @param res.meta.request_id {string}
 * @param res.meta.error {string}
 * @param res.error_data {}
 */
const extractResponseData = res => (res.data);

const headers = {
    'Content-Type': 'application/json',
};

const checkErrorResponseMeta = (err) => {
    const {response} = err;

    const data = extractResponseData(response);

    if (data.meta && data.meta.error) {
        const finalErr = new Error(adoptErrorMessage(data.meta.error) || response.statusText);
        finalErr.isAPIError = true;
        finalErr.errorToken = data.meta.error;
        finalErr.status = response.status;
        finalErr.errorData = data.meta.error_data;
        finalErr.requestId = data.meta.request_id;
        return Promise.reject(finalErr);
    }

    const {status} = response;
    return Promise.reject(createStatusBasedError(status));
};

function adoptErrorMessage(error) {
    return error in errorsMapByToken ? errorsMapByToken[error] : false;
}

function createStatusBasedError(status) {
    const finalErr = Error(errorByStatus[status] || errorByStatus.default + status);
    finalErr.status = status;
    return finalErr;
}

const buildPOSTRequestProvider = (endpoint, params) => axios.post(endpoint, params);

const buildPUTRequestProvider = (endpoint, params) => axios.put(endpoint, params);

const buildPATCHRequestProvider = (endpoint, params) => axios.patch(endpoint, params);

const buildDELETERequestProvider = (endpoint, params) => axios.delete(endpoint, {headers, data: params });

const buildGETRequestProvider = (endpoint, params) => axios.get(endpoint, {params});

const buildRequestProvider = (endpoint, params, method) => {
    endpoint = APIPrefix + endpoint;
    switch (method.toUpperCase()) {
        case 'POST':
            return buildPOSTRequestProvider(endpoint, params);

        case 'GET':
            return buildGETRequestProvider(endpoint, params);

        case 'DELETE':
            return buildDELETERequestProvider(endpoint, params);

        case 'PUT':
            return buildPUTRequestProvider(endpoint, params);

        case 'PATCH':
            return buildPATCHRequestProvider(endpoint, params);

        default:
            return Promise.reject(new Error('Can\'t specify request provider'));
    }
};

/**
 * @param endpoint {string}
 * @param params {{}}
 * @param method {string}
 * @return {Promise}
 */
export const apiCall = (endpoint, params, method) => Promise
    .resolve(buildRequestProvider(endpoint, params, method))
    .then(checkResponseMeta)
    .then(extractResponseData)
    .catch(checkErrorResponseMeta);

