import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import ShopifyBilling from 'modules/onboarding/components/ShopifyBilling/ShopifyBilling';

import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { ONBOARDING_TYPES } from 'constants/onboarding';

import {changeOnboardingState} from '../../../../home/utils';
import { tikTokStatePolling } from '../../containers/TikTokOnboardingMiddleware';

export const ShopifyBillingTikTokView = ({shopId, replace}) => {
    const onRecurringChargeIsApproved = () => {
        changeOnboardingState({
            currentShopId: shopId,
            type: ONBOARDING_TYPES.tik_tok,
            status: ONBOARDING_STATES.approve_app_fee,
        }).then(() => {
            tikTokStatePolling(shopId, replace);
        })
    }

    return <ShopifyBilling
        onRecurringChargeIsApproved={onRecurringChargeIsApproved} />
}

const mapStateToProps = ({ shops }) => (
    {
        shopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace,
};

export const ShopifyBillingTikTok =  connect(mapStateToProps, mapPropsToDispatch)(ShopifyBillingTikTokView);