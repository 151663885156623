import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CUSTOMER_INTERVIEW_INVITE } from 'constants/notificationType';
import { metricType } from 'constants/metric';
import { shopHasNotification, dayDiff } from 'helpers';
import { requestSendEventMetrics } from 'actions/shop';
import {
    requestUpdateShopNotification,
    requestDeleteShopNotification,
} from 'actions/notification';

import CustomerInterviewInvite from './CustomerInterviewBanner';

const CustomerInterviewBannerWrapper = ({ shopId, customerInterviewInvite, hideNotification, removeNotification, requestSendEventMetrics, isActive }) => {
    const [isCustomerInterviewInviteHidden, setIsCustomerInterviewInviteHidden] = useState(false);

    useEffect(() => {
        if (customerInterviewInvite) {
            const updated_at = new Date(customerInterviewInvite.updated_at);
            const now = new Date();
            const dayPassed = dayDiff(updated_at, now);

            if (dayPassed <= 7 || customerInterviewInvite.deleted_at) {
                setIsCustomerInterviewInviteHidden(true);
            }
        }
    }, []);

    const hideCustomerInterviewInviteBanner = () => {
        hideNotification({
            id: shopId,
            type: CUSTOMER_INTERVIEW_INVITE,
        });
        setIsCustomerInterviewInviteHidden(true);
    };

    const removeCustomerInterviewInviteBanner = () => {
        removeNotification({
            id: shopId,
            type: CUSTOMER_INTERVIEW_INVITE,
        });
        setIsCustomerInterviewInviteHidden(true);
    };

    const sendEventMetrics = (btn_name) => {
        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: { btn_name },
        };
        requestSendEventMetrics(metricProps);
    };

    const isVisible = !isCustomerInterviewInviteHidden && !!customerInterviewInvite && isActive;

    return isVisible && (
        <CustomerInterviewInvite
            hideCustomerInterviewInviteBanner={hideCustomerInterviewInviteBanner}
            removeCustomerInterviewInviteBanner={removeCustomerInterviewInviteBanner}
            sendEventMetrics={sendEventMetrics}
        />
    )
};

CustomerInterviewBannerWrapper.defaultProps = {
    customerInterviewInvite: null,
};

CustomerInterviewBannerWrapper.propTypes = {
    customerInterviewInvite: PropTypes.shape({
        updated_at: PropTypes.string,
    }),
    shopId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    customerInterviewInvite: shopHasNotification(state.shopNotifications.entity, CUSTOMER_INTERVIEW_INVITE),
});

const mapDispatchToProps = {
    hideNotification: requestUpdateShopNotification,
    removeNotification: requestDeleteShopNotification,
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInterviewBannerWrapper);
