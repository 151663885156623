export default () => {
    window.fbAsyncInit = () => {
        window.FB.init({
            appId: '1581948538742717',
            xfbml: true,
            version: 'v20.0',
        });
    };

    /* eslint-disable */
    (function (d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js"; fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    /* eslint-enable */
};
