export default {
    default: 'Unknown error',
    'bad-login-data': 'Login unsuccessful because of outdated data.',
    'shop-has-wrong-status-action-required': 'shop has status action required',
    'shop-has-wrong-status-rejected': 'shop has status rejected',
    'shop-not-found': 'shop not found',
    'products-in-ads-not-found': 'shop has not products in catalog service',
    'products-not-found': 'Product in shopify not found',
    'shop-is-frozen-due-to-shop-password-enabled': 'password required',
    'shop-is-frozen-due-to-shop-paused': 'failed to validate shop status paused',
    'shop-is-frozen-due-to-ra-plugin-for-wc-deactivated': 'ra plugin deactivated',
    'account-doesnt-have-access-to-ad-account': 'user has analyst role',
    'ad-account-has-wrong-status-disabled': 'ad account has status disabled',
    'ad-account-has-wrong-status-closed': 'ad account has status closed',
    'ad-account-has-wrong-status-pending_risk_review': 'ad account has status pending risk preview',
    'ad-account-has-wrong-status-unsettled': 'ad account has status unsettled',
    'payment-method-not-found': 'payment method not found',
    'business-manager-doesnt-have-access-to-page': 'revoke any require access to page',
    'public-page-not-found': 'unpublished page',
    'campaign-effective-status-has-wrong-status-disapproved': 'campaign ad declined',
    'campaign-already-exists': 'Campaign already exists',
    'failed-to-validate-message': 'failed validate ad message',
};
