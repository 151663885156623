import * as campaignActions from '../actions/campaign';

const initialState = {
    googleCampaignData: null,
    googleCampaignPending: false,
    googleCampaignSuccess: false,
    googleCampaignFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.GOOGLE_CAMPAIGN_REQUEST:
            return {
                ...state,
                googleCampaignSuccess: false,
                googleCampaignPending: true,
                googleCampaignFailure: false,
            };

        case campaignActions.GOOGLE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                googleCampaignSuccess: true,
                googleCampaignPending: false,
                googleCampaignFailure: false,
                googleCampaignData: action.payload,
            };

        case campaignActions.GOOGLE_CAMPAIGN_FAILURE:
            return {
                ...state,
                googleCampaignSuccess: false,
                googleCampaignFailure: true,
                googleCampaignPending: false,
            };

        default:
            return state;
    }
};
