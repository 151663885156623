import React from 'react';

import ShopWrongStatusBanner from './ShopWrongStatusBanner';
import ShopNoPaymentMethodBanner from './ShopNoPaymentMethodBanner';
import ShopIsFrozenBanner from './ShopIsFrozenBanner';
import AdAccountErrorBanner from './AdAccountErrorBanner';
import CommonErrorBanner from './CommonErrorBanner';
import CampaignSettingsErrorBanner from './CampaignSettingsErrorBanner';
import CampaignError from './CampaignError';

const Banners = ({
    shopError,
    changeTipsErrorText,
    handleFormError,
    shopValidateRequesting,
    checkShopForErrors,
    shopId,
    campaignValidateFacebook,
    shopRecurringCharge,
    changePublishPage,
    currentShop,
    stripeUserChargeTryAgainHandler,
    replaceStripeCard,
    isStripeButtonPending,
    requestShops,
    campaignError,
}) => (
    <>
        <ShopWrongStatusBanner
            shopError={shopError}
            handleFormError={handleFormError}
            changeTipsErrorText={changeTipsErrorText}
        />
        <ShopNoPaymentMethodBanner shopError={shopError} changeTipsErrorText={changeTipsErrorText} />
        <ShopIsFrozenBanner
            shopError={shopError}
            validationPending={shopValidateRequesting}
            validateShopSettings={() => checkShopForErrors({ shopId })}
            campaignValidateFacebook={campaignValidateFacebook}
            shopRecurringCharge={shopRecurringCharge}
            checkShopForErrors={() => checkShopForErrors({ shopId })}
            changeTipsErrorText={changeTipsErrorText}
            stripeUserChargeTryAgainHandler={stripeUserChargeTryAgainHandler}
            replaceStripeCard={replaceStripeCard}
            isStripeButtonPending={isStripeButtonPending}
            currentShop={currentShop}
        />
        <AdAccountErrorBanner shopError={shopError} />
        <CommonErrorBanner
            shopError={shopError}
            shopId={shopId}
            checkShopForErrors={() => checkShopForErrors({ shopId })}
            changeTipsErrorText={changeTipsErrorText}
            currentShop={currentShop}
        />
        <CampaignSettingsErrorBanner
            shopError={shopError}
            shopId={shopId}
            checkShopForErrors={() => checkShopForErrors({ shopId })}
            handleFormError={handleFormError}
            changeTipsErrorText={changeTipsErrorText}
            changePublishPage={changePublishPage}
            requestShops={requestShops}
        />
        <CampaignError campaignError={campaignError} />
    </>
);

export default Banners;
