import { environment_name } from 'constants/environment';

const intercomInitScript = () => {
    const APP_ID = (process.env.NODE_ENV === 'production' && environment_name === 'prod')
        ? 'jx5y0q3b'// Prod
        : 'fzwtjpq5';// Dev

    window.intercomSettings = {
        app_id: APP_ID,
        alignment: 'right',
        vertical_padding: 70,
        custom_launcher_selector: '#open_chat',
    };

    /* eslint-disable */
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', window.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments)
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args)
            };
            w.Intercom = i;

            function l() {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            }

            if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
    /* eslint-disable */
}

export default intercomInitScript;
