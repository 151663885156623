import {
    SHOP_NOTIFICATIONS_REQUEST,
    SHOP_NOTIFICATIONS_SUCCESS,
    SHOP_NOTIFICATIONS_FAILURE,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_DELETE_SUCCESS,
} from 'actions/notification';
import { READ_ALL_ORDERS_REQUEST } from 'constants/notificationType';

const initialState = {
    entity: [],
    pending: false,
    success: false,
    failure: false,
};
const notificationsBannerClosedHandler = (state, payload) => {
    state.entity?.forEach((entity) => {
        if (entity.type === payload.type) {
            entity.updated_at = new Date().toUTCString();
        }
    });
    return state;
};

const notificationsBannerDeleteHandler = (state, payload) => {
    state.entity?.forEach((entity) => {
        if (entity.type === payload.type) {
            entity.deleted_at = new Date().toUTCString();
        }
    });
    return state;
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOP_NOTIFICATIONS_REQUEST:
            return {
                ...initialState,
                pending: true,
            };
        case SHOP_NOTIFICATIONS_SUCCESS:
            return {
                ...initialState,
                success: true,
                entity: payload,
            };
        case NOTIFICATION_UPDATE_SUCCESS:
            return {
                ...notificationsBannerClosedHandler(state, payload),
            };
        case NOTIFICATION_DELETE_SUCCESS:
            return {
                ...notificationsBannerDeleteHandler(state, payload),
            };
        case SHOP_NOTIFICATIONS_FAILURE:
            return {
                ...initialState,
                failure: true,
            };
        default:
            return state;
    }
};
