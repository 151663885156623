import React from 'react';
import './GoogleSearchMobile.css';

import planetIcon from './img/planet-icon.svg';

export const GoogleSearchMobile = ({product}) => (
    <div className='gl-search-mob'>
        <div className="gl-search-mob__search-title">{product.title}</div>
        <div className="gl-search-mob__content">
            <div className="gl-search-mob__sponsored">Sponsored</div>
            <div className="gl-search-mob__url-wrapper">
                <img src={planetIcon} alt="planet icon"/>
                <div className="gl-search-mob__url">
                    <div>{product.url}</div>
                    <div>{`https://${product.url}/`}</div>
                </div>
            </div>
            <div className="gl-search-mob__title">
                <div>{product.subtitle}</div>
                <div>| {product.title}</div>
            </div>
            <div className="gl-search-mob__descr">{product.description}</div>
        </div>
    </div>
)