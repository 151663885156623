import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

import intercom from 'libs/intercom';

const GOOGLE_CAMPAIGN_IS_IN_REVIEW = 'google_campaign_is_in_review';

class DashboardGoogleInfoNotifications extends Component {
    state = {
        isShow: true,
        isCampaignIsInReviewShow: false,
    };

    getNotification = () => {
        const { notifications } = this.props;
        const { isShow, isCampaignIsInReviewShow } = this.state;

        let notificationType;
        let notificationDescription;

        const googleCampaignIsInReview = notifications?.find(
            (notification) => notification.type === GOOGLE_CAMPAIGN_IS_IN_REVIEW,
        );

        if ((googleCampaignIsInReview && isShow) || isCampaignIsInReviewShow) {
            notificationType = 'info';
            notificationDescription = (
                <div>
                    <p className="notification__title">Your Google campaign is in review</p>

                    <p className="notification__description">
                        Your store has been successfully reviewed. The campaign will start within the next 7 days. Once
                        it’s active, you will get an email. Please{' '}
                        <Button color="link" className="alert-description__link" onClick={intercom.show}>
                            contact support
                        </Button>{' '}
                        for the additional questions.
                    </p>

                    <Button color="transparent-bordered" onClick={this.closeNotification}>
                        Got it!{' '}
                        <span className="banner__emoji" aria-labelledby="wink-emoji" role="img">
                            😊
                        </span>
                    </Button>
                </div>
            );
        }

        if (notificationDescription) {
            return (
                <section className="dashboard-page__section">
                    <NotificationWidget type={notificationType}>{notificationDescription}</NotificationWidget>
                </section>
            );
        }

        return null;
    };

    closeNotification = () => {
        this.setState({
            isShow: false,
        });
    };

    render() {
        return <div>{this.getNotification()}</div>
    }
}

const mapStateToProps = ({ shopNotifications }) => ({
    notifications: shopNotifications.entity,
});

export default connect(mapStateToProps)(DashboardGoogleInfoNotifications);
