export const ON_INFRAME_TRUE = 'ON_INFRAME_TRUE';
export const ON_INFRAME_FALSE = 'ON_INFRAME_FALSE';

export const setInframeTrue = () => dispatch => dispatch({type: ON_INFRAME_TRUE});

export const setInframeFalse = () => dispatch => dispatch({type: ON_INFRAME_FALSE});

export const ON_INFRAME_SET_PLATFORM_TYPE = 'ON_INFRAME_SET_PLATFORM_TYPE';

export const setInframePlatformType = platformType => dispatch => dispatch({type: ON_INFRAME_SET_PLATFORM_TYPE, platformType});
