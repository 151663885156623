import {
    SHOPS_REQUEST,
    SHOPS_SUCCESS,
    SHOPS_FAILURE,
    SHOP_INSTALL_SUCCESS,
    SHOPS_ENTITIES_UPDATE,
    SHOPS_CURRENT_SHOP_ID_UPDATE,
    ON_INFRAME_FILTER_SHOPS,
    SHOP_INSTALL_FAILURE,
    GET_SHOP_RECOMMENDATION_SUCCESS,
    GET_SHOP_RECOMMENDATION_FAILURE,
    SHOP_UPDATE_REQUEST,
    SHOP_UPDATE_SUCCESS,
    SHOP_UPDATE_FAILURE,
} from 'actions/shop';

import { SHOPIFY } from 'constants/platform';

const initialState = {
    entities: [],
    currentShopId: null,
    error: null,
    shopRequesting: false,
    shopRequestSuccess: false,
    shopRequestFailure: false,
    installation_type: null,
    rav2_migration_onboarding: false,
    shopRecommendation: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOPS_REQUEST:
            return {
                ...state,
                shopRequesting: true,
            };
        case SHOPS_SUCCESS:
            return {
                ...state,
                entities: action.payload,
                shopRequesting: false,
                shopRequestSuccess: true,
                shopRequestFailure: false,
            };
        case SHOPS_FAILURE:
        case SHOP_INSTALL_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case SHOPS_ENTITIES_UPDATE:
            return {
                ...state,
                entities: action.payload,
            };
        case SHOP_INSTALL_SUCCESS: {
            let isNewShop = true;
            let currentShopId = null;
            const entities = state.entities.map((entity) => {
                if (action.payload.shop && entity.id === action.payload.shop.id) {
                    isNewShop = false;
                    currentShopId = action.payload.shop.id;
                    return action.payload.shop;
                }
                return entity;
            });
            if (isNewShop && action.payload.shop) {
                currentShopId = action.payload.shop.id;
                entities.push(action.payload.shop);
            }

            return {
                ...state,
                entities,
                currentShopId: !!currentShopId && currentShopId,
                installation_type: action.payload && action.payload.installation_type,
                rav2_migration_onboarding: action.payload && action.payload.rav2_migration_onboarding,
                shopRequesting: false,
                shopRequestSuccess: true,
                shopRequestFailure: false,
            };
        }

        case SHOPS_CURRENT_SHOP_ID_UPDATE:
            return {
                ...state,
                currentShopId: action.payload,
            };

        case ON_INFRAME_FILTER_SHOPS: {
            const entities = state.entities.filter((shop) => shop.type !== SHOPIFY);
            return {
                ...state,
                entities,
            };
        }

        case GET_SHOP_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                shopRecommendation: action.payload.recommendation,
            };
        }

        case GET_SHOP_RECOMMENDATION_FAILURE: {
            return state;
        }

        case SHOP_UPDATE_REQUEST:
            return {
                ...state,
                shopRequesting: true,
            };

        case SHOP_UPDATE_SUCCESS:
            const entities = state.entities.map((entity) => {
                if (action.payload.shop && entity.id === action.payload.shop.id) {
                    return action.payload.shop;
                }
                return entity;
            });
            return {
                ...state,
                shopRequesting: false,
                entities,
            };

        case SHOP_UPDATE_FAILURE:
            return {
                ...state,
                shopRequesting: false,
            };

        default:
            return state;
    }
};
