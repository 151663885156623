import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import inIframe from 'libs/inIframe';
import { GRANTED } from 'constants/fbInvitaionStatuses';
import { setInframeTrue } from 'actions/inframe';
import {
    isQualified,
    isQualifiedByCriterias,
    SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT,
    SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN,
    SHOP_QUALIFICATION_CRITERIA_ORDERS,
} from 'helpers/shopQualifacation';
import { getCurrentShopSelector, getCurrentBMUserSelector } from 'helpers/selectors';
import {
    isRecurringChargeApproved,
    isStripeAdded,
    isRetargetAppFeeApproved,
    isFacebookPaymentMethodExist,
    isCampaignInfoSaved,
    isFBPageInfoSaved,
    checkFBPage,
    choosenCampaignType,
} from 'helpers/onboardingHelper';
import { setLocalStorageObjectItem } from 'helpers';
import { SPENDS_PRICE, SPENDS_PERCENTS } from 'constants/userBillingType';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { PROSPECTING, RETARGETING } from 'constants/campaign';
import { SHOPIFY, BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';
import { googleFlow } from 'modules/home/utils';
import { fetchOnboardings } from 'modules/onboarding/userBMFlow/helpers/getOnboardingState';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import Wizard from '../components/Wizard';

export class OnboardingMiddleware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
        if (inIframe()) {
            this.props.setInframeTrue();
        }
    }

    facebookOnboardingFlow = () => {
        const {
            replace,
            params: { shopId },
            currentBMuser,
            page,
            hasOldBM,
            businessManager,
            adAccount,
            account,
            shopQualificationCriterias,
            currentShop,
            domainIsVerified,
            pixelIsShared,
            campaignInfoIsSaved,
            retargetAppFeeIsApproved,
            facebookBillingIsApproved,
            campaignIsCreated,
            fbPageInfoIsSaved,
            businessModel,
            choosenCampaignType,
            subscriptionPlan,
        } = this.props;

        if (
            campaignIsCreated &&
            (hasOldBM ||
                (page &&
                    businessManager &&
                    adAccount &&
                    currentBMuser?.status === GRANTED &&
                    domainIsVerified &&
                    pixelIsShared &&
                    campaignInfoIsSaved &&
                    retargetAppFeeIsApproved &&
                    facebookBillingIsApproved &&
                    fbPageInfoIsSaved))
        ) {
            replace(`/${shopId}/`);
            return;
        }

        if (!account.entity?.fb_user_token) {
            replace(`/${shopId}/onboarding/login`);
            return;
        }

        setLocalStorageObjectItem('currentOnboarding', ONBOARDING_TYPES.facebook_childbm);

        if (!fbPageInfoIsSaved || !page || !businessManager || !adAccount) {
            if (currentShop && !isQualified(shopQualificationCriterias)) {
                if (
                    !isQualifiedByCriterias(shopQualificationCriterias, [
                        SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN,
                        SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT,
                        SHOP_QUALIFICATION_CRITERIA_ORDERS,
                    ])
                ) {
                    replace(`/${shopId}/onboarding/unqualified`);
                    return;
                }
            }

            replace(`/${shopId}/onboarding/page`);
            return;
        }

        if (page && businessManager && adAccount && currentBMuser?.status !== GRANTED && fbPageInfoIsSaved) {
            replace(`/${shopId}/onboarding/invitation`);
        }

        if (!campaignIsCreated && currentBMuser?.status === GRANTED) {
            if (!domainIsVerified) {
                replace(`/${shopId}/onboarding/verify-domain`);
                return;
            }

            if (domainIsVerified && !pixelIsShared) {
                replace(`/${shopId}/onboarding/share-pixel`);
                return;
            }

            if (pixelIsShared && !choosenCampaignType) {
                replace(`/${shopId}/onboarding/choose-campaign`)
            }

            if (choosenCampaignType && !campaignInfoIsSaved) {
                if (choosenCampaignType === RETARGETING) {
                    replace(`/${shopId}/onboarding/set-up-retargeting`);
                    return;
                }

                if (choosenCampaignType === PROSPECTING) {
                    replace(`/${shopId}/onboarding/set-up-prospecting`);
                    return;
                }
            }

            if (campaignInfoIsSaved && !subscriptionPlan && ![SPENDS_PERCENTS, SPENDS_PRICE].includes(businessModel)) {
                replace(`/${shopId}/onboarding/choose-plan`);
                return;
            }

            if (campaignInfoIsSaved && !retargetAppFeeIsApproved) {
                if (currentShop?.type === SHOPIFY) {
                    replace(`/${shopId}/onboarding/shopify-billing`);
                    return;
                }
                if (currentShop?.type === BIG_COMMERCE || currentShop?.type === WOO_COMMERCE) {
                    replace(`/${shopId}/onboarding/stripe-billing`);
                    return;
                }
            }

            if (campaignInfoIsSaved && retargetAppFeeIsApproved) {
                replace(`/${shopId}/onboarding/facebook-billing`);
                return;
            }
        }

        if (campaignIsCreated && currentBMuser?.status === GRANTED) replace(`/${shopId}/dashboard`);
    };

    googleOnboardingFlow = () => {
        const {
            params: { shopId },
            replace,
        } = this.props;
        replace(`/${shopId}/google`);
    };

    userBMOnboardingFlow = () => {
        const {
            params: { shopId },
            replace,
        } = this.props;
        replace(`/${shopId}/user-bm`);
    };

    componentDidMount() {
        const {
            isGoogleAuthorized,
            params: { shopId },
        } = this.props;
 
        if (isGoogleAuthorized && googleFlow.get() !== 'none') {
            this.googleOnboardingFlow();
        } else {
            fetchOnboardings(shopId).then(res => {
                const prospectingOnboarding = res.onboardings.find((onboarding) => onboarding.name === ONBOARDING_TYPES.facebook_prospecting);
                const retargetingOnboarding = res.onboardings.find((onboarding) => onboarding.name === ONBOARDING_TYPES.facebook_visitor_retargeting);
                if (
                    retargetingOnboarding?.state !== 'closed' && !!retargetingOnboarding ||
                    prospectingOnboarding?.state !== 'closed' && !!prospectingOnboarding) {
                    this.userBMOnboardingFlow();
                } else {
                    this.setState({ isLoading: false });
                    this.facebookOnboardingFlow();
                }
            });
        }
    }

    render() {
        const { children } = this.props;
        const { isLoading } = this.state;
        return (
            <>
                <LoaderFullScreen
                    isLoaderShowed={isLoading}
                >
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
                <Wizard>{children}</Wizard>
            </>
        )
    }
}

const mapStateToProps = ({
    shopsCampaignsSettings,
    businessManager,
    account,
    shops,
    shopQualification,
    shopSubscription,
    shopRecurringCharge,
    shopBillingInfo,
    onboarding,
    shopError,
    campaignDetails,
    google,
    featureFlags,
}) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    checkFBPage(currentShop.id, shopsCampaignsSettings);
    const recurringChargeIsApproved = isRecurringChargeApproved(shopRecurringCharge);
    const stripeIsAdded = isStripeAdded(onboarding.stripeAdd, currentShop);
    const retargetAppFeeIsApproved = isRetargetAppFeeApproved(currentShop, recurringChargeIsApproved, stripeIsAdded);
    const facebookBillingIsApproved = isFacebookPaymentMethodExist(shopError.shopError);
    return {
        account,
        page: shopsCampaignsSettings.entity && shopsCampaignsSettings.entity.page,
        businessManager: shopsCampaignsSettings.entity && shopsCampaignsSettings.entity.business_manager,
        adAccount: shopsCampaignsSettings.entity && shopsCampaignsSettings.entity.ad_account,
        currentBMuser: businessManager.data && getCurrentBMUserSelector(businessManager.data, account),
        hasOldBM: !!shopsCampaignsSettings?.entity?.fb_business_id,
        shopQualificationCriterias: shopQualification?.criterias,
        currentShop,
        domainIsVerified: currentShop?.params?.domain_verified_checkbox_checked,
        pixelIsShared: currentShop?.params?.pixel_shared_checkbox_checked,
        choosenCampaignType: choosenCampaignType(currentShop.id),
        campaignInfoIsSaved: isCampaignInfoSaved(currentShop.id),
        fbPageInfoIsSaved: isFBPageInfoSaved(currentShop.id),
        retargetAppFeeIsApproved,
        facebookBillingIsApproved,
        campaignIsCreated: campaignDetails?.campaignDetailsData.length,
        isGoogleAuthorized: google?.login?.authorized,
        businessModel: shopBillingInfo.businessModel,
        subscriptionPlan: shopSubscription.name,
    };
};

const mapDispatchToProps = {
    replace,
    setInframeTrue,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingMiddleware);
