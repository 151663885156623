import React from 'react';
import { PropTypes } from 'prop-types';

import { numberDigitSeparateWithSpace } from 'helpers';
import TipsWidget from 'components/TipsWidget';

const StatSubItem = ({ title, tips, prefix, postfix, currency, isFloat, valueCurrent }) => {
    const superscript = postfix || currency;
    const subItemValue = (prefix || '') + (numberDigitSeparateWithSpace(valueCurrent, !isFloat) || valueCurrent);

    return (
        <div>
            <div className="statistics-section-item__title statistics-section-secondary-item__title">
                <span>{title}</span>
                <TipsWidget placement="top">{tips}</TipsWidget>
            </div>
            <p className="statistics-section-item__value statistics-section-secondary-item__value">
                {valueCurrent ? (
                    <span>
                        {subItemValue}
                        <sup>{superscript}</sup>
                    </span>
                ) : (
                    '-'
                )}
            </p>
        </div>
    );
};

StatSubItem.defaultProps = {
    prefix: null,
    postfix: null,
    isFloat: false,
    valueCurrent: null,
    currency: null,
};

StatSubItem.propTypes = {
    title: PropTypes.string.isRequired,
    tips: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    isFloat: PropTypes.bool,
    valueCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    prefix: PropTypes.string,
    postfix: PropTypes.string,
    currency: PropTypes.string,
};

export default StatSubItem;
