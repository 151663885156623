import React from 'react';
import Button from 'components/Button';
import TipsWidget from 'components/TipsWidget';
import { SubscriptionPlanType } from 'constants/subscriptionPlanType';

import { BTN_NAME } from '../../index';
import { SocialIcons } from "../SocialIcons/SocialIcons";

export const SubscriptionQuarterlyView = ({ choosePlanHandler }) => (
    <div className="choose-plan__main-section">
        <div className="choose-plan__item">
            <div className="choose-plan__info-wrapper">
                <h6>Starter</h6>
                <p className="choose-plan__price">$79 <span>/month</span></p>
                <p className="choose-plan__description">
                    <b>$237 billed quarterly <div className='choose-plan__badge'>Save $60</div></b>
                    7-Day Free Trial
                </p>
                <p className="choose-plan__description choose-plan__description--limit">
                    Ad spend limit: $1,000/month <br /> +10% fee on ad overspend
                </p>
                <ul className="choose-plan__list">
                    <li>
                        <span>Unlimited</span> automated campaigns <br /> across
                        <SocialIcons divider='and' />
                    </li>
                    <li>Email & chat support</li>
                </ul>
            </div>
            <Button className="choose-plan__cta-button" onClick={() => choosePlanHandler(SubscriptionPlanType.QUARTERLY_STARTER, BTN_NAME.QUARTERLY_STARTER)}>
                Choose Starter Plan
            </Button>
        </div>

        <div className="choose-plan__item choose-plan__item--accent">
            <div className="choose-plan__info-wrapper">
                <h6>Regular</h6>
                <p className="choose-plan__price choose-plan__price--accent">$199 <span>/month</span></p>
                <p className="choose-plan__description">
                    <b>$597 billed quarterly <div className='choose-plan__badge'>Save $150</div></b>
                    7-Day Free Trial
                </p>
                <p className="choose-plan__description choose-plan__description--limit">
                    Ad spend limit: $3,000/month <br /> +10% fee on ad overspend
                </p>
                <ul className="choose-plan__list">
                    <li><span>Unlimited</span>  automated campaigns <br /> across
                        <SocialIcons divider='and' />
                    </li>
                    <li>Email & chat support</li>
                    <li className='highlighted'>
                        <span>Monthly</span> ad performance <br/> review by an ad expert
                        <TipsWidget placement="right" additionalIconClassName='choose-plan__tooltip-icon'>
                            <p className="tips-description__content">
                                Every 30 days our in-house Ad Expert, <br />who has 10+ years of experience in online ads for eCommerce, will review your account performance, and, if necessary, make changes and provide ad improvement suggestions.
                            </p>
                        </TipsWidget>
                    </li>
                </ul>
            </div>
            <Button color="green"
                    className="choose-plan__cta-button"
                    onClick={() => choosePlanHandler(SubscriptionPlanType.QUARTERLY_REGULAR, BTN_NAME.QUARTERLY_REGULAR)}>
                Choose Regular Plan
            </Button>
        </div>
    </div>
);