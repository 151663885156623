import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'components/Modal/Modal';

import Button from 'components/Button';
import { displayBulkNotification } from 'actions/notification';
import { requestShopSubscription } from 'actions/shop';

import './ConfirmUpgradeModal.css';

const CHARGE_ERROR = 'charge-error-occurred';

const ConfirmUpgradeModal = ({
    isOpen,
    currentShopId,
    setOpenConfirmUpgradeModal,
    upgradeToPlan,
    chargeAmount,
    displayBulkNotification,
    requestShopSubscription,
    goBack
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModal = () => {
        setOpenConfirmUpgradeModal(false);
    };

    const upgradeSubscriptionPlan = () => fetch(`/api/shop/${currentShopId}/subscription`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({"update_to": upgradeToPlan}),
    }).then((res) => {
        if (res.status === 400) {
            throw res.json();
        }
        return res.json();
    });

    const upgradeSubscriptionPlanHandler = () => {
        setIsLoading(true);
        upgradeSubscriptionPlan()
            .then((res) => {
                if (!(res instanceof Error)) {
                    requestShopSubscription(currentShopId).then(() => {
                       displayBulkNotification(`Your plan has been changed to ${upgradeToPlan}`);
                        goBack();
                    });
                } else {
                    displayBulkNotification("We couldn't change your plan. Check payment info and try again.", 'error');
                }
                setIsLoading(false);
                handleCloseModal();
            })
            .catch((error) => {
                error.then((err) => {
                    err.data.error === CHARGE_ERROR
                        ? displayBulkNotification("Billing plan upgrade failure due to failed charge.", 'error')
                        : displayBulkNotification("We couldn't change your plan. Check payment info and try again.", 'error');
                });
                setIsLoading(false);
                handleCloseModal();
            });
    };

    return (
        <Modal className="confirm-upgrade-plan__modal" isOpen={isOpen} centered>
            <ModalBody>
                <div className="confirm-upgrade-plan__modal__body">
                    <h2 className="confirm-upgrade-plan__modal__title">You are upgrading to a {upgradeToPlan} plan</h2>

                    <div className="confirm-upgrade-plan__modal__content">
                        Once you confirm the change, you will be charged {`$${chargeAmount}`}.
                    </div>
                    <div className="confirm-upgrade-plan__modal__content">
                        Your plan will be changed immediately.
                    </div>
                    <div>
                        <Button
                            submit
                            color="green"
                            className="confirm-upgrade-plan-cta-button"
                            onClick={upgradeSubscriptionPlanHandler}
                            pending={isLoading}
                        >
                            Confirm
                        </Button>

                        <Button
                            className="confirm-upgrade-plan-cta-button"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapDispatchToProps = {
    displayBulkNotification,
    requestShopSubscription,
};

export default connect(null, mapDispatchToProps)(ConfirmUpgradeModal);
