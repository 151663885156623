import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

const SelectTitle = ({ fbPagesTips, handleCreateNewFbPage }) => (
    <div className="fb-page-one-page__notice">
        <div className="fb-page-one-page-notice__title">
            <span>Available Facebook pages</span> {fbPagesTips('fb-page__tips-icon')}
        </div>

        <button className="link" onClick={handleCreateNewFbPage}>
            Create а new page
        </button>
    </div>
);

SelectTitle.propTypes = {
    fbPagesTips: PropTypes.func.isRequired,
    handleCreateNewFbPage: PropTypes.func.isRequired,
};

const ConnectFacebookPage = ({ pages, fbPagesTips, handleCreateNewFbPage, handleChosenFbPage }) => {
    const { id, name, fan_count, business } = pages[0] || {};

    return (
        <div className="fb-page-one-page__wrapper">
            <SelectTitle fbPagesTips={fbPagesTips} handleCreateNewFbPage={handleCreateNewFbPage} />

            <div className="fb-page-one-page__info-block">
                <p className="fb-page-one-page-info-block__title" title={name}>
                    {name}
                </p>
                <p className="fb-page-one-page-info-block__subtitle">Likes {fan_count}</p>
            </div>

            <Button
                large
                color="primary"
                className="fb-page__cta-button-secondary"
                onClick={() => handleChosenFbPage(id, business)}
            >
                Connect
            </Button>
        </div>
    );
};

ConnectFacebookPage.propTypes = {
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            fan_count: PropTypes.number.isRequired,
            business: PropTypes.string.isRequired,
        }),
    ).isRequired,
    fbPagesTips: PropTypes.func.isRequired,
    handleChosenFbPage: PropTypes.func.isRequired,
    handleCreateNewFbPage: PropTypes.func.isRequired,
};

const ChosenFbPageInfo = ({ togglePagesList, chosenFbPageInfo }) => (
    <button className="fb-page__cta-button fb-page__cta-button_with-selected-item" onClick={togglePagesList}>
        <span className="fb-page-cta-button-with-selected-item__title" title={chosenFbPageInfo?.name}>
            {chosenFbPageInfo?.name}
        </span>
        <span className="fb-page-cta-button-with-selected-item__subtitle">Likes {chosenFbPageInfo?.fan_count}</span>
    </button>
);

ChosenFbPageInfo.propTypes = {
    togglePagesList: PropTypes.func.isRequired,
    chosenFbPageInfo: PropTypes.shape({
        name: PropTypes.string.isRequired,
        fan_count: PropTypes.number.isRequired,
    }).isRequired,
};

const PagesInfo = ({
    facebook,
    pages,
    togglePagesList,
    fbPagesTips,
    chosenFBPageId,
    handleChosenFbPage,
    handleCreateNewFbPage,
}) => {
    const isFacebookPagesListEmpty = facebook.pages.fbPagesRequestSuccess && pages.length === 0;
    if (isFacebookPagesListEmpty) {
        return (
            <Button className="fb-page__cta-button-secondary" color="primary" large onClick={handleCreateNewFbPage}>
                Create Facebook Page
            </Button>
        );
    }

    const isFacebookPageExist = pages.length === 1;
    if (isFacebookPageExist) {
        return (
            <ConnectFacebookPage
                pages={pages}
                fbPagesTips={fbPagesTips}
                handleChosenFbPage={handleChosenFbPage}
                handleCreateNewFbPage={handleCreateNewFbPage}
            />
        );
    }

    const [chosenFbPageInfo] = pages.filter((page) => page.id === chosenFBPageId);
    if (chosenFbPageInfo) {
        return <ChosenFbPageInfo togglePagesList={togglePagesList} chosenFbPageInfo={chosenFbPageInfo} />;
    }

    return (
        <div className="fb-page-one-page__wrapper">
            <SelectTitle fbPagesTips={fbPagesTips} handleCreateNewFbPage={handleCreateNewFbPage} />
            <button className="fb-page__cta-button" onClick={togglePagesList} disabled={pages.length === 0}>
                Select Facebook page
            </button>
        </div>
    );
};

PagesInfo.propTypes = {
    facebook: PropTypes.shape({
        pages: PropTypes.shape({
            fbPagesRequestSuccess: PropTypes.bool.isRequired
        }),
    }).isRequired,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            fan_count: PropTypes.number.isRequired,
            business: PropTypes.string.isRequired,
        }),
    ).isRequired,
    chosenFBPageId: PropTypes.string.isRequired,
    fbPagesTips: PropTypes.func.isRequired,
    togglePagesList: PropTypes.func.isRequired,
    handleChosenFbPage: PropTypes.func.isRequired,
    handleCreateNewFbPage: PropTypes.func.isRequired,
};

export default PagesInfo;
