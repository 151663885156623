import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PopupWidget, useCalendlyEventListener } from 'react-calendly';

import './CustomerInterviewBanner.css';

const CustomerInterviewInvite = ({ hideCustomerInterviewInviteBanner, removeCustomerInterviewInviteBanner, sendEventMetrics }) => {
    useCalendlyEventListener({
        onEventScheduled: () => removeCustomerInterviewInviteBanner(),
    });

    return (
        <section
            className={classNames('customer-interview-invite', {
                'customer-interview-invite--home': window.location.pathname.endsWith('/home'),
            })}
        >
            <div
                className="customer-interview-invite-close-btn"
                onClick={() => {
                    removeCustomerInterviewInviteBanner();
                    sendEventMetrics('cancel_customer_interview');
                }}
            />
            <header className="customer-interview-invite-header">
                <h2 className="customer-interview-invite-title">
                    Share your experience & get <span>7 more days of free trial</span>
                </h2>
            </header>
            <div className="customer-interview-invite__body">
                <p>
                    We invite you to share your unique perspective and experience with Adwisely during a <strong>30-minute interview</strong>.
                    To thank you for your time, we offer <strong>7 more days of free trial</strong> once the interview is complete. <br />
                    Click <strong>"Book a call"</strong> to choose time and date  <span role="img" aria-label="heart">💛</span>
                </p>
            </div>
            <div className="customer-interview-invite-cta">
                <div
                    className="custom-calendly-button"
                    onClick={() => sendEventMetrics('book_customer_interview')}
                >
                    <PopupWidget
                        url="https://calendly.com/sg-adwisely/30min"
                        rootElement={document.getElementById("root")}
                        text="Book a call"
                        textColor="#00223D"
                        color="#F4CD2B"
                    />
                </div>
                <span
                    onClick={() => {
                        hideCustomerInterviewInviteBanner();
                        sendEventMetrics('skip_customer_interview');
                    }}
                    className="customer-interview-invite-link"
                >
                    Remind me later
                </span>
            </div>
        </section>
    )
};

CustomerInterviewInvite.propTypes = {
    hideCustomerInterviewInviteBanner: PropTypes.func.isRequired,
    removeCustomerInterviewInviteBanner: PropTypes.func.isRequired,
};

export default CustomerInterviewInvite;
