import React, { Component } from 'react';
import { connect } from 'react-redux';

export default (WrappedComponent, ...args) => {
    const [propNames, predicate, onFail] = args;

    class DecoratedComponent extends Component {
        componentDidMount() {
            const { checkData } = this.props;

            if (!predicate(checkData)) {
                this.props.onfail(this.props);
            }
        }

        render() {
            const { checkData, onfail, ...otherProps } = this.props;
            if (predicate(checkData)) {
                return <WrappedComponent {...otherProps} />;
            }
            return <div />;
        }
    }

    const mapStateToProps = (state) => {
        const propsObj = {};
        propNames.forEach((prop) => {
            propsObj[prop] = state[prop];
        });
        return {
            checkData: propsObj,
        };
    };

    const mapPropsToDispatch = {
        onfail: onFail,
    };

    return connect(mapStateToProps, mapPropsToDispatch)(DecoratedComponent);
};
