import { SHOPIFY, BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';
import { RETARGETING } from 'constants/campaign';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import { getLocalStorageObjectItem } from 'helpers';

export const isRecurringChargeApproved = (shopRecurringCharge) => {
    const validStatuses = ['accepted', 'active'];
    if (shopRecurringCharge.entity) {
        return validStatuses.includes(shopRecurringCharge.entity.status);
    }
    return false;
};

export const isStripeAdded = (stripeAdd, currentShop) =>
    stripeAdd.success || (currentShop && currentShop?.stripe_customer_id);

export const isRetargetAppFeeApproved = (currentShop, recurringChargeIsApproved, stripeIsAdded) => {
    if (currentShop?.type === BIG_COMMERCE || currentShop?.type === WOO_COMMERCE) {
        if (typeof stripeIsAdded === 'string') return !!stripeIsAdded;
        return stripeIsAdded;
    }
    if (currentShop?.type === SHOPIFY) {
        return recurringChargeIsApproved;
    }
    return false;
};

export const isFacebookPaymentMethodExist = (shopError) =>
    !(Object.keys(shopError).length !== 0 && shopError?.errorToken === 'payment-method-not-found');

export const getFacebookPaymentStatusType = (facebookBillingIsApproved) =>
    facebookBillingIsApproved ? 'success' : 'preview';

const getSavedInfo = (arrayOfInfo, currentShopId) => arrayOfInfo.find((item) => +item.shopId === +currentShopId);

const arrayOfShopsInfo = JSON.parse(campaignCreateStorageHelper.getCampaignInfoIsSaved()) || [];
export const isCampaignInfoSaved = (currentShopId) => getSavedInfo(arrayOfShopsInfo, currentShopId)?.isSaved || false;
export const choosenCampaignType = (currentShopId) => getSavedInfo(arrayOfShopsInfo, currentShopId)?.campaignType || false;

export const saveCurrentShopCampaignType = (currentShopId, campaignType) => {
    const currentShopInfo = getSavedInfo(arrayOfShopsInfo, currentShopId);
    if (!currentShopInfo) {
        arrayOfShopsInfo.push({
            shopId: currentShopId,
            isSaved: false,
            campaignType,
        });
        campaignCreateStorageHelper.setCampaignInfoIsSaved(arrayOfShopsInfo);
    } else {
        currentShopInfo.campaignType = campaignType;
        campaignCreateStorageHelper.setCampaignInfoIsSaved(arrayOfShopsInfo);
    }
};

export const saveCurrentShopInfo = (currentShopId, campaignType = RETARGETING) => {
    const currentShopInfo = getSavedInfo(arrayOfShopsInfo, currentShopId);
    if (!currentShopInfo) {
        arrayOfShopsInfo.push({
            shopId: currentShopId,
            isSaved: true,
            campaignType,
        });
        campaignCreateStorageHelper.setCampaignInfoIsSaved(arrayOfShopsInfo);
    } else {
        currentShopInfo.isSaved = true;
        campaignCreateStorageHelper.setCampaignInfoIsSaved(arrayOfShopsInfo);
    }
};

const arrayOfFBPagesInfo = JSON.parse(campaignCreateStorageHelper.getFBPageIsSaved()) || [];
export const isFBPageInfoSaved = (currentShopId) => getSavedInfo(arrayOfFBPagesInfo, currentShopId)?.isSaved || false;

export const checkFBPage = (currentShopId, shopsCampaignsSettings) => {
    const currentFBPageInfo = getSavedInfo(arrayOfFBPagesInfo, currentShopId);
    if (shopsCampaignsSettings.entity?.page && !currentFBPageInfo) {
        arrayOfFBPagesInfo.push({
            shopId: currentShopId,
            isSaved: true,
        });
        campaignCreateStorageHelper.setFBPageIsSaved(arrayOfFBPagesInfo);
    }
};

export const checkCurrentOnboarding = (onboardingName) => getLocalStorageObjectItem('currentOnboarding') === onboardingName;
