import React from 'react';
import './MessengerMobile.css';

import background from './img/background.jpg';

export const MessengerMobile = ({product}) => (
    <div className='messenger-mobile'>
        <img className='messenger-mobile__background' src={background} alt="messenger background"/>
        <div className='messenger-mobile__blur messenger-mobile__blur--1' />
        <div className='messenger-mobile__blur messenger-mobile__blur--2' />
        <div className='messenger-mobile__adv'>
            <div className='messenger-mobile__avatar' />
            <div className='messenger-mobile__adv-main'>
                <div className='messenger-mobile__title'>{product.description}</div>
                <div className='messenger-mobile__description'>{product.subtitle}
                </div>
                <div className='messenger-mobile__link'>View more</div>
            </div>
            <div className='messenger-mobile__img-wrapper'>
                <img src={product.img} alt={product.title} />
            </div>
        </div>
    </div>
)