import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { goToLogin, goAfterLogin, confirmLoginWithCode } from 'actions/login';
import { LoaderLongWait as Loader } from 'components/Loader';
import { trackEventFacebookLoginDeclined } from 'actions/intercom';

import intercom from 'libs/intercom';

import { FACEBOOK_LOGIN_CALLBACK_URL, TOKEN, LOGIN_ERROR_MESSAGES } from '../../const';

/**
 * Is facebook params valid
 * @param params {Object}
 * @return {boolean}
 */
function isValidFacebookInstallParams(params) {
    const { code, error } = params;
    return code && !error;
}

/**
 * Is request was declined by user
 * @param params {Object}
 * @return {boolean}
 */
function isUserDeclinedLoginRequest(params) {
    const { error, error_reason } = params;
    return error && error_reason === 'user_denied';
}

export class LoginCallback extends Component {
    componentDidMount() {
        this.validateState();
    }

    UNSAFE_componentWillUpdate(nextProps) {
        const { goAfterLogin, goToLogin, trackEventFacebookLoginDeclined } = this.props;

        const redirectTo = window.localStorage.getItem(FACEBOOK_LOGIN_CALLBACK_URL);

        if (this.props.login !== nextProps.login) {
            if (nextProps.login.confirmFailure) {
                if (nextProps.login.confirmError.errorToken) {
                    let msg = '';
                    switch (nextProps.login.confirmError.errorToken) {
                        case TOKEN.MISSING_SCOPES:
                            trackEventFacebookLoginDeclined();
                            msg = LOGIN_ERROR_MESSAGES.DECLINE;
                            break;
                        case TOKEN.MISSING_EMAIL:
                            msg = LOGIN_ERROR_MESSAGES.NO_USER_EMAIL;
                            break;
                        default:
                            msg = LOGIN_ERROR_MESSAGES.FAILED;
                            break;
                    }
                    goToLogin(msg);
                } else {
                    goToLogin(LOGIN_ERROR_MESSAGES.BAD_PARAMS);
                }
            }

            if (nextProps.login.confirmSuccess) {
                window.localStorage.removeItem(FACEBOOK_LOGIN_CALLBACK_URL);
                goAfterLogin(redirectTo);
            }
        }
    }

    validateState() {
        const {
            goToLogin,
            facebookLogin,
            login,
            location: { query },
            trackEventFacebookLoginDeclined,
        } = this.props;

        if (isUserDeclinedLoginRequest(query)) {
            trackEventFacebookLoginDeclined();
            goToLogin(LOGIN_ERROR_MESSAGES.DECLINE);
            return;
        }
        if (!isValidFacebookInstallParams(query)) {
            goToLogin(LOGIN_ERROR_MESSAGES.BAD_PARAMS);
            return;
        }
        if (!login.confirmRequest && !login.confirmSuccess && !login.confirmFailure) {
            intercom.checkIntercomInited(100, 10).then((intercom_visitor_id) => {
                facebookLogin({
                    code: query.code,
                    intercom_visitor_id,
                });
            });
        }
    }

    render() {
        return (
            <div className="login-view__white-substrate">
                <Loader />
            </div>
        );
    }
}

LoginCallback.propTypes = {
    goToLogin: PropTypes.func.isRequired,
    goAfterLogin: PropTypes.func.isRequired,
};

export default connect(
    ({ facebook: { login } }) => ({
        login,
    }),
    {
        facebookLogin: confirmLoginWithCode,
        goToLogin,
        goAfterLogin,
        trackEventFacebookLoginDeclined,
    },
)(LoginCallback);
