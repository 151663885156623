import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Banners from 'components/Banners';
import {
    goToDashboard,
    setAutoCreateCampaignType,
    requestCreateCampaign,
    clearAutoCreateCampaignType,
    requestCampaignSuggestionsForCampaignCreate,
} from 'actions/campaign';

import { requestShopNotificationsDeleteProspectingInvite } from 'actions/notification';
import { updateRecurringChange, validateShop } from 'actions/shop';

import RecurringChargeModal from 'components/ModalWindowCharge/ModalWindowUpdateChargeProspecting';
import PricingWidget from 'components/PricingWidget/PricingWidget';
import Button from 'components/Button';
import { ArrowLeft } from 'components/Icons';
import pageNameSetter from 'decorator/pageNameSetter';

import { getCurrentShopSelector } from 'helpers/selectors';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';

import { PROSPECTING } from 'constants/campaign';
import { changeShopPage } from 'constants/metric';
import { SPENDS_PERCENTS } from 'constants/userBillingType';
import { PROSPECTING_INVITE } from 'constants/notificationType';
import { BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';

import CampaignInfo from '../components/CampaignInfo';
import CampaignDetails from '../components/CampaignDetails';
import SaveCampaignDetails from '../components/SaveCampaignDetails';
import ProspectingPreview from './ProspectingPreview';
import { CampaignPageLayout, CampaignPageCol } from '../components/CampaignPageLayout';
import prospectingIcon from '../../../img/prospecting-logo-icon.svg';
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from '../constants';

import './ProspectingCampaignCreate.css';

const CAMPAIGN_AD_MESSAGE_DEFAULT = 'You gonna love 😍 this. Treat yourself, you deserved it.';
const CAMPAIGN_TYPE_PROSPECTING = 'prospecting';
const PROSPECTING_RECURRING_CHARGE_AMOUNT = 3000;

export class ProspectingCampaignCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignMinBudget: 0,

            // save values before saving to localStorage:
            dailyBudget: 0,
            adMessage: '',

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            showUpdateRecurringChargeModal: false,
            saveCampaignError: null,
        };
        this.handleFormError = this.handleFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);
        this.createCampaign = this.createCampaign.bind(this);
        this.saveCampaignHandler = this.saveCampaignHandler.bind(this);
        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
            setAutoCreateCampaignType,
            checkShopForErrors,
        } = this.props;
        requestSuggestions({ id: shopId });
        const campaignType = campaignCreateStorageHelper.getCampaignType();
        if (campaignType) {
            setAutoCreateCampaignType(campaignType);
        }
        checkShopForErrors({ shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextCampaignAutoCreateType = nextProps.campaignAutoCreateType.type;
        let newRecurringChargeStatus = null;
        let cappedAmount = null;
        const {
            params: { shopId },
        } = nextProps;

        if (nextProps.shopRecurringCharge.entity) {
            newRecurringChargeStatus = nextProps.shopRecurringCharge.entity.status;
            cappedAmount = parseInt(nextProps.shopRecurringCharge.entity.capped_amount, 10);
        }

        if (
            nextCampaignAutoCreateType === CAMPAIGN_TYPE_PROSPECTING &&
            (newRecurringChargeStatus === 'accepted' || newRecurringChargeStatus === 'active') &&
            cappedAmount === PROSPECTING_RECURRING_CHARGE_AMOUNT &&
            shopId === campaignCreateStorageHelper.getCampaignShopId()
        ) {
            const dailyBudget = campaignCreateStorageHelper.getCampaignDailyBudget();
            const adMessage = campaignCreateStorageHelper.getCampaignAdMessage();
            this.createCampaign(dailyBudget, adMessage);
        }

        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = CAMPAIGN_AD_MESSAGE_DEFAULT;

                this.setState({
                    campaignBudget: suggestionsData.daily_budget.prospecting_suggested_value / 100,
                    campaignMinBudget: suggestionsData.daily_budget.min_value / 100,
                    campaignCurrency: suggestionsData.daily_budget.currency,
                    campaignAdMessage: suggestedAdMessage,
                });
            }
        }
    }

    handleFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        setTimeout(() => {
            this.setState({
                adMessageTagsHasError: !!tipsErrorText,
                adMessageTagsHasErrorTipsErrorText: tipsErrorText,
            });
        }, 0);
    }

    createCampaign(dailyBudget, adMessage) {
        if (!dailyBudget) return;
        const {
            requestCreateCampaign,
            clearAutoCreateCampaignType,
            goToNextStep,
            params: { shopId },
            removeProspectingInviteNotification,
        } = this.props;
        const params = {
            shopId,
            campaign_type: CAMPAIGN_TYPE_PROSPECTING,
            daily_budget: dailyBudget,
            messages: adMessage,
        };
        clearAutoCreateCampaignType();
        campaignCreateStorageHelper.clearStorage();
        const createCampaignPromise = requestCreateCampaign(params);
        const removeProspectingInviteNotificationPromise = removeProspectingInviteNotification({
            id: shopId,
            type: PROSPECTING_INVITE,
        });
        Promise.all([createCampaignPromise, removeProspectingInviteNotificationPromise]).then((result) => {
            if (!(result[0] instanceof Error)) {
                goToNextStep(params.shopId);
                this.setState({
                    saveCampaignError: null,
                });
            } else if (result.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                this.setState({
                    saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                });
            }
        });
    }

    saveCampaignHandler(dailyBudget, adMessage) {
        const {
            params: { shopId },
            shopRecurringCharge,
            suggestions,
            currentShop,
        } = this.props;

        if (currentShop?.type === BIG_COMMERCE || currentShop?.type === WOO_COMMERCE) {
            if (!adMessage || !dailyBudget || !currentShop.stripe_customer_id) return;
            this.createCampaign(dailyBudget, adMessage);
        } else {
            if (!shopRecurringCharge.success || !shopRecurringCharge.entity || !suggestions.campaignSuggestionsSuccess)
                return;

            if (shopRecurringCharge.entity.capped_amount) {
                const cappedAmount = parseInt(shopRecurringCharge.entity.capped_amount, 10);
                this.setState({
                    dailyBudget,
                    messages: adMessage,
                });
                if (
                    cappedAmount !== PROSPECTING_RECURRING_CHARGE_AMOUNT &&
                    currentShop.business_model === SPENDS_PERCENTS
                ) {
                    if (shopRecurringCharge.entity.update_url && shopRecurringCharge.entity.update_url.length) {
                        this.setState({
                            showUpdateRecurringChargeModal: true,
                        });
                    } else {
                        this.props.updateCharge(shopId, PROSPECTING_RECURRING_CHARGE_AMOUNT).then((res) => {
                            if (!(res instanceof Error)) {
                                this.setState({
                                    showUpdateRecurringChargeModal: true,
                                });
                            }
                        });
                    }
                } else {
                    this.createCampaign(dailyBudget, adMessage);
                }
            }
        }
    }

    campaignValidateFacebookHandler(params) {
        const {
            shopValidateRequesting,
            checkShopForErrors,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId, params).then((res) => {
                if (!(res instanceof Error)) {
                    checkShopForErrors({ shopId });
                }
            });
        }
    }

    render() {
        const campaignFormName = 'prospectingCampaignCreate';
        const {
            shopError,
            params: { shopId },
            shopRecurringCharge,
            suggestions: { campaignSuggestionsData },
            adPreviewError,
            currentShop,
            shopValidateRequesting,
            checkShopForErrors,
        } = this.props;

        let recurringChargeModal = null;
        if (this.state.showUpdateRecurringChargeModal) {
            recurringChargeModal = (
                <RecurringChargeModal
                    isOpenModal={this.state.showUpdateRecurringChargeModal}
                    cancelHandler={() => {
                        this.setState({ showUpdateRecurringChargeModal: false });
                    }}
                    approveChargeLink={shopRecurringCharge.entity.update_url}
                    approveHandler={() => {
                        campaignCreateStorageHelper.setCampaign(
                            CAMPAIGN_TYPE_PROSPECTING,
                            this.state.dailyBudget,
                            this.state.adMessage,
                            shopId,
                        );
                    }}
                />
            );
        }
        let shopErrorBanner = null;
        if (shopError && Object.keys(shopError).length) {
            shopErrorBanner = (
                <Banners
                    shopError={shopError || adPreviewError}
                    changeTipsErrorText={this.changeTipsErrorText}
                    handleFormError={this.handleFormError}
                    validationPending={shopValidateRequesting}
                    validateShopSettings={() => checkShopForErrors({ shopId })}
                    campaignValidateFacebook={this.campaignValidateFacebookHandler}
                    shopRecurringCharge={shopRecurringCharge}
                    checkShopForErrors={() => checkShopForErrors({ shopId })}
                    shopId={shopId}
                    currentShop={currentShop}
                />
            );
        }

        return (
            <div className="prospecting-campaign-create__wrapper">
                <Button className="prospecting-campaign-create__back-button" innerUrl={`/${shopId}/dashboard`}>
                    <ArrowLeft /> Back
                </Button>

                {shopErrorBanner}

                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            showIcon
                            shopId={shopId}
                            campaignTitle="Prospecting"
                            titleIconUrl={prospectingIcon}
                            learnMoreLink={
                                'https://help.adwisely.com/improve-your-campaign-results/' +
                                'learn-about-advertising-tactics/prospecting'
                            }
                            campaignDescription={
                                'Boost sales and acquire more customers by targeting new ' +
                                'users who are similar to your current buyers with the ads of ' +
                                'the products they are most likely to purchase.'
                            }
                            customPricingWidget={
                                <PricingWidget
                                    pricingItemTitle="Prospecting Ad Spend"
                                    description={
                                        'Adwisely fee is based on your total Monthly Ad Spends ' +
                                        'and can not be less than $15.00 per month'
                                    }
                                />
                            }
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={() => {}}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={this.state.campaignAdMessage}
                            campaignCurrency={this.state.campaignCurrency}
                            campaignMinBudget={this.state.campaignMinBudget}
                            campaignBudget={this.state.campaignBudget}
                            pricingAdviceItems={
                                campaignSuggestionsData && campaignSuggestionsData.daily_budget
                                    ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                                    : []
                            }
                            saveCampaign={this.saveCampaignHandler}
                            useDynamicTagsDropdown={false}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            dailyBudgetDescription={
                                'Daily budget is the maximum amount of money you are ready ' +
                                'to spend on your ads within a 24-hour period.'
                            }
                            saveCampaignError={this.state.saveCampaignError}
                            campaignType={PROSPECTING}
                        />

                        <SaveCampaignDetails
                            formName={campaignFormName}
                            pending={shopRecurringCharge.updateUrlPending}
                            buttonTitle="Run Prospecting Campaign"
                            buttonColor="primary"
                            hasCampaignFormError={
                                shopError instanceof Error ||
                                this.state.adMessageTagsHasError ||
                                !!this.state.hasCampaignFormError
                            }
                            tipsErrorText={this.state.adMessageTagsHasErrorTipsErrorText || this.state.tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <ProspectingPreview isAdExample />
                    </CampaignPageCol>
                </CampaignPageLayout>
                {recurringChargeModal}
            </div>
        );
    }
}

ProspectingCampaignCreate.defaultProps = {
    adPreviewError: null,
    shopError: null,
};

ProspectingCampaignCreate.propTypes = {
    adPreviewError: PropTypes.shape({
        errorToken: PropTypes.string,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    campaignAutoCreateType: PropTypes.shape({
        type: PropTypes.string
    }).isRequired,
    campaignValidateFacebook: PropTypes.func.isRequired,
    checkShopForErrors: PropTypes.func.isRequired,
    clearAutoCreateCampaignType: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        app_uninstalled_at: PropTypes.any,
        business_model: PropTypes.string,
        created_at: PropTypes.string,
        credentials: PropTypes.any,
        domain: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        owner_email: PropTypes.string,
        owner_name: PropTypes.string,
        platform_id: PropTypes.string,
        review_note: PropTypes.string,
        review_rate: PropTypes.any,
        review_status: PropTypes.string,
        stripe_customer_id: PropTypes.string,
        type: PropTypes.string,
        updated_at: PropTypes.string
    }).isRequired,
    goToNextStep: PropTypes.func.isRequired,
    params: PropTypes.shape({
        shopId: PropTypes.string.isRequired
    }).isRequired,
    removeProspectingInviteNotification: PropTypes.func.isRequired,
    requestCreateCampaign: PropTypes.func.isRequired.isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    setAutoCreateCampaignType: PropTypes.func.isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    shopRecurringCharge: PropTypes.shape({
        entity: PropTypes.shape({
            confirmation_url: PropTypes.string,
            capped_amount: PropTypes.string,
            status: PropTypes.string,
        }),
        pending: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        updateUrlPending: PropTypes.bool.isRequired,
    }).isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsData: PropTypes.shape({
            daily_budget: PropTypes.shape({
                suggested_value: PropTypes.number,
                prospecting_suggested_value: PropTypes.number,
                min_value: PropTypes.number,
                currency: PropTypes.string,
            }),
            ad_message: PropTypes.string
        }),
        campaignSuggestionsSuccess: PropTypes.bool,
    }).isRequired,
    updateCharge: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    campaignSuggestions,
    shops,
    shopError: { shopError, shopValidateRequesting },
    shopRecurringCharge,
    campaignAutoCreateType,
    campaign: { adPreviewError },
}) => ({
    currentShop: getCurrentShopSelector(shops, shops.currentShopId),
    suggestions: campaignSuggestions,
    adPreviewError,
    shops,
    shopError,
    shopRecurringCharge,
    campaignAutoCreateType,
    shopValidateRequesting,
});

const mapDispatchToProps = {
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    updateCharge: updateRecurringChange,
    requestCreateCampaign,
    clearAutoCreateCampaignType,
    goToNextStep: goToDashboard,
    setAutoCreateCampaignType,
    checkShopForErrors: validateShop,
    removeProspectingInviteNotification: requestShopNotificationsDeleteProspectingInvite,
};

const ProspectingCampaignCreateContainer = connect(mapStateToProps, mapDispatchToProps)(ProspectingCampaignCreate);

export default pageNameSetter(changeShopPage.create_prospecting)(ProspectingCampaignCreateContainer);
