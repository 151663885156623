import React from 'react';
import classNames from 'classnames';

import { WarningIcon } from './assets/WarningIcon';
import './ContextNotification.css';

const getNotificationContent = (contentItems) => {
    if (contentItems.length > 1) {
        return (
            <ul>
                {contentItems.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    } else if (contentItems.length === 1) {
        return <p>{contentItems[0]}</p>;
    }

    return '';
};

const ContextNotification = ({ type, contentItems }) => (
    <div className={`context-notification ${classNames({ 'context-notification_warning': type === 'warning' })}`}>
        <div
            className={`context-notification__sign-block ${classNames({
                'context-notification__sign-block_warning': type === 'warning',
            })}`}
        >
            <WarningIcon />
        </div>
        {getNotificationContent(contentItems)}
    </div>
);

export default ContextNotification;
