import * as onboarding from 'actions/onboarding';

const initialState = {
    version: 1,
    onboardings: [],
    onboardingsRequesting: false,
    onboardingsError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case onboarding.GET_ONBOARDINGS_REQUEST:
            state = {
                ...state,
                onboardingsRequesting: true,
            };
            break;

        case onboarding.GET_ONBOARDINGS_SUCCESS:
            state = {
                ...state,
                onboardings: action.payload.onboardings,
                onboardingsRequesting: false,
                onboardingsError: false,
            };
            break;

        case onboarding.GET_ONBOARDINGS_FAILURE:
            state = {
                ...state,
                onboardingsRequesting: false,
                onboardingsError: true,
            };
            break;
        case onboarding.SET_ONBOARDING_VERSION:
            state = {
                ...state,
                version: action.version
            };
            break;

        default:
            break;
    }
    return state;
};
