import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { setLocalStorageObjectItem } from 'helpers';
import {requestSendEventMetrics} from 'actions/shop';
import {metricType, fbCampaignTactics} from 'constants/metric';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { changeOnboardingState } from 'modules/home/utils';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';

import './SpecialOfferInvite.css';

const SpecialOfferInvite = ({ onClickHandler, shopId, requestSendEventMetrics, ownerType, replace }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <section className="special_offer-invite">
            <div className="special_offer-invite-close-btn" onClick={onClickHandler} />
            <header className="special_offer-invite-header">
                <h2 className="special_offer-invite-title">
                    Get more orders with a Special Offer campaign!
                </h2>
            </header>
            <span className="special_offer-invite__body">
                Run a short-term promo for a new collection, sale, sitewide discount,
                or a special holiday offer to brand new customers and store visitors.
            </span>
            <div className="special_offer-invite-cta">
                <Button
                    innerUrl={ownerType === 'child' && `/${shopId}/create/special-offer`}
                    color="green"
                    onClick={() => {
                        if (ownerType !== 'child') {
                            setLocalStorageObjectItem('currentOnboarding', ONBOARDING_TYPES.facebook_special_offer);
                            setIsLoading(true);
                            changeOnboardingState({
                                type: ONBOARDING_TYPES.facebook_special_offer,
                                status: UBM_ONBOARDING_STATES.not_started,
                                currentShopId: shopId,
                            })
                                .then((res) => res.json())
                                .then(res => {
                                    if (res?.processing) {
                                        facebookStatePolling(shopId, replace);
                                    }
                                });
                        }

                        const metricProps = {
                            shop_id: shopId,
                            type: metricType.fbCampaignInvitationAccepted,
                            props: {
                                tactic: fbCampaignTactics.special_offer,
                                source: 'dashboard',
                            },
                        };
                        requestSendEventMetrics(metricProps);
                    }}
                >
                    Set up Special Offer
                </Button>
                <Button
                    color="link"
                    className="special_offer-invite-link"
                    url="https://help.adwisely.com/en/articles/4585544-special-offer"
                >
                    Learn more
                </Button>
                <LoaderFullScreen
                    isLoaderShowed={isLoading}
                >
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
            </div>
        </section>
    )
};

SpecialOfferInvite.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    shopId: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
    requestSendEventMetrics,
    replace,
};

export default connect(null, mapDispatchToProps)(SpecialOfferInvite);
