import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getShopBillingPeriod } from 'actions/shop';
import {
    trackEventExtendTrialBadge,
    trackEventExtendTrialBadgeBC,
    trackEventExtendTrialBadgeWC,
} from 'actions/intercom';
import { BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';
import { TRIAL_FREE_TYPE, BONUS_FREE_TYPE } from 'constants/billingPeriod';
import { getCurrentShopSelector } from 'helpers/selectors';

import TipsWidget from 'components/TipsWidget';
import ExtendBudgetPopover from 'components/ExtendBudgetPopover';

import './TrialBadge.css';

import extendedInfoIcon from './icon-info-extended.svg';

export class TrialBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extendTrialClicked: false,
        };
        this.extendTrialBadge = this.extendTrialBadge.bind(this);
    }

    static getTipsWidget(widgetText, customIcon) {
        const infoIcon = <img src={customIcon} className="tips-widget-icon" alt="info" />;
        return (
            <TipsWidget placement="bottom" isDarkView customIcon={customIcon && infoIcon}>
                <div>
                    <p className="tips-description__content">{widgetText}</p>
                    <p className="tips-description__content">
                        <a
                            href={
                                'https://help.adwisely.com/' +
                                'learn-about-pricing-privacy-policy-and-terms/whats-the-cost-of-retargetapp'
                            }
                            className="link link-white"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            I want to learn more →
                        </a>
                    </p>
                </div>
            </TipsWidget>
        );
    }

    extendTrialBadge() {
        const {
            trackEventExtendTrialBadge,
            trackEventExtendTrialBadgeBC,
            trackEventExtendTrialBadgeWC,
            currentShop,
        } = this.props;

        if (currentShop?.type === BIG_COMMERCE) {
            trackEventExtendTrialBadgeBC();
        } else if (currentShop?.type === WOO_COMMERCE) {
            trackEventExtendTrialBadgeWC();
        } else {
            trackEventExtendTrialBadge();
        }
        this.setState({
            extendTrialClicked: true,
        });
    }

    render() {
        const { trialDaysLeft, isCreatingCampaign, badgeType, extendTrialLabel, currentShop } = this.props;

        const currentShopType = currentShop?.type;
        const currentShopDomain = currentShop?.domain;

        let tipsWidget = TrialBadge.getTipsWidget(
            'During the trial you will pay for ads directly ' +
                'to Facebook and/or Google, however, Adwisely service will be free.',
        );
        if (trialDaysLeft > 0) {
            if (badgeType === TRIAL_FREE_TYPE) {
                if (extendTrialLabel && !this.state.extendTrialClicked) {
                    tipsWidget = TrialBadge.getTipsWidget(
                        'During the trial you will pay for ads directly ' +
                            'to Facebook and/or Google, however, Adwisely service will be free.',
                        extendedInfoIcon,
                    );
                    return (
                        <ExtendBudgetPopover
                            trackExtendTrialBadgeEvent={this.extendTrialBadge}
                            currentShopType={currentShopType}
                            currentShopDomain={currentShopDomain}
                        >
                            <button
                                className={classNames('trial-badge', 'trial-badge-extended')}
                                id="trialBadgeContainer"
                            >
                                <div className="trial-badge-extended_block">
                                    <p>Want to extend the trial?</p>
                                    {trialDaysLeft > 1 ? (
                                        <span>
                                            {trialDaysLeft}
                                            {isCreatingCampaign ? '-day free trial' : ' days of trial left'}
                                        </span>
                                    ) : (
                                        <span>Trial period ends today</span>
                                    )}
                                </div>
                                {tipsWidget}
                            </button>
                        </ExtendBudgetPopover>
                    );
                }

                return (
                    <div
                        className={classNames('trial-badge', { 'trial-badge_absolute': isCreatingCampaign })}
                        id="trialBadgeContainer"
                    >
                        {' '}
                        {trialDaysLeft > 1 ? (
                            <span className="trial-badge__title">
                                {trialDaysLeft}
                                {isCreatingCampaign ? '-day free trial' : ' days of trial left'}
                            </span>
                        ) : (
                            <span className="trial-badge__title">Trial period ends today</span>
                        )}
                        {tipsWidget}
                    </div>
                );
            }

            if (badgeType === BONUS_FREE_TYPE) {
                tipsWidget = TrialBadge.getTipsWidget(`During a bonus period, you don't pay Adwisely fee.
                                    You still pay for ads directly to Facebook and/or Google.`);
                return (
                    <div
                        className={classNames('trial-badge trial-badge-bonus', {
                            'trial-badge_absolute': isCreatingCampaign,
                        })}
                        id="bonusBadgeContainer"
                    >
                        <span className="trial-badge__title">
                            {trialDaysLeft > 1
                                ? `${trialDaysLeft} days of bonus period left`
                                : 'Bonus period ends today'}
                        </span>
                        {tipsWidget}
                    </div>
                );
            }
        }

        return null;
    }
}

TrialBadge.defaultProps = {
    isCreatingCampaign: false,
};

TrialBadge.propTypes = {
    trialDaysLeft: PropTypes.number.isRequired,
    isCreatingCampaign: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    trialDaysLeft: state.shopBillingPeriod.trialDaysLeft,
    badgeType: state.shopBillingPeriod.freeType,
    extendTrialLabel: state.shopBillingPeriod.extendTrialLabel,
    currentShop: getCurrentShopSelector(state.shops, state.shops.currentShopId),
});

const mapDispatchToProps = {
    getBillingPeriod: getShopBillingPeriod,
    trackEventExtendTrialBadge,
    trackEventExtendTrialBadgeBC,
    trackEventExtendTrialBadgeWC,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialBadge);
