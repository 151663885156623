import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { requestGetUserBMList } from 'actions/businessManager';
import { setOnboardingVersion } from 'actions/onboarding';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { getLocalStorageObjectItem } from 'helpers';


import Wizard from '../components/Wizard';

const UserBMOnboardingMiddleware = ({ children, currentShopId, replace, requestGetUserBMList, setOnboardingVersion }) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        requestGetUserBMList(currentShopId, currentOnboarding);
        facebookStatePolling(
            currentShopId,
            replace,
            (res) => {
                setIsLoading(false);
                if (res.onboarding_version) {
                    setOnboardingVersion(res.onboarding_version)
                }
            },
            signal
        );
        return () => {
            controller.abort();
        }
    }, []);

    return <Wizard>{!isLoading && children}</Wizard>;
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    requestGetUserBMList,
    replace,
    setOnboardingVersion
};

export default connect(mapStateToProps, mapPropsToDispatch)(UserBMOnboardingMiddleware);
