export const LEAVE_REVIEW_INVITE = 'leave_review_invite';
export const PROSPECTING_INVITE = 'prospecting_invite';
export const RETARGETING_INVITE = 'retargeting_invite';
export const SPECIAL_OFFER_INVITE = 'special_offer_invite';
export const READ_ALL_ORDERS_REQUEST = 'read_all_orders_request';
export const RETARGETAPP_UPDATE_SUCCESS = 'retargetapp_update_success';
export const CUSTOM_CAMPAIGN = 'custom_campaign_invite';
export const DOMAIN_VERIFICATION_BANNER = 'shop_domain_not_verified_on_facebook';
export const AD_SPENDS_LIMIT_REACHED = 'ad_spends_limit_reached';
export const MANUAL_ONBOARDING_MODAL = 'manual_onboarding_invite';
export const NON_DISCRIMINATION_POLICY = 'non_discrimination_policy';
export const CUSTOMER_INTERVIEW_INVITE = 'customer_interview_invite';
export const SUBSCRIPTION_BILLING_MIGRATION = 'subscription_billing_migration';
export const AI_BOOSTED_SALES_INVITE = 'ai_boosted_sales_invite'