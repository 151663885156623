import React from 'react';
import intercom from 'libs/intercom';
import Button from 'components/Button';

import '../../Error.css';

const PolicyError = ({ userBM }) => (
    <div className="user-bm-error__wrapper">
        <h1 className="user-bm-error__title">We couldn't create a new Ad Account - but it's okay</h1>
        <div className="user-bm-error__body-wrapper">
            <p className="user-bm-error__description">
                It appears that some Ad Accounts in Business Manager <strong>{userBM?.name} (ID: {userBM?.id})</strong> have previously violated {''}
                <a 
                    href="https://www.facebook.com/policies/ads/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="primary-link in-text"
                >
                    Meta Ads Policies
                </a>
            </p>
            <p className="user-bm-error__description">
                We encourage you to follow Meta Advertising Policies, so, before you continue, make sure that your Meta Page, main page of your store and all products are all compliant.
            </p>
            <p className="user-bm-error__description">
                To proceed, we will forward you to an alternative setup. It will take just a few more minutes. 
            </p>
            <p className="user-bm-error__description">
                Click <strong>“Continue”</strong> to continue or {''} 
                    <span
                        onClick={intercom.show}
                        className="primary-link in-text"
                        onKeyDown={intercom.show}
                        role="button"
                    >
                        contact support
                    </span> {''}
                if you think that there was a mistake in the setup process.
            </p>
        </div>
        <Button onClick={intercom.show} color="primary">
            Continue
        </Button>
    </div>
);

export default PolicyError;
