import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import pageNameSetter from 'decorator/pageNameSetter';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import { isFacebookPaymentMethodExist, getFacebookPaymentStatusType } from 'helpers/onboardingHelper';
import { validateShop } from 'actions/shop';
import { requestCampaignValidate } from 'actions/campaign';
import { RETARGETING } from 'constants/campaign';
import { changeShopPage } from 'constants/metric';
import { LoaderLongWait as Loader } from 'components/Loader';

import PaymentError from './PaymentError/PaymentError';
import PaymentReview from './PaymentReview/PaymentReview';
import PaymentSuccess from './PaymentSuccess/PaymentSuccess';
import { useFacebookBillingSession } from './hooks/useFacebookBillingSession';

import './index.css';

const FacebookBilling = (props) => {
    const {
        type,
        shopError,
        params: { shopId },
        checkShopForErrors,
        requestCampaignValidate,
        isLoading,
    } = props;

    const { wasPreviewPaymentShowed } = useFacebookBillingSession();

    useLayoutEffect(() => {
        checkShopForErrors({ shopId });
        window.addEventListener('focus', windowFocusListener);

        return function cleanup() {
            window.removeEventListener('focus', windowFocusListener);
        };
    }, []);

    const windowFocusListener = () => {
        const storageDailyBudget = campaignCreateStorageHelper.getCampaignDailyBudget();
        const storageAdMessage = campaignCreateStorageHelper.getCampaignAdMessage();

        const params = {
            shopId,
            campaign_type: RETARGETING,
            daily_budget: storageDailyBudget,
            message: storageAdMessage,
        };
        requestCampaignValidate(params);
    };

    if (!isLoading && type === 'success') {
        return <PaymentSuccess />;
    } else if (!isLoading && wasPreviewPaymentShowed) {
        return <PaymentError shopError={shopError} shopId={shopId} />;
    } else if (!isLoading) {
        return <PaymentReview shopError={shopError} shopId={shopId} />;
    }

    return (
        <div className="payment-preview__loader-wrapper">
            <Loader />
        </div>
    );
};

const mapStateToProps = ({ shopError }) => {
    const facebookBillingIsApproved = isFacebookPaymentMethodExist(shopError.shopError);

    const type = getFacebookPaymentStatusType(facebookBillingIsApproved);
    return {
        type,
        shopError: shopError.shopError,
        isLoading: shopError?.shopValidateRequesting,
    };
};

const mapDispatchToProps = {
    checkShopForErrors: validateShop,
    requestCampaignValidate,
};

export default pageNameSetter(changeShopPage.onboarding_create_campaign)(
    connect(mapStateToProps, mapDispatchToProps)(FacebookBilling),
);
