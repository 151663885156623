import React from 'react';
import PropTypes from 'prop-types';

import AlertWidget from 'components/AlertWidget';

const AdMessageProhibitedWordsError = ({ word, withTopMargin }) => {
    const fbPolicyLink = (
        <a
            href="https://www.facebook.com/policies/ads/"
            className="alert-description__link_highlighted"
            rel="noopener noreferrer"
            target="_blank"
        >
            Facebook Ad Policies
        </a>
    );
    const prohibitedWord = <strong>{word}</strong>;
    return (
        <AlertWidget color="danger" withTopMargin={withTopMargin}>
            <p className="alert-description__item">
                {'You can\'t save an ad message because it contains the word "'}
                {prohibitedWord}
                {'" which is prohibited by '}
                {fbPolicyLink}
                {'. To save an ad message, remove the word "'}
                {prohibitedWord}
                {'".'}
            </p>
            <p className="alert-description__item">
                <a
                    href="https://help.adwisely.com/improve-your-campaign-results/write-the-best-ad-message"
                    className="alert-description__link_highlighted"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Learn more
                </a>
            </p>
        </AlertWidget>
    );
};

AdMessageProhibitedWordsError.defaultProps = {
    withTopMargin: false,
};

AdMessageProhibitedWordsError.propTypes = {
    withTopMargin: PropTypes.bool,
};

export default AdMessageProhibitedWordsError;
