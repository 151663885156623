import React from 'react';

const SuccessIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM16.1 29.3544L25.0986 38.353L41.996 21.4556L39.4584 18.9L25.0986 33.2598L18.6376 26.8168L16.1 29.3544Z"
            fill="#5BC920"
        />
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM16.1 29.3544L25.0986 38.353L41.996 21.4556L39.4584 18.9L25.0986 33.2598L18.6376 26.8168L16.1 29.3544Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0)">
            <rect x="-32" y="-32" width="120" height="120" fill="#5BC920" />
        </g>
    </svg>
);

export default SuccessIcon;
