import * as onboarding from 'actions/onboarding';

const initialState = {
    shopError: {},
    shopValidateRequesting: false,
    shopValidateSuccess: false,
    shopValidateError: false,
    shopValidateTimestamp: null,
};

export default (state = initialState, action) => {
    let timestamp;
    if (action.meta && action.meta.timestamp) {
        timestamp = action.meta.timestamp;
    }

    switch (action.type) {
        case onboarding.ONBOARDING_VALIDATE_SHOP_REQUEST:
            state = {
                ...state,
                shopValidateTimestamp:
                    !state.shopValidateTimestamp || state.shopValidateTimestamp < timestamp
                        ? timestamp
                        : state.shopValidateTimestamp,
            };
            break;

        case onboarding.ONBOARDING_VALIDATE_SHOP_SUCCESS:
            if (timestamp === state.shopValidateTimestamp) {
                state = {
                    ...initialState,
                    shopValidateSuccess: true,
                };
            }
            break;

        case onboarding.ONBOARDING_VALIDATE_SHOP_FAILURE:
            if (timestamp === state.shopValidateTimestamp) {
                state = {
                    ...initialState,
                    shopError: action.payload,
                    shopValidateError: true,
                };
            }
            break;

        case onboarding.CLEAR_ONBOARDING_SHOP_ERROR:
            state = {
                ...initialState,
            };
            break;

        default:
            break;
    }
    return state;
};
