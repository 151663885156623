import React from 'react';
import classNames from 'classnames';

import { ToDoListItemIcon } from './ToDoListItemIcon';
import { ToDoListItemStatus } from './ToDoListItemStatus';
import Tooltip from '../../Tooltip';

export const ToDoListItem = ({ status, title, onStartBtnClick, onMouseEnter, onMouseLeave, isListItemFirstTimeHovered, isFirstNotStartedItem, tooltipContent }) => (
    <div
        className={classNames(
        "todo-list__item",
        {"todo-list__item--not-started": isFirstNotStartedItem && !isListItemFirstTimeHovered}
    )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onStartBtnClick}
    >
        <div className="todo-list__item-icon">
            <ToDoListItemIcon status={status} />
        </div>

        <Tooltip placement={'bottom'} content={tooltipContent} delay={0} >
            <div className="todo-list__item-title">
                {title}
            </div>
        </Tooltip>
        <div className="todo-list__item-status-wrapper">
            <ToDoListItemStatus status={status} />
        </div>
    </div>
)