// FACEBOOK LOGIN
export const FacebookLoginDeclined = 'Facebook Login Declined';

// VISITOR RETARGETING CAMPAIGN
export const StandardCampaignAdMessageUpdatedViaEditCampaign = 'Standard Campaign AD MESSAGE UPDATED via Edit Campaign';
export const StandardCampaignDateRangeChanged = 'Standard Campaign Date Range Changed';
export const StandardCampaignViewDetailedFunnel = 'Standard Campaign View Detailed Funnel';

// AI BOOSTED
export const AiBoostedCampaignAdMessageUpdatedViaEditCampaign = 'AiBoosted Campaign AD MESSAGE UPDATED via Edit Campaign';

// СUSTOM CAMPAIGN
export const CustomCampaignResumed = 'Custom campaign resumed';
export const CustomCampaignPause = 'Custom campaign paused';

// SPECIAL OFFER CAMPAIGN
export const SpecialOfferCampaignRun = 'Special Offer campaign run';
export const SpecialOfferCampaignAdMessageUpdatedViaDashboard = 'Special Offer Ad Message Updated via Dashboard';
export const SpecialOfferCampaignAdMessageUpdatedViaEditCampaign =
    'Special Offer Campaign AD MESSAGE UPDATED via Edit Campaign';


// INFORM UPDATE BILLING EVENT
export const InformBillingUpdate = 'Informed about billing update';

// GOOGLE INVITATION
export const GooogleBannerClicked = 'Google banner clicked';
export const GoogleLoggedIn = 'Google logged in';


// EXTEND TRIAL PERIOD
export const ExtendTrialBadgeClicked = '“write a review” on extend trial badge clicked';
export const ExtendTrialBadgeClickedBC = '“write a review” on extend trial badge clicked(bc)';
export const ExtendTrialBadgeClickedWC = '“write a review” on extend trial badge clicked(wc)';

