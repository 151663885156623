import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import { choosenCampaignType } from 'helpers/onboardingHelper';

import { requestCreateCampaign, goToDashboard } from 'actions/campaign';
import { PROSPECTING, RETARGETING } from 'constants/campaign';

import { ONBOARDING_TYPES, СBM_ONBOARDING_STATES } from 'constants/onboarding';
import { changeOnboardingState } from 'modules/home/utils';

import Button from 'components/Button';
import SuccessIcon from './SuccessIcon';
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from '../../../../../campaign/constants';

import './PaymentSuccess.css';

const PaymentSuccess = (props) => {
    const {
        createCampaign,
        goToNextStep,
        campaignIsCreated,
        currentShopId,
        redirect,
        choosenCampaignType,
    } = props;

    const storageDailyBudget = campaignCreateStorageHelper.getCampaignDailyBudget();
    const storageAdMessage = JSON.parse(campaignCreateStorageHelper.getCampaignAdMessage());
    const storageShopId = campaignCreateStorageHelper.getCampaignShopId();

    useEffect(() => {
        campaignIsCreated && goToNextStep(storageShopId);
    }, []);

    const approveHandler = () => {
        const params = {
            shopId: +currentShopId,
            campaign_type: choosenCampaignType === RETARGETING ? RETARGETING : PROSPECTING,
            daily_budget: +storageDailyBudget,
            messages: storageAdMessage,
        };

        createCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                campaignCreateStorageHelper.clearStorage();
                changeOnboardingState({
                    currentShopId: +currentShopId,
                    type: ONBOARDING_TYPES.facebook_childbm,
                    status: СBM_ONBOARDING_STATES.started,
                });
                goToNextStep(storageShopId);
            } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                choosenCampaignType === RETARGETING
                    ? redirect(`/${currentShopId}/onboarding/set-up-retargeting`)
                    : redirect(`/${currentShopId}/onboarding/set-up-prospecting`);
            }
        });
    };

    return (
        <div className="payment-success__wrapper">
            <h2 className="payment-success__caption">
                You are almost done{' '}
                <span role="img" aria-label="Party popper">
                    🎉
                </span>
            </h2>
            <div className="payment-success__icon-wrapper">
                <SuccessIcon />
            </div>
            <p className="payment-success__description">
                The payment method has been successfully added! You can now start your free trial.
            </p>
            <Button className="payment-success__button" onClick={approveHandler} large color="green" selfTarget>
                START FREE TRIAL
            </Button>
            <div className="payment-success__link">
                By clicking “Start free trial”, you agree to our&nbsp;
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://help.adwisely.com/en/articles/900431-terms-of-service"
                >
                    Terms of Service
                </a>{' '}
                and <br /> that you have read our{' '}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://help.adwisely.com/en/articles/900424-privacy-policy"
                >
                    Privacy Policy
                </a>
                .
            </div>
        </div>
    );
};

PaymentSuccess.propTypes = {
    campaignIsCreated: PropTypes.bool.isRequired,
    currentShopId: PropTypes.string.isRequired,
}

const mapStateToProps = ({ campaignDetails, shops }) => ({
    campaignIsCreated: !!campaignDetails?.campaignDetailsData.length,
    currentShopId: shops.currentShopId,
    choosenCampaignType: choosenCampaignType(shops.currentShopId),
});

const mapDispatchToProps = {
    redirect: replace,
    goToNextStep: goToDashboard,
    createCampaign: requestCreateCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
