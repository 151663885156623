import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import {
  goToDashboard,
  clearCampaignError,
  requestCampaignSuggestionsForCampaignCreate,
  requestCreateCampaign,
} from 'actions/campaign';
import {displayBulkNotification, requestDeleteShopNotification} from 'actions/notification';
import { requestSendEventMetrics, validateShop } from 'actions/shop';

import Button from 'components/Button';
import { ArrowLeft } from 'components/Icons';
import CampaignInfo from 'modules/campaign/components/CampaignInfo';
import CampaignDetails from 'modules/campaign/components/CampaignDetails';
import SaveCampaignDetails from 'modules/campaign/components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from 'modules/campaign/components/CampaignPageLayout';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import BulkNotification from 'components/BulkNotification';
import NotificationMigratedUserBanner from 'components/Banners/NotificationMigratedUserBanner';

import {
  getCurrentShopSelector,
  isStaffPlatformPlanSelector,
  isShopValidateRequesting,
  getShopErrorTokenSelector,
  getBulkNotificationContentSelector,
  getBulkNotificationTypeSelector,
  getAdAccountsSelector,
  getCampaignSuggestionsSelector,
} from 'helpers/selectors';

import { AI_BOOSTED_SALES, RETARGETING } from 'constants/campaign';
import { SHOPIFY } from 'constants/platform';
import { campaignCopyright } from 'constants/campaignCopyright';

import { DAILY_BUDGET_LESS_THEN_MINIMUM } from 'modules/campaign/constants';

import AdsPreviewSection from 'components/AdsPreviewSection/AdsPreviewSection';

import './AiBoostedSalesCreate.css';
import {
  CreateCampaignAdAudience
} from '../../onboarding/userBMFlow/steps/CreateCampaignAdAudience/CreateCampaignAdAudience';
import {AI_BOOSTED_SALES_INVITE} from '../../../constants/notificationType';

const CAMPAIGN_AD_MESSAGE_DEFAULT = "Buy {{product.name}} for only {{product.price}}. Get it before it's sold out!";

class AiBoostedSalesCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewAdMessage: null,
      currentPreviewType: null,
      isPublishPage: true,
      isLoading: false,

      // save campaign details
      hasCampaignFormError: false,
      tipsErrorText: '',

      // default values
      campaignCurrency: 'USD',
      campaignBudget: 0,
      campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
      campaignMinBudget: 0,

      adMessageTagsHasError: false,
      adMessageTagsHasErrorTipsErrorText: false,

      isMobilePreview: false,
      countriesList : [],
      isWorldwide: false,
      ageMin: 0,
      ageMax: 0,
      genders: []
    };
  }

  componentDidMount() {
    const {
      requestSuggestions,
      params: { shopId },
      checkShopForErrors,
    } = this.props;
    requestSuggestions({ id: shopId });
    checkShopForErrors({ shopId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.suggestions !== nextProps.suggestions) {
      if (nextProps.suggestions.campaignSuggestionsSuccess) {
        const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
        const suggestedAdMessage = suggestionsData.migrated_user
            ? suggestionsData.ad_message
            : CAMPAIGN_AD_MESSAGE_DEFAULT;

        this.setState({
          campaignBudget: suggestionsData.daily_budget.prospecting_suggested_value / 100,
          campaignMinBudget: suggestionsData.daily_budget.min_value / 100,
          campaignCurrency: suggestionsData.daily_budget.currency,
          campaignAdMessage: suggestedAdMessage,
        });
      }
    }

    if (this.props.adAccounts.connectStatus.access_status !== nextProps.adAccounts.connectStatus.access_status) {
      const { adAccounts } = nextProps;
      const { chosenAdAccountId, connectStatus } = adAccounts;

      if (
          chosenAdAccountId &&
          connectStatus.fb_id === this.state.errorData.ad_account_id &&
          connectStatus.access_status === 'granted'
      ) {
        this.refreshMinBudgetAndAdPreview();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearCampaignError();
  }

  handleAdPreviewMsg = (msg) => {
    this.setState({
      previewAdMessage: msg,
    });
  };

  handleFormError = (hasCampaignFormError) => {
    this.setState({
      hasCampaignFormError,
    });
  };

  getAdAudience = (countries, isWorldwide, ageMin, ageMax, genders) => {
    this.setState({
      countriesList: countries.length > 0 ? countries.map(item => item.slug) : [],
      isWorldwide,
      ageMin,
      ageMax,
      genders
    })
  }

  saveCampaign = (dailyBudget, adMessage) => {
    const { countriesList, isWorldwide, ageMin, ageMax, genders } = this.state;

    const {
      createCampaign,
      params: { shopId },
      suggestions,
      goToNextStep,
      removeNotification
    } = this.props;

    const gendersArray = Object.keys(genders).map(gender => +gender)

    const params = {
      shopId,
      campaign_type: AI_BOOSTED_SALES,
      daily_budget: +dailyBudget,
      messages: adMessage,
      currency: this.state.campaignCurrency,
      targeting_settings: {
        countries: countriesList,
        worldwide: isWorldwide,
        age_min: ageMin,
        age_max: ageMax,
        genders: gendersArray
      }
    };

    if (!suggestions.campaignSuggestionsSuccess || !dailyBudget) return;

    const createCampaignPromise = createCampaign(params);
    const removeAiBoostedInviteNotificationPromise = removeNotification({
      id: shopId,
      type: AI_BOOSTED_SALES_INVITE,
    });
    Promise.all([createCampaignPromise, removeAiBoostedInviteNotificationPromise]).then((res) => {
      if (!(res instanceof Error)) {
        this.setState({ isLoading: true });
        goToNextStep(shopId);
      } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
        this.setState({ isLoading: false });
        this.setState({
          saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
        });
      }
    });
  };

  refreshMinBudgetAndAdPreview = () => {
    const {
      requestSuggestions,
      params: { shopId },
    } = this.props;
    requestSuggestions({ id: shopId });
  };

  changeTipsErrorText = (tipsText) => {
    this.setState({
      tipsErrorText: tipsText,
    });
  };

  handleAdMessageTagsHasError = (tipsErrorText) => {
    this.setState({
      adMessageTagsHasError: !!tipsErrorText,
      adMessageTagsHasErrorTipsErrorText: tipsErrorText,
    });
  };

  setIsMobilePreview = (newState) => {
    this.setState({ isMobilePreview: newState });
  };

  render() {
    const campaignFormName = 'retargetingCampaignCreate';
    const {
      shopValidateRequesting,
      isShopErrorExist,
      params: { shopId },
      suggestions: { campaignSuggestionsData },
      currentShop,
      bulkNotificationContent,
      bulkNotificationType,
      suggestions
    } = this.props;
    const {
      campaignBudget,
      campaignAdMessage,
      campaignCurrency,
      campaignMinBudget,
      adMessageTagsHasError,
      hasCampaignFormError,
      adMessageTagsHasErrorTipsErrorText,
      isPublishPage,
      tipsErrorText,
      saveCampaignError,
      isLoading,
      previewAdMessage,
      isMobilePreview,
      isWorldwide,
      countriesList,
    } = this.state;

    let bulkNotification = null;

    if (bulkNotificationContent) {
      bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
    }


    const isMigratedUser = campaignSuggestionsData ? campaignSuggestionsData.migrated_user : false;

    return (
        <div className="retargeting-campaign-create__wrapper">
          <NotificationMigratedUserBanner isMigratedUser={isMigratedUser} />

          <Button className="retargeting-campaign-create__back-button" innerUrl={`/${shopId}/dashboard`}>
            <ArrowLeft /> Back
          </Button>

          <CampaignPageLayout>
            <CampaignPageCol>
              <CampaignInfo
                  campaignTitle={campaignCopyright.ai_boosted_sales.title}
                  linkCaption="Learn more"
                  learnMoreLink="https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose"
                  showPricing
                  showIcon
                  shopId={shopId}
                  campaignDescription={campaignCopyright.ai_boosted_sales.description}
              />

              <CampaignDetails
                  formName={campaignFormName}
                  handleAdPreviewMsg={this.handleAdPreviewMsg}
                  handleFormError={this.handleFormError}
                  changeTipsErrorText={this.changeTipsErrorText}
                  campaignAdMessage={campaignAdMessage}
                  campaignCurrency={campaignCurrency}
                  campaignMinBudget={campaignMinBudget}
                  campaignBudget={campaignBudget}
                  pricingAdviceItems={
                    campaignSuggestionsData && campaignSuggestionsData.daily_budget
                        ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                        : []
                  }
                  saveCampaign={this.saveCampaign}
                  handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                  saveCampaignError={saveCampaignError}
                  campaignType={AI_BOOSTED_SALES}
              />

              <CreateCampaignAdAudience
                  onAdAudienceSettingsChange={this.getAdAudience}
                  shopId={shopId}
                  isDropdownError={!isWorldwide && Object.keys(countriesList).length === 0}
                  isGenderVisible={false}
                  isAgeVisible={false}
                  isCountriesVisible
              />

              <SaveCampaignDetails
                  formName={campaignFormName}
                  buttonTitle="Continue"
                  buttonColor="primary"
                  hasCampaignFormError={
                      adMessageTagsHasError ||
                      hasCampaignFormError ||
                      !!shopValidateRequesting ||
                      isShopErrorExist
                  }
                  tipsErrorText={adMessageTagsHasErrorTipsErrorText || tipsErrorText}
              />
            </CampaignPageCol>
            <CampaignPageCol>
              <AdsPreviewSection
                  currentActiveCard={'facebook'}
                  isMobilePreview={isMobilePreview}
                  setIsMobilePreview={this.setIsMobilePreview}
                  previewAdMessage={previewAdMessage}
                  isShopify={currentShop?.type === SHOPIFY}
              />
            </CampaignPageCol>
          </CampaignPageLayout>
          <LoaderFullScreen
              isLoaderShowed={isLoading}
          >
            <LoaderLongWait title="Loading..." />
          </LoaderFullScreen>
          {bulkNotification}
        </div>
    );
  }
}

AiBoostedSalesCreate.defaultProps = {
  bulkNotificationContent: null,
  bulkNotificationType: null,
  isShopErrorExist: false,
}

AiBoostedSalesCreate.propTypes = {
  adAccounts: PropTypes.shape({
    connectStatus: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        access_status: PropTypes.string,
      })
    ]),
  }).isRequired,
  bulkNotificationContent: PropTypes.string,
  bulkNotificationType: PropTypes.string,
  currentShop: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  isShopErrorExist: PropTypes.bool,
  shopValidateRequesting: PropTypes.bool.isRequired,
  suggestions: PropTypes.shape({
    campaignSuggestionsSuccess: PropTypes.bool.isRequired
  }).isRequired,
}

const mapStateToProps = (state) => {
  const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);

  return {
    suggestions: getCampaignSuggestionsSelector(state),
    shopValidateRequesting: isShopValidateRequesting(state),
    isShopErrorExist: getShopErrorTokenSelector(state),
    currentShop,
    isStaffPlatformPlan: isStaffPlatformPlanSelector(currentShop),
    shops: state.shops,
    bulkNotificationContent: getBulkNotificationContentSelector(state),
    bulkNotificationType: getBulkNotificationTypeSelector(state),
    adAccounts: getAdAccountsSelector(state),
  };
};

const mapDispatchToProps = {
  requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
  goToNextStep: goToDashboard,
  clearCampaignError,
  showBulkNotification: displayBulkNotification,
  checkShopForErrors: validateShop,
  requestSendEventMetrics,
  createCampaign: requestCreateCampaign,
  replace,
  removeNotification: requestDeleteShopNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AiBoostedSalesCreate);
