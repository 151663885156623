import { useEffect, useState } from 'react';
import { logException } from 'libs/sentryConfig';

export const useFetchUserEmail = (currentShopId) => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        fetch(`/api/onboarding/${currentShopId}/google/website_claimed_by`)
            .then((res) => res.json())
            .then(({ email }) => {
                if (email) {
                    setEmail(email);
                }
            })
            .catch((error) => {
                logException(
                    `Fetch website claimed by: couldn't fetch user data by shop id - ${currentShopId}. Request was declined by server. Reason: ${error}`,
                    error,
                    { currentShopId },
                    'javascript',
                    'error',
                );
            });
    }, []);

    return email;
};
