import React from 'react';

export const WarningIcon = () => (
    <svg width="63px" height="53px" viewBox="0 0 63 53" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g
            transform="translate(-307.000000, -377.000000) translate(283.000000, 357.000000) translate(24.000000, 20.000000)"
            fill="#FFF"
            stroke="none"
            strokeWidth={1}
            fillRule="evenodd"
        >
            <text fontFamily="inherit" fontSize={14} fontWeight={500} line-spacing={21} letterSpacing="0.25">
                <tspan x="0.229003906" y={48}>
                    Required
                </tspan>
            </text>
            <path
                d="M13.72 23.524c-1.118 1.908-.233 3.455 1.976 3.455h26c2.208 0 3.097-1.538 1.974-3.455L30.72 1.434c-1.118-1.908-2.927-1.916-4.05 0l-12.95 22.09zm15.975-.545h-3v-3h3v3zm0-6h-3v-7h3v7z"
                fillRule="nonzero"
            />
        </g>
    </svg>
);
