import React from 'react';

import './ShopifyReInstallPage.css';

const ShopifyReInstallPage = ({ title, body, link, linkText }) => (
    <div>
        <h4 className="shopify-reinstall__title">{title}</h4>
        <p className="shopify-reinstall__text">{body}</p>
        <a className="shopify-reinstall__button" href={link}>
            {linkText}
        </a>
    </div>
);

export default ShopifyReInstallPage;
