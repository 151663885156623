import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import ShopifyBilling from 'modules/onboarding/components/ShopifyBilling/ShopifyBilling';

import { goToFacebookBilling } from 'actions/onboarding';

export const ShopifyBillingFacebookChildBMView = ({shopId, redirectToFacebookBillingStep}) => {
    const onRecurringChargeIsApproved = () => {
        redirectToFacebookBillingStep(shopId);
    }

    return <ShopifyBilling
        onRecurringChargeIsApproved={onRecurringChargeIsApproved} />
}

const mapStateToProps = ({ shops }) => (
    {
        shopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    redirectToFacebookBillingStep: goToFacebookBilling,
    replace,
};

export const ShopifyBillingFacebookChildBM =  connect(mapStateToProps, mapPropsToDispatch)(ShopifyBillingFacebookChildBMView);