import React, { PureComponent } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

import intercom from 'libs/intercom';
import { getNoShopBannerLink } from 'helpers/bannerHelper';

class CommonErrorBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.TipsErrorText = '';
        this.getCommonErrorReasonBanner = this.getCommonErrorReasonBanner.bind(this);
    }

    componentDidMount() {
        const { changeTipsErrorText } = this.props;
        if (changeTipsErrorText && this.TipsErrorText) changeTipsErrorText(this.TipsErrorText);
    }

    getCommonErrorReasonBanner() {
        const { shopError, shopId, currentShop } = this.props;
        let notificationData = null;
        let bannerTitle = null;
        let bannerContent = null;
        let bannerButtons = null;
        if (!shopError.errorData) shopError.errorData = {};
        switch (shopError.errorToken) {
            case 'shop-not-found':
                bannerTitle = <p className="notification__title">Activate your store</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Your store {shopError.errorData.shop_name} is closed. To run a campaign, you need to have an
                            active store. Go to <span className="notification__caption">Shopify Account Settings</span>{' '}
                            and activate your store to run a campaign.
                        </p>
                    </div>
                );

                bannerButtons = (
                    <Button
                        url={`https://${shopError.errorData.shop_domain}/admin/settings/account`}
                        color="transparent-bordered"
                    >
                        Go to Shopify Account Settings
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.TipsErrorText = 'You can’t run a campaign for a closed store.';

                break;

            case 'business-manager-doesnt-have-access-to-page':
            case 'business-manager-was-deleted':
            case 'account-doesnt-have-access-to-business-manager':
                bannerTitle = <p className="notification__title">Your action is required</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Adwisely lost access to your page. To start the campaign, you
                            need to connect a Facebook page you are the admin of.
                        </p>

                        <p className="notification__description">
                            Go to <span className="notification__caption">Account Settings</span> to connect a page.
                        </p>
                    </div>
                );

                bannerButtons = (
                    <Button innerUrl={`/${shopId}/settings`} color="transparent-bordered">
                        Go to Account Settings
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.TipsErrorText =
                    'To launch a campaign, you need to make ' +
                    'the connected Facebook page published or choose another page.';

                break;

            case 'has-no-page-access-token':
            case 'account-doesnt-have-access-to-page':
                bannerTitle = <p className="notification__title">Your action is required</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Adwisely lost access to your page. To manage your campaigns,
                            you need to get the admin role to the connected page or choose the page you’re the admin of.
                            Go to <span className="notification__caption">Account Settings</span> to choose a new page.
                        </p>
                    </div>
                );

                bannerButtons = (
                    <Button innerUrl={`/${shopId}/settings`} color="transparent-bordered">
                        Go to Account Settings
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.TipsErrorText = 'You need to connect a Facebook page to edit a campaign.';
                break;

            case 'retargetapp-lost-access-to-ad-account':
            case 'retargetapp-lost-access-to-pixel':
                bannerTitle = <p className="notification__title">Your action is required</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            You can’t manage the advertising campaigns for {shopError.errorData.shop_name} since we lost
                            access to the ad account. It may happen if you are no
                            longer the admin of this ad account or if you removed Adwisely access to it.
                        </p>
                        <p className="notification__description ">
                            <span className="font-weight-bold">Contact us</span> to help you resolve the issue asap.
                        </p>
                    </div>
                );

                bannerButtons = (
                    <Button color="transparent-bordered" onClick={intercom.show}>
                        Contact us
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;

            case 'campaign-effective-status-has-wrong-status-disapproved':
                bannerTitle = <p className="notification__title">Ad declined</p>;

                bannerContent = (
                    <p className="notification__description">
                        Your ad was disapproved by Facebook. For more details, please, read{' '}
                        <a
                            href={
                                'https://help.adwisely.com/install-and-configure-retargetapp/' +
                                'troubleshooting/what-do-i-do-if-my-ad-was-not-approved'
                            }
                            className="notification__link"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            this article
                        </a>{' '}
                        or <span className="font-weight-bold">contact us</span>.
                    </p>
                );

                bannerButtons = (
                    <Button color="transparent-bordered" onClick={intercom.show}>
                        Contact us
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                break;

            case 'products-not-found': // TODO: make only one token for "0 products"
            case 'missing-shop-products': // TODO: make only one token for "0 products"
                bannerTitle = <p className="notification__title">Add products</p>;
                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            Your store {currentShop.name} has no products. To run a campaign, it is required to have at
                            least a few products in your store.
                            <br />
                            <b>Add products</b> to your store to run a campaign.
                        </p>
                    </div>
                );
                bannerButtons = (
                    <Button
                        url={getNoShopBannerLink(currentShop?.type, currentShop.domain)}
                        color="transparent-bordered"
                    >
                        Add Products
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.TipsErrorText = 'You can’t resume a campaign for a store without products.';
                break;
            default:
                break;
        }
        return notificationData;
    }

    render() {
        const shopCommonErrorBannerArray = this.getCommonErrorReasonBanner();
        if (shopCommonErrorBannerArray) {
            return (
                <div>
                    <NotificationWidget type="danger">
                        {shopCommonErrorBannerArray.map((notificationItem, key) => (
                            <span key={key}>{notificationItem}</span>
                        ))}
                    </NotificationWidget>
                </div>
            );
        }
        return null;
    }
}

export default CommonErrorBanner;
