import { Component } from 'react';
import { connect } from 'react-redux';

import { requestAccountInfo } from 'actions/account';
import { redirect } from 'actions/redirect';
import { requestShopsCampaignsSettings } from 'actions/shopCampaignsSettings';

import { GRANTED } from 'constants/fbInvitaionStatuses';
import { getLocalStorageObjectItem } from 'helpers';
import { getCurrentBMUserStatus } from 'helpers/selectors';

export class CampaignMiddleware extends Component {
    componentDidMount() {
        const {
            replace,
            params: { shopId },
            page,
            businessManager,
            adAccount,
            fbInviteStatus,
            hasOldBM,
            ownerType,
        } = this.props;

        if (
            !hasOldBM && ownerType === 'child' &&
            !(page && businessManager && adAccount && fbInviteStatus === GRANTED) &&
            getLocalStorageObjectItem('activeTab') !== '1' // not google tab
        ) {
            replace(`/${shopId}/onboarding`);
        }
    }

    render() {
        return this.props.children;
    }
}

const mapStateToProps = ({ shops, account, shopsCampaignsSettings, campaignDetails, businessManager }) => ({
    shops,
    shopsCampaignsSettings,
    account,
    campaignDetails,
    page: shopsCampaignsSettings?.entity?.page,
    ownerType: shopsCampaignsSettings?.entity?.fb_business_manager_owner_type,
    hasOldBM: !!shopsCampaignsSettings?.entity?.fb_business_id,
    businessManager: shopsCampaignsSettings?.entity?.business_manager,
    adAccount: shopsCampaignsSettings?.entity?.ad_account,
    fbInviteStatus: businessManager.data && getCurrentBMUserStatus(businessManager.data, account),
});

const mapPropsToDispatch = {
    requestAccountInfo,
    requestShopsCampaignsSettings,
    replace: redirect,
};

export default connect(mapStateToProps, mapPropsToDispatch)(CampaignMiddleware);
