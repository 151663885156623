import { replace } from 'react-router-redux';
import { ROUTES } from 'constants/routes';
import { AI_BOOSTED_SALES } from 'constants/campaign';
import { AI_BOOSTED_SALES_INVITE } from 'constants/notificationType';
import withData from 'decorator/withStateData';
import AiBoostedSalesCreate from './AiBoostedSalesCreate';

export default withData(
    AiBoostedSalesCreate,
    ['shopNotifications', 'campaignDetails'],
    (data) => {
      const { campaignDetailsData } = data.campaignDetails;
      const isCreated = campaignDetailsData.find((el) => el.type === AI_BOOSTED_SALES);
      const isInvited = !!data.shopNotifications.entity.find((el) => el.type === AI_BOOSTED_SALES_INVITE);
      return isInvited && !isCreated;
    },
    (nextState) => (dispatch) => {
      const {
        params: { shopId },
      } = nextState;
      dispatch(replace(`/${shopId}${ROUTES.dashboard}`));
    },
);
