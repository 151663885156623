import { useEffect, useState } from 'react';

const RESEND_SMS_TIME = 59;

export const useCounter = () => {
    const [counter, setCounter] = useState(RESEND_SMS_TIME);

    const handleCountDown = () => {
        setCounter((count) => (count > 10 ? count - 1 : `0${count - 1}`));
    };

    useEffect(() => {
        const counterId = setInterval(handleCountDown, 1000);

        return () => clearInterval(counterId);
    }, []);

    const resetCounter = () => {
        setCounter(RESEND_SMS_TIME);
    };

    const isTimeOut = parseInt(counter, 10) === 0;

    return [counter, isTimeOut, resetCounter];
};
