import React from 'react';
import PropTypes from 'prop-types';

import AlertWidget from 'components/AlertWidget';

const AdMessageTagsError = ({ withTopMargin }) => (
    <AlertWidget color="danger" withTopMargin={withTopMargin}>
        <p className="alert-description__item">
            Check that the dynamic tag in your ad message is used correctly. It should look like one of the following:
        </p>
        <p className="alert-description__item">
            — <b>{'{{product.name}}'}</b> — the name of the product;
            <br />— <b>{'{{product.price}}'}</b> — the price of the product.
        </p>
    </AlertWidget>
);

AdMessageTagsError.defaultProps = {
    withTopMargin: false,
};

AdMessageTagsError.propTypes = {
    withTopMargin: PropTypes.bool,
};

export default AdMessageTagsError;
