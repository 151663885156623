import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropdown from 'components/Dropdown';
import DropdownProvider from '../../components/DropdownProvider';
import Button from '../Button';
import { Plus } from '../Icons';

import './AdMessageInput.css';

export class AdMessageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caretPosition: props.adMessage?.length,
            inputValue: props?.adMessage,
        };
        this.chooseDynamicTag = this.chooseDynamicTag.bind(this);
        this.handleChangeAdMessage = this.handleChangeAdMessage.bind(this);
        this.checkCaretPosition = this.checkCaretPosition.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this.props.onMountFocus) this.messageInput.focus();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.inputValue !== nextProps.adMessage) {
            this.setState({ inputValue: nextProps.adMessage });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.checkCaretPosition();
        }
    }

    chooseDynamicTag(tag, setDropdownState) {
        return () => {
            const { caretPosition, inputValue } = this.state;
            setDropdownState(false);
            const inputValueArray = inputValue.split('');
            inputValueArray.splice(caretPosition, 0, tag);
            const inputWithTag = inputValueArray.join('');
            const newCarretPosition = caretPosition + tag.length;
            this.handleChangeAdMessage(
                {
                    target: {
                        value: inputWithTag,
                        name: this.props.name,
                    },
                },
                () => {
                    this.messageInput.focus();
                    this.messageInput.setSelectionRange(newCarretPosition, newCarretPosition);
                    this.checkCaretPosition({
                        target: {
                            selectionStart: newCarretPosition,
                        },
                    });
                },
            );
        };
    }

    handleChangeAdMessage(e, cb) {
        if (e.persist !== undefined) e.persist();
        this.props.changeAdMessage(e);
        this.setState({ inputValue: e.target.value }, () => {
            this.checkCaretPosition(e);
            if (cb) cb();
        });
    }

    checkCaretPosition(e) {
        const position =
            e?.target?.selectionStart !== undefined
                ? e.target.selectionStart
                : this.state.inputValue?.length;
        this.setState({ caretPosition: position });
    }

    render() {
        const { inputClassName, wrapperClassName, disabled, useDynamicTagsDropdown, error } = this.props;
        const { inputValue } = this.state;
        return (
            <div
                className={`ad-message-input-wrapper ${wrapperClassName}`}
                ref={(wrapper) => {
                    this.wrapperRef = wrapper;
                }}
            >
                <textarea
                    className={classNames('ad-message-input', inputClassName, {
                        'ad-message-input_with-dropdown': useDynamicTagsDropdown,
                        'ad-message-input_error': error,
                    })}
                    type="textarea"
                    name={this.props.name}
                    id="adMessageInput"
                    value={inputValue}
                    disabled={disabled}
                    onChange={this.handleChangeAdMessage}
                    onKeyDown={this.checkCaretPosition}
                    onClick={this.checkCaretPosition}
                    onFocus={this.checkCaretPosition}
                    ref={(textarea) => {
                        this.messageInput = textarea;
                    }}
                />
                <span
                    className={classNames('ad-message-counter', {
                        'ad-message-counter_error': error,
                    })}
                >
                    {inputValue?.length}/512
                </span>
                {useDynamicTagsDropdown && (
                    <DropdownProvider>
                        {({ isOpen, toggle }) => {
                            const dropdownItems = (
                                <>
                                    <div
                                        id="dynamicTagProductName"
                                        className="ad-message-tag-dropdown__item"
                                        onClick={this.chooseDynamicTag('{{product.name}}', toggle)}
                                    >
                                        Product name
                                    </div>
                                    <div
                                        className="ad-message-tag-dropdown__item"
                                        onClick={this.chooseDynamicTag('{{product.price}}', toggle)}
                                    >
                                        Price
                                    </div>
                                </>
                            );

                            const flagToggler = (
                                <Button square disabled={disabled}>
                                    <Plus />
                                </Button>
                            );

                            return (
                            <Dropdown
                                isOpen={isOpen}
                                toggle={toggle}
                                flagToggler={flagToggler}
                                items={dropdownItems}
                                additionalMenuClassName="ad-message-tag-dropdown__menu"
                                additionalDropdownClassName="ad-message-tag-dropdown"
                            />
                        )}}
                    </DropdownProvider>
                )}
            </div>
        );
    }
}

AdMessageInput.defaultProps = {
    inputClassName: '',
    wrapperClassName: '',
    useDynamicTagsDropdown: true,
    disabled: false,
    error: false,
    onMountFocus: false,
};

AdMessageInput.propTypes = {
    inputClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    adMessage: PropTypes.string.isRequired,
    changeAdMessage: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    useDynamicTagsDropdown: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onMountFocus: PropTypes.bool,
};

export default connect(null)(AdMessageInput);
