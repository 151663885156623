import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Modal, ModalBody, ModalFooter } from 'components/Modal/Modal';
import { connect } from 'react-redux';

import { requestShopBillingInfo } from 'actions/shop';
import {
    requestCreateStripeCustomer,
    requestUpdateStripeSource,
    handleErrorStripeCreateSource,
    resetStripeData,
} from 'actions/stripe';
import { CREATE_SOURCE_ERROR } from 'constants/platform';
import { displayBulkNotification } from 'actions/notification';
import { isStripeAdded } from 'helpers/onboardingHelper';
import { getCurrentShopSelector } from 'helpers/selectors';

import IntercomChatCaller from 'components/IntercomChatCaller';

import './ModalAddStripe.css';
import logo from './logo.svg';
import poweredByStripe from './powered_by_stripe.svg';
import securedByStripe from './secured_by.svg';
import locker from './locker.svg';
import cardIcons from './card_icons.svg';
import createOptions from './stripeStyleConfig';

class ModalAddStripe extends Component {
    static handleStripeReady(StripeElement) {
        StripeElement.focus();
    }

    constructor(props) {
        super(props);
        this.state = {
            completeCard: false,
            formError: null,
            saveChangesBtnPending: false,
            billingInfoYear: new Date().getFullYear(),
        };
        this.submitCardHandler = this.submitCardHandler.bind(this);
        this.handleChangeCardInput = this.handleChangeCardInput.bind(this);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.showStripeCardWasSuccessfullyAdded = this.showStripeCardWasSuccessfullyAdded.bind(this);
    }

    showStripeCardWasSuccessfullyAdded({ showBulkNotification, successBulkMessage } = this.props) {
        if (!window.location.pathname.endsWith('/onboarding/stripe-billing')) {
            showBulkNotification(successBulkMessage || 'Card was successfully added', null, 3000);
        }
    }

    handleChangeCardInput(change) {
        if (change.error) {
            this.setState({ completeCard: false, formError: change.error.message });
        } else {
            this.setState({ completeCard: false, formError: null });
        }
        if (change.complete) {
            this.setState({ completeCard: true, formError: null });
        }
    }

    closeModalHandler() {
        const { trackEventStripeModalClosed, hideModalHandler } = this.props;
        trackEventStripeModalClosed();
        hideModalHandler();
        this.setState({formError: null});
        this.props.resetStripeData();
    }

    async submitCardHandler() {
        const { completeCard, billingInfoYear } = this.state;
        this.setState({ saveChangesBtnPending: true });
        const {
            requestCreateStripeCustomer,
            requestUpdateStripeSource,
            shopId,
            handleErrorStripeCreateSource,
            hideModalHandler,
            trackEventStripeCardAdded,
            trackEventStripeCardReplaced,
            additionalAction,
            replaceCard,
            stripeIsAdded,
            getBillingInfo,
        } = this.props;
        if (completeCard) {
            this.props.stripe
                .createSource({
                    type: 'card',
                    usage: 'reusable',
                }).then(
                    ({ source }) => {
                        if (source) {
                            const cardData = {
                                id: source.id,
                                client_secret: source.client_secret,
                                card: source && source.card ? {
                                    brand: source.card.brand,
                                    exp_month: source.card.exp_month,
                                    exp_year: source.card.exp_year,
                                    last4: source.card.last4,
                                    address_zip_check: source.card.address_zip_check,
                                } : {},
                            };

                            return !stripeIsAdded
                                ? requestCreateStripeCustomer(shopId, cardData)
                                : requestUpdateStripeSource(shopId, cardData);
                        }
                        return handleErrorStripeCreateSource();
                    },
                    () => handleErrorStripeCreateSource(),
                )
                .then(() => {
                    const { error } = this.props;
                    if (error) throw Error(error);
                    if (additionalAction) return additionalAction();
                    return null;
                })
                .then((error) => {
                    this.setState({ saveChangesBtnPending: false });
                    if (replaceCard) {
                        trackEventStripeCardReplaced();
                    } else trackEventStripeCardAdded();
                    hideModalHandler();
                    if (!error) {
                        getBillingInfo(shopId, { billingYear: billingInfoYear });
                        this.showStripeCardWasSuccessfullyAdded();
                    };
                })
                .catch((error) => {
                    this.setState({ saveChangesBtnPending: false });
                });
        }
    }

    render() {
        const { completeCard, formError } = this.state;
        const {
            isOpenModal,
            pending,
            error,
            stripeBillingAmount,
            stripeModalHeader,
            stripeModalBody,
            stripeModalList,
        } = this.props;
        const saveChangesBtnText = stripeBillingAmount ? `pay ${stripeBillingAmount} usd` : 'save changes';
        let settingError = null;

        let saveChangesBtn = (
            <button
                tabIndex={0}
                disabled={!completeCard}
                onClick={this.submitCardHandler}
                className="modal-add-stripe__add-card-btn"
                onKeyPress={(event) => (event.key === 'Enter' ? this.submitCardHandler() : null)}
            >
                <img src={locker} alt="locker" />
                <span>{saveChangesBtnText}</span>
            </button>
        );
        const detailOptions = stripeModalList.map((item, index) => <li key={index}>{item}</li>);
        if (pending || this.state.saveChangesBtnPending) {
            saveChangesBtn = (
                <button disabled className="modal-add-stripe__add-card-btn modal-add-stripe__add-card-btn-pending">
                    Pending
                </button>
            );
        }
        if (error) {
            if (error === CREATE_SOURCE_ERROR) {
                settingError = (
                    <p className="modal-add-stripe__add-card-error">
                        This doesn&#39;t look right{' '}
                        <span aria-label="omg" role="img">
                            {' '}
                            🤔
                        </span>
                        Please check card details and try again.
                    </p>
                );
            } else {
                settingError = (
                    <p className="modal-add-stripe__add-card-error">
                        Oops! We couldn&#39;t add your card.
                        <span aria-label="omg" role="img">
                            {' '}
                            😮{' '}
                        </span>
                        Please try again or{' '}
                        <IntercomChatCaller
                            className="modal-add-stripe__add-card-error__underline"
                            content="contact us"
                        />
                        .
                    </p>
                );
            }
        }
        if (formError) {
            settingError = <p className="modal-add-stripe__add-card-error">{formError}</p>;
        }
        return (
            <Modal className="modal-form__dialog modal-add-stripe" isOpen={isOpenModal} centered>
                <button className="modal-special__close-button" tabIndex={0} onClick={this.closeModalHandler} />
                <img className="modal-add-stripe__header-logo" src={logo} alt="Adwisely" />
                <ModalBody className="modal-add-stripe__header">
                    <h5>{stripeModalHeader}</h5>
                    <p>{stripeModalBody}</p>
                </ModalBody>
                <ModalBody className="modal-add-stripe__body">
                    <h5 className="modal-add-stripe__body-billed">Billed now: {stripeBillingAmount} USD</h5>
                    <div className="modal-add-stripe__body-card-element">
                        <img src={cardIcons} alt="cardIcons" />
                        <div className="modal-add-stripe__body-card-element-card">
                            <p>
                                <span>Card number</span> {'  '}
                                <img src={securedByStripe} alt="securedByStripe" />
                            </p>
                            <CardElement
                                tabIndex={0}
                                onChange={this.handleChangeCardInput}
                                onReady={ModalAddStripe.handleStripeReady}
                                {...createOptions()}
                            />
                        </div>
                    </div>
                    {settingError}
                    {saveChangesBtn}
                    <div className="modal-add-stripe__body-detail-options">
                        <ul>{detailOptions}</ul>
                    </div>
                </ModalBody>

                <ModalFooter className="modal-add-stripe__footer">
                    <img src={poweredByStripe} alt="powered_by_stripe" />
                </ModalFooter>
            </Modal>
        );
    }
}

ModalAddStripe.defaultProps = {
    stripeModalHeader: "Pay Adwisely fee directly to Adwisely",
    stripeModalBody: "To run ads for you, Adwisely has created a brand new ad account. Please, connect a payment method to it",
    stripeModalList: [],
    rechargeCard: false,
    stripeBillingAmount: '0',
    error: null,
};

ModalAddStripe.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    hideModalHandler: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    stripeModalHeader: PropTypes.string,
    stripeModalBody: PropTypes.node,
    stripeModalList: PropTypes.arrayOf(PropTypes.any),
    rechargeCard: PropTypes.bool,
    // eslint-disable-next-line
    error: PropTypes.any,
    stripeBillingAmount: PropTypes.string.isRequired,
};
const mapStateToProps = ({ onboarding, stripeCharge, shops }) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    const stripeIsAdded = isStripeAdded(onboarding.stripeAdd, currentShop);
    return {
        pending: onboarding.stripeAdd.pending,
        success: onboarding.stripeAdd.success,
        error: onboarding.stripeAdd.error,
        stripeBillingAmount: parseFloat(stripeCharge.stripeBillingAmount).toFixed(2),
        stripeIsAdded,
    };
};

const mapDispatchToProps = {
    requestCreateStripeCustomer,
    requestUpdateStripeSource,
    resetStripeData,
    handleErrorStripeCreateSource,
    showBulkNotification: displayBulkNotification,
    getBillingInfo: requestShopBillingInfo,
};

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(ModalAddStripe));
