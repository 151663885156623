import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal/Modal';
import classNames from 'classnames';

import './ModalWindowWidget.css';

const MODAL_WINDOW_TYPE = {
    info: 'modal-widget__dialog_info',
    success: 'modal-widget__dialog_success',
    error: 'modal-widget__dialog_error',
};

class ModalWindowWidget extends Component {
    render() {
        const {
            type,
            isOpen,
            toggle,
            additionalModalClassName,
            additionalHeaderClassName,
            additionalBodyClassName,
            additionalFooterClassName,
            headerContent,
            headerHasCloseBtn,
            bodyContent,
            footerContent,
            footerExtended,
        } = this.props;

        return (
            <Modal
                className={classNames('modal-widget__dialog', MODAL_WINDOW_TYPE[type], additionalModalClassName)}
                isOpen={isOpen}
                toggle={toggle}
            >
                {headerContent ? (
                    <ModalHeader
                        toggle={toggle}
                        className={classNames(
                            'modal-widget__header',
                            { 'modal-widget__header_has-close-button': headerHasCloseBtn },
                            additionalHeaderClassName,
                        )}
                    >
                        {headerContent}
                    </ModalHeader>
                ) : null}

                {bodyContent ? (
                    <ModalBody className={classNames('modal-widget__body', additionalBodyClassName)}>
                        {bodyContent}
                    </ModalBody>
                ) : null}

                {footerContent ? (
                    <ModalFooter
                        className={classNames(
                            'modal-widget__footer',
                            { 'modal-widget-footer_extended': footerExtended },
                            additionalFooterClassName,
                        )}
                    >
                        {footerContent}
                    </ModalFooter>
                ) : null}
            </Modal>
        );
    }
}

export default ModalWindowWidget;
