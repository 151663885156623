import { CALL_API } from 'actions/api';
import * as dataProvider from 'data';
import { logException } from 'libs/sentryConfig';
import { environment_name } from 'constants/environment';

export default (state) => (next) => (action) => {
    const APICall = action[CALL_API];
    if (typeof APICall === 'undefined') {
        return next(action);
    }

    const { endpoint, params, method, mockedResponseData, mockedResponseError, expectedClientErrors } = APICall;
    const [reqType, reqSuccess, reqFail] = APICall.types;
    const timestamp = process.env.NODE_ENV !== 'test' ? Date.now() : 'testTimestamp';

    let { interceptor } = APICall;
    if (!interceptor) {
        interceptor = (interceptorNext, interceptorParams) => interceptorNext(interceptorParams);
    }
    interceptor(next, {
        type: reqType,
        payload: params,
        meta: { timestamp },
    });

    let apiCall = () => dataProvider.apiCall(endpoint, params, method);

    if (mockedResponseData) {
        apiCall = () => Promise.resolve(mockedResponseData);
    }

    if (mockedResponseError) {
        apiCall = () => Promise.reject(mockedResponseError);
    }

    return apiCall()
        .then((response) => {
            interceptor(next, {
                type: reqSuccess,
                payload: response?.data ? response.data : response,
                meta:
                    response.meta?.intercom !== undefined
                        ? { timestamp, intercom: response.meta.intercom }
                        : { timestamp },
            });
            if (response.data?.status === 'server-error') {
                if (environment_name !== 'prod') {
                    console.error(new Error('Google API request limit has been exceeded'));
                }
                const appState = state.getState();
                logException(
                    `API Exception ${response.data.status}`,
                    new Error(response.data.status),
                    {
                        shop_id: appState.shops ? appState.shops.currentShopId : '',
                        account_Id: appState.account && appState.account.entity ? appState.account.entity.id : '',
                        request_id: response.meta.request_id,
                    },
                    'javascript',
                    'error',
                );
            }
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            if (environment_name !== 'prod') {
                console.log(action);
                console.error(err);
            }
            interceptor(next, {
                type: reqFail,
                payload: err,
                meta: { timestamp, params },
                error: true,
            });

            if (!expectedClientErrors.includes(err.status)) {
                const errorIndex = err.status ? err.status.toString()[0] : null;
                const errorToken = err.errorToken || 'undefined';
                const requestId = err.requestId || 'undefined';
                const appState = state.getState();

                logException(
                    `API Exception ${errorToken}`,
                    err,
                    {
                        shop_id: appState.shops ? appState.shops.currentShopId : '',
                        account_Id: appState.account && appState.account.entity ? appState.account.entity.id : '',
                        error_token: errorToken,
                        request_id: requestId,
                    },
                    'javascript',
                    errorIndex === '5' ? 'error' : 'warning',
                );
            }

            return Promise.resolve(err);
        });
};
