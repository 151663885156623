import React, {useEffect, useState} from 'react';

export const useFetchInstagramAccounts = (shopId) => {
    const [instagramAccounts, setInstagramAccounts] = useState([]);

    useEffect(() => {
        fetch(`/api/onboarding/${shopId}/facebook_social_media_retargeting/instagram_accounts`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(accounts => {
                setInstagramAccounts(accounts.data)
            })
    }, [])

    return instagramAccounts;
}