import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';

import error from './error';
import loader from './loader';
import notification from './notification';
import onboarding from './onboarding/index';
import facebook from './facebook/index';
import google from './google/index';
import shops from './shops';
import shopError from './shop-error';
import campaign from './campaign';
import account from './account';
import campaignStatistics from './campaign-statistics';
import campaignDetails from './campaign-details';
import campaignsPromo from './campaignsPromo';
import shopsCampaignsSettings from './shopsCampaignsSettings';
import shopCampaignSettingsPrepare from './shopCampaignSettingsPrepare';
import campaignSuggestions from './campaign-suggestions';
import shopRecurringCharge from './shop-recurring-charge';
import bulkNotification from './bulkNotification';
import shopBillingPeriod from './shop-billing-period';
import pageTitle from './pageTitle';
import shopNotifications from './shopNotifications';
import shopQualification from './shopQualification';
import campaignAutoCreateType from './campaignAutoCreateType';
import shopBillingInfo from './shop-billing-info';
import shopInfo from './shopInfo';
import businessManager from './businessManager';
import shopBillingPlanStatus from './shop-billing-plan-status';
import actionHandler from './action';
import stripeCharge from './stripe-charge';
import loadingStatus from './loading-status';
import googleCampaigns from './googleCampaigns';
import googleCampaign from './googleCampaign';
import googleStandardAdPreview from './googleStandardAdPreview';
import googleRemarketingAdPreview from './googleRemarketingAdPreview';
import userBusinessManager from './user-bm';
import featureFlags from './featureFlags';
import shopSubscription from './shop-subscription';

const rootReduces = combineReducers({
    loader,
    shops,
    shopError,
    facebook,
    google,
    onboarding,
    account,
    businessManager,
    campaign,
    googleCampaign,
    googleStandardAdPreview,
    googleRemarketingAdPreview,
    campaignStatistics,
    googleCampaigns,
    campaignDetails,
    campaignsPromo,
    campaignSuggestions,
    campaignAutoCreateType,
    shopsCampaignsSettings,
    shopCampaignSettingsPrepare,
    shopRecurringCharge,
    notification,
    error,
    bulkNotification,
    routing,
    loadingStatus,
    shopBillingPeriod,
    shopBillingPlanStatus,
    pageTitle,
    shopNotifications,
    shopQualification,
    shopBillingInfo,
    shopSubscription,
    shopInfo,
    stripeCharge,
    actionHandler,
    userBusinessManager,
    featureFlags,
});

export default rootReduces;
