import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import ShopifyBilling from 'modules/onboarding/components/ShopifyBilling/ShopifyBilling';

import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import { changeOnboardingState } from '../../../../home/utils';
import { facebookStatePolling } from '../../helpers/ubmStatePolling';

export const ShopifyBillingFacebookView = ({shopId, replace}) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const onRecurringChargeIsApproved = () => {
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.confirm_shopify_payment,
            currentShopId: shopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(shopId, replace)
                }
                return res.json()
            })
            .then((res) => {
                if (res?.processing) {
                    facebookStatePolling(shopId, replace);
                }
            });
    }

    return <ShopifyBilling
        onRecurringChargeIsApproved={onRecurringChargeIsApproved} />
}

const mapStateToProps = ({ shops }) => (
    {
        shopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace,
};

export const ShopifyBillingFacebook =  connect(mapStateToProps, mapPropsToDispatch)(ShopifyBillingFacebookView);


