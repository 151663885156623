import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'components/Modal/Modal';

import './ModalError.css';

const ModalError = (props) => {
    const { isOpen, buttonClickHandler, children, hideModal, buttonName, modalLogo } = props;

    return (
        <Modal className="modal-error__dialog" isOpen={isOpen}>
            <button className="modal-special__close-button" onClick={hideModal} />
            <ModalBody className="modal-error__body">
                <div className={`modal-error-body__logo ${modalLogo}`} />
                <div className="modal-error-body__content">{children}</div>
            </ModalBody>

            <ModalFooter className="modal-error__footer">
                <button className="modal-error__button modal-error__button_primary" onClick={buttonClickHandler}>
                    {buttonName}
                </button>
            </ModalFooter>
        </Modal>
    );
};

ModalError.defaultProps = {
    isOpen: false,
    modalLogo: 'default',
};

ModalError.propTypes = {
    isOpen: PropTypes.bool,
    buttonClickHandler: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    buttonName: PropTypes.string.isRequired,
    modalLogo: PropTypes.string,
};

export default ModalError;
