import {
    callAPIAccountInfo,
    callAPIAccountRemove,
    callAPIChangeUserEmail,
} from './api';

export const ACCOUNT_INFO_REQUEST = 'ACCOUNT_INFO_REQUEST';
export const ACCOUNT_INFO_REQUEST_SUCCESS = 'ACCOUNT_INFO_REQUEST_SUCCESS';
export const ACCOUNT_INFO_REQUEST_FAILURE = 'ACCOUNT_INFO_REQUEST_FAILURE';

export const requestAccountInfo = () => (dispatch) => {
    dispatch(callAPIAccountInfo({
        types: [
            ACCOUNT_INFO_REQUEST,
            ACCOUNT_INFO_REQUEST_SUCCESS,
            ACCOUNT_INFO_REQUEST_FAILURE,
        ],
    }));
};

export const ACCOUNT_REMOVE_REQUEST = 'ACCOUNT_REMOVE_REQUEST';
export const ACCOUNT_REMOVE_SUCCESS = 'ACCOUNT_REMOVE_SUCCESS';
export const ACCOUNT_REMOVE_FAILURE = 'ACCOUNT_REMOVE_FAILURE';

export const removeAccount = () => dispatch => (
    dispatch(callAPIAccountRemove({
        types: [
            ACCOUNT_REMOVE_REQUEST,
            ACCOUNT_REMOVE_SUCCESS,
            ACCOUNT_REMOVE_FAILURE,
        ],
    }))
);

export const ACCOUNT_CHANGE_EMAIL_REQUEST = 'ACCOUNT_CHANGE_EMAIL_REQUEST';
export const ACCOUNT_CHANGE_EMAIL_SUCCESS = 'ACCOUNT_CHANGE_EMAIL_SUCCESS';
export const ACCOUNT_CHANGE_EMAIL_FAILURE = 'ACCOUNT_CHANGE_EMAIL_FAILURE';

export const changeAccountEmail = params => dispatch => (
    dispatch(callAPIChangeUserEmail({
        params,
        types: [
            ACCOUNT_CHANGE_EMAIL_REQUEST,
            ACCOUNT_CHANGE_EMAIL_SUCCESS,
            ACCOUNT_CHANGE_EMAIL_FAILURE,
        ],
    }))
);
