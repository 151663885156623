import React from 'react';
import { connect } from 'react-redux';
import { replace } from "react-router-redux";


import { metricType } from 'constants/metric';
import { requestSendEventMetrics } from 'actions/shop';
import { setGoogleOnboardingState } from "actions/googleOnboardingState";

import { ONBOARDING_TYPES } from 'constants/onboarding';
import { changeOnboardingState } from 'modules/home/utils';
import { ONBOARDING_STATES, ONBOARDING_STEPS } from 'constants/googleOnboarding';

import { GoogleEditStandardAdsForm } from './components/GoogleEditStandardAdsForm';

import './SetupDailyBudget.css';
import {googleStatePolling} from "../../../../helpers/googleStatePolling";

const DEFAULT_CAMPAIGN_SETTINGS = {
    currency: 'USD',
    minBudget: 15,
    dailyBudget: 30,
};

const handleGoogleEvents = ({ state, currentShopId }) => {
    if (state === ONBOARDING_STEPS.oauth) {
        requestSendEventMetrics({
            shop_id: currentShopId,
            type: metricType.pageViewed,
            page: 'google_account_connected', // TODO: replace with constants
            url_path: `/${currentShopId}/onboarding/page`,
        });
    }

    if (state === ONBOARDING_STEPS.approve_app_fee) { // TODO: check campaign_settings state instead of approve_app_fee
        requestSendEventMetrics({
            shop_id: currentShopId,
            type: metricType.pageViewed,
            page: 'phone_verified', // TODO: replace with constants
            url_path: `/${currentShopId}/onboarding/invitation`,
        });
    }
};

const SetupDailyBudgetView = ({
    formName,
    isVerifyPhone,
    currentShopId,
    hasCampaignFormError,
    setHasCampaignFormError,
    replace,
    setGoogleOnboardingState
}) => {
    const { currency, minBudget, dailyBudget } = DEFAULT_CAMPAIGN_SETTINGS;

    const saveCampaign = (daily_budget) => {
        changeOnboardingState({
            currentShopId,
            data: { daily_budget },
            type: ONBOARDING_TYPES.google,
            status: ONBOARDING_STATES.configure_campaign,
        }).then((res) => {
            if (res.status === 409) {
                googleStatePolling(currentShopId, replace, setGoogleOnboardingState)
            }
            return res.json()
        })
            .then((res) => {
                if (!(res instanceof Error)) {
                    handleGoogleEvents({ state: res.state, currentShopId });
                }
                if (res?.processing) {
                    googleStatePolling(currentShopId, replace, setGoogleOnboardingState);
                }
            });
    };

    return (
        <>
            <p className="gl-onboarding-settings__daily-budget__title">
                A budget is a limit for how much you want to pay for your ads for a specific campaign, on average, per
                day.
            </p>

            <GoogleEditStandardAdsForm
                disabled={false}
                formName={formName}
                campaignCurrency={currency}
                campaignMinBudget={minBudget}
                handleFormError={() => null}
                campaignDailyBudget={dailyBudget}
                setHasCampaignFormError={setHasCampaignFormError}
                disabledSubmitButton={isVerifyPhone || hasCampaignFormError}
                saveCampaign={saveCampaign}
            />
        </>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    requestSendEventMetrics,
    replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export const SetupDailyBudget = connect(mapStateToProps, mapPropsToDispatch)(SetupDailyBudgetView);
