import React, { PureComponent } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import Button from 'components/Button';
import { dayDiff } from 'helpers';

import './DropdownDatePicker.css';

class DropdownDatePicker extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedDays: null,
            from: props.fromDate,
            to: props.toDate,
        };

        this.onApplyDate = this.onApplyDate.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    onApplyDate() {
        const { getCustomDateStatistics } = this.props;
        const { from, to } = this.state;
        getCustomDateStatistics(from, to);
    }

    handleDayClick(day) {
        if (day > new Date()) return;
        const range = DateUtils.addDayToRange(day, this.state);
        const selectedDays = range.from && range.to ? dayDiff(range.from, range.to) + 1 : null;
        this.setState({ ...range, selectedDays });
    }

    handleResetClick() {
        const { clearDatePicker } = this.props;
        clearDatePicker();
        this.setState({
            selectedDays: null,
            from: null,
            to: null,
        });
    }

    render() {
        const { from, to, selectedDays } = this.state;
        const modifiers = { start: from, end: to, disabled: { after: new Date() } };
        const initialMonth = new Date();
        initialMonth.setMonth(initialMonth.getMonth() - 1);
        let selectedDaysRow = null;
        if (selectedDays) {
            selectedDaysRow = selectedDays === 1 ? '1 day selected' : `${selectedDays} day(s) selected`;
        }

        return (
            <div className="datepicker__wrapper">
                <DayPicker
                    className="datepicker-selectable"
                    numberOfMonths={2}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    initialMonth={initialMonth}
                    onDayClick={this.handleDayClick}
                    disabledDays={{ after: new Date() }}
                />
                <div className="datepicker-buttons__wrapper">
                    <Button color="primary" onClick={this.onApplyDate} disabled={!(from && to)}>
                        Apply
                    </Button>
                    <Button onClick={this.handleResetClick}>Clear</Button>
                    <p>{selectedDaysRow}</p>
                </div>
            </div>
        );
    }
}

DropdownDatePicker.defaultProps = {
    getCustomDateStatistics: () => {},
    numberOfMonths: 2,
};

export default DropdownDatePicker;
