import React, {useState} from 'react';
import {Modal, ModalBody} from 'components/Modal/Modal';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';

export const ToDoListModalView = ({
        isOpen,
        children,
        checkboxLabel,
        onCompleteBtnClick,
        onSupportBtnClick,
        onCloseBtnClick }) => {

    const [isChecked, setIsChecked] = useState(false);
    return (
        <Modal isOpen={isOpen} className="todo-list-modal">
            <div className="modal-common__close-button todo-list-modal__close-btn" onClick={onCloseBtnClick}/>
            <ModalBody className="todo-list-modal__body">
                {children}
                <label htmlFor="todoListModalCheckbox">
                    <Checkbox
                        onChange={() => {
                            setIsChecked(!isChecked)
                        }}
                        checkboxId="todoListModalCheckbox"
                        initialCheckedValue={isChecked}/>
                    {checkboxLabel}
                </label>
                <div className="todo-list-modal__btns">
                    <Button color="primary" onClick={onCompleteBtnClick} disabled={!isChecked}>Complete the
                        step</Button>
                    <Button onClick={onSupportBtnClick}>Contact Support</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}