export default () => {
    /* eslint-disable */
    (function(i,s,o,g,r,a,m){
            i["esSdk"] = r;
            i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments)
            }, a=s.createElement(o), m=s.getElementsByTagName(o)[0]; a.async=1; a.src=g;
            m.parentNode.insertBefore(a,m)}
    ) (window, document, "script", "https://esputnik.com/scripts/v1/public/scripts?apiKey=eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0NTI0ZWZhYTJkYzI2MGRmYTM4YTE1NDBlMWFlYmU0MmQ5YmYyMTEwMjk1ZGZhOTIwYWJkNDk2Y2Q3OGY1NTQzMjVhZWZlN2EwMGY1YTkyOTM1MGU2ZGY5ZGUyNmZkMDA0NWJjYjkwYjU2MzY3ZmE0YTE3NTAzYzQyMjI0ZWI1MmFiYzNhOTM2MzE3NTdhNDJlMTM4ZWQ3M2EwOGI0MjE2MDM4OGU2OTQ5YzFjNjQ1YWZhMDdhMWQ4In0.d13rtGXYwqwL56dWetv3hC5czKU7747_nGODuZwB6v7JsI2jxzqShdb_WnvLhzSbW_KOCVRQQdAsoPmpTq8LzQ&domain=910627B7-2AEF-443F-B1FA-1A12B2F05093", "es");
    es("pushOn");
    /* eslint-enable */
}
