import {
    callAPIShopsCampaignsSettings,
    callAPISetCampSettings,
    callAPIShopCampaignsSettingsPrepare,
    callAPICreateChildBM,
    callAPICreateAdAccountInChildBM,
} from './api';

export const SHOP_CAMPAIGN_SETTINGS_REQUEST = 'SHOP_CAMPAIGN_SETTINGS_REQUEST';
export const SHOP_CAMPAIGN_SETTINGS_SUCCESS = 'SHOP_CAMPAIGN_SETTINGS_SUCCESS';
export const SHOP_CAMPAIGN_SETTINGS_FAILURE = 'SHOP_CAMPAIGN_SETTINGS_FAILURE';

export const requestShopsCampaignsSettings = shopId => callAPIShopsCampaignsSettings({
    types: [
        SHOP_CAMPAIGN_SETTINGS_REQUEST,
        SHOP_CAMPAIGN_SETTINGS_SUCCESS,
        SHOP_CAMPAIGN_SETTINGS_FAILURE,
    ],
    params: {
        shopId,
    },
});

export const SHOP_CAMPAIGN_SETTINGS_UPDATE_REQUEST = 'SHOP_CAMPAIGN_SETTINGS_UPDATE_REQUEST';
export const SHOP_CAMPAIGN_SETTINGS_UPDATE_SUCCESS = 'SHOP_CAMPAIGN_SETTINGS_UPDATE_SUCCESS';
export const SHOP_CAMPAIGN_SETTINGS_UPDATE_FAILURE = 'SHOP_CAMPAIGN_SETTINGS_UPDATE_FAILURE';

export const setShopCampaignSettings = (shopId, {adAccountId, pageId, businessId}) => callAPISetCampSettings(shopId, {
    types: [
        SHOP_CAMPAIGN_SETTINGS_UPDATE_REQUEST,
        SHOP_CAMPAIGN_SETTINGS_UPDATE_SUCCESS,
        SHOP_CAMPAIGN_SETTINGS_UPDATE_FAILURE,
    ],
    params: {
        fb_page_id: pageId,
        fb_ad_account_id: adAccountId,
        fb_business_id: businessId,
    },
});

export const SHOP_CAMPAIGN_SETTINGS_PREPARE_REQUEST = 'SHOP_CAMPAIGN_SETTINGS_PREPARE_REQUEST';
export const SHOP_CAMPAIGN_SETTINGS_PREPARE_SUCCESS = 'SHOP_CAMPAIGN_SETTINGS_PREPARE_SUCCESS';
export const SHOP_CAMPAIGN_SETTINGS_PREPARE_FAILURE = 'SHOP_CAMPAIGN_SETTINGS_PREPARE_FAILURE';

export const setShopCampaignSettingsPrepare = (shopId, businessId) => dispatch =>
    dispatch(callAPIShopCampaignsSettingsPrepare({
        types: [
            SHOP_CAMPAIGN_SETTINGS_PREPARE_REQUEST,
            SHOP_CAMPAIGN_SETTINGS_PREPARE_SUCCESS,
            SHOP_CAMPAIGN_SETTINGS_PREPARE_FAILURE,
        ],
        params: {
            shopId,
            business_id: businessId,
        },
    })).then((res) => {
        if (!(res instanceof Error)) {
            dispatch({type: SHOP_CAMPAIGN_SETTINGS_SUCCESS, payload: res});
        }
        return res;
    });


export const CREATE_CHILD_BM_REQUEST = 'CREATE_CHILD_BM_REQUEST';
export const CREATE_CHILD_BM_SUCCESS = 'CREATE_CHILD_BM_SUCCESS';
export const CREATE_CHILD_BM_FAILURE = 'CREATE_CHILD_BM_FAILURE';

export const createChildBM = shopId => callAPICreateChildBM({
    types: [
        CREATE_CHILD_BM_REQUEST,
        CREATE_CHILD_BM_SUCCESS,
        CREATE_CHILD_BM_FAILURE,
    ],
    params: {
        shopId,
    },
});

export const CREATE_AD_ACCOUNT_IN_CHILD_BM_REQUEST = 'CREATE_AD_ACCOUNT_IN_CHILD_BM_REQUEST';
export const CREATE_AD_ACCOUNT_IN_CHILD_BM_SUCCESS = 'CREATE_AD_ACCOUNT_IN_CHILD_BM_SUCCESS';
export const CREATE_AD_ACCOUNT_IN_CHILD_BM_FAILURE = 'CREATE_AD_ACCOUNT_IN_CHILD_BM_FAILURE';

export const createAdAccountInChildBM = shopId => callAPICreateAdAccountInChildBM({
    types: [
        CREATE_AD_ACCOUNT_IN_CHILD_BM_REQUEST,
        CREATE_AD_ACCOUNT_IN_CHILD_BM_SUCCESS,
        CREATE_AD_ACCOUNT_IN_CHILD_BM_FAILURE,
    ],
    params: {
        shopId,
    },
});
