import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { ROUTES } from 'constants/routes';

export class CampaignIndex extends Component {
    componentDidMount() {
        const {
            replace,
            params: { shopId },
            campaignDetails,
        } = this.props;

        if (campaignDetails.campaignDetailsData.length) {
            replace(`/${shopId}${ROUTES.dashboard}`);
            return;
        }

        replace(`/${shopId}/home`);
    }

    render() {
        return null;
    }
}

// @TODO: unused props: account, shopsCampaignsSettings
const props = ({ account, shopsCampaignsSettings, campaignDetails }) => ({
    account,
    shopsCampaignsSettings,
    campaignDetails,
});

export default connect(props, { replace })(CampaignIndex);
