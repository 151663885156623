import { googleFlow } from "modules/home/utils";
import { setLocalStorageObjectItem } from "helpers";
import {ONBOARDING_STEPS} from "constants/googleOnboarding";

export const getGoogleRedirectPage = (res, currentShopId, replace) => {
    if (res?.state === 'done') {
        googleFlow.done();
        setLocalStorageObjectItem('activeTab', '1');
        replace(`/${currentShopId}/dashboard`);
        return;
    }

    if (res?.state === 'failed') {
        replace(`/${currentShopId}/google/error`);
        return;
    }

    if (Object.keys(ONBOARDING_STEPS).includes(res?.state)) {
        replace(`/${currentShopId}/google/${ONBOARDING_STEPS[res?.state]}`);
    } else {
        replace(`/${currentShopId}/home`);
    }
}