import React, { useEffect, useState } from 'react';

export const useGetToDoList = (shopId) => {
    const [todoListData, setTodoListData] = useState([]);

    const updateTodoList = (listName, listItem) => {
        const todoListDataItemIndex = todoListData.findIndex(item => item.name === listName);
        const todoListDataItem = todoListData[todoListDataItemIndex];
        const itemToChangeIndex = todoListDataItem.items.findIndex(item => item.id === listItem.id);
        const newTodoList = [...todoListDataItem.items.slice(0, itemToChangeIndex), listItem, ...todoListDataItem.items.slice(itemToChangeIndex + 1)];
        const newTodoListDataItem = {...todoListDataItem, items: newTodoList};
        const newTodoListData = [...todoListData.slice(0, todoListDataItemIndex), newTodoListDataItem, ...todoListData.slice(todoListDataItemIndex + 1)];
        setTodoListData(newTodoListData);
    }

    useEffect(() => {
        fetch(`/api/shops/${shopId}/todo-lists`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => {
                setTodoListData(data.data)
            })
    }, [])

    return [todoListData, updateTodoList];
}