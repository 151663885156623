import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Card, CardContent, CardTitle } from 'components/Card';
import { Progress } from 'components/Progress/Progress';

import { requestShopBillingPlanStatus } from 'actions/shop';
import { numberWithCommas } from 'helpers';

const ACTIVE_CAMPAIGN_CLIENT_STATUS = 'active';
const hourInMilliseconds = 1000 * 3600;

class BillingPlanStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.billingStatusInterval = null;
        this.checkActiveCampaigns = this.checkActiveCampaigns.bind(this);
    }

    componentDidMount() {
        const { requestShopBillingPlanStatus, shopId } = this.props;

        if (this.checkActiveCampaigns()) {
            this.billingStatusInterval = setInterval(() => {
                requestShopBillingPlanStatus(shopId);
            }, hourInMilliseconds);
        }
    }

    componentWillUnmount() {
        clearInterval(this.billingStatusInterval);
    }

    checkActiveCampaigns() {
        const { campaignDetails } = this.props;
        let activeCampaigns = true;
        campaignDetails.forEach((item) => {
            activeCampaigns = ACTIVE_CAMPAIGN_CLIENT_STATUS === item.client_status;
        });
        return activeCampaigns;
    }

    render() {
        const { currency, currentBillingPlan, nextBillingPlan, spends, billingPeriodDateRange } = this.props;
        let addSpentView = null;
        let currentBillingPlanView = null;
        let billingPeriodDateRangeView = null;
        if (billingPeriodDateRange) {
            billingPeriodDateRangeView = (
                <div>
                    <p>
                        <b>Current billing period:&nbsp;</b>
                        {billingPeriodDateRange}
                    </p>
                </div>
            );
        }
        if (currentBillingPlan) {
            currentBillingPlanView = (
                <div>
                    <p>
                        <b>Current fee:&nbsp;</b>
                        {currentBillingPlan.fee && numberWithCommas(currentBillingPlan.fee)}&nbsp;{currency}
                    </p>
                </div>
            );
        }
        if (nextBillingPlan) {
            addSpentView = (
                <div>
                    <p>
                        <b>Ad spend during the current billing period:&nbsp;</b>
                        {numberWithCommas(spends)}&nbsp;{currency}&nbsp;
                    </p>
                </div>
            );
        }

        return (
            <Card className="settings-page__container">
                <div className="settings-page__block-content_flex">
                    <div className="settings-page__block-content-wrapper">
                        <CardTitle>Billing Plan</CardTitle>
                        <CardContent>
                            <p>
                                Adwisely fee depends on the total ad spend of your store per billing period.&nbsp;
                                <a
                                    href={
                                        'https://help.adwisely.com/en/articles/8608646-legacy-billing-plans'
                                    }
                                    target=" _blank"
                                    rel=" noopener noreferrer"
                                    className=" modal-special-body__underline"
                                >
                                    Learn more about billing plans
                                </a>
                                .
                                <br />
                                Here is the up-to-date information about your ad spend and fee.
                            </p>
                        </CardContent>
                        <CardContent className="billing-plan-statistics_block">
                            <div className="billing-plan-statistics_block-details">
                                {billingPeriodDateRangeView}
                                {addSpentView}
                                {currentBillingPlanView}
                            </div>
                        </CardContent>
                    </div>
                </div>
            </Card>
        );
    }
}

BillingPlanStatus.propTypes = {
    campaignDetails: PropTypes.shape({
        client_status: PropTypes.string,
    }).isRequired,
    currency: PropTypes.string,
    currentBillingPlan: PropTypes.shape({
        fee: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        spends: PropTypes.number,
    }),
    nextBillingPlan: PropTypes.shape({
        fee: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        spends: PropTypes.number,
    }),
    requestShopBillingPlanStatus: PropTypes.func.isRequired,
    shopId: PropTypes.number.isRequired,
    spends: PropTypes.string.isRequired,
    billingPeriodDateRange: PropTypes.string.isRequired,
};

BillingPlanStatus.defaultProps = {
    currency: null,
    currentBillingPlan: null,
    nextBillingPlan: null,
    billingPeriodDateRange: null,
};

const mapStateToProps = (state) => ({
    shopId: +state.shops.currentShopId,
    currency: state.shopBillingPlanStatus.currency,
    currentBillingPlan: state.shopBillingPlanStatus.currentBillingPlan,
    nextBillingPlan: state.shopBillingPlanStatus.nextBillingPlan,
    spends: parseFloat(state.shopBillingPlanStatus.spends).toFixed(2),
    billingPeriodDateRange: state.shopBillingPlanStatus.billingPeriodDateRange,
    error: state.shopBillingPlanStatus.error,
    campaignDetails: state.campaignDetails.campaignDetailsData,
});
const mapDispatchToProps = {
    requestShopBillingPlanStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPlanStatus);
