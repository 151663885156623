import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";

export const StripeBillingView = ({ title, subTitle, billingText, addStripeModal, showModalHandler }) => (
    <div className="stripe-billing__wrapper">
        <h1 className="stripe-billing__title">{title}</h1>
        <section className="stripe-billing__section">
            <p className="stripe-billing__subtitle">{subTitle}</p>
            <p className="stripe-billing__text">
                {billingText}
            </p>
            <p className="stripe-billing__text--info">
                <a
                    className="stripe-billing__link"
                    href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-retargetapp"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn more
                </a>
            </p>
            <Button color="primary" className="stripe-billing__button" onClick={showModalHandler}>
                Add Card
            </Button>
        </section>
        {addStripeModal}
    </div>
);

StripeBillingView.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    billingText: PropTypes.string.isRequired,
    addStripeModal: PropTypes.node.isRequired,
    showModalHandler: PropTypes.func.isRequired,
};