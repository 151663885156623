import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { logException } from 'libs/sentryConfig';

import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';
import { getLocalStorageObjectItem } from 'helpers';

import './ConfirmPixel.css';

const ConfirmPixel = ({ currentShopId, replace, ubmId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPixelsPending, setIsPixelsPending] = useState(false);
    const [selectedPixelId, setSelectedPixelId] = useState(null);
    const [pixels, setPixels] = useState([]);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    useEffect(() => {
        setIsPixelsPending(!isPixelsPending);
        currentOnboarding && fetch(`/api/onboarding/${currentShopId}/${currentOnboarding}/pixels`)
            .then((res) => {
                if (!res.ok) {
                    const err = new Error(`Status code: ${res.status}`);
                    err.status = res.status;
                    err.statusText = res.statusText;
                    throw err;
                }
                return res.json();
            })
            .then((res) => {
                if (!(res instanceof Error)) {
                    setSelectedPixelId(res.data[0]?.id);
                    setPixels(res.data);
                    setIsPixelsPending(!isPixelsPending); 
                }
            })
            .catch((error) => {
                logException(
                    `Couldn't fetch Pixels. Reason: ${error.statusText}`,
                    error,
                    { currentShopId },
                    'javascript',
                    'error',
                );
            });
    }, []);

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.assign_pixel_to_ad_account,
            currentShopId,
            data: { pixel_fb_id: selectedPixelId },
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
    }

    const onChange = (pixelId) => setSelectedPixelId(pixelId);

    const getPixelIdLink = (pixelId) => <a href={`https://business.facebook.com/settings/pixels/${pixelId}?business_id=${ubmId}`} target="_blank" rel="noopener noreferrer">{pixelId}</a>;
    
    const chosenPixel = pixels?.find((pixel) => +pixel.id === +selectedPixelId);

    let pixelSelector = null;

    if (isPixelsPending) {
        pixelSelector = <p className="confirm-pixel__description confirm-pixel__description--offset">Loading...</p>
    }

    if (pixels?.length > 1) {
        pixelSelector = <DropdownProvider>
            {({ toggle, isOpen }) => {
                const dropdownItems = pixels?.map((pixel, key) => (
                    <DropdownItemMain
                        key={key}
                        value={pixel.id}
                        onClick={() => {
                            onChange(pixel.id);
                            toggle();
                        }}
                        active={+pixel.id === +selectedPixelId}
                        title={pixel.name}
                        subtitle={<span>ID: {getPixelIdLink(pixel.id)}</span>}
                    />
                ));

                const flagToggler = (
                    <FlagToggler
                        title={chosenPixel ? chosenPixel.name : null}
                        subtitle={chosenPixel ? <span>ID: {getPixelIdLink(chosenPixel.id)}</span> : null}
                        findIcon
                    >
                        Select Meta Pixel
                    </FlagToggler>
                );

                return (
                    <Dropdown toggle={toggle} isOpen={isOpen} flagToggler={flagToggler} items={dropdownItems} />
                )
            }}
        </DropdownProvider>
    }

    if (pixels?.length === 1) {
        pixelSelector = <p className="confirm-pixel__description confirm-pixel__description--offset">
            <strong>[{pixels[0]?.name}] (ID: {getPixelIdLink(pixels[0]?.id)})</strong>
        </p> 
    }

    return (
        <div className="confirm-pixel__wrapper">
            <h1 className="confirm-pixel__title">Select your Meta Pixel</h1>
            <div className="confirm-pixel__body-wrapper">
                <div>
                    <p className="confirm-pixel__description">
                        Adwisely will use your Meta Pixel to run ads. Kindly choose Meta Pixel, which is already installed on your website.
                    </p>
                    <h6>Meta Pixel</h6>
                    {pixelSelector}
                </div>
                <BookACallSection />
            </div>
            <Button onClick={handleContinueBtn} color="primary" disabled={!selectedPixelId}>
                Continue
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops, shopsCampaignsSettings }) => ({
    currentShopId: shops?.currentShopId,
    ubmId: shopsCampaignsSettings.entity.fb_business_manager_fb_id,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ConfirmPixel);
