import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import './Tabs.css';

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '0',
            disableTab: false,
        };
    }

    UNSAFE_componentWillMount() {
        const { campaignDetailsData } = this.props;

        const retargetingCampaign = campaignDetailsData.find(campaign => campaign.type === "retargeting");
        const retargetingECR = retargetingCampaign?.ad_sets?.find(ad_set => ad_set.type === "retargeting_ecr");

        if (retargetingECR?.effective_status === 'CAMPAIGN_PAUSED') {
            this.setState({ disableTab: true });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        const { tabsTitles, tabsContent } = this.props;
        const { activeTab, disableTab } = this.state;
        return (
            <div className="tabs">
                <div className="tabs-navigation">
                    {tabsTitles.map((title, id) => (
                        <div key={id}>
                            <a
                                className={classNames(
                                    'tabs-navigation__link',
                                    { 'tabs-navigation__link_active': activeTab === `${id}` },
                                    { 'disable-tab': disableTab && title.props.className.includes('retargeting') },
                                )}
                                onClick={() => {
                                    this.toggle(`${id}`);
                                }}
                            >
                                {title}
                            </a>
                        </div>
                    ))}
                </div>
                <div className="tabs-content">
                    {tabsContent.map((tabContent, id) => (
                        <div className={classNames('tabs-pane', {'active':  activeTab === `${id}`})} key={id}>
                            {tabContent}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    campaignDetailsData: state.campaignDetails.campaignDetailsData,
});

Tabs.propTypes = {
    tabsTitles: PropTypes.arrayOf(PropTypes.element).isRequired,
    tabsContent: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default connect(mapStateToProps)(Tabs);
