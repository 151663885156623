import React, { Component } from 'react';
import { connect } from 'react-redux';

import { requestDeleteCampaignPromo } from '../../../../actions/campaign';
import { CAMPAIGN_CLIENT_STATUS } from '../helpers';
import { numberDigitSeparateWithSpace } from '../../../../helpers/index';
import { displayBulkNotification } from '../../../../actions/notification';
import campaignEditHelper from '../../../../helpers/campaignEditHelper';
import { trackEventPromoBannerCTAClicked } from '../../../../actions/intercom';
import { changeDailyBudgetPage } from '../../../../constants/campaign';

import CampaignDetailsPromo, { TYPE_BFCM, TYPE_XMAS } from './CampaignDetailsPromo';

const TYPE_BLACK_FRIDAY = 'black_friday';
const TYPE_CYBER_MONDAY = 'cyber_monday';
const TYPE_CHRISTMAS = 'christmas';

const copyright = {
    [TYPE_CHRISTMAS]: {
        retargeting: 'Boost your sales this holiday season',
        prospecting: 'Promote your holiday deals',
    },
    [TYPE_BLACK_FRIDAY]: {
        retargeting: 'Boost your sales this Black Friday',
        prospecting: 'Get more customers this Black Friday',
    },
    [TYPE_CYBER_MONDAY]: {
        retargeting: 'Boost your sales this Cyber Monday',
        prospecting: 'Get more customers this Cyber Monday',
    },
};

const promoTypes = {
    [TYPE_CHRISTMAS]: TYPE_XMAS,
    [TYPE_BLACK_FRIDAY]: TYPE_BFCM,
    [TYPE_CYBER_MONDAY]: TYPE_BFCM,
};

const getDoubleDailyBudget = (target) => {
    if (target.settings) {
        return `${numberDigitSeparateWithSpace((target.settings.daily_budget * 2) / 100)} ${target.currency}`;
    }
    return '-';
};

const today = new Date();
const yyyy = today.getFullYear();
const mm = today.getMonth() + 1;
const dd = today.getDate();
export const currentDate = `${yyyy}-${mm}-${dd}`;

export class CampaignDetailsPromoContainer extends Component {
    increaseDailyBudget = (cb) => {
        const { campaignPromo, campaign, applyPromo, toggleBulkNotification, intercomEventCTA } = this.props;

        const newDB = Math.ceil(campaign.settings.daily_budget * 0.02) * 100;

        intercomEventCTA(campaignPromo.promo_type);

        applyPromo(campaignPromo, campaign, newDB).then((res) => {
            cb();
            if (!(res instanceof Error)) {
                toggleBulkNotification('Daily budget applied');
            }
        });
    };

    render() {
        const { campaignPromo, campaign } = this.props;

        const doubleDB = getDoubleDailyBudget(campaign);

        const showPromo =
            campaignPromo &&
            campaignPromo.start_at &&
            campaignPromo.end_at &&
            campaignPromo.show &&
            today >= new Date(campaignPromo.start_at) &&
            today <= new Date(campaignPromo.end_at) &&
            (campaign.client_status === CAMPAIGN_CLIENT_STATUS.ACTIVE ||
                campaign.client_status === CAMPAIGN_CLIENT_STATUS.PAUSED);

        if (!showPromo) return null;

        if (Object.keys(promoTypes).includes(campaignPromo.promo_type) === false) return null;

        return (
            <div>
                <CampaignDetailsPromo
                    msg={copyright[campaignPromo.promo_type][campaign.type]}
                    ctaMsg={`Apply Daily Budget ${doubleDB}`}
                    promoType={promoTypes[campaignPromo.promo_type]}
                    ctaAction={this.increaseDailyBudget}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    campaignPromo: state.campaignsPromo.campaignsPromoData.find((promo) => ownProps.campaign.id === promo.campaign),
});

const mapDispatchToProps = {
    toggleBulkNotification: displayBulkNotification,
    applyPromo: (campaignPromo, campaign, newDB) => (dispatch, getState) => {
        const params = {
            id: +campaign.id,
            shopId: campaign.shop,
            campaign_type: campaign.type,
            daily_budget: newDB,
            message: campaign.settings.message,
            set_from_page: changeDailyBudgetPage.dashboard,
            promo: campaignPromo.promo_type,
        };
        return campaignEditHelper(params, dispatch, getState).then((res) => {
            if (!(res instanceof Error)) {
                dispatch(requestDeleteCampaignPromo(campaign.shop, campaignPromo.id));
            }
        });
    },
    intercomEventCTA: trackEventPromoBannerCTAClicked,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetailsPromoContainer);
