import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { push } from 'react-router-redux';

import {
    requestCampaignValidateFacebook,
    requestPauseCampaign,
    requestResumeCampaign,
    requestResumeGoogleCampaign,
    requestShopCampaigns,
    requestGoogleCampaigns,
    requestGoogleStandardAdPreview,
    requestGoogleRemarketingAdPreview,
} from 'actions/campaign';
import {
    clearShopError,
    requestShops,
    validateShop,
    requestShopRecommendation,
    requestSendEventMetrics,
} from 'actions/shop';
import {
    displayBulkNotification,
    requestUpdateShopNotification,
    requestShopNotificationsDeleteReadAllOrders,
    requestDeleteShopNotification,
    requestShopNotifications,
} from 'actions/notification';
import { requestRechargeUseStripeCard, requestStripeBillingAmount } from 'actions/stripe';
import {
    trackEventCampaignResumed,
    trackEventInitiateCampaignPause,
} from 'actions/intercom';
import { READ_ALL_ORDERS_REQUEST, RETARGETAPP_UPDATE_SUCCESS } from 'constants/notificationType';
import { changeShopPage, metricType } from 'constants/metric';
import { DEFAULT_FILTER_CAMPAIGN, CAMPAIGNS_TYPES } from 'constants/campaign';
import pageNameSetter from 'decorator/pageNameSetter';
import { dayDiff, getLocalStorageObjectItem, isGooglePlatformSupported, sortCampaignsByPriority } from 'helpers';
import { getVerificationNotificationType } from 'helpers/getVerificationNotificationType';
import {
    isTrialPeriodSelector,
    getCurrentShopSelector,
    campaignErrorSelector,
    isCampaignStatisticsPending,
    isShopValidateRequesting,
    getShopErrorSelector,
    getBulkNotificationContentSelector,
    getBulkNotificationTypeSelector,
    getShopRecurringChargeSelector,
} from 'helpers/selectors';
import getInstallLink from 'modules/shopify/utils';
import Banners from 'components/Banners';
import Tabs from 'components/StatisticTabs';
import ModalAddStripe from 'components/ModalAddStripe';

import GeneralBannerNotificationList from 'components/GeneralBannerNotificationList';
import ProspectingCampaignRejected from 'components/Banners/ProspectingCampaignRejected';
import { ResumePlanBanner } from 'components/Banners/ResumeBannerBanner/ResumePlanBanner';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';


import UpdateShopifyPermissionsModal from 'components/ModalWindowCharge/ModalWindowUpdateShopifyPermissions';
import BulkNotification from 'components/BulkNotification';
import DashboardRecommendation from './DashboardRecommendation/DashboardRecommendation';
import ExtraAdSpendsWarning from './DashboardWarningNotifications/ExtraAdSpendsWarning';
import DashboardInfoNotifications from './DashboardInfoNotifications';
import Insights, { DATE_RANGES } from './Insights';
import CampaignDetails from './CampaignDetails';
import GoogleCampaignDetails from './CampaignDetails/GoogleCampaignDetails';
import {
    LearningPhaseBanner,
    CustomCampaignWrapper,
    ProspectingInviteWrapper,
    RetargetingInviteWrapper,
    SpecialOfferInviteWrapper,
    DomainVerificationBannerWrapper,
    CustomerInterviewBannerWrapper,
} from './Banners';
import { campaignCopyright } from './dashboard.constants';

import './Dashboard.css';
import {VerificationNotifications} from '../home/components/VerificationNotifications/VerificationNotifications';
import {ToDoList} from '../../components/ToDoList/ToDoList';
import AiBoostedSalesInvite from '../../components/Banners/AiBoostedSalesInvite/AiBoostedSalesInvite';
import {BoostSalesBanner} from '../../components/Banners/BoostSalesBanner/BoostSalesBanner';
import {requestOnboardings} from '../../actions/onboarding';

const UpdateShopifyPermissionsDayNumber = 14;

export class Dashboard extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isUpdateShopifyPermissionsModalVisible: false,
            isUpdateShopifyPermissionsChecked: false,
            isStripeButtonPending: false,
            showReplaceStripeCardModal: false,
            buttonLinkForUpdate: '',
            campaignType:
                getLocalStorageObjectItem('activeTab') === '1' ? CAMPAIGNS_TYPES.google : CAMPAIGNS_TYPES.facebook,
        };

        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
        this.checkUpdateShopifyPermissionsModal = this.checkUpdateShopifyPermissionsModal.bind(this);
        this.removeUpdateShopifyPermissionsModal = this.removeUpdateShopifyPermissionsModal.bind(this);
        this.checkReadAllOrdersBulkNotification = this.checkReadAllOrdersBulkNotification.bind(this);
        this.replaceStripeCard = this.replaceStripeCard.bind(this);
        this.rechargeUserStripeCard = this.rechargeUserStripeCard.bind(this);
        this.stripeUserChargeTryAgainHandler = this.stripeUserChargeTryAgainHandler.bind(this);
    }

    componentDidMount() {
        const {
            bulkNotificationContent,
            showBulkNotification,
            params: { shopId },
            checkShopForErrors,
            requestShopRecommendation,
            shopNotificationEntities,
            currentShopId,
            requestGoogleCampaigns,
            requestShopCampaigns,
            requestShopNotifications,
            requestOnboardings
        } = this.props;
        if (bulkNotificationContent) {
            showBulkNotification(bulkNotificationContent);
        }
        requestShopNotifications({id: shopId})
        requestGoogleCampaigns(currentShopId);
        requestShopCampaigns(currentShopId);
        checkShopForErrors({ shopId });
        requestOnboardings(shopId)
        if (!this.state.isUpdateShopifyPermissionsChecked) {
            this.checkUpdateShopifyPermissionsModal();
        }
        if (shopNotificationEntities?.length) {
            this.checkReadAllOrdersBulkNotification(shopId);
        }

        requestShopRecommendation(shopId);
    }

    componentDidUpdate() {
        if (this.props.campaignError) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    componentWillUnmount() {
        this.props.clearShopError();
    }

    static getShopParams(shop) {
        return shop.params ? shop.params : null;
    }

    campaignValidateFacebookHandler(params) {
        const {
            shopValidateRequesting,
            checkShopForErrors,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId, params).then((res) => {
                if (!(res instanceof Error)) {
                    checkShopForErrors({ shopId });
                }
            });
        }
    }

    checkUpdateShopifyPermissionsModal() {
        this.props.shopNotificationEntities?.forEach((entity) => {
            if (entity.type && entity.type === READ_ALL_ORDERS_REQUEST) {
                if (dayDiff(new Date(), new Date(entity.updated_at)) >= UpdateShopifyPermissionsDayNumber) {
                    this.setState({
                        ...this.state,
                        isUpdateShopifyPermissionsModalVisible: true,
                    });
                }
            }
        });
    }

    checkReadAllOrdersBulkNotification(shopId) {
        const { showBulkNotification, removeReadAllOrdersNotification } = this.props;
        this.props.shopNotificationEntities?.forEach((entity) => {
            if (entity.type && entity.type === RETARGETAPP_UPDATE_SUCCESS) {
                showBulkNotification('Adwisely update was successful', null, 7000);
                removeReadAllOrdersNotification({
                    id: shopId,
                    type: RETARGETAPP_UPDATE_SUCCESS,
                });
            }
        });
    }

    removeUpdateShopifyPermissionsModal() {
        this.setState({
            ...this.state,
            isUpdateShopifyPermissionsModalVisible: false,
            isUpdateShopifyPermissionsChecked: true,
        });
    }

    replaceStripeCard(shopId) {
        const { requestStripeBillingAmount} = this.props;
        requestStripeBillingAmount(shopId);
        this.setState({
            showReplaceStripeCardModal: true,
        });
    }

    rechargeUserStripeCard(shopId) {
        const {
            requestRechargeUseStripeCard,
            showBulkNotification,
            requestShopCampaigns,
            checkShopForErrors,
            trackEventStripeChargeFailed,
        } = this.props;
        this.setState({ isStripeButtonPending: true });
        return requestRechargeUseStripeCard(shopId)
            .then(
                () => {
                    if (this.props.stripeChargedSuccess) {
                        return requestShopCampaigns(shopId);
                    }
                    if (this.props.stripeChargedError) throw new Error();
                    return null;
                },
                () => {
                    throw new Error();
                },
            )
            .then(() => checkShopForErrors({ shopId }))
            .then(() => {
                this.setState({ isStripeButtonPending: false });
                if (this.props.stripeChargedSuccess) {
                    showBulkNotification('Charge successful. All ads are resumed.', null, 3000);
                }
                if (this.props.shopValidateError) throw new Error();
            })
            .catch(() => {
                trackEventStripeChargeFailed();
                this.setState({ isStripeButtonPending: false });
                showBulkNotification("We couldn't charge your card. Please, try again or replace card.", 'error', 5000);
                return true;
            });
    }

    stripeUserChargeTryAgainHandler(shopId) {
        this.rechargeUserStripeCard(shopId);
    }

    handleCampaignType = (type) => {
        this.setState({ campaignType: type });
    };

    render() {
        const {
            currentShop,
            currentShopId,
            requestShops,
            checkShopForErrors,
            shopError,
            campaignDetailsData,
            requestPauseCampaign,
            resumeCampaign,
            requestResumeGoogleCampaign,
            params: { shopId },
            shopRecurringCharge,
            showBulkNotification,
            shopValidateRequesting,
            updateShopNotifications,
            trackEventInitiateCampaignPause,
            isTrialPeriod,
            googleAuthorized,
            shopRecommendationText,
            campaignError,
            googleCampaigns,
            shopNotificationEntities,
            requestGoogleStandardAdPreview,
            requestGoogleRemarketingAdPreview,
            campaignStatisticsPending,
            facebookFilterCampaignId,
            googleFilterCampaignId,
            removeNotification,
            requestSendEventMetrics,
            onboardings,
            push,
            isGoogleAuthorized,
            googleLoginUrl
        } = this.props;
        const { showReplaceStripeCardModal, campaignType, isLoading } = this.state;
        let replaceStripeCardModal = null;
        let bulkNotification = null;
        // Dashboard Recommendation variables
        let dashboardRecommendation = null;
        const recommendedBudget = this.props.campaignDetailsData[0]?.recommended_budget / 100;
        const dailyBudgetValue = `
            ${recommendedBudget.toFixed(2)} ${this.props.campaignDetailsData[0]?.currency}
        `;
        const shopRecommendationTextVar = shopRecommendationText;
        // Dashboard Recommendation variables

        let updateShopifyPermissionsModal = null;

        let shopHost = '';
        let shopType = '';
        if (currentShop) {
            shopHost = currentShop.platform_id;
            shopType = currentShop.type;
        }

        if (this.state.isUpdateShopifyPermissionsModalVisible && !Object.keys(shopError)?.length) {
            updateShopifyPermissionsModal = (
                <UpdateShopifyPermissionsModal
                    isOpenModal={this.state.isUpdateShopifyPermissionsModalVisible}
                    cancelHandler={() => {
                        this.removeUpdateShopifyPermissionsModal();
                        updateShopNotifications({
                            id: shopId,
                            type: READ_ALL_ORDERS_REQUEST,
                        });
                    }}
                    updatePermissionsLink={getInstallLink(shopHost, shopType)}
                    updatePermissionsHandler={() => {
                        this.removeUpdateShopifyPermissionsModal();
                    }}
                />
            );
        }

        if (this.props.bulkNotificationContent) {
            bulkNotification = (
                <BulkNotification text={this.props.bulkNotificationContent} type={this.props.bulkNotificationType} />
            );
        }

        if (
            shopRecommendationText !== null &&
            shopRecommendationText !== '' &&
            !isTrialPeriod &&
            recommendedBudget !== 0
        ) {
            dashboardRecommendation = (
                <DashboardRecommendation>
                    <p>Daily Recommendation</p>
                    <div>{shopRecommendationTextVar.replace('<>', dailyBudgetValue)}</div>
                </DashboardRecommendation>
            );
        }

        if (showReplaceStripeCardModal) {
            const stripeModalHeader = 'Update billing info';
            const stripeModalBody = (
                <>
                    Replace the card you use to pay Adwisely fee &middot;{' '}
                    <a
                        className="modal-add-stripe__header-link"
                        href={'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/privacy-policy'}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </>
            );
            const userParams = Dashboard.getShopParams(currentShop);
            const stripeModalList = [
                'All campaigns will be automatically resumed',
                <>Your current card *{userParams ? userParams.stripe_card.last4 : 'xxxx'} will be removed</>,
            ];
            replaceStripeCardModal = (
                <ModalAddStripe
                    stripeModalHeader={stripeModalHeader}
                    stripeModalBody={stripeModalBody}
                    stripeModalList={stripeModalList}
                    shopId={currentShop.id}
                    isOpenModal={showReplaceStripeCardModal}
                    hideModalHandler={() => this.setState({ showReplaceStripeCardModal: false })}
                    additionalAction={() => this.rechargeUserStripeCard(shopId)}
                />
            );
        }
        let shopErrorBanner = null;
        if ((Object.keys(shopError)?.length && shopError !== 'shop-doesnt-have-recurring-charge') || campaignError) {
            shopErrorBanner = (
                <Banners
                    shopError={shopError}
                    changeTipsErrorText={this.changeTipsErrorText}
                    handleFormError={this.handleFormError}
                    validationPending={shopValidateRequesting}
                    validateShopSettings={() => checkShopForErrors({ shopId })}
                    campaignValidateFacebook={this.campaignValidateFacebookHandler}
                    shopRecurringCharge={shopRecurringCharge}
                    checkShopForErrors={() => checkShopForErrors({ shopId })}
                    shopId={shopId}
                    changePublishPage={this.changePublishPage}
                    currentShop={currentShop}
                    stripeUserChargeTryAgainHandler={() => this.stripeUserChargeTryAgainHandler(shopId)}
                    replaceStripeCard={() => this.replaceStripeCard(shopId)}
                    isStripeButtonPending={this.state.isStripeButtonPending}
                    requestShops={requestShops}
                    campaignError={campaignError}
                />
            );
        }

        const tabsTitles = [
            <div className="tabs-navigation__link-content-static--insights">
                <span>Facebook Ads</span>
            </div>,
        ];

        const tabsContent = [
            <Insights
                shopId={shopId}
                initialDateRange={DATE_RANGES.LAST14DAYS}
                type={CAMPAIGNS_TYPES.facebook}
                campaignType={campaignType}
            />,
        ];

        const isGoogleCampaignExist = googleCampaigns && googleCampaigns.length > 0;

        if (isGoogleCampaignExist || googleAuthorized) {
            isGooglePlatformSupported(currentShop.type) &&
                tabsTitles.push(
                    <div className="tabs-navigation__link-content-static--insights google-tab">
                        <span>Google Ads</span>
                    </div>,
                );
            tabsContent.push(
                <Insights
                    shopId={shopId}
                    initialDateRange={DATE_RANGES.LAST14DAYS}
                    type={CAMPAIGNS_TYPES.google}
                    campaignType={campaignType}
                />,
            );
        }

        tabsTitles.push(
            <div className="tabs-navigation__link-content-static--insights available-ad-types-tab">
                <Link to={`/${shopId}/home`}>
                   <span>Available ad types</span>
                </Link>
            </div>,
        );

        const facebookCampaigns =
            facebookFilterCampaignId !== DEFAULT_FILTER_CAMPAIGN
                ? campaignDetailsData?.filter((item) => item?.id?.toString() === facebookFilterCampaignId)
                : campaignDetailsData;
        const filteredGoogleCampaigns =
            googleFilterCampaignId !== DEFAULT_FILTER_CAMPAIGN
                ? googleCampaigns?.filter((item) => item?.id?.toString() === googleFilterCampaignId)
                : googleCampaigns;

        return (
            <>
                <div>
                    <ToDoList
                        shopId={currentShopId}
                        push={push}
                        onboardings={onboardings}
                        isGoogleAuthorized={isGoogleAuthorized}
                        googleLoginUrl={googleLoginUrl} />
                    <div className="dashboard-page">
                        <AiBoostedSalesInvite setIsLoading={(state) => {
                                this.setState({isLoading: state})
                            }
                        }/>
                        <VerificationNotifications
                            type={getVerificationNotificationType(shopNotificationEntities)}
                            setIsLoading={(state) => {
                                this.setState({isLoading: state})
                            }}
                        />
                        <CustomerInterviewBannerWrapper shopId={shopId} isActive={false} />
                        <ExtraAdSpendsWarning shopNotifications={shopNotificationEntities} />
                        <ProspectingCampaignRejected campaignDetails={campaignDetailsData} />
                        <DomainVerificationBannerWrapper campaignType={campaignType} />
                        <SpecialOfferInviteWrapper shopId={shopId} campaignType={campaignType} isActive />
                        <CustomCampaignWrapper shopNotificationEntities={shopNotificationEntities} />
                        <DashboardInfoNotifications
                            shop={currentShop}
                            shopNotificationEntities={shopNotificationEntities}
                            removeNotification={removeNotification}
                        />
                        <ResumePlanBanner
                            isVisible={shopError.errorToken === 'shop-is-frozen-due-to-subscription-canceled'}
                            shopId={shopId}
                            setIsLoadingTrue={() => {
                                this.setState({isLoading: true})
                            }}
                            onResumeBtnClick={() => {
                                checkShopForErrors({ shopId }).then(() => {
                                    this.setState({isLoading: false})
                                })
                            }}
                        />
                        <LearningPhaseBanner />
                        <GeneralBannerNotificationList
                            shopId={shopId}
                            validateShopSettings={() => checkShopForErrors({ shopId })}
                            campaignValidateFacebook={this.campaignValidateFacebookHandler}
                            rechargeUserStripeCard={() => this.rechargeUserStripeCard(shopId)}
                            replaceStripeCard={() => this.replaceStripeCard(shopId)}
                        />

                        {shopErrorBanner}

                        {campaignType === CAMPAIGNS_TYPES.facebook && <BoostSalesBanner notifications={shopNotificationEntities} />}

                        <section className="dashboard-page__section">
                            <Tabs
                                tabsTitles={tabsTitles}
                                tabsContent={tabsContent}
                                getCampaignType={this.handleCampaignType}
                                currentShopId={currentShopId}
                                disableTab={campaignStatisticsPending}
                            />

                            <div>{dashboardRecommendation}</div>
                        </section>
                        {campaignType === CAMPAIGNS_TYPES.facebook &&
                            facebookCampaigns?.map((campaign, i) => (
                                <section className="dashboard-page__section" key={campaign.id}>
                                    <CampaignDetails
                                        title={campaignCopyright[campaign.type].title}
                                        titleTooltipContent={campaignCopyright[campaign.type].titleTooltipContent}
                                        titleTooltipLink={campaignCopyright[campaign.type].titleTooltipLink}
                                        campaignType={campaign.type}
                                        shop={currentShop}
                                        shopError={shopError}
                                        shopValidateRequesting={shopValidateRequesting}
                                        campaignDetailsData={campaign}
                                        trackEventInitiateCampaignPause={trackEventInitiateCampaignPause}
                                        resumeCampaign={(campaignId) => {
                                            resumeCampaign({ id: campaignId }, campaign.type).then((res) => {
                                                if (!(res instanceof Error)) {
                                                    showBulkNotification('Campaign resumed.');
                                                }
                                            });
                                        }}
                                        requestPauseCampaign={requestPauseCampaign}
                                        campaignPausePending={campaign.campaignPausePending}
                                        campaignResumePending={campaign.campaignResumePending}
                                        type={campaignType}
                                        isTrialPeriod={isTrialPeriod}
                                        requestSendEventMetrics={requestSendEventMetrics}
                                    />

                                    {i === 0 ? (
                                        <section className="dashboard-page__section">
                                            <ProspectingInviteWrapper shopId={shopId} />
                                            <RetargetingInviteWrapper shopId={shopId} />
                                        </section>
                                    ) : null}
                                </section>
                            ))}

                        {campaignType === CAMPAIGNS_TYPES.google &&
                            filteredGoogleCampaigns?.map((campaign) => (
                                <section className="dashboard-page__section" key={campaign.id}>
                                    <GoogleCampaignDetails
                                        titleTooltipContent={campaignCopyright.google.titleTooltipContent}
                                        googleCampaignData={campaign}
                                        shop={currentShop}
                                        shopValidateRequesting={shopValidateRequesting}
                                        resumeCampaign={(currentShopId, campaignId) => {
                                            requestResumeGoogleCampaign({ shopId: currentShopId, campaignId }).then((res) => {
                                                if (!(res instanceof Error)) {
                                                    showBulkNotification('Campaign resumed.');
                                                }
                                            });
                                        }}
                                        requestGoogleStandardAdPreview={(currentShopId, campaignId, adId) => {
                                            requestGoogleStandardAdPreview({
                                                shopId: currentShopId,
                                                campaignId,
                                                adId,
                                            });
                                        }}
                                        requestGoogleRemarketingAdPreview={(currentShopId, campaignId, adId) => {
                                            requestGoogleRemarketingAdPreview({
                                                shopId: currentShopId,
                                                campaignId,
                                                adId,
                                            });
                                        }}
                                        standardAd={campaign.ads && campaign.ads?.find((ad) => ad.type?.includes('Standard'))}
                                        remarketingAd={
                                            campaign.ads && campaign.ads?.find((ad) => ad.type?.includes('Remarketing'))
                                        }
                                        campaignId={campaign.id}
                                        shopId={currentShopId}
                                        campaignType={campaignType}
                                        campaignPausePending={campaign.campaignPausePending}
                                        campaignResumePending={campaign.campaignResumePending}
                                        requestSendEventMetrics={requestSendEventMetrics}
                                    />
                                </section>
                            ))}

                        {bulkNotification}
                        {updateShopifyPermissionsModal}
                        {replaceStripeCardModal}
                    </div>
                </div>
                <LoaderFullScreen
                    isLoaderShowed={isLoading}
                >
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
            </>

        );
    }
}

Dashboard.defaultProps = {
    shopError: null,
    bulkNotificationContent: null,
    bulkNotificationType: null,
    shopNotificationEntities: [],
};

Dashboard.propTypes = {
    campaignDetailsData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            campaignPausePending: PropTypes.bool,
            campaignResumePending: PropTypes.bool,
            client_status: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired,
            products_in_ads: PropTypes.number,
            settings: PropTypes.shape({
                daily_budget: PropTypes.number,
            }),
            shop: PropTypes.number.isRequired,
        }),
    ).isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    shopValidateRequesting: PropTypes.bool.isRequired,
    currentShop: PropTypes.shape({
        app_uninstalled_at: PropTypes.string,
        created_at: PropTypes.string,
        credentials: PropTypes.string,
        domain: PropTypes.string,
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        owner_email: PropTypes.string,
        owner_name: PropTypes.string,
        platform_id: PropTypes.string,
        review_note: PropTypes.string,
        review_status: PropTypes.string,
        type: PropTypes.string,
        updated_at: PropTypes.string,
    }).isRequired,
    shopNotificationEntities: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.string,
            deleted_at: PropTypes.string,
            expiry_at: PropTypes.any,
            id: PropTypes.number,
            shop: PropTypes.number,
            type: PropTypes.string,
            updated_at: PropTypes.string,
        }),
    ),
    shopRecurringCharge: PropTypes.shape({
        entity: PropTypes.shape({
            confirmation_url: PropTypes.string,
        }),
        error: PropTypes.instanceOf(Error),
        failure: PropTypes.bool,
        pending: PropTypes.bool,
        success: PropTypes.bool,
        timestamp: PropTypes.number,
        updateUrlPending: PropTypes.bool,
    }).isRequired,
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    checkShopForErrors: PropTypes.func.isRequired,
    resumeCampaign: PropTypes.func.isRequired,
    clearShopError: PropTypes.func.isRequired,
    requestShops: PropTypes.func.isRequired,
    showBulkNotification: PropTypes.func.isRequired,
    campaignValidateFacebook: PropTypes.func.isRequired,
    isTrialPeriod: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    campaignDetailsData: sortCampaignsByPriority(state.campaignDetails?.campaignDetailsData),
    facebookFilterCampaignId: state.campaignDetails?.facebookFilterCampaignId,
    googleFilterCampaignId: state.campaignDetails?.googleFilterCampaignId,
    shopError: getShopErrorSelector(state),
    shopRequestFailure: state.shops?.shopRequestFailure,
    shopValidateRequesting: isShopValidateRequesting(state),
    shopValidateError: state.shopError?.shopValidateError,
    currentShop: getCurrentShopSelector(state.shops, state.shops?.currentShopId),
    currentShopId: state.shops?.currentShopId,
    shopRecurringCharge: getShopRecurringChargeSelector(state),
    bulkNotificationContent: getBulkNotificationContentSelector(state),
    bulkNotificationType: getBulkNotificationTypeSelector(state),
    shopNotificationEntities: state.shopNotifications?.entity,
    googleAuthorized: state.google?.login?.authorized,
    stripeChargedSuccess: state.stripeCharge?.stripeChargedSuccess,
    stripeChargedError: state.stripeCharge?.stripeChargedError,
    isTrialPeriod: isTrialPeriodSelector(state.shopBillingPeriod?.freeType, state.shopBillingPeriod?.trialDaysLeft),
    shopRecommendationText: state.shops?.shopRecommendation,
    campaignError: campaignErrorSelector(state.campaignDetails?.campaignDetailsData),
    googleCampaigns: state.googleCampaigns?.googleCampaignsData,
    campaignStatisticsPending: isCampaignStatisticsPending(state),
    shopSubscription: state.shopSubscription,
    onboardings: state.onboarding.onboardingsState.onboardings,
    googleLoginUrl: state.google?.login?.authUrl,
    isGoogleAuthorized:state.google?.login?.authorized,
});

const mapDispatchToProps = {
    push,
    checkShopForErrors: validateShop,
    campaignValidateFacebook: requestCampaignValidateFacebook,
    resumeCampaign: (params, campaignType) => (dispatch) =>
        dispatch(requestResumeCampaign(params)).then((res) => {
            dispatch(trackEventCampaignResumed(campaignType));
            return res;
        }),
    requestGoogleStandardAdPreview,
    requestGoogleRemarketingAdPreview,
    requestResumeGoogleCampaign,
    clearShopError,
    requestShopRecommendation,
    requestShops,
    showBulkNotification: displayBulkNotification,
    updateShopNotifications: requestUpdateShopNotification,
    removeReadAllOrdersNotification: requestShopNotificationsDeleteReadAllOrders,
    requestRechargeUseStripeCard,
    requestShopCampaigns,
    requestGoogleCampaigns,
    trackEventInitiateCampaignPause,
    requestStripeBillingAmount,
    requestSendEventMetrics,
    requestPauseCampaign,
    removeNotification: requestDeleteShopNotification,
    requestShopNotifications,
    requestOnboardings
};
const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default pageNameSetter(changeShopPage.dashboard)(DashboardContainer);
