import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'components/Modal/Modal';
import IntercomChatCaller from 'components/IntercomChatCaller';

import './ModalAddEmailToFB.css';

class ModalAddEmailToFB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
        };
        this.hideModal = this.hideModal.bind(this);
    }

    hideModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { isOpenModal } = this.props;

        return (
            <Modal className="modal-special__dialog" isOpen={isOpenModal && this.state.showModal}>
                <button className="modal-special__close-button" onClick={this.hideModal} />
                <ModalHeader className="modal-special__header">
                    <p className="modal-special__title">Add an e-mail to your Facebook profile</p>
                </ModalHeader>

                <ModalBody className="modal-special__body">
                    <p className="modal-special-body__content">
                        It appears that your Facebook profile doesn't have an e-mail address that's why our system can't
                        authorise you. Add an email to{' '}
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            your Facebook profile
                        </a>{' '}
                        to continue setting up your campaign ({' '}
                        <a
                            className="modal-special-body__underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/help/162801153783275?helpref=faq_content"
                        >
                            detailed instruction
                        </a>
                        ).
                    </p>

                    <p className="modal-special-body__content modal-special-body__footnote">
                        <IntercomChatCaller className="modal-special-body__underline" content="Message us" /> if you
                        have any questions or need help.
                    </p>
                </ModalBody>

                <ModalFooter className="modal-special__footer">
                    <a
                        href="https://www.facebook.com/settings?tab=account&section=email"
                        target="_blank"
                        className="modal-special__button modal-special__button_primary"
                        rel="noopener noreferrer"
                    >
                        Add email on Facebook
                    </a>
                </ModalFooter>
            </Modal>
        );
    }
}

ModalAddEmailToFB.defaultProps = {
    isOpenModal: false,
};

ModalAddEmailToFB.propTypes = {
    isOpenModal: PropTypes.bool,
};

export default ModalAddEmailToFB;
