import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { goBack } from 'react-router-redux';
import * as shopCampaignsSettings from 'actions/shopCampaignsSettings';
import * as pagesActions from 'actions/facebook/pages';
import { displayBulkNotification } from 'actions/notification';
import { requestShopBillingInfo, requestSendEventMetrics, requestShopSubscription } from 'actions/shop';
import { requestStripeDeleteSource } from 'actions/stripe';
import { requestOnboardings } from 'actions/onboarding';

import {
    getCurrentShopSelector,
    isStaffPlatformPlanSelector,
    getBulkNotificationContentSelector,
} from 'helpers/selectors';
import { BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';
import { metricType } from 'constants/metric';
import { SUBSCRIPTION } from 'constants/userBillingType';

import BulkNotification from 'components/BulkNotification';
import Button from 'components/Button';
import { ArrowLeft } from 'components/Icons';
import ModalAddStripe from 'components/ModalAddStripe';
import IntercomChatCaller from 'components/IntercomChatCaller';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import PaymentCardModal from '../PaymentCardModal';
import FacebookPageSettings from '../FacebookPageSettings';
import BillingInfo from '../BillingInfo';
import CardInfo from '../CardInfo';
import ChangeEmail from '../ChangeEmail';

import './SettingsPage.css';
import {fetchUserAccount} from '../../../helpers/fetchUserAccount';

let timer = null;

const publishedPages = (pages) => {
    if (pages.length) {
        return pages.filter((page) => page.is_published);
    }

    return [];
}

export class SettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFacebookSettingsPending: false,
            showReplaceStripeCardModal: false,
            showAddStripeCardModal: false,
            showDeleteStripeCardModal: false,
            showDeleteStripeCardErrorModal: false,
            billingInfoYear: new Date().getFullYear(),
            isLoading: false,
            userEmail: ''
        };

        this.onSubmitPages = this.onSubmitPages.bind(this);
        this.onCreateNewPage = this.onCreateNewPage.bind(this);
        this.setFacebookSettingsPending = this.setFacebookSettingsPending.bind(this);
        this.updateBillingInfo = this.updateBillingInfo.bind(this);
        this.setUserEmail = this.setUserEmail.bind(this);
        this.setIsLoading = this.setIsLoading.bind(this);
    }

    componentDidMount() {
        const {
            pages,
            getFbPages,
            getBillingInfo,
            currentShop,
            requestShopSubscription,
            currentShopId,
            requestOnboardings } = this.props;
        if (!pages.entities.length) getFbPages({}, false);
        getBillingInfo(currentShop.id, { billingYear: this.state.billingInfoYear });
        requestOnboardings(currentShopId);

        fetchUserAccount(this.setIsLoading, this.setUserEmail);

        const isSubscriptionBusinessModel = currentShop && currentShop?.business_model === SUBSCRIPTION;
        isSubscriptionBusinessModel && requestShopSubscription(currentShop.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { pages, getFbPages } = this.props;

        const currentPagesNumber = publishedPages(pages.entities).length;
        const updatedPagesNumber = publishedPages(nextProps.pages.entities).length;

        if (timer && currentPagesNumber === updatedPagesNumber) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                getFbPages({}, false);
            }, 5000);
        } else if (timer) timer = clearTimeout(timer);
    }

    componentWillUnmount() {
        if (timer) clearTimeout(timer);
    }

    onSubmitPages(pageId) {
        const {
            params: { shopId },
            connectFbPage,
            setCampaignSettings,
            displayBulkNotification,
            requestSendEventMetrics,
        } = this.props;
        const metricProps = {
            shop_id: shopId,
            type: metricType.facebookPageChanged,
            props: {
                changed_to: pageId,
                successfully: false,
            },
        };

        this.setFacebookSettingsPending(true);
        connectFbPage({ id: pageId, shopId }).then((result) => {
            if (!(result instanceof Error)) {
                setCampaignSettings(shopId, { pageId }).then((data) => {
                    this.setFacebookSettingsPending(false);
                    if (!(data instanceof Error)) {
                        displayBulkNotification('Facebook page changed.');
                        metricProps.props.successfully = true;
                    }
                    return data;
                });
                requestSendEventMetrics(metricProps);
            } else {
                this.setFacebookSettingsPending(false);
            }
        });
    }

    deleteStripeSource = () => {
        const {
            params: { shopId },
            requestStripeDeleteSource,
        } = this.props;

        requestStripeDeleteSource(shopId)
            .then((res) => {
                this.setState({ showDeleteStripeCardModal: false });
                if (!(res instanceof Error)) {
                    this.updateBillingInfo();
                    displayBulkNotification('Card deleted successfully.');
                } else {
                    this.setState({ showDeleteStripeCardErrorModal: true });
                }
                return res;
            })
    }

    onCreateNewPage() {
        const { getFbPages } = this.props;

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            getFbPages({}, false);
        }, 5000);
    }

    setFacebookSettingsPending(isPending) {
        this.setState({ isFacebookSettingsPending: isPending });
    }

    replaceStripeCard = () => {
        this.setState({
            showReplaceStripeCardModal: true,
        });
    }

    addStripeCard = () => {
        this.setState({
            showAddStripeCardModal: true,
        });
    }

    deleteStripeCard = () => {
        this.setState({
            showDeleteStripeCardModal: true,
        });
    }

    updateBillingInfo(year) {
        const { getBillingInfo, currentShop } = this.props;

        if (year) {
            this.setState({ billingInfoYear: year });
            getBillingInfo(currentShop.id, { billingYear: year });
        } else {
            getBillingInfo(currentShop.id, { billingYear: this.state.billingInfoYear });
        }
    }

    setIsLoading(isLoading) {
        this.setState({
            isLoading
        })
    }

    setUserEmail(email) {
        this.setState({
            userEmail: email
        })
    }

    render() {
        const {
            pages,
            shopsCampaignsSettings,
            params: { shopId },
            isStaffPlatformPlan,
            shopType,
            currentShop,
            stripeCard,
            billingInfoIsPending,
            isOnboardingDone,
            onboardingsRequesting,
            goBack,
        } = this.props;
        const {
            showReplaceStripeCardModal,
            showAddStripeCardModal,
            showDeleteStripeCardModal,
            showDeleteStripeCardErrorModal,
            billingInfoYear,
            isLoading,
            userEmail
        } = this.state;

        // TODO: remove all logic from render method
        let selectedPage = '';
        let replaceStripeCardModal = null;
        let addStripeCardModal = null;
        let deleteStripeCardModal = null;
        let deleteStripeCardErrorModal = null;
        if (shopsCampaignsSettings.entity) {
            selectedPage = shopsCampaignsSettings.entity.fb_page_id;
        }

        let bulkNotification = null;
        if (this.props.bulkNotificationContent) {
            bulkNotification = <BulkNotification text={this.props.bulkNotificationContent} />;
        }

        deleteStripeCardModal = (
            <PaymentCardModal
                isOpen={showDeleteStripeCardModal}
                hideModal={() => this.setState({ showDeleteStripeCardModal: false })}
                modalTitle="Remove your card from Adwisely?"
                secondaryButtonName="Cancel"
                buttonName="Delete"
                buttonClickHandler={this.deleteStripeSource}
            >
                <>
                    <p>Once you do it, all your Adwisely-powered ads will be stopped.</p>
                    <p>You can resume them only when you connect a new payment method.</p>
                </>
            </PaymentCardModal>
        );

        deleteStripeCardErrorModal = (
            <PaymentCardModal
                isOpen={showDeleteStripeCardErrorModal}
                modalTitle="Card not removed"
                buttonName="Try again"
                hideModal={() => this.setState({ showDeleteStripeCardErrorModal: false })}
                buttonClickHandler={() => this.setState({ showDeleteStripeCardErrorModal: false })}
            >
                <>
                    <p>We couldn't remove your card due to a temporary payment service error.</p>
                    <p>Please, try again in 5 minutes or {' '}
                        <IntercomChatCaller
                            className="modal-common__link"
                            content="contact us"
                        />.
                    </p>
                </>
            </PaymentCardModal>
        );

        addStripeCardModal = (
            <ModalAddStripe
                shopId={shopId}
                isOpenModal={showAddStripeCardModal}
                hideModalHandler={() => this.setState({ showAddStripeCardModal: false })}
                successBulkMessage="Card add successfully"
            />
        );

        if (showReplaceStripeCardModal) {
            const stripeModalHeader = 'Update billing info';
            const stripeModalBody = (
                <>
                    Replace the card you use to pay Adwisely fee &middot;{' '}
                    <a
                        className="modal-add-stripe__header-link"
                        href={
                            'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/' +
                            'privacy-policy'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </>
            );
            const stripeModalList = [<>Your current card *{stripeCard ? stripeCard.last4 : 'xxxx'} will be removed</>];
            replaceStripeCardModal = (
                <ModalAddStripe
                    stripeModalHeader={stripeModalHeader}
                    stripeModalBody={stripeModalBody}
                    stripeModalList={stripeModalList}
                    shopId={currentShop.id}
                    isOpenModal={showReplaceStripeCardModal}
                    hideModalHandler={() => this.setState({ showReplaceStripeCardModal: false })}
                    successBulkMessage="Card changed successfully"
                    additionalAction={this.updateBillingInfo}
                    replaceCard
                />
            );
        }

        return (
            <>
                <div className="settings-page">
                    <div className="settings-page__wrapper">
                        <div className="settings-page__button-container">
                            <Button onClick={() => {
                                goBack();
                            }}>
                                <ArrowLeft /> Back
                            </Button>
                        </div>
                        <ChangeEmail userEmail={userEmail} />
                        {(isOnboardingDone) &&
                        <>
                            <FacebookPageSettings
                                selectedPageId={selectedPage}
                                isSubmitPending={this.state.isFacebookSettingsPending}
                                isCancelPending={this.state.isFacebookSettingsPending}
                                onSubmit={this.onSubmitPages}
                                onCreateNewPage={this.onCreateNewPage}
                                isPagePending={pages.fbPagesRequesting && !timer}
                                pages={publishedPages(pages.entities)}
                            />
                            {(shopType === BIG_COMMERCE || shopType === WOO_COMMERCE) && (
                                <CardInfo
                                    stripeCard={stripeCard}
                                    showModal={this.replaceStripeCard}
                                    showDeleteStripeCardModal={this.deleteStripeCard}
                                    showAddStripeCardModal={this.addStripeCard}
                                    pending={billingInfoIsPending}
                                />
                            )}
                            {!isStaffPlatformPlan && (
                                <BillingInfo billingInfoYear={billingInfoYear} updateBillingInfo={this.updateBillingInfo} />
                            )}
                            {bulkNotification}
                            {replaceStripeCardModal}
                            {addStripeCardModal}
                            {deleteStripeCardModal}
                            {deleteStripeCardErrorModal}
                        </>
                        }

                    </div>
                </div>

                <LoaderFullScreen
                    isLoaderShowed={onboardingsRequesting || isLoading}
                >
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
            </>

        );
    }
}

SettingsPage.defaultProps = {
    bulkNotificationContent: null,
    isStaffPlatformPlan: false,
    pages: null,
    stripeCard: null,
};

SettingsPage.propTypes = {
    bulkNotificationContent: PropTypes.string,
    connectFbPage: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    getBillingInfo: PropTypes.func.isRequired,
    getFbPages: PropTypes.func.isRequired,
    isStaffPlatformPlan: PropTypes.bool,
    pages: PropTypes.shape({
        entities: PropTypes.arrayOf(
            PropTypes.shape({
                is_published: PropTypes.bool
            }),
        ),
    }),
    requestSendEventMetrics: PropTypes.func.isRequired,
    setCampaignSettings: PropTypes.func.isRequired,
    shopType: PropTypes.string.isRequired,
    shopsCampaignsSettings: PropTypes.shape({
        entity: PropTypes.shape({
            fb_page_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
    }).isRequired,
    stripeCard: PropTypes.shape({
        last4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
};

const mapStateToProps = (state) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);
    const onboardingsState = state.onboarding.onboardingsState;
    const campaignDetailsData = state.campaignDetails.campaignDetailsData;
    const isOnboardingDone = onboardingsState.onboardings?.filter((onboarding) => onboarding.state === 'done').length > 0;
    return {
        currentShop,
        currentShopId: state.shops.currentShopId,
        pages: state.facebook.pages,
        shopsCampaignsSettings: state.shopsCampaignsSettings,
        bulkNotificationContent: getBulkNotificationContentSelector(state),
        shopType: currentShop?.type,
        isStaffPlatformPlan: isStaffPlatformPlanSelector(currentShop),
        stripeCard: state.shopBillingInfo.stripe_card,
        billingInfoIsPending: state?.shopBillingInfo?.billingInfoState && state?.shopBillingInfo?.billingInfoState === "Pending",
        isOnboardingDone: isOnboardingDone,
        campaignDetailsData: campaignDetailsData,
        onboardingsRequesting: onboardingsState.onboardingsRequesting
    };
};

const mapDispatchToProps = {
    displayBulkNotification,
    requestSendEventMetrics,
    requestShopSubscription,
    requestStripeDeleteSource,
    getBillingInfo: requestShopBillingInfo,
    getFbPages: pagesActions.requestFBPages,
    connectFbPage: pagesActions.requestFBPageConnect,
    setCampaignSettings: shopCampaignsSettings.setShopCampaignSettings,
    requestOnboardings,
    goBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
