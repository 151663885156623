import {
    SHOP_BILLING_PERIOD_REQUEST,
    SHOP_BILLING_PERIOD_SUCCESS,
    SHOP_BILLING_PERIOD_FAILURE,
    SHOP_BILLING_SET_SHOP_ID,
} from 'actions/shop';
import { TRIAL_FREE_TYPE, BONUS_FREE_TYPE } from 'constants/billingPeriod';

const initialState = {
    shopId: null,
    trialDaysLeft: 0,
    freeType: '',
    currentBillingPeriodDaysLeft: 0,
    extendTrialLabel: false,
};

/**
 *
 * @param startDate {sring} - timestamp in ms"
 * @param endDate {sring} - timestamp in ms"
 */
const daysNumberBeetwenDates = (startDate, endDate) => {
    const milisecondsInDay = 1000 * 60 * 60 * 24;
    const delta = (new Date(+endDate) - new Date(+startDate)) / milisecondsInDay;
    return +Math.ceil(delta);
};

const FREE_TYPE = 'free_type';

/**
 *
 * Returns current date(UTC+0) timestamp
 */
const currentDateTimestamp = () => new Date(new Date().toUTCString()).getTime();

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SHOP_BILLING_SET_SHOP_ID:
            return {
                ...state,
                shopId: payload.shopId,
            };
        case SHOP_BILLING_PERIOD_REQUEST:
            return {
                ...state,
                freeType: '',
                trialDaysLeft: 0,
            };
        case SHOP_BILLING_PERIOD_SUCCESS:
            if (payload.shop_has_no_billing_period) {
                return {
                    ...state,
                    freeType: TRIAL_FREE_TYPE,
                    trialDaysLeft: payload.trial_days_left,
                };
            }
            const currentBillingPeriodDaysLeft = daysNumberBeetwenDates(currentDateTimestamp(), payload.end_at * 1000) + 1;
            const extendTrialLabel = payload.extend_trial;
            if (FREE_TYPE in payload) {
                if (payload[FREE_TYPE] === TRIAL_FREE_TYPE) {
                    return {
                        ...state,
                        extendTrialLabel,
                        freeType: TRIAL_FREE_TYPE,
                        trialDaysLeft: currentBillingPeriodDaysLeft,
                    };
                } else if (payload[FREE_TYPE] === BONUS_FREE_TYPE) {
                    return {
                        ...state,
                        extendTrialLabel,
                        freeType: BONUS_FREE_TYPE,
                        trialDaysLeft: currentBillingPeriodDaysLeft,
                    };
                }
            }
            return {
                ...state,
                freeType: payload.free_type,
                extendTrialLabel,
                trialDaysLeft: currentBillingPeriodDaysLeft,
            };
        case SHOP_BILLING_PERIOD_FAILURE:
            return {
                ...state,
                trialDaysLeft: 0,
                currentBillingPeriodDaysLeft: 0,
            };
        default:
            return state;
    }
};
