import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Card.css';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    tag: PropTypes.string,
    disablePadding: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    tag: 'section',
    disablePadding: false,
    className: null,
};

const Card = ({ children, tag: Tag, disablePadding, className }) => (
    <Tag className={classNames('card-block', className, { 'card-block_no-pad': disablePadding })}>{children}</Tag>
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
