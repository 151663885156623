import * as account from 'actions/account';

import { hideLoader } from 'actions/loader';

export default (store) => (next) => (action) => {
    const { dispatch } = store;

    switch (action.type) {
        case account.ACCOUNT_INFO_REQUEST_SUCCESS:
            next(action);
            dispatch(hideLoader());
            break;

        case account.ACCOUNT_INFO_REQUEST_FAILURE:
            next(action);
            break;

        default:
            next(action);
            break;
    }
};
