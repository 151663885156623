import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Button from 'components/Button';
import IntercomChatCaller from 'components/IntercomChatCaller';
import ContextNotification from 'components/ContextNotification';
import {
    isQualified,
    isQualifiedByCriterias,
    SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT,
    SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN,
    SHOP_QUALIFICATION_CRITERIA_ORDERS,
} from 'helpers/shopQualifacation';

import './NotQualified.css';

class NotQualified extends Component {
    componentDidMount() {
        const {
            shops,
            params: { shopId },
            defineUserAsNotQualified,
            shopQualificationCriterias,
        } = this.props;

        const [currentShop] = shops.entities.filter((shop) => +shop.id === +shopId);

        if (currentShop && isQualified(shopQualificationCriterias)) {
            replace(`/${shopId}/`);
        } else {
            defineUserAsNotQualified();
        }
    }

    getContentItems = () => {
        const { shopQualificationCriterias } = this.props;

        const contentItems = [];
        if (!isQualifiedByCriterias(shopQualificationCriterias, SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN)) {
            contentItems.push('Have completed free trial on Shopify');
        }
        if (!isQualifiedByCriterias(shopQualificationCriterias, SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT)) {
            contentItems.push('Are available to all audiences and have at least 1 product to offer');
        }
        if (!isQualifiedByCriterias(shopQualificationCriterias, SHOP_QUALIFICATION_CRITERIA_ORDERS)) {
            contentItems.push('Had at least 30 orders in the past 30 days');
        }

        return contentItems;
    };

    render() {
        return (
            <div className="not-qualified__wrapper">
                <h1 className="fb-page__title">Pre-Launch Check-Up</h1>
                <section className="not-qualified__section">
                    <p className="not-qualified-not-ready__article">
                        Your store may not be ready yet. Adwisely works best for the stores which:
                    </p>
                    <ContextNotification contentItems={this.getContentItems()} />
                    <p className="not-qualified-contact-us__article">
                        Not there yet? It’s okay, we can wait.{' '}
                        <a
                            href={
                                'https://help.adwisely.com/prepare-to-use-retargetapp/' +
                                'make-sure-you-are-retargetapp-ready'
                            }
                            target="_blank"
                            className="billing-info-page__link settings-page__link"
                            rel="noopener noreferrer"
                        >
                            Learn more
                        </a>{' '}
                        or contact us if you got questions.{' '}
                    </p>

                    <IntercomChatCaller
                        content={
                            <Button className="not-qualified_contact_us_button" color="primary">
                                Contact us
                            </Button>
                        }
                        className="not-qualified_contact_us_button_text"
                    />
                </section>
            </div>
        );
    }
}

NotQualified.propTypes = {
    shopQualificationCriterias: PropTypes.shape({
        is_qualified: PropTypes.bool.isRequired,
        reasons: PropTypes.array.isRequired,
    }).isRequired,
    shops: PropTypes.shape({
        entities: PropTypes.array,
    }).isRequired,
};

const mapStateToProps = ({ shops, shopQualification }) => ({
    shops,
    shopQualificationCriterias: shopQualification?.criterias,
});

export default connect(mapStateToProps)(NotQualified);
