import React from 'react';

import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { requestSendEventMetrics } from 'actions/shop';
import { requestOnboardings } from 'actions/onboarding';

import Button from 'components/Button';

const CallBookedTiktok = ({replace, requestOnboardings, shopId}) => (
        <div className='book-a-call'>
            <h2 className="book-a-call__title">
                <span className="phone-icon" role="img" aria-label="checkmark">✅</span>
                You’ve booked a call. What’s next?
            </h2>
            <p className="book-a-call__text">
                During the call you have booked, our Support specialist will help you set up the campaign you requested.
                <br />
                <br />
                In the meantime, you can go back to campaign selection screen and launch ads on another great platform like Facebook or Google.
            </p>
            <Button
                color="transparent"
                className="book-a-call__btn-transparent"
                onClick={() => {
                    requestOnboardings(shopId);
                    replace(`/${shopId}/home`);
                }}
            >
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.316895 4.41064C0.316895 4.24561 0.393066 4.08057 0.52002 3.95996L3.77002 0.709961C3.90967
                            0.583008 4.05566 0.519531 4.20801 0.519531C4.55078 0.519531 4.80469 0.76709 4.80469 1.10352C4.80469 1.2749
                            4.73486 1.4209 4.62695 1.53516L3.69385 2.48096L2.15137 3.88379L3.32568 3.80762H9.11475C9.48291 3.80762 9.73047
                            4.05518 9.73047 4.41064C9.73047 4.76611 9.48291 5.02002 9.11475 5.02002H3.32568L2.14502 4.9375L3.69385 6.34668L4.62695
                            7.28613C4.73486 7.40039 4.80469 7.54639 4.80469 7.71777C4.80469 8.0542 4.55078 8.30176 4.20801 8.30176C4.05566 8.30176
                            3.90967 8.23828 3.77637 8.11133L0.52002 4.86133C0.393066 4.74072 0.316895 4.57568 0.316895 4.41064Z"
                          fill="currentColor"/>
                </svg>
                <span>
                    Go to Campaign selection
                </span>
            </Button>
        </div>
    )

const mapStateToProps = ({ shops } ) => (
    {
        shopId: shops.currentShopId,
    }
)

const mapDispatchToProps = {
    replace,
    requestSendEventMetrics,
    requestOnboardings
}

export default connect(mapStateToProps, mapDispatchToProps)(CallBookedTiktok)