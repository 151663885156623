import React, { useEffect, useState } from 'react';
import { changeOnboardingState } from 'modules/home/utils';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { googleStatePolling } from 'modules/onboarding/google/helpers/googleStatePolling';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';

export const useProductFeed = (shopId, redirect, setGoogleOnboardingState) => {
    const [productFeed, setProductFeed] = useState([]);
    const [choosedProductFeedItem, setChoosedProductFeedItem] = useState()

    const onProductItemClick = (id) => {
        const item = productFeed.find(item => item.id === id)
        setChoosedProductFeedItem(item)
    }

    const onCreateProductFeedBtnClick = () => {
        setChoosedProductFeedItem(null);
        nextStep(null);
    }

    const onCreateDropdownClick = () => {
        setChoosedProductFeedItem(null)
    }

    const nextStep = (id) => {
        changeOnboardingState({
            type: ONBOARDING_TYPES.google,
            status: ONBOARDING_STATES.choose_product_feed,
            currentShopId: shopId,
            data: { id: id },
        }).then((res) => {
            if (res.status === 409) {
                googleStatePolling(shopId, redirect, setGoogleOnboardingState)
            }
            return res.json()
        })
            .then((res) => {
                if (res?.processing) {
                    googleStatePolling(shopId, redirect, setGoogleOnboardingState);
                }
            });
    }

    const onContinueBtnClick = () => {
        nextStep(choosedProductFeedItem.id);
    }

    useEffect(() => {
        fetch(`/api/onboarding/${shopId}/google/product_feeds`)
            .then(res => res.json())
            .then(data => {
                setProductFeed(data);
            })
    }, [])

    return {
        productFeed,
        onProductItemClick,
        choosedProductFeedItem,
        onCreateProductFeedBtnClick,
        onContinueBtnClick,
        onCreateDropdownClick
    }
}