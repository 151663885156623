import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from 'components/Button';
import TipsWidget from 'components/TipsWidget';
import AdPreviewLoader from 'components/AdPreviewLoader';
import BulkNotification from 'components/BulkNotification';
import { Card, CardTitle, CardContent } from 'components/Card';

import './ProspectingPreview.css';
import prospectingAdPreviewImage from './prospecting-ad-preview.gif';

const CardHeaderWrapper = () => (
    <div className="prospecting-preview-card__header">
        <CardTitle subTitle>
            <span>Ad Preview</span>
            <TipsWidget placement="top" isDarkView>
                <p className="tips-description__content">
                    It is just an example of an ad preview to give you an idea of how the ad will look like. Adwisely
                    will show each user the product he/she is most likely to purchase.
                </p>
                <p className="tips-description__content">
                    <a
                        href={
                            'https://help.adwisely.com/improve-your-campaign-results/' +
                            'learn-about-advertising-tactics/prospecting'
                        }
                        className="link link-white"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        What products will be used in the ads? &#x2192;
                    </a>
                </p>
            </TipsWidget>
        </CardTitle>
    </div>
);

class ProspectingPreview extends Component {
    state = {
        frameLoaded: false,
    };

    render() {
        const {
            haveHeader,
            adPreviewLink,
            adPreviewSuccess,
            isAdExample,
            adPreviewFailure,
            adPreviewError,
            bulkNotificationType,
            bulkNotificationContent,
            campaignDetailsData,
        } = this.props;

        let bulkNotification = null;

        if (this.props.bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        const iframeSize = {
            width: this.state.frameLoaded ? '320' : '0',
            height: this.state.frameLoaded ? '671' : '0',
        };
        let adPreview = <AdPreviewLoader />;
        const isProspectingInReview = campaignDetailsData.filter(
            (campaign) => campaign.client_status === 'in_review' && campaign.type === 'prospecting',
        );
        if (adPreviewFailure) {
            if (adPreviewError && adPreviewError.errorToken) {
                adPreview = <p className="text-muted text-center">Ad Preview Unavailable</p>;
            } else {
                adPreview = (
                    <div>
                        <p className="text-muted">Failed to request ad preview.</p>
                        <Button color="primary" onClick={this.props.requestAdPreview}>
                            Re-send request
                        </Button>
                    </div>
                );
            }
        }
        if (adPreviewSuccess || isProspectingInReview.length) {
            adPreview = (
                <div>
                    {!this.state.frameLoaded && <AdPreviewLoader />}
                    <iframe
                        scrolling="yes"
                        title="ad preview"
                        width={iframeSize.width}
                        height={iframeSize.height}
                        src={adPreviewLink}
                        style={{
                            display: 'initial',
                            border: 'none',
                        }}
                        onLoad={() => {
                            this.setState({
                                frameLoaded: true,
                            });
                        }}
                    />
                </div>
            );
        }
        return (
            <Card className="prospecting-preview-card" disablePadding>
                {haveHeader && <CardHeaderWrapper />}
                <CardContent className="prospecting-preview-card__wrapper">
                    {isAdExample || isProspectingInReview.length ? (
                        <img
                            className="prospecting-preview-card__image"
                            src={prospectingAdPreviewImage}
                            alt="Prospecting preview icon"
                        />
                    ) : (
                        adPreview
                    )}
                </CardContent>
                {!!(isAdExample || isProspectingInReview.length) && (
                    <div className="prospecting-preview-card__description">
                        <h3 className="prospecting-preview-card__description-title">Ad Example</h3>
                        <p className="prospecting-preview-card__description-content">
                            Your Ad Preview will be available once you create a prospecting campaign.
                        </p>
                    </div>
                )}
                {bulkNotification}
            </Card>
        );
    }
}

export default connect(({ campaign, bulkNotification, campaignDetails }) => ({
    adPreviewLink: campaign.adPreviewLink,
    adPreviewFailure: campaign.adPreviewFailure,
    adPreviewSuccess: campaign.adPreviewSuccess,
    adPreviewError: campaign.adPreviewError,
    bulkNotificationContent: bulkNotification.notificationContent,
    bulkNotificationType: bulkNotification.notificationType,
    campaignDetailsData: campaignDetails.campaignDetailsData,
}))(ProspectingPreview);
