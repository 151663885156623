import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Button from 'components/Button';
import { googleFlow } from 'modules/home/utils';
import { Card } from 'modules/home/components/Card';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { setLocalStorageObjectItem } from 'helpers';
import googleIcon from "../../images/google-btn-icon.svg";

const GoogleCardView = ({
    state,
    push,
    currentShopId,
    disableButtons,
    googleLoginUrl,
    isGoogleAuthorized,
    handleStartOnboarding,
    onClick,
    isActive,
    icons,
    additionalClassName
}) => {
    const handleContinueWithGoogle = () => {
        if (state === ONBOARDING_STATES.done) {
            setLocalStorageObjectItem('activeTab', '1');
            push(`/${currentShopId}/dashboard`);
            handleStartOnboarding();
            return;
        }

        if (isGoogleAuthorized) {
            push(`/${currentShopId}/google`); // start google onboarding flow
            handleStartOnboarding(); // send change request for onboarding state
        } else {
            window.location.assign(googleLoginUrl);
        }

        // TODO: should be in root entry point - when user doesn't have localstorage data we need to add it
        if (!googleFlow.get()) {
            googleFlow.set(state);
        }
    };

    return (
        <Card
            additionalClassName={additionalClassName}
            icons={icons}
            type={state}
            onClick={onClick}
            isActive={isActive}
            title="Google AI-powered Campaign"
            text="This smart ad strategy drives sales with high ROAS across Google Search, YouTube, Display Network, and Gmail with precise targeting and advanced learning capabilities, simultaneously engaging both retargeting and acquiring new users"
            renderButton={() => (
                <Button
                    color="primary"
                    disabled={disableButtons}
                    onClick={handleContinueWithGoogle}
                    className="home__board__block__content__btn"
                >
                    <span className="home__board__block__content__btn-icon">
                        <img src={googleIcon} alt="google icon"/>
                    </span>
                    {state === ONBOARDING_STATES.not_started ? 'Launch my Ads on Google'
                        : state === ONBOARDING_STATES.done ? 'See my results' 
                            : 'Continue setup to run Ads'}
                </Button>
            )}
        />
    );
};

const mapStateToProps = ({ shops, google }) => ({
    currentShopId: shops?.currentShopId,
    isGoogleAuthorized: google?.login?.authorized,
});

const mapPropsToDispatch = {
    push,
};

export const GoogleCard = connect(mapStateToProps, mapPropsToDispatch)(GoogleCardView);
