export const FACEBOOK_LOGIN_CALLBACK_URL = 'FACEBOOK_LOGIN_CALLBACK_URL';

export const TOKEN = {
    MISSING_SCOPES: 'missing-scopes',
    MISSING_EMAIL: 'missing-email',
};

export const LOGIN_ERROR_MESSAGES = {
    DECLINE: 'decline',
    FAILED: 'failed',
    BAD_PARAMS: 'bad-params',
    NO_USER_EMAIL: 'no-user-email',
};
