import {
    callAPICreateStripeCustomer,
    callAPIStripeRetryCharge,
    callAPIGetStripeChargeAmount,
    callAPIStripeSource,
    callAPIDeleteStripeSource,
} from './api';
import {CREATE_SOURCE_ERROR} from '../constants/platform';

export const STRIPE_ID_REQUEST = 'STRIPE_ID_REQUEST';
export const STRIPE_ID_SUCCESS = 'STRIPE_ID_SUCCESS';
export const STRIPE_ID_FAILURE = 'STRIPE_ID_FAILURE';
export const STRIPE_ID_RESET = 'STRIPE_ID_RESET';
export const STRIPE_BILLING_AMOUNT = 'STRIPE_BILLING_AMOUNT';

export const requestCreateStripeCustomer = (shopId, params) => (callAPICreateStripeCustomer(shopId, {
    params,
    types: [
        STRIPE_ID_REQUEST,
        STRIPE_ID_SUCCESS,
        STRIPE_ID_FAILURE,
    ],
}));

export const requestUpdateStripeSource = (shopId, params) => (callAPIStripeSource(shopId, {
    params,
    types: [
        STRIPE_ID_REQUEST,
        STRIPE_ID_SUCCESS,
        STRIPE_ID_FAILURE,
    ],
}));

export const handleErrorStripeCreateSource = () => dispatch => dispatch({
    type: STRIPE_ID_FAILURE,
    payload: CREATE_SOURCE_ERROR,
});

export const STRIPE_DELETE_SOURCE_REQUEST = 'STRIPE_DELETE_SOURCE_REQUEST';
export const STRIPE_DELETE_SOURCE_SUCCESS = 'STRIPE_DELETE_SOURCE_SUCCESS';
export const STRIPE_DELETE_SOURCE_FAILURE = 'STRIPE_DELETE_SOURCE_FAILURE';

export const requestStripeDeleteSource = (shopId, params = {}) =>
    callAPIDeleteStripeSource(shopId, {
        params,
        types: [
            STRIPE_DELETE_SOURCE_REQUEST,
            STRIPE_DELETE_SOURCE_SUCCESS,
            STRIPE_DELETE_SOURCE_FAILURE,
        ],
    });

export const STRIPE_BILLING_AMOUNT_REQUEST = 'STRIPE_BILLING_AMOUNT_REQUEST';
export const STRIPE_BILLING_AMOUNT_SUCCESS = 'STRIPE_BILLING_AMOUNT_SUCCESS';
export const STRIPE_BILLING_AMOUNT_FAILURE = 'STRIPE_BILLING_AMOUNT_FAILURE';

export const requestStripeBillingAmount = shopId => callAPIGetStripeChargeAmount(shopId, {
    types: [
        STRIPE_BILLING_AMOUNT_REQUEST,
        STRIPE_BILLING_AMOUNT_SUCCESS,
        STRIPE_BILLING_AMOUNT_FAILURE,
    ],
});

export const resetStripeData = () => dispatch => dispatch({
    type: STRIPE_ID_RESET,
});

export const setStripeBillingAmount = amount => dispatch => dispatch({
    type: STRIPE_BILLING_AMOUNT,
    payload: amount,
});

export const STRIPE_RETRY_CHARGE_REQUEST = 'STRIPE_RETRY_CHARGE_REQUEST';
export const STRIPE_RETRY_CHARGE_SUCCESS = 'STRIPE_RETRY_CHARGE_SUCCESS';
export const STRIPE_RETRY_CHARGE_FAILURE = 'STRIPE_RETRY_CHARGE_FAILURE';

export const requestRechargeUseStripeCard = shopId => callAPIStripeRetryCharge(shopId, {
    types: [
        STRIPE_RETRY_CHARGE_REQUEST,
        STRIPE_RETRY_CHARGE_SUCCESS,
        STRIPE_RETRY_CHARGE_FAILURE,
    ],
});
