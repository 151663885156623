import { replace } from 'react-router-redux';
import { ROUTES } from 'constants/routes';
import withData from 'decorator/withStateData';
import Uninstalled from './Uninstalled';

export default withData(
    Uninstalled,
    ['account'],
    (data) => data.account.entity,
    () => (dispatch) => dispatch(replace(ROUTES.facebookLogin)),
);
