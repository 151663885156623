import * as notificationAction from 'actions/notification';

const initialState = {
    notificationContent: null,
    notificationType: null,
};

export default (state = { ...initialState }, action) => {
    switch (action.type) {
        case notificationAction.SHOW_BULK_NOTIFICATION:
            state = {
                notificationContent: action.payload.notificationContent,
                notificationType: action.payload.notificationType,
            };
            break;
        case notificationAction.HIDE_BULK_NOTIFICATION:
            state = {
                notificationContent: null,
                notificationType: null,
            };
            break;
        default:
            break;
    }
    return state;
};
