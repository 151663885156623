import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { ONBOARDING_TYPES } from 'constants/onboarding'
import { TIK_TOK_ONBOARDING_STATES } from 'constants/tikTokOnboarging';

import SubscriptionPlan from 'modules/onboarding/components/SubscriptionPlan';

import { tikTokStatePolling } from 'modules/onboarding/tiktok/containers/TikTokOnboardingMiddleware';
import { changeOnboardingState } from 'modules/home/utils';

const SubscriptionPlanTikTokView = ({currentShopId, replace}) => {
    const onSubscriptionPlanSelectSuccess = () => {
        changeOnboardingState({
            type: ONBOARDING_TYPES.tik_tok,
            status: TIK_TOK_ONBOARDING_STATES.choose_subscription_plan,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    tikTokStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then((res) => {
                if (res?.processing) {
                    tikTokStatePolling(currentShopId, replace)
                }
            });
    }

    return (
        <SubscriptionPlan
            onSubscriptionPlanSelectSuccess={onSubscriptionPlanSelectSuccess}
        />
    );
}

const mapStateToProps = ({ shops }) => (
    {
        currentShopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace,
};

export const SubscriptionPlanTikTok = connect(mapStateToProps, mapPropsToDispatch)(SubscriptionPlanTikTokView);