import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PopupModal } from 'react-calendly';
import {replace} from "react-router-redux";

import Button from 'components/Button';

import { ONBOARDING_TYPES } from 'constants/onboarding';
import { getCurrentShopSelector } from 'helpers/selectors';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';

import './ShopProtected.css';
import {googleStatePolling} from "../../onboarding/google/helpers/googleStatePolling";
import {setGoogleOnboardingState} from "../../../actions/googleOnboardingState";

const ShopProtectedView = ({ currentShop, currentShopId, redirect, setGoogleOnboardingState }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
        setIsError(false);
    };

    const buttonClickHandler = () => {
        setIsPending(!isPending);

        fetch(`/api/onboarding/${currentShopId}/google/shop_password_enabled`)
            .then((res) => res.json())
            .then((response) => {
                if (!response.password_enabled) {
                    changeOnboardingState({
                        currentShopId,
                        type: ONBOARDING_TYPES.google,
                        status: ONBOARDING_STATES.shop_protected,
                    }).then((res) => {
                        if (res.status === 409) {
                            googleStatePolling(currentShopId, redirect, setGoogleOnboardingState)
                        }
                        return res.json()
                    })
                        .then((res) => {
                            if (res?.processing) {
                                googleStatePolling(currentShopId, redirect, setGoogleOnboardingState);
                            }
                        });
                    setIsError(false);
                } else {
                    setIsPending(false);
                    setIsChecked(false);
                    setIsError(true);
                }
            });
    };

    return (
        <>
            <div className="shop-protected__wrapper">
                <h1 className="shop-protected__title">Your store is password protected</h1>

                <div className="shop-protected__main-section">
                    <p className="shop-protected__description">
                        To continue setting up Google Ads, you need to disable password protection in your Shopify{' '}
                        <a
                            href={`https://${currentShop.name}.myshopify.com/admin/online_store/preferences`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            admin panel.
                        </a>{' '}
                        <br />
                        <a
                            href="https://help.shopify.com/en/manual/online-store/themes/password-page"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="shop-protected__text-link"
                        >
                            Learn more
                        </a>
                    </p>

                    <p className="shop-protected__description shop-protected__description_bottom">
                        <label className="shop-protected__checkbox" htmlFor="isShopProtected">
                            <input
                                className="checkbox"
                                type="checkbox"
                                disabled={false}
                                aria-disabled={false}
                                onChange={toggleCheckbox}
                                checked={isChecked}
                                id="isShopProtected"
                            />
                            I removed password from my shop
                        </label>
                    </p>

                    {isError && <p className="shop-protected__error">Your store is still password protected</p>}

                    <div className="shop-protected__buttons">
                        <Button
                            color="primary"
                            pending={isPending}
                            disabled={!isChecked}
                            onClick={buttonClickHandler}
                            className="shop-protected__button"
                        >
                            Continue
                        </Button>
                        <div>
                            <Button
                                className="shop-protected__button"
                                onClick={() => setIsCalendlyOpen(true)}
                            >
                                Contact support
                            </Button>
                            <PopupModal
                                url="https://calendly.com/adwisely-support-team/support-team"
                                onModalClose={() => setIsCalendlyOpen(false)}
                                open={isCalendlyOpen}
                                rootElement={document.getElementById("root")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
    currentShop: getCurrentShopSelector(shops, shops.currentShopId),
});

const mapPropsToDispatch = {
    redirect: replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export const ShopProtected = connect(mapStateToProps, mapPropsToDispatch)(ShopProtectedView);
