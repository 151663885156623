import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { requestGoogleLoginLink, requestLoginLink } from 'actions/login';
import { setInframeTrue } from 'actions/inframe';
import { getIntercomUser } from 'actions/intercom';
import { Loader } from 'components/Loader';

import { ROUTES } from 'constants/routes';

import inIframe from 'libs/inIframe';

import LoginView from './LoginView';
import { LOGIN_ERROR_MESSAGES, FACEBOOK_LOGIN_CALLBACK_URL } from '../const';
import { getLoginErrorMessage } from './LoginErrorMessage/getLoginErrorMessage';
import {addGDPRBanner} from "../../../../helpers/gdpr";

/**
 * Component implement logic of login page
 */
class LoginContainer extends Component {
    constructor(props) {
        super(props);
        if (inIframe()) {
            this.props.setInframeTrue();
        }
    }

    componentDidMount() {
        const {
            location: { query },
            replace,
            getIntercomUser,
            requestGoogleLoginLink,
            requestFacebookLoginLink,
        } = this.props;

        getIntercomUser();

        fetch('/api/account', {
            method: 'GET'
        }).then(res => res.json())
          .then(data => {
                const { id, shops } = data.data;
                if (id) {
                    if (shops.length === 0) {
                        replace(ROUTES.chooseInstallShop)
                    } else if (shops.length > 1) {
                        replace(ROUTES.chooseShop)
                    } else {
                        replace(`/${shops[0].id}/home`)
                    }
                }
            })

        requestFacebookLoginLink(false);
        requestGoogleLoginLink({ redirect_path: query.redirect_to ? query.redirect_to : '/' });
        if (query.redirect_to) {
            localStorage.setItem(FACEBOOK_LOGIN_CALLBACK_URL, query.redirect_to);
        }

        addGDPRBanner();
    }

    getTitleAndDescription = () => {
        const { location } = this.props;

        let title = 'Use your account on Facebook or Google to';

        let description = <p className="alert-description__item">create new or manage existing ad campaigns</p>;

        if (location.query.from) {
            title = location.query.from;
            description = 'create new or manage existing ad campaigns';
        }

        return {
            title,
            description,
        };
    };

    render() {
        const {
            facebookLoginLink,
            isFacebookLoginLinkRequest,
            facebookLoginLinkError,
            requestFacebookLoginLink,
            inframe,
            googleLoginLink,
            isGoogleLoginLinkRequest,
            location: {
                query: { error },
            },
        } = this.props;

        if (isFacebookLoginLinkRequest || isGoogleLoginLinkRequest) {
            return <Loader />;
        }

        const errorMessage = getLoginErrorMessage({
            error,
            facebookLoginLinkError,
            facebookLoginLink,
            requestFacebookLoginLink,
        });

        const { title, description } = this.getTitleAndDescription();

        return (
            <LoginView
                title={title}
                facebookLoginLink={facebookLoginLink}
                googleLoginLink={googleLoginLink}
                errorMsg={errorMessage}
                description={description}
                inframe={inframe.inframe}
                className="fb-login-page"
                targetBlank={errorMessage || inframe.inframe}
                errorWide={error === LOGIN_ERROR_MESSAGES.DECLINE}
            />
        );
    }
}

const mapStateToProps = ({
    account,
    onboarding,
    facebook: {
        login: { loginLink, loginLinkError, loginLinkRequest },
    },
    google,
    shops
}) => ({
    account,
    inframe: onboarding.inframe,
    onboarding,
    facebookLoginLink: loginLink,
    facebookLoginLinkError: loginLinkError,
    isFacebookLoginLinkRequest: loginLinkRequest,
    googleLoginLink: google?.login?.authUrl,
    isGoogleLoginLinkRequest: google?.login?.loginLinkRequest,
    shops

});

const mapDispatchToProps = {
    requestFacebookLoginLink: requestLoginLink,
    requestGoogleLoginLink: requestGoogleLoginLink,
    setInframeTrue,
    getIntercomUser,
    replace
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
