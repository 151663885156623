import React from 'react';
import PropTypes from 'prop-types';

import AlertWidget from 'components/AlertWidget';
import IntercomChatCaller from 'components/IntercomChatCaller';

const PageNotificationsNotifications = ({ isVisible }) => {
    if (isVisible) {
        return (
            <AlertWidget color="warning">
                <p className="alert-description__item">
                    We couldn't create some assets for your campaign. Please,
                    <IntercomChatCaller
                        content=" contact us "
                        className="alert-description__link alert-description__link_highlighted"
                    />
                    and we will do our best to resolve it asap.
                </p>
            </AlertWidget>
        );
    }

    return null;
};

PageNotificationsNotifications.defaultProps = {
    isVisible: false,
};

PageNotificationsNotifications.propTypes = {
    isVisible: PropTypes.bool,
};

export default PageNotificationsNotifications;
