import React from 'react';
import './InstagramDesktop.css';
import dots from "../../img/dots.svg";

import instagramMenu from './img/instagram-menu.svg';
import instagramComments from './img/instagram-comments.svg';

export const InstagramDesktop = ({product}) => (
        <div className='instagram-desktop'>
            <div className="instagram-desktop__header">
                <div className="instagram-desktop__avatar-wrapper">
                    <div className="instagram-desktop__avatar" />
                    <div className="instagram-desktop__name-wrapper">
                        <div className="instagram-desktop__name">
                            <span>{product.url}</span>
                        </div>
                        <div className="instagram-desktop__description">
                            <span>Sponsored</span>
                        </div>
                    </div>
                </div>
                <div className="instagram-desktop__dots">
                    <img src={dots} alt="dots icon"/>
                </div>
            </div>
            <div className="instagram-desktop__img-wrapper">
                <img src={product.img} alt={product.title} />
            </div>
            <div className="instagram-desktop__menu">
                <img src={instagramMenu} alt="instagram"/>
            </div>
            <div className='instagram-desktop__post-info'>
                <span className='instagram-desktop__text'>
                    <span className='instagram-desktop__post-text'>
                        <span className='instagram-desktop__author'>{product.title}</span>
                        {product.description}
                    </span>
                    <span className='instagram-desktop__more'>... more</span>
                </span>
            </div>
            <div className="instagram-desktop__menu">
                <img src={instagramComments} alt="instagram"/>
            </div>
        </div>
    )
