import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

export const ShopifyBillingView = ({
    title,
    subTitle,
    billingText,
    handleOnClick,
    approveChargeURL,
}) => (
    <div className="shopify-billing__wrapper">
        <h1 className="shopify-billing__title">{title}</h1>
        <section className="shopify-billing__section">
            <p className="shopify-billing__subtitle">{subTitle}</p>
            <p className="shopify-billing__text">{billingText}</p>
            <p className="shopify-billing__text--info">
                <a
                    className="shopify-billing__link"
                    href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-retargetapp"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn more
                </a>
            </p>
            <Button
                onClick={() => {
                    localStorage.setItem('redirect_from_page', window.location.href);
                    handleOnClick();
                }}
                color="primary"
                className="shopify-billing__button"
                url={approveChargeURL}
                selfTarget
            >
                Approve Charge
            </Button>
        </section>
    </div>
);

ShopifyBillingView.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    billingText: PropTypes.string.isRequired,
    approveChargeURL: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func.isRequired,
};
