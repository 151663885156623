import { callAPIShopQualification } from './api';

export const SHOP_QUALIFICATION_REQUEST = 'SHOP_QUALIFICATION_REQUEST';
export const SHOP_QUALIFICATION_SUCCESS = 'SHOP_QUALIFICATION_SUCCESS';
export const SHOP_QUALIFICATION_FAILURE = 'SHOP_QUALIFICATION_FAILURE';

export const requestShopQualification = params => (callAPIShopQualification({
    params,
    types: [
        SHOP_QUALIFICATION_REQUEST,
        SHOP_QUALIFICATION_SUCCESS,
        SHOP_QUALIFICATION_FAILURE,
    ],
}));
