import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './DailyBudgetInput.css';

export const DailyBudgetInput = ({
    dailyBudget,
    dailyBudgetCurrency,
    onChange,
    onFocus,
    disabled,
    dailyBudgetHasErrors,
}) => (
    <div className={classNames('daily-budget-input', { 'daily-budget-input_error': dailyBudgetHasErrors })}>
        <div className="daily-budget-input__addon">{dailyBudgetCurrency}</div>
        <input
            name="campaignDailyBudget"
            id="dbInput"
            value={dailyBudget}
            onChange={onChange}
            onFocus={onFocus}
            onWheel={(e) => e.preventDefault()}
            className="daily-budget-input__input"
            type="number"
            disabled={disabled}
        />
    </div>
);

DailyBudgetInput.propTypes = {
    dailyBudget: PropTypes.string.isRequired,
    dailyBudgetCurrency: PropTypes.string.isRequired,
    dailyBudgetHasErrors: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DailyBudgetInput;
