import * as onboarding from 'actions/onboarding';

import { hideLoader, showLoader } from 'actions/loader';
import { showNotification } from 'actions/notification';

let adAccountChecker = null;

export default (store) => (next) => (action) => {
    const { dispatch } = store;

    /* Beware! You should decide to DO or NOT call `next()` in each case */
    switch (action.type) {
        case onboarding.ONBOARDING_FACEBOOK_LOGIN_SUCCESS:
            next(action);
            dispatch(hideLoader());
            break;

        case onboarding.ONBOARDING_FACEBOOK_LOGIN_FAILURE:
            dispatch(showNotification(action.payload));
            next(action);
            dispatch(onboarding.goToLogin());
            break;

        case onboarding.ONBOARDING_FB_LOGIN_LINK_REQUEST_SUCCESS:
            next(action);
            dispatch(hideLoader());
            break;

        case onboarding.ONBOARDING_FB_LOGIN_LINK_REQUEST_FAILURE:
            dispatch(showNotification(action.payload));
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_CHOSEN:
            if (store.getState().onboarding.adAccounts.chosenAdAccountId && typeof adAccountChecker === 'function') {
                adAccountChecker(true);
            }
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST:
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_SUCCESS:
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_FAILURE:
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST:
            next(action);
            dispatch(showLoader());
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_SUCCESS:
            {
                const connectionStatus = action.payload;
                delete action.payload;
                action.connectStatus = connectionStatus;
                adAccountChecker = createRequestChecker(
                    onboarding
                        .requestFBAdAccountConnectStatus({
                            id: store.getState().onboarding.adAccounts.chosenAdAccountId,
                        })
                        .bind(null, dispatch),
                );
                adAccountChecker(connectionStatus.access_status === 'granted');
                dispatch(hideLoader());
                next(action);
            }
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_FAILURE:
            dispatch(showNotification(action.payload));
            dispatch(hideLoader());
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST:
            next(action);
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_SUCCESS:
            {
                const connectionStatus = action.payload;
                adAccountChecker(
                    connectionStatus.access_status === 'granted' || connectionStatus.access_status === 'declined',
                );
                action.connectStatus = action.payload;
                delete action.payload;
                next(action);
            }
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_FAILURE:
            adAccountChecker();
            dispatch(showNotification(action.payload));
            next(action);
            break;

        case onboarding.ONBOARDING_FB_PAGE_CONNECT_REQUEST:
            next(action);
            break;

        case onboarding.ONBOARDING_FB_PAGE_CONNECT_REQUEST_SUCCESS: {
            action.fbPageConnectStatus = action.payload;
            delete action.payload;
            next(action);
            break;
        }

        case onboarding.ONBOARDING_FB_PAGE_CONNECT_REQUEST_FAILURE:
            dispatch(showNotification(action.payload));
            dispatch(hideLoader());
            next(action);
            break;

        case onboarding.ONBOARDING_INSTALL_SHOP_SUCCESS:
            next(action);
            dispatch(onboarding.goToLogin(action.payload.id));
            break;

        case onboarding.ONBOARDING_INSTALL_SHOP_FAILURE:
            dispatch(showNotification(action.payload));
            next(action);
            break;

        default:
            next(action);
            break;
    }
};

function createRequestChecker(cb) {
    let destroyed = false;
    let timer = null;
    return (shouldStop) => {
        if (destroyed) return;

        if (shouldStop) {
            clearTimeout(timer);
            destroyed = true;
            return;
        }

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(() => {
            cb();
        }, 2000);
    };
}
