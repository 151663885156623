import PropTypes from "prop-types"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    requestCampaignSuggestionsForCampaignCreate,
    requestCreateCampaign,
    goToDashboard,
    clearCampaignError,
    requestCampaignValidateFacebook,
} from 'actions/campaign';
import { requestShopNotificationsDeleteSpecialOfferInvite } from 'actions/notification';
import { validateShop } from 'actions/shop';

import pageNameSetter from 'decorator/pageNameSetter';

import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import {
    getCurrentShopSelector,
    isShopValidateRequesting,
    getShopErrorSelector,
    getShopErrorTokenSelector,
    getAdAccountsSelector,
    getCampaignSuggestionsSelector,
    getShopRecurringChargeSelector,
} from 'helpers/selectors';

import Banners from 'components/Banners';

import { changeShopPage } from 'constants/metric';
import { SPECIAL_OFFER_INVITE } from 'constants/notificationType';
import { SPECIAL_OFFER } from "constants/campaign";
import AdsPreviewSection from "components/AdsPreviewSection/AdsPreviewSection";
import { ONBOARDING_TYPES } from "constants/onboarding";
import { SHOPIFY } from "constants/platform";
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from '../constants';


import CampaignInfo from '../components/CampaignInfo';
import CampaignDetails from '../components/CampaignDetails';
import SaveCampaignDetails from '../components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from '../components/CampaignPageLayout';

import './SpecialOfferCampaignCreate.css';
import specialOfferIcon from './stars.svg';

const CAMPAIGN_AD_MESSAGE_DEFAULT = "THIS WEEK ONLY: Get {{product.name}} for just {{product.price}}. Shop now!";
const CAMPAIGN_AD_MESSAGE_DEFAULT_2 = "Psst! We know you checked out {{product.name}}. It’s on sale now—come get one while it’s still in stock!";
const recurringChargeError = 'shop-is-frozen-due-to-pending-recurring-charge';

export class SpecialOfferCampaignCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewAdMessage: null,
            currentPreviewType: null,
            isPublishPage: true,

            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignAdMessage2: CAMPAIGN_AD_MESSAGE_DEFAULT_2,
            campaignMinBudget: 45,

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            saveCampaignError: null,
            isMobilePreview: false
        };

        this.handleAdPreviewMsg = this.handleAdPreviewMsg.bind(this);
        this.handlePreviewType = this.handlePreviewType.bind(this);
        this.changePublishPage = this.changePublishPage.bind(this);

        // save campaign details
        this.handleFormError = this.handleFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);

        this.saveCampaign = this.saveCampaign.bind(this);
        this.refreshMinBudgetAndAdPreview = this.refreshMinBudgetAndAdPreview.bind(this);

        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
            checkShopForErrors,
        } = this.props;
        requestSuggestions({ id: shopId });
        if (this.props.shopRecurringCharge.success) {
            const params = {
                shopId: this.props.params.shopId,
                campaign_type: SPECIAL_OFFER,
                daily_budget: this.state.campaignBudget,
                message: this.state.campaignAdMessage,
            };
        }
        checkShopForErrors({ shopId });
        window.addEventListener('focus', this.windowFocusListener);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            params: { shopId },
        } = nextProps;
        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = CAMPAIGN_AD_MESSAGE_DEFAULT;

                const storageCampaignType = campaignCreateStorageHelper.getCampaignType();
                const storageDailyBudget = campaignCreateStorageHelper.getCampaignDailyBudget();
                const storageAdMessage = campaignCreateStorageHelper.getCampaignAdMessage();
                const storageShopId = campaignCreateStorageHelper.getCampaignShopId();

                if (
                    shopId === storageShopId &&
                    storageCampaignType === SPECIAL_OFFER &&
                    storageDailyBudget &&
                    storageAdMessage
                ) {
                    this.setState({
                        campaignBudget: parseInt(storageDailyBudget, 10) / 100,
                        campaignMinBudget: suggestionsData.daily_budget.special_offer_min_value / 100,
                        campaignCurrency: suggestionsData.daily_budget.currency,
                        campaignAdMessage: storageAdMessage,
                    });
                } else {
                    this.setState({
                        campaignBudget: suggestionsData.daily_budget.special_offer_suggested_value / 100,
                        campaignMinBudget: suggestionsData.daily_budget.special_offer_min_value / 100,
                        campaignCurrency: suggestionsData.daily_budget.currency,
                        campaignAdMessage: suggestedAdMessage,
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.clearCampaignError();
        window.removeEventListener('focus', this.windowFocusListener);
    }

    handleAdPreviewMsg(msg, isForceRequest = false) {
        this.setState({
            previewAdMessage: msg,
        });
    }

    handlePreviewType(curPreviewType, isForceRequest = true) {
        this.setState({
            currentPreviewType: curPreviewType,
        });
    }

    handleFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    isRecurringChargeActive(shopRecurringCharge = this.props.shopRecurringCharge) {
        const validStatuses = ['accepted', 'active'];
        if (shopRecurringCharge.entity) {
            return validStatuses.includes(shopRecurringCharge.entity.status);
        }
        return false;
    }

    saveCampaign(dailyBudget, adMessages) {
        const {
            createCampaign,
            goToNextStep,
            params: { shopId },
            suggestions,
            removeSpecialOfferInviteNotification,
        } = this.props;
        if (!suggestions.campaignSuggestionsSuccess || !dailyBudget) return;
        const params = {
            shopId,
            campaign_type: SPECIAL_OFFER,
            daily_budget: dailyBudget,
            messages: adMessages,
        };

        createCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                campaignCreateStorageHelper.clearStorage();
                removeSpecialOfferInviteNotification({
                    id: shopId,
                    type: SPECIAL_OFFER_INVITE,
                });
                goToNextStep(shopId);
                this.setState({
                    saveCampaignError: null,
                });
            } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                this.setState({
                    saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                });
            }
        });
    }

    refreshMinBudgetAndAdPreview() {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    changePublishPage(isPublish) {
        this.setState({
            isPublishPage: isPublish,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        this.setState({
            adMessageTagsHasError: !!tipsErrorText,
            adMessageTagsHasErrorTipsErrorText: tipsErrorText,
        });
    }

    campaignValidateFacebookHandler() {
        const {
            shopValidateRequesting,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId);
        }
    }

    setIsMobilePreview = (newState) => {
        this.setState({isMobilePreview: newState})
    }

    render() {
        const campaignFormName = 'specialOfferCampaignCreate';
        const {
            shopError,
            shopValidateRequesting,
            shopRecurringCharge,
            params: { shopId },
            currentShop,
            checkShopForErrors,
        } = this.props;
        const {
            campaignBudget,
            campaignAdMessage,
            campaignAdMessage2,
            campaignCurrency,
            campaignMinBudget,
            adMessageTagsHasError,
            hasCampaignFormError,
            adMessageTagsHasErrorTipsErrorText,
            isPublishPage,
            tipsErrorText,
            saveCampaignError,
        } = this.state;

        let shopErrorBanner = null;
        if (shopError && Object.keys(shopError).length) {
            shopErrorBanner = (
                <Banners
                    shopError={shopError}
                    changeTipsErrorText={this.changeTipsErrorText}
                    handleFormError={this.handleFormError}
                    validationPending={shopValidateRequesting}
                    validateShopSettings={() => checkShopForErrors({ shopId })}
                    campaignValidateFacebook={this.campaignValidateFacebookHandler}
                    shopRecurringCharge={shopRecurringCharge}
                    checkShopForErrors={() => checkShopForErrors({ shopId })}
                    shopId={shopId}
                    currentShop={currentShop}
                    changePublishPage={this.changePublishPage}
                />
            );
        }

        return (
            <div className="special_offer-campaign-create__wrapper">
                {shopErrorBanner}
                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            showIcon
                            shopId={shopId}
                            campaignTitle={<p className="campaign-info__title">Facebook <span className="campaign-info__title--green">Special Offer</span><img src={specialOfferIcon} alt="" className="campaign-info__title-img--special-offer" /></p>}
                            titleIconUrl={specialOfferIcon}
                            learnMoreLink="https://help.adwisely.com/en/articles/4585544-special-offer"
                            linkCaption="Learn more"
                            campaignDescription={
                                `Run a short-term promo for a new collection, sale, sitewide discount,
                                or a special holiday offer to brand new customers and store visitors`
                            }
                            campaignType={SPECIAL_OFFER}
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={campaignAdMessage}
                            campaignAdMessage2={campaignAdMessage2}
                            campaignCurrency={campaignCurrency}
                            campaignMinBudget={campaignMinBudget}
                            campaignBudget={campaignBudget}
                            saveCampaign={this.saveCampaign}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            saveCampaignError={saveCampaignError}
                            campaignType={SPECIAL_OFFER}
                        />

                        <SaveCampaignDetails
                            formName={campaignFormName}
                            buttonTitle="Next"
                            buttonColor="primary"
                            hasCampaignFormError={
                                adMessageTagsHasError || hasCampaignFormError || !!shopValidateRequesting
                            }
                            tipsErrorText={adMessageTagsHasErrorTipsErrorText || tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdsPreviewSection
                            currentActiveCard={ONBOARDING_TYPES.facebook_visitor_retargeting}
                            isMobilePreview={this.state.isMobilePreview}
                            setIsMobilePreview={this.setIsMobilePreview}
                            isShopify={currentShop?.type === SHOPIFY}
                            previewAdMessage={this.state.previewAdMessage}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
            </div>
        );
    }
}

SpecialOfferCampaignCreate.defaultProps = {
    shopError: null,
};

SpecialOfferCampaignCreate.propTypes = {
    campaignValidateFacebook: PropTypes.func.isRequired,
    checkShopForErrors: PropTypes.func.isRequired,
    clearCampaignError: PropTypes.func.isRequired,
    createCampaign: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    goToNextStep: PropTypes.func.isRequired,
    params: PropTypes.shape({
        shopId: PropTypes.string.isRequired,
    }).isRequired,
    removeSpecialOfferInviteNotification: PropTypes.func.isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    shopError: PropTypes.shape({
        errorToken: PropTypes.string,
        errorData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
    }),
    shopRecurringCharge: PropTypes.shape({
        entity: PropTypes.shape({
            status: PropTypes.string,
        }),
        success: PropTypes.bool,
    }).isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsSuccess: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);
    return {
        suggestions: getCampaignSuggestionsSelector(state),
        shopError:
            getShopErrorSelector(state) && getShopErrorTokenSelector(state) !== recurringChargeError
                ? getShopErrorSelector(state)
                : {},
        shopValidateRequesting: isShopValidateRequesting(state),
        currentShop,
        shops: state.shops,
        shopRecurringCharge: getShopRecurringChargeSelector(state),
        adAccounts: getAdAccountsSelector(state),
    };
};

const mapDispatchToProps = {
    campaignValidateFacebook: requestCampaignValidateFacebook,
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    createCampaign: requestCreateCampaign,
    goToNextStep: goToDashboard,
    clearCampaignError,
    checkShopForErrors: validateShop,
    removeSpecialOfferInviteNotification: requestShopNotificationsDeleteSpecialOfferInvite,
};

const SpecialOfferCampaignCreateContainer = connect(mapStateToProps, mapDispatchToProps)(SpecialOfferCampaignCreate);

export default pageNameSetter(changeShopPage.create_campaign)(SpecialOfferCampaignCreateContainer);
