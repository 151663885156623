import React, { PureComponent } from 'react';

import { FB_SETTINGS_LINK, FB_SETTINGS_ADD_EMAIL } from '../../constants/staticLinks';
import intercom from '../../libs/intercom';

import Button from '../Button';

import './ConnectEmailFB.css';
import connectEmailFBImg from './connect-email-fb-img.png';

// eslint-disable-next-line react/prefer-stateless-function
class ConnectEmailFB extends PureComponent {
    triggerIntercom(e) {
        e.preventDefault();
        intercom.show();
    }

    render() {
        return (
            <div>
                <img className="connect-email-fb__img" src={connectEmailFBImg} alt="connect-email-fb-img" />
                <h2 className="connect-email-fb__title">Add an e-mail to your Facebook profile</h2>
                <p className="login-view__description connect-email-fb__text">
                    It appears that your Facebook profile doesn<span>&#39;</span>t have an e-mail address that
                    <span>&#39;</span>s why our system can<span>&#39;</span>t authorise you. Add an email to{' '}
                    <Button className="connect-email-fb__link connect-email-fb__link--bluebold" url={FB_SETTINGS_LINK}>
                        your Facebook profile
                    </Button>{' '}
                    to continue setting up your campaign{' '}
                    <Button className="connect-email-fb__link" url={FB_SETTINGS_ADD_EMAIL}>
                        (detailed instruction)
                    </Button>
                    .
                </p>
                <p className="login-view__description connect-email-fb__text">
                    <span role="button" tabIndex="0" className="trigger-intercom-link" onClick={this.triggerIntercom}>
                        Message us
                    </span>{' '}
                    if you have any questions or need help.
                </p>
                <a
                    href="https://fb.com/settings"
                    className="choose-shop-login__button button_primary add-email-button"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Add email in Facebook
                </a>
            </div>
        );
    }
}

export default ConnectEmailFB;
