import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { RETARGETING, PROSPECTING } from 'constants/campaign';
import { saveCurrentShopCampaignType } from 'helpers/onboardingHelper';

import './ChooseCampaign.css';

const ChooseCampaign = ({ currentShopId, replace }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [campaignType, setCampaignType] = useState(null);

    const campaignTypeHandler = (event) => setCampaignType(event.target.value);

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        saveCurrentShopCampaignType(currentShopId, campaignType);
        
        campaignType === RETARGETING 
            ? replace(`/${currentShopId}/onboarding/set-up-retargeting`)
            : replace(`/${currentShopId}/onboarding/set-up-prospecting`)
    }

    return (
        <div className="choose-campaign__wrapper">
            <h1 className="choose-campaign__title">Choose campaign</h1>
            <div className="choose-campaign__body-wrapper">
                <div onChange={campaignTypeHandler}>
                    <input type="radio" value={PROSPECTING} id={PROSPECTING} name="campaignType" />
                    <label htmlFor={PROSPECTING}>Prospecting</label>
                    <p className="choose-campaign__description">
                        Boost sales and acquire more customers by targeting new users who are similar to your
                        current buyers with the ads of the products they are most likely to purchase.
                    </p>
                    <input type="radio" value={RETARGETING} id={RETARGETING} name="campaignType" />
                    <label htmlFor={RETARGETING}>Retargeting</label>
                    <p className="choose-campaign__description">
                        Re-engage your site visitors who didn’t make a purchase with personalized ads of the previously viewed products. 
                    </p>
                </div>
            </div>
            <Button onClick={handleContinueBtn} color="primary" disabled={!campaignType}>
                Continue
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ChooseCampaign);
