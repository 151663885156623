import React, { useEffect, useRef } from 'react';
import {Manager, Popper, Target} from 'react-popper';

import classNames from 'classnames';

import './Dropdown.css';

const Dropdown = ({
                      items,
                      toggle,
                      isOpen,
                      flagToggler,
                      additionalMenuClassName,
                      additionalDropdownClassName,
                      disabled,
                      placement='bottom-start'}) => {

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    toggle(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <Manager>
            <div
                className={classNames(
                    'dropdown_wrapper',
                    additionalDropdownClassName,
                )}
                ref={wrapperRef}
            >
                <div onClick={() => {
                    if (disabled) return;
                    toggle();
                }}>
                    <Target>
                        {flagToggler}
                    </Target>
                </div>
                {isOpen &&
                    <Popper
                        modifiers={{flip: { enabled: false }}}
                        placement={placement}
                        className={classNames(
                            'dropdown-menu_list',
                            additionalMenuClassName,
                        )}
                    >
                        {items}
                    </Popper>
                }
            </div>
        </Manager>

    )
};

export default Dropdown;