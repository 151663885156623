import React from 'react';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { campaignCopyright } from 'constants/campaignCopyright';
import {AI_BOOSTED_SALES, DYNAMIC_RETARGETING} from 'constants/campaign';

import CreateRetargetingCampaign from '../CreateRetargetingCampaign';

export const CreateAiBoostedSalesCampaign = () => (
    <CreateRetargetingCampaign
        campaignDescription={campaignCopyright.ai_boosted_sales.description}
        campaignTitle={campaignCopyright.ai_boosted_sales.title}
        adMessageTipText={campaignCopyright.ai_boosted_sales.adMessageTipText}
        onboardingType={ONBOARDING_TYPES.facebook_ai_boosted_sales}
        campaignType={AI_BOOSTED_SALES}
        onboardingStatus={UBM_ONBOARDING_STATES.create_ai_boosted_sales_campaign}
        campaignAdMessage={'Buy {{product.name}} for only {{product.price}}. Get it before it\'s sold out!'}
        isGenderVisible={false}
        isAgeVisible={false}
    />
);
