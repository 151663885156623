import * as pages from 'actions/facebook/pages';

const initialState = {
    entity: null,
    pending: false,
    success: false,
    failure: false,
    error: null,
};

export default (state = { ...initialState }, action) => {
    switch (action.type) {
        case pages.FB_PAGE_CONNECT_REQUEST:
            state = {
                ...initialState,
                pending: true,
            };
            break;

        case pages.FB_PAGE_CONNECT_SUCCESS:
            state = {
                ...initialState,
                entity: action.payload,
                success: true,
            };
            break;

        case pages.FB_PAGE_CONNECT_FAILURE:
            state = {
                ...initialState,
                error: action.payload,
                failure: true,
            };
            break;

        default:
            break;
    }
    return state;
};
