import React, { PureComponent } from 'react';

import 'containers/App.css';
import Header from 'components/Header';

import getInstallLink from '../utils';

import './NoShops.css';

class NoShops extends PureComponent {
    render() {
        const {
            location: {
                query: { shop },
            },
        } = this.props;

        return (
            <div className="no-shops-wrapper">
                <Header />
                <section className="no-shops-section">
                    <div className="no-shops-section__container">
                        <h1 className="no-shops-section__title">Log in</h1>
                        <p className="no-shops-section__description">
                            We can’t find any Shopify-based store connected to the Facebook profile you are currently
                            logged into.
                        </p>
                        <a
                            className="no-shops-section__buttton"
                            href={getInstallLink(shop.platform_id, shop.type)}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Log in with Shopify
                        </a>
                    </div>
                </section>
            </div>
        );
    }
}

export default NoShops;
