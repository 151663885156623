import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS } from 'constants/requestStatuses';

const BillingInfoTableBody = ({ data, billingInfoState, currency, isStripePayment, currentShop }) => {
    let billingInfoTableBody = (
        <tr>
            <td>—</td>
            <td className='align-center'>—</td>
            <td className='align-center'>—</td>
            {!isStripePayment && <td className='align-right'>—</td>}
        </tr>
    );

    if (billingInfoState === REQUEST_STATUS_PENDING) {
        billingInfoTableBody = (
            <tr>
                <td className="loading" colSpan="4" />
            </tr>
        );
    }

    const downloadIcon = (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75 15H14.25V13.5H3.75V15ZM14.25 6.75H11.25V2.25H6.75V6.75H3.75L9 12L14.25 6.75Z" fill="#758896"/>
        </svg>

    );

    if (billingInfoState === REQUEST_STATUS_SUCCESS && data && data.length) {
        billingInfoTableBody = data.map((item) => (
            <tr key={item.billing_period_date.toString()}>
                <td>{item.billing_period_date}</td>
                <td className='align-center'>
                    <p className="settings-page-info__content_capitalised">
                        {item.subscription_plan_name}
                    </p>
                </td>
                <td className='align-center'>
                    {item.subscription_plan_fee} {currency}
                </td>
                <td className='align-right'>
                    {item.overspends_extra_fine === 0 ? '---' : `${item.overspends_extra_fine} ${currency}`}
                </td>
                {!isStripePayment &&
                    <td className='align-right'>
                        <a
                            href={`https://${currentShop.domain}/admin/settings/billing`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {downloadIcon}
                        </a>
                    </td>
                }
            </tr>
        ));
    }

    return (
        <Table className="styled-table styled-table_top-bottom-border styled-table--billing-history">
            <thead>
                <tr>
                    <th>Billing period</th>
                    <th className='align-center'>Subscription plan</th>
                    <th className='align-center'>Plan fee</th>
                    <th className='align-right'>*10% fee on overspend</th>
                    {isStripePayment &&
                        <th className='align-right' />
                    }
                </tr>
            </thead>
            <tbody>{billingInfoTableBody}</tbody>
        </Table>
    );
};

BillingInfoTableBody.defaultProps = {
    data: [],
    billingInfoState: null,
};

BillingInfoTableBody.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            subscription_plan_fee: PropTypes.number.isRequired,
            subscription_plan_name: PropTypes.string.isRequired,
            overspends_extra_fine: PropTypes.number.isRequired,
            billing_period_date: PropTypes.string.isRequired,
            billing_period_id: PropTypes.number.isRequired
        }),
    ),
    billingInfoState: PropTypes.string,
    isStripePayment: PropTypes.bool.isRequired
};

export default BillingInfoTableBody;
