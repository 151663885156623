import * as onboarding from '../../actions/onboarding';

const initialState = {
    shop: null,
    shopRequesting: false,
    shopRequestError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case onboarding.ONBOARDING_INSTALL_SHOP_REQUEST:
            state = {
                ...state,
                shopRequesting: true,
            };
            break;

        case onboarding.ONBOARDING_INSTALL_SHOP_SUCCESS:
            state = {
                ...state,
                shop: action.payload,
                shopRequesting: false,
                shopRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_INSTALL_SHOP_FAILURE:
            state = {
                ...state,
                shopRequesting: false,
                shopRequestError: true,
            };
            break;

        default:
            break;
    }
    return state;
};
