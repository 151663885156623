import * as onboarding from '../../actions/onboarding';

const initialState = {
    chosenAdAccountId: '',
    fbAdAccountConnectRequesting: false,
    fbAdAccountConnectRequestSuccess: false,
    fbAdAccountConnectRequestError: false,

    connectStatus: '',
    fbAdAccountConnectStatusRequesting: false,
    fbAdAccountConnectStatusRequestSuccess: false,
    fbAdAccountConnectStatusRequestError: false,

    fbAdAccountRemoveRequesting: false,
    fbAdAccountRemoveRequestSuccess: false,
    fbAdAccountRemoveRequestError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CHOSEN:
            state = {
                ...state,
                chosenAdAccountId: action.payload,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_CHOSEN:
            state = {
                ...state,
                chosenAdAccountId: '',
                connectStatus: '',
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST:
            state = {
                ...state,
                fbAdAccountConnectRequesting: true,
                fbAdAccountConnectRequestSuccess: false,
                fbAdAccountConnectRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_SUCCESS:
            state = {
                ...state,
                connectStatus: action.connectStatus,
                fbAdAccountConnectRequesting: false,
                fbAdAccountConnectRequestSuccess: true,
                fbAdAccountConnectRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_REQUEST_FAILURE:
            state = {
                ...state,
                fbAdAccountConnectRequesting: false,
                fbAdAccountConnectRequestSuccess: false,
                fbAdAccountConnectRequestError: true,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST:
            state = {
                ...state,
                fbAdAccountConnectStatusRequesting: true,
                fbAdAccountConnectStatusRequestSuccess: false,
                fbAdAccountConnectStatusRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_SUCCESS:
            state = {
                ...state,
                connectStatus: action.connectStatus,
                fbAdAccountConnectStatusRequesting: false,
                fbAdAccountConnectStatusRequestSuccess: true,
                fbAdAccountConnectStatusRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECT_STATUS_REQUEST_FAILURE:
            state = {
                ...state,
                fbAdAccountConnectStatusRequesting: false,
                fbAdAccountConnectStatusRequestSuccess: false,
                fbAdAccountConnectStatusRequestError: true,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_CONNECTED:
            state = {
                ...state,
                fbAdAccountConnectStatusRequesting: false,
                fbAdAccountConnectStatusRequestSuccess: false,
                fbAdAccountConnectStatusRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST:
            state = {
                ...state,
                fbAdAccountRemoveRequesting: true,
                fbAdAccountRemoveRequestSuccess: false,
                fbAdAccountRemoveRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_SUCCESS:
            state = {
                ...state,
                fbAdAccountRemoveRequesting: false,
                fbAdAccountRemoveRequestSuccess: true,
                fbAdAccountRemoveRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_AD_ACCOUNT_REMOVE_REQUEST_FAILURE:
            state = {
                ...state,
                fbAdAccountRemoveRequesting: false,
                fbAdAccountRemoveRequestSuccess: false,
                fbAdAccountRemoveRequestError: true,
            };
            break;

        default:
            break;
    }

    return state;
};
