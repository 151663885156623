import React from 'react';

const LoginErrorMessageDecline = ({ loginLinkError, loginLink }) => (
    <div>
        <p className="alert-description__item">
            We need your Facebook profile to associate you with a Adwisely account and view the list of pages you are
            the admin of. The profile won’t be displayed in ads.
        </p>
        <p className="alert-description__item">
            {loginLinkError ? null : (
                <a href={loginLink} className="alert-description__link_highlighted">
                    Try again
                </a>
            )}{' '}
            to grant all required permissions to use Adwisely.
        </p>
    </div>
);

export default LoginErrorMessageDecline;
