import React from 'react';
import classNames from 'classnames';

import './icons.css';

export const PenIcon = ({ className }) => <i className={classNames('pen-icon', className)} />;
export const ArrowLeft = () => <i className="arrow-left-icon" />;
export const ArrowRight = () => <i className="arrow-right-icon" />;
export const Plus = () => <i className="plus-icon" />;
export const Caret = ({ white }) => <i className={classNames('caret-icon', { 'caret-icon_white': white })} />;
export const Close = ({ white }) => <i className={classNames('close-icon', { 'close-icon_white': white })} />;
export const Info = ({ id, className }) => <i id={id} className={classNames('info-icon', className)} />;
export const BlueInfo = ({ id, className }) => <i id={id} className={classNames('blue-info-icon', className)} />;
export const Warning = ({ id, className }) => <i id={id} className={classNames('warning-icon', className)} />;

export default PenIcon;
