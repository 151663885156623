import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GoogleStandardAdPreviewCard from './GoogleStandardAdPreviewCard';

import './GoogleStandardAdPreview.css';

const GoogleStandardAdPreview = ({ googleStandardAdPreviewData, campaignId }) => {
    const currentCampaignAdPreview = googleStandardAdPreviewData.filter(
        (adPreview) => adPreview.campaign_id === campaignId,
    );

    return (
        <div className="ad-preview-standard">
            <span>Ad Preview</span>
            <p>Standard Shopping Ads are created automatically and can’t be edited</p>
            <div className="ad-preview-standard__wrap">
                {currentCampaignAdPreview.slice(0, 3).map((adPreview, index) => (
                    <GoogleStandardAdPreviewCard
                        key={index}
                        imageLink={adPreview.image_link}
                        title={adPreview.title}
                        currency={adPreview.currency}
                        price={adPreview.price}
                        shopName={adPreview.shop_name}
                    />
                ))}
            </div>
        </div>
    );
};

GoogleStandardAdPreview.propTypes = {
    campaignId: PropTypes.number.isRequired,
    googleStandardAdPreviewData: PropTypes.arrayOf(PropTypes.shape({
        image_link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        shop_name: PropTypes.string.isRequired,
        campaign_id: PropTypes.number.isRequired,
    })).isRequired,
};

const mapStateToProps = ({ googleStandardAdPreview }) => ({
    googleStandardAdPreviewData: googleStandardAdPreview.googleStandardAdPreviewData,
});

export default connect(mapStateToProps)(GoogleStandardAdPreview);
