import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { render } from 'react-dom';
import { browserHistory as routerHistory } from 'react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import Raven from 'raven-js';

import Root from './containers/Root';
import ManualRedirectFromIframe from './components/ManualRedirectFromIframe';
import configureStore from './store/configureStore';
import intercomInitScript from './libs/intercomScriptInit';
import intercom from './libs/intercom';
import inIframe from './libs/inIframe';
import fbSDK from './libs/fbSDK';
import './libs/windowLocationPolifill';
import { SENTRY_URL } from './libs/sentryConfig';
import { environment_name, release_version } from './constants/environment';

const preloadedState = {};

Raven.config(SENTRY_URL, {
    release: release_version,
    environment: environment_name,
    ignoreErrors: ['authorization-required'],
}).install();

const store = configureStore({ preloadedState, middlewares: [routerMiddleware(routerHistory)] });
const history = syncHistoryWithStore(routerHistory, store);

window.addEventListener('storage', (e) => {
    if (e.key === 'RA_LOGOUT') {
        window.location.reload();
    }
});

fbSDK();
intercomInitScript();
intercom.boot();

if (inIframe()) {
    intercom.trackEvent({ type: 'App opened in iframe' });
    try {
        intercom.trackEvent({ type: 'App is trying to redirect from iframe' });
        render(<Root store={store} history={history} />, document.getElementById('root'));
    } catch (e) {
        Raven.captureException(e);
        render(<ManualRedirectFromIframe url={window.self.location.href} />, document.getElementById('root'));
    }
} else {
    render(<Root store={store} history={history} />, document.getElementById('root'));
}
