import React from 'react';

import './AdSpendProgressBar.css';
import { useAdSpendProgressBarData } from './hooks/useAdSpendProgressBarData';
import TipsWidget from '../TipsWidget';

const Arrow = () => (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.68311 4.41064C9.68311 4.24561 9.60693
                4.08057 9.47998 3.95996L6.22998 0.709961C6.09033 0.583008
                5.94434 0.519531 5.79199 0.519531C5.44922 0.519531 5.19531 0.76709
                5.19531 1.10352C5.19531 1.2749 5.26514 1.4209 5.37305
                1.53516L6.30615 2.48096L7.84863 3.88379L6.67432 3.80762H0.885254C0.51709
                3.80762 0.269531 4.05518 0.269531 4.41064C0.269531 4.76611 0.51709 5.02002
                0.885254 5.02002H6.67432L7.85498 4.9375L6.30615 6.34668L5.37305 7.28613C5.26514
                7.40039 5.19531 7.54639 5.19531 7.71777C5.19531 8.0542 5.44922 8.30176 5.79199
                8.30176C5.94434 8.30176 6.09033 8.23828 6.22363 8.11133L9.47998 4.86133C9.60693
                4.74072 9.68311 4.57568 9.68311 4.41064Z" fill="currentColor"
        />
    </svg>
)

export const AdSpendProgressBar = ({ shopId }) => {
    const {totalSpends, currentSpends, color, percents} = useAdSpendProgressBarData(shopId);
    return (
        <div className='spend-progress-bar'>
            <div className="spend-progress-bar__title">Ad spend</div>
            <div className="spend-progress-bar__content">
                <div className="spend-progress-bar__spends">
                    ${currentSpends} / ${totalSpends}
                    <div className="spend-progress-bar__percents">({percents}%)</div>
                </div>
                <div>
                    <TipsWidget
                        placement='bottom'
                    >
                        <div className='spends-progress-bar__tooltip'>
                            <p>
                                Once you reach the $300 ad spend limit, your ads will be paused until the beginning of the next 30-day billing period.
                            </p>
                            <a
                                href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-adwisely"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>Learn more</span>
                                <Arrow />
                            </a>
                        </div>
                    </TipsWidget>
                </div>

            </div>
            <div className="spend-progress-bar__line">
                <div className="spend-progress-bar__progress-line"
                    style={{
                        width: `${percents}%`,
                        background: color
                    }}
                />
            </div>
        </div>
        )
}