import {
    callApiGetUserStatusInChildBM,
    callApiAddUserToChildBM,
    callAPIUserBMList,
} from './api';

export const GET_USER_STATUS_IN_CHILD_BM_REQUEST = 'GET_USER_STATUS_IN_CHILD_BM_REQUEST';
export const GET_USER_STATUS_IN_CHILD_BM_SUCCESS = 'GET_USER_STATUS_IN_CHILD_BM_SUCCESS';
export const GET_USER_STATUS_IN_CHILD_BM_FAILURE = 'GET_USER_STATUS_IN_CHILD_BM_FAILURE';

export const requestGetUserStatusInChildBM = bmId => callApiGetUserStatusInChildBM({
    types: [
        GET_USER_STATUS_IN_CHILD_BM_REQUEST,
        GET_USER_STATUS_IN_CHILD_BM_SUCCESS,
        GET_USER_STATUS_IN_CHILD_BM_FAILURE,
    ],
    params: {
        bmId,
    },
});

export const ADD_USER_TO_CHILD_BM_REQUEST = 'ADD_USER_TO_CHILD_BM_REQUEST';
export const ADD_USER_TO_CHILD_BM_SUCCESS = 'ADD_USER_TO_CHILD_BM_SUCCESS';
export const ADD_USER_TO_CHILD_BM_FAILURE = 'ADD_USER_TO_CHILD_BM_FAILURE';

export const requestAddUserToChildBM = bmId => callApiAddUserToChildBM({
    types: [
        ADD_USER_TO_CHILD_BM_REQUEST,
        ADD_USER_TO_CHILD_BM_SUCCESS,
        ADD_USER_TO_CHILD_BM_FAILURE,
    ],
    params: {
        bmId,
    },
});

export const GET_USER_BM_LIST_REQUEST = 'GET_USER_BM_LIST_REQUEST';
export const GET_USER_BM_LIST_SUCCESS = 'GET_USER_BM_LIST_SUCCESS';
export const GET_USER_BM_LIST_FAILURE = 'GET_USER_BM_LIST_FAILURE';

export const requestGetUserBMList = (shopId, onboardingType) => callAPIUserBMList(shopId, onboardingType, {
    types: [
        GET_USER_BM_LIST_REQUEST,
        GET_USER_BM_LIST_SUCCESS,
        GET_USER_BM_LIST_FAILURE,
    ],
});
