import React from 'react';
import { connect } from "react-redux";
import { replace } from "react-router-redux";

import { getLocalStorageObjectItem } from 'helpers';
import { ONBOARDING_TYPES } from "constants/onboarding";
import { changeOnboardingState } from "modules/home/utils";
import { facebookStatePolling } from "modules/onboarding/userBMFlow/helpers/ubmStatePolling";
import { StartFreeTrialView } from "./StartFreeTrialView/StartFreeTrialView";
import {UBM_ONBOARDING_STATES} from "../../../../../constants/ubmOnboarding";

const StartFreeTrial = ({currentShopId, replace}) => {
    const onStartFreeTrialBtnClick = () => {
        const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.start_free_trial,
            currentShopId: currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
    }

    return <StartFreeTrialView onStartTrialBtnClick={onStartFreeTrialBtnClick} />
}

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops.currentShopId,
})

const mapDispatchToProps = {
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartFreeTrial)