import PropTypes from "prop-types"
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { requestEditGoogleCampaign, requestGoogleCampaigns, goToDashboard } from 'actions/campaign';
import { showBulkNotification, displayBulkNotification } from 'actions/notification';

import pageNameSetter from 'decorator/pageNameSetter';

import { getBulkNotificationContentSelector, getBulkNotificationTypeSelector } from 'helpers/selectors';

import BulkNotification from 'components/BulkNotification';
import TipsWidget from 'components/TipsWidget';
import { CardTitle } from 'components/Card';

import { changeShopPage } from 'constants/metric';

import { CampaignPageLayout, CampaignPageCol } from '../components/CampaignPageLayout';
import CampaignInfo from '../components/CampaignInfo/index';
import GoogleStandardAdPreviewCard from '../components/GoogleStandardAdPreview/GoogleStandardAdPreviewCard';
import EditCampaignDetails from '../components/EditCampaignDetails/index';
import EditStandardAdsForm from '../components/EditStandardAdsForm';

import './StandardAdsEdit.css';

export class StandardAdsEdit extends Component {
    state = {
        // save campaign details
        hasCampaignFormError: false,
        tipsErrorText: '',

        // default values
        campaignMinBudget: 25,
        campaignCurrency: 'USD',
        campaignDailyBudget: this.props.campaign ? this.props.campaign.daily_budget / 100 : 0,
    };

    saveCampaign = (dailyBudget) => {
        const {
            requestEditGoogleCampaign,
            requestGoogleCampaigns,
            goToNextStep,
            params: { shopId, id },
        } = this.props;

        const params = {
            campaignId: +id,
            shopId,
            daily_budget: dailyBudget,
        };

        requestEditGoogleCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                this.props.showBulkNotification('Campaign settings updated.');
                requestGoogleCampaigns(shopId);
                goToNextStep(shopId);
            } else {
                this.props.displayBulkNotification('Campaign edit failure!', 'error');
            }
        });
    };

    handleFormError = (hasCampaignFormError) => {
        this.setState({
            hasCampaignFormError,
        });
    };

    render() {
        const {
            campaignEditPending,
            params: { shopId, id },
            bulkNotificationContent,
            bulkNotificationType,
            googleStandardAdPreviewData,
        } = this.props;
        const { campaignCurrency, campaignMinBudget, campaignDailyBudget } = this.state;

        let bulkNotification = null;

        if (bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        const currentCampaignAdPreview = googleStandardAdPreviewData.filter(
            (adPreview) => adPreview.campaign_id === +id,
        );

        return (
            <div className="standard-ads-edit__wrapper">
                <CampaignPageLayout>
                    <CampaignPageCol>
                        <EditStandardAdsForm
                            formName="standardAdsEdit"
                            campaignCurrency={campaignCurrency}
                            campaignMinBudget={campaignMinBudget}
                            campaignDailyBudget={campaignDailyBudget}
                            disabled={campaignEditPending}
                            saveCampaign={this.saveCampaign}
                            handleFormError={this.handleFormError}
                        />

                        <CampaignInfo
                            showPricing={false}
                            showIcon={false}
                            campaignTitle="Smart Shopping Ads"
                            learnMoreLink="https://help.adwisely.com/en/articles/3343845-smart-shopping"
                            campaignDescription="Re-engage your site visitors who didn’t make a purchase with personalized ads of the previously viewed products."
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <div className="standard-ads-edit__ad-preview">
                            <CardTitle className="campaign-details__title" labelFor="campaignAdPreview" subTitle>
                                <span>Ad Preview</span>
                                <TipsWidget placement="right" isDarkView>
                                    <p className="tips-description__content">
                                        It is just an example of an ad preview to give you an idea of how the ad will
                                        look like. Adwisely will show each user the product he/she is most likely to
                                        purchase.
                                    </p>
                                </TipsWidget>
                            </CardTitle>
                            <p>Please, contact our support team if you want to change the image of the ad</p>
                            <div className="ad-preview-standard__wrap">
                                {currentCampaignAdPreview &&
                                    currentCampaignAdPreview
                                        .slice(0, 3)
                                        .map((adPreview, index) => (
                                            <GoogleStandardAdPreviewCard
                                                key={index}
                                                imageLink={adPreview.image_link}
                                                title={adPreview.title}
                                                currency={adPreview.currency}
                                                price={adPreview.price}
                                                shopName={adPreview.shop_name}
                                            />
                                        ))}
                            </div>
                        </div>
                    </CampaignPageCol>
                </CampaignPageLayout>
                <EditCampaignDetails
                    formName="standardAdsEdit"
                    hasCampaignFormError={this.state.hasCampaignFormError}
                    shopId={shopId}
                    campaignEditPending={campaignEditPending}
                    tipsErrorText={this.state.tipsErrorText}
                />
                {bulkNotification}
            </div>
        );
    }
}

StandardAdsEdit.defaultProps = {
    bulkNotificationContent: null,
    bulkNotificationType: null,
    campaign: null,
    googleStandardAdPreviewData: [],
};

StandardAdsEdit.propTypes = {
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    campaign: PropTypes.shape({
        daily_budget: PropTypes.number,
    }),
    campaignEditPending: PropTypes.bool.isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    googleStandardAdPreviewData: PropTypes.arrayOf(
        PropTypes.shape({
            campaign_id: PropTypes.number,
        }),
    ),
    requestEditGoogleCampaign: PropTypes.func.isRequired,
    requestGoogleCampaigns: PropTypes.func.isRequired,
    showBulkNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    campaign: state.googleCampaign.googleCampaignData,
    campaignAd:
        state.googleCampaign.googleCampaignData &&
        state.googleCampaign.googleCampaignData.ads.find(
            (ad) => ad.type === state.googleCampaign.googleCampaignData.default_ad_type,
        ),
    campaignEditPending: state.googleCampaigns.googleCampaignsData.find((el) => el.id === +ownProps.params.id)
        .campaignEditPending,
    bulkNotificationContent: getBulkNotificationContentSelector(state),
    bulkNotificationType: getBulkNotificationTypeSelector(state),
    googleStandardAdPreviewData: state.googleStandardAdPreview.googleStandardAdPreviewData,
});

const mapDispatchToProps = {
    requestEditGoogleCampaign,
    requestGoogleCampaigns,
    showBulkNotification,
    displayBulkNotification,
    goToNextStep: goToDashboard,
};

const StandardAdsEditContainer = connect(mapStateToProps, mapDispatchToProps)(StandardAdsEdit);

export default pageNameSetter(changeShopPage.edit_google_ads)(StandardAdsEditContainer);
