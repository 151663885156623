export const ERROR_COMMON = 'ERROR_COMMON';

export const REMOVE_ERROR = 'REMOVE_ERROR';

export const commonError = err => ({
    type: ERROR_COMMON,
    payload: err,
    error: true,
});

export const removeError = err => ({
    type: REMOVE_ERROR,
    payload: err,
    error: true,
});
