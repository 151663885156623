import React from 'react';
import PropTypes from 'prop-types';

import PricingWidgetItem from './PricingWidgetItem';
import './PricingWidget.css';

const PricingWidget = (props) => {
    const { pricingItemTitle, description, learnMoreLink, pricingItems } = props;
    return (
        <div>
            <div className="pricing-widget">
                <h3 className="pricing-widget__title">Pricing</h3>

                <div className="pricing-widget__content">
                    <p className="pricing-widget-content__description">{description}</p>

                    <a className="link with-arrow" href={learnMoreLink} rel="noopener noreferrer" target="_blank">
                        <span>I want to learn more</span> &#x2192;
                    </a>
                    {pricingItems.map((pricingItem, index) => (
                        <PricingWidgetItem
                            key={index}
                            fee={pricingItem.fee}
                            adSpend={pricingItem.adSpend}
                            pricingItemTitle={pricingItemTitle}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

PricingWidget.defaultProps = {
    pricingItemTitle: 'Retargeting Ad Spend',
    description:
        'Adwisely fee is based on your Monthly Retargeting Ad Spend and is not less than $15.00 USD per month.',
    learnMoreLink:
        'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/whats-the-cost-of-retargetapp',
    pricingItems: [
        {
            fee: '10',
            adSpend: '150',
        },
        {
            fee: '8',
            adSpend: '1,000',
        },
        {
            fee: '5',
            adSpend: '3,000',
        },
    ],
};

PricingWidget.propTypes = {
    pricingItemTitle: PropTypes.string,
    description: PropTypes.string,
    learnMoreLink: PropTypes.string,
    pricingItems: PropTypes.arrayOf(
        PropTypes.shape({
            fee: PropTypes.string,
            adSpend: PropTypes.string,
        }),
    ),
};

export default PricingWidget;
