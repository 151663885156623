export const useFacebookBillingSession = () => {
    const wasPreviewPaymentShowed = JSON.parse(sessionStorage.getItem('wasPreviewPaymentShowed') || 'false');

    const savePreviewPaymentStatus = () => sessionStorage.setItem('wasPreviewPaymentShowed', 'true');

    return {
        savePreviewPaymentStatus,
        wasPreviewPaymentShowed,
    };
};
