import {callAPIFBPages, callAPIConnectFBPage} from './../api';

export const FB_PAGES_REQUEST = 'FB_PAGES_REQUEST';
export const FB_PAGES_REQUEST_SUCCESS = 'FB_PAGES_REQUEST_SUCCESS';
export const FB_PAGES_REQUEST_FAILURE = 'FB_PAGES_REQUEST_FAILURE';


export const FB_PAGES_PENDING = 'FB_PAGES_PENDING';
export const FB_PAGES_SUCCESS = 'FB_PAGES_SUCCESS';
export const FB_PAGES_FAILURE = 'FB_PAGES_FAILURE';

export const requestFBPages = (params = {}, withLoader = true) => (dispatch) => {
    let types = [
        FB_PAGES_PENDING,
        FB_PAGES_SUCCESS,
        FB_PAGES_FAILURE,
    ];

    if (withLoader) {
        types = [
            FB_PAGES_REQUEST,
            FB_PAGES_REQUEST_SUCCESS,
            FB_PAGES_REQUEST_FAILURE,
        ];
    }

    return dispatch(callAPIFBPages({
        params,
        types: types,
    }));
};

export const FB_PAGE_CONNECT_REQUEST = 'FB_PAGE_CONNECT_REQUEST';
export const FB_PAGE_CONNECT_SUCCESS = 'FB_PAGE_CONNECT_SUCCESS';
export const FB_PAGE_CONNECT_FAILURE = 'FB_PAGE_CONNECT_FAILURE';

export const requestFBPageConnect = (params = {}) => dispatch => dispatch(callAPIConnectFBPage({
    params,
    types: [
        FB_PAGE_CONNECT_REQUEST,
        FB_PAGE_CONNECT_SUCCESS,
        FB_PAGE_CONNECT_FAILURE,
    ],
}));
