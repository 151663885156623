import React from 'react';

import './NotificationWidget.css';

const NOTIFICATION_TYPES = {
    info: 'notification__wrapper_info',
    success: 'notification__wrapper_success',
    warning: 'notification__wrapper_warning',
    danger: 'notification__wrapper_danger',
};

/**
 *
 * @param type {array}
 * @param children {object}
 * @constructor
 */
const NotificationWidget = ({ type, children }) => (
    <div className={`notification__wrapper ${NOTIFICATION_TYPES[type] ? NOTIFICATION_TYPES[type] : ''}`}>
        {children}
    </div>
);

export default NotificationWidget;
