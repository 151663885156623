import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS } from 'constants/requestStatuses';
import { toFloatNumberWithCurrency } from 'helpers';

import getInstallIframeLink from '../../../../bigcommerce/utils';

const NewBillingInfoTableBody = ({
    data,
    billingInfoState,
    currency,
    shopId,
    shopTypeBigCommerceOrWooCommerce,
    sendStripeMetrics,
}) => {
    let billingInfoTableBody = (
        <tr>
            <td className="billing-period">—</td>
            <td className="billing-plan">—</td>
            <td className="fee-paid">—</td>
            {shopTypeBigCommerceOrWooCommerce && <td className="download-receipt">-</td>}
        </tr>
    );

    if (billingInfoState === REQUEST_STATUS_PENDING) {
        billingInfoTableBody = (
            <tr>
                <td className="loading" colSpan="3" />
            </tr>
        );
    }

    if (billingInfoState === REQUEST_STATUS_SUCCESS && data && data.length) {
        billingInfoTableBody = data.map((item) => (
            <tr key={item.billing_period_date}>
                <td className="billing-period">{item.billing_period_date}</td>
                <td className="fee-paid">{toFloatNumberWithCurrency(item.fee_paid, currency)}</td>

                {shopTypeBigCommerceOrWooCommerce && (
                    <td className="download-receipt">
                        <a
                            onClick={sendStripeMetrics}
                            href={`${getInstallIframeLink()}/api/shop/${shopId}/invoice/${item.billing_period_id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Download receipt
                        </a>
                    </td>
                )}
            </tr>
        ));
    }

    return (
        <Table
            className={`styled-table styled-table_top-bottom-border
        ${
        shopTypeBigCommerceOrWooCommerce
            ? 'bigcommerce-or-woocommerce-billing-info__table'
            : 'shopify-billing-info__table'
        }`}
        >
            <thead>
                <tr>
                    <th className="billing-period">Billing period</th>
                    <th className="fee-paid">The amount of fee paid</th>
                    {shopTypeBigCommerceOrWooCommerce && <th className="download-receipt">Receipt</th>}
                </tr>
            </thead>
            <tbody>{billingInfoTableBody}</tbody>
        </Table>
    );
};

NewBillingInfoTableBody.defaultProps = {
    data: [
        {
            billing_period_date: '',
            billing_plan_name: '',
            fee_paid: '',
        },
    ],
    billingInfoState: null,
};

NewBillingInfoTableBody.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            billing_period_date: PropTypes.string,
            billing_plan_name: PropTypes.string,
            fee_paid: PropTypes.string,
        }),
    ),
    billingInfoState: PropTypes.string,
};

export default NewBillingInfoTableBody;
