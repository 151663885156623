export const fetchUserAccount = (setIsLoading, setEmail) => (
    fetch('/api/account', {
        method: 'GET'
    })
        .then((res) => res.json())
        .then((data) => {
            setIsLoading(false);
            setEmail(data.data.email)}
        )
);
