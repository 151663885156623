import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { ONBOARDING_STEPS } from 'constants/googleOnboarding';
import { setGoogleOnboardingState } from 'actions/googleOnboardingState';
import WizardStatusBar from 'modules/onboarding/components/WizardStatusBar';

import { Icon } from './components/Icon';
import { Tips } from './components/Tips';
import { LoaderLine } from './components/LoaderLine';

import './error-screen.styles.css';
import {googleStatePolling} from '../../onboarding/google/helpers/googleStatePolling';

const ProcessingScreenView = ({ replace, currentShopId, setGoogleOnboardingState, googleState }) => {
    const [loader, setLoader] = useState();

    useEffect(() => {
        googleStatePolling(currentShopId, replace, setGoogleOnboardingState, setLoader)
    }, [])

    return (
        <>
            <div className="error-screen__wrapper">
                <WizardStatusBar state={ONBOARDING_STEPS[googleState] || googleState} isCreateCampaignStep={false} />
                <LoaderLine loader={loader} />
                <Icon />
                <h2 className="error-screen__caption">Give us a minute :)</h2>
                <p className="error-screen__text">
                    We are still creating necessary ad assets. No worries - <br /> it shouldn’t take more than 30
                    seconds.
                </p>
                <div className="error-screen__tips">
                    <Tips />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ shops, google }) => ({
    currentShopId: shops?.currentShopId,
    googleState: google.googleOnboardingState
});

const mapPropsToDispatch = {
    replace,
    setGoogleOnboardingState,
};

export const ProcessingScreen = connect(mapStateToProps, mapPropsToDispatch)(ProcessingScreenView);
