import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';

import SubscriptionPlan from 'modules/onboarding/components/SubscriptionPlan';

import { googleStatePolling } from 'modules/onboarding/google/helpers/googleStatePolling';
import { setGoogleOnboardingState } from 'actions/googleOnboardingState';
import { changeOnboardingState } from 'modules/home/utils';

const SubscriptionPlanGoogleView = ({currentShopId, replace, setGoogleOnboardingState}) => {
    const onSubscriptionPlanSelectSuccess = () => {
        changeOnboardingState({
            type: ONBOARDING_TYPES.google,
            status: ONBOARDING_STATES.choose_subscription_plan,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    googleStatePolling(currentShopId, replace, setGoogleOnboardingState)
                }
                return res.json()
            })
            .then((res) => {
                if (res?.processing) {
                    googleStatePolling(currentShopId, replace, setGoogleOnboardingState)
                }
            });
    }

    return (
        <SubscriptionPlan
            onSubscriptionPlanSelectSuccess={onSubscriptionPlanSelectSuccess}
        />
    )
};

const mapStateToProps = ({ shops }) => (
    {
        currentShopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export const SubscriptionPlanGoogle = connect(mapStateToProps, mapPropsToDispatch)(SubscriptionPlanGoogleView);