import React, { Component } from 'react';

import { LoaderLongWait as Loader } from 'components/Loader';

export class WooCommerceInstallCallback extends Component {
    componentDidMount() {
        const {
            location: { search },
        } = this.props;

        const queryString = search;

        window.location.replace(`/callbacks/wc${queryString}`);
    }

    render() {
        return <Loader />;
    }
}

export default WooCommerceInstallCallback;
