import React from 'react';
import cx from 'classnames';

import Button from 'components/Button';
import { Caret } from 'components/Icons';
import Dropdown from 'components/Dropdown';
import DropdownProvider from 'components/DropdownProvider';

import { countriesCodes } from './countries-codes';
import { useDropdownCountry, useCountriesFilter } from './hooks';

import './CountriesDropdown.css';


const handleChooseCountry = ({
    setCountry,
    countryCode,
    countryNameEn,
    setCountryCode,
    countryCallingCode,
    setCountryCallingCode,
}) => {
    setCountryCode(countryCode);
    setCountryCallingCode(countryCallingCode);
    setCountry({ name: countryNameEn, code: countryCallingCode });
};

const scrollToSelectedCountry = () => {
    setTimeout(() => {
        if (document.querySelector('.gl-onboarding-dropdown__item_selected')) {
            document.querySelector('.gl-onboarding-dropdown__item_selected').scrollIntoView(false);
        }
    }, 0);
};

export const CountriesDropdown = ({ phone, phoneError, setPhone, setCountryCode, setCountryCallingCode }) => {
    const { country, setCountry } = useDropdownCountry();

    const { resetCountriesFilter, handleCountriesSearch, getSearchedCountries } = useCountriesFilter();
    const searchedCountries = getSearchedCountries(countriesCodes);

    const clearPhoneInput = () => setPhone('');
    const updatePhoneNumber = ({ target }) => {
        setPhone(target?.value);
    };

    return (
        <div
            className={cx('gl-onboarding-countries-dropdown-input-wrapper', {
                'gl-onboarding-countries-dropdown-input-wrapper_error': phoneError,
            })}
        >
            <DropdownProvider>
                {({ toggle, isOpen }) => {
                    const flagToggler = (
                        <Button square disabled={false}>
                            <span className="gl-onboarding-countries-dropdown__country">{country?.name}</span>
                            <Caret />
                        </Button>
                    )

                    const dropdownItems = (
                        searchedCountries?.map(({ countryCode, countryNameEn, countryCallingCode }) => (
                            <div
                                key={countryNameEn}
                                id="dynamicTagProductName"
                                className={cx('gl-onboarding-dropdown__item', {
                                    'gl-onboarding-dropdown__item_selected': countryNameEn === country.name,
                                })}
                                onClick={() => {
                                    toggle()
                                    clearPhoneInput();
                                    handleChooseCountry({
                                        setCountry,
                                        countryCode,
                                        countryNameEn,
                                        setCountryCode,
                                        countryCallingCode,
                                        setCountryCallingCode,
                                    });
                                }}
                            >
                                <span>{countryNameEn}</span>
                                <span className="gl-onboarding-dropdown__item__code">+{countryCallingCode}</span>
                            </div>
                        ))
                    )
                    return (
                        <Dropdown
                            isOpen={isOpen}
                            toggle={(toggleState) => {
                                toggle(toggleState);
                                resetCountriesFilter();
                                scrollToSelectedCountry();
                            }}
                            additionalDropdownClassName={'gl-onboarding-countries-dropdown'}
                            additionalMenuClassName={'ad-message-tag-dropdown__menu gl-onboarding-countries-dropdown-list'}
                            flagToggler={flagToggler}
                            items={
                                <>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        onChange={handleCountriesSearch}
                                        onBlur={({ target }) => target.focus()}
                                        className="gl-onboarding-countries__search"
                                    />
                                    {dropdownItems}
                                </>
                            }
                        />)
                    }}
            </DropdownProvider>

            <div className="gl-onboarding-countries-dropdown__country-code">+{country?.code}</div>
            <input
                type="number"
                value={phone}
                placeholder="000000000"
                onInput={updatePhoneNumber}
                className="gl-onboarding-countries-dropdown__country-input"
            />
        </div>
    );
};
