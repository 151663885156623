import React, { useState } from "react";
import intercom from 'libs/intercom';
import { PopupModal } from 'react-calendly';

import './BookACallSection.css';

export const BookACallSection = ({ intercomLink }) => {
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    return (
        <div className="book-call">
            <h3 className="book-call__title">Need help?</h3>
            <p className="book-call__caption">{
                intercomLink ? 'If you have any issues, please' : 'Our managers will be happy to assist!'
            }</p>
            {intercomLink ? (
                <span
                    className="primary-link in-text"
                    onClick={intercom.show}
                >
                    Contact support
                </span>
            ) : (
                <div>
                    <p
                        className="primary-link in-text"
                        onClick={() => setIsCalendlyOpen(true)}
                    >
                        Book a call
                    </p>
                    <PopupModal
                        url="https://calendly.com/adwisely-support-team/support-team"
                        onModalClose={() => setIsCalendlyOpen(false)}
                        open={isCalendlyOpen}
                        rootElement={document.getElementById("root")}
                    />
                </div>
            )}
        </div>
    )
};
