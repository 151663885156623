import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { SPECIAL_OFFER } from 'constants/campaign';
import { Card, CardTitle, CardContent } from 'components/Card';

import './CampaignInfo.css';
import retargetingIcon from './retargeting-logo-icon.svg';

function getTipsPlacement(smallScreenPlacement, mediumScreenPlacement, bigScreenPlacement) {
    let placement = smallScreenPlacement;
    const screenWidth = window.innerWidth;

    if (screenWidth < 576) {
        placement = smallScreenPlacement;
    }

    if (screenWidth >= 576 && screenWidth <= 768) {
        placement = mediumScreenPlacement;
    }

    if (screenWidth > 768) {
        placement = bigScreenPlacement;
    }

    return placement;
}

export class CampaignInfo extends Component {
    constructor(props) {
        super(props);

        const trialTipsPlacement = getTipsPlacement('left', 'left', 'bottom');
        const pricingTipsPlacement = getTipsPlacement('bottom left', 'right', 'right');

        this.state = {
            trialTipsPlacement,
            pricingTipsPlacement,
        };

        this.handleTrialTipsPlacementOnWindowResize = debounce(
            this.handleTrialTipsPlacementOnWindowResize.bind(this),
            100,
        );
        this.handlePricingTipsPlacementOnWindowResize = debounce(
            this.handlePricingTipsPlacementOnWindowResize.bind(this),
            100,
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleTrialTipsPlacementOnWindowResize);
        window.addEventListener('resize', this.handlePricingTipsPlacementOnWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleTrialTipsPlacementOnWindowResize);
        window.removeEventListener('resize', this.handlePricingTipsPlacementOnWindowResize);
    }

    handleTrialTipsPlacementOnWindowResize() {
        const placement = getTipsPlacement('left', 'left', 'bottom');

        if (placement !== this.state.trialTipsPlacement) {
            this.setState({
                trialTipsPlacement: placement,
            });
        }
    }

    handlePricingTipsPlacementOnWindowResize() {
        const placement = getTipsPlacement('bottom left', 'right', 'right');

        if (placement !== this.state.pricingTipsPlacement) {
            this.setState({
                pricingTipsPlacement: placement,
            });
        }
    }

    render() {
        const { campaignTitle, campaignDescription, titleIconUrl, learnMoreLink, showIcon, linkCaption, campaignType } = this.props;

        return (
            <Card className="campaign-info">
                {showIcon && campaignType !== SPECIAL_OFFER && <img src={titleIconUrl} alt="" className="campaign-info__title-img" />}
                <CardTitle>{campaignTitle}</CardTitle>

                <CardContent>{campaignDescription}</CardContent>

                <CardContent>
                    <a className="link with-arrow" href={learnMoreLink} rel="noopener noreferrer" target="_blank">
                        <span>{linkCaption}</span> &#x2192;
                    </a>
                </CardContent>
            </Card>
        );
    }
}

CampaignInfo.propTypes = {
    campaignDescription: PropTypes.string.isRequired,
    campaignTitle: PropTypes.string,
    learnMoreLink: PropTypes.string,
    linkCaption: PropTypes.string,
    showIcon: PropTypes.bool.isRequired,
    titleIconUrl: PropTypes.string,
};

CampaignInfo.defaultProps = {
    linkCaption: 'I want to learn more',
    campaignTitle: 'Retargeting',
    titleIconUrl: retargetingIcon,
    learnMoreLink: 'https://help.adwisely.com/improve-your-campaign-results/learn-about-advertising-tactics/prospecting',
};

export default CampaignInfo;
