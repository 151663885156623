import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/routes';
import { tokenActionTypes } from 'constants/actionTypes';

import Button from 'components/Button';
import RALogo from 'components/ErrorPage/RA-color-logo.svg';
import IntercomChatCaller from 'components/IntercomChatCaller';
import ErrorPageMonkeyImg from 'components/ErrorPage/errorpage-monkey-img.png';

import intercom from 'libs/intercom';

import './ActionTokenFailure.css';

class ActionTokenFailure extends PureComponent {
    componentDidMount() {
        const goToDashboard = document.getElementById('goToDashboard');
        if (goToDashboard) goToDashboard.focus();
    }

    render() {
        const { action_type, shop_id } = this.props.entity || {};

        let failureTitle = 'Oops! Something is not right';
        let copyright = (
            <span>
                Please, go to Adwisely to apply changes or{' '}
                <IntercomChatCaller className="modal-try-again-body__underline" content="contact us" />.
            </span>
        );
        let errorButton = (
            <Button
                id="goToDashboard"
                innerUrl={`/${shop_id}${ROUTES.home}`}
                className="ad-message-input action-token-failure__button"
            >
                Go to Home Page
            </Button>
        );

        switch (action_type) {
            case tokenActionTypes.VERIFY_EMAIL:
            case tokenActionTypes.CHANGE_USER_EMAIL: {
                failureTitle = 'Sorry, the link you are trying to access is not active anymore.';
                copyright = null;
                errorButton = (
                    <Button
                        id="goToDashboard"
                        innerUrl={`/${shop_id}/settings`}
                        className="ad-message-input action-token-failure__button"
                    >
                        Go to Account Settings
                    </Button>
                );
                break;
            }
            default:
                break;
        }

        return (
            <div className="errorpage__wrapper">
                <div className="errorpage action-token-failure">
                    <div className="errorpage__img">
                        <img src={ErrorPageMonkeyImg} alt="Error page monkey img" />
                    </div>
                    <h2 className="errorpage__title">{failureTitle}</h2>
                    <p className="errorpage__description">{copyright}</p>
                    {errorButton}
                </div>

                <div className="errorpage__ra-logo">
                    <img src={RALogo} alt="RA Logo" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ actionHandler }) => ({
    entity: actionHandler.entity,
});

export default connect(mapStateToProps)(ActionTokenFailure);
