import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import { Card } from "modules/home/components/Card";
import Button from "components/Button";
import { TIK_TOK_ONBOARDING_STATES } from "constants/tikTokOnboarging";

import tikTokIcon from '../../images/tiktok-btn-icon.svg';

const TikTokCardView = ({ state, isBeta, push, currentId, handleStartOnboarding, disableButtons, onClick, isActive, icons, additionalClassName }) => (
        <Card
            additionalClassName={additionalClassName}
            icons={icons}
            onClick={onClick}
            isActive={isActive}
            isBeta={isBeta}
            type={state}
            title="TikTok"
            text="Reach out to new audiences and boost the number of store visitors with short videos on TikTok."
            renderButton={() => (
                <Button
                    disabled={disableButtons}
                    color="primary"
                    className="home__board__block__content__btn"
                    onClick={() => {
                        if (state !== TIK_TOK_ONBOARDING_STATES.done) {
                            push(`/${currentId}/tiktok`)
                            handleStartOnboarding();
                        } else {
                            window.open('https://www.tiktok.com/')
                        }

                    }}
                >
                    <span className="home__board__block__content__btn-icon">
                        <img src={tikTokIcon} alt="TikTok icon"/>
                    </span>
                    {
                      state === TIK_TOK_ONBOARDING_STATES.not_started
                          ? 'Continue with TikTok'
                            : state === TIK_TOK_ONBOARDING_STATES.done
                              ? 'See my results'
                              : 'Continue setup to run Ads'
                    }
                  </Button>
              )}
        />
    );

const mapStateToProps = ( {shops} ) => (
    {
        currentId: shops.currentShopId
    });

const mapDispatchToProps = {
    push
}

export const TikTokCard =  connect(mapStateToProps, mapDispatchToProps)(TikTokCardView);
