import React from 'react';
import classNames from 'classnames';

import './AdAccountDropdown.css';

const dropdownStates = {
    default: 'default',
    selected: 'selected',
};

export const AdAccountDropdown = ({ type, title, openDropdown, renderContent, onClick }) => (
    <>
        <div
            className={classNames(
                dropdownStates[type],
                'verification-dropdown__wrapper',
                {'selected': openDropdown},
            )}
            onClick={onClick}
        >
            {title}
        </div>

        {openDropdown ? <div className="verification-dropdown__content">{renderContent()}</div> : null}
    </>
);
