import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import intercom from 'libs/intercom';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import AlertWidget from 'components/AlertWidget';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import './CreateBM.css';

const CreateBM = ({ currentShopId, replace, onboardings }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(null);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const currentOnboardingState = onboardings.find(onboarding => onboarding.name === currentOnboarding).state;

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES[currentOnboardingState],
            currentShopId,
        })
            .then((res) => res.json())
            .then(res => {
                if (res?.processing) {
                    const resetPage = () => {
                        if (res?.state === UBM_ONBOARDING_STATES.create_bm || res?.state === UBM_ONBOARDING_STATES.still_have_no_bm) {
                            toggleCheckbox();
                            setIsLoading(false);
                        }
                    };
                    facebookStatePolling(currentShopId, replace, resetPage);
                }
            });
    }

    let BMIsMissingAlert = null;

    if (currentOnboardingState === UBM_ONBOARDING_STATES.still_have_no_bm) {
        BMIsMissingAlert = (
            <AlertWidget color="danger">
                <p className="alert-description__item">
                    You still have no Business Manager account
                </p>
            </AlertWidget>
        );
    }

    return (
        <div className="create-bm__wrapper">
            <h1 className="create-bm__title">Create a Business Manager</h1>
            <div className="create-bm__body-wrapper">
                <div>
                    <div className="gl-payment-method__notification">{BMIsMissingAlert}</div>
                    <p className="create-bm__description">
                        Adwisely creates ads inside your Business Manager account.
                    </p>
                    <p className="create-bm__description">
                        Create a {''}
                        <a
                            href="https://business.facebook.com/overview"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Business Manager
                        </a> to continue campaign setup.
                    </p>
                    <p className="create-bm__description">
                        <label htmlFor="verifyBM">
                            <Checkbox onChange={toggleCheckbox} checkboxId="verifyBM" />
                            I have created a Business Manager 
                        </label>
                    </p>
                </div>
                <BookACallSection />
            </div>
            <Button onClick={handleContinueBtn} color="primary" disabled={!isChecked} className="create-bm__button">
                Continue
            </Button>
            <Button onClick={intercom.show}>
                Contact support
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops, onboarding }) => ({
    currentShopId: shops?.currentShopId,
    onboardings: onboarding.onboardingsState.onboardings,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(CreateBM);
