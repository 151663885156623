import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { requestEditCampaign } from 'actions/campaign';
import { displayBulkNotification } from 'actions/notification';

import {AI_BOOSTED_SALES, changeDailyBudgetPage, CUSTOM} from 'constants/campaign';

import { AdMessageValidator } from 'libs/AdMessageValidator';
import { InvalidTagError, ProhibitedWordError, AD_MESSAGE_ERROR_CODE } from 'libs/AdMessageValidatorErrors';

import Button from 'components/Button';
import { PenIcon } from 'components/Icons';
import TipsWidget from 'components/TipsWidget';
import AdMessageInput from 'components/AdMessageInput';

import AdMessageTagsError from '../../campaign/components/AdMessageTagsError';
import AdMessageProhibitedWordsError from '../../campaign/components/AdMessageProhibitedWordsError';

const normalizeCountriesObject = (countries) => {
    const countriesArray = Object.values(countries);
    let resultCountries = '';
    if (countriesArray.length > 1) {
        resultCountries = `${countriesArray.slice(0, countriesArray.length - 1).join(', ')} and ${countriesArray[countriesArray.length - 1]}`;
    } else {
        resultCountries = countriesArray.join('');
    }
    return resultCountries;
}

export class CampaignDetailsAdMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAdMessage: props.adMessage,
            adMessageHasError: false,
            adMessageErrors: [],
            collapseAdMessageInput: false,
            collapseAdMessagePreview: true,
        };

        this.changeAdMessage = this.changeAdMessage.bind(this);
        this.cancelChangeAdMessage = this.cancelChangeAdMessage.bind(this);
        this.onAdMessageFormSubmit = this.onAdMessageFormSubmit.bind(this);
        this.toggleCollapseAdMessage = this.toggleCollapseAdMessage.bind(this);
    }

    onAdMessageFormSubmit() {
        const {
            shop,
            campaignId,
            dailyBudget,
            type,
            editCampaign,
            displayBulkNotification,
            adSetType,
        } = this.props;

        const params = {
            shopId: shop,
            id: campaignId,
            messages: { [adSetType]: this.state.currentAdMessage },
            campaign_type: type,
            daily_budget: dailyBudget,
            set_from_page: changeDailyBudgetPage.dashboard,
        };

        return editCampaign(params).then((res) => {
            if (!(res instanceof Error)) {
                this.toggleCollapseAdMessage();
                displayBulkNotification('Ad message updated.');
            } else {
                displayBulkNotification('Campaign edit failure!', 'error');
            }
        });
    }

    toggleCollapseAdMessage() {
        this.props.toggleCollapseAdMessage();
        this.setState({
            collapseAdMessageInput: !this.state.collapseAdMessageInput,
            collapseAdMessagePreview: !this.state.collapseAdMessagePreview,
        });
    }

    cancelChangeAdMessage() {
        this.toggleCollapseAdMessage();
        this.setState({
            currentAdMessage: this.props.adMessage,
            adMessageHasError: false,
            adMessageErrors: [],
        });
    }

    changeAdMessage(e) {
        const msgValidator = new AdMessageValidator(e.target.value);
        const errors = [];
        const prohibitedWord = msgValidator.getProhibitedWord();
        if (prohibitedWord !== null) {
            errors.push(new ProhibitedWordError(prohibitedWord));
        }
        const invalidTag = msgValidator.getInvalidTag();
        if (invalidTag !== null) {
            errors.push(new InvalidTagError(invalidTag));
        }
        this.setState({
            currentAdMessage: e.target.value,
            adMessageHasError: !msgValidator.isValid(),
            adMessageErrors: errors,
        });
    }

    render() {
        const { campaignEditPending, campaignId, isDisabledEditAdMessageButton, adSetType, adTargetingSettings, shop, type } = this.props;
        const { adMessageHasError, currentAdMessage, collapseAdMessageInput, collapseAdMessagePreview } = this.state;
        const editRequestPending = campaignEditPending || false;
        let adMessagePreview = '';
        if (currentAdMessage) {
            adMessagePreview = currentAdMessage.split(/(\r\n|\n\r|\r|\n)/g).map((line, key) => {
                if (line !== '\n') {
                    return (
                        <p className="dashboard-campaign-admessage-preview__content" key={key}>
                            {line}
                        </p>
                    );
                }
                return '';
            });
        }

        let adMessageErrorAlert = null;
        if (this.state.adMessageHasError && this.state.adMessageErrors.length) {
            const adMessageError = this.state.adMessageErrors[0];
            switch (adMessageError.code) {
                case AD_MESSAGE_ERROR_CODE.INVALID_DYNAMIC_TAG:
                    adMessageErrorAlert = <AdMessageTagsError withTopMargin />;
                    break;
                case AD_MESSAGE_ERROR_CODE.PROHIBITED_WORD:
                    adMessageErrorAlert = <AdMessageProhibitedWordsError withTopMargin word={adMessageError.value} />;
                    break;
                default:
                    break;
            }
        }

        const editAdMessageButton = isDisabledEditAdMessageButton ? (
            ''
        ) : (
            <Button type="button" color="link" onClick={this.toggleCollapseAdMessage}>
                <PenIcon />
                Edit Ad Message
            </Button>
        );

        const formId = `${adSetType}_${campaignId}`;
        const gender = adTargetingSettings ? Object.values(adTargetingSettings.genders).join('') : '';

        return (
            <form
                id={formId}
                onSubmit={(e) => {
                    e.preventDefault();
                    this.onAdMessageFormSubmit();
                }}
            >
                <div className="dashboard-campaign-admessage">
                    <div className="dashboard-campaign__main-title">
                        <span>Ad Message </span>

                        <TipsWidget isDarkView>
                            <p className="tips-description__content">
                                Ad message is a short text displayed in your ad which should convince customers to buy
                                from your store.
                            </p>

                            <p className="tips-description__content">
                                <a
                                    href={
                                        'https://help.adwisely.com/improve-your-campaign-results/' +
                                        'write-the-best-ad-message'
                                    }
                                    className="link link-white"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Learn how to write a great ad message →
                                </a>
                            </p>
                        </TipsWidget>
                    </div>
                    {collapseAdMessagePreview && (
                        <div className="dashboard-campaign-admessage-preview">
                            {adMessagePreview}
                            {this.props.type !== CUSTOM && editAdMessageButton}
                        </div>
                    )}
                    {collapseAdMessageInput && (
                        <div className="dashboard-campaign-admessage-editor">
                            {adMessageHasError ? adMessageErrorAlert : ''}

                            <AdMessageInput
                                wrapperClassName="dashboard-campaign-admessage-editor__input-wrapper"
                                adMessage={this.state.currentAdMessage || ''}
                                name="adMessage"
                                changeAdMessage={this.changeAdMessage}
                                disabled={editRequestPending}
                                error={adMessageHasError}
                                onMountFocus
                            />

                            <div className="dashboard-campaign-admessage-editor__button-group">
                                <Button
                                    form={formId}
                                    className="dashboard-campaign-admessage-editor__cancel"
                                    disabled={editRequestPending}
                                    onClick={this.cancelChangeAdMessage}
                                    color="transparent"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    pending={editRequestPending}
                                    submit
                                    form={formId}
                                    color="primary"
                                    disabled={
                                        editRequestPending ||
                                        adMessageHasError ||
                                        this.props.adMessage === this.state.currentAdMessage
                                    }
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                {(adTargetingSettings && (Object.keys(adTargetingSettings.countries).length > 0 || adTargetingSettings.worldwide)) &&
                    <div className="dashboard-campaign-admessage">
                        <div className="dashboard-campaign__main-title">
                            <span>Ad Audience </span>
                        </div>
                        <div className="dashboard-campaign-admessage-preview">
                            <div className="dashboard-campaign-admessage-preview__content">
                                {type !== AI_BOOSTED_SALES &&
                                <>
                                    <b>{gender}</b> aged <b>{adTargetingSettings.age_min}</b> to <b>{adTargetingSettings.age_max}</b>{' '}
                                </>
                                }
                               from <b>{adTargetingSettings.worldwide ? "Worldwide" : normalizeCountriesObject(adTargetingSettings.countries)}</b>
                            </div>
                            <Button type="button" color="link" innerUrl={`/${shop}/${campaignId}/edit`}>
                                <PenIcon />
                                Edit Ad Audience
                            </Button>
                        </div>
                    </div>
                }
            </form>
        );
    }
}

CampaignDetailsAdMessage.defaultProps = {
    adMessage: '',
    campaignEditPending: false,
};

CampaignDetailsAdMessage.propTypes = {
    adMessage: PropTypes.string,
    campaignEditPending: PropTypes.bool,
    campaignId: PropTypes.number.isRequired,
    dailyBudget: PropTypes.number.isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    editCampaign: PropTypes.func.isRequired,
    isDisabledEditAdMessageButton: PropTypes.bool.isRequired,
    shop: PropTypes.number.isRequired,
    toggleCollapseAdMessage: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default connect(null, {
    editCampaign: (params) => (dispatch) =>
        dispatch(requestEditCampaign(params)),
    displayBulkNotification,
})(CampaignDetailsAdMessage);
