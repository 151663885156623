import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestSendEventMetrics } from 'actions/shop';
import { currentBillingPeriodInfoSelector, billingInfoDataSelector, getCurrentShopSelector } from 'helpers/selectors';
import { SPENDS_PRICE } from 'constants/userBillingType';
import { BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';

import { metricType } from 'constants/metric';
import { Card, CardContent, CardTitle } from 'components/Card';
import SelectDropdown from 'components/SelectDropdown';

import BillingInfoTableBody from './components/BillingInfoTableBody';
import NewBillingInfoTableBody from './components/NewBillingInfoTableBody';
import { getListOfYears, sortDataByDate, formatDataForTableRender } from '../helpers';

export class BillingHistory extends Component {
    constructor(props) {
        super(props);
        this.selectDropdownHandler = this.selectDropdownHandler.bind(this);
        this.sendStripeMetrics = this.sendStripeMetrics.bind(this);
    }

    selectDropdownHandler(year) {
        const { updateBillingInfo, billingInfoYear } = this.props;

        if (year !== billingInfoYear) {
            updateBillingInfo(year);
        }
    }

    sendStripeMetrics() {
        const { requestSendEventMetrics, trackEventStripeReceiptDownloaded, currentShop } = this.props;
        const metricProps = {
            shop_id: +currentShop.id,
            type: metricType.downloadedInvoices,
            props: {},
        };
        requestSendEventMetrics(metricProps);
        trackEventStripeReceiptDownloaded();
    }

    render() {
        const {
            billingInfoState,
            data,
            shopBillingBusinessModel,
            shopBillingCurrency,
            billingInfoYear,
            currentShop,
        } = this.props;

        let billingInfoTableBody = null;
        const isStripePayment = currentShop.type === BIG_COMMERCE || currentShop.type === WOO_COMMERCE
        if (shopBillingBusinessModel === SPENDS_PRICE) {
            billingInfoTableBody = (
                <NewBillingInfoTableBody
                    data={data}
                    billingInfoState={billingInfoState}
                    currency={shopBillingCurrency}
                    shopId={currentShop.id}
                    shopTypeBigCommerceOrWooCommerce={isStripePayment}
                    sendStripeMetrics={this.sendStripeMetrics}
                />
            );
        } else {
            billingInfoTableBody = (
                <BillingInfoTableBody
                    data={data}
                    billingInfoState={billingInfoState}
                    currency={shopBillingCurrency}
                    isStripePayment={isStripePayment}
                    currentShop={currentShop}
                />
            );
        }
        const billingHistoryPreview = (
            <CardContent className='billing-history__description-text'>
                The history of Adwisely charges are displayed here.{' '}
                {(!isStripePayment && shopBillingBusinessModel !== SPENDS_PRICE) &&
                    <span>To download receipts click
                        {' '} <span style={{fontWeight: 500}}>Download icon </span>
                    </span>
                }
                { shopBillingBusinessModel !== SPENDS_PRICE &&
                    <div>
                        *If you exceed the ad spend limit, you will pay 10% fee on all extra ad spend.{' '}
                        <a
                            href={
                                'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/' +
                                'whats-the-cost-of-retargetapp'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="primary-link"
                            style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                textDecoration: 'underline'
                            }}
                        >
                            Learn more
                        </a>
                    </div>
                }
            </CardContent>
        )
        return (
            <Card className="settings-page__container">
                <CardTitle>Billing History</CardTitle>
                <div className="settings-page__block-content_flex billing-history__description-wrapper">
                    <div className="settings-page__block-content-wrapper">
                        {billingHistoryPreview}
                    </div>
                    <div style={{flexShrink: 0}}>
                        <SelectDropdown
                            list={getListOfYears()}
                            placeholder={billingInfoYear.toString()}
                            clickItemHandler={(selectedYear) => this.selectDropdownHandler(selectedYear)}
                            disabled={getListOfYears().length <= 1 && !data}
                        />
                    </div>
                </div>
                <div className="settings-page__block-content_flex_column ">
                    <div className="settings-page__block-content-wrapper">{billingInfoTableBody}</div>
                </div>
            </Card>
        );
    }
}

BillingHistory.propTypes = {
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
        domain: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    shopId: PropTypes.number.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                endedAtYear: PropTypes.string,
                datePeriod: PropTypes.string,
                campaignTypes: PropTypes.string,
                feeRate: PropTypes.string,
                feeAmount: PropTypes.string,
            }),
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                billing_period: PropTypes.string.isRequired,
                billing_plan_name: PropTypes.string.isRequired,
                fee_paid: PropTypes.string.isRequired,
            }),
        ),
    ]),
    shopBillingBusinessModel: PropTypes.string,
    billingInfoState: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    currentBillingPeriodInfo: PropTypes.arrayOf(
        PropTypes.shape({
            datePeriod: PropTypes.string,
            campaign_type: PropTypes.string,
            fee_rate: PropTypes.string,
        }),
    ),
    shopBillingCurrency: PropTypes.string,
    billingInfoYear: PropTypes.number,
    updateBillingInfo: PropTypes.func,
};

BillingHistory.defaultProps = {
    data: [],
    billingInfoState: null,
    currentBillingPeriodInfo: [
        {
            datePeriod: '',
            campaign_type: '',
            fee_rate: '',
        },
    ],
    shopBillingBusinessModel: '',
    shopBillingCurrency: '',
    billingInfoYear: new Date().getFullYear(),
    updateBillingInfo: () => {},
};

const mapStateToProps = (state) => {
    const currentBillingPeriodInfo = currentBillingPeriodInfoSelector(state.shopBillingInfo.billingInfoData);

    return {
        currentShop: getCurrentShopSelector(state.shops, state.shops.currentShopId),
        shopId: +state.shops.currentShopId,
        data: billingInfoDataSelector(state.shopBillingInfo.billingInfoData, currentBillingPeriodInfo),
        shopBillingBusinessModel: state.shopBillingInfo.businessModel,
        shopBillingCurrency: state.shopBillingInfo.currency,
        currentBillingPeriodInfo,
        billingInfoState: state.shopBillingInfo.billingInfoState,
        trialDaysLeft: state.shopBillingPeriod.trialDaysLeft,
    };
};

const mapDispatchToProps = {
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
