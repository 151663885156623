import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

import { Card, CardContent, CardTitle } from 'components/Card';
import IntercomChatCaller from 'components/IntercomChatCaller';
import Button from 'components/Button';

import { formatMonth, getCardLogo } from './helpers';

import './CardInfo.css';

class CardInfo extends PureComponent {
    render() {
        const {
            stripeCard,
            showModal,
            showDeleteStripeCardModal,
            showAddStripeCardModal,
            pending,
        } = this.props;

        let cardContentBlock = null;
        let paymentCardDescription = null;

        if (stripeCard) {
            paymentCardDescription = "This is your default payment card.";
            const expiresMonth = stripeCard.exp_month ? formatMonth(stripeCard.exp_month) : '00';
            const cardLogoClass = stripeCard.brand ? getCardLogo(stripeCard.brand) : '';
            cardContentBlock = (
                <Card disablePadding>
                    <CardContent className="card-info-card__block">
                        <div className={`card-info-card-logo__block ${cardLogoClass}`}>
                            <p>
                                {stripeCard.brand} ending in {stripeCard.last4}
                            </p>
                        </div>
                        <div className="card-info-card-expires__block">
                            <p>
                                Expires: {expiresMonth}/{stripeCard.exp_year}
                            </p>
                        </div>
                        <div className="card-info-card-replace__block">
                            <Button onClick={showModal} className="card-info-replace-card__button">
                                Replace card
                            </Button>
                            <Button onClick={showDeleteStripeCardModal} className="card-info-delete-card__button">
                                Delete card
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            );
        }

        if (!stripeCard) {
            paymentCardDescription = "To continue working with Adwisely, add a new card";
            cardContentBlock = (
                <Button color="primary" onClick={showAddStripeCardModal}>Add card</Button>
            )
        }

        return (
            <Card disablePadding className="settings-page__container">
                <div className="settings-page__block">
                    <CardTitle>Payment card</CardTitle>
                    <CardContent>{paymentCardDescription}</CardContent>
                </div>
                <div className="card-info-card__wrapper">
                    {pending && "Loading..."}
                    {!pending && cardContentBlock}
                </div>
            </Card>
        );
    }
}

CardInfo.propTypes = {
    stripeCard: PropTypes.shape({
        last4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        exp_month: PropTypes.string,
        brand: PropTypes.string,
        exp_year: PropTypes.string,
    }).isRequired,
    showModal: PropTypes.func.isRequired,
};

export default CardInfo;
