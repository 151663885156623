import React, { Suspense, lazy } from 'react';
import { Router, Route, IndexRoute } from 'react-router';

import { PATH } from 'constants/routes';

// Onboarding Step: get-started
import Page from 'modules/onboarding/steps/get-started/page/Page';
import NotQualified from 'modules/onboarding/steps/get-started/NotQualified';
import FacebookLogin from 'modules/onboarding/steps/get-started/login/FacebookLogin';
import AcceptInvitation from 'modules/onboarding/steps/get-started/invitation/AcceptInvitation';

// Onboarding Step: create-campaign
import SharePixel from 'modules/onboarding/steps/create-campaign/SharePixel';
import VerifyDomain from 'modules/onboarding/steps/create-campaign/VerifyDomain';
import StripeBilling from 'modules/onboarding/steps/create-campaign/StripeBilling';
import FacebookBilling from 'modules/onboarding/steps/create-campaign/FacebookBilling';
import SetUpRetargeting from 'modules/onboarding/steps/create-campaign/SetUpRetargeting';
import SetUpProspecting from 'modules/onboarding/steps/create-campaign/SetUpProspecting';

import DefaultError from 'modules/onboarding/components/DefaultError';

// Google onboarding steps
import Settings from 'modules/onboarding/google/steps/ConfigureCampaign';
import Billing from 'modules/onboarding/google/steps/Billing';
import { ShopProtected } from 'modules/google-onboarding/ShopProtected';
import { ProcessingScreen } from 'modules/google-onboarding/ProcessingScreen';
import { WebsiteClaimed } from 'modules/google-onboarding/WebsiteClaimed';
import ConnectGoogleAdsAccount from 'modules/onboarding/google/steps/AdAccount/ConnectGoogleAdsAccount';
import { SubscriptionPlanGoogle } from 'modules/onboarding/google/steps/SubscriptionPlanGoogle/SubscriptionPlanGoogle';
import CreateMerchantCenter from 'modules/onboarding/google/steps/MerchantCenter/CreateMerchantCenter';

// UserBm onboarding steps
import ConnectBM from 'modules/onboarding/userBMFlow/steps/ConnectBM/ConnectBM';
import CreatePage from 'modules/onboarding/userBMFlow/steps/CreatePage/CreatePage';
import ConnectPage from 'modules/onboarding/userBMFlow/steps/ConnectPage/ConnectPage';
import RestrictedError from 'modules/onboarding/userBMFlow/steps/AdAccount/RestrictedError';
import UpgradeRoleInBM from 'modules/onboarding/userBMFlow/steps/ConnectBM/UpgradeRoleInBM';
import CreateBM from 'modules/onboarding/userBMFlow/steps/CreateBM';
import ConfirmPixel from 'modules/onboarding/userBMFlow/steps/ConfirmPixel/ConfirmPixel';
import PixelNotFound from 'modules/onboarding/userBMFlow/steps/ConfirmPixel/PixelNotFound';
import StripeBillingUBM from 'modules/onboarding/userBMFlow/steps/StripeBilling';
import FacebookBillingUBM from 'modules/onboarding/userBMFlow/steps/FacebookBilling';
import ChooseCampaign from 'modules/onboarding/components/ChooseCampaign';
import CreateProspectingCampaign from 'modules/onboarding/userBMFlow/steps/CreateProspectingCampaign';
import CreateSpecialOfferCampaign from 'modules/onboarding/userBMFlow/steps/CreateSpecialOfferCampaign';
import StartFreeTrial from "modules/onboarding/userBMFlow/steps/StartFreeTrial/StartFreeTrial";
import AdAccountError from 'modules/onboarding/userBMFlow/errors/AdAccountError';

import RootMiddleware from './containers/RootMiddleware';
import RoutingRoot from './containers/RoutingRoot';
import App from '../containers/App';
import NotFound from '../containers/NotFound';
import ShopIndex from './shop/ShopIndex';
import ShopMiddleware from './shop/ShopMiddleware';
import OnboardingIndex from './onboarding/containers/OnboardingIndex';
import OnboardingMiddleware from './onboarding/containers/OnboardingMiddleware';
import GoogleOnboardingMiddleware from './onboarding/containers/GoogleOnboardingMiddleware';
import UserBMOnboardingMiddleware from './onboarding/containers/UserBMOnboardingMiddleware';

import DashboardWrapper from './dashboard/DashboardWrapper';
import UpgradeSubscriptionPlan from './settings/UpgradeSubscriptionPlan';
import ProspectingCampaignCreateWrapper from './campaign/ProspectingCampaignCreate/ProspectingCampaignCreateWrapper';
import RetargetingCampaignCreateWrapper from './campaign/RetargetingCampaignCreate/RetargetingCampaignCreateWrapper';
import SpecialOfferCampaignCreateWrapper from './campaign/SpecialOfferCampaignCreate/SpecialOfferCampaignCreateWrapper';
import CampaignMiddleware from './campaign/containers/CampaignMiddleware';
import CampaignIndex from './campaign/containers/CampaignIndex';
import EditCampaignMiddleware from './campaign/containers/EditCampaignMiddleware';
import ShopifyMiddleware from './shopify/containers/ShopifyMiddleware';
import ShopifyPageLayout from './shopify/components/ShopifyPageLayout';
import LoginCallback from './shopify/login-callback/LoginCallback';
import InstallCallback from './shopify/install-callback/InstallCallback';
import NoShopsWrapper from './shopify/no-shops/NoShopsWrapper';
import UninstalledWrapper from './shopify/uninstalled/UninstalledWrapper';
import FacebookPageLayout from './facebook/components/FacebookPageLayout';
import LoginContainer from './facebook/login/components/LoginContainer';
import FbLoginCallback from './facebook/login/callback/components/LoginCallback';
import ActionHandler from './action';
import BigCommerceInstallCallback from './bigcommerce/BigCommerceInstallCallback';
import BigCommerceContactUs from './bigcommerce/BigCommerceContactUs';
import ChooseShop from '../components/ChooseShop';
import ChooseInstallShop from '../components/ChooseInstallShop';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import WooCommerceInstallCallback from './woocommerce/WooCommerceInstallCallback';
import Home from './home';

// TikTok Flow
import TikTokOnboardingMiddleware from "./onboarding/tiktok/containers/TikTokOnboardingMiddleware";
import BeforeYouBeginTikTok from "./onboarding/tiktok/components/BeforeYouBeginTikTok/BeforeYouBeginTikTok";
import BookACallTikTok from "./onboarding/tiktok/components/BookACallTikTok/BookACallTikTok";
import CallBookedTiktok from './onboarding/tiktok/components/CallBookedTikTok/CallBookedTiktok';
import { SubscriptionPlanTikTok } from './onboarding/tiktok/components/SubscriptionPlanTikTok/SubscriptionPlanTikTok';

import {
    CreateVisitorRetargetingCampaign
} from './onboarding/userBMFlow/steps/CreateVisitorRetargetingCampaign/CreateVisitorRetargetingCampaign';
import {
    CreateDynamicRetargetingCampaign
} from './onboarding/userBMFlow/steps/CreateDynamicRetargetingCampaign/CreateDynamicRetargetingCampaign';
import { ConnectOrCreateAdAccount } from './onboarding/userBMFlow/steps/AdAccount/ConnectOrCreateAdAccount';
import { ConnectAdAccount } from './onboarding/userBMFlow/steps/AdAccount/ConnectAdAccount';
import {
    SubscriptionPlanFacebook
} from './onboarding/userBMFlow/steps/SubscriptionPlanFacebook/SubscriptionPlanFacebook';
import { ShopifyBillingTikTok } from './onboarding/tiktok/components/ShopifyBillingTikTok/ShopifyBillingTikTok';
import { ShopifyBillingFacebook } from './onboarding/userBMFlow/steps/ShopifyBillingFacebook/ShopifyBillingFacebook';
import { ShopifyBillingGoogle } from './onboarding/google/steps/ShopifyBillingGoogle/ShopifyBillingGoogle';
import {
    ShopifyBillingFacebookChildBM
} from './onboarding/steps/create-campaign/ShopifyBillingFacebookChildBM/ShopifyBillingFacebookChildBM';
import {
    SubscriptionPlanFacebookChildBM
} from './onboarding/steps/create-campaign/SubscriptionPlanFacebookChildBM/SubscriptionPlanFacebookChildBM';
import {
    CreateSocialRetargetingCampaign
} from './onboarding/userBMFlow/steps/CreateSocialRetargetingCampaign/CreateSocialRetargetingCampaign';
import {ChooseInstagramAccount} from './onboarding/userBMFlow/steps/InstagramProfile/components/ChooseInstagramAccount/ChooseInstagramAccount';
import {CreateInstagramAccount} from './onboarding/userBMFlow/steps/InstagramProfile/components/CreateInstagramAccount/CreateInstagramAccount';
import {
    NoInstagramAccount
} from './onboarding/userBMFlow/steps/InstagramProfile/components/NoInstagramAccount/NoInstagramAccount';
import SettingsPage from './settings/SettingsPage';
import { ChooseOrCreateProductFeedPage } from './onboarding/google/steps/ChooseOrCreateProductFeed/ChooseOrCreateProductFeed';
import {
    CreateAiBoostedSalesCampaign
} from './onboarding/userBMFlow/steps/CreateAiBoostedSalesCampaign/CreateAiBoostedSalesCampaign';
import AiBoostedSalesCreateWrapper from './campaign/AiBoostedSalesCreate/AiBoostedSalesCreateWrapper';

const MigrationToRa3 = lazy(() => import('./migrationToRa3'));

const MigrationToRa3Loader = (props) => (
    <Suspense fallback={<div />}>
        <MigrationToRa3 {...props} />
    </Suspense>
);

const AppRouter = ({ history }) => (
    <Router history={history}>
        <Route path="/" component={RootMiddleware}>
            <Route path={PATH.facebook} component={FacebookPageLayout}>
                <Route path="login">
                    <IndexRoute component={LoginContainer} />
                    <Route path="callback" component={FbLoginCallback} />
                </Route>
            </Route>
            <Route path={PATH.errorPage} component={ErrorPage} />
            <Route path={PATH.chooseShop} component={ChooseShop} />
            <Route path={PATH.chooseInstallShop} component={ChooseInstallShop} />
            <Route path="ra3-migration/:shopId" component={MigrationToRa3Loader} />
            <Route path={PATH.shopify} component={ShopifyMiddleware}>
                <Route component={ShopifyPageLayout}>
                    <Route path="login-callback" component={LoginCallback} />
                    <Route path="install-callback" component={InstallCallback} />
                    <Route path="no-shops" component={NoShopsWrapper} />
                    <Route path="uninstalled/:shopId" component={UninstalledWrapper} />
                </Route>
            </Route>
            <Route path={PATH.woocommerce} component={ShopifyMiddleware}>
                <Route component={ShopifyPageLayout}>
                    <Route path="install-callback" component={WooCommerceInstallCallback} />
                    <Route path="uninstalled/:shopId" component={UninstalledWrapper} />
                </Route>
            </Route>
            <Route path={PATH.bigcommerce} component={ShopifyMiddleware}>
                <Route component={ShopifyPageLayout}>
                    <Route path="install-callback" component={BigCommerceInstallCallback} />
                    <Route path="contact-us" component={BigCommerceContactUs} />
                    <Route path="uninstalled/:shopId" component={UninstalledWrapper} />
                </Route>
            </Route>
            <Route path={PATH.action} component={ActionHandler} />

            <IndexRoute component={RoutingRoot} />
            <Route component={App}>
                <Route path="shop/:shopId" component={ShopMiddleware}>
                    <IndexRoute component={ShopIndex} />
                </Route>
                <Route path="/:shopId" component={ShopMiddleware}>
                    <IndexRoute component={ShopIndex} />
                    <Route path="home" component={Home} />
                    <Route path={PATH.onboarding.google.processing} component={ProcessingScreen} />
                    <Route path={'/:shopId/google/processing'} component={ProcessingScreen} />
                    <Route path="google" component={GoogleOnboardingMiddleware}>
                        <Route path={PATH.onboarding.google.settings} component={Settings} />
                        <Route path={PATH.onboarding.google.billing} component={Billing} />
                        <Route path={PATH.onboarding.google.createMerchantCenter} component={CreateMerchantCenter} />
                        <Route path={PATH.onboarding.google.chooseAdAccount} component={ConnectGoogleAdsAccount} />
                         <Route path={PATH.onboarding.google.chooseProductFeed} component={ChooseOrCreateProductFeedPage } />
                        <Route path={PATH.onboarding.google.websiteClaimed} component={WebsiteClaimed} />
                        <Route path={PATH.onboarding.google.shopProtected} component={ShopProtected} />
                        <Route path={PATH.onboarding.google.shopifyBilling} component={ShopifyBillingGoogle} />
                        <Route path={PATH.onboarding.google.choosePlan} component={SubscriptionPlanGoogle} />
                        <Route path={PATH.onboarding.google.stripeBilling} component={StripeBilling} />
                        <Route path={PATH.onboarding.google.error} component={DefaultError} />
                    </Route>
                    <Route path="user-bm" component={UserBMOnboardingMiddleware}>
                        <Route path={PATH.onboarding.userBM.connectBM} component={ConnectBM} />
                        <Route path={PATH.onboarding.userBM.upgradeRole} component={UpgradeRoleInBM} />
                        <Route path={PATH.onboarding.userBM.createBM} component={CreateBM} />
                        <Route path={PATH.onboarding.userBM.createPage} component={CreatePage} />
                        <Route path={PATH.onboarding.userBM.connectPage} component={ConnectPage} />
                        <Route path={PATH.onboarding.userBM.connectAdAccount} component={ConnectAdAccount } />
                        <Route path={PATH.onboarding.userBM.connectOrCreateAdAccount} component={ConnectOrCreateAdAccount } />
                        <Route path={PATH.onboarding.userBM.restrictedBm} component={RestrictedError} />
                        <Route path={PATH.onboarding.userBM.confirmPixel} component={ConfirmPixel} />
                        <Route path={PATH.onboarding.userBM.pixelNotFound} component={PixelNotFound} />
                        <Route path={PATH.onboarding.userBM.choosePlan} component={SubscriptionPlanFacebook} />
                        <Route path={PATH.onboarding.userBM.shopifyBilling} component={ShopifyBillingFacebook} />
                        <Route path={PATH.onboarding.userBM.stripeBilling} component={StripeBillingUBM} />
                        <Route path={PATH.onboarding.userBM.facebookBilling} component={FacebookBillingUBM} />
                        <Route path={PATH.onboarding.userBM.chooseCampaign} component={ChooseCampaign} />
                        <Route path={PATH.onboarding.userBM.createProspectingCampaign} component={CreateProspectingCampaign} />
                        <Route path={PATH.onboarding.userBM.createVisitorRetargetingCampaign} component={CreateVisitorRetargetingCampaign} />
                        <Route path={PATH.onboarding.userBM.createDynamicRetargetingCampaign} component={CreateDynamicRetargetingCampaign} />
                        <Route path={PATH.onboarding.userBM.createAiBoostedSalesCampaign} component={CreateAiBoostedSalesCampaign} />
                        <Route path={PATH.onboarding.userBM.chooseInstagramAccount} component={ChooseInstagramAccount} />
                        <Route path={PATH.onboarding.userBM.createInstagramAccount} component={CreateInstagramAccount} />
                        <Route path={PATH.onboarding.userBM.stillHaveNoInstagramAccount} component={NoInstagramAccount} />
                        <Route path={PATH.onboarding.userBM.createSocialRetargetingCampaign} component={CreateSocialRetargetingCampaign} />
                        <Route path={PATH.onboarding.userBM.createSpecialOfferCampaign} component={CreateSpecialOfferCampaign} />
                        <Route path={PATH.onboarding.userBM.startFreeTrial} component={StartFreeTrial} />
                        <Route path={PATH.onboarding.userBM.error} component={DefaultError} />
                        <Route path={PATH.onboarding.userBM.adAccountError} component={AdAccountError} />
                    </Route>
                    <Route path="onboarding" component={OnboardingMiddleware}>
                        <IndexRoute component={OnboardingIndex} />

                        {/* Onboarding Step: get-started */}
                        <Route path={PATH.onboarding.firstStep.login} component={FacebookLogin} />
                        <Route path={PATH.onboarding.firstStep.unqualified} component={NotQualified} />
                        <Route path={PATH.onboarding.firstStep.page} component={Page} />
                        <Route path={PATH.onboarding.firstStep.invitation} component={AcceptInvitation} />

                        {/* Onboarding Step: create-campaign */}
                        <Route path={PATH.onboarding.secondStep.verifyDomain} component={VerifyDomain} />
                        <Route path={PATH.onboarding.secondStep.sharePixel} component={SharePixel} />
                        <Route path={PATH.onboarding.secondStep.chooseCampaign} component={ChooseCampaign} />
                        <Route path={PATH.onboarding.secondStep.setUpRetargeting} component={SetUpRetargeting} />
                        <Route path={PATH.onboarding.secondStep.setUpProspecting} component={SetUpProspecting} />
                        <Route path={PATH.onboarding.secondStep.choosePlan} component={SubscriptionPlanFacebookChildBM} />
                        <Route path={PATH.onboarding.secondStep.shopifyBilling} component={ShopifyBillingFacebookChildBM} />
                        <Route path={PATH.onboarding.secondStep.stripeBilling} component={StripeBilling} />
                        <Route path={PATH.onboarding.secondStep.facebookBilling} component={FacebookBilling} />
                    </Route>
                    <Route path="tiktok" component={TikTokOnboardingMiddleware}>
                        <Route path={PATH.onboarding.tikTok.beforeYouBegin} component={BeforeYouBeginTikTok} />
                        <Route path={PATH.onboarding.tikTok.choosePlan} component={SubscriptionPlanTikTok} />
                        <Route path={PATH.onboarding.tikTok.shopifyBilling} component={ShopifyBillingTikTok} />
                        <Route path={PATH.onboarding.tikTok.stripeBilling} component={StripeBilling} />
                        <Route path={PATH.onboarding.tikTok.bookACall} component={BookACallTikTok} />
                        <Route path={PATH.onboarding.tikTok.callBooked} component={CallBookedTiktok} />
                    </Route>

                    <Route path={PATH.dashboard} component={DashboardWrapper} />

                    <Route path={PATH.settings} component={SettingsPage} />

                    <Route path={PATH.upgradePlan} component={UpgradeSubscriptionPlan} />

                    <Route path="create" component={CampaignMiddleware}>
                        <Route path={PATH.create.retargeting} component={RetargetingCampaignCreateWrapper} />
                        <Route path={PATH.create.prospecting} component={ProspectingCampaignCreateWrapper} />
                        <Route path={PATH.create.specialOffer} component={SpecialOfferCampaignCreateWrapper} />
                        <Route path={PATH.create.aiBoosted} component={AiBoostedSalesCreateWrapper} />
                    </Route>

                    <Route path=":id" component={CampaignMiddleware}>
                        <IndexRoute component={CampaignIndex} />
                        <Route path={PATH.edit} component={EditCampaignMiddleware} />
                    </Route>
                </Route>
            </Route>
        </Route>
        <Route path="*" component={NotFound} />
    </Router>
);

export default AppRouter;
