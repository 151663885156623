import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import './PixelNotFound.css';

const PixelNotFound = ({ currentShopId, replace }) => {
    const [isLoading, setIsLoading] = useState(false);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.check_pixel_settings,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
        }

    return (
        <div className="pixel-not-found__wrapper">
            <h1 className="pixel-not-found__title">Check your Meta Pixel settings</h1>
                <div>
                    <p className="pixel-not-found__description">
                        We couldn't find any Meta pixel with activity for the last 14 days or pixels owned by selected BM
                    </p>
                    <p className="pixel-not-found__description">
                        Please follow {''}
                        <a
                            href="https://www.facebook.com/business/help/952192354843755"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="primary-link in-text"
                        >
                            this link
                        </a> {''}
                        to see the instructions on how to create & install Meta pixel.
                    </p>
                    <p className="pixel-not-found__description">
                        If you need our support team assistance, simply book a call with our specialist by the {''}
                        <a
                            href="https://calendly.com/adwisely-support-team/support-team"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="primary-link in-text"
                        >
                            following link
                        </a>.
                    </p>
                </div>
            <Button onClick={handleContinueBtn} color="primary">
                Already Done
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(PixelNotFound);
