import React, {useEffect, useState} from 'react';

const getProgressBarColor = (percent) => {
    if (percent <= 40) {
        return '#5BC920'
    } else if (percent > 40 && percent <= 70) {
        return '#FE9929'
    }
    return '#FE1616'
}

export const useAdSpendProgressBarData = (shopId) => {
    const [spendsData, setSpendsData] = useState(
        {
            currentSpends: 0,
            totalSpends: 0,
            color: '#5BC920',
            percents: 0
        }
    );

    useEffect(() => {
        fetch(`/api/shop/${shopId}/subscription/current_spends_balance`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(res => res.json())
          .then(data => {
            if (data.data) {
                const { spends_usd, spends_limit_usd, used_in_percent } = data.data;
                const percents = used_in_percent < 100 ? used_in_percent : 100;
                setSpendsData({
                    currentSpends: spends_usd,
                    totalSpends: spends_limit_usd,
                    percents: percents,
                    color: getProgressBarColor(percents)
                })
            }
        })
    }, [])

    return spendsData;
}