import React, { useState } from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';

import { changeOnboardingState } from 'modules/home/utils';
import { getLocalStorageObjectItem } from 'helpers';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import { InstagramAccount } from '../../InstagramAccount';

export const CreateInstagramAccountView = ({shopId, replace}) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const [isShareCheckboxChecked, setIsShareCheckboxChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleContinueClick = () => {
        setIsLoading(true)
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.create_instagram_account,
            currentShopId: shopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(shopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(shopId, replace);
                }
            });
    }

    return (
        <InstagramAccount isLoading={isLoading}>
            <div className="connect-instagram__description">Adwisely will run ads on behalf of your Instagram Business profile.</div>
            <div className="connect-instagram__description">
                To proceed, please,
                <a
                    href="https://business.facebook.com/settings/instagram-account-v2s/"
                    target='_blank'
                    rel="noopener noreferrer"
                > go to your Business Manager </a>
                and add an Instagram account
            </div>
            <div className="connect-instagram__checkbox-wrapper">
                <label htmlFor="shareInstagramAccount">
                    <Checkbox
                        checkboxId={'shareInstagramAccount'}
                        onChange={() => {setIsShareCheckboxChecked(!isShareCheckboxChecked)}}
                    />I have added an Instagram account
                </label>
            </div>
            <div className="connect-instagram__btn-wrapper">
                <Button color='primary' disabled={!isShareCheckboxChecked} onClick={handleContinueClick}>
                    Continue
                </Button>
            </div>
        </InstagramAccount>
    )
}

const mapStateToProps = ({ shops }) => (
    {
        shopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace
};

export const CreateInstagramAccount = connect(mapStateToProps, mapPropsToDispatch)(CreateInstagramAccountView)