import * as actions from '../actions/stripe';

const initialState = {
    stripeBillingAmount: 0.0,
    stripeChargedSuccess: false,
    stripeChargedError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.STRIPE_BILLING_AMOUNT_SUCCESS:
            state = {
                ...state,
                stripeBillingAmount: +action.payload.amount ? action.payload.amount : 0,
            };
            break;
        case actions.STRIPE_BILLING_AMOUNT:
            state = {
                ...state,
                stripeBillingAmount: +action.payload,
            };
            break;
        case actions.STRIPE_RETRY_CHARGE_SUCCESS:
            state = {
                ...state,
                stripeChargedSuccess: true,
                stripeChargedError: null,
            };
            break;
        case actions.STRIPE_RETRY_CHARGE_FAILURE:
            state = {
                ...state,
                stripeChargedSuccess: false,
                stripeChargedError: action.payload,
            };
            break;
        default:
            break;
    }
    return state;
};
