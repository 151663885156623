import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { setGoogleOnboardingState } from 'actions/googleOnboardingState';

import Wizard from '../components/Wizard';
import { googleStatePolling } from "../google/helpers/googleStatePolling";

const GoogleOnboardingMiddleware = ({ replace, children, currentShopId, setGoogleOnboardingState }) => {

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        const polling = googleStatePolling(currentShopId, replace, setGoogleOnboardingState, () => {}, signal);

        return () => {
            polling.stopPolling();
            controller.abort();
        }
    }, [])

    return <Wizard>{children}</Wizard>;
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
    setGoogleOnboardingState,
};

export default connect(mapStateToProps, mapPropsToDispatch)(GoogleOnboardingMiddleware);
