import * as campaignActions from '../actions/campaign';

const initialState = {
    googleStandardAdPreviewData: [],
    googleStandardAdPreviewPending: false,
    googleStandardAdPreviewSuccess: false,
    googleStandardAdPreviewFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.GOOGLE_STANDARD_AD_PREVIEW_REQUEST:
            return {
                ...state,
                googleStandardAdPreviewSuccess: false,
                googleStandardAdPreviewPending: true,
                googleStandardAdPreviewFailure: false,
                googleStandardAdPreviewData: [],
            };

        case campaignActions.GOOGLE_STANDARD_AD_PREVIEW_SUCCESS:
            return {
                ...state,
                googleStandardAdPreviewSuccess: true,
                googleStandardAdPreviewPending: false,
                googleStandardAdPreviewFailure: false,
                googleStandardAdPreviewData: [...state.googleStandardAdPreviewData, ...action.payload],
            };

        case campaignActions.GOOGLE_STANDARD_AD_PREVIEW_FAILURE:
            return {
                ...state,
                googleStandardAdPreviewSuccess: false,
                googleStandardAdPreviewFailure: true,
                googleStandardAdPreviewPending: false,
            };

        default:
            return state;
    }
};
