import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CardContent.css';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    tag: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    tag: 'div',
    className: null,
};

const CardContent = ({ children, tag: Tag, className }) => (
    <Tag className={classNames('card-block-content', className)}>{children}</Tag>
);

CardContent.propTypes = propTypes;
CardContent.defaultProps = defaultProps;

export default CardContent;
