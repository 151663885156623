import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { isFacebookPaymentMethodExist } from 'helpers/onboardingHelper';
import { getLocalStorageObjectItem } from 'helpers';
import { validateShop } from 'actions/shop';
import { LoaderLongWait as Loader } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import PaymentError from './PaymentError/PaymentError';
import PaymentReview from './PaymentReview/PaymentReview';
import { useFacebookBillingSession } from './hooks/useFacebookBillingSession';

import './index.css';

const FacebookBilling = ({
    shopError,
    params: { shopId },
    validateShop,
    isShopValidating,
    facebookBillingIsApproved,
    replace,
}) => {
    const { wasPreviewPaymentShowed } = useFacebookBillingSession();

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding'); 

    useLayoutEffect(() => {
        checkFacebookPaymentMethod();
        window.addEventListener('focus', checkFacebookPaymentMethod);

        return function cleanup() {
            window.removeEventListener('focus', checkFacebookPaymentMethod);
        };
    }, []);

    const checkFacebookPaymentMethod = () => {
        validateShop({ shopId }).then(() => {
            if (facebookBillingIsApproved) {
                changeOnboardingState({
                    type: ONBOARDING_TYPES[currentOnboarding],
                    status: UBM_ONBOARDING_STATES.confirm_facebook_payment,
                    currentShopId: shopId,
                })
                    .then((res) => {
                        if (res.status === 409) {
                            facebookStatePolling(shopId, replace)
                        }
                        return res.json()
                    })
                    .then(res => {
                        if (res?.processing) {
                            facebookStatePolling(shopId, replace);
                        }
                    });
            }
        })
    };

    if (!isShopValidating && !facebookBillingIsApproved && wasPreviewPaymentShowed) {
        return <PaymentError shopError={shopError} shopId={shopId} />;
    } else if (!isShopValidating && !facebookBillingIsApproved) {
        return <PaymentReview shopError={shopError} shopId={shopId} />;
    }

    return (
        <div className="payment-preview__loader-wrapper">
            <Loader />
        </div>
    );
};

const mapStateToProps = ({ shopError }) => ({
    facebookBillingIsApproved: isFacebookPaymentMethodExist(shopError.shopError),
    shopError: shopError.shopError,
    isShopValidating: shopError?.shopValidateRequesting,
});

const mapDispatchToProps = {
    validateShop,
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookBilling);
