import React from 'react';
import PropTypes from 'prop-types';

const PricingWidgetItem = ({ fee, adSpend, pricingItemTitle }) => (
    <section className="pricing-widget-content__list-item">
        <p className="pricing-widget-content-list-item__section">
            <span className="pricing-widget-content-list-item-section__title-badge">
                {fee}
                <i className="pricing-widget-content-list-item-section__title-sign">%</i>
            </span>

            <span className="pricing-widget-content-list-item-section__title-description">Adwisely Fee</span>
        </p>

        <p className="pricing-widget-content-list-item__section">
            <span className="pricing-widget-content-list-item-section__description">
                <span className="pricing-widget-content-list-item-section__description__caption">{`$${adSpend}+`}</span>{' '}
                (USD/month)
            </span>

            <span className="pricing-widget-content-list-item-section__description-notice">{pricingItemTitle}</span>
        </p>
    </section>
);

PricingWidgetItem.propTypes = {
    pricingItemTitle: PropTypes.string.isRequired,
    adSpend: PropTypes.string.isRequired,
    fee: PropTypes.string.isRequired,
};

export default PricingWidgetItem;
