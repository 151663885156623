import React from 'react';
import PropTypes from 'prop-types';

import { Caret } from 'components/Icons';
import './index.css';

const propTypes = {
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export const TransparentToggler = ({ disabled, children }) => (
    <button disabled={disabled} className="transparent-toggler">
        {children}
        <Caret white />
    </button>
);

TransparentToggler.propTypes = propTypes;

export default TransparentToggler;
