import React, { useEffect, useState } from 'react';
import { getLocalStorageObjectItem } from 'helpers';
import {
    normalizeCountriesArray,
    normalizeSelectedCountriesData
} from 'modules/campaign/components/AdAudience/helpers/handleAdAudienceState';

export const useCampaignInitialSettings = (shopId, setSearchedCountries) => {
    const [availableCountries, setAvailableCountries] = useState([]);
    const [countriesList, setCountriesList] = useState([])
    const [isWorldwideAvailable, setIsWorldwideAvailable] = useState(false);
    const [isWorldwide, setIsWorldWide] = useState(false);
    const [maxNumOfCountries, setMaxNumOfCountries] = useState(3);
    const [availableAgeMax, setAvailableAgeMax] = useState(65);
    const [availableAgeMin, setAvailableAgeMin] = useState(18);
    const [availableGenders, setAvailableGenders] = useState({});
    const [genders, setGenders] = useState({});
    const [ageMin, setAgeMin] = useState(21)
    const [ageMax, setAgeMax] = useState(65);

    useEffect(() => {
        const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

        fetch(`/api/onboarding/${shopId}/${currentOnboarding}/campaign_initial_settings`, {
            method: 'GET'
        })
            .then((res) => res.json())
            .then(data => {
                const { targeting_settings } = data.data;
                const availableCountries = targeting_settings.available_countries;
                const availableCountriesSlugArray = Object.keys(availableCountries);
                const normalizedAvailableCountries = normalizeCountriesArray(availableCountriesSlugArray, availableCountries);
                const countries = targeting_settings.countries;
                const countriesSlugArray = Object.keys(countries);
                const normalizedSelectedCountries = normalizeCountriesArray(countriesSlugArray, countries);
                normalizeSelectedCountriesData(normalizedAvailableCountries, normalizedSelectedCountries, setAvailableCountries, setCountriesList, setSearchedCountries)

                const {
                    available_worldwide,
                    worldwide,
                    max_num_of_countries,
                    available_genders,
                    genders,
                    age_max,
                    age_min,
                    available_age_max,
                    available_age_min
                } = targeting_settings;
                setIsWorldwideAvailable(available_worldwide);
                setIsWorldWide(worldwide);
                setMaxNumOfCountries(max_num_of_countries);
                setAvailableGenders(available_genders);
                setGenders(genders);
                setAgeMax(age_max);
                setAgeMin(age_min);
                setAvailableAgeMin(available_age_min);
                setAvailableAgeMax(available_age_max);
            })
    }, [])

    return {
        isWorldwide,
        countriesList,
        availableCountries,
        isWorldwideAvailable,
        setIsWorldWide,
        setCountriesList,
        setAvailableCountries,
        maxNumOfCountries,
        availableAgeMin,
        availableAgeMax,
        availableGenders,
        ageMin,
        ageMax,
        genders,
        setGenders,
        setAgeMin,
        setAgeMax
    }
}