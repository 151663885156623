import React from 'react';
import PropTypes from 'prop-types';

import TipsWidget from 'components/TipsWidget';
import TextInput from 'components/TextInput';
import TextareaInput from 'components/TextareaInput';
import DailyBudgetInput from 'components/DailyBudgetInput';
import { Card, CardContent, CardTitle } from 'components/Card';

export const EditRemarketingAdsFormView = ({
    formName,
    disabled,
    dailyBudget,
    dailyBudgetAlert,
    campaignFinalUrl,
    dailyBudgetErrors,
    dailyBudgetCurrency,
    changeCampaignField,
    campaignDescription,
    campaignLongHeadline,
    campaignShortHeadline,
    campaignFinalUrlError,
    campaignDescriptionError,
    onCampaignCreateFormSubmit,
    campaignShortHeadlineError,
}) => (
    <Card className="google-ads-form">
        <form id={formName} onSubmit={onCampaignCreateFormSubmit}>
            <CardTitle className="campaign-details__title" labelFor="campaignShortHeadline" subTitle>
                <span>Short Headline</span>
                <TipsWidget placement="right" isDarkView>
                    <p className="tips-description__content">Examples:</p>
                    <p className="tips-description__content">
                        "
                        <span role="img" aria-label="shopping_bags">
                            🛍{' '}
                        </span>
                        Flash SALE!{' '}
                        <span role="img" aria-label="shopping_bags">
                            🛍
                        </span>
                        "<br />
                        "Don’t miss out!" <br />"
                        <span role="img" aria-label="fire">
                            🔥
                        </span>
                        Hot Hot Hot{' '}
                        <span role="img" aria-label="fire">
                            🔥
                        </span>
                        "
                    </p>
                </TipsWidget>
            </CardTitle>
            <CardContent>
                <TextInput
                    maxLength={25}
                    inputClassName="campaign-details__input"
                    wrapperClassName="campaign-details__admessage-wrapper"
                    name="campaignShortHeadline"
                    inputValue={campaignShortHeadline}
                    onChange={changeCampaignField}
                    disabled={disabled}
                    error={campaignShortHeadlineError}
                />
            </CardContent>
            <CardTitle className="campaign-details__title" labelFor="campaignLongHeadline" subTitle>
                <span>Long Headline</span>
                <TipsWidget placement="right" isDarkView>
                    <p className="tips-description__content">Example:</p>
                    <p className="tips-description__content">
                        "Get 20% OFF your order now{' '}
                        <span role="img" aria-label="flexed_biceps">
                            💪
                        </span>{' '}
                        &nbsp;Use code SALE20 at checkout{' '}
                        <span role="img" aria-label="present">
                            🎁
                        </span>{' '}
                        &nbsp;"
                    </p>
                </TipsWidget>
            </CardTitle>
            <CardContent>
                <TextareaInput
                    maxLength={90}
                    inputClassName="campaign-details__input"
                    wrapperClassName="campaign-details__admessage-wrapper"
                    name="campaignLongHeadline"
                    inputValue={campaignLongHeadline}
                    onChange={changeCampaignField}
                    disabled={disabled}
                    error={campaignShortHeadlineError}
                />
            </CardContent>
            <CardTitle className="campaign-details__title" labelFor="campaignDescription" subTitle>
                <span>Description</span>
                <TipsWidget placement="right" isDarkView>
                    <p className="tips-description__content">Examples:</p>
                    <p className="tips-description__content">
                        "Offer expires soon" <br />
                        "Hurry and get yours"
                    </p>
                </TipsWidget>
            </CardTitle>
            <CardContent>
                <TextareaInput
                    maxLength={90}
                    inputClassName="campaign-details__input"
                    wrapperClassName="campaign-details__admessage-wrapper"
                    name="campaignDescription"
                    inputValue={campaignDescription}
                    onChange={changeCampaignField}
                    disabled={disabled}
                    error={campaignDescriptionError}
                />
            </CardContent>
            <CardTitle className="campaign-details__title" labelFor="adMessageInput" subTitle>
                <span>Final URL</span>
                <TipsWidget placement="right" isDarkView>
                    <p className="tips-description__content">Final URL is the direct link to your website</p>
                </TipsWidget>
            </CardTitle>
            <CardContent>
                <TextInput
                    inputClassName="campaign-details__input"
                    wrapperClassName="campaign-details__admessage-wrapper"
                    name="campaignFinalUrl"
                    inputValue={campaignFinalUrl}
                    onChange={changeCampaignField}
                    disabled={disabled}
                    error={campaignFinalUrlError}
                />
            </CardContent>
            {dailyBudgetAlert}
            <CardTitle subTitle labelFor="dbInput">
                <span>Daily Budget</span>
                <TipsWidget placement="top" isDarkView>
                    <p className="tips-description__content">
                        Daily budget is the maximum amount of money you are ready to spend on your retargeting ads
                        within a 24-hour period.
                    </p>
                    <p className="tips-description__content">
                        <a
                            href={
                                'https://help.adwisely.com' +
                                '/learn-about-pricing-privacy-policy-and-terms' +
                                '/whats-the-cost-of-retargetapp'
                            }
                            className="link link-white"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            I want to learn more &#x2192;
                        </a>
                    </p>
                </TipsWidget>
            </CardTitle>
            <CardContent>
                <DailyBudgetInput
                    dailyBudget={dailyBudget}
                    dailyBudgetCurrency={dailyBudgetCurrency}
                    onChange={changeCampaignField}
                    onFocus={changeCampaignField}
                    disabled={disabled}
                    dailyBudgetHasErrors={!!dailyBudgetErrors.length}
                />
            </CardContent>
        </form>
    </Card>
);

EditRemarketingAdsFormView.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    dailyBudget: PropTypes.string.isRequired,
    dailyBudgetAlert: PropTypes.node.isRequired,
    campaignFinalUrl: PropTypes.string.isRequired,
    changeCampaignField: PropTypes.func.isRequired,
    campaignDescription: PropTypes.string.isRequired,
    campaignFinalUrlError: PropTypes.bool.isRequired,
    dailyBudgetCurrency: PropTypes.string.isRequired,
    campaignLongHeadline: PropTypes.string.isRequired,
    campaignShortHeadline: PropTypes.string.isRequired,
    campaignDescriptionError: PropTypes.bool.isRequired,
    onCampaignCreateFormSubmit: PropTypes.func.isRequired,
    campaignShortHeadlineError: PropTypes.bool.isRequired,
    dailyBudgetErrors: PropTypes.arrayOf(PropTypes.bool.isRequired).isRequired,
};
