import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { DEFAULT_FILTER_CAMPAIGN, CAMPAIGNS_TYPES } from 'constants/campaign';
import { AD_SPENDS_LIMIT_REACHED } from 'constants/notificationType';
import { requestSendEventMetrics } from 'actions/shop';
import { requestShopStatisticsData, filterFacebookCampaigns, filterGoogleCampaigns } from 'actions/campaign';
import { trackEventStandardCampaignDateRangeChanged } from 'actions/intercom';
import { shopHasNotification } from 'helpers';
import {
    DATE_RANGES,
    dateRangeList,
    getStatisticsDataToRender,
    getStatisticsDataToRenderMainList,
    getStatisticsDataToRenderSubsectionList,
    getStatisticsDataToRenderMainPurchaseTipsList,
} from './constants';
import { getCustomDateRange } from './insights.utils';

import Statistics from './Statistics';
import { StatisticsFilters } from './Statistics/components/StatisticsFilters';

import './Insights.css';

export class Insights extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sinceDate: null,
            untilDate: null,
            dateRange: props.initialDateRange,
            dateRangeItemActive: props.initialDateRange,
            [CAMPAIGNS_TYPES.google]: props.googleFilterCampaignId,
            [CAMPAIGNS_TYPES.facebook]: props.facebookFilterCampaignId,
        };
    }

    componentDidMount() {
        const { type, shopId, campaignType, reqStatisticsData } = this.props;

        if (type === campaignType) {
            const currentDateRange = this.state.dateRange;

            reqStatisticsData(shopId, {
                campaignType: type,
                dateRange: currentDateRange,
                campaignId:
                    this.state?.[campaignType] && this.state?.[campaignType] !== DEFAULT_FILTER_CAMPAIGN
                        ? this.state?.[campaignType]
                        : null,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { type, shopId, campaignType, reqStatisticsData } = this.props;
        const { sinceDate, untilDate, dateRange } = this.state;

        if (campaignType !== prevProps.campaignType) {
            if (type === campaignType) {
                reqStatisticsData(shopId, {
                    dateRange,
                    sinceDate,
                    untilDate,
                    campaignType,
                    campaignId:
                        this.state?.[campaignType] && this.state?.[campaignType] !== DEFAULT_FILTER_CAMPAIGN
                            ? this.state?.[campaignType]
                            : null,
                });
            }
        }
    }

    dateRangeChangeHandler = ({ dateRange, sinceDate, untilDate }) => {
        const { shopId, campaignType, reqStatisticsData } = this.props;

        const { dateRangeItemActive } = this.state;

        const prevDateRange = dateRangeItemActive !== dateRange ? dateRangeItemActive : null;

        reqStatisticsData(shopId, {
            dateRange,
            sinceDate,
            untilDate,
            campaignType,
            prevDateRange,
            prevCampaignType: null,
            campaignId:
                this.state?.[campaignType] && this.state?.[campaignType] !== DEFAULT_FILTER_CAMPAIGN
                    ? this.state?.[campaignType]
                    : null,
        });

        this.setState({
            sinceDate,
            untilDate,
            dateRange,
            dateRangeItemActive: dateRange || (sinceDate && getCustomDateRange(sinceDate, untilDate)),
        });
    };

    campaignChangeHandler = (campaignId) => {
        const { shopId, campaignType, reqStatisticsData, filterGoogleCampaigns, filterFacebookCampaigns } = this.props;
        const { sinceDate, untilDate, dateRange, dateRangeItemActive } = this.state;

        if (campaignType === CAMPAIGNS_TYPES.google) {
            filterGoogleCampaigns(campaignId);
        } else if (campaignType === CAMPAIGNS_TYPES.facebook) {
            filterFacebookCampaigns(campaignId);
        }

        const prevCampaignType = null;
        const prevDateRange = dateRangeItemActive !== dateRange ? dateRangeItemActive : null;

        reqStatisticsData(shopId, {
            sinceDate,
            untilDate,
            campaignType,
            prevDateRange,
            prevCampaignType,
            dateRange: dateRangeItemActive,
            campaignId: campaignId === DEFAULT_FILTER_CAMPAIGN ? null : campaignId,
        });

        this.setState({ [campaignType]: campaignId });
    };

    render() {
        const {
            shopId,
            campaignType,
            googleCampaigns,
            currentStatistics,
            campaignDetailsData,
            statisticsRequestPending,
            requestSendEventMetrics,
            isAdSpendsOverTheLimit,
        } = this.props;
        const { campaignId, dateRangeItemActive } = this.state;

        const { currency } = (campaignDetailsData && campaignDetailsData[0]) || {};
        const statisticsDataToRender = getStatisticsDataToRender(currency);

        if (currentStatistics) {
            Object.keys(currentStatistics).forEach((metric) => {
                const item = statisticsDataToRender[metric];

                if (item) {
                    item.valueCurrent = item.isFloat
                        ? (+currentStatistics[metric]).toFixed(2)
                        : +currentStatistics[metric];
                }
            });
        }

        return (
            <section className="statistics__wrapper">
                <StatisticsFilters
                    shopId={shopId}
                    campaignId={campaignId}
                    campaignType={campaignType}
                    dateRangeList={dateRangeList}
                    googleCampaigns={googleCampaigns}
                    isPending={statisticsRequestPending}
                    dateRangeActive={dateRangeItemActive}
                    campaignDetailsData={campaignDetailsData}
                    onDateRangeChange={this.dateRangeChangeHandler}
                    requestSendEventMetrics={requestSendEventMetrics}
                    campaignChangeHandler={this.campaignChangeHandler}
                    activeCampaign={this.state?.[campaignType] || DEFAULT_FILTER_CAMPAIGN}
                />
                <Statistics
                    dataMainList={getStatisticsDataToRenderMainList(statisticsDataToRender)}
                    dataSubsectionList={getStatisticsDataToRenderSubsectionList(statisticsDataToRender)}
                    dataMainPurchaseTipsList={getStatisticsDataToRenderMainPurchaseTipsList(statisticsDataToRender)}
                    isAdSpendsOverTheLimit={isAdSpendsOverTheLimit}
                />
            </section>
        );
    }
}

Insights.propTypes = {
    shopId: PropTypes.string.isRequired,
    initialDateRange: PropTypes.string.isRequired,
    campaignDetailsData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
        }),
    ).isRequired,
    currentStatistics: PropTypes.shape({
        add_to_card: PropTypes.string,
        cpc: PropTypes.number,
        cpp: PropTypes.number,
        ctr: PropTypes.number,
        link_clicks: PropTypes.number,
        products_views: PropTypes.string,
        purchases: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        purchases_value: PropTypes.number,
        reach: PropTypes.number,
        roas: PropTypes.number,
        spend: PropTypes.number,
    }),
    statisticsRequestPending: PropTypes.bool.isRequired,
    reqStatisticsData: PropTypes.func.isRequired,
};

Insights.defaultProps = {
    currentStatistics: {
        add_to_card: '0',
        cpc: 0,
        cpp: 0,
        ctr: 0,
        link_clicks: 0,
        products_views: '0',
        purchases: '',
        purchases_value: 0,
        reach: 0,
        roas: 0,
        spend: 0,
    },
};

const mapStateToProps = (state) => ({
    facebookFilterCampaignId: state.campaignDetails.facebookFilterCampaignId,
    googleFilterCampaignId: state.campaignDetails.googleFilterCampaignId,
    googleCampaigns: state.googleCampaigns.googleCampaignsData,
    campaignDetailsData: state.campaignDetails.campaignDetailsData,
    currentStatistics: state.campaignStatistics.campaignStatisticsData,
    statisticsRequestPending: state.campaignStatistics.campaignStatisticsDataPending,
    isAdSpendsOverTheLimit: !!shopHasNotification(state.shopNotifications.entity, AD_SPENDS_LIMIT_REACHED),
});

const mapDispatchToProps = {
    reqStatisticsData: (shopId, params) => (dispatch) =>
        dispatch(requestShopStatisticsData(shopId, params)).then((res) => {
            if (params.prevDateRange) {
                dispatch(
                    trackEventStandardCampaignDateRangeChanged({
                        prev_date_range: params.prevDateRange,
                        next_date_range: params.dateRange || DATE_RANGES.CUSTOM,
                    }),
                );
            }
            return res;
        }),
    filterFacebookCampaigns,
    filterGoogleCampaigns,
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
