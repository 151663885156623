import React from 'react';
import IntercomChatCaller from 'components/IntercomChatCaller';

const LoginErrorMessageMissingEmail = () => (
    <div>
        <p className="alert-description__item">
            The Facebook profile you logged in with doesn't have an email associated with it.
            <br />
            <br />
            Adwisely cares about the security of your account,
            <br />
            so <span className="font-weight-bold"> we require an email address</span> as additional means of
            authentication.
            <br />
            <br /> To proceed with the sign-up, click <span className="font-weight-bold">
                Continue with Google
            </span> or{' '}
            <IntercomChatCaller
                content="contact us"
                className="alert-description__link alert-description__link_highlighted"
            />{' '}
            if you don't have a Google account.
        </p>
    </div>
);

export default LoginErrorMessageMissingEmail;
