export const FACEBOOK_ONBOARDING_TYPES = {
    facebook_visitor_retargeting: 'facebook_visitor_retargeting',
    facebook_dynamic_retargeting: 'facebook_dynamic_retargeting',
    facebook_social_media_retargeting: 'facebook_social_media_retargeting',
    facebook_prospecting: 'facebook_prospecting',
    facebook_special_offer: 'facebook_special_offer',
    facebook_childbm: 'facebook_childbm',
    facebook_ai_boosted_sales: 'facebook_ai_boosted_sales'
}

export const FACEBOOK_ONBOARDING_NAMES = Object.values(FACEBOOK_ONBOARDING_TYPES);

export const ONBOARDING_TYPES = {
    ...FACEBOOK_ONBOARDING_TYPES,
    google: 'google',
    tik_tok: 'tiktok',
};

export const СBM_ONBOARDING_STATES = {
    not_started: 'not_started',
    started: 'started',
    done: 'done',
};
