import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { ArrowLeft, ArrowRight } from 'components/Icons';
import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { StandardToggler } from 'components/DropdownToggler';
import { DropdownItemIcon } from 'components/DropdownItem';

import {
    PREVIEW_TYPE_DESKTOP_FEED_STANDARD,
    PREVIEW_TYPE_RIGHT_COLUMN_STANDARD,
    PREVIEW_TYPE_MOBILE_FEED_STANDARD,
    PREVIEW_TYPE_INSTANT_ARTICLES_STANDARD,
    PREVIEW_TYPE_MARKETPLACE_MOBILE,
    PREVIEW_TYPE_FACEBOOK_STORY_MOBILE,
    PREVIEW_TYPE_INSTAGRAM_STORY,
    PREVIEW_TYPE_INSTAGRAM_STANDARD,
    PREVIEW_TYPE_MESSENGER_MOBILE_INBOX_MEDIA,
} from 'constants/adPreview';
import { metricType } from 'constants/metric';

import previewDesktopNewsFeedIcon from './preview-desktop-news-feed-icon.png';
import previewMobileNewsFeedIcon from './preview-mobile-news-feed-icon.png';
import previewDesktopRightColumnIcon from './preview-desktop-right-column-icon.png';
import previewInstantArticlesIcon from './preview-instant-articles-icon.png';
import previewMarketplaceIcon from './preivew-marketplace-icon.png';
import previewFacebookStoryIcon from './preview-facebook-story-icon.png';
import previewInstagramStoryIcon from './preview-instagram-story-icon.png';
import previewInstagramFeedIcon from './preview-instagram-feed-icon.png';
import previewMessengerInboxIcon from './preview-messenger-inbox.png';

import '../AdPreview.css';

const PREVIEW_TYPES_LIST = [
    PREVIEW_TYPE_DESKTOP_FEED_STANDARD,
    PREVIEW_TYPE_MOBILE_FEED_STANDARD,
    PREVIEW_TYPE_RIGHT_COLUMN_STANDARD,
    PREVIEW_TYPE_INSTANT_ARTICLES_STANDARD,
    PREVIEW_TYPE_MARKETPLACE_MOBILE,
    PREVIEW_TYPE_FACEBOOK_STORY_MOBILE,
    PREVIEW_TYPE_INSTAGRAM_STORY,
    PREVIEW_TYPE_INSTAGRAM_STANDARD,
    PREVIEW_TYPE_MESSENGER_MOBILE_INBOX_MEDIA,
];

const PREVIEW_TYPES = {
    [PREVIEW_TYPE_DESKTOP_FEED_STANDARD]: {
        name: 'Desktop News Feed',
        icon: previewDesktopNewsFeedIcon,
    },
    [PREVIEW_TYPE_MOBILE_FEED_STANDARD]: {
        name: 'Mobile News Feed',
        icon: previewMobileNewsFeedIcon,
    },
    [PREVIEW_TYPE_RIGHT_COLUMN_STANDARD]: {
        name: 'Desktop Right Column',
        icon: previewDesktopRightColumnIcon,
    },
    [PREVIEW_TYPE_INSTANT_ARTICLES_STANDARD]: {
        name: 'Facebook Instant Articles',
        icon: previewInstantArticlesIcon,
    },
    [PREVIEW_TYPE_MARKETPLACE_MOBILE]: {
        name: 'Facebook Marketplace',
        icon: previewMarketplaceIcon,
    },
    [PREVIEW_TYPE_FACEBOOK_STORY_MOBILE]: {
        name: 'Facebook Stories',
        icon: previewFacebookStoryIcon,
    },
    [PREVIEW_TYPE_INSTAGRAM_STORY]: {
        name: 'Instagram Stories',
        icon: previewInstagramStoryIcon,
    },
    [PREVIEW_TYPE_INSTAGRAM_STANDARD]: {
        name: 'Instagram Feed',
        icon: previewInstagramFeedIcon,
    },
    [PREVIEW_TYPE_MESSENGER_MOBILE_INBOX_MEDIA]: {
        name: 'Messenger inbox',
        icon: previewMessengerInboxIcon,
    },
};

const propTypes = {
    changePreviewType: PropTypes.func.isRequired,
    previewType: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const CHANGED_PREVIEW = {
    dropDown: 'drop-down',
    arrows: 'arrows',
};

export default class PreviewTypeSelect extends Component {
    constructor(props) {
        super(props);

        this.isActivePreview = this.isActivePreview.bind(this);
        this.changePreviewType = this.changePreviewType.bind(this);
        this.shiftNextPreviewType = this.shiftNextPreviewType.bind(this);
        this.shiftPrevPreviewType = this.shiftPrevPreviewType.bind(this);
        this.sendEventMetrics = this.sendEventMetrics.bind(this);
    }

    changePreviewType(type) {
        const { adPreviewRequesting } = this.props;
        if (adPreviewRequesting) return;
        this.props.changePreviewType(type);
    }

    isActivePreview(type) {
        return type === this.props.previewType;
    }

    sendEventMetrics(type, changedVia) {
        const { campaignType, sourcePage, requestSendEventMetrics, shopId } = this.props;
        const metricProps = {
            shop_id: +shopId,
            type: metricType.adPreviewPlacementChanged,
            props: {
                tactic: campaignType,
                source: sourcePage,
                placement: type,
                changed_via: changedVia,
            },
        };

        requestSendEventMetrics(metricProps);
    }

    shiftPrevPreviewType() {
        const { previewType } = this.props;
        const totalLen = PREVIEW_TYPES_LIST.length;
        const newIndex = PREVIEW_TYPES_LIST.indexOf(previewType) - 1;
        const newType = PREVIEW_TYPES_LIST[newIndex < 0 ? totalLen - 1 : newIndex];
        this.changePreviewType(newType);
        this.sendEventMetrics(newType, CHANGED_PREVIEW.arrows);
    }

    shiftNextPreviewType() {
        const { previewType } = this.props;

        const totalLen = PREVIEW_TYPES_LIST.length;
        const newIndex = PREVIEW_TYPES_LIST.indexOf(previewType) + 1;
        const newType = PREVIEW_TYPES_LIST[newIndex >= totalLen ? 0 : newIndex];
        this.changePreviewType(newType);
        this.sendEventMetrics(newType, CHANGED_PREVIEW.arrows);
    }

    render() {
        const { disabled } = this.props;

        return (
            <div className="ad-preview-section__navbar">
                <section className="ad-preview-section-navbar__section ad-preview-section-navbar__section_dropdown">
                    <DropdownProvider>
                        {({ isOpen, toggle }) => {
                            const dropdownItems = PREVIEW_TYPES_LIST.map((type, key) => {
                                const item = PREVIEW_TYPES[type];
                                return (
                                    <DropdownItemIcon
                                        key={key}
                                        active={this.isActivePreview(type)}
                                        icon={item.icon}
                                        title={item.name}
                                        onClick={() => {
                                            this.changePreviewType(type);
                                            toggle(false);
                                            this.sendEventMetrics(type, CHANGED_PREVIEW.dropDown);
                                        }}
                                    />
                                );
                            });

                            const flagToggler = (
                                <StandardToggler disabled={disabled}>
                                    {PREVIEW_TYPES[this.props.previewType].name}
                                </StandardToggler>
                            );

                            return (
                                <Dropdown
                                    isOpen={isOpen}
                                    toggle={toggle}
                                    flagToggler={flagToggler}
                                    items={dropdownItems}
                                    additionalMenuClassName="ad-preview-dropdown__menu"
                                />
                            )
                        }}
                    </DropdownProvider>
                </section>

                <section className="ad-preview-section-navbar__section ad-preview-section-navbar__section_pagination">
                    <span className="ad-preview-pagination__title">
                        {`${PREVIEW_TYPES_LIST.indexOf(this.props.previewType) + 1} `}
                        of {PREVIEW_TYPES_LIST.length}
                    </span>
                    <Button onClick={this.shiftPrevPreviewType} square disabled={disabled}>
                        <ArrowLeft />
                    </Button>
                    <Button onClick={this.shiftNextPreviewType} square disabled={disabled}>
                        <ArrowRight />
                    </Button>
                </section>
            </div>
        );
    }
}

PreviewTypeSelect.propTypes = propTypes;
