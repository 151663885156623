import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import ShopifyBilling from 'modules/onboarding/components/ShopifyBilling/ShopifyBilling';

import {setGoogleOnboardingState} from 'actions/googleOnboardingState';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { ONBOARDING_TYPES } from 'constants/onboarding';

import {changeOnboardingState} from '../../../../home/utils';
import {googleStatePolling} from '../../helpers/googleStatePolling';

export const ShopifyBillingGoogleView = ({shopId, replace, setGoogleOnboardingState}) => {
    const onRecurringChargeIsApproved = () => {
        changeOnboardingState({
            currentShopId: shopId,
            type: ONBOARDING_TYPES.google,
            status: ONBOARDING_STATES.approve_app_fee,
        }).then((res) => {
            if (res.status === 409) {
                googleStatePolling(shopId, replace, setGoogleOnboardingState)
            }
            return res.json()
        })
            .then((res) => {
                if (res?.processing) {
                    googleStatePolling(shopId, replace, setGoogleOnboardingState);
                }
            });
    }

    return <ShopifyBilling
        onRecurringChargeIsApproved={onRecurringChargeIsApproved} />
}

const mapStateToProps = ({ shops }) => (
    {
        shopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace,
    setGoogleOnboardingState: setGoogleOnboardingState

};

export const ShopifyBillingGoogle = connect(mapStateToProps, mapPropsToDispatch)(ShopifyBillingGoogleView);