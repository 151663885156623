import {
    SHOP_QUALIFICATION_FAILURE,
    SHOP_QUALIFICATION_REQUEST,
    SHOP_QUALIFICATION_SUCCESS,
} from 'actions/shopQualification';

const initialState = {
    criterias: [],
    pending: false,
    success: false,
    failure: false,
};

// TODO: 1. Empty list from server
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOP_QUALIFICATION_REQUEST:
            return {
                ...initialState,
                pending: true,
            };
        case SHOP_QUALIFICATION_SUCCESS:
            return {
                ...initialState,
                success: true,
                criterias: payload,
            };
        case SHOP_QUALIFICATION_FAILURE:
            return {
                ...initialState,
                failure: true,
            };
        default:
            return state;
    }
};
