import React from 'react';

const WarningIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28ZM31.5 27.9985V10.4985H24.5V27.9985H31.5ZM31.5 34.9985V41.9985H24.5V34.9985H31.5Z"
            fill="#FE9929"
        />
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28ZM31.5 27.9985V10.4985H24.5V27.9985H31.5ZM31.5 34.9985V41.9985H24.5V34.9985H31.5Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0)">
            <rect x="-182" y="-182" width="420" height="420" fill="#FE9929" />
        </g>
    </svg>
);

export default WarningIcon;
