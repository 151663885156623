import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { stripeProviderPublicKey } from 'constants/stripe';
import ModalAddStripe from './ModalAddStripe';

export default (props) => (
    <StripeProvider apiKey={stripeProviderPublicKey}>
        <Elements>
            <ModalAddStripe {...props} />
        </Elements>
    </StripeProvider>
);
