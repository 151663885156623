export const UBM_ONBOARDING_STATES = {
    not_started: 'not_started',
    started: 'started',
    login: 'login',
    configure_campaign: 'configure_campaign',
    add_payment_method: 'add_payment_method',
    approve_app_fee: 'approve_app_fee',
    select_bm: 'select_bm',
    upgrade_role_in_bm: 'upgrade_role_in_bm',
    create_bm: 'create_bm',
    still_have_no_bm: 'still_have_no_bm',
    create_fb_page: 'create_fb_page',
    choose_or_create_ad_account: 'choose_or_create_ad_account',
    choose_ad_account: 'choose_ad_account',
    assign_system_user_to_page: 'assign_system_user_to_page',
    bm_restricted_exception: 'bm_restricted_exception',
    assign_pixel_to_ad_account: 'assign_pixel_to_ad_account',
    check_pixel_settings: 'check_pixel_settings',
    choose_subscription_plan: 'choose_subscription_plan',
    confirm_shopify_payment: 'confirm_shopify_payment',
    confirm_stripe_payment: 'confirm_stripe_payment',
    confirm_facebook_payment: 'confirm_facebook_payment',
    create_prospecting_campaign: 'create_prospecting_campaign',
    create_campaign_request: 'create_campaign_request',
    create_visitor_retargeting_campaign: 'create_visitor_retargeting_campaign',
    create_dynamic_retargeting_campaign: 'create_dynamic_retargeting_campaign',
    create_social_media_retargeting_campaign: 'create_social_media_retargeting_campaign',
    create_special_offer_campaign: 'create_special_offer_campaign',
    create_ai_boosted_sales_campaign: 'create_ai_boosted_sales_campaign',
    start_free_trial: 'start_free_trial',
    choose_instagram_account: 'choose_instagram_account',
    create_instagram_account: 'create_instagram_account',
    still_have_no_instagram_account: 'still_have_no_instagram_account',
    done: 'done',
};