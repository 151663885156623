import React from 'react';
import PropTypes from 'prop-types';

const GoogleStandardAdPreviewCard = ({ imageLink, title, currency, price, shopName }) => (
    <div className="ad-preview-standard__card">
        <div className="ad-preview-standard__image-wrapper">
            <img className="ad-preview-standard__image" src={imageLink} alt="product" />
        </div>
        <div className="ad-preview-standard__desc">
            <div className="ad-preview-standard__product-name">{title}</div>
            <div className="ad-preview-standard__price">
                {currency} {(price / 100).toFixed(2)}
            </div>
            <div className="ad-preview-standard__shop-name">{shopName}</div>
        </div>
    </div>
);

GoogleStandardAdPreviewCard.propTypes = {
    currency: PropTypes.string.isRequired,
    imageLink: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    shopName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default GoogleStandardAdPreviewCard;
