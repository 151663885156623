export const SubscriptionPlanType = {
    BASIC: 'Basic',
    STANDARD: 'Standard',
    ADVANCED: 'Advanced',
    YEAR_BASIC: 'Annually Basic',
    YEAR_STANDARD: 'Annually Standard',
    YEAR_ADVANCED: 'Annually Advanced',
    YEAR_BASIC_BFCM_2022: 'Annually Basic BFCM 2022 SALE',
    YEAR_STANDARD_BFCM_2022: 'Annually Standard BFCM 2022 SALE',
    YEAR_ADVANCED_BFCM_2022: 'Annually Advanced BFCM 2022 SALE',
    YEAR_BASIC_EASTER_2023: 'Annually Basic Easter 2023 SALE',
    YEAR_STANDARD_EASTER_2023: 'Annually Standard Easter 2023 SALE',
    YEAR_ADVANCED_EASTER_2023: 'Annually Advanced Easter 2023 SALE',
    STARTER: 'Starter',
    REGULAR: 'Regular',
    QUARTERLY_STARTER: 'Quarterly Starter',
    QUARTERLY_REGULAR: 'Quarterly Regular'
}