import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import SubscriptionPlan from 'modules/onboarding/components/SubscriptionPlan';

import { getLocalStorageObjectItem } from 'helpers';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { changeOnboardingState } from 'modules/home/utils';

const SubscriptionPlanFacebookView = ({currentShopId, replace}) => {
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');
    const onSubscriptionPlanSelectSuccess = () => {
        changeOnboardingState({
            type: currentOnboarding,
            status: UBM_ONBOARDING_STATES.choose_subscription_plan,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then((res) => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace)
                }
            });
    }

    return (
        <SubscriptionPlan
            onSubscriptionPlanSelectSuccess={onSubscriptionPlanSelectSuccess}
        />
    );
};

const mapStateToProps = ({ shops }) => (
    {
        currentShopId: shops?.currentShopId,
    }
);

const mapPropsToDispatch = {
    replace,
};

export const SubscriptionPlanFacebook = connect(mapStateToProps, mapPropsToDispatch)(SubscriptionPlanFacebookView);