import React, { Component } from 'react';
import cx from 'classnames';

import './CampaignDetailsPromo.css';

export const TYPE_XMAS = 'xmas';
export const TYPE_BFCM = 'bfcm';

class CampaignDetailsPromo extends Component {
    state = {
        loading: false,
    };

    hideLoader = () => this.setState({ loading: false });
    showLoader = () => this.setState({ loading: true });

    ctaClicked = () => {
        const { ctaAction } = this.props;
        this.showLoader();
        ctaAction(this.hideLoader);
    };

    render() {
        const { loading } = this.state;
        const { msg, ctaMsg, promoType } = this.props;

        return (
            <section
                className={cx('dashboard-campaign-promo', {
                    'dashboard-campaign-promo--xmas': promoType === TYPE_XMAS,
                })}
            >
                {promoType === TYPE_BFCM && <span className="dashboard-campaign-promo__badge">Promo</span>}

                <div className="dashboard-campaign-promo__content">
                    <p className="dashboard-campaign-promo__text">{msg}</p>
                    <button
                        className={cx(
                            `dashboard-campaign-promo__button dashboard-campaign-promo__button--${promoType}`,
                            {
                                [`dashboard-campaign-promo__button_loading--${promoType}`]: loading,
                            },
                        )}
                        onClick={this.ctaClicked}
                    >
                        {ctaMsg}
                    </button>
                </div>
            </section>
        );
    }
}

export default CampaignDetailsPromo;
