import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getLocalStorageObjectItem } from 'helpers';
import TipsWidget from 'components/TipsWidget';
import { ONBOARDING_TYPES } from 'constants/onboarding';

import './ConnectedAssets.css';

const ConnectedAssets = ({ currentShopId }) => {
    const [connectedAssets, setConnectedAssets] = useState({});
    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    useEffect(() => {
        if (currentOnboarding === ONBOARDING_TYPES.facebook_prospecting ||
            currentOnboarding === ONBOARDING_TYPES.facebook_visitor_retargeting ||
            currentOnboarding === ONBOARDING_TYPES.facebook_dynamic_retargeting ||
            currentOnboarding === ONBOARDING_TYPES.facebook_social_media_retargeting ||
            currentOnboarding === ONBOARDING_TYPES.facebook_ai_boosted_sales) {
            fetch(`/api/onboarding/${currentShopId}/${currentOnboarding}/connected_assets`)
                .then(res => res.json()
                .then(res => setConnectedAssets(res.data)));
        }
    }, []);

    const getBusinessManager = (connectedBM) => (
        <div className="connected-assets__item">
            <div className="connected-assets__caption">
                <span>Business Manager</span>
                <TipsWidget placement="top" blueWarningIcon>
                    We create or use your ad account inside your Business Manager.
                    If you only have 1 Business Manager, it’s connected automatically
                </TipsWidget>
            </div>
            <p className="connected-assets__info" title={connectedBM?.name}>{`[${connectedBM?.name}]`}</p>
            <p className="connected-assets__info" title={`[ID:${connectedBM?.id}]`}>{`[ID:${connectedBM?.id}]`}</p>
        </div>
    )

    const getPage = (connectedPage) => (
        <div className="connected-assets__item">
            <div className="connected-assets__caption">
                <span>Facebook Page</span>
                <TipsWidget placement="top" blueWarningIcon>
                    We run ads on behalf of Facebook Page of your business.
                    If you only are an Admin of only 1 Facebook Page, it’s connected automatically
                </TipsWidget>
            </div>

            <p className="connected-assets__info" title={connectedPage?.name}>{`[${connectedPage?.name}]`}</p>
            <p className="connected-assets__info" title={`[ID:${connectedPage?.id}]`}>{`[ID:${connectedPage?.id}]`}</p>
        </div>
    )

    return (
        <div className="connected-assets">
            <h3 className="connected-assets__title">Connected assets</h3>
            {connectedAssets?.business_manager && getBusinessManager(connectedAssets?.business_manager)}
            {connectedAssets?.page && getPage(connectedAssets?.page)}
        </div>
    )
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

export default connect(mapStateToProps)(ConnectedAssets);
