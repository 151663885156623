import React, { useState } from 'react';

import { AD_SPENDS_LIMIT_REACHED } from 'constants/notificationType';
import { shopHasNotification } from 'helpers';
import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

const ExtraAdSpendsWarning = ({ shopNotifications }) => {
    const [isShow, setIsShow] = useState(true);
    const isAdSpendsOverTheLimit = !!shopHasNotification(shopNotifications, AD_SPENDS_LIMIT_REACHED);

    let adSpendsWarning;

    const closeNotification = () => setIsShow(false);

    if (isAdSpendsOverTheLimit) {
        adSpendsWarning = (
            <div>
                <p className="notification__title">Consider updating your plan</p>

                <p className="notification__description notification__description-bottom-margin-8">
                    You have exceeded the ad spend limit on your current plan.
                    In beginning of the next billing period, you will pay 10% fee on all the spend above the limit.
                </p>

                <p className="notification__description">
                    To increase your ad spend limit and get more sales, consider upgrading your plan.
                </p>

                <Button color="transparent-bordered" url="mailto:help@adwisely.com?subject=I%20would%20like%20to%20upgrade%20my%20Adwisely%20plan">
                    Talk to support
                </Button>

                <Button color="transparent-no-border" onClick={closeNotification}>
                    I want to stay on current plan
                </Button>
            </div>
        );
    } else {
        adSpendsWarning = null;
    }

    if (adSpendsWarning && isShow) {
        return (
            <section className="dashboard-page__section">
                <NotificationWidget type="warning">{adSpendsWarning}</NotificationWidget>
            </section>
        );
    }
    
    return null;
}

export default ExtraAdSpendsWarning;
