/* eslint-disable */

import React, { Component } from 'react';

import './SMSInput.css';

const KEY_CODE = {
    up: 38,
    left: 37,
    down: 40,
    right: 39,
    backspace: 8,
};

// TODO: the component was copied from react sms input library - better to remove unused functionality
export class SMSInput extends Component {
    static defaultProps = {
        fields: 6,
        autoFocus: true,
        disabled: false,
        required: false,
        placeholder: [],
    };

    constructor(props) {
        super(props);
        const { fields, values } = props;

        let vals;
        let autoFocusIndex = 0;
        if (values && values.length) {
            vals = [];
            for (let i = 0; i < fields; i++) {
                vals.push(values[i] || '');
            }
            autoFocusIndex = values.length >= fields ? 0 : values.length;
        } else {
            vals = Array(fields).fill('');
        }
        this.state = { values: vals, autoFocusIndex };

        this.iRefs = [];
        for (let i = 0; i < fields; i++) {
            this.iRefs.push(React.createRef());
        }
        this.id = +new Date();
    }

    triggerChange = (values = this.state.values) => {
        const { onChange, onComplete, fields } = this.props;
        const val = values.join('');
        onChange && onChange(val);
        if (onComplete && val.length >= fields) {
            onComplete(val);
        }
    };

    onChange = (e) => {
        const { setSmsCode } = this.props;
        const index = parseInt(e.target.dataset.id);

        const { fields } = this.props;
        let next;
        const value = e.target.value;
        let { values } = this.state;
        values = Object.assign([], values);
        if (value.length > 1) {
            let nextIndex = value.length + index - 1;
            if (nextIndex >= fields) {
                nextIndex = fields - 1;
            }
            next = this.iRefs[nextIndex];
            const split = value.split('');
            split.forEach((item, i) => {
                const cursor = index + i;
                if (cursor < fields) {
                    values[cursor] = item;
                }
            });
            this.setState({ values });
        } else {
            next = this.iRefs[index + 1];
            values[index] = value;
            this.setState({ values });
        }

        if (next) {
            next.current.focus();
            next.current.select();
        }

        setSmsCode(values.join(''));
        this.triggerChange(values);
    };

    onKeyDown = (e) => {
        const { setSmsCode, setHasSmsCodeVerificationError } = this.props;
        const index = parseInt(e.target.dataset.id);

        const prevIndex = index - 1;
        const nextIndex = index + 1;

        const prev = this.iRefs[prevIndex];
        const next = this.iRefs[nextIndex];

        switch (e.keyCode) {
            case KEY_CODE.backspace:
                e.preventDefault();
                const vals = [...this.state.values];
                if (this.state.values[index]) {
                    vals[index] = '';
                    this.setState({ values: vals });
                    this.triggerChange(vals);
                } else if (prev) {
                    vals[prevIndex] = '';
                    prev.current.focus();
                    this.setState({ values: vals });
                    this.triggerChange(vals);
                }
                setSmsCode(vals?.join(''));

                if (vals?.join('')?.length === 0) {
                    setHasSmsCodeVerificationError(false)
                }

                break;
            case KEY_CODE.left:
                e.preventDefault();
                if (prev) {
                    prev.current.focus();
                }
                break;
            case KEY_CODE.right:
                e.preventDefault();
                if (next) {
                    next.current.focus();
                }
                break;
            case KEY_CODE.up:
            case KEY_CODE.down:
                e.preventDefault();
                break;
            default:
                break;
        }
    };

    onFocus = (e) => {
        e.target.select(e);
    };

    render() {
        const { values, autoFocusIndex } = this.state;
        const { id, autoFocus, hasSmsCodeVerificationError } = this.props;

        return (
            <>
                <div>
                    {values.map((value, index) => (
                        <input
                            type="tel"
                            value={value}
                            data-id={index}
                            ref={this.iRefs[index]}
                            onFocus={this.onFocus}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            key={`${this.id}-${index}`}
                            autoFocus={autoFocus && index === autoFocusIndex}
                            id={id ? `${id}-${index}` : null}
                            className={[
                                hasSmsCodeVerificationError && 'gl-onboarding-settings__modal__sms__input_error',
                                'gl-onboarding-settings__modal__sms__input',
                            ].join(' ')}
                        />
                    ))}
                </div>
            </>
        );
    }
}
