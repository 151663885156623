import * as onboarding from '../../actions/onboarding';

const initialState = {
    fbAuthSuccess: false,
    fbAuthFailed: false,
    fbAuthInProgress: false,
    fbLoginLink: null,
    fbLoginLinkRequestSuccess: false,
    fbLoginLinkRequestError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case onboarding.ONBOARDING_FACEBOOK_LOGIN_REQUEST:
            state = {
                ...state,
                fbAuthInProgress: true,
                fbAuthSuccess: false,
                fbAuthFailed: false,
            };
            break;

        case onboarding.ONBOARDING_FACEBOOK_LOGIN_SUCCESS:
            state = {
                ...state,
                fbAuthInProgress: false,
                fbAuthSuccess: true,
                fbAuthFailed: false,
            };
            break;

        case onboarding.ONBOARDING_FACEBOOK_LOGIN_FAILURE:
            state = {
                ...state,
                fbAuthInProgress: false,
                fbAuthSuccess: false,
                fbAuthFailed: true,
            };
            break;

        case onboarding.ONBOARDING_FB_LOGIN_LINK_REQUEST_SUCCESS:
            state = {
                ...state,
                fbLoginLinkRequestSuccess: true,
                fbLoginLinkRequestError: false,
                fbLoginLink: action.payload,
            };
            break;

        case onboarding.ONBOARDING_FB_LOGIN_LINK_REQUEST_FAILURE:
            state = {
                ...state,
                fbLoginLinkRequestSuccess: false,
                fbLoginLinkRequestError: true,
                fbLoginLink: null,
            };
            break;

        default:
            break;
    }

    return state;
};
