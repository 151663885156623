import React, {useEffect, useState} from 'react';

import Card, { CardContent, CardTitle } from 'components/Card';

import './AdAudience.css';

import { CountriesDropdown } from './components/CountriesDropdown/CountriesDropdown';
import {
    handleGetSearchedCountries,
    handleOnCountryListItemClick,
    handleOnSelectedCountryClick,
    handleOnWorldwideItemClick
} from './helpers/handleAdAudienceState';
import { GenderDropdown } from './components/GenderDropdown/GenderDropdown';
import { AgeDropdown } from './components/AgeDropdown/AgeDropdown';

export const AdAudience = ({
                               isDropdownError,
                               countriesList,
                               availableCountries,
                               isWorldwide,
                               isWorldwideAvailable,
                               setCountriesList,
                               setAvailableCountries,
                               setIsWorldWide,
                               onAdAudienceSettingsChange,
                               searchedCountries,
                               setSearchedCountries,
                               maxNumOfCountries,
                               genders,
                               setGenders,
                               availableGenders,
                               availableAgeMax,
                               availableAgeMin,
                               ageMax,
                               setAgeMax,
                               ageMin,
                               setAgeMin,
                               isGenderVisible,
                               isAgeVisible,
                               isCountriesVisible
        }) => {
    const [countryFilter, setCountryFilter] = useState('');

    const onCountryListItemClick = (id) => {
        handleOnCountryListItemClick({
            id,
            availableCountries,
            countriesList,
            setCountriesList,
            setAvailableCountries,
            setSearchedCountries,
            countryFilter,
            setIsWorldwide: setIsWorldWide,
            maxNumOfCountries
        })
    }

    const onSelectedCountryClick = (id) => {
        handleOnSelectedCountryClick({
            id,
            countriesList,
            setCountriesList,
            setAvailableCountries,
            availableCountries,
            setSearchedCountries,
            countryFilter
        });
    }

    const onWorldwideItemClick = () => {
        handleOnWorldwideItemClick({
            setCountriesList,
            isWorldwide,
            setIsWorldWide,
            setAvailableCountries,
            countriesList,
            countryFilter,
            availableCountries,
            setSearchedCountries
        })
    }

    const handleCountriesSearch = ({ target }) => {
        setCountryFilter(target.value?.toLowerCase());
    };

    const resetCountriesFilter = () => {
        setCountryFilter('');
    };

    useEffect(() => {
        handleGetSearchedCountries({countriesList: availableCountries, countryFilter, setSearchedCountries })
    }, [countryFilter])

    useEffect(() => {
        onAdAudienceSettingsChange(countriesList, isWorldwide, ageMin, ageMax, genders)
    }, [countriesList, isWorldwide, ageMin, ageMax, genders])

    return (
        <Card>
            <CardTitle className="campaign-details__title" labelFor="adMessageInput" subTitle>
                <span>Ad Audience</span>
            </CardTitle>
            <CardContent>
              {isGenderVisible &&
                  <div className='ad-audience-block'>
                    <GenderDropdown
                        genders={genders}
                        setGenders={setGenders}
                        availableGenders={availableGenders} />
                  </div>
              }

              {isAgeVisible &&
                  <div className='ad-audience-block'>
                    <AgeDropdown
                        startAge={ageMin}
                        endAge={ageMax}
                        setStartAge={setAgeMin}
                        setEndAge={setAgeMax}
                        availableStartAge={availableAgeMin}
                        availableEndAge={availableAgeMax}
                    />
                  </div>
              }

              {isCountriesVisible &&
                  <div className='ad-audience-block'>
                    <CountriesDropdown
                        isDropdownError={isDropdownError}
                        countriesList={countriesList}
                        availableCountries={searchedCountries}
                        isWorldwide={isWorldwide}
                        isWorldwideAvailable={isWorldwideAvailable}
                        onWorldwideItemClick={onWorldwideItemClick}
                        handleCountriesSearch={handleCountriesSearch}
                        resetCountriesFilter={resetCountriesFilter}
                        onSelectedCountryClick={onSelectedCountryClick}
                        onCountryListItemClick={onCountryListItemClick}
                        maxNumOfCountries={maxNumOfCountries}
                    />
                  </div>
              }
            </CardContent>
        </Card>
    )
}