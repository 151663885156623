import React, { useState, useEffect } from 'react';

const tips = [
    'The ads campaign needs at least 15 days for training (learning phase), during this time the results of the campaign will be insignificant.',
    'If you are launching a Google Ads campaign for the first time, you will automatically receive a welcome bonus of up to $500, depending on your country.',
    'Your ad will be placed on Google Search, YouTube, Gmail, Discovery, and throughout the Google Display Network.',
    'Google Ads uses artificial intelligence to make your advertising as effective as possible.',
];

export const TipsLoaderLine = ({ max, step }) => (
    <div className="tips__loader__wrapper">

        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img width={`${(step / max) * 100}%`} className="tips__loader" />
    </div>
);

export const Tips = () => {
    const [state, setState] = useState(0);

    useEffect(() => {
        const id = setInterval(() => {
            setState((i) => (i > 2 ? 0 : i + 1));
        }, 5000);

        return () => clearInterval(id);
    });

    return <>
        <div className="error-screen__tips__container">{tips[state]}</div>
        <TipsLoaderLine max={tips.length} step={state} />
    </>;
};
