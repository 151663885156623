import React from 'react';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import WizardBody from './WizardBody';

const Wizard = ({ children }) => (
    <div className='wizard-wrapper'>
        <LoaderFullScreen
            isLoaderShowed={!children}
        >
            <LoaderLongWait title="Loading..." />
        </LoaderFullScreen>
        <WizardBody>{children}</WizardBody>
    </div>
);


export default Wizard;
