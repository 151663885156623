import React from 'react';
import './FacebookDesktop.css';

import checkmark from '../../img/checkmark.svg';
import planet from '../../img/planet.svg';

import ctaBtn from './img/cta-btn.svg';
import bottom from './img/bottom.svg';
import top from './img/top.svg';

export const FacebookDesktop = ({product}) => (
    <div className="facebook-desktop">
        <div className="facebook__social-approve">
            <img src={top} alt={'facebook'} />
            <div>
                <span>Mark Zuckerberg </span>like <span>{product.url}</span>
            </div>
        </div>
        <div className="facebook-desktop__header">
            <div className="facebook-desktop__avatar-wrapper">
                <div className="facebook-desktop__avatar" />
                <div className="facebook-desktop__name-wrapper">
                    <div className="facebook-desktop__name">
                        <span>{product.url}</span>
                        <img src={checkmark} alt='checkmark'/>
                    </div>
                    <div className="facebook-desktop__description">
                        <span>Sponsored ·</span>
                        <img src={planet} alt="planet-icon"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="facebook-desktop__text">
            <span>{product.description}</span>
        </div>
        <div className="facebook-desktop__cards">
            <div className="facebook-desktop-card">
                <div className="facebook-desktop__img-wrapper">
                    <img src={product.img} alt={product.title} />
                </div>
                <div className="facebook-desktop__cta">
                    <div className="facebook-desktop__cta-content">
                        <div className="facebook-desktop__cta-title">{product.title}</div>
                        <div className="facebook-desktop__cta-url">{product.price}</div>
                    </div>
                    <img src={ctaBtn} alt="button"/>
                </div>
            </div>
            <div className="facebook-desktop-card">
                <div className="facebook-desktop__img-wrapper">
                    <img src={product.img} alt={product.title} />
                </div>
                <div className="facebook-desktop__cta">
                    <div className="facebook-desktop__cta-content">
                        <div className="facebook-desktop__cta-title">{product.title}</div>
                        <div className="facebook-desktop__cta-url">{product.price}</div>
                    </div>
                    <img src={ctaBtn} alt="button"/>
                </div>
            </div>
        </div>
        <img src={bottom} alt="facebook social"/>
    </div>
)