import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { requestEditCampaign } from 'actions/campaign';
import { displayBulkNotification } from 'actions/notification';
import { changeDailyBudgetPage, RETARGETING } from 'constants/campaign';

import Button from 'components/Button';
import TipsWidget from 'components/TipsWidget';
import { CardTitle } from 'components/Card';

import {
    numberDigitSeparateWithSpace,
    setLocalStorageObjectItem,
    getLocalStorageObjectItem,
    dayDiff,
} from '../../../helpers';
import { RECOMMENDED_DB_APPROVED } from '../../../constants';

export class DailyBudgetRecommendation extends Component {
    constructor(props) {
        super(props);
        const recommendedDBApproved = getLocalStorageObjectItem(RECOMMENDED_DB_APPROVED);

        const hidePeriod = (startAt, endAt) => {
            const today = new Date();
            return today >= new Date(startAt) && today <= new Date(endAt);
        };

        let hideDailyBudgetRecommendation = hidePeriod('2019-11-18', '2019-12-27');
        /**
         * Set hideDailyBudgetRecommendation to true
         * if recommended DB was applied less then 3 days ago
         * for hiding DailyBudgetRecommendation
         * */
        if (recommendedDBApproved) {
            const recommendedDBApproveDate = recommendedDBApproved[props.campaign.id];
            const hideSuggestedDBAfterThreeDays = dayDiff(new Date(recommendedDBApproveDate), new Date()) < 3;
            hideDailyBudgetRecommendation = props.campaign.type === RETARGETING ? hideSuggestedDBAfterThreeDays : false;
        }
        this.state = {
            recommendedBudgetTipsForceOpen: false,
            hideDailyBudgetRecommendation,
        };

        this.applyDailyBudgetRecommendationHandler = this.applyDailyBudgetRecommendationHandler.bind(this);
    }

    applyDailyBudgetRecommendationHandler(recommendedBudget) {
        const { campaign, editCampaign, displayBulkNotification } = this.props;
        const {
            shop,
            id,
            ad_sets,
            type,
        } = campaign;

        this.setState({ recommendedBudgetTipsForceOpen: true });

        const messages = {};
        ad_sets.forEach(item => {
            messages[item.type] = item.message;
        })

        return editCampaign({
            shopId: shop,
            id,
            messages,
            campaign_type: type,
            daily_budget: recommendedBudget,
            set_from_page: changeDailyBudgetPage.dashboard,
        }).then((res) => {
            if (res instanceof Error) {
                this.setState({ recommendedBudgetTipsForceOpen: false });
                displayBulkNotification("Daily budget suggestion wasn't applied.", 'error');
            } else {
                this.setState({ recommendedBudgetTipsForceOpen: false });
                displayBulkNotification('Daily budget suggestion applied.');
            }
        });
    }

    render() {
        const {
            campaign,
            campaign: { campaignEditPending },
        } = this.props;
        if (this.state.hideDailyBudgetRecommendation) return null;

        const tooltipText =
            campaign.type === RETARGETING ? (
                <p>This is recommended daily budget to retarget all potential buyers.</p>
            ) : (
                <p>
                    Our system analyzed the recent performance of your prospecting ads and determined that they can be
                    more successful if you apply the recommended budget
                </p>
            );

        const getBudgetRecommendationButton = () => {
            const recommendedBudget = `${numberDigitSeparateWithSpace(campaign.recommended_budget / 100)} ${
                campaign.currency
            }`;

            const tipsCustomIcon = (
                <button
                    className={'dashboard-campaign-info__item-value dashboard-campaign-info__item-value_with-cta'}
                    disabled={
                        campaign.settings.daily_budget === campaign.recommended_budget ||
                        campaign.client_status === 'paused'
                    }
                >
                    {recommendedBudget}
                </button>
            );

            const tipsContent = (
                <div className="tips-description__content dashboard-campaign-suggested-budget-tips__content">
                    {tooltipText}
                    <Button
                        className="dashboard-campaign-suggested-budget-tips__cta-button"
                        id="applyRecommendedBudgetButton"
                        onClick={() =>
                            !campaignEditPending &&
                            this.applyDailyBudgetRecommendationHandler(campaign.recommended_budget)
                        }
                        disabled={campaignEditPending}
                        color="light"
                        pending={campaignEditPending}
                    >
                        Apply {recommendedBudget}
                    </Button>
                </div>
            );

            return (
                <TipsWidget
                    isDarkView
                    tipsForceOpen={this.state.recommendedBudgetTipsForceOpen}
                    placement="top"
                    additionalClassName="dashboard-campaign-suggested-budget-tips__wrapper"
                    customIcon={tipsCustomIcon}
                >
                    {tipsContent}
                </TipsWidget>
            );
        };

        let recommendedBudgetSection = null;

        if (campaign.recommended_budget && campaign.type !== 'special_offer') {
            recommendedBudgetSection = (
                <li className="dashboard-campaign-info__item" id="recommendedBudgetSection">
                    <CardTitle subTitle>Suggested budget</CardTitle>

                    {getBudgetRecommendationButton()}
                </li>
            );
        }

        return recommendedBudgetSection;
    }
}

DailyBudgetRecommendation.propTypes = {
    campaign: PropTypes.shape({
        client_status: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        recommended_budget: PropTypes.number,
        settings: PropTypes.shape({
            daily_budget: PropTypes.number.isRequired,
        }),
        shop: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    displayBulkNotification: PropTypes.func.isRequired,
    editCampaign: PropTypes.func.isRequired,
};

export default connect(null, {
    editCampaign: (params) => (dispatch, getState) => {
        const state = getState();
        const currentCampaign = state.campaignDetails.campaignDetailsData.find((camp) => camp.id === +params.id);
        const newDailyBudget = params.daily_budget;
        const oldDailyBudget = currentCampaign && currentCampaign.settings.daily_budget;

        return dispatch(requestEditCampaign(params)).then((res) => {
            if (!(res instanceof Error)) {
                let recommendedDBApproved = getLocalStorageObjectItem(RECOMMENDED_DB_APPROVED);
                recommendedDBApproved = recommendedDBApproved
                    ? {
                        ...recommendedDBApproved,
                        [params.id]: new Date(),
                    }
                    : {
                        [params.id]: new Date(),
                    };
                /** Set to localStorage date of appling recommended DB */
                setLocalStorageObjectItem(RECOMMENDED_DB_APPROVED, recommendedDBApproved);

            }
            return res;
        });
    },
    displayBulkNotification,
})(DailyBudgetRecommendation);
