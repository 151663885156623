import React from 'react';
import Button from 'components/Button';

import closeBtnIcon from '../assets/close-btn.svg';

export const AiBoostedSalesInviteView = ({ onCloseBtnClick, onLaunchBtnClick, onRemindBtnClick }) => (
    <div className='ai-invite'>
      <div className='ai-invite__close-btn'>
        <img src={closeBtnIcon} alt='Close Ai Boosted Sales Invite Banner' onClick={onCloseBtnClick} />
      </div>
      <div className="ai-invite__content">
        <h3 className="ai-invite__title">New Ad Tactic: AI-Boosted Sales Campaign</h3>
        <p className="ai-invite__description">
          This smart ad strategy maximizes effectiveness and boosts profits by combining machine learning to allocate your budget wisely, reaching both new and existing customers.
          This results in personalized product showcases and optimal outcomes.
        </p>
        <div className="ai-invite__btns">
          <Button className="ai-invite__btn" onClick={onLaunchBtnClick} color="yellow">
            Launch Campaign Now
          </Button>
          <span
              onClick={onRemindBtnClick}
              className="customer-interview-invite-link"
          >
            Remind me later
          </span>
        </div>
      </div>
    </div>
)