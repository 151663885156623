import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DOMAIN_VERIFICATION_BANNER } from 'constants/notificationType';
import { shopHasNotification } from 'helpers';
import { CAMPAIGNS_TYPES } from 'constants/campaign';

import DomainVerificationBanner from './DomainVerificationBanner';

class DomainVerificationBannerWrapper extends Component {
    state = {
        isDomainVerificationBannerHidden: false,
    };

    hideDomainVerificationBanner = () => {
        this.setState({ isDomainVerificationBannerHidden: true });
    };

    render() {
        const { isDomainVerificationBannerHidden } = this.state;
        const { showDomainVerificationBanner, campaignType } = this.props;
        const isVisible =
            !isDomainVerificationBannerHidden && showDomainVerificationBanner && campaignType === CAMPAIGNS_TYPES.facebook;

        return <>{isVisible && <DomainVerificationBanner onClickHandler={this.hideDomainVerificationBanner} />}</>;
    }
}

DomainVerificationBannerWrapper.propTypes = {
    showDomainVerificationBanner: PropTypes.bool.isRequired,
    campaignType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    showDomainVerificationBanner: !!shopHasNotification(state.shopNotifications.entity, DOMAIN_VERIFICATION_BANNER),
});

export default connect(mapStateToProps)(DomainVerificationBannerWrapper);
