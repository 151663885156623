import React from 'react';

import intercom from 'libs/intercom';

const IntercomChatCaller = ({ content, className }) => (
    <span
        role="button"
        tabIndex="0"
        onClick={intercom.show}
        className={className || 'notification__link'}
        onKeyPress={({ key }) => (key === 'Enter' ? intercom.show() : null)}
    >
        {content}
    </span>
);

export default IntercomChatCaller;
