import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { requestSendEventMetrics } from 'actions/shop';
import { PREVIEW_TYPE_DESKTOP_FEED_STANDARD } from 'constants/adPreview';

import Button from 'components/Button';
import TipsWidget from 'components/TipsWidget';
import AlertWidget from 'components/AlertWidget';
import AdPreviewLoader from 'components/AdPreviewLoader';
import { Card, CardContent, CardTitle } from 'components/Card';

import PreviewTypeSelect from './PreviewTypeSelect';

import './AdPreview.css';

const PREVIEW_TYPE_DEFAULT = PREVIEW_TYPE_DESKTOP_FEED_STANDARD;

export class AdPreview extends Component {
    state = {
        frameLoaded: false,
        previewType: PREVIEW_TYPE_DEFAULT,
    };

    componentDidMount() {
        this.props.handlePreviewType(this.state.previewType, true);
    }

    changePreviewType = (type) => {
        const { adPreviewRequesting } = this.props;
        if (adPreviewRequesting) return;
        this.setState({
            previewType: type,
            frameLoaded: false,
        });
        this.props.handlePreviewType(type);
    };

    render() {
        const {
            adPreviewLink,
            adPreviewRequesting,
            adPreviewSuccess,
            adPreviewFailure,
            adPreviewError,
            handlePreviewType,
            isPublishPage,
            pageType,
            requestSendEventMetrics,
            sourcePage,
            campaignType,
            shopId,
        } = this.props;
        let adPreview = ' ';

        if (adPreviewSuccess) {
            let frameContainerClassName = 'ad-preview__frame-container';
            let loader = null;

            if (!this.state.frameLoaded) {
                frameContainerClassName += ' ad-preview__frame-container_empty';
                loader = <AdPreviewLoader />;
            }
            adPreview = (
                <div className={frameContainerClassName}>
                    {loader}
                    <iframe
                        scrolling="yes"
                        title="ad preview"
                        width="540"
                        height="450"
                        src={adPreviewLink}
                        style={{
                            display: this.state.frameLoaded ? 'initial' : 'none',
                            border: 'none',
                            maxWidth: '100%',
                        }}
                        onLoad={() => {
                            this.setState({
                                frameLoaded: true,
                            });
                        }}
                    />
                </div>
            );
        }

        if (adPreviewRequesting) {
            adPreview = (
                <div className="ad-preview__frame-container ad-preview__frame-container_empty">
                    <AdPreviewLoader />
                </div>
            );
        }

        if (adPreviewFailure) {
            if (adPreviewError && adPreviewError.errorToken) {
                if (adPreviewError.errorToken === 'products-not-found') {
                    adPreview = (
                        <div className="ad-preview__frame-container ad-preview__frame-container_empty">
                            <AlertWidget color="danger" withTopMargin>
                                <p className="alert-description__item">
                                    <a
                                        href={`http://${adPreviewError.errorData.shop_domain}${adPreviewError.errorData.shop_products_endpoint}`}
                                        className="alert-description__link alert-description__link_highlighted"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Add products
                                    </a>{' '}
                                    to your store to see the Facebook ad preview.
                                </p>
                            </AlertWidget>
                        </div>
                    );
                } else {
                    adPreview = (
                        <div className="ad-preview__frame-container ad-preview__frame-container_empty">
                            <p className="text-muted text-center">Ad Preview Unavailable</p>
                        </div>
                    );
                }
            } else {
                adPreview = (
                    <div className="ad-preview__frame-container ad-preview__frame-container_empty">
                        <p className="text-muted">Failed to request ad preview.</p>
                        <Button color="primary" onClick={() => handlePreviewType(this.state.previewType, true)}>
                            Re-send request
                        </Button>
                    </div>
                );
            }
        }

        return (
            <Card className={classNames('ad-preview', { 'ad-preview_disabled': !isPublishPage })}>
                <CardTitle subTitle>
                    <span>Ad Preview</span>
                    <TipsWidget placement="top" isDarkView>
                        <p className="tips-description__content">
                            Preview contains random products just to give you an idea of how the ad will look like.
                            Adwisely shows each visitor the products he/she has previously viewed on your website.
                        </p>
                        <p className="tips-description__content">
                            <a
                                href={
                                    'https://help.adwisely.com/install-and-configure-retargetapp/' +
                                    'products/what-products-will-be-used-in-the-ads'
                                }
                                className="link link-white"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                What products will be used in the ads? &#x2192;
                            </a>
                        </p>
                    </TipsWidget>
                </CardTitle>

                <CardContent>
                    <PreviewTypeSelect
                        shopId={shopId}
                        pageType={pageType}
                        sourcePage={sourcePage}
                        campaignType={campaignType}
                        changePreviewType={this.changePreviewType}
                        previewType={this.state.previewType}
                        disabled={adPreviewRequesting}
                        requestSendEventMetrics={requestSendEventMetrics}
                    />
                </CardContent>

                <CardContent>{adPreview}</CardContent>
            </Card>
        );
    }
}

AdPreview.defaultProps = {
    adPreviewLink: null,
    adPreviewError: null,
};

AdPreview.propTypes = {
    adPreviewError: PropTypes.shape({
        errorData: PropTypes.shape({
            shop_domain: PropTypes.string,
            shop_products_endpoint: PropTypes.any
        }),
        errorToken: PropTypes.string
    }),
    adPreviewFailure: PropTypes.bool.isRequired,
    adPreviewLink: PropTypes.string,
    adPreviewRequesting: PropTypes.bool.isRequired,
    adPreviewSuccess: PropTypes.bool.isRequired,
    campaignType: PropTypes.string.isRequired,
    handlePreviewType: PropTypes.func.isRequired,
    isPublishPage: PropTypes.bool.isRequired,
    pageType: PropTypes.string.isRequired,
    requestSendEventMetrics: PropTypes.func.isRequired,
    shopId: PropTypes.number.isRequired,
    sourcePage: PropTypes.string.isRequired,
};

const mapStateToProps = ({ campaign }) => ({
    adPreviewLink: campaign.adPreviewLink,
    adPreviewRequesting: campaign.adPreviewRequesting,
    adPreviewSuccess: campaign.adPreviewSuccess,
    adPreviewFailure: campaign.adPreviewFailure,
    adPreviewError: campaign.adPreviewError,
});
const mapDispatchToProps = {
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdPreview);
