import * as notificationAction from '../actions/notification';

const notification = (state = null, action) => {
    switch (action.type) {
        case notificationAction.SHOW_NOTIFICATION:
            if (action.payload instanceof Error) {
                state = action.payload.message;
            } else {
                state = action.payload;
            }
            break;
        case notificationAction.HIDE_NOTIFICATION:
            state = null;
            break;
        default:
    }

    return state;
};
export default notification;
