import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { clearCampaignError, requestCampaignSuggestionsForCampaignCreate } from 'actions/campaign';
import { displayBulkNotification } from 'actions/notification';
import { requestSendEventMetrics } from 'actions/shop';
import { requestOnboardings } from 'actions/onboarding';

import CampaignInfo from 'modules/campaign/components/CampaignInfo';
import CampaignDetails from 'modules/campaign/components/CampaignDetails';
import SaveCampaignDetails from 'modules/campaign/components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from 'modules/campaign/components/CampaignPageLayout';
import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';

import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import BulkNotification from 'components/BulkNotification';
import NotificationMigratedUserBanner from 'components/Banners/NotificationMigratedUserBanner';

import {
    getCurrentShopSelector,
    isStaffPlatformPlanSelector,
    isShopValidateRequesting,
    getBulkNotificationContentSelector,
    getBulkNotificationTypeSelector,
    getAdAccountsSelector,
    getCampaignSuggestionsSelector,
} from 'helpers/selectors';

import { RETARGETING } from 'constants/campaign';
import { SHOPIFY } from 'constants/platform';
import { DAILY_BUDGET_LESS_THEN_MINIMUM } from 'modules/campaign/constants';

import AdsPreviewSection from 'components/AdsPreviewSection/AdsPreviewSection';

import './CreateRetargetingCampaign.css';

import { CreateCampaignAdAudience } from '../CreateCampaignAdAudience/CreateCampaignAdAudience';

const CAMPAIGN_AD_MESSAGE_DEFAULT = "Check what we've got 😉 I'm sure you'll love it! Get your {{product.name}} now!";

export class CreateRetargetingCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewAdMessage: null,
            currentPreviewType: null,
            isPublishPage: true,
            isLoading: false,

            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: this.props.campaignAdMessage ? this.props.campaignAdMessage : CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignMinBudget: 0,

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            isMobilePreview: false,
            countriesList : [],
            isWorldwide: false,
            ageMin: 0,
            ageMax: 0,
            genders: []
        };
    }

    componentDidMount() {
        const {
            requestSuggestions,
            shopId,
            requestOnboardings,
        } = this.props;
        requestOnboardings(shopId);
        requestSuggestions({ id: shopId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = suggestionsData.migrated_user
                    ? suggestionsData.ad_message
                    : this.props.campaignAdMessage ? this.props.campaignAdMessage : CAMPAIGN_AD_MESSAGE_DEFAULT;

                this.setState({
                    campaignBudget: suggestionsData.daily_budget.prospecting_suggested_value / 100,
                    campaignMinBudget: suggestionsData.daily_budget.min_value / 100,
                    campaignCurrency: suggestionsData.daily_budget.currency,
                    campaignAdMessage: suggestedAdMessage,
                });
            }
        }

        if (this.props.adAccounts.connectStatus.access_status !== nextProps.adAccounts.connectStatus.access_status) {
            const { adAccounts } = nextProps;
            const { chosenAdAccountId, connectStatus } = adAccounts;

            if (
                chosenAdAccountId &&
                connectStatus.fb_id === this.state.errorData.ad_account_id &&
                connectStatus.access_status === 'granted'
            ) {
                this.refreshMinBudgetAndAdPreview();
            }
        }
    }

    componentWillUnmount() {
        this.props.clearCampaignError();
    }

    handleAdPreviewMsg = (msg) => {
        this.setState({
            previewAdMessage: msg,
        });
    };

    handleFormError = (hasCampaignFormError) => {
        this.setState({
            hasCampaignFormError,
        });
    };

    getAdAudience = (countries, isWorldwide, ageMin, ageMax, genders) => {
        this.setState({
            countriesList: countries.length > 0 ? countries.map(item => item.slug) : [],
            isWorldwide,
            ageMin,
            ageMax,
            genders
        })
    }

    saveCampaign = (dailyBudget, adMessage) => {
        const { countriesList, isWorldwide, ageMin, ageMax, genders } = this.state;
        const {
            shopId,
            suggestions,
            replace,
            onboardingStatus,
            onboardingType,
            campaignType
        } = this.props;

        const gendersArray = Object.keys(genders).map(gender => +gender)

        const params = {
            shopId,
            campaign_type: campaignType,
            daily_budget: +dailyBudget,
            messages: adMessage,
            currency: this.state.campaignCurrency,
            targeting_settings: {
                countries: countriesList,
                worldwide: isWorldwide,
                age_min: ageMin,
                age_max: ageMax,
                genders: gendersArray
            }
        };

        if (!suggestions.campaignSuggestionsSuccess || !dailyBudget) return;

        changeOnboardingState({
            type: onboardingType,
            status: onboardingStatus,
            currentShopId: shopId,
            data: params,
        })
            .then((res) => {
                if (!(res instanceof Error)) {
                    this.setState({ isLoading: true });
                } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                    this.setState({
                        saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                        isLoading: false,
                    });
                }

                if (res.status === 409) {
                    facebookStatePolling(shopId, replace);
                }
                return res.json();
            })
            .then((res) => {
                if (res?.processing) {
                    facebookStatePolling(shopId, replace);
                }
            });
    };

    refreshMinBudgetAndAdPreview = () => {
        const {
            requestSuggestions,
            shopId
        } = this.props;
        requestSuggestions({ id: shopId });
    };

    changeTipsErrorText = (tipsText) => {
        this.setState({
            tipsErrorText: tipsText,
        });
    };

    handleAdMessageTagsHasError = (tipsErrorText) => {
        this.setState({
            adMessageTagsHasError: !!tipsErrorText,
            adMessageTagsHasErrorTipsErrorText: tipsErrorText,
        });
    };

    setIsMobilePreview = (newState) => {
        this.setState({ isMobilePreview: newState });
    };

    render() {
        const campaignFormName = 'retargetingCampaignCreate';
        const {
            shopValidateRequesting,
            shopId,
            suggestions: { campaignSuggestionsData },
            bulkNotificationContent,
            bulkNotificationType,
            currentShop,
            campaignTitle,
            campaignDescription,
            onboardingType,
            isGenderVisible = true,
            isAgeVisible = true,
            isCountriesVisible = true
        } = this.props;
        const {
            campaignBudget,
            campaignAdMessage,
            campaignCurrency,
            campaignMinBudget,
            adMessageTagsHasError,
            hasCampaignFormError,
            adMessageTagsHasErrorTipsErrorText,
            tipsErrorText,
            saveCampaignError,
            isLoading,
            isMobilePreview,
            previewAdMessage,
            isWorldwide,
            countriesList,
        } = this.state;

        let bulkNotification = null;

        if (bulkNotificationContent) {
            bulkNotification = <BulkNotification text={bulkNotificationContent} type={bulkNotificationType} />;
        }

        const isMigratedUser = campaignSuggestionsData ? campaignSuggestionsData.migrated_user : false;
        return (
            <div className="set-up-retargeting__wrapper">
                <NotificationMigratedUserBanner isMigratedUser={isMigratedUser} />

                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            campaignTitle={campaignTitle}
                            linkCaption="Learn more"
                            learnMoreLink="https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose"
                            showPricing
                            showIcon
                            shopId={shopId}
                            campaignDescription={campaignDescription}
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={campaignAdMessage}
                            campaignCurrency={campaignCurrency}
                            campaignMinBudget={campaignMinBudget}
                            campaignBudget={campaignBudget}
                            pricingAdviceItems={
                                campaignSuggestionsData && campaignSuggestionsData.daily_budget
                                    ? campaignSuggestionsData.daily_budget.recommendation_tips_info
                                    : []
                            }
                            saveCampaign={this.saveCampaign}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            saveCampaignError={saveCampaignError}
                            campaignType={this.props.campaignType}
                            adMessageTipText={this.props.adMessageTipText}
                        />

                        <CreateCampaignAdAudience
                            onAdAudienceSettingsChange={this.getAdAudience}
                            shopId={shopId}
                            isDropdownError={!isWorldwide && Object.keys(countriesList).length === 0}
                            isGenderVisible={isGenderVisible}
                            isAgeVisible={isAgeVisible}
                            isCountriesVisible={isCountriesVisible}
                        />

                        <SaveCampaignDetails
                            isButtonDisabled={!isWorldwide && Object.keys(countriesList).length === 0}
                            formName={campaignFormName}
                            buttonTitle="Continue"
                            buttonColor="primary"
                            hasCampaignFormError={
                                adMessageTagsHasError || hasCampaignFormError || !!shopValidateRequesting
                            }
                            tipsErrorText={adMessageTagsHasErrorTipsErrorText || tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdsPreviewSection
                            currentActiveCard={onboardingType}
                            isMobilePreview={isMobilePreview}
                            setIsMobilePreview={this.setIsMobilePreview}
                            previewAdMessage={previewAdMessage}
                            isShopify={currentShop?.type === SHOPIFY}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <LoaderFullScreen isLoaderShowed={isLoading}>
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
                {bulkNotification}
            </div>
        );
    }
}

CreateRetargetingCampaign.defaultProps = {
    bulkNotificationContent: null,
    bulkNotificationType: null,
};

CreateRetargetingCampaign.propTypes = {
    adAccounts: PropTypes.shape({
        connectStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                access_status: PropTypes.string,
            }),
        ]),
    }).isRequired,
    bulkNotificationContent: PropTypes.string,
    bulkNotificationType: PropTypes.string,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsSuccess: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);
    return {
        suggestions: getCampaignSuggestionsSelector(state),
        shopValidateRequesting: isShopValidateRequesting(state),
        currentShop,
        shopId: state.shops?.currentShopId,
        isStaffPlatformPlan: isStaffPlatformPlanSelector(currentShop),
        shops: state.shops,
        bulkNotificationContent: getBulkNotificationContentSelector(state),
        bulkNotificationType: getBulkNotificationTypeSelector(state),
        adAccounts: getAdAccountsSelector(state),
        onboardings: state.onboarding.onboardingsState.onboardings,
    };
};

const mapDispatchToProps = {
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    clearCampaignError,
    showBulkNotification: displayBulkNotification,
    requestSendEventMetrics,
    requestOnboardings,
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRetargetingCampaign);
