import * as infoAction from 'actions/pageTitle';

const initialState = '';

const pageTitle = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case infoAction.SET_PAGE_TITLE:
            state = payload;
            break;
        default:
            break;
    }

    return state;
};
export default pageTitle;
