import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { requestShops } from 'actions/shop';
import { ROUTES } from 'constants/routes';

export class RoutingRoot extends React.Component {
    componentDidMount() {
        const { replace, shops, account } = this.props;

        // if shops state object was changed
        if (shops.shopRequestSuccess) {
            if (shops.entities.length) {
                replace(ROUTES.chooseShop);
                return;
            }

            if (!account.entity) {
                replace(ROUTES.facebookLogin);
                return;
            }
 
            replace(ROUTES.chooseInstallShop);
        }

        if (shops.shopRequestFailure && account.requestingFailure) {
            replace(ROUTES.facebookLogin);
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ account, shops }) => ({
    shops,
    account,
});

const mapPropsToDispatch = {
    requestShops,
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(RoutingRoot);
