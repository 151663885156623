import { requestSendEventMetrics } from '../actions/shop';
import { metricType } from '../constants/metric';

export const trackVerifyDomainConfirmCheckbox = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_domain_confirm',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackVerifyDomainContinueButton = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_domain_continue',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackSharePixelConfirmCheckbox = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_pixel_confirm',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackSharePixelContinueButton = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_pixel_continue',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackSetUpRetargetingContinueButton = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_setup_continue',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackApproveCharge = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_fee_approve_charge',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackApproveAppFee = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_fee_add_card',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackAddFacebookCard = (shopId) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.btnClicked,
        props: {
            btn_name: 'fb_rt_creation_addcard_add_paymethod',
        },
    };
    requestSendEventMetrics(metricProps);
};

export const trackBookCall = (shopId, requestSendEventMetrics, page) => {
    const metricProps = {
        shop_id: shopId,
        type: metricType.bookCall,
        props: {
            page,
        },
    };
    requestSendEventMetrics(metricProps);
}
