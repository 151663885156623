import * as accountActions from 'actions/account';
import intercom from 'libs/intercom';
import { GET_INTERCOM_USER_SUCCESS, INTERCOM_TRACK_EVENT } from 'actions/intercom';
import { LOGIN_CONFIRM_SUCCESS, LOGIN_CONFIRM_FAILURE } from 'actions/login';
import { SHOP_INSTALL_SUCCESS, SHOP_LOGIN_SUCCESS } from 'actions/shop';

export default () => (next) => (action) => {
    switch (action.type) {
        case LOGIN_CONFIRM_SUCCESS:
        case accountActions.ACCOUNT_INFO_REQUEST_SUCCESS:
        case SHOP_INSTALL_SUCCESS:
        case SHOP_LOGIN_SUCCESS:
        case LOGIN_CONFIRM_FAILURE:
        case GET_INTERCOM_USER_SUCCESS:
            if (action.meta.intercom !== undefined) {
                intercom.shutdown();
                intercom.boot(action.meta.intercom);
            }
            break;

        case INTERCOM_TRACK_EVENT:
            intercom.trackEvent({
                type: action.payload.type,
                meta: action.payload.meta,
            });
            break;

        default:
            break;
    }
    next(action);
};
