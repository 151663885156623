import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GoogleRemarketingAdPreviewCard from './GoogleRemarketingAdPreviewCard';

import './GoogleRemarketingAdPreview.css';

const GoogleRemarketingAdPreview = ({ googleRemarketingAdPreviewData, campaignId }) => {
    const currentCampaignAdPreview =
        googleRemarketingAdPreviewData &&
        googleRemarketingAdPreviewData.find((adPreview) => adPreview.campaign_id === campaignId);

    return (
        <div className="ad-preview-remarketing">
            <span>Ad Preview</span>
            <p>Example of your native ad</p>
            {currentCampaignAdPreview && (
                <GoogleRemarketingAdPreviewCard
                    imageLink={currentCampaignAdPreview.image_link}
                    headline={currentCampaignAdPreview.short_headline}
                    shopUrl={currentCampaignAdPreview.final_url}
                />
            )}
        </div>
    );
};

GoogleRemarketingAdPreview.propTypes = {
    campaignId: PropTypes.number.isRequired,
    googleRemarketingAdPreviewData: PropTypes.arrayOf(PropTypes.shape({
        image_link: PropTypes.string.isRequired,
        short_headline: PropTypes.string.isRequired,
        final_url: PropTypes.string.isRequired,
        campaign_id: PropTypes.number.isRequired,
    })).isRequired,
};

const mapStateToProps = ({ googleRemarketingAdPreview }) => ({
    googleRemarketingAdPreviewData: googleRemarketingAdPreview.googleRemarketingAdPreviewData,
});

export default connect(mapStateToProps)(GoogleRemarketingAdPreview);
