import React from "react";
import Button from "components/Button";

import "./BookACallView.css";

export const BookACallView = ({ onBookACallClick }) => (
        <div className='book-a-call'>
            <h2 className="book-a-call__title">
                <span className="phone-icon" role="img" aria-label="phone">📞</span>
                Book a call to complete ad setup
            </h2>
            <p className="book-a-call__text">
                Our Support team will be happy to set the ads up for you during a quick call.
                <br />
                <b>Click “Book a call” to choose a convenient time slot.</b>
            </p>
            <Button
                color="primary"
                className="book-a-call__btn"
                onClick={onBookACallClick}
            >
                Book a call
            </Button>
        </div>
    );