import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'components/Modal/Modal';
import Button from 'components/Button';

import './PaymentCardModal.css';

const PaymentCardModal = (props) => {
    const {
        isOpen,
        buttonClickHandler,
        children,
        hideModal,
        buttonName,
        secondaryButtonName,
        modalTitle,
    } = props;

    const secondaryButton = secondaryButtonName ? (
        <Button className="modal-common__button modal-common__button--secondary" onClick={hideModal}>
            {secondaryButtonName}
        </Button>
    ) : null;

    return (
        <Modal className="modal-common__dialog" isOpen={isOpen}>
            <div className="modal-common__close-button" onClick={hideModal} />
            <ModalBody className="modal-common__body">
                {modalTitle ? <h4 className="modal-common-body__title">{modalTitle}</h4> : null}
                <div className="modal-common-body__content">{children}</div>
                <div className="modal-common__buttons">
                    {secondaryButton}
                    <Button color="danger" className="modal-common__button modal-common__button--primary" onClick={buttonClickHandler}>
                        {buttonName}
                    </Button>  
                </div>
            </ModalBody>
        </Modal>
    );
};

PaymentCardModal.defaultProps = {
    isOpen: false,
    modalTitle: null,
    secondaryButtonName: null,
};

PaymentCardModal.propTypes = {
    isOpen: PropTypes.bool,
    buttonClickHandler: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    buttonName: PropTypes.string.isRequired,
    secondaryButtonName: PropTypes.string,
    modalTitle: PropTypes.string,
};

export default PaymentCardModal;