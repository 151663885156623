import React, { useState } from 'react';
import intercom from 'libs/intercom';

import VideoPlayerModal from './VideoPlayerModal';
import playButton from './assets/youtube.svg';

import './VideoPlayerModal.css';

const VideoHelpSection = ({ videoLink }) => {
    const [showVideo, setShowVideo] = useState(false);

    const videoButtonToggle = () => setShowVideo(!showVideo);

    return (
        <div className="video-help-section">
            <h3 className="book-call__title">Need help?</h3>
            <span className="">Watch video:</span>
            <div className="video-help-section__play-button" onClick={videoButtonToggle}>
                <img src={playButton} alt="play button" />
            </div>
            <VideoPlayerModal isOpen={showVideo} hideModal={videoButtonToggle} url={videoLink} />
            <p
                onClick={intercom.show}
                className="video-help-section__chat-button"
            >
                <span className="person-icon" /> {''}
                Contact Support
            </p>
        </div>
    )
};

export default VideoHelpSection;
