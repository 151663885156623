import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setPageTitle } from 'actions/pageTitle';
import { requestSendEventMetrics } from 'actions/shop';
import { metricType } from 'constants/metric';
import { getCurrentShopSelector } from 'helpers/selectors';

/**
 * @param pageName {String}
 * @param subPageName {String}
 */
export default (pageName, subPageName = null) => (WrappedComponent) => {
    class DecoratedComponent extends Component {
        componentDidMount() {
            this.props.setPageTitle(pageName);

            this.sendMetrics();
        }

        componentWillUnmount() {
            this.props.setPageTitle('');
        }

        // TODO: create class to handle all metrics logic in one place
        sendMetrics = () => {
            const { currentShop, requestSendEventMetrics } = this.props;

            const toMetricsNameFormat = (value) => value.toLowerCase().split(' ').join('_');

            if (currentShop) {
                const metricProps = {
                    shop_id: currentShop.id,
                    type: metricType.pageViewed,
                    props: {
                        // use subPageName to track onboarding pages
                        page: subPageName ? toMetricsNameFormat(subPageName) : toMetricsNameFormat(pageName),
                        url_path: window.location.pathname,
                    },
                };
                requestSendEventMetrics(metricProps);
            }
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = ({ shops }) => ({
        currentShop: getCurrentShopSelector(shops, shops.currentShopId),
    });

    const mapDispatchToProps = {
        setPageTitle,
        requestSendEventMetrics,
    };

    return connect(mapStateToProps, mapDispatchToProps)(DecoratedComponent);
};
