import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CardTitle.css';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    subTitle: PropTypes.bool,
    className: PropTypes.string,
    labelFor: PropTypes.string,
};

const defaultProps = {
    className: null,
    subTitle: false,
    labelFor: null,
};

const CardTitle = ({ children, className, subTitle, labelFor }) => {
    let Tag = subTitle ? 'h5' : 'h4';
    if (labelFor) Tag = 'label';
    return (
        <Tag
            className={classNames('card-block-title', className, { 'card-block-title_subtitle': subTitle })}
            htmlFor={labelFor}
        >
            {children}
        </Tag>
    );
};

CardTitle.propTypes = propTypes;
CardTitle.defaultProps = defaultProps;

export default CardTitle;
