const trackAppInstalled = (shopId) => {
    // Because of GDPR, Cookie Script features, and asynchronous nature of JS.
    // the event is tracked via GTM. We use Local Storage to pass a value to GTM scripts.

    // A GTM script might look like this:
    // const shopIdStr = window.localStorage.getItem('installedShopId')
    // if (shopIdStr) {
    //     window.localStorage.removeItem('installedShopId')
    //     gtag('event', 'installed', {'event_category': 'app', 'event_label': shopIdStr})
    //     fbq('track', 'Lead', {'value': Number(shopIdStr)})
    // }

    window.localStorage.setItem('installedShopId', `${shopId}`)
}

export default trackAppInstalled
