export const campaignCopyright = {
    ai_boosted_sales: {
        title: 'Meta AI-powered Campaign',
        titleTooltipContent: 'This smart ad strategy maximizes effectiveness and boosts profits by combining machine learning to allocate your budget wisely, reaching both new and existing customers. This results in personalized product showcases and optimal outcomes.',
        titleTooltipLink: 'https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose',
    },
    visitor_retargeting: {
        title: 'Facebook Visitor Retargeting',
        titleTooltipContent:
            'Re-engage your site visitors who didn’t make a purchase ' +
            'with personalized ads of the previously viewed products.',
        titleTooltipLink: 'https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose',
    },
    dynamic_retargeting: {
        title: 'Facebook Dynamic Retargeting',
        titleTooltipContent:
            'Re-engage your site visitors who didn’t make a purchase ' +
            'with personalized ads of the previously viewed products.',
        titleTooltipLink: 'https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose',
    },
    social_media_retargeting: {
        title: 'Facebook Social Media Retargeting',
        titleTooltipContent:
            'Re-engage your site visitors who didn’t make a purchase ' +
        'with personalized ads of the previously viewed products.',
        titleTooltipLink: 'https://help.adwisely.com/en/articles/7913816-ad-tactics-what-are-they-and-which-ones-do-i-choose',
    },
    custom: {
        title: 'Custom',
        titleTooltipContent: '',
        titleTooltipLink: 'https://help.adwisely.com/en/articles/5733611-custom-campaigns',
    },
    prospecting: {
        title: 'Prospecting',
        titleTooltipContent:
            'Prospecting Ads attract new people who are ' +
            'similar to your current buyers so they are more likely to purchase.',
        titleTooltipLink:
            'https://help.adwisely.com/improve-your-campaign-results/' +
            'learn-about-advertising-tactics/prospecting',
    },
    google: {
        titleTooltipContent:
            'You can always contact our support team if you have any question regarding your Google Ads campaign',
    },
    special_offer: {
        title: 'Special Offer',
        titleTooltipContent:
            'Re-engage people who were interested in your ' +
            'products in the past 180 days with a limited-time offer like a sitewide discount.',
        titleTooltipLink: 'https://help.adwisely.com/en/articles/4585544-special-offer',
    },
};
