import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PROSPECTING } from 'constants/campaign';
import { PROSPECTING_INVITE } from 'constants/notificationType';
import { shopHasNotification } from 'helpers';
import { fetchProspectingOnboardingState } from 'modules/onboarding/userBMFlow/helpers/getOnboardingState';
import ProspectingInvite from './ProspectingInvite';

const ProspectingInviteWrapper = ({
    shopId,
    showProspectingBanner,
    shopValidateSuccess,
    ownerType,
}) => {
    const [toRemindLater, setToRemindLater] = useState(false);

    const isVisible = ownerType === 'user'
        ? showProspectingBanner && shopValidateSuccess
        : showProspectingBanner && shopValidateSuccess;

    return <>{isVisible && !toRemindLater && <ProspectingInvite shopId={shopId} setToRemindLater={setToRemindLater} ownerType={ownerType} />}</>;
};

ProspectingInviteWrapper.propTypes = {
    shopId: PropTypes.string.isRequired,
    showProspectingBanner: PropTypes.bool.isRequired,
};

const showProspectingBannerSelector = (campaignDetailsData, shopNotifications) => {
    if (!campaignDetailsData.some((campaign) => campaign.type === PROSPECTING)) {
        return !!shopHasNotification(shopNotifications, PROSPECTING_INVITE);
    }
    return false;
};

const mapStateToProps = (state) => ({
    shopValidateSuccess: state.shopError.shopValidateSuccess,
    showProspectingBanner: showProspectingBannerSelector(
        state.campaignDetails.campaignDetailsData,
        state.shopNotifications.entity,
    ),
    ownerType: state.shopsCampaignsSettings.entity.fb_business_manager_owner_type,
});

export default connect(mapStateToProps)(ProspectingInviteWrapper);
