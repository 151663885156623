import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'components/Button';
import {requestSendEventMetrics} from 'actions/shop';
import {metricType, fbCampaignTactics} from 'constants/metric';
import './SpecialOfferInvite.css';

const SpecialOfferInviteExistingUsers = ({ onClickHandler, shopId, requestSendEventMetrics, campaignId }) => (
    <section className="special_offer-invite">
        <div className="special_offer-invite-close-btn" onClick={onClickHandler} />
        <header className="special_offer-invite-header">
            <h2 className="special_offer-invite-title">
                Get more orders with a Special Offer campaign!
            </h2>
        </header>
        <span className="special_offer-invite__body">
            Run a short-term promo for a new collection, sale, sitewide discount,
            or a special holiday offer to brand new customers and store visitors.
        </span>
        <div className="special_offer-invite-cta">
            <Button
                innerUrl={`/${shopId}/${campaignId}/edit`}
                color="green"
                onClick={() => {
                    const metricProps = {
                        shop_id: shopId,
                        type: metricType.fbCampaignInvitationAccepted,
                        props: {
                            tactic: fbCampaignTactics.special_offer,
                            source: 'dashboard',
                        },
                    };
                    requestSendEventMetrics(metricProps);
                }}
            >
                Run Another Special Offer
            </Button>
            <Button
                color="link"
                className="special_offer-invite-link"
                url="https://help.adwisely.com/en/articles/4585544-special-offer"
            >
                Learn more
            </Button>
        </div>
    </section>
);

SpecialOfferInviteExistingUsers.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    shopId: PropTypes.string.isRequired,
    campaignId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
    requestSendEventMetrics,
};

export default connect(null, mapDispatchToProps)(SpecialOfferInviteExistingUsers);
