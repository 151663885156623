import React from 'react';
import FacebookAlertButton from '../../FacebookAlertButton';

const LoginErrorMessageLinkError = ({ requestLoginLink }) => (
    <p className="alert-description__item">
        Application can`t resolve login link.{' '}
        <FacebookAlertButton onClick={() => requestLoginLink()} className="alert-description__link_highlighted">
            Try again
        </FacebookAlertButton>
    </p>
);

export default LoginErrorMessageLinkError;
