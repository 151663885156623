import { combineReducers } from 'redux';

import login from './login';
import googleOnboardingState from './googleOnboardingState';

const google = combineReducers({
    login,
    googleOnboardingState,
});

export default google;
