import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';

import { metricType } from 'constants/metric';
import DropdownWidget from 'components/DropdownWidget';
import { checkImage, cancelablePromise } from 'helpers';

import userIcon from '../../img/default-user-icon.svg';

import './UserProfileWidgets.css';

class UserProfileWidget extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            userIcon,
            isPhoto: false,
        };

        this.cancelableUpdateUserIcon = cancelablePromise(
            new Promise((resolve, reject) => {
                checkImage(this.props.accountImage, (status) => {
                    if (status) {
                        resolve(status);
                    } else {
                        reject(status);
                    }
                });
            }),
        );

        this.logOutHandler = this.logOutHandler.bind(this);
    }

    setAccountImage() {
        const { accountImage } = this.props;

        if (accountImage) {
            this.cancelableUpdateUserIcon.promise.then(
                (isAvailable) => {
                    if (isAvailable) {
                        this.setState({
                            isPhoto: true,
                            userIcon: accountImage,
                        });
                    }
                },
                () => {},
            );
        }
    }

    componentDidMount() {
        this.setAccountImage();
    }

    componentWillUnmount() {
        this.cancelableUpdateUserIcon.cancel();
    }

    logOutHandler() {
        const { shopId, pageTitle, logout, requestSendEventMetrics } = this.props;

        const metricProps = {
            shop_id: +shopId,
            type: metricType.loggedOut,
            props: {
                source: pageTitle.toString().toLowerCase().split(' ').join('_'),
            },
        };
        requestSendEventMetrics(metricProps);

        logout();
    }

    get userName() {
        const { firstName, lastName } = this.props;

        return `${firstName}  ${lastName}`;
    }

    get menuLinks() {
        const { shopId, isFbCampaignsExist, isStaffPlatformPlan, onboardingsState } = this.props;

        const isOnboardingDone = onboardingsState.onboardings?.filter((onboarding) => onboarding.state === 'done').length > 0;
        let raSettingItem = null;
        if (
            (window.location.href.search(/onboarding/) === -1)
        ) {
            raSettingItem = (
                <Link to={`/${shopId}/settings`} className="dropdown-widget__menu-item">
                    <p className="dropdown-widget__menu-item__title">Settings</p>
                    {isFbCampaignsExist && (
                        <p className="dropdown-widget__menu-item__subtitle">
                            Facebook Page {!isStaffPlatformPlan ? ' / Billing' : ''}
                        </p>
                    )}
                </Link>
            );
        }

        let linkToDashboard = null;
        if (
            isOnboardingDone &&
            !window.location.href.includes('dashboard')
        ) {
            linkToDashboard = (
                <Link to={`/${shopId}/dashboard`} className="dropdown-widget__menu-item">
                    <span className="dropdown-widget__menu-item__title">Dashboard</span>
                </Link>
            );
        }

        let linkToHomePage = null;
        if (window.location.href.search(/home/) === -1) {
            linkToHomePage = (
                <Link to={`/${shopId}/home`} className="dropdown-widget__menu-item">
                    <span className="dropdown-widget__menu-item__title">Explore ad option</span>
                </Link>
            );
        }

        const linkToHelpCenter = (
            <a
                href="https://help.adwisely.com/"
                className="dropdown-widget__menu-item dropdown-widget__menu-item_external-link"
                rel="noopener noreferrer"
                target="_blank"
            >
                <span className="dropdown-widget__menu-item__title">Help Center</span>
            </a>
        );

        const logoutButton = (
            <button className="dropdown-widget__menu-item" onClick={this.logOutHandler}>
                <span className="dropdown-widget__menu-item__title">Log Out</span>
            </button>
        );

        return [linkToHomePage, raSettingItem, linkToDashboard, linkToHelpCenter, logoutButton];
    }

    render() {
        const { shopId, lastName, firstName } = this.props;
        const { userIcon, isPhoto } = this.state;

        if (!firstName || !lastName) return null;

        return (
            <DropdownWidget
                inNavbar
                smallDropdown
                shopId={shopId}
                toggleIcon={userIcon}
                menuList={this.menuLinks}
                toggleTitle={this.userName}
                toggleSubTitle="My Account"
                alignMenuToTogglesRightSide
                customWrapperClassName="user-profile-wrapper"
                customToggleImageClass={isPhoto ? 'dropdown-widget__toggle-icon_bordered' : null}
            />
        );
    }
}

UserProfileWidget.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    accountImage: PropTypes.string,
    logout: PropTypes.func.isRequired,
    shopId: PropTypes.number.isRequired,
};

UserProfileWidget.defaultProps = {
    firstName: null,
    lastName: null,
    accountImage: null,
};

export default UserProfileWidget;
