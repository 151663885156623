import React, {useState} from 'react';
import { AdAudience } from 'modules/campaign/components/AdAudience/AdAudience';

import { useCampaignInitialSettings } from './hooks/useCampaignInitialSettings';

export const CreateCampaignAdAudience = ({
                                             shopId,
                                             isDropdownError,
                                             onAdAudienceSettingsChange,
                                             isGenderVisible,
                                             isAgeVisible,
                                             isCountriesVisible
                                         }) => {
    const [searchedCountries, setSearchedCountries] = useState([]);

    const {
        countriesList,
        availableCountries,
        isWorldwide,
        isWorldwideAvailable,
        setIsWorldWide,
        setCountriesList,
        setAvailableCountries,
        maxNumOfCountries,
        setGenders,
        genders,
        availableAgeMin,
        availableAgeMax,
        availableGenders,
        ageMin,
        ageMax,
        setAgeMin,
        setAgeMax
    } = useCampaignInitialSettings(shopId, setSearchedCountries);

    return (
        <AdAudience
            searchedCountries={searchedCountries}
            setSearchedCountries={setSearchedCountries}
            isDropdownError={isDropdownError}
            countriesList={countriesList}
            availableCountries={availableCountries}
            isWorldwide={isWorldwide}
            isWorldwideAvailable={isWorldwideAvailable}
            onAdAudienceSettingsChange={onAdAudienceSettingsChange}
            setIsWorldWide={setIsWorldWide}
            setCountriesList={setCountriesList}
            setAvailableCountries={setAvailableCountries}
            maxNumOfCountries={maxNumOfCountries}
            setGenders={setGenders}
            genders={genders}
            availableGenders={availableGenders}
            availableAgeMin={availableAgeMin}
            availableAgeMax={availableAgeMax}
            ageMin={ageMin}
            ageMax={ageMax}
            setAgeMin={setAgeMin}
            setAgeMax={setAgeMax}
            isGenderVisible={isGenderVisible}
            isAgeVisible={isAgeVisible}
            isCountriesVisible={isCountriesVisible}
        />)
}