export const changeOnboardingState = ({ type, status, currentShopId, data = {} }) => {
    const jsonData = JSON.stringify(data);

    return fetch(`/api/onboarding/${currentShopId}/${type}/${status}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: jsonData,
    });
};
