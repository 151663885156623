import { useState } from 'react';

const DropdownProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = (open) => {
        open !== undefined ? setIsOpen(open) : setIsOpen(!isOpen);
    };

    return children({ isOpen, toggle });
};

export default DropdownProvider;
