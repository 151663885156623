import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { goToDashboard } from 'actions/campaign';
import { goToDomainVerification } from 'actions/onboarding';
import { requestAddUserToChildBM } from 'actions/businessManager';
import * as pagesActions from 'actions/facebook/pages';
import { shouldRender, disableRender } from 'actions/pageLoading';
import Button from 'components/Button';
import { PenIcon } from 'components/Icons';

import pageNameSetter from 'decorator/pageNameSetter';

import { changeShopPage } from 'constants/metric';
import { GRANTED } from 'constants/fbInvitaionStatuses';
import { REQUEST_STATUS_PENDING } from 'constants/requestStatuses';
import {
    getCurrentBMUserStatus,
    getCurrentBMUserSelector,
    getCurrentShopSelector,
    getSelectedFBPage,
} from 'helpers/selectors';
import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';

import AcceptInvitationNotifications from './AcceptInvitationNotifications';
import './AcceptInvitation.css';

const NewBMInviteCopy = () => (
    <p className="accept-invitation__description">
        To continue with campaign setup, please, accept the invitation to the Business Manager Adwisely created for
        your store
    </p>
);

const OldBMInviteCopy = () => (
    <>
        <p className="accept-invitation__description">
            For best results, Adwisely will run all your ads in a brand new Business Manager.
        </p>
        <p className="accept-invitation__description">
            To get access to it, please, go to the invitation and follow the instructions
        </p>
    </>
);

const EmailInviteCopy = ({email}) => (
    <>
        <p className="accept-invitation__description">
            Confirmation letter was sent to your email account <strong>{email}</strong>.
            Please check your inbox and click on the link in the email,
            and then click on the button below.
        </p>
    </>
);
class AcceptInvitation extends Component {
    state = {
        acceptedButtonClicked: false,
    };

    componentDidMount() {
        const {
            pages,
            getFbPages,
            currentBMuser,
            addUserToChildBM,
            BMid,
            shouldRender,
            disableRender,
        } = this.props;
        if (!pages.entities.length) getFbPages({}, false);
        
        disableRender();
        if (JSON.stringify(currentBMuser) === '{}') {
            addUserToChildBM(BMid).then(() => shouldRender());
        } else {
            shouldRender();
        }
    }

    componentDidUpdate() {
        const {
            currentBMuser,
            params: { shopId },
            redirectToDashboard,
            domainIsVerified,
            campaignIsCreated,
            redirectToDomainVerification,
        } = this.props;

        if (currentBMuser?.status === GRANTED) {
            campaignIsCreated && domainIsVerified ? redirectToDashboard(shopId) : redirectToDomainVerification(shopId);
        }
    }

    acceptHandler = () => {
        const { BMid, acceptInvitationPending, addUserToChildBM } = this.props;

        if (!acceptInvitationPending) {
            addUserToChildBM(BMid).then(() => this.setState({ acceptedButtonClicked: true }));
        }
    };

    getBMInviteCopy = () => !this.props.currentBMuser?.invite_link_available ? <EmailInviteCopy email={this.props.currentBMuser?.email} />
        : this.props.BMusers?.length > 1 ? <NewBMInviteCopy /> : <OldBMInviteCopy />;

    changeButtonHandler = () => {
        const {
            params: { shopId },
        } = this.props;

        const fbPageInfo = JSON.parse(campaignCreateStorageHelper.getFBPageIsSaved());

        const fbPageIndex = fbPageInfo.findIndex(page => page.shopId === +shopId);

        if (fbPageIndex !== -1) {
            fbPageInfo[fbPageIndex].isSaved = false;
        } else {     
            fbPageInfo.push({
                shopId: shopId,
                isSaved: false,
            });
        }

        campaignCreateStorageHelper.setFBPageIsSaved(fbPageInfo);
    };

    render() {
        const {
            BMid,
            currentBMuser,
            showNotification,
            acceptInvitationPending,
            intercomEventGoToInvitation,
            pageName,
            params: { shopId },
        } = this.props;
        const { acceptedButtonClicked } = this.state;

        if (!BMid) return null;

        const BMinviteCopy = this.getBMInviteCopy();    

        return (
            <>
                <h1 className="accept-invitation__title">Accept Invitation</h1>
                <div className="accept-invitation__notification">
                    <AcceptInvitationNotifications isVisible={showNotification && acceptedButtonClicked} />
                </div>

                <div className="accept-invitation__wrapper">
                    {pageName && (
                        <div className="accept-invitation__change-page">
                            <p>
                                You right now on facebook page: <strong>{pageName}</strong>
                            </p>
                            <Button
                                color="link"
                                onClick={this.changeButtonHandler}
                                innerUrl={`/${shopId}/onboarding/page`}
                            >
                                <PenIcon />
                                Change
                            </Button>
                        </div>
                    )}
                    <div className="accept-invitation__main-section">
                        <div className="accept-invitation__item">
                            <h4 className="accept-invitation__description-title accept-invitation__description-title_first">
                                {currentBMuser?.invite_link_available ? '1. Click "Accept Invitation"' : '1. Accept via email '}
                            </h4>
                            {BMinviteCopy}
                            {currentBMuser?.invite_link_available && (
                                <Button
                                    className={classNames('accept-invitation-cta-button', {
                                        'accept-invitation-cta-button_accent': showNotification && acceptedButtonClicked,
                                    })}
                                    onClick={intercomEventGoToInvitation}
                                    url={currentBMuser?.fb_invite_link}
                                >
                                    Accept invitation
                                </Button>
                            )}
                        </div>
                        <div className="accept-invitation__divider" />
                        <div className="accept-invitation__item">
                            <h4 className="accept-invitation__description-title accept-invitation__description-title_second">
                                2. Proceed to campaign settings
                            </h4>
                            <p className="accept-invitation__description">
                                Once you accept the invitation, return to this tab and click "Next" to review your ad
                                settings.
                            </p>
                            <Button
                                medium
                                onClick={this.acceptHandler}
                                pending={acceptInvitationPending}
                                disabled={acceptInvitationPending}
                                className="accept-invitation-cta-button"
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

AcceptInvitation.defaultProps = {
    BMid: null,
    pageName: "",
    currentBMuser: {},
    pages: null,
};

AcceptInvitation.propTypes = {
    BMid: PropTypes.number,
    pageName: PropTypes.string,
    acceptInvitationPending: PropTypes.bool.isRequired,
    showNotification: PropTypes.bool.isRequired,
    campaignIsCreated: PropTypes.bool.isRequired,
    currentBMuser: PropTypes.shape({
        status: PropTypes.string.isRequired,
        fb_invite_link: PropTypes.string.isRequired,
    }),
    domainIsVerified: PropTypes.bool.isRequired,
    pages: PropTypes.shape({
        entities: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            }),
        ),
    }),
    addUserToChildBM: PropTypes.func.isRequired,
    getFbPages: PropTypes.func.isRequired,
    redirectToDashboard: PropTypes.func.isRequired,
    redirectToDomainVerification: PropTypes.func.isRequired,
}

const AcceptInvitationContainer = connect(
    ({ shops, account, businessManager, campaignDetails, shopsCampaignsSettings, facebook }) => ({
        BMusers: businessManager.data,
        campaignIsCreated: !!campaignDetails?.campaignDetailsData.length,
        acceptInvitationPending: businessManager.requestStatus === REQUEST_STATUS_PENDING,
        BMid: shopsCampaignsSettings.entity && shopsCampaignsSettings.entity.business_manager,
        currentBMuser: businessManager.data && getCurrentBMUserSelector(businessManager.data, account),
        showNotification: !businessManager.data || getCurrentBMUserStatus(businessManager.data, account) !== GRANTED,
        domainIsVerified: getCurrentShopSelector(shops, shops.currentShopId)?.params?.domain_verified_checkbox_checked,
        pageName: getSelectedFBPage(facebook.pages.entities, shopsCampaignsSettings.entity)?.name,
        pages: facebook.pages,
    }),
    {
        addUserToChildBM: requestAddUserToChildBM,
        redirectToDomainVerification: goToDomainVerification,
        redirectToDashboard: goToDashboard,
        getFbPages: pagesActions.requestFBPages,
        shouldRender,
        disableRender,
    },
)(AcceptInvitation);

export default pageNameSetter(
    changeShopPage.onboarding_get_started,
    changeShopPage.invitation,
)(AcceptInvitationContainer);
