import {
    GET_GOOGLE_LOGIN_LINK_REQUEST,
    GET_GOOGLE_LOGIN_LINK_SUCCESS,
    GET_GOOGLE_LOGIN_LINK_FAILED,
} from '../../actions/login';

const initialState = {
    authUrl: '',
    authorized: false,
    loginLinkRequest: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GOOGLE_LOGIN_LINK_REQUEST:
            state = {
                ...state,
                loginLinkRequest: true
            };
            break;
        case GET_GOOGLE_LOGIN_LINK_SUCCESS:
            state = {
                ...state,
                authUrl: action.payload ? action.payload.auth_url : '',
                authorized: action.payload && action.payload.authorized,
                loginLinkRequest: false,
            };
            break;
        case GET_GOOGLE_LOGIN_LINK_FAILED:
            state = {
                ...state,
                authUrl: '',
                authorized: false,
                loginLinkRequest: false,
            };
            break;
        default:
    }
    return state;
};
