import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestSendEventMetrics } from 'actions/shop';
import { isRecurringChargeApproved } from 'helpers/onboardingHelper';
import { LoaderFullScreen, LoaderLongWait } from "components/Loader";

import { metricType } from 'constants/metric';

import { ShopifyBillingView } from './ShopifyBillingView';

import './ShopifyBilling.css';

const ShopifyBilling = ({
    shopId,
    recurringChargeIsApproved,
    onRecurringChargeIsApproved,
    requestSendEventMetrics,
    approveChargeURL
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(true)

    useEffect(() => {
        if (localStorage.getItem('auto_redirect_to_approve') === 'true') {
            setIsContentVisible(false);
            setIsLoading(true);
            localStorage.removeItem('auto_redirect_to_approve');
            localStorage.setItem('return_to_location', window.location.href);
            location.replace(approveChargeURL);
        }

        if (recurringChargeIsApproved) {
            setIsLoading(true);
            setIsContentVisible(false);
            onRecurringChargeIsApproved();
        }
    }, [])

    const handleOnClick = () => {
        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_fee_approve_charge',
            },
        };
        requestSendEventMetrics(metricProps);

        localStorage.setItem('return_to_location', window.location.href);
    };

    return (
        <>
            { isContentVisible &&
                <ShopifyBillingView
                    currentShopId={shopId}
                    title="Review payment info: Adwisely fee"
                    subTitle={
                        <>
                            You will pay Adwisely fee via <span>Shopify</span>
                        </>
                    }
                    billingText="Please, approve future charges from Adwisely. You will not pay Adwisely fee during the
            7-day free trial"
                    approveChargeURL={approveChargeURL}
                    handleOnClick={handleOnClick}
                />
            }

            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </>
    )
}

ShopifyBilling.propTypes = {
    approveChargeURL: PropTypes.string.isRequired,
    recurringChargeIsApproved: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ shopRecurringCharge, shops }) => {
    const recurringChargeIsApproved = isRecurringChargeApproved(shopRecurringCharge);
    return {
        shopId: shops?.currentShopId,
        approveChargeURL: shopRecurringCharge.entity?.confirmation_url,
        recurringChargeIsApproved,
    };
};

const mapPropsToDispatch = {
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ShopifyBilling);
