import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Caret } from 'components/Icons';
import './index.css';

const propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

const defaultProps = {
    disabled: false,
};

export const CustomToggler = ({ disabled, children, title, subtitle, caret, customIcon, customToggleImageClass, inNavbar, smallDropdown, toggleTitle }) => (
    <button
        disabled={disabled}
        className={classNames(
            'dropdown-widget__toggle',
            { 'dropdown-widget__toggle_small': smallDropdown },
            { 'dropdown-widget__toggle_in-navbar': inNavbar },
        )}
        type="button"
    >
        <img
            src={customIcon}
            alt={toggleTitle}
            className={classNames('dropdown-widget__toggle-icon', customToggleImageClass)}
        />
        <div className="dropdown-widget__toggle__description">
            {!title && children}
            {title && <span className="flag-toggler__title" title={title}>{title}</span>}
            {title && subtitle && <span className="flag-toggler__subtitle" title={subtitle}>{subtitle}</span>}
        </div>
        
        {caret && <Caret />}
    </button>
);

CustomToggler.propTypes = propTypes;
CustomToggler.defaultProps = defaultProps;

export default CustomToggler;
