export const normalizeCountriesArray = (array, availableCountries) => array.map((slug, id) => ({
        slug,
        name: availableCountries[slug],
        id }
))

export const handleOnCountryListItemClick = ({
                                                 id,
                                                 availableCountries,
                                                 setCountriesList,
                                                 setAvailableCountries,
                                                 countriesList,
                                                 countryFilter,
                                                 setSearchedCountries,
                                                 setIsWorldwide,
                                                 maxNumOfCountries
                                             }) => {
    setIsWorldwide(false)
    let clickedItemId = availableCountries.findIndex(item => item.id === id);
    const clickedItem = availableCountries[clickedItemId];
    let totalCountriesArray = availableCountries;
    if (maxNumOfCountries === 1 ) {
        if (countriesList.length === 1) {
            const restoredAvailableCountriesArray = [...availableCountries.slice(0, countriesList[0].id), countriesList[0] ,...availableCountries.slice(countriesList[0].id)];
            totalCountriesArray = restoredAvailableCountriesArray;
            clickedItemId = id;
        }
        setCountriesList([clickedItem]);
    } else {
        setCountriesList([...countriesList, clickedItem]);
    }
    const updatedAvailableCountriesArray = [...totalCountriesArray.slice(0, clickedItemId), ...totalCountriesArray.slice(clickedItemId + 1)]
    setAvailableCountries(updatedAvailableCountriesArray);
    handleGetSearchedCountries({
        countriesList: updatedAvailableCountriesArray,
        countryFilter,
        setSearchedCountries
    });
}

export const handleOnSelectedCountryClick = ({
                                                 id,
                                                 countriesList,
                                                 setCountriesList,
                                                 setAvailableCountries,
                                                 availableCountries,
                                                 countryFilter,
                                                 setSearchedCountries }) => {
    const clickedItemId = countriesList.findIndex(item => item.id === id);
    const clickedItem = countriesList[clickedItemId];
    const updatedCountries = [...countriesList.slice(0, clickedItemId), ...countriesList.slice(clickedItemId + 1)]
    setCountriesList(updatedCountries);
    const updatedAvailableCountriesArray = [...availableCountries.slice(0, clickedItem.id), clickedItem ,...availableCountries.slice(clickedItem.id)]
    setAvailableCountries(updatedAvailableCountriesArray);
    handleGetSearchedCountries({
        countriesList: updatedAvailableCountriesArray,
        countryFilter,
        setSearchedCountries
    });
}

export const handleGetSearchedCountries = ({countriesList, countryFilter, setSearchedCountries}) => {
    const filteredCountries = countriesList?.filter((country) => {
        if (countryFilter.length > 1) {
            return country.name.toLowerCase().includes(countryFilter) && country;
        }

        return country;
    });
    setSearchedCountries(filteredCountries)
}

export const handleOnWorldwideItemClick = ({
                                               setIsWorldWide,
                                               isWorldwide,
                                               setCountriesList,
                                               countriesList,
                                               availableCountries,
                                               setAvailableCountries,
                                               countryFilter,
                                               setSearchedCountries
                                           }) => {
    setIsWorldWide(!isWorldwide);
    const updatedAvailableCountriesArray = availableCountries.slice();
    if (!isWorldwide) {
        countriesList.forEach(item => {
            updatedAvailableCountriesArray.splice(item.id, 0, item)

        });
        setAvailableCountries(updatedAvailableCountriesArray);
    }
    setCountriesList([])

    handleGetSearchedCountries({
        countriesList: updatedAvailableCountriesArray,
        countryFilter,
        setSearchedCountries
    });
}

export const normalizeSelectedCountriesData = (availableArray, selectedArray, setAvailableData, setSelectedData, setSearchedCountries) => {
    const dublicatedIds = selectedArray.map(selectedItem => {
        const dublicateId = availableArray.findIndex(availableItem => availableItem.slug === selectedItem.slug);
        return dublicateId
    });

    const updatedCountries = availableArray.filter(item => !dublicatedIds.includes(item.id));

    selectedArray.forEach(selectedItem => {
        const dublicateId = availableArray.findIndex(availableItem => availableItem.slug === selectedItem.slug)
        selectedItem.id = availableArray[dublicateId].id;

    })

    setAvailableData(updatedCountries);
    setSelectedData(selectedArray);
    setSearchedCountries(updatedCountries)
}