import { environment_name } from 'constants/environment';

export const stripeProviderPublicKey = (() => {
    switch (environment_name) {
        case 'prod':
            return 'pk_live_1pyYLXUHy77wkCCOZrqIxaWa';
        default:
            return 'pk_test_nHjt7MMZQzIkn1ailAR8zt8V';
    }
})();
