import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

import { metricType } from 'constants/metric';
import Dropdown from 'components/Dropdown';
import { DropdownItemMain } from 'components/DropdownItem';
import DropdownProvider from 'components/DropdownProvider';
import { TransparentToggler } from 'components/DropdownToggler';

import { DATE_RANGES } from '../Insights/constants';
import DropdownDatePicker from './DropdownDatePicker';

import './DropdownButton.css';

const initialState = {
    datePickerVisible: false,
};
const UNSELECTED_ITEM_NAME = '';

const getSelectedItem = ({ dropdownItemsList, activeDropdownItemName }) => {
    let selected = dropdownItemsList.filter(({ id }) => id === activeDropdownItemName).pop();

    if (!selected) {
        selected = { id: DATE_RANGES.CUSTOM, name: activeDropdownItemName };
    }

    return selected;
};

class DropdownButton extends PureComponent {
    state = {
        sinceStatisticDate: null,
        untilStatisticDate: null,
        datePickerVisible: false,
    };

    sendMetricsEvent = (itemId) => {
        const { shopId, requestSendEventMetrics } = this.props;

        const metricProps = {
            shop_id: +shopId,
            type: metricType.dashboardDateRangeChanged,
            props: {
                date_range: itemId,
            },
        };
        requestSendEventMetrics(metricProps);
    };

    chooseDropdownItem = (toggle, itemId) => {
        const { setCampaignId, isCampaignFilter, onCampaignChange, onDateRangeChange } = this.props;

        this.sendMetricsEvent(itemId);

        if (itemId === DATE_RANGES.CUSTOM) {
            this.setState({ datePickerVisible: true });
        } else {
            if (isCampaignFilter) {
                setCampaignId(itemId);
                onCampaignChange(itemId);
            }

            this.setState({ datePickerVisible: false });
            onDateRangeChange?.({ dateRange: itemId });

            toggle();
        }
    };

    clearDatePicker = () => {
        this.setState({
            sinceStatisticDate: null,
            untilStatisticDate: null,
        });
    };

    getCustomDateStatistics = ({ fromDate, toDate }) => {
        this.props.onDateRangeChange({
            dateRange: null,
            untilDate: toDate,
            sinceDate: fromDate,
        });
    };

    render() {
        const { disabled, dropdownItemsList, activeDropdownItemName } = this.props;

        const { sinceStatisticDate, untilStatisticDate, datePickerVisible } = this.state;

        const selected = getSelectedItem({ dropdownItemsList, activeDropdownItemName });

        return (
            <DropdownProvider>
                {({ toggle, isOpen }) => {
                    const flagToggler =  (
                        <TransparentToggler disabled={disabled}>
                            {selected ? selected.name : UNSELECTED_ITEM_NAME}
                        </TransparentToggler>
                    );

                    const datePicker = (
                        <DropdownDatePicker
                            toDate={untilStatisticDate}
                            fromDate={sinceStatisticDate}
                            clearDatePicker={this.clearDatePicker}
                            getCustomDateStatistics={(fromDate, toDate) => {
                                this.getCustomDateStatistics({ fromDate, toDate });
                                this.setState({ sinceStatisticDate: fromDate, untilStatisticDate: toDate });
                                toggle();
                            }}
                        />
                    )

                    const dropdownItems = (
                        dropdownItemsList.map(({ id, name }) => (
                            <DropdownItemMain
                                key={id}
                                title={name}
                                active={id === selected.id}
                                newIcon={id === DATE_RANGES.CUSTOM}
                                onClick={() => this.chooseDropdownItem(toggle, id)}
                            />
                        ))
                    )

                    return (
                        <Dropdown
                            isOpen={isOpen}
                            toggle={(toggleState) => {
                                this.setState({...initialState});
                                toggle(toggleState);
                            }}
                            flagToggler={flagToggler}
                            additionalMenuClassName={'dropdown-button__menu'}
                            additionalDropdownClassName={'dropdown-button'}
                            items={
                                <>
                                    <div className="dropdown-button__menu-list">
                                        {dropdownItems}
                                    </div>
                                    {datePickerVisible && datePicker}
                                </>
                            }
                        />
                    )
                }}
            </DropdownProvider>
        );
    }
}

DropdownButton.defaultProps = {
    disabled: false,
    isCampaignFilter: false,
    onCampaignChange: () => {},
    onDateRangeChange: () => {},
    setCampaignId: () => {},
};

DropdownButton.propTypes = {
    activeDropdownItemName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    dropdownItemsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    isCampaignFilter: PropTypes.bool,
    onCampaignChange: PropTypes.func,
    onDateRangeChange: PropTypes.func,
    setCampaignId: PropTypes.func,
};

export default DropdownButton;
