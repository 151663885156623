import React from 'react';

const LoginErrorMessageFailed = ({ loginLinkError, loginLink }) => (
    <div>
        The server is not responding. It may happen if your internet signal is very slow or absent.{' '}
        {loginLinkError ? null : (
            <a href={loginLink} className="alert-description__link_highlighted">
                Try again
            </a>
        )}
    </div>
);

export default LoginErrorMessageFailed;
