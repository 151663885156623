import React from 'react';
import './InstagramStoriesMobile.css';

import icons from './img/icons.svg';
import bottomMenu from './img/bottom-menu.svg';

export const InstagramStoriesMobile = ({product}) => (
    <div className="insta-stories">
        <div className="insta-stories__lines">
            <div />
            <div />
            <div />
        </div>
        <div className="insta-stories__top">
            <div className='insta-stories__avatar-wrapper'>
                <div className="insta-stories__avatar" />
                <div className="insta-stories__title-wrapper">
                    <div className="insta-stories__title">{product.url}</div>
                    <div className="insta-stories__subtitle">Sponsored</div>
                </div>
            </div>
            <img className='insta-stories__icons' src={icons} alt="icons"/>
        </div>
        <div className="insta-stories__img-wrapper">
            <img src={product.img} alt={product.title } />
        </div>
        <div className="insta-stories__bottom">
            <img src={bottomMenu} alt="menu img"/>
        </div>
    </div>
)