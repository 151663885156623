import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { fetchPost } from 'helpers';

import { SMSModal } from '../SMSModal';
import { useSetPhoneData } from './hooks';
import { CountriesDropdown } from '../VerificationDropdown/CountriesDropdown';

import './VerificationPhoneNumber.css';

const handleSendSmsCode =
    ({ phone, countryCode, setPhoneError, currentShopId, setOpenSMSModal, setIsSMSCodeChecked, countryCallingCode }) =>
    () => {
        const jsonData = JSON.stringify({
            country_code: countryCode,
            phone_number: `+${countryCallingCode}${phone}`,
        });

        fetchPost(`/api/onboarding/${currentShopId}/google/phone-verification-request`, jsonData)
            .then((res) => res.json())
            .then((response) => {
                // reset any phone error for input because we were able to validate phone number
                setIsSMSCodeChecked?.(false);

                if (response.success) {
                    setPhoneError(false);
                    setOpenSMSModal(true);
                    return;
                }

                if (response.error) {
                    setPhoneError(true);
                }
            }).catch(() => {
                setPhoneError(true);
            });
    };

export const VerificationPhoneNumberView = ({ phoneError, setPhoneError, currentShopId }) => {
    const [openSMSModal, setOpenSMSModal] = useState(false);
    const [isSMSCodeChecked, setIsSMSCodeChecked] = useState(false);

    const { phone, setPhone, countryCode, setCountryCode, countryCallingCode, setCountryCallingCode } =
        useSetPhoneData();

    return (
        <>
            <p className="gl-onboarding-settings__verification-phone-number__content">
                Google will use this number to verify you`re the owner of the business. It will not be displayed to
                customers.
            </p>

            <div className="gl-onboarding-settings__verification-phone-number__dropdown-wrapper">
                <span className="gl-onboarding-settings__verification-phone-number__dropdown-wrapper__title">
                    Phone number:
                </span>
                <CountriesDropdown
                    phone={phone}
                    setPhone={setPhone}
                    phoneError={phoneError}
                    setCountryCode={setCountryCode}
                    setCountryCallingCode={setCountryCallingCode}
                />
            </div>

            <Button
                submit
                color="primary"
                onClick={handleSendSmsCode({
                    phone,
                    countryCode,
                    setPhoneError,
                    currentShopId,
                    setOpenSMSModal,
                    setIsSMSCodeChecked,
                    countryCallingCode,
                })}
                className="gl-onboarding-settings__verification-phone-number__button"
            >
                Next
            </Button>

            <SMSModal
                setOpenSMSModal={setOpenSMSModal}
                setIsSMSCodeChecked={setIsSMSCodeChecked}
                isOpen={openSMSModal && !isSMSCodeChecked}
                handleResendSmsCode={handleSendSmsCode({
                    phone,
                    countryCode,
                    setPhoneError,
                    currentShopId,
                    setOpenSMSModal,
                    setIsSMSCodeChecked,
                    countryCallingCode,
                })}
            />
        </>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

export const VerificationPhoneNumber = connect(mapStateToProps)(VerificationPhoneNumberView);
