import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import ModalAddStripe from 'components/ModalAddStripe';
import { goToFacebookBilling } from 'actions/onboarding';
import { setGoogleOnboardingState } from "actions/googleOnboardingState";

import { requestSendEventMetrics, requestShops } from 'actions/shop';
import { tikTokStatePolling } from "modules/onboarding/tiktok/containers/TikTokOnboardingMiddleware";
import { googleStatePolling } from "modules/onboarding/google/helpers/googleStatePolling";



import { getCurrentShopSelector } from 'helpers/selectors';
import { isStripeAdded } from 'helpers/onboardingHelper';

import { metricType } from 'constants/metric';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';

import { changeOnboardingState } from 'modules/home/utils';

import { StripeBillingView } from './StripeBillingView';

import './StripeBilling.css';

class StripeBilling extends Component {
    state = {
        showAddStripeModal: false,
    };

    componentDidMount() {
        const {
            params: { shopId },
            stripeIsAdded,
            redirectToNextStep,
            replace,
            setGoogleOnboardingState
        } = this.props;

        if (localStorage.getItem('auto_redirect_to_approve') === 'true') {
            localStorage.removeItem('auto_redirect_to_approve');
            this.setState({ showAddStripeModal: true });
        }

        if (window.location.pathname.includes(ONBOARDING_TYPES.google) && stripeIsAdded) {
            changeOnboardingState({
                currentShopId: shopId,
                type: ONBOARDING_TYPES.google,
                status: ONBOARDING_STATES.approve_stripe_app_fee,
            }).then((res) => {
                if (res.status === 409) {
                    googleStatePolling(currentShopId, replace, setGoogleOnboardingState)
                }
                return res.json()
            })
                .then((res) => {
                    if (res?.processing) {
                        googleStatePolling(currentShopId, replace, setGoogleOnboardingState);
                    }
                });
            return;
        }

        if (window.location.pathname.includes(ONBOARDING_TYPES.tik_tok) && stripeIsAdded) {
            changeOnboardingState({
                currentShopId: shopId,
                type: ONBOARDING_TYPES.tik_tok,
                status: ONBOARDING_STATES.approve_stripe_app_fee,
            }).then(() => {
                tikTokStatePolling(shopId, replace)

            });
            return;
        }

        stripeIsAdded && redirectToNextStep(shopId);
    }

    componentDidUpdate(prevProps) {
        const {
            stripeIsAdded,
            redirectToNextStep,
            params: { shopId },
            onboarding,
            requestShops,
            replace
        } = this.props;

        if (prevProps.onboarding.stripeAdd.success !== onboarding.stripeAdd.success) {
            requestShops().then(() => {
                if (window.location.pathname.includes(ONBOARDING_TYPES.google) && stripeIsAdded) {
                    return;
                }
                if (window.location.pathname.includes(ONBOARDING_TYPES.tik_tok) && stripeIsAdded) {
                    tikTokStatePolling(shopId, replace)
                    return;
                }

                stripeIsAdded && redirectToNextStep(shopId)
            });
        }
    }

    showModalHandler = () => {
        const {
            params: { shopId },
            requestSendEventMetrics,
        } = this.props;

        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_fee_add_card',
            },
        };
        requestSendEventMetrics(metricProps);

        this.setState({ showAddStripeModal: true });
    };

    hideModalHandler = () => {
        this.setState({ showAddStripeModal: false });
    };

    getStripeModal = (showAddStripeModal) => {
        if (!showAddStripeModal) {
            return null;
        }

        const { currentShop } = this.props;

        const stripeModalHeader = 'Pay Adwisely fee directly to Adwisely';
        const stripeModalBody =
            'To run ads for you, Adwisely has created a brand new ad account. Please, connect a payment method to it';

        const stripeModalList = [
            'You will not be charged now',
            <>
                You will have a <span className="modal-add-stripe__body-detail-options-bold">7-Day Free Trial</span>
            </>,
        ];

        return (
            <ModalAddStripe
                shopId={currentShop.id}
                stripeModalBody={stripeModalBody}
                stripeModalList={stripeModalList}
                isOpenModal={showAddStripeModal}
                stripeModalHeader={stripeModalHeader}
                hideModalHandler={this.hideModalHandler}
            />
        );
    };

    render() {
        const { showAddStripeModal } = this.state;

        const addStripeModal = this.getStripeModal(showAddStripeModal);

        return (
            <StripeBillingView
                title="Review payment info: Adwisely fee"
                subTitle="Pay Adwisely fee directly to Adwisely"
                billingText="Please, approve future charges from Adwisely. You will not pay Adwisely fee during the 7-Day Free Trial"
                addStripeModal={addStripeModal}
                showModalHandler={this.showModalHandler}
            />
        );
    }
}

StripeBilling.propTypes = {
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    onboarding: PropTypes.shape({
        stripeAdd: PropTypes.shape({
            success: PropTypes.bool.isRequired,
        }),
    }).isRequired,
    stripeIsAdded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

const mapStateToProps = ({ shops, onboarding }) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    const stripeIsAdded = isStripeAdded(onboarding.stripeAdd, currentShop);
    return {
        currentShop,
        stripeIsAdded,
        onboarding,
    };
};

const mapDispatchToProps = {
    redirectToNextStep: goToFacebookBilling,
    requestShops,
    requestSendEventMetrics,
    replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeBilling);
