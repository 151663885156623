import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateShopsEntities, updateCurrentShopId } from 'actions/shop';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loader';

import './App.css';
import {addGDPRBanner} from "../helpers/gdpr";

class App extends Component {
    constructor(props) {
        super(props);
        const {
            changeCurrentShopId,
            params: { shopId },
        } = this.props;
        if (+shopId) changeCurrentShopId(shopId);
    }

    componentDidMount() {
        window.addEventListener('storage', (e) => this.storageHandler(e));
        addGDPRBanner();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.shopId !== this.props.params.shopId) {
            this.props.changeCurrentShopId(this.props.params.shopId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('storage', (e) => this.storageHandler(e));
    }

    storageHandler(e) {
        if (e.key === 'shopEntities' && e.newValue !== e.oldValue) {
            this.props.updateShopsEntities(JSON.parse(e.newValue));
        }
    }

    render() {
        const {
            children,
            params: { shopId },
            location,
        } = this.props;

        const isChoosePlanStep = window.location.pathname.endsWith('/choose-plan');

        return (
            <div className="main-wrapper">
                <Header shopId={shopId} location={location} />
                <main
                    className={classNames('main-content main-container', {
                        'main-container--lg': isChoosePlanStep,
                    })}
                >{children}</main>
                <Footer />
                <Loader />
            </div>
        );
    }
}

export default connect(null, {
    updateShopsEntities,
    changeCurrentShopId: (shopId) => (dispatch) => {
        dispatch(updateCurrentShopId(shopId));
        if (window.ga) window.ga('set', 'userId', shopId);
    },
})(App);
