import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import {
    requestCampaignSuggestionsForCampaignCreate,
    clearCampaignError,
    requestCampaignValidateFacebook,
} from 'actions/campaign';

import pageNameSetter from 'decorator/pageNameSetter';

import campaignCreateStorageHelper from 'helpers/campaignCreateStorageHelper';
import {
    getCurrentShopSelector,
    isShopValidateRequesting,
    getAdAccountsSelector,
    getCampaignSuggestionsSelector,
} from 'helpers/selectors';

import Banners from 'components/Banners';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeShopPage } from 'constants/metric';
import AdsPreviewSection from 'components/AdsPreviewSection/AdsPreviewSection';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { SPECIAL_OFFER } from 'constants/campaign';
import { SHOPIFY } from 'constants/platform';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';

import { DAILY_BUDGET_LESS_THEN_MINIMUM } from 'modules/campaign/constants';

import CampaignInfo from 'modules/campaign/components/CampaignInfo';
import CampaignDetails from 'modules/campaign/components/CampaignDetails';
import SaveCampaignDetails from 'modules/campaign/components/SaveCampaignDetails';
import { CampaignPageLayout, CampaignPageCol } from 'modules/campaign/components/CampaignPageLayout';
import { changeOnboardingState } from 'modules/home/utils';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';

// import './CreateSpecialOfferCampaign.css';
import specialOfferIcon from './stars.svg';
import {CreateCampaignAdAudience} from '../CreateCampaignAdAudience/CreateCampaignAdAudience';

const CAMPAIGN_AD_MESSAGE_DEFAULT = 'THIS WEEK ONLY: Get {{product.name}} for just {{product.price}}. Shop now!';
const CAMPAIGN_AD_MESSAGE_DEFAULT_2 =
    'Psst! We know you checked out {{product.name}}. It’s on sale now—come get one while it’s still in stock!';

export class CreateSpecialOfferCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewAdMessage: null,
            currentPreviewType: null,
            isPublishPage: true,
            isLoading: false,

            // save campaign details
            hasCampaignFormError: false,
            tipsErrorText: '',

            // default values
            campaignCurrency: 'USD',
            campaignBudget: 0,
            campaignAdMessage: CAMPAIGN_AD_MESSAGE_DEFAULT,
            campaignAdMessage2: CAMPAIGN_AD_MESSAGE_DEFAULT_2,
            campaignMinBudget: 45,

            adMessageTagsHasError: false,
            adMessageTagsHasErrorTipsErrorText: false,
            saveCampaignError: null,
            isMobilePreview: false,
            countriesList : [],
            isWorldwide: false,
            ageMin: 0,
            ageMax: 0,
            genders: []
        };

        this.handleAdPreviewMsg = this.handleAdPreviewMsg.bind(this);
        this.handlePreviewType = this.handlePreviewType.bind(this);
        this.changePublishPage = this.changePublishPage.bind(this);

        // save campaign details
        this.handleFormError = this.handleFormError.bind(this);
        this.changeTipsErrorText = this.changeTipsErrorText.bind(this);

        this.saveCampaign = this.saveCampaign.bind(this);
        this.refreshMinBudgetAndAdPreview = this.refreshMinBudgetAndAdPreview.bind(this);

        this.handleAdMessageTagsHasError = this.handleAdMessageTagsHasError.bind(this);
        this.campaignValidateFacebookHandler = this.campaignValidateFacebookHandler.bind(this);
    }

    componentDidMount() {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });

        window.addEventListener('focus', this.windowFocusListener);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            params: { shopId },
        } = nextProps;
        if (this.props.suggestions !== nextProps.suggestions) {
            if (nextProps.suggestions.campaignSuggestionsSuccess) {
                const suggestionsData = nextProps.suggestions.campaignSuggestionsData;
                const suggestedAdMessage = CAMPAIGN_AD_MESSAGE_DEFAULT;

                const storageCampaignType = campaignCreateStorageHelper.getCampaignType();
                const storageDailyBudget = campaignCreateStorageHelper.getCampaignDailyBudget();
                const storageAdMessage = campaignCreateStorageHelper.getCampaignAdMessage();
                const storageShopId = campaignCreateStorageHelper.getCampaignShopId();

                if (
                    shopId === storageShopId &&
                    storageCampaignType === SPECIAL_OFFER &&
                    storageDailyBudget &&
                    storageAdMessage
                ) {
                    this.setState({
                        campaignBudget: parseInt(storageDailyBudget, 10) / 100,
                        campaignMinBudget: suggestionsData.daily_budget.special_offer_min_value / 100,
                        campaignCurrency: suggestionsData.daily_budget.currency,
                        campaignAdMessage: storageAdMessage,
                    });
                } else {
                    this.setState({
                        campaignBudget: suggestionsData.daily_budget.special_offer_suggested_value / 100,
                        campaignMinBudget: suggestionsData.daily_budget.special_offer_min_value / 100,
                        campaignCurrency: suggestionsData.daily_budget.currency,
                        campaignAdMessage: suggestedAdMessage,
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.clearCampaignError();
        window.removeEventListener('focus', this.windowFocusListener);
    }

    handleAdPreviewMsg(msg, isForceRequest = false) {
        this.setState({
            previewAdMessage: msg,
        });
    }

    handlePreviewType(curPreviewType, isForceRequest = true) {
        this.setState({
            currentPreviewType: curPreviewType,
        });
    }

    handleFormError(hasCampaignFormError) {
        this.setState({
            hasCampaignFormError,
        });
    }

    saveCampaign(dailyBudget, adMessages) {
        const {
            params: { shopId },
            suggestions,
            onboardings,
            replace,
        } = this.props;

        const { countriesList, isWorldwide, ageMin, ageMax, genders } = this.state;

        if (!suggestions.campaignSuggestionsSuccess || !dailyBudget) return;

        const gendersArray = Object.keys(genders).map(gender => +gender)

        const params = {
            shopId,
            campaign_type: SPECIAL_OFFER,
            daily_budget: dailyBudget,
            messages: adMessages,
            currency: this.state.campaignCurrency,
            targeting_settings: {
                countries: countriesList,
                worldwide: isWorldwide,
                age_min: ageMin,
                age_max: ageMax,
                genders: gendersArray
            }
        };

        changeOnboardingState({
            type: ONBOARDING_TYPES.facebook_special_offer,
            status: UBM_ONBOARDING_STATES.create_special_offer_campaign,
            currentShopId: shopId,
            data: params,
        })
            .then((res) => {
                if (!(res instanceof Error)) {
                    this.setState({ isLoading: true });
                } else if (res.errorToken === DAILY_BUDGET_LESS_THEN_MINIMUM) {
                    this.setState({
                        saveCampaignError: DAILY_BUDGET_LESS_THEN_MINIMUM,
                        isLoading: false,
                    });
                }

                if (res.status === 409) {
                    facebookStatePolling(shopId, replace);
                }
                return res.json();
            })
            .then((res) => {
                if (res?.processing) {
                    facebookStatePolling(shopId, replace);
                }
            });
    }

    refreshMinBudgetAndAdPreview() {
        const {
            requestSuggestions,
            params: { shopId },
        } = this.props;
        requestSuggestions({ id: shopId });
    }

    changeTipsErrorText(tipsText) {
        this.setState({
            tipsErrorText: tipsText,
        });
    }

    changePublishPage(isPublish) {
        this.setState({
            isPublishPage: isPublish,
        });
    }

    handleAdMessageTagsHasError(tipsErrorText) {
        this.setState({
            adMessageTagsHasError: !!tipsErrorText,
            adMessageTagsHasErrorTipsErrorText: tipsErrorText,
        });
    }

    campaignValidateFacebookHandler() {
        const {
            shopValidateRequesting,
            campaignValidateFacebook,
            params: { shopId },
        } = this.props;
        if (!shopValidateRequesting) {
            campaignValidateFacebook(shopId);
        }
    }

    setIsMobilePreview = (newState) => {
        this.setState({ isMobilePreview: newState });
    };

    getAdAudience = (countries, isWorldwide, ageMin, ageMax, genders) => {
        this.setState({
            countriesList: countries.length > 0 ? countries.map(item => item.slug) : [],
            isWorldwide,
            ageMin,
            ageMax,
            genders
        })
    }

    render() {
        const campaignFormName = 'specialOfferCampaignCreate';
        const {
            shopValidateRequesting,
            params: { shopId },
            currentShop,
        } = this.props;
        const {
            campaignBudget,
            campaignAdMessage,
            campaignAdMessage2,
            campaignCurrency,
            campaignMinBudget,
            adMessageTagsHasError,
            hasCampaignFormError,
            adMessageTagsHasErrorTipsErrorText,
            tipsErrorText,
            saveCampaignError,
            isLoading,
        } = this.state;

        const { isWorldwide, countriesList } = this.state;

        return (
            <div className="special_offer-campaign-create__wrapper">
                <CampaignPageLayout>
                    <CampaignPageCol>
                        <CampaignInfo
                            showIcon
                            shopId={shopId}
                            campaignTitle={
                                <p className="campaign-info__title">
                                    Facebook <span className="campaign-info__title--green">Special Offer</span>
                                    <img
                                        src={specialOfferIcon}
                                        alt=""
                                        className="campaign-info__title-img--special-offer"
                                    />
                                </p>
                            }
                            titleIconUrl={specialOfferIcon}
                            learnMoreLink="https://help.adwisely.com/en/articles/4585544-special-offer"
                            linkCaption="Learn more"
                            campaignDescription={`Run a short-term promo for a new collection, sale, sitewide discount,
                                or a special holiday offer to brand new customers and store visitors`}
                            campaignType={SPECIAL_OFFER}
                        />

                        <CampaignDetails
                            formName={campaignFormName}
                            handleAdPreviewMsg={this.handleAdPreviewMsg}
                            handleFormError={this.handleFormError}
                            changeTipsErrorText={this.changeTipsErrorText}
                            campaignAdMessage={campaignAdMessage}
                            campaignAdMessage2={campaignAdMessage2}
                            campaignCurrency={campaignCurrency}
                            campaignMinBudget={campaignMinBudget}
                            campaignBudget={campaignBudget}
                            saveCampaign={this.saveCampaign}
                            handleAdMessageTagsHasError={this.handleAdMessageTagsHasError}
                            saveCampaignError={saveCampaignError}
                            campaignType={SPECIAL_OFFER}
                        />

                        <CreateCampaignAdAudience
                            onAdAudienceSettingsChange={this.getAdAudience}
                            shopId={shopId}
                            isDropdownError={!isWorldwide && Object.keys(countriesList).length === 0}
                            isGenderVisible
                            isAgeVisible
                            isCountriesVisible
                        />

                        <SaveCampaignDetails
                            isButtonDisabled={!isWorldwide && Object.keys(countriesList).length === 0}
                            formName={campaignFormName}
                            buttonTitle="Next"
                            buttonColor="primary"
                            hasCampaignFormError={
                                adMessageTagsHasError || hasCampaignFormError || !!shopValidateRequesting
                            }
                            tipsErrorText={adMessageTagsHasErrorTipsErrorText || tipsErrorText}
                        />
                    </CampaignPageCol>
                    <CampaignPageCol>
                        <AdsPreviewSection
                            currentActiveCard={ONBOARDING_TYPES.facebook_visitor_retargeting}
                            isMobilePreview={this.state.isMobilePreview}
                            setIsMobilePreview={this.setIsMobilePreview}
                            isShopify={currentShop?.type === SHOPIFY}
                            previewAdMessage={this.state.previewAdMessage}
                        />
                    </CampaignPageCol>
                </CampaignPageLayout>
                <LoaderFullScreen isLoaderShowed={isLoading}>
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
            </div>
        );
    }
}

CreateSpecialOfferCampaign.propTypes = {
    campaignValidateFacebook: PropTypes.func.isRequired,
    clearCampaignError: PropTypes.func.isRequired,
    currentShop: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    params: PropTypes.shape({
        shopId: PropTypes.string.isRequired,
    }).isRequired,
    requestSuggestions: PropTypes.func.isRequired,
    shopValidateRequesting: PropTypes.bool.isRequired,
    suggestions: PropTypes.shape({
        campaignSuggestionsSuccess: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const currentShop = getCurrentShopSelector(state.shops, state.shops.currentShopId);
    return {
        suggestions: getCampaignSuggestionsSelector(state),
        shopValidateRequesting: isShopValidateRequesting(state),
        currentShop,
        shops: state.shops,
        adAccounts: getAdAccountsSelector(state),
        onboardings: state.onboarding.onboardingsState.onboardings,
    };
};

const mapDispatchToProps = {
    campaignValidateFacebook: requestCampaignValidateFacebook,
    requestSuggestions: requestCampaignSuggestionsForCampaignCreate,
    clearCampaignError,
    replace,
};

const SpecialOfferCampaignCreateContainer = connect(mapStateToProps, mapDispatchToProps)(CreateSpecialOfferCampaign);

export default pageNameSetter(changeShopPage.create_campaign)(SpecialOfferCampaignCreateContainer);
