import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux'

import { BeforeYouBeginView } from "modules/onboarding/components/BeforeYouBegin/BeforeYouBeginView";
import { changeOnboardingState } from "modules/home/utils";
import { ONBOARDING_TYPES } from "constants/onboarding";
import { TIK_TOK_ONBOARDING_STATES } from "constants/tikTokOnboarging";
import pageNameSetter from "decorator/pageNameSetter";
import { changeShopPage, metricType } from "constants/metric";
import { requestSendEventMetrics } from "actions/shop";
import { LoaderFullScreen, LoaderLongWait } from "components/Loader";
import { tikTokStatePolling } from "../../containers/TikTokOnboardingMiddleware";

const BeforeYouBeginTikTok = ({ shopId, replace, requestSendEventMetrics }) => {
    const [isLoading, setIsLoading] = useState(false);

        return (
            <>
                <BeforeYouBeginView
                    onAgreeButtonClick={() => {
                        setIsLoading(true)
                        changeOnboardingState({
                            currentShopId: shopId,
                            type: ONBOARDING_TYPES.tik_tok,
                            status: TIK_TOK_ONBOARDING_STATES.before_you_begin
                        }).then(() => {
                            tikTokStatePolling(shopId, replace);
                        })
                        const metricProps = {
                            shop_id: shopId,
                            type: metricType.btnClicked,
                            props: {
                                btn_name: 'i_agree',
                            },
                        };
                        requestSendEventMetrics(metricProps);
                    }}
                    onBackButtonClick={() => {
                        replace(`/${shopId}/home/`);
                        const metricProps = {
                            shop_id: shopId,
                            type: metricType.btnClicked,
                            props: {
                                btn_name: 'go_back_and_select_another_platform',
                            },
                        };
                        requestSendEventMetrics(metricProps);
                    }}
                />
                <LoaderFullScreen
                    isLoaderShowed={isLoading}
                >
                    <LoaderLongWait title="Loading..." />
                </LoaderFullScreen>
            </>
        );
    }

const mapStateToProps = ({ shops } ) => (
    {
        shopId: shops.currentShopId
    }
)

const mapDispatchToProps = {
    replace,
    requestSendEventMetrics
}

export default pageNameSetter(changeShopPage.before_you_begin)(
    connect(mapStateToProps, mapDispatchToProps)(BeforeYouBeginTikTok)
);