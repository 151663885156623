import React, {useEffect, useState} from 'react';
import { AdAudience } from '../AdAudience/AdAudience';
import {normalizeSelectedCountriesData} from '../AdAudience/helpers/handleAdAudienceState';

export const EditCampaignAdAudience = ({
    onAdAudienceSettingsChange,
    isDropdownError,
    isWorldwideAvailable,
    isWorldWide,
    availableCountriesData,
    countriesListData,
    maxNumOfCountries,
    gendersData,
    availableGendersData,
    availableAgeMaxData,
    availableAgeMinData,
    ageMaxData,
    ageMinData,
    isGenderVisible,
    isAgeVisible,
    isCountriesVisible
}) => {
    const [availableCountries, setAvailableCountries] = useState(availableCountriesData);
    const [countriesList, setCountriesList] = useState([])
    const [isWorldwide, setIsWorldWide] = useState(isWorldWide);
    const [searchedCountries, setSearchedCountries] = useState([]);
    const [genders, setGenders] = useState(gendersData);
    const [ageMin, setAgeMin] = useState(ageMinData)
    const [ageMax, setAgeMax] = useState(ageMaxData);

    useEffect(() => {
        if (countriesListData.length > 0) {
            normalizeSelectedCountriesData(availableCountriesData, countriesListData, setAvailableCountries, setCountriesList, setSearchedCountries)
        }
    }, [])


    return (
        <AdAudience
            onAdAudienceSettingsChange={onAdAudienceSettingsChange}
            isDropdownError={isDropdownError}
            searchedCountries={searchedCountries}
            setSearchedCountries={setSearchedCountries}
            countriesList={countriesList}
            availableCountries={availableCountries}
            isWorldwide={isWorldwide}
            isWorldwideAvailable={isWorldwideAvailable}
            setAvailableCountries={setAvailableCountries}
            setCountriesList={setCountriesList}
            setIsWorldWide={setIsWorldWide}
            maxNumOfCountries={maxNumOfCountries}
            availableGenders={availableGendersData}
            availableAgeMin={availableAgeMinData}
            availableAgeMax={availableAgeMaxData}
            genders={genders}
            setGenders={setGenders}
            ageMin={ageMin}
            ageMax={ageMax}
            setAgeMin={setAgeMin}
            setAgeMax={setAgeMax}
            isGenderVisible={isGenderVisible}
            isAgeVisible={isAgeVisible}
            isCountriesVisible={isCountriesVisible}
        />)
}