import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { login } from 'actions/shop';
import intercom from 'libs/intercom';
import { LoaderLongWait } from 'components/Loader';

import ShopifyReInstallPage from '../components/ShopifyReInstallPage';

const MIGRATION_ERROR_TOKEN = 'app-install-redirect';

const createRequestError = (shop) => {
    const [title, body, linkText] = ['Login', 'Something went wrong. Try logging in again.', 'Log in With Shopify'];

    return <ShopifyReInstallPage title={title} body={body} link={`https://${shop}/admin/apps`} linkText={linkText} />;
};

const validateShopifyParams = (params) => {
    const { timestamp, hmac, shop } = params;
    return timestamp && hmac && shop;
};

class Install extends PureComponent {
    state = {
        paramsError: false,
        shopRequesting: false,
        shopRequestError: false,
        error: null,
    };

    componentDidMount() {
        const { location, loginShop } = this.props;

        if (validateShopifyParams(location.query)) {
            this.setState({
                shopRequesting: true,
            });
            intercom.checkIntercomInited(100, 10).then((intercomVisitorId) => {
                loginShop({
                    params: location.query,
                    intercom_visitor_id: intercomVisitorId,
                }).then((shop) => {
                    if (shop instanceof Error) {
                        if (shop.errorToken === MIGRATION_ERROR_TOKEN && shop.errorData && shop.errorData.url) {
                            document.location.assign(shop.errorData.url);
                            return;
                        }
                        this.setState({
                            shopRequestError: true,
                            shopRequesting: false,
                            error: shop,
                        });
                        return;
                    }
                    window.location = `/${shop.id}/`;
                });
            });
        } else {
            this.setState({
                paramsError: true,
            });
        }
    }

    render() {
        const { location } = this.props;
        const { paramsError, shopRequestError, shopRequesting, error } = this.state;

        if (shopRequestError) {
            return createRequestError(location.query.shop, error);
        }

        if (paramsError) {
            return createRequestError(location.query.shop, { errorToken: 'bad-params' });
        }

        if (shopRequesting) {
            return <LoaderLongWait />;
        }

        return <div />;
    }
}

export default connect(null, {
    redirect: replace,
    loginShop: login,
})(Install);
