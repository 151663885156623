import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from "react-router-redux";

import { Modal, ModalBody } from 'components/Modal/Modal';

import Button from 'components/Button';
import { fetchPost } from 'helpers';
import { changeOnboardingState } from 'modules/home/utils';
import {setGoogleOnboardingState} from "actions/googleOnboardingState";
import { googleStatePolling } from "modules/onboarding/google/helpers/googleStatePolling";


import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';

import { useSmsCode } from './hooks';
import { SMSInput, SMSCounter } from './components';

import './SMSModal.css';

const SMSModalView = ({ isOpen, currentShopId, setOpenSMSModal, setIsSMSCodeChecked, handleResendSmsCode, replace, setGoogleOnboardingState }) => {
    const { smsCode, isCodeSet, setSmsCode } = useSmsCode();
    const [hasSmsCodeVerificationError, setHasSmsCodeVerificationError] = useState(false);

    const handleConfirmSMSCode =
        ({ smsCode, currentShopId, setIsSMSCodeChecked, setHasSmsCodeVerificationError }) =>
            () => {
                const jsonSmsCode = JSON.stringify({
                    code: smsCode,
                });

                fetchPost(`/api/onboarding/${currentShopId}/google/verify-phone-number`, jsonSmsCode)
                    .then((res) => res.json())
                    .then((response) => {
                        if (response.success) {
                            setIsSMSCodeChecked(true);
                            setHasSmsCodeVerificationError(false);

                            changeOnboardingState({
                                currentShopId,
                                type: ONBOARDING_TYPES.google,
                                status: ONBOARDING_STATES.verify_phone,
                            }).then((res) => {
                                if (res.status === 409) {
                                    googleStatePolling(currentShopId, replace, setGoogleOnboardingState)
                                }
                                return res.json()
                            })
                                .then((res) => {
                                    if (res?.processing) {
                                        googleStatePolling(currentShopId, replace, setGoogleOnboardingState);
                                    }
                                });

                            return;
                        }

                        setHasSmsCodeVerificationError(true);
                    });
            };

    const handleCloseModal = () => {
        setSmsCode('');
        setOpenSMSModal(false);
        setHasSmsCodeVerificationError(false);
    };

    return (
        <Modal isOpen={isOpen} className="gl-onboarding-settings__modal">
            <ModalBody className="gl-onboarding-settings__modal-body">
                <div className="gl-onboarding-settings__modal-body-content">
                    <div
                        onClick={handleCloseModal}
                        className="gl-onboarding-settings__modal__close-icon"
                    />
                    <h2 className="gl-onboarding-settings__modal__title">Enter your verification code</h2>

                    <div className="gl-onboarding-settings__modal__content">
                        Check your phone for the text message with the verification code and enter the 6-digit code you
                        received.
                    </div>
                    <div className="gl-onboarding-settings__modal__content">
                        It may take up to 1 minute to receive the text message.
                    </div>

                    <SMSCounter currentShopId={currentShopId} handleResendSmsCode={handleResendSmsCode} />

                    <SMSInput
                        setSmsCode={setSmsCode}
                        hasSmsCodeVerificationError={hasSmsCodeVerificationError}
                        setHasSmsCodeVerificationError={setHasSmsCodeVerificationError}
                    />

                    {hasSmsCodeVerificationError && (
                        <span className="gl-onboarding-countries-dropdown-wrong-phone">Wrong code</span>
                    )}

                    <Button
                        submit
                        color="primary"
                        disabled={!isCodeSet || hasSmsCodeVerificationError}
                        className="gl-onboarding-settings-cta-button"
                        onClick={handleConfirmSMSCode({
                            smsCode,
                            currentShopId,
                            setIsSMSCodeChecked,
                            setHasSmsCodeVerificationError,
                        })}
                    >
                        Confirm
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
    setGoogleOnboardingState: setGoogleOnboardingState
}

export const SMSModal = connect(mapStateToProps, mapPropsToDispatch)(SMSModalView);
