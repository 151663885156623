import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { SetupDailyBudget } from './components/SetupDailyBudget';
import { VerificationDropdown } from './components/VerificationDropdown';
import { VerificationPhoneNumber } from './components/VerificationPhoneNumber';

import './ConfigureCampaign.css';

const Settings = ({ googleOnboardingState }) => {
    const isVerifyPhone = googleOnboardingState === ONBOARDING_STATES.verify_phone;
    const isConfigureCampaign = googleOnboardingState === ONBOARDING_STATES.configure_campaign;

    const [phoneError, setPhoneError] = useState(false);
    const [hasCampaignFormError, setHasCampaignFormError] = useState(false);

    return (
        <>
            <h1 className="gl-onboarding-settings__title">Review the campaign settings</h1>

            <div className="gl-onboarding-settings__wrapper">
                <VerificationDropdown
                    openDropdown={isVerifyPhone}
                    title="Verify your phone number"
                    type={phoneError ? 'error' : isConfigureCampaign && 'successful'}
                    renderContent={() => (
                        <VerificationPhoneNumber phoneError={phoneError} setPhoneError={setPhoneError} />
                    )}
                />

                <br />
                <br />

                <VerificationDropdown
                    title="Set your daily budget"
                    openDropdown={isConfigureCampaign}
                    type={hasCampaignFormError && 'error'}
                    renderContent={() => (
                        <SetupDailyBudget
                            isVerifyPhone={isVerifyPhone}
                            hasCampaignFormError={hasCampaignFormError}
                            setHasCampaignFormError={setHasCampaignFormError}
                        />
                    )}
                />
            </div>
        </>
    );
};

const mapStateToProps = ({ google }) => ({ googleOnboardingState: google.googleOnboardingState });

export default connect(mapStateToProps)(Settings);
