import * as account from 'actions/account';
import { LOGIN_CONFIRM_SUCCESS } from 'actions/login';

const initialState = {
    entity: null,
    requesting: false,
    requestingSuccess: false,
    requestingFailure: false,
    error: null,
};

/**
 *
 * @param state
 * @param action {{}}
 * @param action.type {string}
 * @param action.payload {{}}
 * @returns {{entity: null, requesting: boolean, requestingSuccess: boolean, requestingFailure: boolean, error: null}}
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case account.ACCOUNT_INFO_REQUEST:
            state = {
                ...initialState,
                requesting: true,
            };
            break;

        case LOGIN_CONFIRM_SUCCESS:
        case account.ACCOUNT_INFO_REQUEST_SUCCESS:
            state = {
                ...initialState,
                entity: action.payload,
                requestingSuccess: true,
            };
            break;

        case account.ACCOUNT_INFO_REQUEST_FAILURE:
            state = {
                ...initialState,
                requestingFailure: true,
                error: action.payload,
            };
            break;
        default:
            break;
    }
    return state;
};
