import React, { PureComponent } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

import { createLinkToBMPayments } from 'helpers';
import { fixedItButton } from 'helpers/bannerHelper';

class ShopIsFrozenBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.TipsErrorText = null;
        this.FormErrorText = null;
        this.getShopFrozenReasonBanner = this.getShopFrozenReasonBanner.bind(this);
    }

    componentDidMount() {
        const { changeTipsErrorText, handleFormError } = this.props;
        if (changeTipsErrorText && this.TipsErrorText) changeTipsErrorText(this.TipsErrorText);
        if (handleFormError && this.FormErrorText) handleFormError(this.FormErrorText);
    }

    getShopFrozenReasonBanner() {
        const { shopError, checkShopForErrors, validationPending, campaignValidateFacebook, currentShop } = this.props;
        let notificationData = null;
        let bannerTitle = null;
        let bannerContent = null;
        let bannerButtons = null;
        if (!shopError.errorData) shopError.errorData = {};
        switch (shopError.errorToken) {
            case 'shop-is-frozen-due-to-shop-password-enabled': {
                bannerTitle = <p className="notification__title">Activate your store</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Your store {shopError.errorData.shop_name} requires a password to view products. To run a
                            campaign, each user needs to have an unrestricted access to your store.{' '}
                            <span className="notification__caption">Go to Shopify Online Store Preferences</span> and
                            disable a password page to run a campaign.
                        </p>
                    </div>
                );

                bannerButtons = (
                    <div>
                        <Button
                            url={`https://${shopError.errorData.shop_domain}/admin/online_store/preferences`}
                            color="transparent-bordered"
                            pending={validationPending}
                        >
                            Go to Online Store Preferences
                        </Button>
                        {fixedItButton(checkShopForErrors)}
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.FormErrorText = true;
                this.TipsErrorText = 'You can’t run a campaign for a store with a password.';
                break;
            }
            case 'shop-is-frozen-due-to-shop-closed': {
                bannerTitle = <p className="notification__title">Launch your store</p>;
                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Your store {shopError.errorData.shop_name} is not launched yet. To run a campaign, you need
                            to launch your store. <b>Go to BigCommerce store settings</b> and launch your store.
                        </p>
                    </div>
                );
                bannerButtons = (
                    <div>
                        <Button
                            url={`https://${shopError.errorData.shop_domain}/manage/settings/store`}
                            color="transparent-bordered"
                        >
                            Go to BigCommerce Settings
                        </Button>
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;
            }
            case 'shop-is-frozen-due-to-shop-down-for-maintenance': {
                bannerTitle = <p className="notification__title">Activate your store</p>;
                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Your store {shopError.errorData.shop_name} has “Down for Maintenance” status. To run a
                            campaign, you need to have an “Open” store. <b>Go to BigCommerce store settings</b> and set
                            the Store Status to “Open”.
                        </p>
                    </div>
                );
                bannerButtons = (
                    <div>
                        <Button
                            url={`https://${shopError.errorData.shop_domain}/manage/settings/store`}
                            color="transparent-bordered"
                        >
                            Go to BigCommerce Settings
                        </Button>
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;
            }
            case 'shop-is-frozen-due-to-spend-cap-reached': {
                bannerTitle = <p className="notification__title">Your action is required</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            The Facebook ad account Adwisely created for running your campaign (for{' '}
                            {shopError.errorData.shop_name}) reached the spending limit that’s why the ads are not
                            delivering. To remove the spending limit and resume your campaign,{' '}
                            <span className="notification__caption">go to Ad Account Settings</span>{' '}
                            {'and in "Set Account Spending Limit" block click on "Remove".'}
                        </p>

                        <p className="notification__description">
                            Once the limit is removed, click{' '}
                            <span className="notification__caption">{'"I fixed it"'}</span> to resume your campaign.
                        </p>
                    </div>
                );

                bannerButtons = (
                    <div>
                        <Button
                            url={
                                'https://business.facebook.com/ads/manager/account_settings/account_billing/' +
                                `?act=${shopError.errorData.ad_account_id || ''}` +
                                `&business_id=${shopError.errorData.fb_business_id || ''}`
                            }
                            color="transparent-bordered"
                        >
                            Go to Ad Account Settings
                        </Button>
                        {fixedItButton(campaignValidateFacebook, { spend_cap_fix_check: true })}
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                break;
            }
            case 'shop-is-frozen-due-to-shop-paused': {
                bannerTitle = <p className="notification__title">Activate your store</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description">
                            Your store ({shopError.errorData.shop_name}) is paused. To run a campaign, you need to have
                            an active store.{' '}
                            <span className="notification__caption">Go to Shopify Account Settings</span> and activate
                            your store to run a campaign.
                        </p>
                    </div>
                );
                bannerButtons = (
                    <Button
                        url={`https://${shopError.errorData.shop_domain}/admin/settings/account`}
                        color="transparent-bordered"
                    >
                        Go to Shopify Account Settings
                    </Button>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.FormErrorText = true;
                this.TipsErrorText = 'You can’t run a campaign for a paused store.';
                break;
            }
            case 'shop-is-frozen-due-to-ad-account-is-unsettled':
            case 'ad-account-has-wrong-status-unsettled': {
                bannerTitle = <p className="notification__title">Your action is required!</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            The ad account Adwisely created for running your campaign is unsettled (you owe money to
                            Facebook). To run a campaign,{' '}
                            <span className="notification__caption">go to Payment Settings</span> and follow the
                            onscreen instructions.
                        </p>
                        <p className="notification__description">
                            Once you settle the payment, click{' '}
                            <span className="notification__caption">I fixed it.</span>
                        </p>
                    </div>
                );
                bannerButtons = (
                    <div>
                        <Button
                            url={createLinkToBMPayments(
                                shopError.errorData.ad_account_id,
                                shopError.errorData.fb_business_id,
                            )}
                            color="transparent-bordered"
                        >
                            Go to Payment Settings
                        </Button>
                        {fixedItButton(campaignValidateFacebook, { ad_account_status: true })}
                    </div>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];
                this.TipsErrorText =
                    'To edit a campaign, you need to add a ' +
                    'new payment method to the ad account used for your retargeting ads.';
                break;
            }
            case 'shop-is-frozen-due-to-pending-recurring-charge':
            case 'shop-is-frozen-due-to-expired-recurring-charge':
            case 'shop-is-frozen-due-to-cancelled-recurring-charge': {
                const { shopRecurringCharge } = this.props;
                const isOnboarding = window.location.pathname.includes('onboarding');
                bannerTitle = <p className="notification__title">Approve Adwisely charge</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            <span>
                                To run a Adwisely-powered campaign, you need to approve a charge from Adwisely (
                            </span>
                            <a
                                href={
                                    'https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/' +
                                    'whats-the-cost-of-retargetapp'
                                }
                                target="_blank"
                                className="billing-info-page__link"
                                rel="noopener noreferrer"
                            >
                                learn more
                            </a>
                            <span>
                                ). Don’t worry, approving charge doesn’t suppose immediate billing. Adwisely fee will
                                be included in your monthly Shopify bill.
                            </span>
                        </p>
                        <p className="notification__description">
                            <span>Make sure a valid card with sufficient funds is added in&nbsp;</span>
                            <a
                                href={`https://${shopError.errorData.shop_domain}/admin/settings/payments`}
                                target="_blank"
                                className="billing-info-page__link"
                                rel="noopener noreferrer"
                            >
                                Shopify Payments
                            </a>
                            <span>
                                &nbsp;before you&nbsp;
                                <span className="settings-page-info__title">Approve charge.</span>
                            </span>
                        </p>
                    </div>
                );
                bannerButtons = shopRecurringCharge.entity ? (
                    <Button
                        url={shopRecurringCharge.entity.confirmation_url}
                        color="transparent-bordered"
                        selfTarget
                    >
                        Approve charge
                    </Button>
                ) : null;

                !isOnboarding && (notificationData = [bannerTitle, bannerContent, bannerButtons]);
                this.TipsErrorText = "You can't run a campaign for not active store.";
                break;
            }
            case 'shop-payment-frozen':
            case 'shop-is-frozen-due-to-shop-payment-required':
            case 'shop-is-frozen-due-to-frozen-recurring-charge': {
                if (currentShop === "shopify") {
                    bannerTitle = <p className="notification__title">Campaign is paused</p>;

                    bannerContent = (
                        <div>
                            <p className="notification__description">
                                Your store ({shopError.errorData.shop_name}) is paused since Shopify couldn’t charge
                                connected payment method. To run your campaign, you need to have an active store.
                            </p>
    
                            <p className="notification__description">
                                Go to <span className="notification__caption">Payments</span> in your Shopify Store Settings
                                to activate your store and run a Adwisely-powered campaign.
                            </p>
                        </div>
                    );
                    bannerButtons = (
                        <Button
                            url={`https://${shopError.errorData.shop_domain}/admin/settings/payments`}
                            color="transparent-bordered"
                        >
                            Go to Shopify Payments
                        </Button>
                    );
                } else {
                    bannerTitle = <p className="notification__title">Add a card to resume your ad</p>;

                    bannerContent = (
                        <div>
                            <p className="notification__description">
                                All your Adwisely-powered ads have been stopped since you removed a payment method from Adwisely.
                            </p>

                            <p className="notification__description">
                                To resume the ads, go to <span className="notification__caption">Payments</span> and add a new card.
                            </p>
                        </div>
                    );
                    bannerButtons = (
                        <Button
                            innerUrl={`/${currentShop.id}/settings`}
                            color="transparent-bordered"
                        >
                            Go to Payments
                        </Button>
                    );
                }

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                this.TipsErrorText = 'We can’t launch a campaign for an inactive store.';

                break;
            }
            case 'shop-is-frozen-due-to-failed-stripe-charge': {
                const { stripeUserChargeTryAgainHandler, isStripeButtonPending, replaceStripeCard } = this.props;
                bannerTitle = (
                    <p className="notification__title">Your ads are paused because we couldn&#39;t charge your card</p>
                );

                bannerContent = (
                    <p className="notification__description">
                        All campaigns you run with Adwisely are paused because we couldn&#39;t charge your card.
                        Please, add funds to your card <br /> and click &#8220;Try again&#8221; or replace the card to
                        resume your ads.
                    </p>
                );
                bannerButtons = (
                    <div>
                        <Button
                            onClick={stripeUserChargeTryAgainHandler}
                            color="transparent-bordered"
                            pending={isStripeButtonPending}
                        >
                            Try again
                        </Button>
                        <button
                            type="button"
                            onClick={replaceStripeCard}
                            className="notification__link notification__link_with-left-margin"
                        >
                            Replace card
                        </button>
                    </div>
                );
                if (replaceStripeCard) {
                    notificationData = [bannerTitle, bannerContent, bannerButtons];
                }

                break;
            }
            case 'shop-is-frozen-due-to-force-paused': {
                bannerTitle = <p className="notification__title">All your ads have been paused</p>;

                bannerContent = (
                    <div>
                        <p className="notification__description notification__description-bottom-margin-8">
                            We determined that your store gets fewer than 500 visitors/month.
                        </p>
                        <p className="notification__description notification__description-bottom-margin-8">
                            At this traffic level, we cannot guarantee the cost-effective performance of the ads we
                            create and manage for you, so we paused your ads.
                        </p>
                        <p className="notification__description notification__description-bottom-margin-8">
                            We will resume your ads once the daily visitor count reaches at least 500 visitors/month.
                        </p>
                        <p className="notification__description">
                            {' '}
                            Learn&nbsp;
                            <a
                                href="https://serpstat.com/blog/website-traffic-analysis-beginners-guide/"
                                className="billing-info-page__link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                how to measure your store visitor count
                            </a>{' '}
                            and&nbsp;
                            <a
                                href="https://sumo.com/stories/increase-website-traffic"
                                className="billing-info-page__link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                how to get more website visitors
                            </a>
                            .
                        </p>
                    </div>
                );

                notificationData = [bannerTitle, bannerContent, bannerButtons];

                break;
            }
            case 'shop-is-frozen-due-to-ra-plugin-for-wc-deactivated': {
                bannerTitle = <p className="notification__title">Activate your Adwisely plugin for WooCommerce</p>;
                bannerContent = (
                    <p className="notification__description">
                        Your Adwisely plugin for your {shopError.errorData.shop_name} is deactivated. To run a
                        campaign, go to your WordPress plugins page and activate Adwisely plugin.
                    </p>
                );
                bannerButtons = (
                    <Button
                        url={`${shopError.errorData.protocol}://${shopError.errorData.shop_domain}${shopError.errorData.shop_plugins_endpoint}`}
                        className="button button_link button_link_with-arrow button_transparent"
                    >
                        Go to the store settings
                    </Button>
                );
                notificationData = [bannerTitle, bannerContent, bannerButtons];

                break;
            }
            default:
                break;
        }

        return notificationData;
    }

    render() {
        const shopIsFrozenBannerArray = this.getShopFrozenReasonBanner();
        if (shopIsFrozenBannerArray) {
            return (
                <div>
                    <NotificationWidget type="danger">
                        {shopIsFrozenBannerArray.map((notificationItem, key) => (
                            <span key={key}>{notificationItem}</span>
                        ))}
                    </NotificationWidget>
                </div>
            );
        }
        return null;
    }
}

export default ShopIsFrozenBanner;
