import { push } from 'react-router-redux';

import { ROUTES } from 'constants/routes';

import {
    callAPICreateCampaign,
    callAPIEditCampaign,
    callAPIGetAdPreview,
    callAPIGetEditPageAdPreview,
    callAPIGetCampaignSuggestions,
    callAPIGetCampaignValidate,
    callAPIGetCampaignsPromo,
    callAPIGetShopCampaigns,
    callAPIGetGoogleCampaigns,
    callAPIGetGoogleCampaign,
    callAPIGetGooglePaymentMethod,
    callAPIGetShopStatisticsData,
    callAPIPauseCampaign,
    callAPIPauseCampaignReason,
    callAPIRequestCampaignValidateFacebook,
    callAPIResumeCampaign,
    callAPIResumeGoogleCampaign,
    callAPIPauseGoogleCampaign,
    callAPIPauseGoogleCampaignReason,
    callAPIDeleteCampaignsPromo,
    callAPIGetGoogleStandardAdPreview,
    callAPIGetGoogleRemarketingAdPreview,
    callAPIEditGoogleCampaign,
} from './api';

export const goToDashboard = (id) => push(`/${id}${ROUTES.dashboard}`);

export const CAMPAIGN_CREATE_REQUEST = 'CAMPAIGN_CREATE_REQUEST';
export const CAMPAIGN_CREATE_SUCCESS = 'CAMPAIGN_CREATE_SUCCESS';
export const CAMPAIGN_CREATE_FAILURE = 'CAMPAIGN_CREATE_FAILURE';

export const requestCreateCampaign = (params) =>
    callAPICreateCampaign({
        params,
        types: [CAMPAIGN_CREATE_REQUEST, CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_CREATE_FAILURE],
    });

export const AD_PREVIEW_REQUEST = 'AD_PREVIEW_REQUEST';
export const AD_PREVIEW_SUCCESS = 'AD_PREVIEW_SUCCESS';
export const AD_PREVIEW_FAILURE = 'AD_PREVIEW_FAILURE';

export const requestAdPreview = (params) =>
    callAPIGetAdPreview({
        params,
        types: [AD_PREVIEW_REQUEST, AD_PREVIEW_SUCCESS, AD_PREVIEW_FAILURE],
    });

export const requestEditPageAdPreview = (campaignId, message, format) =>
    callAPIGetEditPageAdPreview(campaignId, {
        params: {
            format,
            message,
        },
        types: [AD_PREVIEW_REQUEST, AD_PREVIEW_SUCCESS, AD_PREVIEW_FAILURE],
    });

export const requestAdPreviewForCampCreate = (params) => (dispatch) => dispatch(requestAdPreview(params));

export const CLEAR_CAMPAIGN_ERROR = 'CLEAR_CAMPAIGN_ERROR';

export const clearCampaignError = () => ({
    type: CLEAR_CAMPAIGN_ERROR,
});

export const CAMPAIGN_EDIT_REQUEST = 'CAMPAIGN_EDIT_REQUEST';
export const CAMPAIGN_EDIT_SUCCESS = 'CAMPAIGN_EDIT_SUCCESS';
export const CAMPAIGN_EDIT_FAILURE = 'CAMPAIGN_EDIT_FAILURE';

export const requestEditCampaign = (params) =>
    callAPIEditCampaign({
        params,
        types: [CAMPAIGN_EDIT_REQUEST, CAMPAIGN_EDIT_SUCCESS, CAMPAIGN_EDIT_FAILURE],
    });

export const GOOGLE_CAMPAIGN_EDIT_REQUEST = 'GOOGLE_CAMPAIGN_EDIT_REQUEST';
export const GOOGLE_CAMPAIGN_EDIT_SUCCESS = 'GOOGLE_CAMPAIGN_EDIT_SUCCESS';
export const GOOGLE_CAMPAIGN_EDIT_FAILURE = 'GOOGLE_CAMPAIGN_EDIT_FAILURE';

export const requestEditGoogleCampaign = (params) =>
    callAPIEditGoogleCampaign({
        params,
        types: [GOOGLE_CAMPAIGN_EDIT_REQUEST, GOOGLE_CAMPAIGN_EDIT_SUCCESS, GOOGLE_CAMPAIGN_EDIT_FAILURE],
    });

export const CAMPAIGN_VALIDATE_REQUEST = 'CAMPAIGN_VALIDATE_REQUEST';
export const CAMPAIGN_VALIDATE_SUCCESS = 'CAMPAIGN_VALIDATE_SUCCESS';
export const CAMPAIGN_VALIDATE_FAILURE = 'CAMPAIGN_VALIDATE_FAILURE';

export const requestCampaignValidate = (params) =>
    callAPIGetCampaignValidate({
        params,
        types: [CAMPAIGN_VALIDATE_REQUEST, CAMPAIGN_VALIDATE_SUCCESS, CAMPAIGN_VALIDATE_FAILURE],
    });

export const CAMPAIGN_VALIDATE_FB_REQUEST = 'CAMPAIGN_VALIDATE_FB_REQUEST';
export const CAMPAIGN_VALIDATE_FB_SUCCESS = 'CAMPAIGN_VALIDATE_FB_SUCCESS';
export const CAMPAIGN_VALIDATE_FB_FAILURE = 'CAMPAIGN_VALIDATE_FB_FAILURE';

export const requestCampaignValidateFacebook = (shopId, params) =>
    callAPIRequestCampaignValidateFacebook({
        shopId,
        params,
        types: [CAMPAIGN_VALIDATE_FB_REQUEST, CAMPAIGN_VALIDATE_FB_SUCCESS, CAMPAIGN_VALIDATE_FB_FAILURE],
    });

export const CAMPAIGN_SUGGESTIONS_REQUEST = 'CAMPAIGN_SUGGESTIONS_REQUEST';
export const CAMPAIGN_SUGGESTIONS_SUCCESS = 'CAMPAIGN_SUGGESTIONS_SUCCESS';
export const CAMPAIGN_SUGGESTIONS_FAILURE = 'CAMPAIGN_SUGGESTIONS_FAILURE';

export const requestCampaignSuggestions = (params) =>
    callAPIGetCampaignSuggestions({
        params,
        types: [CAMPAIGN_SUGGESTIONS_REQUEST, CAMPAIGN_SUGGESTIONS_SUCCESS, CAMPAIGN_SUGGESTIONS_FAILURE],
    });

export const requestCampaignSuggestionsForCampaignCreate = (params) => (dispatch) => {
    dispatch(requestCampaignSuggestions(params));
};

export const SHOP_STATISTIC_REQUEST = 'SHOP_STATISTIC_REQUEST';
export const SHOP_STATISTIC_SUCCESS = 'SHOP_STATISTIC_SUCCESS';
export const SHOP_STATISTIC_FAILURE = 'SHOP_STATISTIC_FAILURE';

export const requestShopStatisticsData = (shopId, params) =>
    callAPIGetShopStatisticsData(shopId, {
        params: {
            date_preset: params.sinceDate ? null : params.dateRange, // if sinceDate exists (user uses custom datepicker - we should ignore date_preset)
            campaign_id: params.campaignId,
            campaign_type: params.campaignType,
            since: params.sinceDate && params.sinceDate.getTime(),
            until: params.untilDate && params.untilDate.getTime(),
            ...params.params,
        },
        types: [SHOP_STATISTIC_REQUEST, SHOP_STATISTIC_SUCCESS, SHOP_STATISTIC_FAILURE],
    });

export const SHOP_STATISTIC_LIFETIME_REQUEST = 'SHOP_STATISTIC_LIFETIME_REQUEST';
export const SHOP_STATISTIC_LIFETIME_SUCCESS = 'SHOP_STATISTIC_LIFETIME_SUCCESS';
export const SHOP_STATISTIC_LIFETIME_FAILURE = 'SHOP_STATISTIC_LIFETIME_FAILURE';

export const requestShopStatisticsLifetimeData = (shopId, campaignId) =>
    callAPIGetShopStatisticsData(shopId, {
        params: {
            date_range: 'lifetime',
            campaign_id: campaignId,
        },
        types: [SHOP_STATISTIC_LIFETIME_REQUEST, SHOP_STATISTIC_LIFETIME_SUCCESS, SHOP_STATISTIC_LIFETIME_FAILURE],
    });

export const SHOP_CAMPAIGNS_REQUEST = 'SHOP_CAMPAIGNS_REQUEST';
export const SHOP_CAMPAIGNS_SUCCESS = 'SHOP_CAMPAIGNS_SUCCESS';
export const SHOP_CAMPAIGNS_FAILURE = 'SHOP_CAMPAIGNS_FAILURE';

export const requestShopCampaigns = (shopId, params) =>
    callAPIGetShopCampaigns(shopId, {
        params,
        types: [SHOP_CAMPAIGNS_REQUEST, SHOP_CAMPAIGNS_SUCCESS, SHOP_CAMPAIGNS_FAILURE],
    });

export const GOOGLE_CAMPAIGNS_REQUEST = 'GOOGLE_CAMPAIGNS_REQUEST';
export const GOOGLE_CAMPAIGNS_SUCCESS = 'GOOGLE_CAMPAIGNS_SUCCESS';
export const GOOGLE_CAMPAIGNS_FAILURE = 'GOOGLE_CAMPAIGNS_FAILURE';

export const requestGoogleCampaigns = (shopId, params) =>
    callAPIGetGoogleCampaigns(shopId, {
        params,
        types: [GOOGLE_CAMPAIGNS_REQUEST, GOOGLE_CAMPAIGNS_SUCCESS, GOOGLE_CAMPAIGNS_FAILURE],
    });

export const GOOGLE_CAMPAIGN_REQUEST = 'GOOGLE_CAMPAIGN_REQUEST';
export const GOOGLE_CAMPAIGN_SUCCESS = 'GOOGLE_CAMPAIGN_SUCCESS';
export const GOOGLE_CAMPAIGN_FAILURE = 'GOOGLE_CAMPAIGN_FAILURE';

export const requestGoogleCampaign = (shopId, campaignId, params) =>
    callAPIGetGoogleCampaign(shopId, campaignId, {
        params,
        types: [GOOGLE_CAMPAIGN_REQUEST, GOOGLE_CAMPAIGN_SUCCESS, GOOGLE_CAMPAIGN_FAILURE],
    });

export const GOOGLE_PAYMENT_METHOD_REQUEST = 'GOOGLE_PAYMENT_METHOD_REQUEST';
export const GOOGLE_PAYMENT_METHOD_SUCCESS = 'GOOGLE_PAYMENT_METHOD_SUCCESS';
export const GOOGLE_PAYMENT_METHOD_FAILURE = 'GOOGLE_PAYMENT_METHOD_FAILURE';

export const requestGooglePaymentMethod = (shopId, params) =>
    callAPIGetGooglePaymentMethod(shopId, {
        params,
        types: [GOOGLE_PAYMENT_METHOD_REQUEST, GOOGLE_PAYMENT_METHOD_SUCCESS, GOOGLE_PAYMENT_METHOD_FAILURE],
    });

export const CAMPIGNS_PROMO_REQUEST = 'CAMPIGNS_PROMO_REQUEST';
export const CAMPIGNS_PROMO_SUCCESS = 'CAMPIGNS_PROMO_SUCCESS';
export const CAMPIGNS_PROMO_FAILURE = 'CAMPIGNS_PROMO_FAILURE';

export const requestCampaignsPromo = (shopId, params) =>
    callAPIGetCampaignsPromo(shopId, {
        params,
        types: [CAMPIGNS_PROMO_REQUEST, CAMPIGNS_PROMO_SUCCESS, CAMPIGNS_PROMO_FAILURE],
    });

export const CAMPIGNS_PROMO_DELETE_REQUEST = 'CAMPIGNS_PROMO_DELETE_REQUEST';
export const CAMPIGNS_PROMO_DELETE_SUCCESS = 'CAMPIGNS_PROMO_DELETE_SUCCESS';
export const CAMPIGNS_PROMO_DELETE_FAILURE = 'CAMPIGNS_PROMO_DELETE_FAILURE';
export const requestDeleteCampaignPromo = (shopId, campaignPromoId, params = {}) =>
    callAPIDeleteCampaignsPromo(shopId, campaignPromoId, {
        params,
        types: [CAMPIGNS_PROMO_DELETE_REQUEST, CAMPIGNS_PROMO_DELETE_SUCCESS, CAMPIGNS_PROMO_DELETE_FAILURE],
    });

export const GOOGLE_CAMPAIGN_PAUSE_REQUEST = 'GOOGLE_CAMPAIGN_PAUSE_REQUEST';
export const GOOGLE_CAMPAIGN_PAUSE_SUCCESS = 'GOOGLE_CAMPAIGN_PAUSE_SUCCESS';
export const GOOGLE_CAMPAIGN_PAUSE_FAILURE = 'GOOGLE_CAMPAIGN_PAUSE_FAILURE';

export const requestPauseGoogleCampaign = (params) =>
    callAPIPauseGoogleCampaign({
        params,
        types: [GOOGLE_CAMPAIGN_PAUSE_REQUEST, GOOGLE_CAMPAIGN_PAUSE_SUCCESS, GOOGLE_CAMPAIGN_PAUSE_FAILURE],
    });

export const GOOGLE_CAMPAIGN_PAUSE_REASON = 'GOOGLE_CAMPAIGN_PAUSE_REASON';
export const GOOGLE_CAMPAIGN_PAUSE_REASON_SUCCESS = 'GOOGLE_CAMPAIGN_PAUSE_REASON_SUCCESS';
export const GOOGLE_CAMPAIGN_PAUSE_REASON_FAILURE = 'GOOGLE_CAMPAIGN_PAUSE_REASON_FAILURE';

export const requestGoogleCampaignPauseReason = (params) =>
    callAPIPauseGoogleCampaignReason({
        params,
        types: [
            GOOGLE_CAMPAIGN_PAUSE_REASON,
            GOOGLE_CAMPAIGN_PAUSE_REASON_SUCCESS,
            GOOGLE_CAMPAIGN_PAUSE_REASON_FAILURE,
        ],
    });

export const CAMPAIGN_PAUSE_REQUEST = 'CAMPAIGN_PAUSE_REQUEST';
export const CAMPAIGN_PAUSE_SUCCESS = 'CAMPAIGN_PAUSE_SUCCESS';
export const CAMPAIGN_PAUSE_FAILURE = 'CAMPAIGN_PAUSE_FAILURE';

export const requestPauseCampaign = (params) =>
    callAPIPauseCampaign({
        params,
        types: [CAMPAIGN_PAUSE_REQUEST, CAMPAIGN_PAUSE_SUCCESS, CAMPAIGN_PAUSE_FAILURE],
    });

export const CAMPAIGN_PAUSE_REASON = 'CAMPAIGN_PAUSE_REASON';
export const CAMPAIGN_PAUSE_REASON_SUCCESS = 'CAMPAIGN_PAUSE_REASON_SUCCESS';
export const CAMPAIGN_PAUSE_REASON_FAILURE = 'CAMPAIGN_PAUSE_REASON_FAILURE';

export const requestCampaignPauseReason = (params) =>
    callAPIPauseCampaignReason({
        params,
        types: [CAMPAIGN_PAUSE_REASON, CAMPAIGN_PAUSE_REASON_SUCCESS, CAMPAIGN_PAUSE_REASON_FAILURE],
    });

export const CAMPAIGN_RESUME_REQUEST = 'CAMPAIGN_RESUME_REQUEST';
export const CAMPAIGN_RESUME_SUCCESS = 'CAMPAIGN_RESUME_SUCCESS';
export const CAMPAIGN_RESUME_FAILURE = 'CAMPAIGN_RESUME_FAILURE';

export const requestResumeCampaign = (params) =>
    callAPIResumeCampaign({
        params,
        types: [CAMPAIGN_RESUME_REQUEST, CAMPAIGN_RESUME_SUCCESS, CAMPAIGN_RESUME_FAILURE],
    });

export const GOOGLE_CAMPAIGN_RESUME_REQUEST = 'GOOGLE_CAMPAIGN_RESUME_REQUEST';
export const GOOGLE_CAMPAIGN_RESUME_SUCCESS = 'GOOGLE_CAMPAIGN_RESUME_SUCCESS';
export const GOOGLE_CAMPAIGN_RESUME_FAILURE = 'GOOGLE_CAMPAIGN_RESUME_FAILURE';

export const requestResumeGoogleCampaign = (params) =>
    callAPIResumeGoogleCampaign({
        params,
        types: [GOOGLE_CAMPAIGN_RESUME_REQUEST, GOOGLE_CAMPAIGN_RESUME_SUCCESS, GOOGLE_CAMPAIGN_RESUME_FAILURE],
    });

export const GOOGLE_STANDARD_AD_PREVIEW_REQUEST = 'GOOGLE_STANDARD_AD_PREVIEW_REQUEST';
export const GOOGLE_STANDARD_AD_PREVIEW_SUCCESS = 'GOOGLE_STANDARD_AD_PREVIEW_SUCCESS';
export const GOOGLE_STANDARD_AD_PREVIEW_FAILURE = 'GOOGLE_STANDARD_AD_PREVIEW_FAILURE';

export const requestGoogleStandardAdPreview = (params) =>
    callAPIGetGoogleStandardAdPreview({
        params,
        types: [
            GOOGLE_STANDARD_AD_PREVIEW_REQUEST,
            GOOGLE_STANDARD_AD_PREVIEW_SUCCESS,
            GOOGLE_STANDARD_AD_PREVIEW_FAILURE,
        ],
    });

export const GOOGLE_REMARKETING_AD_PREVIEW_REQUEST = 'GOOGLE_REMARKETING_AD_PREVIEW_REQUEST';
export const GOOGLE_REMARKETING_AD_PREVIEW_SUCCESS = 'GOOGLE_REMARKETING_AD_PREVIEW_SUCCESS';
export const GOOGLE_REMARKETING_AD_PREVIEW_FAILURE = 'GOOGLE_REMARKETING_AD_PREVIEW_FAILURE';

export const requestGoogleRemarketingAdPreview = (params) =>
    callAPIGetGoogleRemarketingAdPreview({
        params,
        types: [
            GOOGLE_REMARKETING_AD_PREVIEW_REQUEST,
            GOOGLE_REMARKETING_AD_PREVIEW_SUCCESS,
            GOOGLE_REMARKETING_AD_PREVIEW_FAILURE,
        ],
    });

export const SET_AUTO_CREATE_CAMPAIGN_TYPE = 'SET_AUTO_CREATE_CAMPAIGN_TYPE';

export const setAutoCreateCampaignType = (title) => ({
    type: SET_AUTO_CREATE_CAMPAIGN_TYPE,
    payload: title,
});

export const CLEAR_AUTO_CREATE_CAMPAIGN_TYPE = 'CLEAR_AUTO_CREATE_CAMPAIGN_TYPE';

export const FILTER_FACEBOOK_CAMPAIGN_ID = 'FILTER_FACEBOOK_CAMPAIGN';

export const filterFacebookCampaigns = (campaignid) => ({
    type: FILTER_FACEBOOK_CAMPAIGN_ID,
    payload: campaignid,
});

export const FILTER_GOOGLE_CAMPAIGN_ID = 'FILTER_GOOGLE_CAMPAIGN_ID';

export const filterGoogleCampaigns = (campaignid) => ({
    type: FILTER_GOOGLE_CAMPAIGN_ID,
    payload: campaignid,
});

export const clearAutoCreateCampaignType = () => ({
    type: CLEAR_AUTO_CREATE_CAMPAIGN_TYPE,
});
