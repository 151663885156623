import { SHOP_BILLING_PLAN_STATUS_SUCCESS, SHOP_BILLING_PLAN_STATUS_FAILURE } from 'actions/shop';

const initialState = {
    currency: null,
    currentBillingPlan: null,
    nextBillingPlan: null,
    spends: null,
    error: null,
    billingPeriodDateRange: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SHOP_BILLING_PLAN_STATUS_SUCCESS:
            state = {
                ...initialState,
                currency: payload.currency,
                currentBillingPlan: payload.current_billing_plan,
                nextBillingPlan: payload.next_billing_plan,
                spends: payload.spends,
                error: null,
                billingPeriodDateRange: payload.billing_period_date_range
            };
            break;
        case SHOP_BILLING_PLAN_STATUS_FAILURE:
            state = {
                ...initialState,
                error: payload,
            };
            break;
        default:
            break;
    }
    return state;
};
