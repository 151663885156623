import { push } from 'react-router-redux';

import * as api from './api';

export const LOGIN_PAGE_URI = '/facebook/login';
export const LOGIN_CALLBACK_PAGE_URI = '/facebook/login/callback';

/**
 * Action creator that redirects to the in-app Facebook login page
 * Possible to show error alert.
 * @param errorStr {string} - error to be alerted at login page
 * @return {{}} - action object
 */
export const goToLogin = (errorStr) => push(`${LOGIN_PAGE_URI}?error=${errorStr}`);

/**
 *  Action creator that redirect to Facebook login page
 *  @param uri {string} - uri to redirect
 *  @return {{}} - action object
 */
export const goAfterLogin = (uri) => () => {
    window.location = uri || '/';
};

export const LOGIN_LINK_REQUEST = 'LOGIN_LINK_REQUEST';
export const LOGIN_LINK_SUCCESS = 'LOGIN_LINK_SUCCESS';
export const LOGIN_LINK_FAILURE = 'LOGIN_LINK_FAILURE';

/**
 * Action creator to request Facebook OAuth login link
 */
export const requestLoginLink = (rerequest) => {
    const params = {
        redirect_url: window.location.origin + LOGIN_CALLBACK_PAGE_URI,
    };
    if (rerequest) {
        params.rerequest = true;
    }
    return api.callAPIGetFBLoginLink({
        params,
        types: [LOGIN_LINK_REQUEST, LOGIN_LINK_SUCCESS, LOGIN_LINK_FAILURE],
    });
};

export const LOGIN_CONFIRM_REQUEST = 'LOGIN_CONFIRM_REQUEST';
export const LOGIN_CONFIRM_SUCCESS = 'LOGIN_CONFIRM_SUCCESS';
export const LOGIN_CONFIRM_FAILURE = 'LOGIN_CONFIRM_FAILURE';

/**
 * Action creator to confirm Facebook authentication from Facebook by `code`
 * @param code {string} - Facebook authentication code
 * @param redirect_url {string} - Callback uri.
 *
 */
export const confirmLoginWithCode = (data) =>
    api.callAPIFacebookLogin({
        params: {
            code: data.code,
            intercom_visitor_id: data.intercom_visitor_id,
            redirect_url: window.location.origin + LOGIN_CALLBACK_PAGE_URI,
        },
        types: [LOGIN_CONFIRM_REQUEST, LOGIN_CONFIRM_SUCCESS, LOGIN_CONFIRM_FAILURE],
    });

/**
 * Action creator to confirm Facebook authentication from Facebook by `code`
 * @param code {string} - Facebook authentication code
 * @param redirect_url {string} - Callback uri.
 *
 */

export const GET_GOOGLE_LOGIN_LINK_REQUEST = 'GET_GOOGLE_LOGIN_LINK_REQUEST';
export const GET_GOOGLE_LOGIN_LINK_SUCCESS = 'GET_GOOGLE_LOGIN_LINK_SUCCESS';
export const GET_GOOGLE_LOGIN_LINK_FAILED = 'GET_GOOGLE_LOGIN_LINK_FAILED';

export const requestGoogleLoginLink = (params) =>
    api.callAPIGetGoogleLoginUrl({
        params,
        types: [GET_GOOGLE_LOGIN_LINK_REQUEST, GET_GOOGLE_LOGIN_LINK_SUCCESS, GET_GOOGLE_LOGIN_LINK_FAILED],
    });
