import * as businessManager from '../actions/businessManager';

const initialState = {
    userBMList: [],
    userBMListRequesting: false,
    userBMListError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case businessManager.GET_USER_BM_LIST_REQUEST:
            state = {
                ...state,
                userBMListRequesting: true,
            };
            break;

        case businessManager.GET_USER_BM_LIST_SUCCESS:
            state = {
                ...state,
                userBMList: action.payload,
                userBMListRequesting: false,
                userBMListError: false,
            };
            break;

        case businessManager.GET_USER_BM_LIST_FAILURE:
            state = {
                ...state,
                userBMListRequesting: false,
                userBMListError: true,
            };
            break;

        default:
            break;
    }
    return state;
};
