import React from 'react';
import cx from 'classnames';

import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { Caret } from 'components/Icons';

const normalizeDropdownPlaceholder = (choosedCounriesArray) => choosedCounriesArray.map(item => item.name).join(', ')

export const CountriesDropdown = ({
                                      isDropdownError,
                                      availableCountries,
                                      countriesList,
                                      handleCountriesSearch,
                                      isWorldwide,
                                      isWorldwideAvailable,
                                      onCountryListItemClick,
                                      onSelectedCountryClick,
                                      onWorldwideItemClick,
                                      resetCountriesFilter,
                                      maxNumOfCountries }) => (
    <DropdownProvider>
        {({isOpen, toggle}) => {
            const flagToggler = (
                <Button square className={cx('countries-dropdown-flag', {
                    'countries-dropdown-flag--error': isDropdownError
                })}>
                    <span>{isWorldwide ? "Worldwide" : countriesList.length > 0 ? normalizeDropdownPlaceholder(countriesList) : `Pick up to ${maxNumOfCountries} locations`}</span>
                    <Caret/>
                </Button>
            )
            const selectedCountries = countriesList.map(({name, id, slug}) => (
                <div
                    key={slug}
                    className={cx('countries-dropdown-item countries-dropdown-item--selected')}
                    onClick={() => {
                        onSelectedCountryClick(id);
                    }}
                >
                    <span>{name}</span>
                </div>
            ))

            const items = availableCountries?.map(({name, id, slug}) => (
                <div
                    key={slug}
                    className={cx('countries-dropdown-item')}
                    onClick={() => {
                        if (maxNumOfCountries !== 1 && countriesList.length >= maxNumOfCountries) return;
                        onCountryListItemClick(id);
                    }}
                >
                    <span>{name}</span>
                </div>
            ))
            return (
                <div className='ad-audience-form-group'>
                    <div className='ad-audience-form-group__label'>from</div>
                    <Dropdown
                        toggle={(e) => {
                            resetCountriesFilter();
                            toggle(e);
                        }}
                        isOpen={isOpen}
                        items={
                            <>
                                {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                                <input autoFocus
                                    type="text"
                                    placeholder="Search"
                                    onChange={handleCountriesSearch}
                                    onBlur={({target}) => {
                                        target.focus()
                                    }}
                                    className="countries-dropdown-search"
                                />
                                {isWorldwideAvailable &&
                                    <div
                                        className={cx('countries-dropdown-item countries-dropdown-item--worldwide', {
                                            "countries-dropdown-item--selected": isWorldwide
                                        })}
                                        onClick={() => {
                                            onWorldwideItemClick();
                                        }}
                                    >
                                        <span>Worldwide</span>
                                    </div>
                                }
                                <div className="countries-dropdown-info">Pick up to {maxNumOfCountries} locations</div>
                                {selectedCountries}
                                {items}
                            </>
                        }
                        flagToggler={flagToggler}
                        additionalMenuClassName={'countries-dropdown-menu'}
                    />
                </div>
            )

        }}
    </DropdownProvider>
)