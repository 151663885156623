import React from 'react';

import './facebookButton.styles.css';

export const FacebookButton = ({ link, target, onClick, disabled } = {}) => {
    if (link) {
        return (
            <a
                className={disabled ? 'facebook_button_wrapper disabled' : 'facebook_button_wrapper'}
                href={link}
                target={target}
            >
                Continue with Facebook
            </a>
        );
    }

    return (
        <div onClick={onClick} className={disabled ? 'facebook_button_wrapper disabled' : 'facebook_button_wrapper'}>
            Continue with Facebook
        </div>
    );
};
