import * as campaignActions from '../actions/campaign';

const initialState = {
    campaignSuggestionsData: null,
    campaignSuggestionsPending: false,
    campaignSuggestionsSuccess: false,
    campaignSuggestionsFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.CAMPAIGN_SUGGESTIONS_REQUEST: {
            state = {
                ...initialState,
                campaignSuggestionsPending: true,
            };
            break;
        }
        case campaignActions.CAMPAIGN_SUGGESTIONS_SUCCESS: {
            state = {
                ...initialState,
                campaignSuggestionsData: action.payload,
                campaignSuggestionsSuccess: true,
            };
            break;
        }
        case campaignActions.CAMPAIGN_SUGGESTIONS_FAILURE: {
            state = {
                ...initialState,
                campaignSuggestionsFailure: true,
            };
            break;
        }
        default:
            break;
    }
    return state;
};
