import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/routes';

import { redirect } from 'actions/redirect';
import { requestAccountInfo } from 'actions/account';
import { requestGoogleLoginLink } from 'actions/login';
import { requestShopNotifications } from 'actions/notification';
import { shouldRender, disableRender } from 'actions/pageLoading';
import { requestShopQualification } from 'actions/shopQualification';
import { requestShops, getOrCreateRecurringCharge, requestShopSubscription } from 'actions/shop';
import { requestGetUserStatusInChildBM } from 'actions/businessManager';
import { requestShopsCampaignsSettings } from 'actions/shopCampaignsSettings';
import { requestShopCampaigns, requestGoogleCampaigns, requestCampaignsPromo } from 'actions/campaign';

import { getCurrentShopSelector, isStaffPlatformPlanSelector } from 'helpers/selectors';

import { LoaderLongWait as Loader } from 'components/Loader';
import { BIG_COMMERCE, SHOPIFY, WOO_COMMERCE } from 'constants/platform';
import { SUBSCRIPTION } from 'constants/userBillingType';
import { currentDate } from 'modules/dashboard/CampaignDetails/CampaignDetailsPromo';

function hasAccessToShop(shops, shopId) {
    return shops.some((shop) => shop.id === +shopId);
}

export class ShopMiddleware extends Component {
    componentDidMount() {
        const {
            replace,
            params: { shopId },
            location: { pathname },
            getCampaignsSettingsAndUserStatusInChildBM,
            currentShop,
            shops,
            account,
            shouldRender,
            disableRender,
        } = this.props;

        const savedLocationHref = localStorage.getItem('return_to_location');
        if ((location?.search.indexOf('charge_id') !== -1) && savedLocationHref) {
            window.location = savedLocationHref;
            localStorage.removeItem('return_to_location');
            return;
        }

        if (shops.shopRequestSuccess) {
            if (Array.isArray(shops.entities) && !hasAccessToShop(shops.entities, shopId)) {
                if (currentShop) {
                    replace(`/${currentShop.id}/`);
                    return;
                }
                replace('/');
                return;
            }

            if (pathname.search(/onboarding/) === -1 && pathname.search(/shop\/[0-9]*\/?$/) === -1 && !account.entity) {
                replace(ROUTES.facebookLogin);
                return;
            }

            const isSubscriptionBusinessModel = currentShop && currentShop?.business_model === SUBSCRIPTION;
            const isShopify = currentShop && currentShop?.type === SHOPIFY;
            getCampaignsSettingsAndUserStatusInChildBM(shopId, shouldRender, disableRender, isShopify, isSubscriptionBusinessModel);

            if (currentShop && currentShop.rav2_migration_onboarding) {
                replace(`/ra3-migration/${shopId}`);
                return;
            }

            if (currentShop && currentShop.app_uninstalled_at) {
                if (currentShop?.type === SHOPIFY) {
                    replace(`/shopify/uninstalled/${shopId}`);
                } else if (currentShop?.type === BIG_COMMERCE) {
                    replace(`/bigcommerce/uninstalled/${shopId}`);
                } else if (currentShop?.type === WOO_COMMERCE) {
                    replace(`/woocommerce/uninstalled/${shopId}`);
                }
                return;
            }

            if (currentShop) {
                try {
                    const currentShopParams = JSON.parse(currentShop.params);
                    if (
                        currentShop &&
                        currentShop?.type === BIG_COMMERCE &&
                        currentShopParams &&
                        currentShopParams.stencil_enabled === false
                    ) {
                        replace('/bigcommerce/contact-us');
                    }
                } catch (e) {}
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            replace,
            shops,
            params: { shopId },
            getCampaignsSettingsAndUserStatusInChildBM,
            currentShop,
            shouldRender,
            disableRender,
        } = this.props;

        if (prevProps.shops !== shops || shopId !== prevProps.params.shopId) {
            if (currentShop) {
                if (currentShop.app_uninstalled_at) {
                    if (currentShop?.type === SHOPIFY) {
                        replace(`/shopify/uninstalled/${shopId}`);
                    } else if (currentShop?.type === BIG_COMMERCE) {
                        replace(`/bigcommerce/uninstalled/${shopId}`);
                    } else if (currentShop?.type === WOO_COMMERCE) {
                        replace(`/woocommerce/uninstalled/${shopId}`);
                    }
                    return;
                }
            } else {
                replace('/');
                return;
            }
        }

        if (currentShop) {
            if (currentShop.id !== prevProps.currentShop.id) {
                const isSubscriptionBusinessModel = currentShop && currentShop?.business_model === SUBSCRIPTION;
                const isShopify = currentShop && currentShop?.type === SHOPIFY;
                getCampaignsSettingsAndUserStatusInChildBM(shopId, shouldRender, disableRender, isShopify, isSubscriptionBusinessModel);
            }
        }
    }

    render() {
        const { pageLoaded, children } = this.props;
        if (pageLoaded) return children;
        return (
            <div style={{ alignSelf: 'center', width: '100%' }}>
                <Loader />
            </div>
        );
    }
}

const mapStateToProps = ({
    shops,
    account,
    shopsCampaignsSettings,
    businessManager,
    campaignDetails,
    loadingStatus: { loaded },
    google
}) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    return {
        shops,
        currentShop,
        shopsCampaignsSettings,
        account,
        businessManager,
        campaignDetails,
        isStaffPlatformPlan: isStaffPlatformPlanSelector(currentShop),
        pageLoaded: loaded,
        isGoogleAuthorized: google.login.authorized
    };
};

const mapPropsToDispatch = {
    requestShops,
    requestAccountInfo,
    requestShopCampaigns,
    requestGoogleCampaigns,
    requestGoogleLoginLink,
    requestCampaignsPromo,
    getOrCreateRecurringCharge,
    getCampaignsSettingsAndUserStatusInChildBM: (shopId, shouldRender, disableRender, isShopify, isSubscriptionBusinessModel) => (dispatch) => {
        disableRender();
        return (
            dispatch(requestShopCampaigns(shopId))
                .then(() => dispatch(requestGoogleCampaigns(shopId)))
                .then(() => dispatch(requestGoogleLoginLink({ redirect_path: `/${shopId}/google` })))
                .then(() => dispatch(requestCampaignsPromo(shopId, { date: currentDate })))
                // TODO: Resolve if check qualification failed
                .then(() => dispatch(requestShopQualification({ id: shopId })))
                .then(() => dispatch(requestShopNotifications({ id: shopId })))
                .then(() => isSubscriptionBusinessModel && dispatch(requestShopSubscription(shopId)))
                .then(() => isShopify && dispatch(getOrCreateRecurringCharge(shopId)))
                .then(() => dispatch(requestShopsCampaignsSettings(shopId)))
                .then((res) => {
                    if (!(res instanceof Error)) {
                        if (!!res.page && !!res.business_manager && !!res.ad_account) {
                            return dispatch(requestGetUserStatusInChildBM(res.business_manager));
                        }
                        shouldRender();
                    } else {
                        disableRender();
                    }
                    return Promise.reject();
                })
                .then((res) => {
                    shouldRender();
                    return res;
                })
                .catch((error) => error)
        );
    },
    replace: redirect,
    shouldRender,
    disableRender,
};

export default connect(mapStateToProps, mapPropsToDispatch)(ShopMiddleware);
