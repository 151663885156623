import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { metricType } from 'constants/metric';
import { goToSetUpRetargeting, goToChooseCampaign } from 'actions/onboarding';
import { getCurrentShopSelector } from 'helpers/selectors';
import { requestSendEventMetrics, requestUpdateShop } from 'actions/shop';

import { SharePixelView } from './SharePixelView';

import './SharePixel.css';

class SharePixel extends Component {
    state = {
        isChecked: false,
        isCopied: false,
    };

    componentDidMount() {
        const {
            pixelIsShared,
            params: { shopId },
            redirectToSetUpRetargeting,
            redirectToChooseCampaign,
        } = this.props;

        pixelIsShared && redirectToChooseCampaign(shopId);
    }

    toggleCheckbox = () => {
        const {
            params: { shopId },
            requestSendEventMetrics,
        } = this.props;

        this.setState({ isChecked: !this.state.isChecked });

        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_pixel_confirm',
            },
        };
        requestSendEventMetrics(metricProps);
    };

    continueButtonHandler = () => {
        const {
            params: { shopId },
            requestUpdateShop,
            requestSendEventMetrics,
        } = this.props;

        const params = {
            shop_id: shopId,
            type: 'shopify',
            params_to_update: {
                pixel_shared_checkbox_checked: true,
            },
        };

        requestUpdateShop(params);
        const metricProps = {
            shop_id: shopId,
            type: metricType.btnClicked,
            props: {
                btn_name: 'fb_rt_creation_pixel_continue',
            },
        };
        requestSendEventMetrics(metricProps);
    };

    copyToClipboard = () => {
        const copyText = document.querySelector('#copy-to-clipboard');
        const textArea = document.createElement('textarea');
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        this.setState({ isCopied: true });
        textArea.remove();
    };

    render() {
        const { isChecked, isCopied } = this.state;
        const {
            params: { shopId },
            continueButtonLoading,
            requestSendEventMetrics,
        } = this.props;

        const tooltipContent = isCopied ? 'Partner ID copied' : 'Copy Partner ID';

        return (
            <SharePixelView
                shopId={shopId}
                isChecked={isChecked}
                tooltipContent={tooltipContent}
                toggleCheckbox={this.toggleCheckbox}
                copyToClipboard={this.copyToClipboard}
                continueButtonLoading={continueButtonLoading}
                title="Share your Meta Pixel with Adwisely"
                continueButtonHandler={this.continueButtonHandler}
                requestSendEventMetrics={requestSendEventMetrics}
                text="To run and optimize ads for your store, Adwisely uses your Meta Pixel. Make sure it is properly set
                up and share it with Adwisely - it’s 100% secure and you will still have full control over your Pixel."
            />
        );
    }
}

SharePixel.propTypes = {
    continueButtonLoading: PropTypes.bool.isRequired,
    pixelIsShared: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ shops, featureFlags }) => {
    const currentShop = getCurrentShopSelector(shops, shops.currentShopId);
    return {
        pixelIsShared: currentShop.params.pixel_shared_checkbox_checked,
        continueButtonLoading: shops.shopRequesting,
    };
};

const mapPropsToDispatch = {
    requestUpdateShop,
    redirectToSetUpRetargeting: goToSetUpRetargeting,
    redirectToChooseCampaign: goToChooseCampaign,
    requestSendEventMetrics,
};

export default connect(mapStateToProps, mapPropsToDispatch)(SharePixel);
