export const STARTING_CAMPAIGN_SHOP_ID = 'startingCampaignShopId';
export const STARTING_CAMPAIGN_TYPE = 'startingCampaignType';
export const STARTING_CAMPAIGN_DAILY_BUDGET = 'startingCampaignDailyBudget';
export const STARTING_CAMPAIGN_AD_MESSAGE = 'startingCampaignAdMessage';
export const CAMPAIGN_INFO = 'campaignInfo';
export const FB_PAGE_IS_SAVED = 'fbPageIsSaved';

const campaignCreateStorageHelper = {
    /**
     * Set campaign creation data to localStorage
     * @param campaignType {string}
     * @param dailyBudget {string}
     * @param adMessage {string}
     * @param shopId {string}
     *
     */
    setCampaign: (campaignType, dailyBudget, adMessage, shopId) => {
        if (campaignType) window.localStorage.setItem(STARTING_CAMPAIGN_TYPE, campaignType);
        if (dailyBudget) window.localStorage.setItem(STARTING_CAMPAIGN_DAILY_BUDGET, dailyBudget);
        if (adMessage) window.localStorage.setItem(STARTING_CAMPAIGN_AD_MESSAGE, JSON.stringify(adMessage));
        if (shopId) window.localStorage.setItem(STARTING_CAMPAIGN_SHOP_ID, shopId);
    },
    setCampaignInfoIsSaved: (campaignInfo) =>
        window.localStorage.setItem(CAMPAIGN_INFO, JSON.stringify(campaignInfo)),
    getCampaignInfoIsSaved: () => window.localStorage.getItem(CAMPAIGN_INFO) || '[]',

    setFBPageIsSaved: (fbPageInfo) =>
        window.localStorage.setItem(FB_PAGE_IS_SAVED, JSON.stringify(fbPageInfo)),
    getFBPageIsSaved: () => window.localStorage.getItem(FB_PAGE_IS_SAVED) || '[]',

    getCampaignType: () => window.localStorage.getItem(STARTING_CAMPAIGN_TYPE),
    getCampaignDailyBudget: () => window.localStorage.getItem(STARTING_CAMPAIGN_DAILY_BUDGET),
    getCampaignAdMessage: () => window.localStorage.getItem(STARTING_CAMPAIGN_AD_MESSAGE),
    getCampaignShopId: () => window.localStorage.getItem(STARTING_CAMPAIGN_SHOP_ID),
    clearStorage: () => {
        window.localStorage.removeItem(STARTING_CAMPAIGN_TYPE);
        window.localStorage.removeItem(STARTING_CAMPAIGN_DAILY_BUDGET);
        window.localStorage.removeItem(STARTING_CAMPAIGN_AD_MESSAGE);
        window.localStorage.removeItem(STARTING_CAMPAIGN_SHOP_ID);
    },
};

export default campaignCreateStorageHelper;
