import React, { useState, useEffect } from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';

import AdAccountPolicyError from 'modules/onboarding/userBMFlow/errors/AdAccountError/PolicyError';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';
import { fetchOnboardingState } from 'helpers/fetchOnboardingState';
import {getLocalStorageObjectItem} from '../../../../../helpers';

const AdAccountError = ({ currentShopId, userBusinessManagerList, replace, selectedUbmId }) => {
    const [facebookState, setFacebookState] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    useEffect(() => {
        setIsLoading(!isLoading);
        fetchOnboardingState(currentShopId, currentOnboarding)
            .then((res) => {
                const [_, response] = res;
                response.then(data => {
                    setFacebookState(data?.state);
                })
            });
    }, []);

    const userBusinessManager = userBusinessManagerList.find(ubm => ubm.id === selectedUbmId);

    if (facebookState === 'ad_account_policy_violation_exception') {
        return <AdAccountPolicyError userBM={userBusinessManager} />;
    }

    return (
        <LoaderFullScreen
            isLoaderShowed={isLoading}
        >
            <LoaderLongWait title="Loading..." />
        </LoaderFullScreen>
    )   
};

const mapStateToProps = ({ shops, userBusinessManager, shopsCampaignsSettings }) => ({
    currentShopId: shops?.currentShopId,
    userBusinessManagerList: userBusinessManager?.userBMList,
    selectedUbmId: shopsCampaignsSettings.entity.fb_business_manager_fb_id,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(AdAccountError);
