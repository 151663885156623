import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Button from 'components/Button';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { BookACallSection } from 'modules/onboarding/userBMFlow/components/BookACallSection';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import './CreatePage.css';

const CreatePage = ({ currentShopId, replace }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.create_fb_page,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    const resetPage = () => {
                        if (res?.state === UBM_ONBOARDING_STATES.create_fb_page) {
                            toggleCheckbox();
                            setIsLoading(false);
                        }
                    };
                    facebookStatePolling(currentShopId, replace, resetPage);
                }
            });
    }

    return (
        <div className="create-page__wrapper">
            <h1 className="create-page__title">Create a Facebook page</h1>
            <div className="create-page__body-wrapper">
                <div>
                    <p className="create-page__description">
                        Adwisely runs ads on behalf of your Facebook Page. <br />
                        Create a {''}
                            <a
                                href="https://business.facebook.com/settings/pages"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Facebook Page
                            </a> to continue campaign setup.
                    </p>
                    <p className="create-page__description">
                        <label htmlFor="createFbPage">
                            <input
                                className="checkbox"
                                type="checkbox"
                                onChange={toggleCheckbox}
                                checked={isChecked}
                                id="createFbPage"
                            />
                            I have created a Facebook Page
                        </label>
                    </p>
                </div>
                <BookACallSection />
            </div>
            <Button onClick={handleContinueBtn} color="primary" disabled={!isChecked}>
                Continue
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(CreatePage);
