import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { replace, push } from 'react-router-redux';
import { connect } from 'react-redux';

import { BONUS_FREE_TYPE } from 'constants/billingPeriod';
import {requestShopBillingPlanStatus, requestShopSubscription} from 'actions/shop';
import { SPENDS_PRICE, SUBSCRIPTION } from 'constants/userBillingType';

import BillingPlanStatus from './BillingPlanStatus';
import BillingStatus from './BillingStatus';
import BillingHistory from './BillingHistory';

export class BillingInfo extends Component {
    componentDidMount() {
        const { requestShopBillingPlanStatus, shopId } = this.props;
        requestShopBillingPlanStatus(shopId);
    }

    render() {
        const {
            subscriptionPlan,
            freeBillingType,
            businessModel,
            currentBillingPlan,
            billingInfoYear,
            updateBillingInfo,
            shopId,
            replace,
            push,
            requestShopSubscription,
            shopError
        } = this.props;

        let billingPlanStatus = null;
        let billingHistoryStatus = null;

        if (businessModel === SPENDS_PRICE && currentBillingPlan && Object.keys(currentBillingPlan).length) {
            if (!freeBillingType || (freeBillingType && freeBillingType !== BONUS_FREE_TYPE)) {
                billingPlanStatus = <BillingPlanStatus />;
            }
        }
        if (businessModel === SUBSCRIPTION) {
            billingPlanStatus = <BillingStatus
                shopId={shopId}
                replace={replace}
                push={push}
                subscriptionPlan={subscriptionPlan}
                requestShopSubscription={requestShopSubscription}
                shopError={shopError}
            />;
        }

        billingHistoryStatus = <BillingHistory billingInfoYear={billingInfoYear} updateBillingInfo={updateBillingInfo} />


        return (
            <section>
                {billingPlanStatus}
                {billingHistoryStatus}
            </section>
        );
    }
}

BillingInfo.defaultProps = {
    requestShopBillingPlanStatus: () => {},
    currentBillingPlan: {},
    billingInfoYear: new Date().getFullYear(),
    updateBillingInfo: () => {},
};

BillingInfo.propTypes = {
    shopId: PropTypes.number.isRequired,
    requestShopBillingPlanStatus: PropTypes.func,
    freeBillingType: PropTypes.string.isRequired,
    businessModel: PropTypes.string.isRequired,
    currentBillingPlan: PropTypes.shape({
        fee: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        spends: PropTypes.number,
    }),
    billingInfoYear: PropTypes.number,
    updateBillingInfo: PropTypes.func,
};

const mapStateToProps = (state) => ({
    shopId: +state.shops.currentShopId,
    freeBillingType: state.shopBillingPeriod.freeType,
    businessModel: state.shopBillingInfo.businessModel,
    currentBillingPlan: state.shopBillingPlanStatus.currentBillingPlan,
    subscriptionPlan: state.shopSubscription,
    shopError: state.shopError.shopError,
});

const mapDispatchToProps = {
    requestShopBillingPlanStatus,
    requestShopSubscription,
    replace,
    push
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
