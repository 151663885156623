import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Checkbox.css';

const Checkbox = (props) => {
    const { onChange, isDisabled, initialCheckedValue, checkboxId } = props;

    const [checked, setChecked] = useState(initialCheckedValue);

    const handleCheckboxChange = () => {
        setChecked((checked) => !checked);
        onChange(checked);
    };

    return (
        <input
            className="checkbox"
            type="checkbox"
            disabled={isDisabled}
            aria-disabled={isDisabled}
            onChange={handleCheckboxChange}
            checked={checked}
            id={checkboxId}
        />
    );
};

Checkbox.propTypes = {
    isDisabled: PropTypes.bool,
    initialCheckedValue: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    checkboxId: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
    isDisabled: false,
    initialCheckedValue: false,
};

export default Checkbox;
