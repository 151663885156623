import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Caret } from 'components/Icons';
import './index.css';

const propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

const defaultProps = {
    disabled: false,
};

export const FlagToggler = ({ disabled, children, title, subtitle, findIcon, caseIcon }) => (
    <button
        disabled={disabled}
        className={classNames("flag-toggler",
            { 'find-icon': findIcon },
            { 'case-icon': caseIcon }
        )}
        type="button"
    >
        {!title && children}
        {title && <span className="flag-toggler__title" title={title}>{title}</span>}
        {title && subtitle && <span className="flag-toggler__subtitle" title={subtitle}>{subtitle}</span>}
        <Caret />
    </button>
);

FlagToggler.propTypes = propTypes;
FlagToggler.defaultProps = defaultProps;

export default FlagToggler;
