import React from 'react';
import './YoutubeDesktop.css';

export const YoutubeDesktop = ({product}) => (
    <div className="yt-dp">
        <div className="yt-dp__adv">
            <img src={product.img} alt={product.title} />
            <div className="yt-dp__avatar-wrapper">
                <div className="yt-dp__avatar" />
                <div className="yt-dp__title-wrapper">
                    <div className="yt-dp__title">{product.title}</div>
                    <div className="yt-dp__subtitle">{product.description}</div>
                </div>
            </div>
            <div className="yt-dp__duration">Advertisement (1:23)</div>
        </div>
    </div>
)