import React from 'react';
import { ToDoListModalView } from './ToDoListModalView';

export const ShareAccessToPixelModal = ({isOpen, closeModal, onSupportBtnClick, onCompleteBtnClick}) => (
    <ToDoListModalView
        isOpen={isOpen}
        onCloseBtnClick={closeModal}
        checkboxLabel="I have shared access to the Meta Pixel"
        onSupportBtnClick={onSupportBtnClick}
        onCompleteBtnClick={onCompleteBtnClick}
    >
        <h3 className="todo-list-modal__title">Share access to Meta Pixel</h3>
        <p>To let Adwisely track and improve the results of the ads we run for you, share your Meta Pixel with Adwisely.</p>

        <ol>
            <li>
                In your Business Manager Settings, go to {' '}
                <a href=" https://business.facebook.com/settings/partners"
                   target="_blank"
                   rel="noopener noreferrer">Partners</a>
            </li>
            <li>Click on <b>Adwisely</b></li>
            <li>Under Assets you shared, click <b>Share Assets</b></li>
            <li>In the list on the right, pick <b>Pixels</b> and select the Pixel you want to share</li>
            <li>Under <b>Pixels Partial Access</b>, click on the toggle <br /> next to <b>View Pixels</b></li>
            <li>Click <b>Save Changes</b></li>
            <li>On the screen that appears, select <b>Adwisely acts as an agency for my business</b> and click <b>Next</b></li>
            <li>Review the points on the screen, then click <b>I accept</b></li>
            <li>On the screen that appears, click <b>Done</b></li>
        </ol>
    </ToDoListModalView>
)