import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import intercom from 'libs/intercom';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { getLocalStorageObjectItem } from 'helpers';

import Button from 'components/Button';

import './UpgradeRoleInBM.css';

const UpgradeRoleInBM = ({ currentShopId, replace }) => {
    const [isLoading, setIsLoading] = useState(false);

    const currentOnboarding = getLocalStorageObjectItem('currentOnboarding');

    const handleContinueBtn = () => {
        setIsLoading(!isLoading);
        changeOnboardingState({
            type: ONBOARDING_TYPES[currentOnboarding],
            status: UBM_ONBOARDING_STATES.upgrade_role_in_bm,
            currentShopId,
        })
            .then((res) => {
                if (res.status === 409) {
                    facebookStatePolling(currentShopId, replace)
                }
                return res.json()
            })
            .then(res => {
                if (res?.processing) {
                    facebookStatePolling(currentShopId, replace);
                }
            });
    }

    return (
        <div className="upgrade-role__wrapper">
            <h1 className="upgrade-role__title">Upgrade your role in Business Manager</h1>
            <div className="upgrade-role__body-wrapper">
                <div>
                    <p className="upgrade-role__description">
                        It appears that you do not have Admin rights for Business Manager(s).
                    </p>
                    <p className="upgrade-role__description">
                        To proceed with Adwisely setup, make sure that you are the Admin in at least one Business Manager or {''}
                        <span
                            onClick={intercom.show}
                            className="primary-link in-text"
                        >
                            contact our Support
                        </span> for additional info.
                    </p>
                </div>
            </div>
            <Button onClick={handleContinueBtn} color="primary">
                Already done
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    )
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    replace,
};

export default connect(mapStateToProps, mapPropsToDispatch)(UpgradeRoleInBM);
