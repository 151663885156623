import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import './Collapse.css';

export const Collapse = ({className, isOpen, children}) => {
    const collapseRef = useRef();
    const [height, setHeight] = useState(null);
    const [isShowed, setIsShowed] = useState(false);
    const [isTransitionRunning, setTransitionRunning] = useState(false);
    const parentClassName = classNames(
            className,
            'c-collapse',
            {
                'show': isShowed,
                'collapsing': isTransitionRunning
            }
        );

    useEffect(() => {
        if (!isTransitionRunning) {
            if (isOpen) {
                // Before opening
                setIsShowed(true)
                setHeight({height: '0px'});
            } else {
                // Before closing
                setHeight({height: `${collapseRef.current.scrollHeight}px`})

            }

            setTransitionRunning(true)

            setTimeout(() => {
                setTransitionRunning(false)
            }, 300)
        }


    }, [isOpen])

    useEffect(() => {

        if (isOpen && isTransitionRunning) {
            // Opening
            setHeight({height: `${collapseRef.current.scrollHeight}px`})
        }

        if (isOpen && !isTransitionRunning) {
            // Opened
            setHeight(null)
        }

        if (!isOpen && isTransitionRunning) {
            // Closing
            setHeight({height: `${collapseRef.current.scrollHeight}px`})
            setHeight({height: '0px'})
        }

        if (!isOpen && !isTransitionRunning) {
            // Closed
            setHeight(null);
            setIsShowed(false)
        }
    }, [isTransitionRunning])
    return (
        <div
            className={parentClassName}
            ref={collapseRef}
            style={height}>
            {children}
        </div>
    )
}