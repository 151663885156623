export const ROUTES = {
    dashboard: '/dashboard',
    chooseShop: '/chooseshop',
    facebookLogin: '/facebook/login',
    chooseInstallShop: '/choose-install-shop',
    home: '/home'
};

export const PATH = {
    edit: 'edit',
    action: 'action',
    shopify: 'shopify',
    settings: 'settings',
    upgradePlan: 'upgrade-plan',
    facebook: 'facebook',
    dashboard: 'dashboard',
    errorPage: 'errorpage',
    chooseShop: 'chooseshop',
    woocommerce: 'woocommerce',
    bigcommerce: 'bigcommerce',
    chooseInstallShop: 'choose-install-shop',

    create: {
        retargeting: 'retargeting',
        prospecting: 'prospecting',
        specialOffer: 'special-offer',
        aiBoosted: 'ai-boosted-sales'
    },

    onboarding: {
        firstStep: {
            page: 'page',
            login: 'login',
            unqualified: 'unqualified',
            invitation: 'invitation',
        },
        secondStep: {
            sharePixel: 'share-pixel',
            verifyDomain: 'verify-domain',
            chooseCampaign: 'choose-campaign',
            setUpRetargeting: 'set-up-retargeting',
            setUpProspecting: 'set-up-prospecting',
            choosePlan: 'choose-plan',
            stripeBilling: 'stripe-billing',
            shopifyBilling: 'shopify-billing',
            facebookBilling: 'facebook-billing',
        },
        google: {
            settings: 'settings',
            billing: 'billing',
            createMerchantCenter: 'create-merchant-center',
            chooseAdAccount: 'choose-ad-account',
            shopProtected: 'shop-protected',
            chooseProductFeed: 'choose-product-feed',
            processing: 'processing',
            websiteClaimed: 'website-claimed',
            shopifyBilling: 'shopify-billing',
            choosePlan: 'choose-plan',
            stripeBilling: 'stripe-billing',
            error: 'error',
        },
        userBM: {
            connectBM: 'connect-bm',
            upgradeRole: 'upgrade-role',
            createPage: 'create-page',
            connectPage: 'connect-page',
            connectAdAccount: 'connect-ad-account',
            connectOrCreateAdAccount: 'connect-or-create-ad-account',
            restrictedBm: 'restricted-bm',
            adAccountError: 'ad-account-error',
            error: 'error',
            confirmPixel: 'confirm-pixel',
            pixelNotFound: 'pixel-not-found',
            choosePlan: 'choose-plan',
            shopifyBilling: 'shopify-billing',
            stripeBilling: 'stripe-billing',
            facebookBilling: 'facebook-billing',
            createProspectingCampaign: 'create-prospecting-campaign',
            createVisitorRetargetingCampaign: 'create-visitor-retargeting-campaign',
            createDynamicRetargetingCampaign: 'create-dynamic-retargeting-campaign',
            createSocialRetargetingCampaign: 'create-social-media-retargeting-campaign',
            createAiBoostedSalesCampaign: 'create-ai-boosted-sales-campaign',
            createSpecialOfferCampaign: 'create-special-offer-campaign',
            startFreeTrial: 'start-free-trial',
            createBM: 'create-bm',
            chooseInstagramAccount: 'choose-instagram-account',
            createInstagramAccount: 'create-instagram-account',
            stillHaveNoInstagramAccount: 'still-have-no-instagram-account'
        },
        tikTok: {
            beforeYouBegin: 'before-you-begin',
            choosePlan: 'choose-plan',
            shopifyBilling: 'shopify-billing',
            stripeBilling: 'stripe-billing',
            bookACall: 'book-call',
            callBooked: 'call-booked'
        }
    },
};
