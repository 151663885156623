import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.css';

const propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

const defaultProps = { subtitle: null };

export const DropdownItemMain = ({ title, active, onClick, subtitle, newIcon, disabled }) => (
    <button
        className={classNames('dropdown-item-main', { active },
            { 'dropdown-item-main--disabled': disabled },
            newIcon ? 'dropdown-item-main-new' : null
        )}
        onClick={onClick}
    >
        <span className="dropdown-item-main__title" title={title}>{title}</span>
        {!!subtitle && <span className="dropdown-item-main__subtitle" title={subtitle}>{subtitle}</span>}
    </button>
);

DropdownItemMain.propTypes = propTypes;
DropdownItemMain.defaultProps = defaultProps;

export default DropdownItemMain;
