import React, {useState} from 'react';
import classNames from 'classnames';

import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';

import './ToDoList.css';

import { ToDoListItem } from './components/ToDoListItem';
import { ToDoListFlagToggler } from './components/ToDoListFlagToggler';
import {ToDoItemTooltip, ToDoListStatus, ToDoListTitle} from './ToDoListConstants';

export const ToDoListView = ({listData, title, progress, onStartBtnClick, listName}) => {
    const [isListItemFirstTimeHovered, setIsListItemFirstTimeHovered] = useState(false);
    const firstNotStartedItem = listData.find(item => item.status === ToDoListStatus.not_started);

    return (
        <div className="todo-list">
            <DropdownProvider>
                {({toggle, isOpen}) => {
                    const dropdownItems = listData.map(item => (
                        <ToDoListItem
                            title={ToDoListTitle[item.name]}
                            status={item.status}
                            key={item.id}
                            onStartBtnClick={() => {
                                onStartBtnClick(
                                    {
                                        slug: item.name,
                                        id: item.id,
                                        listName: listName,
                                        status: item.status
                                    }
                                )
                            }}
                            isListItemFirstTimeHovered={isListItemFirstTimeHovered}
                            isFirstNotStartedItem={firstNotStartedItem && firstNotStartedItem.id === item.id}
                            onMouseEnter={() => {setIsListItemFirstTimeHovered(true)}}
                            onMouseLeave={() => {setIsListItemFirstTimeHovered(false)}}
                            tooltipContent={ToDoItemTooltip[item.name]}
                        />
                    ));
                    dropdownItems.push(
                        <div className="todo-list__item todo-list__item--subtext" key='info'>
                            <span>*</span>Once you complete the task, we need up to 24 hours to verify it
                        </div>
                    )

                    return (
                        <Dropdown
                            placement='bottom-end'
                            additionalMenuClassName='todo-list__dropdown-menu'
                            additionalDropdownClassName='todo-list-dropdown'
                            items={dropdownItems}
                            flagToggler={<ToDoListFlagToggler title={title} progress={progress}/>}
                            toggle={toggle}
                            isOpen={isOpen}
                        />
                    )
                }}
            </DropdownProvider>
        </div>
    )
};