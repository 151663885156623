import LoaderFullScreenWithState, {
    LoaderLongWait as _LoaderLongWait,
    Loader as _Loader,
    LoaderFullScreen as _LoaderFullScreen,
} from './Loader';

export const Loader = _Loader;

export const LoaderFullScreen = _LoaderFullScreen;

export const LoaderLongWait = _LoaderLongWait;

export default LoaderFullScreenWithState;
