import React from 'react';
import { connect } from 'react-redux';

import {
    isQualifiedByCriterias,
    SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN,
    SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT,
    SHOP_QUALIFICATION_CRITERIA_ORDERS,
} from 'helpers/shopQualifacation';
import Button from 'components/Button';
import intercom from 'libs/intercom';

const getNotificationContent = (contentItems) => {
    if (contentItems.length) {
        return (
            <ul>
                {contentItems.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    }

    return '';
};

const getQualificationCriteria = (shopQualificationCriteria) => {
    const contentItems = [];
    if (!isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN)) {
        contentItems.push('Have completed free trial on Shopify');
    }
    if (!isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT)) {
        contentItems.push('Are available to all audiences and have at least 1 product to offer');
    }
    if (!isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_ORDERS)) {
        contentItems.push('Had at least 30 orders in the past 30 days');
    }

    return contentItems;
};

const NotQualifiedBanner = ({ shopQualificationCriteria }) => {
    const qualificationCriteria = getQualificationCriteria(shopQualificationCriteria);

    if (!qualificationCriteria.length) {
        return null;
    }

    return (
        <div className="home__banner">
            <div className="notification-icon"  />
            <div>
                <div className="home__banner__title">Adwisely can’t run ads for your store yet</div>
                <div className="home__banner__text">
                    <p>We at Adwisely strive to both save your time and provide the best results possible. That’s why we can only run ads for the stores that:</p>
                    {getNotificationContent(qualificationCriteria)}
                </div>
                <div className="home__banner__text">
                    <p><b>Not there yet?</b> Not a problem - we’ll be looking forward to working with you once you meet the criteria above.</p>
                </div>
                <div className="home__banner__text">
                    <p><b>Already got all this?</b> Send us an email at help@adwisely.com - we’ll see how Adwisely can be useful for your business.</p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ shopQualification }) => ({
    shopQualificationCriteria: shopQualification?.criterias,
});

export default connect(mapStateToProps, null)(NotQualifiedBanner);
