import React from 'react';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { campaignCopyright } from 'constants/campaignCopyright';
import {VISITOR_RETARGETING} from 'constants/campaign';

import CreateRetargetingCampaign from '../CreateRetargetingCampaign';

export const CreateVisitorRetargetingCampaign = () => (
    <CreateRetargetingCampaign
        campaignDescription={campaignCopyright.visitor_retargeting.description}
        campaignTitle={campaignCopyright.visitor_retargeting.title}
        adMessageTipText={campaignCopyright.visitor_retargeting.adMessageTipText}
        onboardingType={ONBOARDING_TYPES.facebook_visitor_retargeting}
        campaignType={VISITOR_RETARGETING}
        onboardingStatus={UBM_ONBOARDING_STATES.create_visitor_retargeting_campaign}
    />
);
