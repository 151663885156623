import React from 'react';
import { connect } from 'react-redux';

import {
    isQualified,
    isQualifiedByCriterias,
    SHOP_QUALIFICATION_CRITERIA_ORDERS,
    SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN,
    SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT,
} from 'helpers/shopQualifacation';
import Button from 'components/Button';
import intercom from 'libs/intercom';

import './GeneralRestrictionBanner.css'

const GeneralRestrictionBanner = ({ shopQualificationCriteria }) => {
    const isShopNotQualified = !isQualified(shopQualificationCriteria) ||
    !isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_PLATFORM_PLAN) ||
    !isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_PRODUCTS_COUNT) ||
    !isQualifiedByCriterias(shopQualificationCriteria, SHOP_QUALIFICATION_CRITERIA_ORDERS);

    return isShopNotQualified && (
        <div className="home__banner general-restrictions__banner">
            <div className="notification-icon" />
            <div>
                <div className="home__banner__title">Your store is under review</div>
                <div className="home__banner__text">
                    <p>
                        To make sure that Adwisely can help your online business grow, we are now checking your store and products.
                        This won’t take us more than 24 hours. Once the checkup is complete, we will send you an email so that you can continue ad creation.
                        If you have questions in the meantime, please contact Support.
                    </p>
                </div>
                <Button
                    onClick={() => {
                        intercom.show();
                    }}
                    className="home__banner__button"
                    color="transparent-bordered"
                >
                    Contact us
                </Button>
            </div>
        </div>
    )
};

const mapStateToProps = ({ shopQualification }) => ({
    shopQualificationCriteria: shopQualification?.criterias,
});

export default connect(mapStateToProps, null)(GeneralRestrictionBanner);
