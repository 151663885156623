import React from 'react';
import classNames from 'classnames';

import './VerificationDropdown.css';

const verificationStates = {
    error: 'error',
    successful: 'successful',
};

export const VerificationDropdown = ({ type, title, openDropdown, renderContent }) => (
    <>
        <div className={classNames(verificationStates[type], 'verification-dropdown__wrapper')}>{title}</div>

        {openDropdown ? <div className="verification-dropdown__content">{renderContent()}</div> : null}
    </>
);
