import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { install, requestShops } from 'actions/shop';
import { requestAccountInfo } from 'actions/account';
import { setInframePlatformType, setInframeTrue } from 'actions/inframe';

import inIframe from 'libs/inIframe';

import { BIG_COMMERCE } from 'constants/platform';

import ErrorPage from 'components/ErrorPage';
import { requestFeatureFlags } from 'actions/featureFlags';

const LOAD = 'load';
const INSTALL_CALLBACK = 'install-callback';

export class RootMiddleware extends Component {
    constructor(props) {
        super(props);
        if (inIframe()) {
            this.props.setInframeTrue();
        }
        this.installBigcommerceShop = this.installBigcommerceShop.bind(this);
    }

    componentDidMount() {
        const { account, shops, requestShops, requestAccountInfo, location } =
            this.props;

        const paramsArray = location.pathname.split('/');
        if (paramsArray.includes(BIG_COMMERCE)) {
            if (paramsArray.includes(INSTALL_CALLBACK)) this.installBigcommerceShop(INSTALL_CALLBACK);
            if (paramsArray.includes(LOAD)) this.installBigcommerceShop(LOAD);
        }

        if (
            !account.entity &&
            account.requesting === false &&
            account.requestingSuccess === false &&
            account.requestingFailure === false
        ) {
            requestAccountInfo();
        }

        if (
            !shops.entities.length &&
            shops.shopRequesting === false &&
            shops.shopRequestSuccess === false &&
            shops.shopRequestFailure === false
        ) {
            requestShops();
        }
    }

    componentDidUpdate(prevState) {
        const { account, params, requestFeatureFlags } = this.props;

        if (prevState.params?.shopId !== params?.shopId ||
            prevState.account?.entity !== account?.entity) {
            if (account.entity && params?.shopId) {
                requestFeatureFlags(params.shopId);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { shops, account } = this.props;

        if (account.entity !== nextProps.account.entity || shops !== nextProps.shop) {
            this.forceUpdate();
        }
    }

    installBigcommerceShop(installType) {
        const { location, installBigcommerce, replace } = this.props;
        let installParams = location.query;
        if (installType === INSTALL_CALLBACK) installParams = { params: location.query };
        installBigcommerce({
            ...installParams,
            type: BIG_COMMERCE,
            intercom_visitor_id: '',
        }).then((shop) => {
            if (shop instanceof Error) {
                return;
            }
            setInframePlatformType(BIG_COMMERCE);
            if (shop.shop) replace(`/shop/${shop.shop.id}/`);
        });
    }

    render() {
        const { account, shops } = this.props;

        if ((account.requestingSuccess || account.requestingFailure) && shops.shopRequestSuccess) {
            return this.props.children;
        } else if (shops.shopRequestFailure) {
            return <ErrorPage />;
        }
        return null;
    }
}

const mapStateToProps = ({ shops, google, account }) => ({
    shops,
    google,
    account,
});

const mapPropsToDispatch = {
    replace,
    requestShops,
    setInframeTrue,
    requestAccountInfo,
    setInframePlatformType,
    requestFeatureFlags,
    installBigcommerce: install,
};

export default connect(mapStateToProps, mapPropsToDispatch)(RootMiddleware);
