import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import './DomainVerificationBanner.css';

const DomainVerificationBanner = ({ onClickHandler }) => (
    <section className="domain_verification_banner">
        <div className="domain_verification_banner-close-btn" onClick={onClickHandler} />
        <header className="domain_verification_banner-header">
            <h2 className="domain_verification_banner-title">Verify your domain to continue running ads</h2>
        </header>
        <span className="domain_verification_banner-body">
            After iOS 14.5 release, Adwisely will run ads only for verified domains. <br />
            Follow{' '}
            <a
                href="https://help.adwisely.com/en/articles/4957812-how-do-i-prepare-for-ios-14-update"
                target="_blank"
                rel="noopener noreferrer"
            >
                this guide
            </a>{' '}
            to verify your domain ASAP.
        </span>
        <div className="domain_verification_banner-cta">
            <Button url="https://business.facebook.com/settings/owned-domains/" color="yellow">
                Go to Domains
            </Button>
        </div>
    </section>
);

DomainVerificationBanner.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
};

export default DomainVerificationBanner;
