import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import {
    AI_BOOSTED_SALES,
    CAMPAIGNS_TYPES,
    DYNAMIC_RETARGETING,
    PROSPECTING, SOCIAL_RETARGETING,
    SPECIAL_OFFER,
    VISITOR_RETARGETING
} from 'constants/campaign';
import ProspectingCampaignEdit from '../ProspectingCampaignEdit/ProspectingCampaignEdit';
import RetargetingCampaignEdit from '../RetargetingCampaignEdit/RetargetingCampaignEdit';
import SpecialOfferCampaignEdit from '../SpecialOfferCampaignEdit/SpecialOfferCampaignEdit';
import RemarketingAdsEdit from '../RemarketingAdsEdit/RemarketingAdsEdit';
import StandardAdsEdit from '../StandardAdsEdit/StandardAdsEdit';
import {
    requestShopCampaigns,
    requestGoogleCampaign,
    requestGoogleRemarketingAdPreview,
    requestGoogleStandardAdPreview,
} from '../../../actions/campaign';
import { getLocalStorageObjectItem } from '../../../helpers';

import { LoaderLongWait as Loader } from '../../../components/Loader';
import {campaignCopyright} from '../../../constants/campaignCopyright';

class EditCampaignMiddleware extends Component {
    state = {
        campaignType: null,
        googleCampaignType: null,
        activeTab: getLocalStorageObjectItem('activeTab') === '1' ? CAMPAIGNS_TYPES.google : CAMPAIGNS_TYPES.facebook,
    };

    componentDidMount() {
        const {
            requestCampaignsData,
            requestGoogleCampaignData,
            requestGoogleRemarketingAdPreview,
            requestGoogleStandardAdPreview,
            params: { shopId, id },
            campaignType,
        } = this.props;

        if (this.state.activeTab === 'facebook') {
            requestCampaignsData(shopId).then(() => {
                this.setState({ campaignType });
            });
        } else {
            requestGoogleCampaignData(shopId, id).then((res) => {
                this.setState({ googleCampaignType: res.type });

                const adId = res?.ads.find((ad) => ad.type === res.default_ad_type).id;

                if (res.type.includes('Smart')) {
                    requestGoogleRemarketingAdPreview({
                        shopId,
                        campaignId: id,
                        adId,
                    });
                }

                if (res.type.includes('Standard')) {
                    requestGoogleStandardAdPreview({
                        shopId,
                        campaignId: id,
                        adId,
                    });
                }
            });
        }
    }

    render() {
        const { replace, params, loading } = this.props;

        const { campaignType, googleCampaignType } = this.state;

        if (loading) {
            return (
                <div style={{ alignSelf: 'center', width: '100%' }}>
                    <Loader />
                </div>
            );
        }

        if (campaignType !== null) {
            switch (campaignType) {
                case VISITOR_RETARGETING:
                    return <RetargetingCampaignEdit
                        params={params}
                        campaignTitle={campaignCopyright.visitor_retargeting.title}
                        campaignDescription={campaignCopyright.visitor_retargeting.description}
                        campaignType={VISITOR_RETARGETING}
                    />;
                case DYNAMIC_RETARGETING:
                    return <RetargetingCampaignEdit
                        params={params}
                        campaignTitle={campaignCopyright.dynamic_retargeting.title}
                        campaignDescription={campaignCopyright.dynamic_retargeting.description}
                        campaignType={DYNAMIC_RETARGETING}
                    />;
                case SOCIAL_RETARGETING:
                    return <RetargetingCampaignEdit
                        params={params}
                        campaignTitle={campaignCopyright.social_retargeting.title}
                        campaignDescription={campaignCopyright.social_retargeting.description}
                        campaignType={SOCIAL_RETARGETING}
                    />;
                case AI_BOOSTED_SALES:
                    return <RetargetingCampaignEdit
                        params={params}
                        campaignTitle={campaignCopyright.ai_boosted_sales.title}
                        campaignDescription={campaignCopyright.ai_boosted_sales.description}
                        campaignType={AI_BOOSTED_SALES}
                    />;
                case PROSPECTING:
                    return <ProspectingCampaignEdit params={params} />;
                case SPECIAL_OFFER:
                    return <SpecialOfferCampaignEdit params={params} />;
                default:
                    replace(`/${params.shopId}/dashboard/`);

            }
        }

        if (googleCampaignType !== null) {
            if (googleCampaignType.includes('Smart')) return <RemarketingAdsEdit params={params} />;
            if (googleCampaignType.includes('Standard')) return <StandardAdsEdit params={params} />;
            replace(`/${params.shopId}/dashboard/`);
        }

        return null;
    }
}

const mapStateToProps = ({ campaignDetails, googleCampaign }, { params }) => {
    const currentCampaign = campaignDetails.campaignDetailsData.find((el) => +params.id === el.id);
    return {
        loading: campaignDetails.campaignDetailsPending || googleCampaign.googleCampaignPending,
        campaignType: currentCampaign && currentCampaign.type,
        params,
    };
};

const mapDispatchToProps = {
    requestCampaignsData: requestShopCampaigns,
    requestGoogleCampaignData: requestGoogleCampaign,
    replace,
    requestGoogleRemarketingAdPreview,
    requestGoogleStandardAdPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignMiddleware);
