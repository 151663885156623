import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestShopNotifications, requestShopNotificationsDeleteReviewBanner } from 'actions/notification';
import { trackEventInformBillingUpdate } from 'actions/intercom';

import Button from 'components/Button';
import NotificationWidget from 'components/NotificationWidget';
import IntercomChatCaller from 'components/IntercomChatCaller';

import {
    SUBSCRIPTION_BILLING_MIGRATION,
} from 'constants/notificationType';

class GeneralBannerNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            isClosed: true,
        };
        this.handleNotification = this.handleNotification.bind(this);
        this.changeNotificationContent = this.changeNotificationContent.bind(this);
        this.closeNotificationBanner = this.closeNotificationBanner.bind(this);
    }

    componentDidMount() {
        const { notificationReason } = this.props;
        if (notificationReason) {
            this.handleNotification();
        }
    }

    changeNotificationContent(notificationContent, isClosed) {
        this.setState({
            notificationData: notificationContent,
            isClosed,
        });
    }

    closeNotificationBanner(isIntercomEvent) {
        const {
            shopId,
            getShopNotifications,
            closeBanner,
            trackEventInformBillingUpdate,
            notificationReason,
        } = this.props;
        this.setState({
            isClosed: true,
        });
        if (isIntercomEvent) {
            trackEventInformBillingUpdate();
        }
        closeBanner({
            id: shopId,
            type: notificationReason,
        }).then(() => {
            getShopNotifications({ id: shopId });
        });
    }

    handleNotification() {
        const { notificationReason, currentBillingPeriodDaysLeft } = this.props;
        let notificationTitle = '';
        let notificationDescription = [];
        let notificationData = [];
        let isClosed = true;
        switch (notificationReason) {
            case SUBSCRIPTION_BILLING_MIGRATION:
                isClosed = false;
                notificationTitle = <p className="notification__title"> Our billing has changed since you last logged in</p>;
                notificationDescription = (
                    <div>
                        <p className="notification__description">
                            We have recently optimised the way we charge for Adwisely services.
                            If you’d like to continue advertising with us, please,
                            <a
                                href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-adwisely"
                                target="_blank"
                                rel="noopener noreferrer"
                            > learn about new billing rules </a>
                            and contact Support to resume your ads.
                        </p>
                        <Button
                            color="primary"
                            url='https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-adwisely'
                            className="mr-3"
                        >
                            Learn about new billing
                        </Button>
                        <IntercomChatCaller content="Contact Support" />
                    </div>
                );
                notificationData = [notificationTitle, notificationDescription];

                this.changeNotificationContent(notificationData, isClosed);
                break;
            default: {
                isClosed = true;
                this.changeNotificationContent([], isClosed);
            }
        }
    }

    render() {
        const { type } = this.props;
        let notificationWidget = null;
        if (this.state.notificationData.length !== 0) {
            notificationWidget = (
                <NotificationWidget type={type}>
                    {this.state.notificationData.map((notificationData, key) => (
                        <span key={key}>{notificationData}</span>
                    ))}
                </NotificationWidget>
            );
        }
        return <div>{!this.state.isClosed ? notificationWidget : null}</div>;
    }
}

GeneralBannerNotification.propTypes = {
    closeBanner: PropTypes.func,
    trackEventInformBillingUpdate: PropTypes.func,
    getShopNotifications: PropTypes.func,
    notificationReason: PropTypes.string,
    currentBillingPeriodDaysLeft: PropTypes.number,
};

GeneralBannerNotification.defaultProps = {
    closeBanner: () => {},
    trackEventInformBillingUpdate: () => {},
    getShopNotifications: () => {},
    notificationReason: '',
    currentBillingPeriodDaysLeft: 0,
};

const mapStateToProps = (state) => ({
    currentBillingPeriodDaysLeft: state.shopBillingPeriod.currentBillingPeriodDaysLeft,
});

const mapDispatchToProps = {
    closeBanner: requestShopNotificationsDeleteReviewBanner,
    trackEventInformBillingUpdate,
    getShopNotifications: requestShopNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralBannerNotification);
