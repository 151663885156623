import { ONBOARDING_STATES } from 'constants/googleOnboarding';

export const googleFlow = {
    get: () => localStorage.getItem('google_flow'),
    start: () => localStorage.setItem('google_flow', ONBOARDING_STATES.started),
    end: () => localStorage.setItem('google_flow', ONBOARDING_STATES.not_started),
    done: () => localStorage.setItem('google_flow', ONBOARDING_STATES.done),
    set: (flow) => localStorage.setItem('google_flow', flow),
    reset: () => localStorage.setItem('google_flow', 'none'),
};
