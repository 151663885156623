import { replace } from 'react-router-redux';
import withData from 'decorator/withStateData';
import { getCurrentBMUserSelector } from 'helpers/selectors';
import { GRANTED } from 'constants/fbInvitaionStatuses';
import { googleFlow } from 'modules/home/utils';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';

import Dashboard from './Dashboard';

export default withData(
    Dashboard,
    ['campaignDetails', 'businessManager', 'account', 'shopsCampaignsSettings'],
    (data) => {
        const campaignIsCreated = !!(
            data.campaignDetails.campaignDetailsData && data.campaignDetails.campaignDetailsData.length
        );
        const currentBMUserStatusIsGranted =
            getCurrentBMUserSelector(data.businessManager.data, data.account)?.status === GRANTED;
        const hasOldBM = data.shopsCampaignsSettings?.entity?.fb_business_id;

        if (googleFlow.get() === ONBOARDING_STATES.done || googleFlow.get() === 'none') {
            return true;
        }
        return campaignIsCreated && (currentBMUserStatusIsGranted || hasOldBM);
    },
    (nextState) => (dispatch) => {
        const {
            params: { shopId },
        } = nextState;

        if (googleFlow.get() === ONBOARDING_STATES.done || googleFlow.get() === 'none') {
            return false;
        }
        dispatch(replace(`/${shopId}/home`));
    },
);
