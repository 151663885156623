import React, {useState} from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { setGoogleOnboardingState } from 'actions/googleOnboardingState';

import { AdAccountDropdown } from 'modules/onboarding/components/AdAccountDropdown';
import Button from 'components/Button';
import DropdownProvider from 'components/DropdownProvider';
import Dropdown from 'components/Dropdown';
import { FlagToggler } from 'components/DropdownToggler';
import { DropdownItemMain } from 'components/DropdownItem';

import './ChooseOrCreateProductFeed.css';

import {useProductFeed} from './hooks/useProductFeed';

const ChooseOrCreateProductFeed = ({shopId, redirect, setGoogleOnboardingState}) => {
    const [isChooseOpen, setIsChooseOpen] = useState(true);
    const [isCreateOpen, setIsCreateOpen] = useState(false);

    const {
        productFeed,
        onProductItemClick,
        choosedProductFeedItem,
        onCreateProductFeedBtnClick,
        onContinueBtnClick,
        onCreateDropdownClick
    } = useProductFeed(shopId, redirect, setGoogleOnboardingState)

    const handleDropdowns = () => {
        setIsChooseOpen(!isChooseOpen);
        setIsCreateOpen(!isCreateOpen);
    };

    return (
        <div className="product-feed">
            <div className="product-feed__title">Please choose or create product feed</div>
            <div className="product-feed__body">
                <div className="product-feed__item">
                    <AdAccountDropdown
                        onClick={handleDropdowns}
                        title="Choose product feed"
                        openDropdown={isChooseOpen}
                        type={!isChooseOpen ? 'default' : 'selected'}
                        renderContent={() => (
                            <div className="product-feed__choose-block">
                                <div>Select product feed from the following list to continue with onboarding.</div>
                                {
                                    <DropdownProvider>
                                        {({ toggle, isOpen }) => {
                                            const flagToggler = <FlagToggler
                                                title={choosedProductFeedItem ? `${choosedProductFeedItem.name} (${choosedProductFeedItem.products_count} items)` : null}
                                                caseIcon
                                            >
                                                Select Product Feed
                                            </FlagToggler>

                                            const dropdownItems = productFeed?.map((feedItem, key) => (
                                                <DropdownItemMain
                                                    key={key}
                                                    value={feedItem.id}
                                                    onClick={() => {
                                                        onProductItemClick(feedItem.id);
                                                        toggle();
                                                    }}
                                                    active={choosedProductFeedItem && feedItem.id === choosedProductFeedItem.id}
                                                    title={`${feedItem.name} (${feedItem.products_count} items)`}
                                                />
                                            ));


                                            return <Dropdown
                                                isOpen={isOpen}
                                                items={dropdownItems}
                                                toggle={toggle}
                                                flagToggler={flagToggler}
                                                additionalDropdownClassName='product-feed-dropdown'
                                            />
                                        }}

                                    </DropdownProvider>
                                }
                            </div>
                        )}
                    />
                </div>
                <div className="product-feed__item">
                    <AdAccountDropdown
                        onClick={() => {
                            handleDropdowns();
                            onCreateDropdownClick();
                        }}
                        title="Create new product feed"
                        openDropdown={isCreateOpen}
                        type={!isCreateOpen ? 'default' : 'selected'}
                        renderContent={() => (
                            <div className="product-feed__create-block">
                                <div>Press button to create a new product feed</div>
                                <Button color="primary" onClick={onCreateProductFeedBtnClick}>Create</Button>
                            </div>
                        )}
                    />
                </div>
                <div className="product-feed__item product-feed__item--end">
                    <Button color="primary" disabled={!choosedProductFeedItem} onClick={onContinueBtnClick}>Continue</Button>
                </div>
            </div>
        </div>
)}

const mapStateToProps = ({ google, shops }) => ({
    googleOnboardingState: google.googleOnboardingState,
    shopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    redirect: replace,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export const ChooseOrCreateProductFeedPage = connect(mapStateToProps, mapPropsToDispatch)(ChooseOrCreateProductFeed);
