import { useEffect } from 'react';

import { googleFlow } from 'modules/home/utils';
import { ONBOARDING_PAGES, ONBOARDING_STATES } from 'constants/googleOnboarding';

export const useOnboardingState = ({ redirect, onboardingState, currentShopId }) => {
    useEffect(() => {
        onboardingState?.map(({ state, processing }) => {
            if (processing && googleFlow.get() !== 'none') {
                redirect(`/${currentShopId}/${ONBOARDING_PAGES.processing}`);
                return null;
            }

            if (state === ONBOARDING_STATES.done) {
                googleFlow.done();
                // redirect(`/${currentShopId}/dashboard`);
                return null;
            }

            return null;
        });
    }, [onboardingState.length]);
};
