import { useEffect } from 'react';

import { ONBOARDING_STEPS } from 'constants/googleOnboarding';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { googleFlow } from 'modules/home/utils';
import { facebookStatePolling } from '../../onboarding/userBMFlow/helpers/ubmStatePolling';

export const useHandleNotAuthOnboading = ({
                                              onboardings,
                                              isFacebookAuthorized,
                                              currentShopId,
                                              changeOnboardingState,
                                              setIsLoading,
                                              redirect
    }) => {
    useEffect(() => {
        const googleOnboarding = onboardings.find(onboarding => onboarding.name === 'google');
        const facebookOnboardings  =
            onboardings.filter(onboarding => (
                onboarding.name === ONBOARDING_TYPES.facebook_prospecting ||
                onboarding.name === ONBOARDING_TYPES.facebook_special_offer ||
                onboarding.name === ONBOARDING_TYPES.facebook_visitor_retargeting ||
                onboarding.name === ONBOARDING_TYPES.facebook_dynamic_retargeting ||
                onboarding.name === ONBOARDING_TYPES.facebook_ai_boosted_sales
            ));
        const facebookNotAuthOnboarding = facebookOnboardings.find(onboarding => onboarding.state === UBM_ONBOARDING_STATES.login);

        if (facebookNotAuthOnboarding && isFacebookAuthorized) {
            setIsLoading(true);
            googleFlow.reset();
            changeOnboardingState({
                currentShopId,
                status: UBM_ONBOARDING_STATES.login,
                type: facebookNotAuthOnboarding.name,
            })
                .then(res => res.json())
                .then(res => {
                    if (res?.processing) {
                        facebookStatePolling(currentShopId, redirect);
                    }
                });
        }

        if (googleOnboarding && googleOnboarding.state === ONBOARDING_STEPS.oauth) {
            changeOnboardingState({
                currentShopId,
                status: ONBOARDING_STEPS.oauth,
                type: ONBOARDING_TYPES.google,
            });
        }
    }, [onboardings])
}