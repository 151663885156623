import * as onboarding from '../../actions/onboarding';

const initialState = {
    chosenFBPageId: '',
    fbPageConnectRequesting: false,
    fbPageConnectRequestSuccess: false,
    fbPageConnectRequestError: false,

    fbPageConnectStatus: '', // TODO: rename this to '' because there is not only connect status,
    // but also other info, for instance like this:
    // {
    //     backed_instagram_accounts_id: "1785066744914761",
    //     created_at: "2018-05-04T11:01:37.269Z",
    //     fb_id: "405064166628477",
    //     id: 4,
    //     permitted_role: "ADVERTISER",
    //     review_note: "",
    //     review_status: "",
    //     updated_at: "2018-07-13T08:35:52.560Z",
    // };
};

export default (state = initialState, action) => {
    switch (action.type) {
        case onboarding.ONBOARDING_FB_PAGE_CHOSEN:
            state = {
                ...state,
                chosenFBPageId: action.payload,
            };
            break;

        case onboarding.ONBOARDING_FB_PAGE_CONNECT_REQUEST:
            state = {
                ...state,
                fbPageConnectRequesting: true,
                fbPageConnectRequestSuccess: false,
                fbPageConnectRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_PAGE_CONNECT_REQUEST_SUCCESS:
            state = {
                ...state,
                fbPageConnectStatus: action.fbPageConnectStatus,
                fbPageConnectRequesting: false,
                fbPageConnectRequestSuccess: true,
                fbPageConnectRequestError: false,
            };
            break;

        case onboarding.ONBOARDING_FB_PAGE_CONNECT_REQUEST_FAILURE:
            state = {
                ...state,
                fbPageConnectRequesting: false,
                fbPageConnectRequestSuccess: false,
                fbPageConnectRequestError: true,
            };
            break;

        default:
            break;
    }

    return state;
};
