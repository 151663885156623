import React from "react";

import Button from "components/Button";
import './BeforeYouBeginView.css';

export const BeforeYouBeginView = ({ onAgreeButtonClick, onBackButtonClick }) => (
        <div className='before-begin'>
            <h1 className='before-begin__title'>Before you begin</h1>
            <div className='before-begin__notification notification-before-begin'>
                Note that the platform you selected is still in beta testing stage.
            </div>
            <p className='before-begin__text'>You will need to set up a call with Support to complete the ad setup.
                It will definitely be worth the waiting time, though <span role='img' aria-label='smile'>🙂</span></p>
            <p className='before-begin__text'>To continue with ad setup, click “I agree”</p>
            <p className='before-begin__buttons'>
                <Button
                    color='primary'
                    className='before-begin__primary-btn'
                    onClick={onAgreeButtonClick}
                >I agree</Button>
                <Button
                    color='transparent'
                    className='before-begin__transparent-btn'
                    onClick={onBackButtonClick}
                >Go back and select another platform</Button>
            </p>
        </div>
    );
