import { numberDigitSeparateWithSpace } from 'helpers';

export const CAMPAIGN_CLIENT_STATUS = {
    PENDING: 'in_review',
    ACTIVE: 'active',
    PAUSED: 'paused',
    DECLINED: 'declined',
    CREATING: 'creating',
    REJECTED: 'rejected',
};

export const GOOGLE_CAMPAIGN_STATUS = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    UNSPECIFIED: 'UNSPECIFIED',
    UNKNOWN: 'UNKNOWN',
    REMOVED: 'REMOVED',
};

export const getDailyBudget = (target) => {
    if (target.settings) {
        return `${numberDigitSeparateWithSpace(target.settings.daily_budget / 100)} ${target.currency}`;
    }
    return '-';
};

export const getGlDailyBudget = (target) => {
    if (target) {
        return `${numberDigitSeparateWithSpace(target.daily_budget / 100)} USD`;
    }
    return '-';
};

export const switchClassNameFromCampaignStatus = (
    clientStatus,
    inLearningPhase,
    defaultName = '',
    pendingName = '',
    activeName = '',
    pausedName = '',
    declinedName = '',
    creatingName = '',
    rejectedName = '',
    removedName = '',
    inLearningPhaseName = ''
) => {
    let res = defaultName;

    if (inLearningPhase) {
        res = `${defaultName} ${inLearningPhaseName}`;
        return res;
    }

    switch (clientStatus) {
        case CAMPAIGN_CLIENT_STATUS.PENDING:
        case GOOGLE_CAMPAIGN_STATUS.UNSPECIFIED:
        case GOOGLE_CAMPAIGN_STATUS.UNKNOWN:
            res = `${defaultName} ${pendingName}`;
            break;

        case CAMPAIGN_CLIENT_STATUS.ACTIVE:
        case GOOGLE_CAMPAIGN_STATUS.ACTIVE:
            res = `${defaultName} ${activeName}`;
            break;

        case CAMPAIGN_CLIENT_STATUS.PAUSED:
        case GOOGLE_CAMPAIGN_STATUS.PAUSED:
            res = `${defaultName} ${pausedName}`;
            break;

        case CAMPAIGN_CLIENT_STATUS.DECLINED:
            res = `${defaultName} ${declinedName}`;
            break;

        case CAMPAIGN_CLIENT_STATUS.CREATING:
            res = `${defaultName} ${creatingName}`;
            break;

        case CAMPAIGN_CLIENT_STATUS.REJECTED:
            res = `${defaultName} ${rejectedName}`;
            break;

        case GOOGLE_CAMPAIGN_STATUS.REMOVED:
            res = `${defaultName} ${removedName}`;
            break;

        default:
            break;
    }

    return res;
};

export default CAMPAIGN_CLIENT_STATUS;
