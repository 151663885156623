import { SHOP_INFO_REQUEST, SHOP_INFO_SUCCESS, SHOP_INFO_FAILURE } from 'actions/shop';

const initialState = {
    data: null,
    pending: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOP_INFO_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case SHOP_INFO_SUCCESS:
            return {
                ...state,
                pending: false,
                data: payload,
            };
        case SHOP_INFO_FAILURE:
            return {
                ...state,
                pending: false,
            };
        default:
            return state;
    }
};
