import { SET_GOOGLE_ONBOARDING_STATE } from 'actions/googleOnboardingState';

const initialState = {
    googleOnboardingState: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_GOOGLE_ONBOARDING_STATE:
            state = action.payload;
            break;
        default:
    }
    return state;
};
