import React from 'react';

import { Status } from 'modules/home/components/Status';

export const Card = ({ type, title, text, renderButton, isBeta, isActive, onClick, icons, additionalClassName }) => (
    <div className={`home__board__block ${isActive ? 'home__board__block--active' : ''} ${additionalClassName}`} onClick={onClick}>
        <div className='home__board__block__icons'>
            {icons && icons.length && icons.map((icon, i) => (
                <div className='home__board__block__icon' key={i}>
                    <img src={icon} alt="card icon"/>
                </div>
            ))}
        </div>
        <div className="home__board__block__header">
            <div className="home__board__block__content__title">
                {title}
                {isBeta && <div className={"home__board__block__beta"}>beta</div>}
            </div>
            <Status type={type} />
        </div>
        <div className="home__board__block__inner">
            <div className="home__board__block__content">
                <div className="home__board__block__content__text">{text}</div>
            </div>
            {renderButton()}
        </div>
    </div>
);
