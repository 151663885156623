import * as campaignActions from 'actions/campaign';
import { HIDE_CAMPAIGN_PROMO } from 'actions/campaignsPromo';

const initialState = {
    campaignsPromoData: [],
    campaignsPromoDataLoading: false,
    campaignsPromoDataSuccess: false,
    campaignsPromoDataFailure: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case campaignActions.CAMPIGNS_PROMO_REQUEST:
            return {
                ...state,
                campaignsPromoDataLoading: true,
            };

        case campaignActions.CAMPIGNS_PROMO_SUCCESS:
            return {
                ...state,
                campaignsPromoDataSuccess: true,
                campaignsPromoDataLoading: false,
                campaignsPromoData: action.payload,
            };

        case campaignActions.CAMPIGNS_PROMO_FAILURE:
            return {
                ...state,
                campaignsPromoDataFailure: true,
                campaignsPromoDataLoading: false,
            };

        case HIDE_CAMPAIGN_PROMO:
            const newCampaignsPromoData = state.campaignsPromoData.filter(
                (promo) => promo.campaign !== action.campaignId,
            );
            return {
                ...state,
                campaignsPromoData: newCampaignsPromoData,
            };

        default:
            return state;
    }
};
