import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import DropdownButton from '../../../../DropdownButton';
import { getDropdownCampaigns } from '../../statistics.utils';

const StatisticsFilters = ({
    shopId,
    isPending,
    campaignType,
    dateRangeList,
    activeCampaign,
    googleCampaigns,
    dateRangeActive,
    onDateRangeChange,
    campaignDetailsData,
    campaignChangeHandler,
    requestSendEventMetrics,
}) => {
    const [campaignId, setCampaignId] = useState(null);
    const dropdownItemsList = getDropdownCampaigns({ campaignType, googleCampaigns, campaignDetailsData });

    return (
        <div className="statistics-section__dropdowns-wrapper">
            <div className="statistics-section__dropdown-wrapper statistics-section__item statistics-section__item_selector">
                <span>Campaign</span>
                <DropdownButton
                    shopId={shopId}
                    isCampaignFilter
                    disabled={isPending}
                    setCampaignId={setCampaignId}
                    dropdownItemsList={dropdownItemsList}
                    activeDropdownItemName={activeCampaign}
                    onCampaignChange={campaignChangeHandler}
                    requestSendEventMetrics={requestSendEventMetrics}
                />
            </div>

            <div className="statistics-section__dropdown-wrapper statistics-section__item statistics-section__item_selector">
                <span>Date</span>
                <DropdownButton
                    shopId={shopId}
                    disabled={isPending}
                    campaignId={campaignId}
                    dropdownItemsList={dateRangeList}
                    onDateRangeChange={onDateRangeChange}
                    activeDropdownItemName={dateRangeActive}
                    requestSendEventMetrics={requestSendEventMetrics}
                />
            </div>
        </div>
    );
};

export default StatisticsFilters;

StatisticsFilters.propTypes = {
    dateRangeList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    dateRangeActive: PropTypes.string.isRequired,
    isPending: PropTypes.bool,
    onDateRangeChange: PropTypes.func.isRequired,
};

StatisticsFilters.defaultProps = {
    dataMainPurchaseTipsList: [],
    isPending: false,
};
