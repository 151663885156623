import { createSelector } from 'reselect';
import { FEE_RATES } from 'modules/settings/BillingInfo/constants';
import { convertDatePeriodToString } from 'modules/settings/BillingInfo/helpers';
import { TRIAL_FREE_TYPE } from 'constants/billingPeriod';

export const shopDomainSelector = (shopsList, shopId) => {
    let shopDomain = '';
    if (shopsList && shopId) {
        shopsList.forEach((shop) => {
            if (shop.id === shopId) shopDomain = shop.domain;
        });
    }
    return shopDomain;
};

export const isTrialPeriodHandler = (freeType, trialDaysLeft) => freeType === TRIAL_FREE_TYPE && trialDaysLeft > 0;

export const isTrialPeriodSelector = createSelector([isTrialPeriodHandler], (isTrialPeriod) => isTrialPeriod);

export const isStaffPlatformPlanHandler = (currentShop) => {
    const platformPlanList = ['staff_business', 'staff'];
    return currentShop && platformPlanList.includes(currentShop.platform_plan);
};

export const isStaffPlatformPlanSelector = createSelector(
    [isStaffPlatformPlanHandler],
    (isStaffPlatformPlan) => isStaffPlatformPlan,
);

export const getCurrentShopHandler = (shops, shopId) => {
    const [currentShop] =
        shops && Array.isArray(shops.entities) ? shops.entities.filter((shop) => +shop.id === +shopId) : [];
    return currentShop;
};

export const getCurrentShopSelector = createSelector([getCurrentShopHandler], (currentShop) => currentShop);

export const currentBillingPeriodInfoSelector = (infoData) => {
    if (!infoData || infoData.length === 0) return null;
    const currentBillingPeriod = infoData
        .filter((item) => {
            const today = new Date().getTime();
            const billingEndedDay = new Date(item.ended_at).getTime();
            return today < billingEndedDay;
        })
        .map((item) => ({
            datePeriod: convertDatePeriodToString(item.started_at, item.ended_at),
            campaign_type: item.campaign_type,
            fee_rate: item.fee_rate,
        }));
    if (currentBillingPeriod.length === 0) return null;
    return currentBillingPeriod;
};

export const billingInfoDataSelector = (billingInfoData, currentBillingPeriodInfo = []) => {
    if (!billingInfoData) return null;
    const currentBillingPeriodDate =
        currentBillingPeriodInfo && currentBillingPeriodInfo[0] && currentBillingPeriodInfo[0].datePeriod
            ? currentBillingPeriodInfo[0].datePeriod
            : null;
    return billingInfoData.filter((dataItem) => {
        const currentDateFeeRate = FEE_RATES[dataItem.fee_rate];
        const currentDate = convertDatePeriodToString(dataItem.started_at, dataItem.ended_at);
        const feeRateIsTrial =
            currentDateFeeRate === FEE_RATES.trial || currentDateFeeRate === FEE_RATES['prospecting-trial'];
        return !(feeRateIsTrial || currentBillingPeriodDate === currentDate);
    });
};

export const campaignErrorHandler = (campaignDetailsData) => {
    const campaignWithErrors = campaignDetailsData.filter((campaign) => !!campaign.campaignFailureError)[0];
    return campaignWithErrors ? campaignWithErrors.campaignFailureError : null;
};

export const campaignErrorSelector = createSelector([campaignErrorHandler], (campaignError) => campaignError);

export const getCurrentBMUserSelector = (users, account) =>
    Array.isArray(users) && users.length
        ? users.find((user) => user.invited_facebookaccount_id === account.entity.id)
        : {};

export const getCurrentBMUserStatus = (users, account) => {
    let currentBMUserStatus = '';
    const currentBMUser =
        Array.isArray(users) && users.find((user) => user.invited_facebookaccount_id === account.entity.id);
    if (currentBMUser !== undefined) {
        currentBMUserStatus = currentBMUser.status;
    }
    return currentBMUserStatus;
};

export const isCampaignStatisticsPending = (state) => state.campaignStatistics.campaignStatisticsDataPending;
export const isShopValidateRequesting = (state) => state.shopError.shopValidateRequesting;
export const getShopErrorSelector = (state) => state.shopError.shopError;
export const getShopErrorTokenSelector = (state) => state.shopError.shopError.errorToken;
export const getBulkNotificationContentSelector = (state) => state.bulkNotification.notificationContent;
export const getBulkNotificationTypeSelector = (state) => state.bulkNotification.notificationType;
export const getAdAccountsSelector = (state) => state.onboarding.adAccounts;
export const getCampaignSuggestionsSelector = (state) => state.campaignSuggestions;
export const getShopRecurringChargeSelector = (state) => state.shopRecurringCharge;
export const getSelectedFBPage = (pages, shopSettings) => pages.find((page) => page.id === shopSettings.fb_page_id);
