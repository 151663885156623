import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Button from 'components/Button';
import AlertWidget from 'components/AlertWidget';

import { requestGooglePaymentMethod } from 'actions/campaign';
import { setGoogleOnboardingState } from "actions/googleOnboardingState";
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { ONBOARDING_STATES } from 'constants/googleOnboarding';
import { changeOnboardingState } from 'modules/home/utils/changeOnboardingState';
import { googleStatePolling } from "modules/onboarding/google/helpers/googleStatePolling";



import VideoHelpSection from '../../../components/VideoHelpSection';
import './Billing.css';

const Billing = ({ currentShopId, checkGooglePaymentMethod, setGoogleOnboardingState, redirect }) => {
    const [googlePaymentMethodError, setGooglePaymentMethodError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let googlePaymentMethodAlert = null;

    const buttonClickHandler = () => {
        setIsLoading(true);
        checkGooglePaymentMethod(currentShopId).then((res) => {
            if (!(res instanceof Error)) {
                if (res.status === 'payment-method-required') {
                    setGooglePaymentMethodError(true);
                    setIsLoading(false);
                }
                if (res.status === 'payment-method-connected') {
                    changeOnboardingState({
                        currentShopId,
                        type: ONBOARDING_TYPES.google,
                        status: ONBOARDING_STATES.add_payment_method,
                    }).then((res) => {
                        if (res.status === 409) {
                            googleStatePolling(currentShopId, redirect, setGoogleOnboardingState)
                        }
                        return res.json()
                    })
                        .then((res) => {
                            if (res?.processing) {
                                googleStatePolling(currentShopId, redirect, setGoogleOnboardingState);
                            }
                        });
                }
            } else {
                setGooglePaymentMethodError(true);
                setIsLoading(false);
            }
        });
    };

    if (googlePaymentMethodError) {
        googlePaymentMethodAlert = (
            <AlertWidget color="danger">
                <p className="alert-description__item">
                    Your payment method isn't confirmed yet. Confirm it and try again.
                </p>
            </AlertWidget>
        );
    }

    return (
        <>
            <h1 className="gl-payment-method__title">Review payment info: Google ad spend</h1>
            <div className="gl-payment-method__wrapper">
                <div className="gl-payment-method__main-section">
                    <div className="gl-payment-method__notification">{googlePaymentMethodAlert}</div>
                    <p className="gl-payment-method__description">You will pay for ads directly to Google.</p>
                    <p className="gl-payment-method__description">To complete ad setup, you need to:</p>
                    <ol className="gl-payment-method__list">
                        <li className="gl-payment-method__list-item">
                            Go to your &nbsp;
                            <a
                                className="gl-payment-method__text-link"
                                href="https://mail.google.com/mail/u/0/#search/from%3A(ads-account-noreply%40google.com)+in%3Aanywhere"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="gl-payment-method__gmail-icon" /> Gmail inbox
                            </a> and find an email with invite from Google Ads.
                        </li>
                        <li className="gl-payment-method__list-item">
                            Click the link in it to accept the invite to manage Google Ads account we created for you.
                        </li>
                        <li className="gl-payment-method__list-item">
                            A new page will open. Wait till it fully loads and click on <br />
                            <span className="gl-payment-method__tool-icon" /> <strong>Tools and Settings</strong>
                        </li>
                        <li className="gl-payment-method__list-item">
                            In the menu that appears, click on <strong>Summary</strong> under Billing.
                        </li>
                        <li className="gl-payment-method__list-item">Fill out your payment information & click <strong>Submit</strong> button.</li>
                    </ol>
                    <Button color="primary" className="gl-payment-method-cta-button" onClick={buttonClickHandler} pending={isLoading}>
                        I added a payment method
                    </Button>
                </div>
                <VideoHelpSection videoLink="https://www.youtube.com/embed/N6yFWFLNOaw" />
            </div>
        </>
    );
};

const mapStateToProps = ({ shops }) => ({
    currentShopId: shops?.currentShopId,
});

const mapPropsToDispatch = {
    redirect: replace,
    checkGooglePaymentMethod: requestGooglePaymentMethod,
    setGoogleOnboardingState: setGoogleOnboardingState
};

export default connect(mapStateToProps, mapPropsToDispatch)(Billing);
