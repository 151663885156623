export const AD_MESSAGE_ERROR_CODE = {
    PROHIBITED_WORD: 'PROHIBITED_WORD',
    INVALID_DYNAMIC_TAG: 'INVALID_DYNAMIC_TAG',
};

export class AdMessageError extends Error {
    constructor(code, subject, value, ...args) {
        super(...args);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdMessageError);
        } else {
            this.stack = (new Error()).stack;
        }
        this.code = code;
        this.subject = subject;
        this.value = value;
    }
}

export class InvalidTagError extends AdMessageError {
    constructor(tag) {
        super(AD_MESSAGE_ERROR_CODE.INVALID_DYNAMIC_TAG, 'dynamic tag', tag, 'invalid tag used');
    }
}

export class ProhibitedWordError extends AdMessageError {
    constructor(word) {
        super(AD_MESSAGE_ERROR_CODE.PROHIBITED_WORD, 'prohibited word', word, 'prohibited words used in message');
    }
}
