import React from 'react';

import './Footer.css';
import hcIcon from './hc.png';
import metaPartners from './meta-partners-logo.svg';
import googlePartners from './google-partners-logo.svg'

const Footer = () => (
    <footer className="footer-wrapper">
        <div className="main-container">
            <ul className="footer__section footer__section_nav">
                <li className="footer-section__item footer-section__item_help-center">
                    <a
                        href="https://help.adwisely.com"
                        className="footer-section-item__link"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={hcIcon} alt="help-center-icon" className="footer-section-item__link-icon" />
                        Help Center
                    </a>
                </li>

                <li className="footer-section__item">
                    <a
                        href="https://adwisely.com/blog/"
                        className="footer-section-item__link"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Blog
                    </a>
                </li>

                <li className="footer-section__item">
                    <a
                        href="https://help.adwisely.com/learn-about-pricing-privacy-policy-and-terms/whats-the-cost-of-retargetapp"
                        className="footer-section-item__link"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Pricing
                    </a>
                </li>
            </ul>

            <div className="footer__section footer__section_with-fb-marketing-badge">
                <a
                    href="https://www.google.com/partners/agency?id=3011380129"
                    className="footer-section-item__link footer-section-item__link_with-google-marketing-badge"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={googlePartners} alt="Google Partner" />
                </a>
                <a
                    href="https://www.facebook.com/business/partner-directory/details?id=428805074168510"
                    className="footer-section-item__link footer-section-item__link_with-fb-marketing-badge"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={metaPartners} alt="Meta Business Partners" />
                </a>
            </div>

            <div className="footer__section footer__section_corp-info">
                <div className="footer-section__item footer-section__item_corp-info">
                    <p className="footer-section-item__description footer-section-item__description_corp-info">
                        &copy; {new Date().getFullYear()} Ad Intelegence Inc.
                    </p>
                    <p className="footer-section-item__description footer-section-item__description_corp-info">
                        Adwisely &#8212; Make advertising easy.
                    </p>
                </div>
            </div>

            <div className="footer__section footer__section_back-to-top">
                <button
                    className="footer-section__item footer-section__item_back-to-top"
                    onClick={() => window.scrollTo(0, 0)}
                    type="button"
                >
                    <span className="footer-section-item__button footer-section-item__button_with-arrow">
                        Back to Top
                    </span>
                </button>
            </div>
        </div>
    </footer>
);

export default Footer;
