import React, { PureComponent } from 'react';

import './DashboardRecommendation.css';

class DashboardRecommendation extends PureComponent {
    render() {
        return <div className="dashboard-page-trial__block dashboard-recommendation">{this.props.children}</div>;
    }
}

export default DashboardRecommendation;
