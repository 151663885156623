import React from 'react';
import './GoogleDisplayDesktop.css';

export const GoogleDisplayDesktop = ({product}) => (
    <div className='gl-dp'>
        <div className='gl-dp__p gl-dp__p--p1'>
            <img src={product.img} alt={product.title} />
        </div>
        <div className='gl-dp__p gl-dp__p--p2'>
            <img src={product.img} alt={product.title} />
        </div>
        <div className='gl-dp__p gl-dp__p--p3'>
            <img src={product.img} alt={product.title} />
        </div>
    </div>
)