import React from 'react';
import PropTypes from 'prop-types';

import TipsWidget from 'components/TipsWidget';
import Button from 'components/Button';

import './SaveCampaignDetails.css';

const SaveCampaignDetails = (props) => {
    const { formName, hasCampaignFormError, tipsErrorText, buttonTitle, pending, buttonColor, isButtonDisabled } = props;

    const disabledButtonTips = (
        <TipsWidget
            isDarkView
            placement="top"
            additionalClassName="save-campaign-details-cta-button__tips-wrapper"
            additionalIconClassName="save-campaign-details-cta-button__tips-icon"
        >
            <div>
                <p className="tips-description__content">{tipsErrorText}</p>
            </div>
        </TipsWidget>
    );

    return (
        <section className="save-campaign-details__wrapper">
            <Button
                pending={pending}
                submit
                large
                form={formName}
                color={buttonColor}
                className="save-campaign-details__cta-button"
                disabled={hasCampaignFormError || pending || isButtonDisabled}
            >
                <span>{buttonTitle}</span>

                {hasCampaignFormError && !!tipsErrorText ? disabledButtonTips : ''}
            </Button>
        </section>
    );
};

SaveCampaignDetails.defaultProps = {
    hasCampaignFormError: null,
    pending: false,
};

SaveCampaignDetails.propTypes = {
    buttonColor: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    hasCampaignFormError: PropTypes.bool,
    pending: PropTypes.bool,
    tipsErrorText: PropTypes.string.isRequired,
};

export default SaveCampaignDetails;
