import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import TipsWidget from 'components/TipsWidget';

import { BookACallSection } from './BookACallSection';

export const SharePixelView = ({
    text,
    title,
    shopId,
    isChecked,
    tooltipContent,
    copyToClipboard,
    toggleCheckbox,
    requestSendEventMetrics,
    continueButtonLoading,
    continueButtonHandler,
}) => (
    <div className="share-pixel__wrapper">
        <h1 className="share-pixel__title">{title}</h1>
        <section className="share-pixel__section">
            <p className="share-pixel__text">{text}</p>
            <div className="share-pixel__instruction-wrapper">
                <div>
                    <ol className="share-pixel__list">
                        <li className="share-pixel__list-item">
                            Go to{' '}
                            <a
                                className="share-pixel__text-link"
                                href="https://business.facebook.com/events_manager2"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Events Manager
                            </a>
                            .{' '}
                            <a
                                className="share-pixel__text-link--red"
                                href="https://www.youtube.com/watch?v=PBpVCkCo7zw"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                See instruction <span className="share-pixel__yt-icon" />
                            </a>
                        </li>
                        <li className="share-pixel__list-item">
                            Choose your <strong>Pixel</strong> and make sure that <strong>Purchase event</strong> is set
                            as the highest priority
                        </li>
                        <li className="share-pixel__list-item">
                            Now go to{' '}
                            <a
                                className="share-pixel__text-link"
                                href="https://business.facebook.com/settings/pixels/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Pixels
                            </a>
                            .
                        </li>
                        <li className="share-pixel__list-item">
                            Click <strong>“Assign Partners”</strong> and paste {''}
                            <TipsWidget
                                isDarkView
                                customIcon={
                                    <div className="share-pixel__copy-to-clipboard" onClick={copyToClipboard}>
                                        <span id="copy-to-clipboard">1416106945361739</span> {''}
                                        <span className="share-pixel__clipboard-icon" />
                                    </div>
                                }
                                additionalClassName="share-pixel__tips-wrapper"
                            >
                                <p className="tips-description__content">{tooltipContent}</p>
                            </TipsWidget>{' '}
                            under <strong>Partner ID</strong>.
                        </li>
                        <li className="share-pixel__list-item">
                            Provide <strong>Full Control</strong> for management pixel
                        </li>
                        <li className="share-pixel__list-item">Follow the on-screen instructions.</li>
                    </ol>

                    <div className="share-pixel__validation">
                        <label className="share-pixel__checkbox-label" htmlFor="sharePixelVerification">
                            <Checkbox onChange={toggleCheckbox} checkboxId="sharePixelVerification" />I set Purchase
                            event on my Pixel as the highest priority and shared it with Adwisely
                        </label>
                    </div>

                    <Button
                        color="primary"
                        className="share-pixel__button"
                        pending={continueButtonLoading}
                        onClick={continueButtonHandler}
                        disabled={!isChecked || continueButtonLoading}
                    >
                        Continue
                    </Button>
                </div>

                <BookACallSection shopId={shopId} requestSendEventMetrics={requestSendEventMetrics} />
            </div>
        </section>
    </div>
);

SharePixelView.propTypes = {
    shopId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    tooltipContent: PropTypes.string.isRequired,
    copyToClipboard: PropTypes.func.isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
    continueButtonLoading: PropTypes.bool.isRequired,
    continueButtonHandler: PropTypes.func.isRequired,
    requestSendEventMetrics: PropTypes.func.isRequired,
};
