import React, { PureComponent } from 'react';

import NotificationWidget from 'components/NotificationWidget';
import Button from 'components/Button';

class ShopNoPaymentMethodBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.TipsErrorText = null;
    }

    componentDidMount() {
        const { changeTipsErrorText } = this.props;
        if (changeTipsErrorText && this.TipsErrorText) changeTipsErrorText(this.TipsErrorText);
    }

    render() {
        const { shopError } = this.props;
        let noPaymentMethodBanner = null;
        if (!shopError.errorData) shopError.errorData = {};
        switch (shopError.errorToken) {
            case 'payment-method-not-found':
                if (!window.location.pathname.includes('onboarding')) {
                    const bannerTitle = <p className="notification__title">Campaign is paused</p>;

                    const bannerContent = (
                        <div>
                            <p className="notification__description">
                                Your campaign is paused since the ad account it is running in doesn’t have a valid
                                payment method.
                            </p>

                            <p className="notification__description">
                                Go to <span className="notification__caption">Ad Account Billing</span> to add a Payment
                                Method. Once it is done, your campaign will be resumed automatically.
                            </p>
                        </div>
                    );

                    const bannerButtons = (
                        <div>
                            <Button
                                url={
                                    'https://business.facebook.com/ads/manager/account_settings/account_billing/' +
                                    `?act=${shopError.errorData.ad_account_id}&pid=p2&page=account_settings&` +
                                    'tab=account_billing_settings'
                                }
                                color="transparent-bordered"
                            >
                                Go to Payment Settings
                            </Button>
                        </div>
                    );
                    this.TipsErrorText =
                        'To edit a campaign, you need to add a payment method ' +
                        'to the ad account used for your retargeting ads.';

                    noPaymentMethodBanner = (
                        <div>
                            <NotificationWidget type="danger">
                                {[bannerTitle, bannerContent, bannerButtons].map((bannerItem, key) => (
                                    <span key={key}>{bannerItem}</span>
                                ))}
                            </NotificationWidget>
                        </div>
                    );
                }

                break;
            default:
                break;
        }
        return noPaymentMethodBanner;
    }
}

export default ShopNoPaymentMethodBanner;
