import * as shopActions from 'actions/shop';
import * as campaignActions from 'actions/campaign';

const initialState = {
    shopError: {},
    shopValidateRequesting: false,
    shopValidateSuccess: false,
    shopValidateError: false,
    shopValidateTimestamp: null,
};

export default (state = initialState, action) => {
    let timestamp;
    if (action.meta && action.meta.timestamp) {
        timestamp = action.meta.timestamp;
    }
    switch (action.type) {
        case shopActions.SHOP_VALIDATE_REQUEST:
        case campaignActions.CAMPAIGN_VALIDATE_REQUEST:
        case campaignActions.CAMPAIGN_CREATE_REQUEST:
        case campaignActions.CAMPAIGN_EDIT_REQUEST:
        case campaignActions.CAMPAIGN_VALIDATE_FB_REQUEST:
            state = {
                ...state,
                shopValidateRequesting: true,
                shopValidateTimestamp:
                    !state.shopValidateTimestamp || state.shopValidateTimestamp < timestamp
                        ? timestamp
                        : state.shopValidateTimestamp,
            };
            break;

        case shopActions.SHOP_VALIDATE_SUCCESS:
            const isError = !!action.payload.error;
            const shopError = isError ? {
                errorToken: action.payload.error,
                errorData: action.payload.error_data
            } : action.payload;
            state = {
                ...initialState,
                shopError: shopError,
                shopValidateError: isError,
                shopValidateRequesting: false,
                shopValidateSuccess: !isError,
            };
            break;
        case campaignActions.CAMPAIGN_VALIDATE_SUCCESS:
        case campaignActions.CAMPAIGN_CREATE_SUCCESS:
        case campaignActions.CAMPAIGN_EDIT_SUCCESS:
            if (timestamp === state.shopValidateTimestamp) {
                state = {
                    ...initialState,
                    shopValidateRequesting: false,
                    shopValidateSuccess: true,
                };
            }
            break;

        case shopActions.SHOP_VALIDATE_FAILURE:
        case campaignActions.CAMPAIGN_CREATE_FAILURE:
        case campaignActions.CAMPAIGN_VALIDATE_FAILURE:
        case campaignActions.CAMPAIGN_EDIT_FAILURE:
        case campaignActions.CAMPAIGN_VALIDATE_FB_FAILURE:
            if (timestamp === state.shopValidateTimestamp) {
                state = {
                    ...initialState,
                    shopError: action.payload,
                    shopValidateError: true,
                    shopValidateRequesting: false,
                    shopValidateSuccess: false,
                };
            }
            break;

        case shopActions.CLEAR_SHOP_ERROR:
        case campaignActions.CLEAR_CAMPAIGN_ERROR:
            state = {
                ...initialState,
            };
            break;

        default:
            break;
    }

    return state;
};
