import PropTypes from "prop-types"
import React from 'react';

import intercom from 'libs/intercom';
import Button from 'components/Button';

import './CustomCampaignBanner.css';

const CustomCampaignBanner = ({ onClickHandler }) => (
    <section className="custom_campaign_banner">
        <div className="custom_campaign_banner-close-btn" onClick={onClickHandler} />
        <header className="custom_campaign_banner-header">
            <h2 className="custom_campaign_banner-title">
                Try a more personalised ad approach with Custom Campaigns
            </h2>
        </header>
        <span className="custom_campaign_banner-body">
            Custom Campaigns are ads that are manually created, monitored & optimized by our team of Facebook ad
            experts. To set up a Custom Campaign, book a call or <span onClick={intercom.show}>message us</span> via the in-app chat.
        </span>
        <div className="custom_campaign_banner-cta">
            <Button className="custom_campaign_banner-cta_book_call" url="https://calendly.com/adwisely-support-team/custom-campaign-presentation" color="yellow">
                Book a call
            </Button>
            <Button url="https://help.adwisely.com/en/articles/5733611-custom-campaigns" className="custom_campaign_banner_learn_more" color="link">
                Learn more
            </Button>
        </div>
    </section>
);

CustomCampaignBanner.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
};

export default CustomCampaignBanner;
