/* eslint-disable */
import React from 'react';

import logo from './../loader.svg';

export const Icon = () => (
    <div className="error-screen__icon-wrapper">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img className="loader-image error-screen__loader-image--spinner" src={logo} />
        <svg
            className="error-screen__icon__inner"
            width="62"
            height="61"
            viewBox="0 0 62 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.8315 50.2673L31 60.8112L35.1685 50.2673C37.8658 43.1563 43.5055 37.5166 50.8617 34.5741L61.4056 30.4056L50.8617 26.2371C43.7507 23.5398 38.111 17.9001 35.1685 10.5439L31 0L26.8315 10.5439C24.1342 17.6549 18.4945 23.2946 11.1383 26.2371L0.594431 30.4056L11.1383 34.5741C18.2493 37.7617 23.889 43.4015 26.8315 50.2673Z"
                fill="#07AE3A"
            />
        </svg>
    </div>
);
