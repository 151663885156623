import React from 'react';
import './TikTokMobile.css';

export const TikTokMobile = ({product}) => (
    <div className="tt">
        <div className="tt__adv">
            <div className="tt__wrapper">
                <div className="tt__avatar" />
                <div className="tt__text-wrapper">
                    <div className="tt__title">{product.title}</div>
                    <div className="tt__descr">{product.description}</div>
                    <div className="tt__sponsored">Sponsored</div>
                </div>
            </div>
            <div className="tt__btn">Shop now</div>

        </div>
    </div>
)