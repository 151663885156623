import { ERROR_COMMON } from 'actions/error';

const error = (state = [], action) => {
    if (action.type === ERROR_COMMON) {
        state = [action.payload];
    }
    return state;
};

export default error;
