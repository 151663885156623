import { SHOP_BILLING_INFO_REQUEST, SHOP_BILLING_INFO_SUCCESS, SHOP_BILLING_INFO_FAILURE } from 'actions/shop';
import { REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS, REQUEST_STATUS_FAILURE } from 'constants/requestStatuses';

const initialState = {
    billingInfoData: null,
    error: null,
    billingInfoState: null,
    businessModel: '',
    currency: 'USD',
    stripe_card: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SHOP_BILLING_INFO_REQUEST:
            state = {
                ...initialState,
                billingInfoState: REQUEST_STATUS_PENDING,
            };
            break;
        case SHOP_BILLING_INFO_SUCCESS:
            state = {
                ...initialState,
                businessModel: payload.business_model || initialState.businessModel,
                billingInfoData: payload.data,
                currency: payload.currency,
                billingInfoState: REQUEST_STATUS_SUCCESS,
                stripe_card: payload.stripe_card,
            };
            break;
        case SHOP_BILLING_INFO_FAILURE:
            state = {
                ...initialState,
                error: payload,
                billingInfoState: REQUEST_STATUS_FAILURE,
            };
            break;
        default:
            break;
    }
    return state;
};
