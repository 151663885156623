import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { SHOPIFY } from 'constants/platform';

import { getCurrentShopSelector } from 'helpers/selectors';

import SubscriptionPlan from 'modules/onboarding/components/SubscriptionPlan';

export const SubscriptionPlanFacebookChildBMView = ({currentShop, currentShopId, replace}) => {
    const onSubscriptionPlanSelectSuccess = () => {
        currentShop.type === SHOPIFY
            ? replace(`/${currentShopId}/onboarding/shopify-billing`)
            : replace(`/${currentShopId}/onboarding/stripe-billing`);
    }

    return (
        <SubscriptionPlan
            onSubscriptionPlanSelectSuccess={onSubscriptionPlanSelectSuccess}
        />
    )
}

const mapStateToProps = ({ shops }) => (
    {
        currentShopId: shops?.currentShopId,
        currentShop: getCurrentShopSelector(shops, shops.currentShopId),
    }
);

const mapPropsToDispatch = {
    replace
};

export const SubscriptionPlanFacebookChildBM = connect(mapStateToProps, mapPropsToDispatch)(SubscriptionPlanFacebookChildBMView);

