import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import {
    SUBSCRIPTION_BILLING_MIGRATION
} from 'constants/notificationType';
import { requestFBPages } from 'actions/facebook/pages';

import GeneralBannerNotification from './GeneralBannerNotification/GeneralBannerNotification';

class GeneralBannerNotificationList extends PureComponent {
    componentDidMount() {
        const { pages, getFbPages } = this.props;
        if (!pages.length) getFbPages({}, false);
    }

    render() {
        const { shopNotifications, shopId, pages, shopsCampaignsSettings } = this.props;
        const generalNotificationList = [];
        const notificationListArray = [SUBSCRIPTION_BILLING_MIGRATION];
        let bannerNotificationList = null;
        let selectedFBPage = null;
        if (shopsCampaignsSettings) {
            const selectedFBPageId = shopsCampaignsSettings.fb_page_id;
            selectedFBPage = (selectedFBPageId && Array.isArray(pages)) && pages?.find((elem) => elem.id === selectedFBPageId);
        }

        shopNotifications.forEach((notification) => {
            if (notificationListArray.includes(notification.type)) {
                generalNotificationList.push({
                    reason: notification.type,
                    type: 'warning',
                    shopId,
                });
            }
        });
        if (generalNotificationList.length) {
            bannerNotificationList = generalNotificationList.map((notification) => (
                <GeneralBannerNotification
                    key={notification.reason}
                    notificationReason={notification.reason}
                    type={notification.type}
                    domain={notification.domain}
                    shopId={shopId}
                    selectedFBPage={selectedFBPage}
                />
            ));
        }
        return <div>{bannerNotificationList}</div>;
    }
}

GeneralBannerNotificationList.propTypes = {
    shopNotifications: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            deleted_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            expiry_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            params: PropTypes.any,
            shop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            type: PropTypes.string,
            updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        }),
    ),
    shopsCampaignsSettings: PropTypes.shape({
        ad_account: PropTypes.number,
        business_manager: PropTypes.number,
        fb_business_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fb_page_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        shop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        shop_owner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        _settings: PropTypes.any,
    }),
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            fan_count: PropTypes.number,
            id: PropTypes.string,
            is_published: PropTypes.boolean,
            name: PropTypes.string,
            tasks: PropTypes.arrayOf(PropTypes.string),
        }),
    ),
    getFbPages: PropTypes.func.isRequired,
    shopId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

GeneralBannerNotificationList.defaultProps = {
    pages: [],
    shopNotifications: [],
    shopsCampaignsSettings: {},
};

const mapStateToProps = (state) => ({
    shopNotifications: state.shopNotifications.entity,
    pages: state.facebook.pages.entities,
    shopsCampaignsSettings: state.shopsCampaignsSettings.entity,
});

const dispatchToProps = {
    getFbPages: requestFBPages,
};

export default connect(mapStateToProps, dispatchToProps)(GeneralBannerNotificationList);
