import React, { PureComponent } from 'react';
import Popover from 'components/Popover';
import Button from 'components/Button';
import { BIG_COMMERCE, WOO_COMMERCE } from 'constants/platform';

import './ExtendBudgetPopover.css';

class ExtendBudgetPopover extends PureComponent {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.togglePopover();
        }
    }

    getExtendBudgeUrl(shopType, shopDomain) {
        if (shopType === BIG_COMMERCE) {
            return 'https://www.bigcommerce.com/apps/adwisely/';
        } else if (shopType === WOO_COMMERCE) {
            return `http://${shopDomain}/wp-admin/plugin-install.php?tab=plugin-information&plugin=retargetapp-integration-for-woocommerce`;
        }
        return 'https://apps.shopify.com/adwisely?#modal-show=ReviewListingModal';
    }

    render() {
        const {
            currentShopType,
            currentShopDomain,
            trackExtendTrialBadgeEvent,
            children,
        } = this.props;

        const extendBudgetUrl = this.getExtendBudgeUrl(currentShopType, currentShopDomain);

        const popoverBody = (
            <div className="trial-badge-extended-popover-body">
                <p className="trial-badge-extended-popover-body__title">Want to extend the trial?</p>
                <p>
                    Your ads are doing great{' '}
                    <span role="img" aria-label="Smile">
                        🙂
                    </span> {''}
                    Share your success with others and get 7 more days of trial.
                </p>
                <Button
                    className="trial-badge-extended-popover-body__button"
                    url={extendBudgetUrl}
                    onClick={trackExtendTrialBadgeEvent}
                    color="primary"
                >
                    Write a <span className="custom-glyph__span">𝟱</span>{' '}
                    <span role="img" aria-label="Star">
                        ⭐
                    </span>{' '}
                    review
                </Button>
            </div>
        );

        return (
            <Popover
                placement="bottom"
                content={popoverBody}
            >
                {children}
            </Popover>
        );
    }
}

export default ExtendBudgetPopover;
