import React from "react";
import { useGetLastActiveSubcription } from 'hooks/useGetLastActiveSubcription';

import { Modal, ModalBody, ModalHeader } from "components/Modal/Modal";
import Button from "components/Button";

export const ResumePlanModal = ({isOpen, onOkBtnClick, shopId, onBackBtnClick}) => {
    const { fee } = useGetLastActiveSubcription(shopId)
    return (
        <Modal isOpen={isOpen} className='cancel-plan-modal'>
            <ModalHeader className='cancel-plan-modal__header'>You have resumed your Adwisely plan</ModalHeader>
            <ModalBody className='cancel-plan-modal__body'>
                <p>You can now resume your Adwisely-powered ads from the app {' '}
                    <button className='billing-plan__link' onClick={onOkBtnClick}>dashboard</button>
                </p>
                <p>You will soon be charged a monthly fee of $ {fee}.
                    <br/>
                    <a
                        href="https://help.adwisely.com/en/articles/900414-what-s-the-cost-of-adwisely"
                        className='billing-plan__link'
                        target='_blank'
                        rel="noopener noreferrer">Learn more</a>
                </p>
                <p>To view or change your current plan, go to My Account → Settings → Billing</p>
                <div className="cancel-plan-modal__buttons">
                    <Button onClick={onBackBtnClick}>Back to Settings</Button>
                    <Button color="primary" onClick={onOkBtnClick}>Go to Dashboard</Button>
                </div>
            </ModalBody>
        </Modal>
)}