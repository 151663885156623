import React, { useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { requestSendEventMetrics } from 'actions/shop';
import { metricType, fbCampaignTactics } from 'constants/metric';

import { changeOnboardingState } from 'modules/home/utils';
import { ONBOARDING_TYPES } from 'constants/onboarding';
import { UBM_ONBOARDING_STATES } from 'constants/ubmOnboarding';
import { facebookStatePolling } from 'modules/onboarding/userBMFlow/helpers/ubmStatePolling';
import { setLocalStorageObjectItem } from 'helpers';
import { LoaderFullScreen, LoaderLongWait } from 'components/Loader';

import './ProspectingInvite.css';

const ProspectingInvite = ({ shopId, requestSendEventMetrics, setToRemindLater, ownerType, replace }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
    <section className="prospecting-invite">
        <header className="prospecting-invite-header">
            <div className="prospecting-invite-title">
                <h2 className="banner-invite-title__content">Acquire new customers with Prospecting</h2>
            </div>
        </header>
        <span className="prospecting-invite__body">
            <p>
                Boost sales and acquire more customers by targeting new users who are similar to your current buyers
                with the ads of the products they are most likely to purchase.
            </p>
        </span>
        <div className="prospecting-invite-cta">
            <Button
                innerUrl={ownerType === 'child' && `/${shopId}/create/prospecting`}
                color="primary"
                onClick={() => {
                    if (ownerType !== 'child') {
                        setLocalStorageObjectItem('currentOnboarding', ONBOARDING_TYPES.facebook_prospecting);
                        setIsLoading(true);
                        changeOnboardingState({
                            type: ONBOARDING_TYPES.facebook_prospecting,
                            status: UBM_ONBOARDING_STATES.not_started,
                            currentShopId: shopId,
                        })
                            .then((res) => res.json())
                            .then(res => {
                                if (res?.processing) {
                                    facebookStatePolling(shopId, replace);
                                }
                            });
                    }

                    const metricProps = {
                        shop_id: shopId,
                        type: metricType.fbCampaignInvitationAccepted,
                        props: {
                            tactic: fbCampaignTactics.prospecting,
                            source: 'dashboard',
                        },
                    };
                    requestSendEventMetrics(metricProps);
                }}
            >
                Start Prospecting
            </Button>
            <Button color="link" onClick={() => setToRemindLater(true)} >
                Remind later
            </Button>
            <LoaderFullScreen
                isLoaderShowed={isLoading}
            >
                <LoaderLongWait title="Loading..." />
            </LoaderFullScreen>
        </div>
    </section>
)};

ProspectingInvite.propTypes = {
    shopId: PropTypes.string.isRequired,
    setToRemindLater: PropTypes.func.isRequired,
    requestSendEventMetrics: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    requestSendEventMetrics,
    replace,
};

export default connect(null, mapDispatchToProps)(ProspectingInvite);
